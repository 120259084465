import React, { useEffect, useState } from 'react'

import { ChromePicker } from 'react-color'

import styled from 'styled-components'

const SketchPickerCosrseImg = (props) => {
  const { statusInput, courseNameColor, instructorNameColor, positionColor } = props
  const [bgColor, setbgColor] = useState('#fff')

  useEffect(() => {
    if (statusInput === 'CourseName') {
      setbgColor(courseNameColor)
    } else if (statusInput === 'InstructorName') {
      setbgColor(instructorNameColor)
    } else if (statusInput === 'Position') {
      setbgColor(positionColor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusInput])

  const handleChangeComplete = (color) => {
    setbgColor(color.hex)
    props.handleSketchPickerCosrseImg(statusInput, color.hex)
  }

  const NotificationContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;

  width: 225px;

  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;

  // @media (max-width: 768px) {
  //   width: 320px;
  //   box-shadow: 0 0 0 0 transparent;
  // }

  // @media (max-width: 426px) {
  //   width: 100vw;
  //   box-shadow: 0 0 0 0 transparent;
  }
`

  return (
    <NotificationContainer>
      <ChromePicker color={bgColor} onChangeComplete={handleChangeComplete} />
    </NotificationContainer>
  )
}

export default SketchPickerCosrseImg
