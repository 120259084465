import { CourseAPI } from '../../api'

export const SchoolCourse = {
  state: {
    schoolCourse: {},
  },
  reducers: {
    setSchoolCourse(state, payload) {
      return {
        ...state,
        schoolCourse: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async deleteCourse({ id }) {
      try {
        await CourseAPI.delete({ id })
      } catch (error) {
        throw error
      }
    },
    async getMyCourseById(courseId) {
      try {
        const filter = JSON.stringify({
          include: ['teachers', 'school', 'publishedSubject'],
        })
        const course = await CourseAPI.findById({ id: courseId, filter })
        const studentCount = await CourseAPI.getCourseNumberOfStudents({ id: courseId })
        this.setSchoolCourse({ ...course, studentCount: studentCount.count })
        return course
      } catch (e) {
        throw e
      }
    },
    async getMyCourseByIdObj({ id }) {
      try {
        const filter = JSON.stringify({
          include: ['teachers', 'school', 'publishedSubject'],
        })
        const course = await CourseAPI.findById({ id, filter })
        this.setSchoolCourse(course)
        return course
      } catch (e) {
        throw e
      }
    },
    async publishContent({ id }) {
      try {
        await CourseAPI.publishContent({ id })
        dispatch.Alert.success({ title: 'Updated successfully' })
      } catch (e) {
        dispatch.Alert.error({ title: 'Update Failed' })
        throw e
      }
    },
  }),
}
