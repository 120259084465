import React, { Fragment, useEffect } from 'react'
import ErrorBoundary from '../../components/errorBoundary'
import PageHeading from '../../components/pageHeading/loadable'
import PeriodSwitch from '../../containers/schoolPackagesOverview/PeriodSwitch'
import Footer from 'components/Footer'
import { tabs } from './tabs'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { DesktopAndTablet, SmallMobile } from '../../utils/displayResponsive'

export const Heading = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-top: 1px solid ${(props) => props.theme.gray8};
  z-index: 5;
  position: relative;
  min-height: 60px;
  @media (max-width: 700px) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 10px 0px; */
    margin-bottom: 30px;
  }
`

export const HeadingRight = styled.div`
  display: 'flex';
  justify-content: 'flex-end';
  align-items: 'center';
  background: 'white';
  padding: 10px 50px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
  @media (max-width: 700px) {
    align-items: 'center';
    padding: 15px 20px 0px;
  }
`
const SchoolPackagesLayout = ({
  match,
  history,
  children,
  mySchoolDetail = {},
  setYearMonth,
  yearMonth,
}) => {
  const location = useLocation()
  const locationPathname = location.pathname.split('/').slice(-1)[0]
  const { billPeriod } = mySchoolDetail

  useEffect(() => {
    if (billPeriod === 'monthly' || !billPeriod) {
      setYearMonth(false)
    } else if (billPeriod === 'yearly') {
      setYearMonth(true)
    }
  }, [billPeriod, setYearMonth])
  const onChangeTab = (path) => {
    history.push(match.url + path)
  }

  return (
    <ErrorBoundary>
      <Fragment>
        {locationPathname === 'overview' ? (
          <Heading>
            <DesktopAndTablet>
              <PageHeading learner tabData={tabs} onChangeTab={onChangeTab} hideShadow />
            </DesktopAndTablet>
            <SmallMobile>
              <PageHeading learner tabData={tabs} onChangeTab={onChangeTab} hideShadow headBorder />
            </SmallMobile>

            <HeadingRight>
              <PeriodSwitch isYearly={yearMonth} setIsYearly={setYearMonth} />
            </HeadingRight>
          </Heading>
        ) : (
          <>
            <DesktopAndTablet>
              <PageHeading learner tabData={tabs} onChangeTab={onChangeTab} />
            </DesktopAndTablet>
            <SmallMobile>
              <PageHeading learner tabData={tabs} onChangeTab={onChangeTab} headBorder />
            </SmallMobile>
          </>
        )}

        <div>{children}</div>
        <Footer />
      </Fragment>
    </ErrorBoundary>
  )
}

const mapState = ({
  MySchool: { mySchoolDetail },
  Language: { locale },
  SchoolPackage: { yearMonth },
}) => ({
  mySchoolDetail,
  locale,
  yearMonth,
})

const mapDispatch = ({ SchoolPackage: { setYearMonth } }) => ({
  setYearMonth,
})

export default connect(mapState, mapDispatch)(SchoolPackagesLayout)
