import Resource from '../utils/resource'

export default new Resource('/CourseMarketplaceOrders', {
  // getSchoolCourseMarketplacesAll: {
  //   url: 'public',
  //   method: 'get',
  // },
  // getSchoolCourseMarketplacesAllCount: {
  //   url: 'public/count',
  //   method: 'get',
  // },
  addCourseMarketplaceOrdersInitial: {
    url: 'initial',
    method: 'post',
  },
  getCourseMarketplacesOrdersStatusById: {
    url: '{id}/status',
    method: 'post',
  },
  courseMarketplacesBuyQuota: {
    url: '{id}/buyQuota',
    method: 'post',
  },
})
