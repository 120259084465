import React from 'react'
import ContentLoader from 'react-content-loader'

const MyLoader = () => (
  <ContentLoader
    speed={2}
    width={270}
    height={240}
    viewBox="0 0 270 240"
    backgroundColor="#e6ecef"
    foregroundColor="#ecebeb"
  >
    <circle cx="118" cy="49" r="32" />
    <rect x="19" y="94" rx="0" ry="0" width="196" height="19" />
    <rect x="43" y="119" rx="0" ry="0" width="147" height="19" />
  </ContentLoader>
)

export default MyLoader
