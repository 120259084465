import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ErrorBoundary from 'components/errorBoundary'

const LogOutPage = ({ logout, clearToken, history }) => {
  useEffect(() => {
    setTimeout(() => {
      clearToken()
      logout().finally(() => {
        clearToken()
        history.push('/user/login')
      })
    }, 1000)
  }, [logout, clearToken, history])

  return (
    <ErrorBoundary>
      <div>Logout ...</div>
    </ErrorBoundary>
  )
}

const mapState = (state) => ({
  appUser: state.AppUser,
})

const mapDispatch = (dispatch) => {
  const {
    AppUser: { logout, clearToken },
  } = dispatch
  return {
    logout,
    clearToken,
  }
}

export default connect(mapState, mapDispatch)(LogOutPage)
