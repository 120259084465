import styled from 'styled-components'
import theme from '../../../../theme'

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 120px;
  top: 98px;
  border: 1px solid #253e87;
  border-radius: 10px;
  background-color: #fff;
  //list-style-type: none;
  margin: 0;
  padding: 0;
  width: 48px;
  //height: 100%;
  gap: 0px;
`

const ListItem = styled.li`
  padding: 10px;
  cursor: pointer;
  color: gray;

  /* :hover {
    color: #fff;
    background-color: ${({ bgColor = theme.grey }) => bgColor};
    svg {
      fill: white;
    }
  } */
`

export { List, ListItem }
