import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 10px;

  .color {
    color: ${(props) => (props.altColor ? props.theme.grey : '')};
  }
`
export const Label = styled.label`
  color: ${(props) => props.theme.grey};
  font-size: ${({ theme }) => theme['text-xs']};
  text-transform: capitalize;
  font-weight: bold;
`
export const ErrorMsg = styled.p`
  margin-top: 5px;
  color: ${(props) => props.theme.danger};
  text-transform: none;
  font-size: ${({ theme }) => theme['text-xs']};
  margin-bottom: 1px !important;
`
export const Input = styled.input`
  border: ${(props) => (props.isLogin ? '1px solid #A9B6C6' : 'none')};
  height: 40px;
  /* line-height: 3; */
  padding: 8px 16px;
  border-radius: ${(props) => (props.isLogin ? '5px' : 'none')};
  font-size: ${({ theme }) => theme['text-sm']};
  border-bottom: ${(props) =>
    props.isLogin ? '1px solid #A9B6C6' : '1px solid rgba(194, 194, 194, 0.5)'};
  transition: border 0.3s;
  color: ${(props) => (props.isLogin && props.error ? props.theme.danger : props.theme.dark)};
  ${(props) => props.full && 'width: 100%;'}
  ${(props) =>
    props.error &&
    (props.isLogin
      ? `border: 1px solid ${props.theme.danger};`
      : `border-bottom: 1px solid ${props.theme.danger};`)}

  :focus {
    border: ${(props) => (props.isLogin ? `1px solid ${props.theme.primary1}` : 'none')};
    border-bottom: 1px solid ${(props) => props.theme.primary1};
    ${(props) =>
      props.error &&
      (props.isLogin
        ? `border: 1px solid ${props.theme.danger};`
        : `border-bottom: 1px solid ${props.theme.danger};`)}
    outline: none;
  }
  ::placeholder {
    color: ${(props) => (props.isLogin ? props.theme.black38 : props.theme.danger)};
  }
`

export const Icon = styled.img`
  width: 15px;
  margin-right: 5px;
`
