import React from 'react'
import PropTypes from 'prop-types'
import Line from './style'

function PureLine({ ...props }) {
  return <Line {...props} />
}

PureLine.propTypes = {
  props: PropTypes.object,
}

PureLine.defaultProps = {
  props: {},
}

export default PureLine
