import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Typography, Label } from '../index'
import theme from 'theme'

const Container = styled.div`
  min-width: 200px;

  div[class$='control'] {
    border-radius: 6px;
    min-height: 48px !important;
    border-color: ${(props) => (props.error ? props.theme.danger : props.theme.black20)};
    box-shadow: none;
    opacity: 80%;
    div {
      font-size: ${(props) => props.theme['text-sm']};
    }
    :hover {
      border-color: ${(props) => props.theme.primary1};
      opacity: 100%;
      cursor: pointer;
    }
  }
  div[class$='indicatorContainer'] > svg[class$='Svg'] {
    fill: ${(props) => (props.isDisabled ? props.theme.grey : props.theme.black60)};
  }
  ${({ isMyCoursePage }) => {
    if (isMyCoursePage) {
      return css`
        div[class$='placeholder'] {
          font-size: 14px !important;
          color: ${(props) => props.theme.black38};
        }
        div[class$='option'] {
          font-size: 14px !important;
          color: #6f6f6f;
          :hover {
            background-color: ${(props) => props.theme.primary1};
            opacity: 100%;
          }
        }
      `
    }
    return css``
  }}
`

// with Formik
export const customReactSelect = ({
  id,
  descId,
  label,
  name,
  value,
  error,
  description,
  touched,
  defaultValue,
  noMargin,
  isAsync,
  loadOptions,
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      '&:hover': {
        border: `1px solid ${theme.primary1}`,
        cursor: 'pointer',
      },
      '&:active': {
        border: `1px solid ${theme.primary1}`,
        cursor: 'pointer',
      },
    }),
    container: (provided, props) => ({
      ...provided,
      marginTop: '10px',
      width: props.styles?.width || '100%',
      minWidth: '250px',
      fontSize: 14,
      color: theme.primary1,
      // zIndex: 0,
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        fontSize: 14,
        color: theme.black38,
      }
    },
    option: (provided, { isSelected, isFocused }) => {
      return {
        ...provided,
        backgroundColor: isSelected ? theme.whiteblue : isFocused ? theme.black10 : null,
        color: isSelected ? theme.primary1 : isFocused ? theme.black87 : null,
        fontSize: 14,
        fontWeight: isSelected ? 700 : 400,
        '&:hover': {
          cursor: 'pointer',
        },
        '&:active': {
          cursor: 'pointer',
        },
      }
    },
    menu: (styles) => ({ ...styles, fontSize: 14 }),
    input: (styles) => ({
      ...styles,
      fontSize: 14,
      '& input': {
        font: 'inherit',
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, backgroundColor: 'transparent' }),
    singleValue: (styles) => ({ ...styles, color: `${theme.black87}` }),
    //   dropdownIndicator: (provided, props) => ({
    //     ...provided,
    //     fill: props.isDisabled ? 'red' : '#707070',
    //     // transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    //   }),
  }
  const handleChange = (value) => {
    props.onChange(name, value)
  }

  const handleBlur = () => {
    props?.onBlur?.(name, true)
  }

  const Input = isAsync ? AsyncSelect : Select
  return (
    <Container error={error} {...props} style={{ ...props.style }}>
      {label && (
        <Label
          id={id}
          descId={descId}
          htmlFor={name}
          noMargin={noMargin}
          description={description}
          isRequired={props.isRequired}
          color={props.color}
          bold={props.bold}
        >
          {label}
        </Label>
      )}
      <Input
        isDisabled={props.isDisabled}
        isOptionDisabled={props.isOptionDisabled}
        styles={customStyles}
        id=""
        options={props.options}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        defaultValue={defaultValue}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
        loadOptions={loadOptions}
        // menuIsOpen={true}
      />
      {error && touched && (
        <Typography margin="5px 0px" strong error>
          {error}
        </Typography>
      )}
    </Container>
  )
}

customReactSelect.defaultProps = {
  name: '',
  options: [
    { label: 'Technology', value: 'teachnology' },
    { label: 'Data', value: 'data' },
    { label: 'Design', value: 'design' },
  ],
  placeholder: 'Select...',
}

customReactSelect.propTypes = {
  name: PropTypes.string,
}

export default customReactSelect
