import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ErrorBoundary from '../errorBoundary'
import { Card, Typography, Wrapper, Status, Button, Label, Popup } from './style'
import { Tooltip, Icon } from 'components'
import MoreIcon from 'images/ic-more-blue-24-px.svg'
import BinIcon from 'images/ic-bin-blue-24-px.svg'

import moment from 'moment'
class CardLiveVideo extends Component {
  state = {
    isShowTooltip: false,
  }
  handleClick = () => {
    const {
      id: meetingId,
      password,
      history,
      match: {
        params: { courseId },
      },
    } = this.props
    const state = { password }
    const url = `/my-course/courses/${courseId}/live/${meetingId}`
    history.replace(url, state)
    console.log('history: ', history)
  }

  meetingMessage = () => {
    const { status } = this.props

    switch (status) {
      case 'started':
        return 'Join Meeting'
      case 'waiting':
        return 'Waiting'
      case 'ended':
        return 'Ended'
      default:
        return 'Ended'
    }
  }

  status = () => {
    const { status } = this.props

    switch (status) {
      case 'started':
        return 'status'
      case 'waiting':
        return 'Waiting'
      case 'ended':
        return 'Ended'
      default:
        return 'Ended'
    }
  }

  handleTooltip = () => {
    this.setState({ isShowTooltip: !this.state.isShowTooltip })
  }

  handleDelete = async () => {
    const {
      id: meetingId,
      match: {
        params: { courseId },
      },
      deleteCourseMeetingById,
      getCourseMeetings,
    } = this.props
    console.log(meetingId, courseId)
    await deleteCourseMeetingById({ courseId, meetingId })
    await getCourseMeetings({ courseId })
  }

  render() {
    const { isShowTooltip } = this.state
    const {
      topic = '',
      meetingId,
      password,
      status,
      schedule,
      createdAt,
      join,
      style,
      hightligh,
    } = this.props

    const listMenu = [
      {
        menu: <Icon size="20" label="Delete" margin="14" src={BinIcon} alt="bin-icon" />,
        onClick: this.handleDelete,
      },
    ]
    return (
      <ErrorBoundary>
        <Wrapper style={style}>
          <Status status={status} live hightligh={hightligh}>
            <Label status={status}>
              <Typography color="white" nowrap>
                {status === 'started' ? 'Live' : status === 'waiting' ? 'Upcoming' : 'Ended'}
              </Typography>
            </Label>
            <Typography bold>{topic}</Typography>
          </Status>
          <Card status={status} hightligh={hightligh}>
            <Typography strong color="#3A3A3A">
              Meeting ID:{' '}
              {meetingId.substring(0, 3) +
                ' ' +
                meetingId.substring(3, 7) +
                ' ' +
                meetingId.substring(7, 11)}
            </Typography>
            <Typography strong thin color="#B7B7B7">
              Passcode: {password}
            </Typography>
            {schedule && (
              <Typography strong thin color="#B7B7B7">
                Time: {moment(schedule).format('MMM DD, YYYY LT')}
              </Typography>
            )}
            <Typography paragraph thin color="#B7B7B7">
              created at {moment(createdAt).format('MMM DD, YYYY LT')}
            </Typography>
            {join && (
              <Button
                primary
                nowrap
                disabled={status !== 'started'}
                type="button"
                onClick={this.handleClick}
              >
                {this.meetingMessage()}
              </Button>
            )}
            <Tooltip
              style={{ position: 'absolute', top: '10px', right: '20px' }}
              className="asm-card"
              placement=""
              trigger="click"
              tooltipShown={isShowTooltip}
              onVisibilityChange={this.handleTooltip}
              tooltip={<Popup list={listMenu} />}
            >
              <img
                style={{ filter: 'grayscale(1) brightness(1.4)' }}
                className="more draft"
                src={MoreIcon}
                alt="more-icon"
              />
            </Tooltip>
          </Card>
        </Wrapper>
      </ErrorBoundary>
    )
  }
}

CardLiveVideo.defaultProps = {
  status: 'started',
  title: 'Meeting',
  description: '',
}

CardLiveVideo.propTypes = {
  // title: PropTypes.string.isRequired,
  // description: PropTypes.string.isRequired,
  // broadcastStatus: PropTypes.string.isRequired,
  // broadcastData: PropTypes.shape({
  //   snippet: PropTypes.shape({
  //     thumbnails: PropTypes.shape({
  //       high: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
  //     }).isRequired,
  //   }).isRequired,
  // }).isRequired,
}

const mapState = null
const mapDispatch = ({
  Course: { getBroadcastById, getStreamById, deleteCourseMeetingById, getCourseMeetings },
}) => ({
  getBroadcastById,
  getStreamById,
  deleteCourseMeetingById,
  getCourseMeetings,
})

export default connect(mapState, mapDispatch)(withRouter(CardLiveVideo))
