import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ErrorBoundary from '../errorBoundary'
import { Card, Typography, Wrapper, Image, Status /* Avatar, Section */ } from './style'

class CardLiveVideoStudent extends Component {
  handleClick = async () => {
    try {
      const {
        id: liveId,
        completed,
        getBroadcastById,
        getStreamById,
        broadcastData: { id: broadcastId },
        streamData: { id: streamId },
        history,
        match: {
          params: { courseId },
        },
      } = this.props
      // Pass props broadcast detail in redux
      await getBroadcastById(broadcastId)
      // Get Steam by id in redux
      await getStreamById(streamId)

      if (completed) {
        // Go to Youtube Viewer
        history.push(`/my-course/courses/${courseId}/broadcast/${broadcastId}`)
      } else {
        // Go to Youtube Studio
        history.push(`/my-course/courses/${courseId}/live/${liveId}`)
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  render() {
    const {
      title,
      description,
      broadcastStatus,
      broadcastData: {
        snippet: {
          thumbnails: {
            high: { url },
          },
        },
      },
    } = this.props
    return (
      <ErrorBoundary>
        <Wrapper onClick={this.handleClick}>
          {broadcastStatus === 'live' && (
            <Status live>
              <Typography color="white">Live</Typography>
            </Status>
          )}
          {broadcastStatus === 'testing' && (
            <Status testing>
              <Typography testing color="white">
                Testing
              </Typography>
            </Status>
          )}
          <Image src={url} alt="thumbnail" />
          <Card>
            <Typography strong color="#3A3A3A">
              {title}
            </Typography>
            <Typography paragrahp thin color="#B7B7B7">
              {description}
            </Typography>
            {/* <Section>
              <Avatar src="https://source.unsplash.com/user/erondu" />
              <Typography paragrahp color="#3A3A3A">
                John Doe
              </Typography>
            </Section> */}
          </Card>
        </Wrapper>
      </ErrorBoundary>
    )
  }
}

CardLiveVideoStudent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  broadcastStatus: PropTypes.string.isRequired,
  broadcastData: PropTypes.shape({
    snippet: PropTypes.shape({
      thumbnails: PropTypes.shape({
        high: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

const mapState = null
const mapDispatch = ({ Course: { getBroadcastById, getStreamById } }) => ({
  getBroadcastById,
  getStreamById,
})

export default connect(mapState, mapDispatch)(withRouter(CardLiveVideoStudent))
