import React from 'react'
import mixpanel from 'mixpanel-browser'

import { ACCESS_TOKEN } from '../constants'

const logError = (error) => {
  const tokenStr = localStorage.getItem(ACCESS_TOKEN)
  const token = JSON.parse(tokenStr)
  let data
  if (token) {
    data = { userId: token.userId, error: error.stack }
  } else {
    data = { error: error.stack }
  }
  mixpanel.track('error_boundary', data)
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    logError(error)
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.error({
      error,
      info,
    }) //eslint-disable-line
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}
export default ErrorBoundary
