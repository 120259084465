import styled, { css } from 'styled-components'

export const LogoStyle = styled.img`
  width: 100px;
  height: auto !important;
  z-index: 10;

  ${(props) => props.full && 'width: 100%;'}

  ${({ cursor }) => {
    if (cursor) {
      return css`
        cursor: ${cursor};
      `
    }
    return css``
  }}
  ${({ padding }) => {
    if (padding) {
      return css`
        padding: ${padding};
      `
    }
    return css``
  }}

   ${({ margin }) => {
    if (margin) {
      return css`
        margin: ${margin};
      `
    }
    return css``
  }}


 
  ${({ left, center, right }) => {
    if (left) {
      return css`
        justify-self: start;
      `
    }
    if (center) {
      return css`
        justify-self: center;
      `
    }

    if (right) {
      return css`
        justify-self: end;
      `
    }
    return css``
  }}
  ${({ width }) => {
    if (width) {
      return css`
        width: ${width}px !important;
      `
    }
    return css``
  }}

${({ height }) => {
    if (height) {
      return css`
        height: ${height}px;
      `
    }
    return css``
  }}
`
export const Container = styled.div`
  background-color: hsl(187, 61%, 54%);
`
