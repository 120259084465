import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from '../errorBoundary'
import { Select, colourStyles } from './style'
import Typography from '../typography'

class PureSelect extends Component {
  render() {
    const {
      name,
      defaultValue,
      value,
      error,
      onChange,
      options,
      placeholder,
      width,
      height,
      disabled,
      border,
      heightButton,
      minHeight,
      feedList,
      style,
    } = this.props
    return (
      <ErrorBoundary>
        <Select
          name={name}
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          options={options}
          error={Boolean(error)}
          styles={style ? style : colourStyles}
          placeholder={placeholder}
          width={width}
          height={height}
          isDisabled={disabled}
          border={border}
          heightButton={heightButton}
          minHeight={minHeight}
          feedList={feedList}
        />
        {error && (
          <Typography error strong margin="10px 0px 0px 0px">
            {error}
          </Typography>
        )}
      </ErrorBoundary>
    )
  }
}

PureSelect.propTypes = {
  options: PropTypes.array,
}

PureSelect.defaultProps = {
  options: [],
}

export default PureSelect
