export const tabs = [
  {
    id: 'tab.label.packages',
    label: 'Packages',
    key: '0',
    component: null,
    path: '/overview',
  },
  {
    id: 'tab.label.packagesHistory',
    label: 'Packages History',
    key: '1',
    component: null,
    path: '/history',
  },
]
