import Resource from '../utils/resource'

export default new Resource('/StudentGrades', {
  sendPdfCertificate: {
    url: 'pdfCertificate',
    method: 'post',
  },
  saveBlockchianCert: {
    url: 'blockchainCertificate',
    method: 'post',
  },
  verifyBlockchainCert: {
    url: '{id}/verifyEcert',
    method: 'get',
  },
  getResult: {
    url: 'result',
    method: 'get',
  },
})
