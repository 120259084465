/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { init as initOnesignal } from 'utils/onesignal'

// import Gateway from 'components/Gateway'
import Analytics from 'components/Analytics'
import Alert from 'components/alert/loadable'
import ConfirmModal from 'components/confirmModal'
import Button from 'components/button'

import CreateCourseLayout from 'layouts/createCourseLayout/loadable'
//import CreateCourseSingleTestLayout from 'layouts/createCourseSingleTestLayout/loadable'
import CallbackPage from 'containers/callbackPage/loadable'
import RedirectPage from 'containers/redirectPage/loadable'

import AnnouncePage from 'containers/AnnouncePage'
import LogOutPage from 'containers/logOutPage'
// import AnnounceModal from 'modals/announceModal'

// import SubjectPage from 'containers/subjectPage/loadable'
// import AssessmentsPage from 'containers/assessmentsPage/loadable'

import NotFoundPage from 'containers/notFoundPage/loadable'

// import MyCourseOverviewTab from 'components/myCourseOverviewTab/loadable'

// import EditProfileCertificatesTab from 'components/editProfileCertificatesTab/loadable'

import PrivateRoute from 'components/privateRoute/loadable'

// import AccountNotificationPage from 'containers/accountNotificationPage/loadable'

// import PointRewardLayout from 'layouts/pointRewardLayout'
// import PointAllRewardsPage from 'containers/pointAllRewardsPage/loadable'
// import PointHistoryPage from 'containers/pointHistoryPage/loadable'

import StudentLayout from 'components/layout'
// Landing page
import LandingPage from 'containers/landingPage/loadable'
import landingTeacherPage from 'containers/landingTeacherPage/loadable'
import landingStudentPage from 'containers/landingStudentPage/loadable'
import FindingCoursePage from 'containers/landingFindingCourse/loadable'

// Live
import TeacherCourseLiveStudio from 'containers/teacherCourseLiveStudio/loadable'
import SchoolCourseLiveStudio from 'layouts/schoolCourseLiveStudio/index'

// import SchoolMandatoryPage from 'containers/schoolMandatoryPage/loadable'

// import SchoolCreateCourseLayout from 'layouts/schoolCreateCourseLayout/index'

import CourseInformationPage from 'containers/courseInformationPage/loadable'

import CourseContentPage from 'containers/courseContentPage'

import CourseInstructorPage from 'containers/courseInstructorPage/loadable'
import CoursePricePage from 'containers/coursePricePage/loadable'

import CourseSettingPage from 'containers/courseSettingPage/loadable'
import ArticleLayout from 'layouts/articleLayout/loadable'
import PrivacyPolicyPage from 'containers/privacyPolicyPage/loadable'
import ServiceAgreementPage from 'containers/serviceAgreementPage/loadable'

import verifyCertificatePage from 'containers/verifyCertificatePage'
import authRoutes from './authRoutes'
import studentRoutes from './studentRoutes'
import teacherRoutes from './teacherRoutes'
import schoolRoutes, {
  coursePreviewRoutes,
  testPreviewRoutes,
  schoolCourseSettingRoutes,
} from './schoolRoutes'
//import CourseSettingSingleTestPage from 'containers/singleTestPage/courseSettingSingleTest'

const schoolCourseLive = {
  path: '/schools/:schoolId/curriculum/manage/:courseId/live/:liveId',
  layout: SchoolCourseLiveStudio,
  routes: [
    {
      exact: true,
      subpath: '/',
      component: TeacherCourseLiveStudio,
    },
  ],
}

const schoolCourseRoutes = {
  path: '/schools/:schoolId/course-draft/:courseId',
  layout: CreateCourseLayout,
  isChildrenFunc: true,
  routes: [
    {
      exact: true,
      subpath: '/',
      render: ({ match }) => <Redirect to={`${match.url}/course-content`} />,
    },
    // {
    //   subpath: '/course-information',
    //   isPrivate: true,
    //   exact: true,
    //   component: CourseInformationPage,
    // },
    {
      subpath: '/course-content',
      isPrivate: true,
      exact: true,
      component: CourseContentPage,
    },
    {
      subpath: '/access',
      isPrivate: true,
      exact: true,
      component: CoursePricePage,
    },
    {
      subpath: '/setting',
      isPrivate: true,
      exact: true,
      component: CourseInformationPage,
    },
    // {
    //   subpath: '/course-instructor',
    //   isPrivate: true,
    //   exact: true,
    //   component: CourseInstructorPage,
    // },
    // {
    //   subpath: '/course-price',
    //   isPrivate: true,
    //   exact: true,
    //   component: CoursePricePage,
    // },
    // {
    //   subpath: '/course-setting',
    //   isPrivate: true,
    //   exact: true,
    //   component: CourseSettingPage,
    // },
  ],
}
// const schoolCourseSingleTestRoutes = {
//   path: '/schools/:schoolId/course-draft/:courseId/single-test',
//   layout: CreateCourseSingleTestLayout,
//   isChildrenFunc: true,
//   routes: [
//     {
//       exact: true,
//       subpath: '/',
//       render: ({ match }) => <Redirect to={`${match.url}/course-content`} />,
//     },
//     {
//       subpath: '/course-content',
//       isPrivate: true,
//       exact: true,
//       component: CourseContentPage,
//     },
//     {
//       subpath: '/access',
//       isPrivate: true,
//       exact: true,
//       component: CoursePricePage,
//     },
//     {
//       subpath: '/setting',
//       isPrivate: true,
//       exact: true,
//       component: CourseSettingSingleTestPage,
//     },
//   ],
// }

const rootRoutes = [
  // {
  //   exact: true,
  //   path: '/landingStudent',
  //   component: landingStudentPage,
  // },
  // {
  //   exact: true,
  //   path: '/landingTeacher',
  //   component: landingTeacherPage,
  // },
  // {
  //   exact: true,
  //   path: '/landingFindingCourse',
  //   component: FindingCoursePage,
  // },

  {
    exact: true,
    path: '/announcement',
    component: AnnouncePage,
  },
  {
    exact: true,
    path: '/callback',
    component: CallbackPage,
  },
  {
    exact: true,
    path: '/redirect',
    component: RedirectPage,
  },
  {
    exact: true,
    path: '/logout',
    component: LogOutPage,
  },
  {
    exact: true,
    path: '/verifyCertificate',
    component: verifyCertificatePage,
  },
  {
    path: '/articles',
    layout: ArticleLayout,
    routes: [
      {
        exact: true,
        subpath: '/services-agreement',
        component: ServiceAgreementPage,
      },
      {
        exact: true,
        subpath: '/privacy-policy',
        component: PrivacyPolicyPage,
      },
    ],
  },
  authRoutes,
  teacherRoutes,
  schoolCourseLive,
  schoolCourseRoutes,
  //schoolCourseSingleTestRoutes,
  schoolCourseSettingRoutes,
  ...coursePreviewRoutes,
  testPreviewRoutes,
  schoolRoutes,
  ...studentRoutes,
]

const generateRoutes = (routes, parentProps) => {
  return (
    <Switch>
      {routes.map(({ routes: childRoutes, layout: Layout, subpath, isPrivate, ...props }) => {
        const CrossRoutes = isPrivate ? PrivateRoute : Route
        const path = subpath ? `${parentProps.path}${subpath}` : props.path
        if (childRoutes) {
          return (
            <CrossRoutes
              key={JSON.stringify(routes)}
              {...props}
              path={path}
              render={(layoutProps) => {
                if (props.isChildrenFunc) {
                  return (
                    <Layout {...layoutProps}>
                      {(otherProps) =>
                        generateRoutes(childRoutes, { ...props, ...otherProps, path })
                      }
                    </Layout>
                  )
                }
                return (
                  <Layout {...layoutProps}>
                    {generateRoutes(childRoutes, { ...props, path })}
                  </Layout>
                )
              }}
            />
          )
        }
        return <CrossRoutes key={JSON.stringify(routes)} {...parentProps} {...props} path={path} />
      })}
      <PrivateRoute path="**" component={NotFoundPage} />
    </Switch>
  )
}

const Routes = ({
  user,
  // alert
  showAlert,
  toggleAlert,
  success,
  error,
  loading,
  title,
  description,
  btnText,
  showBtn,
  stayOpen,

  // confirm
  showConfirm,
  confirmTitle,
  confirmSubtitle,
  onCancel,
  onConfirm,
  confirmText,
  cancelText,
  confirmButton,
  cancelButton,
  onClearConfirm,
  image,
  title2,
  titleNoMargin,
  titleNoMarginId,
  onOk,
}) => {
  useEffect(() => {
    if (user.id) {
      initOnesignal()
    }
  }, [user])
  return (
    <BrowserRouter>
      <Analytics />
      {/* <Gateway /> */}
      {generateRoutes(rootRoutes)}
      {/* <AnnounceModal /> */}
      <Alert
        isOpen={showAlert}
        onRequestClose={() => toggleAlert(false)}
        onOk={() => onOk() || toggleAlert(false)}
        error={error}
        success={success}
        loading={loading}
        title={title}
        description={description}
        btnText={btnText}
        showBtn={showBtn}
        stayOpen={stayOpen}
      />
      <ConfirmModal
        isOpen={showConfirm}
        title={confirmTitle}
        subtitle={confirmSubtitle}
        titleNoMarginId={titleNoMarginId}
        onAfterClose={onClearConfirm}
        image={image}
        title2={title2}
        titleNoMargin={titleNoMargin}
        btn1={
          confirmButton &&
          (confirmText ? (
            <Button primary onClick={onConfirm} style={{ padding: '10px 16px', height: '48px' }}>
              {confirmText}
            </Button>
          ) : (
            <Button
              id="modal.course.enroll.confirmBtn"
              primary
              onClick={onConfirm}
              style={{ padding: '10px 16px', height: '48px' }}
            >
              {confirmText}
            </Button>
          ))
        }
        btn2={
          cancelButton &&
          (cancelText ? (
            <Button whiteBorder style={{ height: '48px' }} onClick={onCancel}>
              {cancelText}
            </Button>
          ) : (
            <Button
              id="modal.course.enroll.cancelBtn"
              whiteBorder
              style={{ height: '48px' }}
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          ))
        }
      />
    </BrowserRouter>
  )
}

export default Routes
