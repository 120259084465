import { init } from '@rematch/core'
import selectPlugin from '@rematch/select'
import createRematchPersist from '@rematch/persist'

import * as models from './models'

const persistPlugin = createRematchPersist({
  whitelist: ['AppUser', 'MyAnswer', 'Language'],
  throttle: 1000,
  version: 1,
})

const store = init({
  models,
  plugins: [selectPlugin(), persistPlugin],
})

export default store
