import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorBoundary from '../../components/errorBoundary'
import Breadcrumb from '../../components/breadcrumb/loadable'
import TabComponent from '../../components/tabComponent/loadable'
import { Heading } from '../../components/styledComponents'
import { menus } from './menus'
import { ACCESS_TOKEN } from '../../constants'

const TabStyle = {
  backgroundColor: 'transparent',
  boxShadow: '0 0 0 0',
  padding: '0',
  borderBottom: '1px solid rgba(194, 194, 194, 0.5)',
}

const Container = styled.div`
  padding: 30px;

  min-height: calc(100vh - 114px);
  padding-bottom: 110px;

  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 80px;
    min-height: calc(100vh - 134px);
  }
`

const Button = styled.a`
  padding: 9px 30px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.primary};
  justify-content: center;
  border: none;
  color: #ffffff;
  font-size: ${({ theme }) => theme['text-sm']};
  width: 150px;
  align-self: start;
  justify-self: end;
  cursor: pointer;
`

const Top = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

class TeacherCourseAssignmentLayout extends Component {
  async componentDidMount() {
    const {
      getAssignmentDetail,
      match: {
        params: { courseId, assignmentId },
      },
    } = this.props
    const testConsole = await getAssignmentDetail({ courseId, assignmentId })
    console.log('testConsole', testConsole)
  }

  onChangeTab = (path) => {
    const { match } = this.props
    this.props.history.push(match.url + path)
  }

  render() {
    const {
      match: {
        params: { assignmentId: id },
      },
    } = this.props
    const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN))
    const link = `${process.env.REACT_APP_API_URL}/Assignments/${id}/report?access_token=${token.id}`

    const {
      children,
      match: {
        params: { courseId },
      },
      assignment: { course, title },
    } = this.props

    const courseName = course ? course.name : ''
    const breadcrumb = [
      {
        label: `${courseName}`,
        link: `/teacher/my-course/courses/${courseId}`,
        isActive: false,
      },
      {
        label: 'Assignment',
        link: `/teacher/my-course/courses/${courseId}/assignment`,
        isMobile: true,
        isActive: false,
      },
      {
        label: `${title}`,
        isActive: true,
      },
    ]

    return (
      <ErrorBoundary>
        <Breadcrumb link="/teacher/my-course" firstLabel="My Course" data={breadcrumb} />
        <Container>
          <Top>
            <Heading>{title}</Heading>
            <Button href={link}>Export to CSV</Button>
          </Top>
          <TabComponent
            style={TabStyle}
            transparent
            tabData={menus}
            activeTab={0}
            onChangeTab={this.onChangeTab}
          />
          {children}
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapState = (state) => ({
  assignment: state.teachingCourse.assignmentDetail,
})

const mapDispatch = (dispatch) => {
  return {
    getAssignmentDetail: dispatch.teachingCourse.getAssignmentDetail,
  }
}

export default connect(mapState, mapDispatch)(TeacherCourseAssignmentLayout)
