import React from 'react'
import styled from 'styled-components'
import FileTextIcon from '../../images/icons/FileText.svg'
import { Typography } from '../../components'
import Icon from '../icon/loadable'
import LectureDone from '../../images/icons/CheckCircle.svg'
import XCircle from '../../images/icons/XCircle.svg'
import { connect } from 'react-redux'
import {
  //Desktop,
  DesktopAndTablet,
  // LargeDesktop,
  //Mobile,
  // MobileAndTablet,
  //SmallMobile,
} from '../../utils/displayResponsive'

const Flex = styled.div`
  display: flex;
  align-items: center;
`
const Logo = styled.div`
  background-color: #ebf4f9;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`

const TeacherCourseEvaluationStudentPageAssignTab = ({ students: [item] }) => {
  if (!item) {
    return null
  }
  const { assignments } = item
  return (
    <div>
      {assignments?.map((value) => {
        return (
          <div key={value.id} style={{ borderBottom: '2px solid #F2F2F2' }}>
            <Flex
              style={{
                justifyContent: 'space-between',
                padding: '16px 12px',
              }}
            >
              <Flex style={{ width: '80%' }}>
                <Logo>
                  <Icon src={FileTextIcon} />
                </Logo>
                <Typography numberOfLines={1} fontSize="text-sm" color="black100">
                  {value.title}
                </Typography>
              </Flex>
              <div>
                {value.hasAnswer === true ? (
                  <Flex style={{ gap: '10px' }}>
                    <DesktopAndTablet>
                      <Typography fontSize="text-sm" color="primary1" bold>
                        Completed
                      </Typography>
                    </DesktopAndTablet>
                    <Icon style={{ width: '32px', height: '32px' }} src={LectureDone} />
                  </Flex>
                ) : (
                  <Flex style={{ gap: '10px' }}>
                    <DesktopAndTablet>
                      <Typography fontSize="text-sm" color="black60" bold>
                        Incompleted
                      </Typography>
                    </DesktopAndTablet>
                    <Icon src={XCircle} style={{ width: '32px', height: '32px' }} />
                  </Flex>
                )}
              </div>
            </Flex>
          </div>
        )
      })}
    </div>
  )
}

const mapState = (state) => ({
  students: state.teachingCourse.students,
})

export default connect(mapState, null)(TeacherCourseEvaluationStudentPageAssignTab)
