// import { Button } from 'components'
import React from 'react'
import ReactModal from 'react-modal'
import ErrorBoundary from '../../components/errorBoundary'
import NotificationPopup from '../../components/notificationPopup/loadable'

const overlay = {
  zIndex: 1000,
  top: 68,
}
const content = {
  borderRadius: 8,
  border: '0px #fff',
  padding: 0,
  overflow: 'hidden',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}

const ModalMobileNoti = ({ isOpen = false, handleTogglePopup, onClose }) => {
  return (
    <ErrorBoundary>
      {isOpen && (
        <div
          style={{
            top: 0,
            left: 0,
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 500,
          }}
          onClick={() => onClose()}
        />
      )}
      <ReactModal isOpen={isOpen} style={{ overlay, content }} onRequestClose={onClose}>
        <NotificationPopup handleToggle={() => handleTogglePopup('showNotiPopup')} />
      </ReactModal>
    </ErrorBoundary>
  )
}

export default ModalMobileNoti
