import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
// import get from 'lodash/get'
// import Breadcrumb from '../../components/breadcrumb/loadable'
// import TabletDetail from './tabletDetail'
import Footer from 'components/Footer'
import TabComponent from './tabComponent/loadable'

// import getImageUrl from '../../utils/getImageUrl'
// import { getFullName } from '../../utils/utils'

import { menus } from './menus'

export const CourseLinkContainer = styled.div`
  border-radius: 6px;
  background-color: #fff;
  padding: 15px 20px 20px;

  .label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px;
    color: ${({ theme }) => theme.dark1};
  }
`

export const Heading = styled.div`
  padding: 0px 30px;
  box-shadow: ${({ hideShadow }) => (hideShadow ? 'none' : '0 5px 5px 0 rgba(0, 0, 0, 0.1)')};
  background: 'white';
  @media (max-width: 1024px) {
    padding: 0px 30px;
  }
  @media (max-width: 768px) {
    padding: 0px 0px;
  }
`

const largeDesktop = window.matchMedia('(max-width: 1024px)')
const smallMobileAndTablet = window.matchMedia('(max-width: 768px)')

const TabStyle = {
  backgroundColor: 'white',
  boxShadow: '0 0 0 0',
  padding: `${
    smallMobileAndTablet.matches ? '0 20px' : largeDesktop.matches ? '0 50px' : '0 50px'
  }`,
  borderBottom: '1px solid rgba(194, 194, 194, 0.5)',
  '@media (max-width: 1024px)': {
    padding: '0 20px',
  },
}

class TeacherCourseDetailLayout extends Component {
  componentDidMount() {
    const { getCourse, match } = this.props
    getCourse(match.params.courseId)
  }

  onChangeTab = (path) => {
    const { match } = this.props
    this.props.history.push(match.url + path)
  }

  // isShowSide = () => {
  //   const { location } = this.props
  //   const result = location.pathname.match(
  //     /\/schools\/(:?[a-z0-9]*?)\/marketplace\/manage\/(:?[a-z0-9]*?)\/(?!(report|evaluation))[a-z0-9?=/-]*$/i
  //   )
  //   const tabSelect = location.pathname.split('/').pop()
  //   if (tabSelect === 'lecture') {
  //     return result !== null
  //   }
  //   return false
  // }

  // isShowPadding = () => {
  //   const { location } = this.props
  //   const result = location.pathname.match(
  //     /\/schools\/(:?[a-z0-9]*?)\/marketplace\/manage\/(:?[a-z0-9]*?)\/(?!(report|evaluation))[a-z0-9?=/-]*$/i
  //   )
  //   return result !== null
  // }

  render() {
    // const {
    //   course,
    //   match: { url },
    // } = this.props

    // const defaultLogo =
    //   'https://dev-api-classwin.dev.witsawa.com/api/Containers/witsawa-classwin/cover/b8dfabc5-d28c-4526-bee8-de52cba937b0.png?width=400&height=400'
    // const data = {
    //   url,
    //   title: course.name,
    //   shortDescription: course.shortDescription,
    //   image: getImageUrl(course.image),
    //   teacherImage: getImageUrl(
    //     get(course, 'customTeacherList[0].profileImage', get(course, 'teachers[0].profileImage')),
    //     `https://via.placeholder.com/${imageWidth}`,
    //     imageWidth,
    //     imageWidth
    //   ),
    //   teacherName: get(
    //     course,
    //     'customTeacherList[0].name',
    //     `${get(course, 'teachers[0].firstname', '')} ${get(course, 'teachers[0].lastname', '')[0]}.`
    //   ),
    //   teacherId: get(course, 'teachers[0].id'),
    //   teacherSlug: get(course, 'teachers[0].slug'),
    //   schoolName: get(course, 'school.name'),
    //   schoolId: get(course, 'school.id'),
    //   schoolSlug: get(course, 'school.slug'),
    //   schoolLogo: getImageUrl(get(course, 'school.logo'), defaultLogo, imageWidth, imageWidth),
    // }
    // const breadcrumb = [
    //   // {
    //   //   label: 'Courses',
    //   //   link: '/teacher/my-course/courses',
    //   //   isMobile: true,
    //   //   isActive: false,
    //   // },
    //   {
    //     label: course.name,
    //     link: '',
    //     isActive: true,
    //   },
    // ]
    return (
      <>
        {/* <Breadcrumb
          firstLabel="My Course"
          link={`/schools/${data.schoolId}/curriculum`}
          data={breadcrumb}
        /> */}
        <Heading style={{ background: 'white' }} hideShadow={false}>
          <TabComponent
            style={TabStyle}
            transparent
            noneBorderBottom
            // tabData={process.env.REACT_APP_SHOW_SETTING_ASSIGNMENT === 'true' ? menus : menus2}
            tabData={menus}
            activeTab={0}
            onChangeTab={this.onChangeTab}
            headBorder={true}
          />
        </Heading>
        {/* <Container showSide={this.isShowSide()} showPadding={this.isShowPadding()}>
          {children}
          {this.isShowSide() && (
            <LargeDesktop>
              <div style={{ gridColumn: '2' }}>
                <SideCourseHeading
                  courseSlug={this.props.course.slug}
                  {...data}
                  isTeacher={isTeacher}
                />
                <Card padding="0px">
                  <CourseLinkContainer>
                    <CourseLink
                      courseLink={`${window.location.host}/courses/${this.props.course.slug}`}
                    />
                  </CourseLinkContainer>
                </Card>
              </div>
            </LargeDesktop>
          )}
        </Container> */}
        <Footer />
      </>
    )
  }
}

const mapState = ({ teachingCourse: { course }, AppUser: { appUser } }) => ({
  course,
  appUser,
})

const mapDispatch = ({ teachingCourse: { getCourse } }) => ({
  getCourse,
})

export default connect(mapState, mapDispatch)(TeacherCourseDetailLayout)
