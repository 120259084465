import styled from 'styled-components'
import theme from '../../../../../theme'

const List = styled.ul`
  display: inline-flex;
  background-color: transparent;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: max-content;
  flex-wrap: wrap;
  gap: 16px;
`

const ListItem = styled.li`
  padding: 10px;
  cursor: pointer;
  color: gray;

  /* :hover {
    color: #fff;
    background-color: ${({ bgColor = theme.grey }) => bgColor};
    svg {
      fill: white;
    }
  } */
`

export { List, ListItem }
