import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'

import ErrorBoundary from '../../components/errorBoundary'
// import AccountGeneralInfoPage from '../accountGeneralInfoPage/loadable'
import MobileMenu from '../../components/mobileMenu/loadable'
import {
  accountsettingMenus,
  teacheraccountsettingMenus,
} from '../../layouts/accountSettingLayout/menus'

// Landing Account-Setting Page
// If Desktop Account-Setting will land on Gerneral Info Page
// If Mobile Account-Setting will land mobileMenu

class AccountMain extends Component {
  render() {
    const currentpath = this.props.location.pathname
    const isTeacherPath = currentpath.startsWith('/teacher')
    const { match } = this.props
    return (
      <ErrorBoundary>
        <LargeDesktop>
          <Redirect to={`${match.url}/general-information`} />
        </LargeDesktop>
        {isTeacherPath ? (
          <MobileAndTablet>
            <MobileMenu menus={teacheraccountsettingMenus} />
          </MobileAndTablet>
        ) : (
          <MobileAndTablet>
            <MobileMenu menus={accountsettingMenus} />
          </MobileAndTablet>
        )}
      </ErrorBoundary>
    )
  }
}

export default AccountMain
