import styled, { css } from 'styled-components'
import theme from '../../theme'

const Line = styled.div`
  border-bottom: 1px solid ${theme.gray3};
  width: 100%;
  padding: 5px 0;

  ${({ width }) => {
    if (width) {
      return css`
        width: ${width};
      `
    }
    return css``
  }}

  ${({ thick }) => {
    if (thick) {
      return css`
        border-bottom: ${thick} solid ${theme.grey};
      `
    }
    return css``
  }}

  ${({ color }) => {
    if (color) {
      return css`
        border-bottom: 1px solid ${color};
      `
    }
    return css``
  }}

  ${({ padding }) => {
    if (padding) {
      return css`
        padding: ${padding};
      `
    }
  }}

${({ margin }) => {
    if (margin) {
      return css`
        margin: ${margin};
      `
    }
  }}
`

export default Line
