import Resource from '../utils/resource'

export default new Resource('/Questions', {
  addQuestionSkill: {
    url: '{id}/questionSkills',
    method: 'post',
  },
  delQuestionSkill: {
    url: '{id}/questionSkills/{questionSkillId}',
    method: 'delete',
  },
})
