import { SchoolAPI } from '../../api'

export const SchoolStudent = {
  state: {
    studentTags: [],
    student: null,
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setStudentTags(state, payload) {
      return {
        ...state,
        studentTags: payload,
      }
    },
    setStudent(state, payload) {
      return {
        ...state,
        student: payload,
      }
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async getStudentTags(payload) {
      try {
        const studentTags = await SchoolAPI.getStudentTags({
          id: payload.schoolId,
          studentId: payload.studentId,
        })
        this.setStudentTags(studentTags)
        return studentTags
      } catch (e) {
        throw e
      }
    },
    async updateStudentTags(payload) {
      try {
        // schoolId studentId body
        const result = await SchoolAPI.updateStudentTags(payload)
        console.log(result)
        dispatch.Alert.success({ title: 'Update Successfully' })
      } catch (e) {
        throw e
      }
    },
    async getStudentById({ schoolId, studentId }) {
      try {
        const result = await SchoolAPI.getStudentDetail({
          id: schoolId,
          fk: studentId,
        })
        this.setStudent(result)
        return result
      } catch (e) {
        throw e
      }
    },
  }),
}
