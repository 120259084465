import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
//import FrameIcon from '../../images/Frame.png'
import Icon from '../icon/loadable'
import styled, { css } from 'styled-components'
import Typography from '../typography'
// import getHeaderTitle from '../../utils/getHeaderTitle'
import Logo from '../../components/logo'
import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'
//import { Circle } from 'rc-progress'
//import ArrowDown from '../../images/icons/ic-down_Primary1.png'
//import { Tooltip } from 'antd'
import BackArrow from '../../images/icons/BackArrow.svg'
import getImageUrl from '../../utils/getImageUrl'
import Avatar from '../../components/avatar/loadable'
import isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import { connect } from 'react-redux'
//import classNames from 'classnames'
import Button from 'components/button'
import { MiniBadge } from '../../components/styledComponents'
import ListIcon from 'images/icons/List.svg'
import NotificationIcon from 'images/icons/BellSimple.svg'
import ModalMobileNoti from './modalMobileNoti'
const ContainerAvatar = styled.div`
  margin-left: 16px;
  cursor: pointer;

  @media (max-width: 426px) {
    margin-left: 14px;
  }
`
const NotiIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`
const NotiContainer = styled.div`
  position: relative;
  /* width: 30px; */
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;

  @media (max-width: 1024px) {
    margin-left: 14px;
    margin-right: 0;
  }
  @media (max-width: 660px) {
    margin-left: 14px;
    margin-right: 0;
  }
`
const IconWithText = styled.div`
  display: flex;
  align-items: center;
  ${({ start }) => {
    if (start) {
      return css`
        justify-content: flex-start;
      `
    }
  }}

  &.mode img {
    filter: invert(40%);
  }
`
const Progress = styled.div`
  padding: 12px 150px;
  background-color: white;
  height: 68px;
  //filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 12px 50px;
  }
  @media (max-width: 768px) {
    padding: 12px 20px;
  }
`

const SearchFormContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  max-width: 400px;
  @media (max-width: 768px) {
    max-width: 768px;
    justify-content: flex-start;
  }
  @media (max-width: 660px) {
    justify-content: space-between;
  }
`

const CourseProgress = styled.div`
  background-color: white;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
  display: flex;
  justify-content: center;
  @media (max-width: 425px) {
    filter: none;
  }
`
// const CircleConteiner = styled.div`
//   height: 32px;
//   width: 32px;
//   display: flex;
//   margin: 0px 10px 0px 0px;
//   .circle-progress-safari {
//     margin-top: -12px;
//   }
// `

// const ArrowDownIcon = styled.img`
//   padding: 0px;
//   height: 16px;
//   width: 16px;
//   margin-left: 10px;
// `

const YourProgress = ({
  // summary: {
  //   summary: { courseProject },
  // },
  title,
  match,
  appUser,
  toggle,
  count,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  //const projectPercent = (courseProject.current * 100) / (courseProject.full || 1)
  const displayWidth = 40
  const imageWidth = displayWidth * 2
  const isTeacherPath = match.url.startsWith('/teacher') || match.url.startsWith('/schools')
  // const header = getHeaderTitle(location.pathname)
  // const progress = (
  //   <span>
  //     complete {courseProject.current} out of {courseProject.full}
  //   </span>
  // )
  // const frameLogo = (value) => {
  //   if (value === 100) {
  //     return <Icon src={FrameIcon} style={{ height: '32px', width: '32px', margin: '10px' }} />
  //   }
  //   return (
  //     <CircleConteiner>
  //       <Circle
  //         className={classNames({
  //           'circle-progress-safari': !navigator.userAgent.includes('Chrome'),
  //         })}
  //         percent={projectPercent}
  //         strokeColor="#253E87"
  //         strokeWidth="16"
  //         trailWidth={16}
  //       />
  //     </CircleConteiner>
  //   )
  // }
  const renderLogo = () => {
    return (
      <>
        <LargeDesktop>
          <Link to="/browse" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Logo
              type="secondary4"
              width="40"
              height="40"
              //margin="0px 20px 0px 0px"
              alt="classwin-logo"
            />
          </Link>
        </LargeDesktop>
        <MobileAndTablet>
          <Link to="/browse">
            <Logo
              type="secondary4"
              width="40"
              height="40"
              //margin="0px 7px 0px 0px"
              alt="classwin-logo"
            />
          </Link>
        </MobileAndTablet>
      </>
    )
  }

  return (
    <>
      <LargeDesktop>
        <CourseProgress>
          <Progress>
            <IconWithText style={{ width: '40%' }}>
              <Link to="/my-course/courses" style={{ width: '60%' }}>
                <div style={{ display: 'flex' }}>
                  <Icon
                    src={BackArrow}
                    style={{ height: '16px', width: '16px', marginTop: '5px' }}
                  />
                  <Typography
                    numberOfLines={1}
                    color="primary1"
                    style={{ fontSize: '16px', padding: '0px 10px' }}
                  >
                    {title}
                  </Typography>
                </div>
              </Link>
            </IconWithText>
            <IconWithText style={{ width: '20%', display: 'flex', justifyContent: 'center' }}>
              {match.url.startsWith('/teacher') && (
                <SearchFormContainer
                  style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                >
                  {renderLogo()}
                </SearchFormContainer>
              )}
              {isTeacherPath ? (
                <SearchFormContainer
                  style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                >
                  {renderLogo()}
                </SearchFormContainer>
              ) : (
                <SearchFormContainer
                  style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                >
                  {renderLogo()}
                </SearchFormContainer>
              )}
              {/* <Link to="/my-course">
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <Icon src={BackArrow} style={{ height: '16px', width: '16px', marginTop: '5px' }} />
              <Typography
                numberOfLines={1}
                color="primary1"
                style={{ fontSize: '16px', padding: '0px 10px' }}
              >
                {title}
              </Typography>
            </div>
          </Link> */}
            </IconWithText>
            <IconWithText style={{ width: '40%' }}>
              {/* {frameLogo(projectPercent)}
                <DesktopAndTablet>
                  <Typography id="Your.Progress" color="primary1" style={{ width: '90px' }}>
                    Your Progress
                  </Typography>
                </DesktopAndTablet> */}
              {/* <ArrowDownIcon src={ArrowDown} /> */}
              {!isEmpty(appUser) && (
                <ContainerAvatar
                  onClick={() => toggle(true)}
                  style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Avatar
                    width={displayWidth}
                    src={getImageUrl(
                      _get(appUser, 'profileImage'),
                      `https://via.placeholder.com/${imageWidth}`,
                      imageWidth,
                      imageWidth
                    )}
                    data-intro="profileMenu"
                  />
                </ContainerAvatar>
              )}
            </IconWithText>
          </Progress>
        </CourseProgress>
      </LargeDesktop>
      <MobileAndTablet>
        <CourseProgress>
          <Progress>
            <div style={{ width: '40%' }}>
              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: '0px',
                }}
                onClick={() => toggle(true)}
                menu
              >
                <NotiIcon src={ListIcon} alt="notification-icon"></NotiIcon>
              </Button>
            </div>
            <div style={{ width: '20%' }}>
              <Link
                to="/browse"
                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              >
                <Logo type="secondary4" margin="0px 0px 0px 0px" alt="classwin-logo" width="40" />
              </Link>
            </div>
            <div
              style={{
                width: '40%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {/* {frameLogo(projectPercent)} */}
              <NotiContainer onClick={() => setIsOpenModal(true)}>
                <NotiIcon
                  src={NotificationIcon}
                  alt="notification-icon"
                  // style={{ marginLeft: !isTeacher ? 15 : 0 }}
                />
                {count > 0 && <MiniBadge margin="0 0 0 10px">{count}</MiniBadge>}
              </NotiContainer>
              <ModalMobileNoti isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
            </div>
          </Progress>
        </CourseProgress>
      </MobileAndTablet>
    </>
  )
}

YourProgress.defaultProps = {
  title: 'Course Title',
  summary: {
    assignments: [],
    lectures: [],
    summary: {
      courseProject: { current: 0, full: 0 },
      courseAssignment: { current: 0, full: 0 },
    },
  },
}

const mapState = (state) => ({
  appUser: state.AppUser.appUser,
  count: state.Notification.count,
})

const mapDispatch = (dispatch) => {
  const {
    AppUser: { fetchMe, updateProfile },
  } = dispatch
  return {
    fetchMe,
    updateProfile,
    toggle: dispatch.Sidebar.toggle,
  }
}

export default connect(mapState, mapDispatch)(withRouter(YourProgress))
