import React from 'react'
import { Container } from './style'
import useRipple from 'useripple'
import './styles.css'

const SimpleRippleButton = (props) => {
  const { children = null, classes = '', onClickHandler = () => {}, btn, background } = props
  const [addRipple, ripples] = useRipple({ background: background || 'rgba(95, 99, 104, 0.3)' })

  return (
    <Container
      className={`${btn ? 'btn' : ''}`}
      onClick={(e) => {
        addRipple(e)
        onClickHandler()
      }}
      {...props}
    >
      {ripples}
      <div className={'ripple' + classes}>{children}</div>
    </Container>
  )
}

export default SimpleRippleButton
