import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import UploadComponent from '../uploadComponent'
import { Typography } from '../index'
import { Content, UploadImageStyle, ImageBox } from './style'
import getImageUrl from '../../utils/getImageUrl'
import PlusIcon from '../../images/ic-plus-blue-24-px.svg'

const displayWidth = 80
const imageWidth = displayWidth * 2

const CircleUploadImage = ({ label, value, onChange, name, detail, height }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClickView = () => {
    setIsOpen(true)
  }

  const renderLightbox = () => {
    return <Lightbox mainSrc={getImageUrl(value)} onCloseRequest={() => setIsOpen(false)} />
  }

  return (
    <Content value={value}>
      {isOpen && renderLightbox()}
      <Typography color="#707070">{label}</Typography>
      <UploadComponent afterUpload={(image) => onChange(name, image.name)}>
        <UploadImageStyle>
          <ImageBox height={height}>
            {value ? (
              <img
                src={getImageUrl(
                  value,
                  `https://via.placeholder.com/${imageWidth}`,
                  imageWidth,
                  imageWidth
                )}
                alt=""
                style={{ height: height - 30, objectFit: 'cover' }}
              />
            ) : (
              <img className="icon" src={PlusIcon} alt="" />
            )}
          </ImageBox>
          <Typography color="#8C8B8B">{detail}</Typography>
        </UploadImageStyle>
      </UploadComponent>
      <Typography id="label.fullimage" className="image-view" color="#5788FC" onClick={onClickView}>
        Click to view full image
      </Typography>
    </Content>
  )
}

CircleUploadImage.defaultProps = {
  label: '',
  detail: 'Click to add photo or drag photo to input',
  onChange: () => null,
}

export default CircleUploadImage
