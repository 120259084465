/* eslint-disable no-unused-vars */
import React from 'react'
import { Typography } from 'components'
import { withRouter } from 'react-router-dom'
import Icon from '../../../../components/icon/loadable'
// icon dark
import IconDarkQuiz from '../../../../images/icons/DarkIcon/Dark-pen.svg'
import IconDarkPDF from '../../../../images/icons/DarkIcon/Dark-pdf.svg'
import IconDarkLecture from '../../../../images/icons/DarkIcon/Dark-lecture.svg'
import IconDarkVDO from '../../../../images/icons/DarkIcon/Dark-VDO.svg'
import IconDarkUploadVDO from '../../../../images/icons/DarkIcon/Dark-uploadVDO.svg'
// icon white
import IconWhiteQuiz from '../../../../images/icons/DarkIcon/White-pen.svg'
import IconWhitePDF from '../../../../images/icons/DarkIcon/White-pdf.svg'
import IconWhiteLecture from '../../../../images/icons/DarkIcon/White-lecture.svg'
import IconWhiteVDO from '../../../../images/icons/DarkIcon/White-VDO.svg'
import IconWhiteUploadVDO from '../../../../images/icons/DarkIcon/White-uploadVDO.svg'
function LectureItemDetail(props) {
  const {
    history,
    match,
    name,
    lectureType,
    type,
    lectureId,
    id,
    onNext,
    checkCompleted,
    isTeacherPath,
    handleLectureComplete,
  } = props
  const handleClickToLecture = () => {
    history.push(`/my-course/courses/${match?.params?.courseId}/lecture/${id}`)
    onNext
  }

  let IconDark =
    type === 'quiz'
      ? IconDarkQuiz
      : lectureType === 'browse'
      ? IconDarkUploadVDO
      : lectureType === 'videoLink'
      ? IconDarkVDO
      : lectureType === 'lectureSlide'
      ? IconDarkPDF
      : lectureType === 'paragraph'
      ? IconDarkLecture
      : IconDarkLecture

  let IconWhite =
    type === 'quiz'
      ? IconWhiteQuiz
      : lectureType === 'browse'
      ? IconWhiteUploadVDO
      : lectureType === 'videoLink'
      ? IconWhiteVDO
      : lectureType === 'lectureSlide'
      ? IconWhitePDF
      : lectureType === 'paragraph'
      ? IconWhiteLecture
      : IconWhiteLecture
  const focus = match?.params?.lectureId === id
  const focusColor = focus ? '#fff' : '#2C2B30'
  return (
    <div
      style={{
        display: ' flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        //justifyContent: 'flex-start',
        backgroundColor: '#2C2B30',
        width: '140px',
        minWidth: '140px',
        maxWidth: '140px',
        height: '84px',
        padding: '8px 12px 8px 12px',
        border: `1px solid ${focusColor}`,
        borderRadius: '8px',
        cursor: 'pointer',
      }}
      onClick={() => handleClickToLecture()}
    >
      <Typography
        color={focus ? 'white' : 'dark2'}
        numberOfLines={2}
        style={{ cursor: 'pointer' }}
        fontSize={'text-xs'}
      >
        {name}
      </Typography>
      <div
        style={{
          display: ' flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: '4px',
        }}
      >
        <div>
          <Icon src={focus ? IconWhite : IconDark} size="14" />
        </div>
        <div>
          <Typography
            color={focus ? 'white' : 'dark2'}
            numberOfLines={1}
            style={{ cursor: 'pointer' }}
          >
            {type === 'quiz'
              ? 'แบบทดสอบ'
              : lectureType === 'browse'
              ? 'อัพโหลดวีดีโอ'
              : lectureType === 'videoLink'
              ? 'Link วีดีโอ'
              : lectureType === 'lectureSlide'
              ? 'ไฟล์ PDF'
              : lectureType === 'paragraph'
              ? 'เนื้อหา'
              : 'เนื้อหา'}
          </Typography>
          {/* <Typography
            color={focus ? 'white' : 'dark2'}
            numberOfLines={1}
            style={{ cursor: 'pointer' }}
          >
            30 min
          </Typography> */}
        </div>
      </div>
    </div>
  )
}

export default withRouter(LectureItemDetail)
