import React, { useState, Fragment } from 'react'
import _ from 'lodash'
import PageHeading from '../../components/schoolMemberHeading/loadable'
import { tabs } from './tabs'

const SchoolMemberLayout = ({ match, location, history, children }) => {
  const initialPath = `/${location.pathname.split('/').slice(-1)[0]}`
  const [activePath, setActivePath] = useState(initialPath)

  const onChangeTab = (path) => {
    setActivePath(path)
    history.push(match.url + path)
  }

  return (
    <Fragment>
      <PageHeading
        tabData={tabs}
        onChangeTab={onChangeTab}
        hideShadow={_.includes([], activePath)}
      />
      {children}
    </Fragment>
  )
}

export default SchoolMemberLayout
