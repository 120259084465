import { UserAPI, CoursePaymentAPI, CourseAPI } from '../../api'

export const Enrollment = {
  state: {
    enrollments: [],
    enrollmentSuccess: false,
    enrollment: {},
    coursePayment: {},
    qrPromptpay: {},
    promotion: {},
    coursePriceDetail: {},
    eWithholdingTax: {},
    prerequisiteResult: {},
  },
  reducers: {
    setEnrollmentSuccess(state, payload) {
      return {
        ...state,
        enrollmentSuccess: payload,
        enrollment: payload ? state.enrollment : {},
      }
    },
    setEnrollment(state, payload) {
      return {
        ...state,
        enrollment: payload,
      }
    },
    setEnrollments(state, payload) {
      return {
        ...state,
        enrollments: payload,
      }
    },
    setCoursePayment(state, payload) {
      return {
        ...state,
        coursePayment: payload,
      }
    },
    setQrPromptpay(state, payload) {
      return {
        ...state,
        qrPromptpay: payload,
      }
    },
    setPromotion(state, payload) {
      return {
        ...state,
        promotion: payload,
      }
    },
    setCoursePriceDetailt(state, payload) {
      return {
        ...state,
        coursePriceDetail: payload,
      }
    },
    setEWithholdingTax(state, payload) {
      return {
        ...state,
        eWithholdingTax: payload,
      }
    },
    setPrerequisiteResult(state, payload) {
      return {
        ...state,
        prerequisiteResult: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async enrollCourse(data) {
      try {
        const {
          courseId,
          invitedCode,
          promoCode,
          billingAddress,
          provider,
          card,
          chargeId,
          includeMe = true,
          quantity = 1,
          isFree,
        } = data
        const enrollment = await UserAPI.enrollCourse({
          courseId,
          invitedCode,
          promoCode,
          billingAddress,
          provider: isFree ? 'cash' : provider,
          card,
          chargeId,
          includeMe,
          quantity,
        })
        this.setEnrollment(enrollment)
        return true
      } catch (e) {
        this.setEnrollmentSuccess(false)
        if (e && e.response) {
          if (e.response.message === 'Expired') {
            dispatch.Alert.error({ title: 'This code has expired.' })
          } else {
            dispatch.Alert.error({ title: 'This code is invalid.' })
          }
        }
        throw e
      }
    },
    // async enrollCourseByLink(data) {
    //   try {
    //     const { courseId, isFree, code, isApproved } = data
    //     let enrollment
    //     if (isFree) {
    //       enrollment = await UserAPI.enrollCourseByLink({
    //         courseId,
    //         provider: 'cash',
    //         code,
    //         isApproved,
    //       })
    //     }

    //     this.setEnrollment(enrollment)
    //     return true
    //   } catch (e) {
    //     this.setEnrollmentSuccess(false)
    //     if (e.response.message === 'Expired')
    //       dispatch.Alert.error({ title: 'This code has expired.' })
    //     else dispatch.Alert.error({ title: 'This code is invalid.' })

    //     throw e
    //   }
    // },
    async getCourseEnrollment({ courseId }) {
      try {
        const filter = JSON.stringify({
          where: {
            // isApproved: true,
            // isPaid: true,
            courseId,
          },
        })
        const data = await UserAPI.getEnrollments({ filter })
        this.setEnrollments(data)
      } catch (e) {
        throw e
      }
    },
    async getCoursePaymentStatus({ courseId }) {
      try {
        const data = await UserAPI.checkCoursePaymentStatus({ courseId })
        this.setCoursePayment(data || {})
        const isSuccess =
          !!data?.isSuccess ||
          (data?.provider === 'omiseCard' && !!data?.data?.authorized && !data?.data?.capture)
        this.setEnrollmentSuccess(isSuccess)
        return data
      } catch (e) {
        throw e
      }
    },
    async generateQrPromptpay({ courseId, quantity, billingAddress }) {
      try {
        const data = await CoursePaymentAPI.generateQrPromptpay({
          courseId,
          quantity,
          billingAddress,
        })
        this.setQrPromptpay(data)
        return data
      } catch (e) {
        throw e
      }
    },
    async checkPromoCode({ courseId, promoCode, price }) {
      try {
        const result = await CourseAPI.checkPromoCode({ id: courseId, promoCode, price })
        this.setPromotion(result)
        return result
      } catch (e) {
        throw e
      }
    },
    async getCoursePriceDetail({ price, tax, discount }) {
      try {
        const result = await CoursePaymentAPI.getCoursePriceDetail({ price, tax, discount })
        this.setCoursePriceDetailt(result)
      } catch (e) {
        throw e
      }
    },
    async getEWithholdingTax({ price, tax, discount }) {
      try {
        const result = await CoursePaymentAPI.getCoursePriceDetail({ price, tax, discount })
        this.setEWithholdingTax(result)
      } catch (e) {
        throw e
      }
    },
    async checkPrerequisite({ courseId }) {
      try {
        const result = await UserAPI.checkPrerequisite({
          courseId,
        })

        this.setPrerequisiteResult(result)
      } catch (e) {
        throw e
      }
    },
  }),
}
