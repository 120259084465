import { SkillAPI } from '../../api'

export const Skill = {
  state: {
    skill: [],
  },
  reducers: {
    setSkill(state, payload) {
      return {
        ...state,
        skill: payload,
      }
    },
  },
  effects: () => ({
    async getSkill() {
      try {
        const result = await SkillAPI.find()
        this.setSkill(result)
      } catch (error) {
        throw error
      }
    },
  }),
}
