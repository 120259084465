import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Typography from '../typography'
import getImageUrl from '../../utils/getImageUrl'
import styled, { css } from 'styled-components'
import Icon from '../icon/loadable'
import ClassIcon from '../../images/classroom-01.svg'
import ClockIcon from '../../images/Clock.svg'
import FileTextIcon from '../../images/icons/FileText.svg'
import OnlineIcon from '../../images/Cloud.svg'
//import ShareIcon from '../../images/icons/Share.svg'
import Avatar from '../avatar/loadable'
import Share from '../../containers/courseDetail/Share'
import _get from 'lodash/get'
// import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'
import { Line } from 'rc-progress'
import ImageRatio from 'components/ImageRatio'

const CardTitle = styled.div`
  /* height: 250px; */

  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  box-shadow: rgb(0 0 0 / 10%) 0px 5px 5px 0px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
    margin-top: 0px;
    border-radius: 0px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

// const CourseImage = styled.div`
//   min-width: 120px;
//   background-image: url(${(props) => props.src});
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
//   border-top-left-radius: 4px;
//   border-top-right-radius: 4px;
//   object-fit: cover;
//   width: 100%;
//   height: 100%;
//   //height: 250px;
//   /* ${({ width }) => css`
//     width: ${width};
//   `} */
//   // margin-bottom: -2px;
//   // @media (max-width: 768px) {
//   //   margin-bottom: -7px;
//   // }
// `

const CourseText = styled.div`
  margin-left: 20px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
  }
`

const CourseTitle = styled(Typography)`
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* @media (max-width: 1024px) {
    max-width: 350px;
  } */
  @media (max-width: 768px) {
    max-width: 620px;
  }
`
const CourseDes = styled(Typography)`
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  color: black;
  font-size: 14px;
`
const IconWithText = styled.div`
  display: flex;
  align-items: center;
  //width: 100px;
  color: ${(props) => (props.primary ? props.theme.primary2 : props.theme.grey)};
  font-size: ${({ theme }) => theme['text-xs']};
  img {
    margin-right: 5px;
  }
  ${({ start }) => {
    if (start) {
      return css`
        justify-content: flex-start;
      `
    }
  }}
  .after {
    // position: relative;
    // padding: 0 16px 0 0;
  }
  .after:after {
    content: '';
    position: absolute;
    height: 15px;
    background-color: ${(props) => props.theme.gray6};
    top: 3px;
    right: 8px;
  }

  &.mode img {
    filter: invert(40%);
  }
`
const Bottom = styled.div`
  display: flex;
  /* gap: 24px; */
  align-items: center;

  .item1 {
    display: flex;
    margin: 16px 24px 0px 0px;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    align-items: center;
    .item1 {
      margin: 16px 12px 0px 0px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 425px) {
    justify-content: space-between;

    .item1 {
      margin-right: 15px;
    }
  }
  @media (max-width: 375px) {
    .item1 {
      margin-right: 15px;
    }
  }
`
const Hilight = styled(Typography)`
  ${({ schoolLink }) => {
    if (schoolLink) {
      return css`
        cursor: pointer;
      `
    }
    return css``
  }}
  min-width: 120px;
  max-width: 120px;
  padding-left: 10px;

  &.school {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (max-width: 500px) {
    min-width: 100px;
  }

  @media (max-width: 400px) {
    min-width: 80px;
  }
`
const Image = styled.div`
  height: 100%;
  margin-right: 20px;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0px;
  }
`
const LineCon = styled.div`
  margin-top: -2px;
  @media (max-width: 768px) {
    margin-top: -7px;
  }
`
const Tag = styled.div`
  width: fit-content;
  height: 32px;
  padding: 6px 15.5px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  background-color: #ebf4f9;
  margin-bottom: -40px;
  left: 10px;
  position: relative;
  bottom: 57px;
  color: #3d4453;
  font-size: 12px;
`

const CourseTitleHeading = ({
  title,
  shortDescription,
  image,
  type,
  estimateTimeUsageHr,
  estimateTimeUsageMin,
  teacherImage,
  teacherName,
  teacherId = undefined,
  schoolLogo,
  schoolName,
  lectureNum: lNumb,
  courseSlug,
  //locale,
  category,
  //courseList,
  //name,
  summary: {
    summary: { courseProject },
  },
  ...props
}) => {
  const checkIcon = (type) => {
    if (type === 'online') {
      return <Icon src={OnlineIcon} alt="online-icon" />
    }
    if (type === 'classroom') {
      return <Icon src={ClassIcon} alt="online-icon" />
    }
  }

  const courseTypes = {
    online: 'Online',
    classroom: 'Classroom',
  }

  const handleClickToProfile = () => {
    const { history, teacherSlug } = props
    history.push(`/users/${teacherSlug}`)
  }

  const handleClickToSchool = () => {
    const { history, schoolSlug } = props

    history.push(`/${schoolSlug}`)
  }
  //const { courseSlug } = match.params
  const projectPercent = (courseProject.current * 100) / (courseProject.full || 1)
  const lectureNum =
    lNumb ||
    _get(props, 'publishedSubject.data.lectures', []).filter((lecture) => lecture.type === '')
      .length

  // const courseImageWidth = (1000 * 250) / 524
  // console.log(courseImageWidth)
  return (
    <CardTitle>
      <Image>
        <ImageRatio
          src={getImageUrl(image, `https://via.placeholder.com/1000/524`)}
          ratio={524 / 1000}
        />
        <LineCon>
          <Line
            className="Line-progress"
            percent={projectPercent}
            strokeColor="#2086C6"
            strokeWidth="2"
            trailWidth={2}
            trailColor="#CBD5E1"
            strokeLinecap="square"
            style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
            gapDegree={0}
          />
        </LineCon>
        <Tag> {category?.name} </Tag>
      </Image>
      <CourseText>
        <div>
          <CourseTitle
            color="black100"
            fontSize="text-xl"
            bold
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Typography numberOfLines={2} color="black100" fontSize="text-lg" bold>
              {title}
            </Typography>

            <Share courseSlug={courseSlug} />
          </CourseTitle>

          <CourseDes>
            <Typography numberOfLines={2} color="black100">
              {shortDescription}
            </Typography>
          </CourseDes>
          <div style={{ marginTop: '15px', display: 'flex' }}>
            <IconWithText primary style={{ marginRight: '15px' }}>
              <Icon src={ClockIcon} />
              <Typography
                id="card.course.total.hour"
                values={{ hourCount: estimateTimeUsageHr, minCount: estimateTimeUsageMin }}
                color="black60"
                fontSize="text-xs"
              >
                {estimateTimeUsageHr} ชั่วโมง {estimateTimeUsageMin} นาที
              </Typography>
            </IconWithText>
            <IconWithText primary style={{ marginRight: '15px' }}>
              <Icon src={FileTextIcon} />
              <Typography
                label2
                className="after"
                color="black60"
                id="card.course.total.lecture"
                fontSize="text-xs"
                values={{ lectureCount: lectureNum }}
              >
                {lectureNum} Lectures
              </Typography>
            </IconWithText>
            <IconWithText primary style={{ marginRight: '15px' }}>
              {checkIcon(type)}
              <Typography id={`card.course.type.${type}`} color="black60" fontSize="text-xs">
                {courseTypes[type]}
              </Typography>
            </IconWithText>
          </div>
        </div>
        <Bottom>
          <div className="item1">
            <Avatar size="small" src={teacherImage} marginRight="10"></Avatar>
            <div>
              <Typography
                id="label.instructor"
                padding="4px 0px 0px 10px"
                color="primary1"
                bold
                fontSize="text-sm"
              >
                Instructor
              </Typography>
              <Hilight
                color="black100"
                onClick={teacherId === undefined ? null : handleClickToProfile}
                schoolLink={teacherId !== undefined}
                fontSize="text-xs"
                numberOfLines={1}
              >
                {teacherName}
              </Hilight>
            </div>
          </div>
          <div className="item1">
            <Avatar size="small" src={schoolLogo} marginRight="10"></Avatar>
            <div>
              <Typography
                id="label.academy"
                padding="4px 0px 0px 10px"
                color="primary1"
                bold
                fontSize="text-sm"
              >
                Academy
              </Typography>
              <Hilight
                className="school"
                color="black100"
                onClick={handleClickToSchool}
                schoolLink
                fontSize="text-xs"
              >
                {schoolName}
              </Hilight>
            </div>
          </div>
        </Bottom>
      </CourseText>
    </CardTitle>
  )
}

CourseTitleHeading.defaultProps = {
  title: 'Course Title',
  summary: {
    assignments: [],
    lectures: [],
    summary: {
      courseProject: { current: 0, full: 0 },
      courseAssignment: { current: 0, full: 0 },
    },
  },
  shortDescription: '',
  image: 'https://via.placeholder.com/270x180',
  teacherImage: 'https://via.placeholder.com/40',
  teacherName: 'Nan BrownBrownBrown',
  schoolName: 'AA School',
  schoolLogo:
    'https://dev-api-classwin.dev.witsawa.com/api/Containers/witsawa-classwin/cover/b8dfabc5-d28c-4526-bee8-de52cba937b0.png?width=400&height=400',
}

CourseTitleHeading.propTypes = {
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  image: PropTypes.string,
  teacherImage: PropTypes.string,
  teacherName: PropTypes.string,
  schoolName: PropTypes.string,
  estimateTimeUsageHr: PropTypes.number,
  estimateTimeUsageMin: PropTypes.number,
}

const mapState = (state) => {
  return {
    Course: state.courseList,
    permissions: state.Permissions.permissions,
  }
}
const mapDispatch = (dispatch) => {
  return {
    getPermissions: dispatch.Permissions.getPermissions,
  }
}

export default connect(mapState, mapDispatch)(withRouter(CourseTitleHeading))
