import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Breadcrumb from '../../components/breadcrumb/loadable'
import { Footer } from '../../components/styledComponents'
import TabComponent from '../../components/tabComponent/loadable'
import Button from '../../components/button'
import { menus } from './menus'

const Container = styled.div`
  padding: 10px 35px 35px;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }

  @media (max-width: 480px) {
    padding: 0px;
  }

  margin-bottom: 20px;
`

const ButtonGroup = styled.div`
  width: 50px;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  grid-column-gap: ${(props) => (props.day ? '5px' : '10px')};
  grid-row-gap: 10px;
  button {
    width: 120px;
    & + button {
      // margin-left: 10px;
    }
  }

  .left-auto {
    margin-left: auto;
  }

  @media (max-width: 1440px) {
    grid-template-columns: ${(props) => props.day && 'auto auto auto auto'};
  }

  @media (max-width: 1240px) {
    grid-template-columns: ${(props) => (props.day ? 'auto auto auto auto' : 'auto auto')};
  }

  @media (max-width: 767px) {
    button {
      width: 100px;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: ${(props) => props.day && 'auto auto auto'};
  }

  @media (max-width: 375px) {
    grid-template-columns: ${(props) => props.day && 'auto auto'};
  }
`

const TabStyle = {
  backgroundColor: 'transparent',
  boxShadow: '0 0 0 0',
  padding: '0',
  borderBottom: '1px solid rgba(194, 194, 194, 0.5)',
}

class CurriculumDetailEditLayout extends Component {
  componentDidMount() {
    const { getMyCourseById, match } = this.props
    getMyCourseById(match.params.courseId)
  }

  schemas = () => {
    const form = Yup.object().shape({
      name: Yup.string().required('Required'),
      description: Yup.string().notRequired(),
      shortDescription: Yup.string().notRequired(),
      type: Yup.string().required('Required'),
      recommendedStudent: Yup.array().required('Required'),
    })
    return form
  }

  onChangeTab = (path) => {
    const { match } = this.props
    this.props.history.push(match.url + path)
  }

  onClickBack = () => {
    const { schoolId, courseId } = this.props.match.params
    this.props.history.replace(`/schools/${schoolId}/curriculum-detail/${courseId}`)
  }

  handleSubmit = async (values) => {
    const { match, updateCourseById } = this.props
    await updateCourseById({ id: match.params.courseId, ...values })
    this.componentDidMount()
  }

  renderStatus = (publicMode) => {
    let status = null
    switch (publicMode) {
      case 'PUBLIC':
        status = 'public'
        break
      case 'CODE':
        status = 'private'
        break
      default:
        status = 'hidden'
        break
    }
    return status
  }

  render() {
    const {
      children,
      match,
      schoolCourse: {
        name,
        shortDescription,
        description,
        type,
        recommendedStudent,
        isMandatory,
        autoApprove,
        maxStudent,
        price,
        publishStartDate,
        publishEndDate,
        registerStartDate,
        registerEndDate,
        publicMode,
      },
    } = this.props
    const url = `/schools/${match.params.schoolId}/curriculum`
    return (
      <>
        <Breadcrumb
          firstLabel="Curriculum"
          link={`${url}/all-course`}
          data={[
            { label: name, link: `${url}-detail/${match.params.courseId}` },
            { label: 'Course setting', isActive: true },
          ]}
        />
        <Container>
          {/* <h1>{name}</h1> */}
          <TabComponent
            style={TabStyle}
            transparent
            tabData={menus}
            activeTab={0}
            onChangeTab={this.onChangeTab}
          />
          <Formik
            initialValues={{
              name,
              shortDescription,
              description,
              type,
              recommendedStudent,
              isMandatory,
              price,
              maxStudent,
              autoApprove,
              publishStartDate: new Date(publishStartDate),
              publishEndDate: new Date(publishEndDate),
              registerStartDate: new Date(registerStartDate),
              registerEndDate: new Date(registerEndDate),
              publishRequest: publicMode && this.renderStatus(publicMode),
            }}
            enableReinitialize
            onSubmit={this.handleSubmit}
            validationSchema={() => this.schemas()}
            render={(props) => (
              <form onSubmit={props.handleSubmit}>
                {children}
                <Footer style={{ position: 'fixed' }}>
                  <ButtonGroup>
                    <Button secondary type="button" onClick={this.onClickBack}>
                      Cancel
                    </Button>
                    <Button type="submit" primary>
                      Save
                    </Button>
                  </ButtonGroup>
                </Footer>
              </form>
            )}
          />
        </Container>
      </>
    )
  }
}

const mapState = ({ SchoolCourse: { schoolCourse } }) => ({ schoolCourse })

const mapDispatch = ({ SchoolCourse: { getMyCourseById }, Course: { updateCourseById } }) => ({
  getMyCourseById,
  updateCourseById,
})

export default connect(mapState, mapDispatch)(CurriculumDetailEditLayout)
