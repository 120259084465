import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Field } from 'formik'

import ErrorBoundary from 'components/errorBoundary'
import FieldInput from 'containers/courseInformationPage/FieldInput'
import { Typography, Container, Input, Label } from 'components'

const Footer = styled((props) => <Container {...props} />)`
  grid-template-columns: repeat(1, minmax(100px, 620px));
`

const StyledTypography = styled((props) => <Typography {...props} />)`
  margin: 0px 0px 20px 0px;

  @media (max-width: 768px) {
    margin: 0px 30px 20px 0px;
  }

  @media (max-width: 576px) {
    margin: 0px 30px 20px 0px;
  }
  @media (max-width: 375px) {
    margin: 0px 30px 20px 0px;
    font-size: ${({ theme }) => theme['text-base']};
  }
`
const Wrapper = styled.div`
  position: relative;
  //max-height: 410px;
  //max-width: 620px;
  max-height: 100%;
  max-width: 100%;
  width: 600px;
  overflow: hidden;
  @media (max-width: 768px) {
    max-height: 360px;
    width: 450px;
  }
  @media (max-width: 576px) {
    max-height: 487px;
    max-width: 407px;
    width: 275px;
  }
  @media (max-width: 576px) and (min-width: 375px) {
    max-height: 525px;
  }
  @media (max-width: 375px) {
    max-height: 454px;
    //max-width: 279px;
    width: 250px;
  }
`

class AddCourseInformation extends Component {
  componentDidMount() {
    const { getCourseCategories } = this.props
    getCourseCategories()
  }

  categories = () => {
    const { courseCategories } = this.props
    return this.reformat(courseCategories)
  }

  reformat = (data) => data.map(({ name, id }) => ({ id, value: name, label: name }))

  subcategories = (categoryId) => {
    const { courseCategories } = this.props
    const { subcategories } = courseCategories.find((categories) => categories.id === categoryId)
    return this.reformat(subcategories)
  }

  render() {
    const {
      formik: { errors, touched, handleChange },
      locale,
    } = this.props

    return (
      <ErrorBoundary>
        <Wrapper>
          <StyledTypography id="courseinfo.headtitle" h3 color="primary1">
            Course Information
          </StyledTypography>
          <div style={{ marginBottom: 30 }}>
            <Footer column="1">
              <Field
                name="title"
                component={FieldInput}
                label="Title"
                colorLabel="primary1"
                placeholder={locale === 'en' ? 'Input title' : 'กรอกชื่อคอร์ส'}
                isRequired
                id="courseinfo.title"
                inputId="courseinfo.title.input"
              />

              <Field name="shortDescription">
                {({
                  field,
                  form,
                  field: { value }, // { name, value, onChange, onBlur }
                }) => (
                  <div>
                    <FieldInput
                      type="textareaDesc"
                      height="96px"
                      isRequired
                      id="courseinfo.shortdesc"
                      inputId="courseInfo.shortdesc.input"
                      label="Short Description"
                      colorLabel={'primary1'}
                      name="shortDescription"
                      placeholder={
                        locale === 'en' ? 'Input short description' : 'กรอกคำอธิบายสั้น ๆ'
                      }
                      maxLength="100"
                      value={value}
                      counter
                      status={errors.shortDescription && 'error'}
                      messageStatus={errors.shortDescription}
                      onChange={handleChange}
                      field={field}
                      form={form}
                    />
                  </div>
                )}
              </Field>
              <Label htmlFor="Course Code" id="courseinfo.coursecode" color="primary1" isRequired>
                Course Code
              </Label>
              <Input
                name="courseCode"
                component={FieldInput}
                label="Course Code"
                id="courseinfo.coursecode"
                inputId="courseinfo.coursecode.input"
                status={errors.courseCode && 'error'}
                messageStatus={errors.courseCode}
                placeholder="CW-101"
                onChange={(e) => {
                  e.target.value = e.target.value.toUpperCase()
                  handleChange(e)
                }}
              />
              {errors.description && touched.description && (
                <Typography color="danger1">{errors.description}</Typography>
              )}
            </Footer>
          </div>
        </Wrapper>
      </ErrorBoundary>
    )
  }
}

const mapState = ({ CourseCategories: { courseCategories }, Language: { locale } }) => ({
  courseCategories,
  locale,
})
const mapDispatch = ({ CourseCategories: { getCourseCategories } }) => ({
  getCourseCategories,
})
export default connect(mapState, mapDispatch)(AddCourseInformation)
