import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import Textarea from './style'
import Typography from '../typography'
import styled from 'styled-components'

const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse wrap;
`

function PureTextarea({ id, name, value, onChange, rows, cols, ...props }) {
  const unique = uuid()
  return (
    <Fragment>
      <Textarea
        id={`${id}-${unique}`}
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
        cols={cols}
        {...props}
      />

      <StatusContainer>
        {props.counter && (
          <Typography counter right label2 {...props}>
            {value.length} / {props.maxLength}
          </Typography>
        )}
        {props.status === 'error' && (
          <Typography strong error>
            {props.messageStatus}
          </Typography>
        )}
        {props.status === 'warning' && (
          <Typography strong warning>
            {props.messageStatus}
          </Typography>
        )}
      </StatusContainer>
    </Fragment>
  )
}

PureTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number,
  cols: PropTypes.number,
  placeholder: PropTypes.string,
  props: PropTypes.object,
}

PureTextarea.defaultProps = {
  placeholder: '',
  rows: 4,
  cols: 50,
  props: {},
}

export default PureTextarea
