import React, { useCallback, useEffect, useState } from 'react'
import { fabric } from 'fabric'
import Undo from '../../../../../images/icons/undo-01 1.svg'
import styled from 'styled-components'
import theme from 'theme'
export const UndoIcon = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  input[type='range'] {
    -webkit-appearance: none;
    background: #cbd5e1;
    border-radius: 5px;
    background-image: linear-gradient(#cbd5e1, #cbd5e1);
    background-repeat: no-repeat;
    height: 7px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #253e87;
    cursor: pointer;
  }
`

const DrawingAnswer = ({ canvas, toggle }) => {
  const [initialColor, setIinitialColor] = useState('#253E87')
  const [value, setValue] = useState('5')
  let isRedoing = false
  let h = []
  fabric.Object.cornerStyle = 'circle'

  const toggleDrawing = useCallback(() => {
    if (canvas) {
      canvas.isDrawingMode = toggle
      const target = document.getElementById('slider')
      if (target) {
        const val = parseInt(value)
        target.style.backgroundSize = ((val - 1) * 100) / 50 + '% 100%'
      }
      if (canvas?.isDrawingMode) {
        canvas.freeDrawingBrush.width = value
        canvas.freeDrawingBrush.color = initialColor
      }
    }
  }, [canvas, initialColor, toggle, value])

  useEffect(() => {
    toggleDrawing(canvas)
  }, [toggleDrawing, canvas, toggle])

  function undo() {
    if (canvas._objects.length > 0) {
      if (canvas._objects[canvas._objects.length - 1].stroke !== null) {
        h.push(canvas._objects.pop())
      }
      canvas.renderAll()
    }
  }
  // function redo() {
  //   if (h.length > 0) {
  //     isRedoing = true
  //     canvas.add(h.pop())
  //   }
  // }

  if (canvas) {
    canvas.on({
      'object:added': function () {
        if (!isRedoing) {
          h = []
        }
        isRedoing = false
      },
    })
  }

  // const circleBrush = () => {
  //   const brush = new fabric.PatternBrush()
  //   brush.color = 'green'
  //   // brush.addPoint([10, 20])

  //   console.log(brush)
  //   // brush.color = 'red'
  //   // brush.width = 4
  //   // console.log(canvas.freeDrawingBrush)
  //   // canvas.freeDrawingBrush.canvas = canvas
  //   // canvas.freeDrawingBrush.width = 4
  //   // canvas.freeDrawingBrush.color = 'red'
  //   // console.log(canvas.freeDrawingBrush)
  // }

  const changeColor = (color) => {
    canvas.freeDrawingBrush.color = color
  }

  const changeProgress = (value) => {
    const target = document.getElementById('slider')
    const min = parseInt(target.min)
    const max = parseInt(target.max)
    const val = parseInt(value)
    target.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%'
    canvas.freeDrawingBrush.width = parseInt(value)
  }

  return (
    <>
      {toggle && (
        <Container style={{ gap: 25 }}>
          <UndoIcon src={Undo} onClick={() => undo()} />
          <div
            style={{
              height: 20,
              width: 0.5,
              backgroundColor: theme.black38,
              margin: 5,
            }}
          ></div>
          <input
            type="color"
            id="drawing-color"
            value={initialColor}
            onChange={(e) => {
              changeColor(e.target.value)
              setIinitialColor(e.target.value)
            }}
            style={{ cursor: 'pointer' }}
          />
          <input
            type="range"
            id="slider"
            value={value}
            min="1"
            max="50"
            style={{ width: '100%', cursor: 'pointer' }}
            onChange={(e) => {
              changeProgress(e.target.value)
              setValue(e.target.value)
            }}
          />
          {/* <button
            style={{
              height: 35,
              borderRadius: 35,
              border: '1px solid #c3a572',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 20,
              backgroundColor: '#fff',
            }}
            onClick={() => {
              const stroke = canvas?._objects.filter((x) => {
                return x.stroke !== null
              })
              const group2 = new fabric.Group(stroke)
              const objects = canvas.getObjects().filter((obj) => obj.stroke !== null)
              console.log(objects)
              for (let i = 0; i < objects.length; i++) {
                canvas.remove(objects[i])
              }
              canvas.renderAll()
              canvas.add(group2)
              canvas.isDrawingMode = false
              setToggle(false)
            }}
          >
            <Typography level={6} color="darkGray" semiBold>
              Done
            </Typography>
          </button> */}
        </Container>
      )}
    </>
  )
}

export default DrawingAnswer
