import React from 'react'
import styled from 'styled-components'
import ClosedServerModal from 'components/closedServerModal'

const Container = styled.div`
  padding: 50px 200px;
  background-color: white;
  height: 100vh;
  @media screen and (max-width: 1024px) {
    padding: 20px 120px;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 60px;
  }
  @media screen and (max-width: 425px) {
    padding: 20px 20px;
  }
`

const AnnouncePage = () => {
  return (
    <Container>
      <ClosedServerModal />
    </Container>
  )
}

export default AnnouncePage
