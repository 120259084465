import React from 'react'
import styled from 'styled-components'
import { Form } from 'formik'
import { Card as CardComp, Typography } from 'components'

export const Card = styled((props) => <CardComp {...props} />)`
  display: grid;
  max-width: 90%;
  grid-template-columns: 1fr 0fr;
  padding: 30px;
  margin: 40px 0 0;
  column-gap: 40px;
  @media (max-width: 768px) {
    max-width: 100%;
    display: block;
    padding: 16px;
  }
`

export const Container = styled.div`
  padding: 30px;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Logo = styled.img`
  width: 150px;
`
export const FormFormik = styled(Form)`
  width: 100%;
  max-width: 400px;
  padding-top: 16px;
`
export const PDFDocumentWrapper = styled.div`
  overflow: auto;
  margin-top: 8px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`
export const ContainerModal = styled.div`
  display: flex;

  flex-direction: column;
`
export const TitleModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 32px 0px;
  @media (max-width: 426px) {
    padding: 24px 0;
  }
`
export const ImageModal = styled.img`
  width: 350px;
  height: 247px;
  @media (max-width: 1440px) {
    width: 300px;
    height: 211px;
  }
  @media (max-width: 1024px) {
    width: 280px;
    height: 197px;
  }

  @media (max-width: 768px) {
    width: 250px;
    height: 176px;
  }
  @media (max-width: 426px) {
    width: 250px;
    height: 176px;
  }
`
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`
export const TopFooter = styled.div`
  padding: 0px 0 8px;
  width: 100%;
  text-align: center;
`
export const BottomFooter = styled.div`
  padding: 8px 0;
  width: 100%;
  text-align: center;
`
export const BadgeImage = styled.div`
  width: 300px;
  height: 300px;
  background-color: pink;
  margin-bottom: 20px;
`
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0 0;
`
export const FlexTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`
export const ValidCert = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor === 'valid' ? '#DAFCDA' : '#FFE0E0'};
  border-radius: 10px;
  width: 100px;
  padding: 5px 0;
  text-align: center;
`

export const Title = styled((props) => <Typography {...props} />)`
  font-size: ${({ theme }) => theme['text-2xl']};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme['text-base']};
    margin-top: 24px;
  }
`
