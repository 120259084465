import { connect } from 'react-redux'
import { CoursePricePage } from './index'

const mapState = ({
  MySchool: { mySchoolDetail },
  SchoolCourse: {
    schoolCourse: { name, price, packagePrice, publicMode },
    schoolCourse,
  },
  TeacherSchool: { ownerDetail },
  Language: { locale },
  teachingCourse: { invitationLink, course },
}) => ({
  name,
  publicMode,
  price,
  isVerified: ownerDetail && ownerDetail.isVerified,
  packagePrice,
  schoolPackage: mySchoolDetail?.schoolPackage,
  mySchoolDetail,
  locale,
  invitationLink,
  course,
  draftInfo: schoolCourse,
  isPublished: true,
})
const mapDispatch = ({
  Course: { updateCourseById, getCourseById },
  TeacherSchool: { getOwnerDetails },
  teachingCourse: { getInvitationLink, getCourse },
}) => ({
  updateCourse: updateCourseById,
  getDraft: getCourseById,
  getOwnerDetails,
  getInvitationLink,
  getCourse,
})

export default connect(mapState, mapDispatch)(CoursePricePage)
