import { CourseMarketplacesOrdersAPI } from '../../api'

export const CourseMarketplaceOrders = {
  state: { courseMarketplacesOrdersById: {}, courseMarketplacesOrdersStatusByid: {} },
  reducers: {
    setCourseMarketplacesOrdersById(state, payload) {
      return {
        ...state,
        courseMarketplacesOrdersById: payload,
      }
    },
    setCourseMarketplacesOrdersStatusByid(state, payload) {
      return {
        ...state,
        courseMarketplacesOrdersStatusByid: payload,
      }
    },
  },

  effects: () => ({
    async getTokenGoogle() {
      try {
        const accessTokenGoogle = window.localStorage.getItem('accessTokenGoogle')
        const isExpired = await this.checkTokenExpired(accessTokenGoogle)
        if (isExpired) {
          window.localStorage.removeItem('accessTokenGoogle')
          return null
        }
        return accessTokenGoogle
      } catch (error) {
        throw new Error(error)
      }
    },
    checkTokenExpired(accessTokenGoogle) {
      const url = `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessTokenGoogle}`
      return new Promise((resolve, reject) => {
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            if (data.error === 'invalid_token') {
              return resolve(true)
            }
            return resolve(false)
          })
          .catch((error) => {
            return reject(error)
          })
      })
    },
    async createCourseMarketplacesOrdersInitial(payload) {
      try {
        const result = await CourseMarketplacesOrdersAPI.addCourseMarketplaceOrdersInitial(payload)
        return result
      } catch (error) {
        throw new Error(error)
      }
    },
    async createCourseMarketplacesBuyQuota(payload) {
      try {
        const result = await CourseMarketplacesOrdersAPI.courseMarketplacesBuyQuota({
          id: payload.courseMarketplaceId,
          ...payload,
        })
        return result
      } catch (error) {
        throw new Error(error)
      }
    },
    async getCourseMarketplacesOrdersByid(ordersId) {
      // const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN)) || {}
      // const appUserId = token.userId
      // const filter = JSON.stringify({
      //   include: [
      //     'teachers',
      //     'school',
      //     'level',
      //     'publishedSubject',
      //     'level',
      //     {
      //       relation: 'enrollments',
      //       scope: {
      //         where: {
      //           appUserId: appUserId || null,
      //         },
      //       },
      //     },
      //   ],
      // })
      // const filter = JSON.stringify({
      //   include: ['category', 'subCategory', 'school'],
      //   limit: 10,
      //   skip: 0,
      // })
      const course = await CourseMarketplacesOrdersAPI.findById({ id: ordersId })
      this.setCourseMarketplacesOrdersById(course)
    },
    async getCourseMarketplacesOrdersStatusByid(ordersId) {
      const course = await CourseMarketplacesOrdersAPI.getCourseMarketplacesOrdersStatusById({
        id: ordersId,
      })
      this.setCourseMarketplacesOrdersStatusByid(course)
      return course
    },
  }),
}
