/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { Formik, Field } from 'formik'
import { Input, ClasswinEditor } from '../../components'
import ErrorBoundary from '../../components/errorBoundary'
import { Desktop, Mobile } from '../../utils/displayResponsive'
import { InputGroup, InputAddon, Container } from './style'
import { Icon } from '../courseContentPage/contents/lecture/style'
import DeleteIcon from 'images/icons/TrashColor.svg'

class QuestionsItem extends Component {
  debounceInput = debounce(async ({ name, value }) => {
    const {
      questionId,
      assessmentId,
      choices = [],
      answers,
      index,
      isAnswer,
      setChoices,
      setAnswers,
    } = this.props

    const payload = {
      assessmentId,
      questionId,
    }

    try {
      if (isAnswer) {
        const newAnswer = [...answers.slice(0, index), value, ...answers.slice(index + 1)]
        payload.choices = []
        payload.answers = newAnswer

        setChoices([])
        setAnswers(newAnswer)
      } else {
        if (name === 'choice') {
          // choices
          const newChoice = [...choices.slice(0, index), value, ...choices.slice(index + 1)]
          payload.choices = newChoice
          setChoices(newChoice)
        }

        if (name === 'answer') {
          // answers
          const newAnswers = [value]
          payload.answers = newAnswers
          setAnswers(newAnswers)
        }
      }
    } catch (error) {
      throw error
    }
  })

  inputChange = (e) => {
    const { name, value } = e.target
    const { isAnswer } = this.props

    if (isAnswer) {
      this.debounceInput({ name, value })
    } else {
      if (name === 'choice') {
        this.debounceInput({ name, value })
      }

      if (name === 'answer') {
        this.debounceInput({ name, value })
      }
    }
  }

  render() {
    const { uid, handleDeleteChoice, handleDeleteAnswer, index, title, isAnswer, answers } =
      this.props
    return (
      <ErrorBoundary>
        <Desktop>
          <Formik
            initialValues={{
              choice: title,
              answer: title,
            }}
            enableReinitialize
          >
            {() => (
              <>
                {isAnswer ? (
                  <Container>
                    <Field name="answer">
                      {({ field, form }) => {
                        return (
                          <Input
                            name="answer"
                            type="text"
                            value={field.value}
                            onChange={(e) => {
                              const input = {
                                target: {
                                  value: e.target.value,
                                  name: 'answer',
                                },
                              }
                              form.handleChange(input)
                              this.inputChange(input)
                            }}
                          />
                        )
                      }}
                    </Field>
                    <Icon style={{ alignSelf: 'center' }} onClick={handleDeleteAnswer(index)}>
                      <img src={DeleteIcon} alt="remove-icon" width="20px" height="20px" />
                    </Icon>
                  </Container>
                ) : (
                  <Container>
                    <InputGroup>
                      <InputAddon>
                        <Field name="answer">
                          {({ form }) => (
                            <Input
                              id="answer"
                              name="answer"
                              type="radio"
                              defaultChecked={answers.includes(uid)}
                              onChange={(event) => {
                                const e = {
                                  target: {
                                    value: uid,
                                    name: event.target.name,
                                    checked: event.target.checked,
                                  },
                                }
                                form.handleChange(e)
                                this.inputChange(e)
                              }}
                            />
                          )}
                        </Field>
                      </InputAddon>
                      <Field name="choice">
                        {({ field, form }) => (
                          <ClasswinEditor
                            title="choice"
                            modules={{
                              toolbar: [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { script: 'sub' },
                                { script: 'super' },
                                'image',
                                'link',
                                'video',
                              ],
                            }}
                            name="choice"
                            value={field.value}
                            // noBorderRadius
                            onChange={(value) => {
                              const e = {
                                target: {
                                  value,
                                  name: 'choice',
                                },
                              }
                              form.handleChange(e)
                              this.inputChange(e)
                            }}
                          />
                        )}
                      </Field>
                    </InputGroup>
                    <Icon style={{ alignSelf: 'center' }} onClick={handleDeleteChoice(index)}>
                      <img src={DeleteIcon} alt="remove-icon" width="20px" height="20px" />
                    </Icon>
                  </Container>
                )}
              </>
            )}
          </Formik>
        </Desktop>
        <Mobile></Mobile>
      </ErrorBoundary>
    )
  }
}

const mapState = null
const mapDispatch = ({ AssessmentQuestion: { updateQuestion } }) => ({
  updateQuestion,
})

export default connect(mapState, mapDispatch)(QuestionsItem)
