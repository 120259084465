import styled, { css } from 'styled-components'

const Icon = styled.img`
  margin: 0px;

  ${({ size }) => {
    if (size) {
      return css`
        width: ${size}px;
        height: ${size}px;
      `
    }
    return css`
      width: 25px;
      height: 25px;
      // @media (max-width: 426px) {
      //   width: 16px;
      //   height: 16px;
      // }
    `
  }}

  ${({ margin }) => {
    if (margin) {
      return css`
        margin-right: ${margin}px;
      `
    }
    return css``
  }}

  ${({ onClick }) => {
    if (onClick) {
      return css`
        cursor: pointer;
      `
    }
    return css``
  }}
`

export { Icon }
