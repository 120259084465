import Typography from 'components/typography'
import styled from 'styled-components'

const PasswordInput = styled.div`
  position: relative;
  padding: 20px 0 20px;
  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    padding-right: 40px;
  }
  @media (max-width: 426px) {
    padding: 10px 0 0px;
  }

  .icon {
    position: absolute;
    top: 58px;
    right: 10px;
    width: 18px;
    height: 18px;
    @media (max-width: 820px) {
      top: 58px;
    }
    @media (max-width: 768px) {
      top: 58px;
    }
    @media (max-width: 426px) {
      top: 47px;
    }
  }
`
const HeaderTitle = styled(Typography)`
  font-size: ${(props) => props.theme['text-5xl']};
  @media (max-width: 426px) {
    font-size: ${(props) => props.theme['text-3xl']};
  }
`
const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`
const Image = styled.img`
  width: 100px;
  height: 100px;
`

const Header = styled.div`
  padding: 8px 0 16px;
  @media (max-width: 426px) {
    padding: 20px 0 20px;
  }
`
const ContainerSignup = styled.div`
  padding: 10px 0px 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const Text = styled(Typography)`
  @media (max-width: 426px) {
    font-size: ${(props) => props.theme['text-xs']};
  }
`
export { PasswordInput, ImageBox, Header, Image, ContainerSignup, HeaderTitle, Text }
