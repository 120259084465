/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Typography } from 'components'
import Icon from '../../../../components/icon/loadable'
import Avatar from '../../../../components/avatar/loadable'
import IconClock from '../../../../images/icons/black-clock.svg'
import IconLecture from '../../../../images/icons/DarkIcon/Black-lecture.svg'
import IconCloud from '../../../../images/icons/black-cloud.svg'
import IconClassroom from '../../../../images/icons/black-classroom.svg'
import IconShare from '../../../../images/icons/DarkIcon/Dark-Share.svg'
import LectureDetail from './LectureDetail'
import Discussion from './Discussion'
import ShareModal from '../../../../containers/courseDetail/ShareModal'
import windowSize from 'react-window-size'
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
  }
  height: 100vh;
  background-color: #1c1c1e;
  padding: 20px 16px 20px 16px;
  min-height: 100%;
  width: 480px;
`
const IconWithText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  //width: 100px;
  color: ${(props) => (props.primary ? props.theme.primary2 : props.theme.grey)};
  font-size: ${({ theme }) => theme['text-xs']};
  img {
    margin-right: 0px;
  }
  ${({ start }) => {
    if (start) {
      return css`
        justify-content: flex-start;
      `
    }
  }}
  .after {
    // position: relative;
    // padding: 0 16px 0 0;
  }
  .after:after {
    content: '';
    position: absolute;
    height: 15px;
    background-color: ${(props) => props.theme.gray6};
    top: 3px;
    right: 8px;
  }

  &.mode img {
    filter: invert(40%);
  }
`
const Hilight = styled(Typography)`
  ${({ schoolLink }) => {
    if (schoolLink) {
      return css`
        cursor: pointer;
      `
    }
    return css``
  }}
  min-width: 120px;
  max-width: 390px;
  padding-left: 10px;

  &.school {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (max-width: 500px) {
    min-width: 100px;
  }

  @media (max-width: 400px) {
    min-width: 80px;
  }
`
function Detail(props) {
  const {
    title,
    teacherName,
    shortDescription,
    estimateTimeUsageHr,
    estimateTimeUsageMin,
    lectureNum,
    type,
    schoolLogo,
    schoolName,
    history,
    match,
    schoolSlug,
    data,
    setIsDetail,
    courseId,
    locale,
    courseSlug,
    courseProject,
  } = props
  const courseTypes = {
    online: 'Online',
    classroom: 'Classroom',
  }
  const [openModal, setOpenModal] = useState(false)

  const url = `https://${window.location.host}/courses/${courseSlug}`
  //const url = `https://www.google.co.th`
  const handleClickToSchool = () => {
    window.open(`/${schoolSlug}`, '_blank')
  }
  return (
    <div style={{ position: 'fixed' }}>
      <Container>
        <Typography
          color="white"
          h3
          numberOfLines={2}
          style={{ padding: '0px 0px 6px 0px', overflow: 'visible' }}
        >
          {title}
        </Typography>
        <Typography color="white" numberOfLines={1} style={{ overflow: 'visible' }}>
          โดย {teacherName}
        </Typography>
        <Typography
          color="white"
          style={{ paddingTop: '20px', overflow: 'visible' }}
          numberOfLines={3}
        >
          {shortDescription}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: '16px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconWithText primary style={{ marginRight: '15px' }}>
              <Icon src={IconClock} />
              <Typography
                id="card.course.total.hour"
                values={{ hourCount: estimateTimeUsageHr }}
                color="white"
                fontSize="text-xs"
              >
                {estimateTimeUsageHr} ชั่วโมง
              </Typography>
              <Typography
                id="card.course.total.min"
                values={{ minCount: estimateTimeUsageMin }}
                color="white"
                fontSize="text-xs"
              >
                {estimateTimeUsageMin} นาที
              </Typography>
            </IconWithText>

            <IconWithText primary style={{ marginRight: '15px' }}>
              {type === 'online' ? (
                <Icon src={IconCloud} alt="online-icon" />
              ) : (
                <Icon src={IconClassroom} alt="online-icon" />
              )}
              <Typography id={`card.course.type.${type}`} color="white" fontSize="text-xs">
                {courseTypes[type]}
              </Typography>
            </IconWithText>
            <IconWithText primary style={{ marginRight: '15px' }}>
              <Icon src={IconLecture} />
              <Typography
                label2
                className="after"
                color="white"
                id="card.course.total.lecture"
                fontSize="text-xs"
                values={{ lectureCount: courseProject?.full }}
              >
                {courseProject?.full} Lectures
              </Typography>
            </IconWithText>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconWithText
              primary
              style={{ marginRight: '15px' }}
              onClick={() => setOpenModal(true)}
            >
              <Icon src={IconShare} />
              {/* <Typography color="white" fontSize="text-xs">
                {locale === 'en' ? 'share' : 'แชร์'}
              </Typography> */}
            </IconWithText>
            <ShareModal isOpen={openModal} url={url} setOpenModal={setOpenModal} locale={locale} />
          </div>
        </div>
        <div
          style={{
            margin: '24px 0px 24px 0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => handleClickToSchool()}
        >
          <Avatar size="small" src={schoolLogo} marginRight="10"></Avatar>
          <div style={{ width: '100%' }}>
            <Typography
              id="label.academy"
              padding="0px 0px 0px 10px"
              color="white"
              bold
              fontSize="text-sm"
            >
              Academy
            </Typography>
            <Hilight className="school" color="white" schoolLink fontSize="text-xs">
              {schoolName}
            </Hilight>
          </div>
        </div>
        <LectureDetail data={data} {...props} setIsDetail={setIsDetail} />
        <Discussion courseId={courseId} />
      </Container>
    </div>
  )
}
const mapState = ({ Language: { locale } }) => ({ locale })
export default withRouter(connect(mapState, null)(Detail))
