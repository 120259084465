import Resource from '../utils/resource'

export default new Resource('/Subjects', {
  getSubjectInfo: {
    url: `{subjectId}`,
    method: 'get',
  },
  updateSubjectInfo: {
    url: `{subjectId}`,
    method: 'patch',
  },
  createLecture: {
    url: '{subjectId}/newLecture',
    method: 'post',
  },
  postLecture: {
    url: '{subjectId}/lectures',
    method: 'post',
  },
  postLectureWithPriority: {
    url: '{subjectId}/newLectureWithPriority',
    method: 'post',
  },
  copyWorksheet: {
    url: '{subjectId}/copyWorksheet',
    method: 'post',
  },
  getLecture: {
    url: '{subjectId}/lectures',
    method: 'get',
  },
  getWorksheetList: {
    url: '{subjectId}/assessments',
    method: 'get',
  },
  getWorksheetDetail: {
    url: '{subjectId}/assessments/{worksheetId}',
    method: 'get',
  },
  getLectureById: {
    url: '{subjectId}/lectures/{lectureId}',
    method: 'get',
  },
  updateLectureById: {
    url: '{subjectId}/lectures/{lectureId}',
    method: 'put',
  },
  removeLectureById: {
    url: '{subjectId}/lectures/{lectureId}',
    method: 'delete',
  },
  publishSubject: {
    url: '{id}/publishedSubjects',
    method: 'post',
  },
  getSubjectEdition: {
    url: '{id}/publishedSubjects',
    method: 'get',
  },
  setSubjectWorksheets: {
    url: '{id}/upsertSubjectWorksheet',
    method: 'post',
  },
  setPrecSubjectWorksheets: {
    url: '{id}/subjectWorksheets',
    method: 'post',
  },
  removeSubjectWorksheets: {
    url: '{id}/subjectWorksheets/{fk}',
    method: 'delete',
  },
  getSubjectWorksheets: {
    url: '{id}/subjectWorksheets/{fk}',
    method: 'get',
  },
  getSubjectWithDetails: {
    url: '{id}/subjectWorksheets?filter={filter}',
    method: 'get',
  },
  setIsRequireWorksheet: {
    url: '{id}/subjectWorksheets/{fk}',
    method: 'put',
  },
  removeSubjectById: {
    url: '{id}',
    method: 'patch',
  },
  getLastVersion: {
    url: '{id}/lastVersion',
    method: 'get',
  },
  updateLectureContent: {
    url: '{id}/lectures/{lectureId}/updateContents',
    method: 'post',
  },
  getLectureTests: {
    url: '{id}/lectures/{lectureId}/tests',
    method: 'get',
  },
  getAssessment: {
    url: '{subjectId}/assessments',
    method: 'get',
  },
  createAssessment: {
    url: '{subjectId}/assessments',
    method: 'post',
  },
  deleteAssessment: {
    url: '{subjectId}/assessments',
    method: 'delete',
  },
  getAssessmentById: {
    url: '{subjectId}/assessments/{fk}',
    method: 'get',
  },
  updateAssessmentById: {
    url: '{subjectId}/assessments/{fk}',
    method: 'put',
  },
  deleteAssessmentById: {
    url: '{subjectId}/assessments/{fk}',
    method: 'delete',
  },
  updateContentPriority: {
    url: '{subjectId}/contentPriority',
    method: 'post',
  },
})
