import styled, { css } from 'styled-components'
import { Container, Typography, Card } from '../index'

const Image = styled.img`
  width: 100%;
  background-color: #c0c0c0;
  height: 159px;
`

const Status = styled.div`
  padding: 5px 13px;
  position: absolute;
  border-radius: 3px;
  left: 11px;
  top: 10px;
  z-index: 1;
  ${({ live, testing }) => {
    if (live) {
      return css`
        background-color: #e41313;
      `
    }
    if (testing) {
      return css`
        background-color: #5f5f5f;
      `
    }
    return css``
  }}
`

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  background-color: silver;
  margin: 12px 12px 12px 0px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
`

const _Container = styled(Container)`
  padding: 30px 0px;
`

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  :hover {
    box-shadow: 0 2px 4px 0 rgba(123, 122, 122, 0.5);
  }
`

const Section = styled.section`
  display: inline-flex;
  width: 100%;
  align-items: center;
`

const _Card = styled(Card)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`

export {
  Avatar,
  Status,
  Wrapper,
  Section,
  _Container as Container,
  Image,
  Typography,
  _Card as Card,
}
