import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import FireIcon from '../../images/firecracker.svg'
import BellIcon from '../../images/BellSimple.svg'
import DownloadIcon from '../../images/Download.svg'
import Icon from '../icon/loadable'
import styled, { css } from 'styled-components'
import Typography from '../typography'
import { Button } from '../index'
import _isEmpty from 'lodash/isEmpty'
import { Custom } from '../../utils/displayResponsive'
const IconWithText = styled.div`
  display: flex;
  align-items: center;
  ${({ start }) => {
    if (start) {
      return css`
        justify-content: flex-start;
      `
    }
  }}

  &.mode img {
    filter: invert(40%);
  }
`
const Alert = styled.div`
  padding: 12px;
  background-color: #ebf4f9;
  height: 68px;
  //filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
  width: 1200px;
  display: flex;
  justify-content: space-between;
`
const NonCertificate = styled.div`
  border: solid;
  border-radius: 10px;
  padding: 9px;
  display: flex;
  border-color: #253e87;
`

const CourseAlert = ({ isOpenedCongratulation, enrollment, courseGrade, certStatus }) => {
  const hasIssuedCertificate = !_isEmpty(courseGrade) || certStatus?.hasIssuedCertificate
  const downloadCertificate = () => {
    if (enrollment?.isCompleted && hasIssuedCertificate) {
      return (
        <Link to="/my-certificates">
          <div>
            <Custom minWidth={768}>
              <Button
                style={{ padding: '6px 16px' }}
                onClick={() => {}}
                id="myCourse.lecture.progress.complete.button"
                primary
              >
                <Icon src={DownloadIcon} style={{ height: '20px', width: '20px', margin: '5px' }} />
                <Typography bold color="white">
                  ดาวน์โหลดใบประกาศนียบัตร
                </Typography>
              </Button>
            </Custom>
            <Custom maxWidth={767}>
              <Button
                style={{
                  backgroundColor: '#253E87',
                  padding: '6px 16px',
                  height: '40px',
                  width: '40px',
                  marginRight: '8px',
                }}
                onClick={() => {}}
                id="myCourse.lecture.progress.complete.button"
              >
                <Icon
                  src={DownloadIcon}
                  style={{ height: '20px', width: '20px', marginLeft: '10px' }}
                />
              </Button>
            </Custom>
          </div>
        </Link>
      )
    }
    return (
      <NonCertificate>
        <IconWithText>
          <Custom minWidth={768}>
            <Icon src={BellIcon} style={{ height: '20px', width: '20px', margin: '5px' }} />
            <Typography bold color="primary1">
              แจ้งเตือนเมื่อได้รับประกาศนียบัตร
            </Typography>
          </Custom>
          <Custom maxWidth={767}>
            <Icon src={BellIcon} style={{ height: '20px', width: '20px', margin: '5px' }} />
          </Custom>
        </IconWithText>
      </NonCertificate>
    )
  }

  if (isOpenedCongratulation) {
    return (
      <div
        style={{
          backgroundColor: '#ebf4f9',
          filter:
            'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Alert>
          <IconWithText>
            <Icon src={FireIcon} style={{ height: '24px', width: '24px', marginRight: '10px' }} />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
              <Custom minWidth={768}>
                <Typography bold color="primary1" style={{ fontSize: '16px' }}>
                  ยินดีด้วย คุณเรียนจบคอร์สนี้แล้ว!
                </Typography>
                <Typography color="primary1">
                  ประกาศนียบัตรของคุณกำลังอยู่ในระหว่างการอนุมัติจาก Academy
                </Typography>
              </Custom>
              <Custom maxWidth={767}>
                <Typography bold color="primary1" style={{ fontSize: '16px' }}>
                  ยินดีด้วย จบคอร์สนี้แล้ว!
                </Typography>
                <Typography color="primary1">รอการอนุมัติจาก Academy</Typography>
              </Custom>
            </div>
          </IconWithText>
          {downloadCertificate()}
        </Alert>
      </div>
    )
  }
  return null
}

export default withRouter(CourseAlert)
