/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import theme from 'theme'
import { NavLink, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { Switch, Typography, Button } from '../../components'
import ManageCourseNavigation from '../../components/manageCourseNavigation/loadable'
import prevIcon from '../../images/icons/primary-back.svg'
import { LargeDesktop } from 'utils/displayResponsive'
import ConfirmModal from '../../components/confirmModal/loadable'
const Container = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  padding-right: 0px;
  /* background-color: #f2f3f5; */
  // overflow-y: auto;

  // @media (min-width: 992px) {
  //   padding-top: 40px;
  //   padding-right: 45px;
  // }

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
`
const Background = styled.div`
  // min-height: calc(100vh);
`
const Heading = styled.header`
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  min-height: 56px;
  padding: 10px 50px;
  align-items: center;
  z-index: 10;
  position: sticky;
  top: 0;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`
const Tab = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  justify-content: center;
  //width: 100%;
`
const HeaderMenu = styled.div`
  cursor: pointer;
  //gap: 32px;
  padding: 0px 16px 12px 16px;
  ${({ activeText }) => {
    if (activeText) {
      return css`
        border-bottom: 2px solid ${theme.primary1};
      `
    }
  }};
`
const BackButton = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`
const SwitchSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  //width: 100%;
`
const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  background: white;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06);
  min-height: 56px;
  padding: 8px 50px;
  align-items: center;
  z-index: 10;
  position: sticky;
  bottom: 0;
`
const Icon = styled.img``

const SettingCourseLayout = (props) => {
  const formRef = React.useRef()
  const { pathname } = useLocation()
  const handleSubmit = formRef?.current?.handleSubmit
  const [isShowModalBack, setIsShowModalBack] = useState(false)
  useEffect(() => {
    const { hideSidebar, showSidebar } = props
    hideSidebar()
    return () => {
      showSidebar()
    }
  }, [props])

  useEffectOnce(() => {
    const {
      match: {
        params: { courseId },
      },
      getMyCourseById,
    } = props
    getMyCourseById(courseId)
  })
  const navigationList = () => {
    const { locale } = props

    if (locale === 'th') {
      const courseCreationLists = {
        title: '',
        lists: [
          { name: 'ข้อมูลคอร์ส', path: '/course-information' },
          { name: 'เนื้อหา', path: '/course-content' },
          { name: 'ผู้สอน', path: '/course-instructor' },
          { name: 'ค่าลงทะเบียน', path: '/course-price' },
          // { name: 'ตั้งค่า', path: '/course-setting' },
        ],
      }
      return courseCreationLists
    }

    const courseCreationLists = {
      title: '',
      lists: [
        { name: 'Course Information', path: '/course-information' },
        { name: 'Course Content', path: '/course-content' },
        { name: 'Instructor', path: '/course-instructor' },
        { name: 'Tuition Fees', path: '/course-price' },
        // { name: 'Setting', path: '/course-setting' },
      ],
    }
    return courseCreationLists
  }
  const handlePublishCourse = async () => {
    const {
      match: {
        params: { courseId },
      },
      unpublishCourse,
    } = props
    await unpublishCourse({ id: courseId, isHidden: false })
  }
  const handleUnpublishCourse = async () => {
    const {
      match: {
        params: { courseId },
      },
      unpublishCourse,
    } = props
    await unpublishCourse({ id: courseId, isHidden: true })
  }
  const {
    children,
    schoolCourse: { name, isHidden /* publicMode */ },
    match: {
      params: { courseId, schoolId },
    },
    locale,
  } = props
  const { location, match, history } = props
  const locationPath = `/${location?.pathname.split('/').pop()}`

  return (
    <Background>
      <Heading>
        {/* <BackButton to={`/schools/${schoolId}/curriculum/manage/${courseId}`}>
          <Icon src={prevIcon} />
          <Typography h5 bold numberOfLines={1} width="100%">
            {name}
          </Typography>
        </BackButton> */}
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}
          onClick={() => setIsShowModalBack(true)}
        >
          <Icon src={prevIcon} />
          <Typography h5 bold numberOfLines={1} id="button.back" style={{ cursor: 'pointer' }}>
            back
          </Typography>
        </div>
        <ConfirmModal
          isOpen={isShowModalBack}
          onRequestClose={() => setIsShowModalBack(false)}
          title={props?.locale === 'en' ? 'Are you sure you want to exit?' : 'ออกจากการสร้างคอร์ส'}
          subtitle={
            props?.locale === 'en'
              ? 'This course has not been saved. Do you want to exit Course Setting?'
              : 'คอร์สนี้ยังไม่ได้บันทึก คุณต้องการออกจากการสร้างคอร์สหรือไม่'
          }
          //titleId="modal.sure.delete"
          btn1={
            <NavLink to={`/schools/${schoolId}/curriculum`}>
              <Button primary>{props?.locale === 'en' ? 'Leave Page' : 'ออก'}</Button>
            </NavLink>
          }
          btn2={
            <Button secondary onClick={() => setIsShowModalBack(false)}>
              {props?.locale === 'en' ? 'Stay on Page' : 'อยู่ต่อหน้านี้'}
            </Button>
          }
        />
        <Tab>
          <HeaderMenu
            onClick={() => {
              history.push(`${match.url}/course-content`)
              console.log('sss')
            }}
            activeText={locationPath === '/course-content' ? true : false}
          >
            <Typography
              color="primary1"
              fontWeight={locationPath === '/course-content' ? '700' : '400'}
              flex
              //id="course.content.title"
              superbold
            >
              {locale === 'en' ? 'Course Content' : 'เนื้อหา'}
            </Typography>
          </HeaderMenu>

          <HeaderMenu
            onClick={() => history.push(`${match.url}/access`)}
            activeText={locationPath === '/access' ? true : false}
          >
            <Typography
              color="primary1"
              fontWeight={locationPath === '/access' ? '700' : '400'}
              flex
              superbold
              //id="courseinfo.headtitle"
            >
              {locale === 'en' ? 'Access' : 'การเข้าถึง'}
            </Typography>
          </HeaderMenu>
          <HeaderMenu
            onClick={() => history.push(`${match.url}/setting`)}
            activeText={locationPath === '/setting' ? true : false}
          >
            <Typography
              color="primary1"
              fontWeight={locationPath === '/setting' ? '700' : '400'}
              flex
              superbold
              //id="courseinfo.headtitle"
            >
              {locale === 'en' ? 'Setting' : 'ตั้งค่า'}
            </Typography>
          </HeaderMenu>
        </Tab>
        <SwitchSection>
          <LargeDesktop>
            <Typography id={isHidden ? 'button.unpublish' : 'button.publish'} color="primary1">
              {isHidden ? 'Unpublish' : 'Publish'}
            </Typography>
            <Switch
              type="checkbox"
              name="isHidden"
              checked={!isHidden}
              onChange={isHidden ? handlePublishCourse : handleUnpublishCourse}
              offColor="darkorange"
              onColor="success2"
            />
          </LargeDesktop>
        </SwitchSection>

        {/* <Typography right>{publicMode === 'CODE' ? 'PRIVATE' : publicMode}</Typography> */}
      </Heading>
      <Container>
        {/* <LargeDesktop>
          <ManageCourseNavigation
            {...props}
            courseCreationLists={navigationList()}
            formik={formRef?.current}
          />
        </LargeDesktop> */}
        {/* {children} */}
        {children({ extendedProps: { formRef: formRef } })}
      </Container>
      {/* <LargeDesktop>
        {`${pathname.split('/').pop()}` !== 'course-content' ? (
          <Footer>
            <Button id="button.save" type="submit" primary width={171} onClick={handleSubmit}>
              Save
            </Button>
          </Footer>
        ) : (
          <Footer style={{ backgroundColor: 'transparent', boxShadow: 'none' }}></Footer>
        )}
      </LargeDesktop> */}
    </Background>
  )
}

SettingCourseLayout.defaultProps = {}

// const mapState = ({ SchoolCourse: { schoolCourse } }) => ({ schoolCourse })
// const mapDispatch = ({ SchoolCourse: { getMyCourseById }, Course: { updateCourseById } }) => ({
//   getMyCourseById,
//   updateCourseById,
// })

const mapState = ({ SchoolCourse: { schoolCourse }, Language: { locale } }) => ({
  schoolCourse,
  locale,
})

const mapDispatch = ({
  SchoolCourse: { getMyCourseById, publishContent },
  Course: { updateCourseById },
  Sidebar: { showSidebar, hideSidebar },
}) => ({
  getMyCourseById,
  updateCourseById,
  unpublishCourse: updateCourseById,
  publishContent,
  showSidebar,
  hideSidebar,
})

export default connect(mapState, mapDispatch)(SettingCourseLayout)
