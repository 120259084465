export const menus = [
  {
    id: 'tab.label.Lecture',
    label: 'Lecture',
    key: '0',
    component: null,
    path: '/lecture',
  },
  // {
  //   id: 'tab.label.Assignment',
  //   label: 'Assignment',
  //   key: '1',
  //   component: null,
  //   path: '/assignment',
  // },
  {
    id: 'tab.label.Discussion',
    label: 'Discussion',
    key: '3',
    component: null,
    path: '/discussion',
  },
  // {
  //   id: 'tab.label.Live',
  //   label: 'Live',
  //   key: '4',
  //   component: null,
  //   path: '/live',
  // },
  // // {
  // //   id: 'tab.label.Report',
  // //   label: 'Report',
  // //   key: '5',
  // //   component: null,
  // //   path: '/report',
  // // },
  // // {
  // //   id: 'tab.label.Evaluation',
  // //   label: 'Evaluation',
  // //   key: '6',
  // //   component: null,
  // //   path: '/evaluation',
  // // },
  {
    id: 'tab.label.Members',
    label: 'Members',
    key: '7',
    component: null,
    path: '/members',
  },
]

export const menus2 = [
  {
    id: 'tab.label.Lecture',
    label: 'Lecture',
    key: '0',
    component: null,
    path: '/lecture',
  },
  {
    id: 'tab.label.Assignment',
    label: 'Assignment',
    key: '1',
    component: null,
    path: '/assignment',
  },
  {
    id: 'tab.label.Discussion',
    label: 'Discussion',
    key: '3',
    component: null,
    path: '/discussion',
  },
  // {
  //   id: 'tab.label.Live',
  //   label: 'Live',
  //   key: '4',
  //   component: null,
  //   path: '/live',
  // },
  {
    id: 'tab.label.Report',
    label: 'Report',
    key: '5',
    component: null,
    path: '/report',
  },
  {
    id: 'tab.label.Evaluation',
    label: 'Evaluation',
    key: '6',
    component: null,
    path: '/evaluation',
  },
  {
    id: 'tab.label.Members',
    label: 'Members',
    key: '7',
    component: null,
    path: '/members',
  },
]
