import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'components'
import MoreIcon from 'images/icons/DarkIcon/White-MoreIcon.svg'

import TooltipOption from './TooltipOption'

const MoreMenu = ({ isMe, Confirm, id, course, locale, handleDel, setIsEdit }) => {
  const [isShown, setIsShown] = useState()
  if (isMe) {
    return (
      <Tooltip
        className="post-menu"
        placement="bottom"
        trigger="click"
        tooltipShown={isShown}
        onVisibilityChange={() => setIsShown((prev) => !prev)}
        style={{
          marginTop: 5,
          marginLeft: 6,
          transition: '0.2s',
          width: 30,
        }}
        tooltip={
          <TooltipOption
            handleDelete={() => {
              setIsShown(false)
              Confirm.open({
                onCancel: () => Confirm.close(),
                confirmText: locale === 'en' ? 'Confirm' : 'ยืนยัน',
                cancelText: locale === 'en' ? 'Cancel' : 'ยกเลิก',
                onConfirm: () => {
                  handleDel({ feedId: id, courseId: course && course.id }, 1)
                  Confirm.close()
                },
                title: locale === 'en' ? 'Confirm delete' : 'ยืนยันการลบ',
                subtitle:
                  locale === 'en'
                    ? 'Do you want to delete your comment?'
                    : 'คุณต้องการลบข้อความแสดงความคิดเห็นใช่หรือไม่',
              })
            }}
            handleEdit={() => {
              setIsEdit(true)
              setIsShown(false)
            }}
          />
        }
      >
        <img
          className="icon-more"
          style={{ width: 20, height: 20 }}
          src={MoreIcon}
          alt="more-icon"
        />
      </Tooltip>
    )
  }
  return null
}

const mapState = ({ Language: { locale } }) => ({ locale })

const mapDispatch = ({ Confirm }) => ({ Confirm })

export default connect(mapState, mapDispatch)(MoreMenu)
