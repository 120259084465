import Resource from '../utils/resource'

export default new Resource('/CoursePayments', {
  charge: {
    url: 'charge',
    method: 'post',
  },
  generateQrPromptpay: {
    url: 'qr-promptpay',
    method: 'post',
  },
  getStudentCompanys: {
    url: 'courses/:courseId/getStudentsCompany',
    method: 'post',
  },
  getCoursePriceDetail: {
    url: 'course-price-detail',
    method: 'get',
  },
})
