/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorBoundary from '../../../components/errorBoundary'
import get from 'lodash/get'
import _ from 'lodash'
import windowSize from 'react-window-size'
import getImageUrl from '../../../utils/getImageUrl'
import { getShortName } from '../../../utils/utils'
import isEmpty from 'lodash/isEmpty'
import store from '../../../store'
import { withRouter, Link } from 'react-router-dom'
import { Typography } from 'components'

import Detail from './Detail/Detail'
import Lecture from './Lecture/Lecture'
import { SmallMobile, DesktopAndTablet } from 'utils/displayResponsive'
import DetailMobile from './Detail/DetailMobile'
import LectureMobile from './Lecture/LectureMobile'

const Container = styled.div`
  background-color: #1c1c1e;
  @media (max-width: 429px) {
    /* background-color: #222223; */
  }
`
function Sidebar(props) {
  const [isDetail, setIsDetail] = useState(false)
  const [playedPercent, setPlayedPercent] = useState(0)
  const {
    children,
    myCourse,
    myCourseError,
    //hideArrow,
    lectures,
    courseGrade,
    certStatus,
    courseLateProgress,
    getMyCourseById,
    getMyAssignmentList,
    getCourseLateProgress,
    match,
    getEnrolledCourseContent,
    preTest,
    postTest,
    lectureSide,
    locale,

    ////11
    onNext,
    checkCompleted,
    isTeacherPath,
    handleLectureComplete,
  } = props

  const height = window.innerHeight
  useEffect(() => {
    getMyCourseById(match?.params?.courseId)
    getCourseLateProgress(match?.params?.courseId)
    getEnrolledCourseContent(match?.params?.courseId)
  }, [getMyCourseById, getCourseLateProgress, getEnrolledCourseContent, match])
  const teacherSlug = (get(myCourse, 'teachers') || []).map((item) => {
    return {
      id: item.id,
      slug: item.slug,
      firstname: item.firstname,
      lastname: item.lastname,
      profileImage: item.profileImage,
    }
  })
  const customTeacher = get(myCourse, 'customTeacherList')
  const TeachersList = (customTeacher || []).map((item) =>
    item.id ? teacherSlug.find((t) => t.id === item.id) : item
  )
  const defaultLogo =
    'https://dev-api-classwin.dev.witsawa.com/api/Containers/witsawa-classwin/cover/b8dfabc5-d28c-4526-bee8-de52cba937b0.png?width=400&height=400'
  const data = {
    title: myCourse.name,
    shortDescription: myCourse.shortDescription,
    image: getImageUrl(myCourse.image),
    teacherImage:
      getImageUrl(TeachersList[0]?.profileImage) ||
      getImageUrl(get(myCourse, 'teachers[0].profileImage')),
    teacherId: TeachersList[0]?.id || get(myCourse, 'teachers[0].id'),
    teacherSlug: TeachersList[0]?.slug || get(myCourse, 'teachers[0].slug'),
    teacherName:
      TeachersList[0]?.name ||
      getShortName(get(myCourse, 'teachers[0]', { firstname: '', lastname: '' })),
    schoolName: get(myCourse, 'school.name'),
    schoolId: get(myCourse, 'school.id'),
    schoolSlug: get(myCourse, 'school.slug'),
    schoolLogo: getImageUrl(get(myCourse, 'school.logo'), defaultLogo),
    type: myCourse.type,
    courseSlug: get(myCourse, 'slug'),
    estimateTimeUsageHr: get(myCourse, 'estimateTimeUsageHr'),
    estimateTimeUsageMin: get(myCourse, 'estimateTimeUsageMin'),
    category: get(myCourse, 'subCategory'),
  }

  const lectures1 = lectures.filter((item) => item.type !== 'posttest' && item.type !== 'pretest')

  const reformatLectures = useCallback(() => {
    const summaryLectures = _.get(courseLateProgress?.summary, 'lectures', [])
    const mapLectures = summaryLectures.map((lecture) => ({
      ...lecture?.content,
      isCompleted: lecture?.isCompleted,
    }))
    const newLectures = lectures1.reduce((arr, item) => {
      if (item?.type === '') {
        const findLecture = mapLectures.find((o) => o.lectureId === item.id)
        return [...arr, { ...item, ...findLecture }]
      }
      return [...arr, item]
    }, [])

    return newLectures
  }, [lectures1, courseLateProgress])

  useEffect(() => {
    reformatLectures()
  }, [reformatLectures])

  const focus = reformatLectures()?.find((item) => {
    if (item.type === 'quiz') {
      if (item.id === courseLateProgress.latestLog?.data.contentId && item.isPass !== true) {
        return item
      }
    } else if (item.type === '') {
      if (item.id === courseLateProgress.latestLog?.data.contentId && item.isCompleted !== true) {
        return item
      }
    }
  })

  const lecturesReduce = reformatLectures().reduce((list, lecture) => {
    if (lecture.type === 'paragraph') {
      return [...list, lecture]
    }
    const lastLecture = _.cloneDeep(list).slice(-1)?.[0]

    if (lastLecture) {
      lastLecture.contents = lastLecture.contents || []
      lastLecture.contents = [...lastLecture.contents, lecture]
      return [...list.slice(0, -1), lastLecture]
    }

    return list
  }, [])
  return (
    <ErrorBoundary>
      <DesktopAndTablet>
        <Container>
          <Detail
            {...data}
            {...lecturesReduce}
            {...courseLateProgress}
            data={lecturesReduce}
            isDetail={isDetail}
            setIsDetail={setIsDetail}
            courseId={match?.params?.courseId}
            locale={locale}
            courseSlug={myCourse?.slug}
            onNext={onNext}
            courseProject={courseLateProgress?.summary?.summary?.courseProject}
          />

          <Lecture
            data={lecturesReduce}
            isDetail={isDetail}
            setIsDetail={setIsDetail}
            preTest={preTest}
            postTest={postTest}
            courseProject={courseLateProgress?.summary?.summary?.courseProject}
          />
        </Container>
      </DesktopAndTablet>
      <SmallMobile>
        {match?.params?.lectureId ? (
          <div
            style={{
              zIndex: '10',
              position: 'fixed',
              bottom: '0',
              width: '100%',
            }}
          >
            <LectureMobile
              data={lecturesReduce}
              courseId={match?.params?.courseId}
              preTest={preTest}
              postTest={postTest}
              courseProject={courseLateProgress?.summary?.summary?.courseProject}
            />
          </div>
        ) : (
          <DetailMobile
            {...data}
            {...lecturesReduce}
            {...courseLateProgress}
            data={lecturesReduce}
            courseId={match?.params?.courseId}
            preTest={preTest}
            postTest={postTest}
            courseSlug={myCourse?.slug}
            courseProject={courseLateProgress?.summary?.summary?.courseProject}
          />
        )}
      </SmallMobile>
    </ErrorBoundary>
  )
}

const selection = store.select((models) => ({
  lectureSide: models.MyCourse.lectureSide,
  lectures: models.MyCourse.lectures,
  lectureStatus: (state, props) =>
    models.MyCourse.lectureStatus(state, { lectureId: props.match.params.lectureId }),
  content: (state, props) =>
    models.MyCourse.content(state, { lectureId: props.match.params.lectureId }),
}))
const mapState = (state, props) => {
  return {
    courseId: state.MyCourse.myCourse.id,
    courseName: state.MyCourse.myCourse.name,
    teachers: get(state.MyCourse, 'myCourse.teachers', [{ firstname: '', lastname: '' }]),
    lectureProgress: state.MyCourse.lectureProgress,
    preTest: state.MyCourse.preTest,
    postTest: state.MyCourse.postTest,
    modalLecture: state.MyCourse.modalLecture,
    locale: state.Language.locale,
    ...selection(state, props),
    courseLateProgress: state.MyCourse.courseLateProgress,
    myCourse: state.MyCourse.myCourse,
    loacle: state.Language.locale,
  }
}
const mapDispatch = ({
  MyAssignment: { getMyAssignmentList },
  MyCourse: { getMyCourseById, getCourseLateProgress, getEnrolledCourseContent },
}) => ({
  getMyCourseById,
  getMyAssignmentList,
  getCourseLateProgress,
  getEnrolledCourseContent,
})
export default withRouter(connect(mapState, mapDispatch)(windowSize(Sidebar)))
