import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Typography } from '../../components'
import ArrowIcon from 'images/icons/ic-prev_Primary1.svg'

const Background = styled.div``

const Linkback = styled.div`
  background-color: #fff;
  box-shadow: ${({ shadow }) => (shadow ? '0 5px 5px 0 rgba(0, 0, 0, 0.1)' : 'none')};
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 68px;
  font-weight: 500;
  min-height: 60px;
  border-bottom: ${({ border }) => (border ? '1px solid #eef0ff' : 'none')};

  padding: 8px 0 8px 150px;
  @media (max-width: 1024px) {
    padding: 15px 50px;
  }
  @media (max-width: 768px) {
    padding: 15px 20px;
  }
`

const Adjust = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`
const Arrow = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 10px 0 0;
`
const PreviewTestLayout = (props) => {
  const { children, history, clearAnswer } = props

  useEffect(() => {
    clearAnswer({ assignmentId: undefined })
  }, [clearAnswer])

  return (
    <Background>
      <Linkback>
        <div style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <Adjust>
            <Arrow src={ArrowIcon} alt="next" />
            <Typography color="primary1" id="button.back" fontSize="text-base" margin="0px">
              Back
            </Typography>
          </Adjust>
        </div>
        <Typography
          id="course.preview.mode"
          style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', marginRight: 16 }}
        >
          PREVIEW MODE
        </Typography>
      </Linkback>
      {children}
    </Background>
  )
}

const mapDispatch = (dispatch) => {
  return {
    clearAnswer: dispatch.MyAnswer.clearAnswer,
  }
}

export default connect(null, mapDispatch)(PreviewTestLayout)
