/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect, useRef, Suspense } from 'react'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffectOnce } from 'react-use'
import compact from 'lodash/compact'
import theme from 'theme'
import IntroductionVideo from './IntroductionVideo'
import { validationSchema, validationSchemaWithoutSlug } from './validationSchema'
import { Button } from 'components'
import CustomReactSelect from '../../components/customReactSelect'
import AddItemList from './AddItemList'
// import CameraIcon from '../../images/ic-new-camera.svg'
import courseImagePic from '../../images/CourseImage1.jpg'
// import getImageUrl from '../../utils/getImageUrl'
import ConfirmModal from '../../components/confirmModal/loadable'
// import BadgeAndSkills from './BadgeAndSkills'
import FieldInput from './FieldInput'
import { FileAPI, SchoolAPI } from 'api'
import { base64toFile } from '../../components/uploadImageUsetemplate/image'

import ChromePicker from '../../components/sketchPickerCosrseImg/tooltipChromePicker'
import ImageTempleteDefault from '../../components/imageTemplateDefault/loadable'
import Tooltip from 'components/tooltip'
import {
  UploadImage,
  UploadImageUsetemplate,
  Typography,
  Textarea,
  UploadAvatar,
  Input,
  Label,
  Card,
  Empty,
} from '../../components'
import RadioButton from 'components/customRadioButton'
import MyLoader from '../courseInstructorPage/myLoader'
import {
  Container,
  Item,
  Itemradio,
  DivAvatar,
  CourseImgInput,
  CourseImgBtnCou,
  CourseImgBtnIns,
  CourseImgBtnPos,
  CourseDivImgBtn,
  Content,
  TimeInput,
  Unit,
  UploadImageWrapper,
  EmptyContainer,
  RadioContainer,
  GridTree,
  Footer,
  Header,
  Grid2,
} from './style'
import CourseSettingPage from 'containers/courseSettingPage/loadable'
import PublishedCourseSettingPage from 'containers/courseSettingPage/publishedCourseSettingPage'
import { MobileAndTablet, LargeDesktop } from 'utils/displayResponsive'
import NoSupport from '../../images/icons/NotSupport.svg'
import Icon from '../../components/icon/loadable'
import colorWheel from '../../images/icons/colorWheel.svg'
import { values } from 'lodash'
const InstructorCard = React.lazy(() => import('../courseInstructorPage/instructorCard'))
export const CourseInformationPage = (props) => {
  const [baseCourses, setBaseCourses] = useState([])
  const [isSave, setIsSave] = useState(true)
  // theme.colors.secondary

  const [defaultCourseImgIndex, setdefaultCourseImgIndex] = useState(0)
  const [modalSketchPickerCosrseImg, setSketchPickerCosrseImg] = useState(false)

  const [statusInput, setStatusInput] = useState('')

  const canvasRef = useRef(null)

  useEffectOnce(() => {
    const { getCourseCategories, getCourseLevels } = props
    getCourseCategories()
    getCourseLevels()
  })
  useEffect(
    () => {
      const {
        match: {
          params: { schoolId },
        },
      } = props
      const getBaseCourses = async () => {
        const courses = await SchoolAPI.getSchoolCourse({
          id: schoolId,
          filter: JSON.stringify({
            where: {
              id: {
                inq: props.baseCourseIds,
              },
            },
            fields: {
              id: 1,
              name: 1,
            },
          }),
        })

        setBaseCourses(
          courses.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        )
      }
      if (props.baseCourseIds?.length > 0) {
        getBaseCourses()
      }
    },
    []
    //[props.baseCourseIds, props.params.schoolId]
  )
  const thisPage = window.location.pathname
  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    context.imageSmoothingQuality = 'high'
    context.clearRect(0, 0, canvas.width, canvas.height)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisPage])

  const uploadImageTemplateCanvase = async () => {
    let limitSize = 5242880
    if (canvasRef) {
      const base64 = canvasRef.current.toDataURL('image/jpeg')
      const formData = new FormData()
      const file = await base64toFile(base64, 'image.png', 'image/png')
      //console.log(base64)
      //  console.log(file)

      // if (file.size <= 209715200) { // 200 MB
      if (limitSize && file.size > limitSize) {
        Alert.error({
          title: 'Too large file',
          description: "You couldn't upload the file because it was too large",
        })
        return false
      }
      formData.append('file', file)

      try {
        Alert.loading()

        const res = await FileAPI.upload(formData)

        //image = res.filename.name
        return res.result.files.file[0].name
      } catch (e) {
        throw e
      } finally {
        Alert.toggle(false)
      }
    }
  }

  const handleSubmitTeacher = (values) => {
    const {
      match: {
        params: { courseId, schoolId },
      },
      // draftInfo
      publicMode,
      name,
      updateCourse,
    } = props

    updateCourse({
      name: values.title,
      id: courseId,
      schoolId,
      publicMode,
      _teachers: values.teachers,
      customTeacherList: values.customTeacherList,
    })
  }

  const handleSubmit = ({
    title: name,
    slug,
    courseCode,
    shortDescription,
    description,
    category,
    subcategory,
    image,

    flatImage,
    // level: {id: levelId},
    objectives,
    targetStudents,
    requirements,
    level,
    introVideo,
    introVideoFilename,
    estimateTimeUsageHr,
    estimateTimeUsageMin,
    badgeDescription,
    badgeImage,
    badgeName,
    coverImageVideo,
    //setting page
    type,
    // publishStartDate,
    // publishEndDate,
    registerStartDate,
    registerEndDate,
    maxStudent,
    baseCourses: xBaseCourses,
    ageType,
    courseImageType,
    courseImageTemplate,
  }) => {
    const {
      publicMode,
      match: {
        params: { courseId, schoolId },
      },
      updateCourse,
      locale,
    } = props

    const data = {
      courseImageType,
      ageType: ageType?.value,
      name,
      slug,
      courseCode,
      shortDescription,

      description,
      // categoryId: category.id,
      // subCategoryId: subcategory.id,
      image,

      courseImageTemplate,

      flatImage,
      objectives: compact(objectives),
      targetStudents: compact(targetStudents),
      requirements: compact(requirements),
      publicMode,
      schoolId,
      estimateTimeUsageHr,
      estimateTimeUsageMin,
      badgeDescription,
      badgeImage,
      badgeName,
      // levelId: level.id,
      coverImageVideo,
      introVideo,
      introVideoFilename,
      //setting
      ...(type.value === 'online'
        ? {
            type: type.value,
            maxStudent: '',
            baseCourseIds: xBaseCourses.map((item) => item.value),
          }
        : {
            type: type.value,
            // publishStartDate,
            // publishEndDate,
            registerStartDate,
            registerEndDate,
            maxStudent,
            baseCourseIds: xBaseCourses.map((item) => item.value),
          }),
    }

    if (category) {
      data.categoryId = category.id
    }
    if (subcategory) {
      data.subCategoryId = subcategory.id
    }
    if (level) {
      data.levelId = level.id
    }

    data.courseImageType = courseImageTypeState
    if (courseImageType === 'useTemplate') {
      const UploadImgTemplateCanvas = async () => {
        data.image = await uploadImageTemplateCanvase()
        data.courseImageTemplate.coverImage = defaultCourseImg
        data.courseImageTemplate.profileImage = handleUploadImgAvatar
        data.locale = locale
        //console.log(handleUploadImgAvatar)
        updateCourse({ id: courseId, ...data })
        //console.log({ data })
      }
      UploadImgTemplateCanvas()
    } else if (courseImageType === 'chooseFile') {
      data.image = image
      updateCourse({ id: courseId, ...data })
      // console.log({ data })
    }
    //console.log(handleUploadImgTemplate)
  }

  const {
    // courseCategories,
    // courseCategoriesOptions,

    appUser,
    name: title,
    slug,
    courseCode,
    description,
    shortDescription,
    categoryId,
    subCategoryId,
    image,

    flatImage,
    objectives,
    targetStudents,
    requirements,
    levelId,
    introVideo,
    courseLevels,
    match,
    history,
    estimateTimeUsageMin,
    estimateTimeUsageHr,
    badgeImage,
    badgeName,
    badgeDescription,
    coverImageVideo,
    formRef,
    //setting page
    courseImageTemplate,
    type,
    registerStartDate,
    registerEndDate,
    maxStudent,
    isPublished,
    isHidden,
    autoApprove: isApprove,
    publicMode,
    editCourse,
    unpublishCourse,
    getCourse,
    deleteDraft,
    introVideoFilename,
    ageType,
    courseImageType,
    Alert,
    match: {
      params: { courseId, schoolId },
    },
    teacherList,
    _teachers,
    customTeacherList,
  } = props
  // console.log(appUser)
  useEffect(() => {
    // if (courseImageTemplate?.profileImage !== undefined) {
    setHandleUploadImgAvatar(courseImageTemplate?.profileImage || appUser?.profileImage)
    // }

    setdefaultCourseImg(courseImageTemplate?.coverImage || courseImagePic)
    setCourseNameState(courseImageTemplate?.courseName || title)
    setInstructorNameState(
      courseImageTemplate?.instructorName || appUser?.firstname + ' ' + appUser?.lastname
    )
    setPositionState(courseImageTemplate?.position || '')
    setColorCourseName(courseImageTemplate?.courseNameColor || '#fff')
    setColorInstructorName(courseImageTemplate?.instructorNameColor || '#fff')
    setColorPositionName(courseImageTemplate?.positionColor || '#fff')
    setCourseImageTypeState(courseImageTypeState || courseImageType || 'chooseFile')

    if (
      courseImageTemplate?.coverImage !== '' &&
      courseImageTemplate?.coverImage !== undefined &&
      !courseImageTemplate?.coverImage.includes('/static/media')
    ) {
      setHandleUploadImgTemplate(courseImageTemplate?.coverImage)
    } else if (courseImageTemplate?.coverImage === undefined) {
      setHandleUploadImgTemplate(courseImagePic)
    }

    // eslint-disable-next-line
  }, [
    title,
    courseImageType,
    // eslint-disable-next-line
    courseImageTemplate || courseImageTemplate === undefined,
  ])

  const [defaultCourseImg, setdefaultCourseImg] = useState(courseImageTemplate?.coverImage)

  const [handleUploadImgTemplate, setHandleUploadImgTemplate] = useState('')
  const [handleUploadImgAvatar, setHandleUploadImgAvatar] = useState('')
  const [imgAvatarBeforUpload, setImgAvatarBeforUpload] = useState('')
  const [courseNameState, setCourseNameState] = useState('')
  const [instructorNameState, setInstructorNameState] = useState('')
  const [positionState, setPositionState] = useState('')

  const [colorCourseName, setColorCourseName] = useState('#fff')
  const [colorInstructorName, setColorInstructorName] = useState('#fff')
  const [colorPositionName, setColorPositionName] = useState('#fff')

  const [courseImageTypeState, setCourseImageTypeState] = useState('')

  const handleSketchPickerCosrseImg = (status, color) => {
    if (status === 'CourseName') {
      setColorCourseName(color)
    }
    if (status === 'InstructorName') {
      setColorInstructorName(color)
    }
    if (status === 'Position') {
      setColorPositionName(color)
    }
  }

  useEffect(() => {
    setCourseImageTypeState('chooseFile')
    // eslint-disable-next-line
  }, [courseId])

  let initialcourseImageTemplate = {
    coverImage: courseImageTemplate?.coverImage || '',
    profileImage: courseImageTemplate?.profileImage || handleUploadImgAvatar,
    courseName: courseNameState || '',
    instructorName: instructorNameState || '',
    position: positionState || '',
    courseNameColor: colorCourseName || '#fff',
    instructorNameColor: colorInstructorName || '#fff',
    positionColor: colorPositionName || '#fff',

    // coverImage:  '',
    // profileImage: '',
    // courseName:'',
    // instructorName: '',
    // position:'',
    // courseNameColor: colorCourseName,
    // instructorNameColor: colorInstructorName,
    // positionColor: colorPositionName,
  }

  const handleInitialCourseImg = (img, index) => {
    setdefaultCourseImg(img)
    setdefaultCourseImgIndex(index)
    setHandleUploadImgTemplate(img)
  }

  const colorCourseNameRef = useRef(colorCourseName)
  const colorInstructorNameRef = useRef(colorInstructorName)
  const colorPositionNameRef = useRef(colorPositionName)

  useEffect(() => {
    colorCourseNameRef.current = colorCourseName
    colorInstructorNameRef.current = colorInstructorName
    colorPositionNameRef.current = colorPositionName
  }, [colorCourseName, colorInstructorName, colorPositionName])

  const setDefaultCourseImgInput = () => {
    setCourseNameState(title)
    setHandleUploadImgAvatar(imgAvatarBeforUpload || handleUploadImgAvatar)
    setInstructorNameState(appUser.firstname + ' ' + appUser.lastname)
    setPositionState('')
    setColorCourseName('#fff')
    setColorInstructorName('#fff')
    setColorPositionName('#fff')
    setdefaultCourseImg(courseImagePic)
    setHandleUploadImgTemplate('')
    setdefaultCourseImgIndex(0)
    initialcourseImageTemplate = {}
  }

  // console.log(getImageUrl(courseImageTemplate?.coverImage))

  const reformat = (data) => data.map(({ name, id }) => ({ id, value: name, label: name }))
  const ageTypeOptions = [
    { label: props.locale === 'en' ? 'Studying Age' : 'วัยเรียน', value: 'studying' },
    { label: props.locale === 'en' ? 'Working Age' : 'วัยทำงาน', value: 'working' },
  ]
  const categoriesOptions = (ageType) => {
    const { courseCategories } = props
    if (ageType) {
      return reformat(courseCategories.filter((item) => item.ageType === ageType))
    }
    return reformat(courseCategories)
  }
  const subcategoriesOptions = (id) => {
    const { courseCategories = [] } = props
    const { subcategories = [] } = courseCategories.find((item) => item.id === id) || []
    return reformat(subcategories)
  }
  const category = categoriesOptions().find((item) => item.id === categoryId)
  const subcategory = subcategoriesOptions(categoryId).find((item) => item.id === subCategoryId)

  // if (item === 'Beginner') {
  //   return (levelCourse = 'พื้นฐาน')
  // } else if (item === 'Intermediate') {
  //   return (levelCourse = 'ปานกลาง')
  // } else {
  //   return (levelCourse = 'สูง')
  // }

  const courseLevelOptions = courseLevels.map((item) => {
    let levelTH = ''
    if (item.name === 'Beginner') {
      levelTH = props.locale === 'en' ? item.name : 'พื้นฐาน'
    } else if (item.name === 'Intermediate') {
      levelTH = props.locale === 'en' ? item.name : 'ปานกลาง'
    } else {
      levelTH = props.locale === 'en' ? item.name : 'สูง'
    }

    return {
      label: levelTH,
      value: item.name,
      id: item.id,
    }
  })
  let levelIdObj
  if (courseLevelOptions) levelIdObj = courseLevelOptions.find((item) => item.id === levelId)
  // console.log('draftInfo', props.draftInfo)
  const isManagePage =
    match.path === '/schools/:schoolId/curriculum/manage/:courseId/setting/course-information'

  // const tutorialLink = 'http://classwin.co/course-information/'
  // const width = window.innerWidth
  // console.log(width)
  // // const isTablet = width <

  return (
    <Container>
      <MobileAndTablet>
        <EmptyContainer>
          <Empty
            image={NoSupport}
            id={{ title: 'course.content.emptyFeatures.mobile' }}
            title="No Features"
            className="no-support"
            noMargin
            style={{ padding: 0, margin: 0, justifyContent: 'flex-start' }}
          />
        </EmptyContainer>
      </MobileAndTablet>
      <LargeDesktop>
        <Formik
          ref={formRef}
          enableReinitialize
          validationSchema={isManagePage ? validationSchema : validationSchemaWithoutSlug}
          initialValues={{
            title: title || '',
            shortDescription: shortDescription || '',

            slug: slug || '',
            courseCode: courseCode || '',
            description: description || '',
            ageType:
              ageType === 'studying'
                ? ageTypeOptions[0]
                : ageType === 'working'
                ? ageTypeOptions[1]
                : undefined,
            category: category || props.category,
            subcategory: subcategory || props.subcategory, // subcategorycolorInstructorName
            image: image || '',

            courseImageType: courseImageTypeState,

            courseImageTemplate: initialcourseImageTemplate,

            flatImage: flatImage || '',
            level: levelIdObj || '',
            objectives: objectives || [''],
            targetStudents: targetStudents || [''],
            requirements: requirements || [''],
            introVideo: introVideo || '',
            introVideoFilename: introVideoFilename || '',
            estimateTimeUsageHr: estimateTimeUsageHr || 0,
            estimateTimeUsageMin: estimateTimeUsageMin || 0,
            badgeImage: badgeImage || '',
            badgeName: badgeName || '',
            badgeDescription: badgeDescription || '',
            coverImageVideo: coverImageVideo || '',
            teachers: _teachers?.length > 0 ? _teachers : [`${teacherList[0]?.id}`],
            customTeacherList: customTeacherList || [],
            //setting
            type: type
              ? {
                  label:
                    // eslint-disable-next-line no-nested-ternary
                    props.locale === 'en'
                      ? type === 'online'
                        ? 'No Limit'
                        : 'Duration and Student Limiting'
                      : type === 'online'
                      ? 'ไม่มีกำหนด'
                      : 'กำหนดระยะเวลาและจำนวนคน',
                  value: type,
                }
              : { label: props.locale === 'en' ? 'No Limit' : 'ไม่มีกำหนด', value: 'online' },
            // publishStartDate: publishStartDate ? new Date(publishStartDate) : '',
            // publishEndDate: publishEndDate ? new Date(publishEndDate) : '',
            registerStartDate: registerStartDate ? new Date(registerStartDate) : '',
            registerEndDate: registerEndDate ? new Date(registerEndDate) : '',
            maxStudent: maxStudent || '',
            baseCourses,
          }}
          onSubmit={(values) => {
            handleSubmit(values)
            handleSubmitTeacher(values) // save name
            setIsSave(true)
          }}
          render={({
            values,
            handleChange,
            // handleSubmit: FormikhandleSubmit,
            // isSubmitting,
            setFieldValue,
            setFieldTouched,
            errors,
            touched,
            formikProps,
          }) => {
            // console.log('errors', errors)
            return (
              <>
                <Content>
                  <Form>
                    <Card padding="24px 32px">
                      <div style={{ display: 'flex' }}>
                        <Typography id="courseinfo.headtitle" h4 bold margin="0 0 24px">
                          Course Information
                        </Typography>
                        <Typography color="danger" bold h4>
                          *
                        </Typography>
                      </div>
                      <Item>
                        <Label
                          id="courseinfo.title"
                          htmlFor="title"
                          //isRequired
                          color="primary1"
                          bold={false}
                        >
                          Title
                        </Label>
                        <Input
                          name="title"
                          value={values.title}
                          status={errors.title && 'error'}
                          messageStatus={errors.title}
                          onChange={(e) => {
                            handleChange(e)
                            setIsSave(false)
                          }}
                        />
                      </Item>
                      {isManagePage && (
                        <Item>
                          <Label
                            noMargin
                            htmlFor="slug"
                            // description="Adjustable unique key of your course"
                            color="primary1"
                            bold={false}
                            // isRequired
                            padding="0 0 8px"
                          >
                            Slug
                          </Label>
                          <Input
                            name="slug"
                            value={values.slug}
                            status={errors.slug && touched.slug && 'error'}
                            messageStatus={errors.slug}
                            onChange={handleChange}
                          />
                          <Typography margin="5px 0" label2 color="black38">
                            https://classwin.com/courses/
                            <span style={{ color: theme.primary13, fontSize: theme['text-sm'] }}>
                              {values.slug}
                            </span>
                          </Typography>
                        </Item>
                      )}

                      <Item>
                        <Label
                          id="courseinfo.shortdesc"
                          htmlFor="shortDescription"
                          // isRequired
                          color="primary1"
                          bold={false}
                        >
                          Short Description
                        </Label>
                        <Input
                          name="shortDescription"
                          maxLength="500"
                          value={values.shortDescription}
                          counter
                          status={errors.shortDescription && 'error'}
                          messageStatus={errors.shortDescription}
                          onChange={handleChange}
                        />
                      </Item>
                      <Item>
                        <Label
                          id="courseinfo.desc"
                          htmlFor="description"
                          // isRequired
                          color="primary1"
                          bold={false}
                        >
                          Description
                        </Label>
                        <Field
                          name="description"
                          component={FieldInput}
                          type="textarea"
                          status={errors.description && 'error'}
                          messageStatus={errors.description}
                        />
                      </Item>
                      {errors.description && touched.description ? (
                        <Typography margin="5px 0px" strong error>
                          {errors.description}
                        </Typography>
                      ) : null}
                      <Item>
                        <Label
                          id="courseinfo.coursecode"
                          htmlFor="courseCode"
                          // isRequired
                          color="primary1"
                          bold={false}
                        >
                          Course Code
                        </Label>
                        <Input
                          name="courseCode"
                          value={values.courseCode}
                          status={errors.courseCode && 'error'}
                          messageStatus={errors.courseCode}
                          onChange={(e) => {
                            e.target.value = e.target.value.toUpperCase()
                            handleChange(e)
                          }}
                        />
                      </Item>
                      <GridTree>
                        <Item>
                          <CustomReactSelect
                            id="courseinfo.learnerType"
                            label="Learner Type"
                            name="ageType"
                            placeholder="Select a learner type"
                            value={values.ageType}
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              setFieldValue('category', '')
                              setFieldValue('subcategory', '')
                            }}
                            onBlur={setFieldTouched}
                            error={errors.ageType}
                            touched={touched.ageType}
                            options={ageTypeOptions}
                            isRequired
                            color="primary1"
                            bold={false}
                          />
                          {/* {errors.ageType && touched.ageType ? (
                            <Typography margin="5px 0px" strong error>
                              {errors.ageType}
                            </Typography>
                          ) : null} */}
                        </Item>
                        <Item>
                          <CustomReactSelect
                            id="courseinfo.category"
                            isDisabled={!values?.ageType?.value}
                            label="Categories"
                            name="category"
                            placeholder="Select a category"
                            value={values.category}
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              setFieldValue('subcategory', '')
                            }}
                            onBlur={setFieldTouched}
                            error={errors.category}
                            touched={touched.category}
                            options={categoriesOptions(values?.ageType?.value)}
                            isRequired
                            color="primary1"
                            bold={false}
                          />
                          {/* {errors.category && touched.category ? (
                            <Typography margin="5px 0px" strong error>
                              {errors.category}
                            </Typography>
                          ) : null} */}
                        </Item>
                        <Item>
                          <CustomReactSelect
                            id="courseinfo.subcategory"
                            isDisabled={!values.category}
                            // isOptionDisabled={values.category === '' ? true : false}
                            options={values.category && subcategoriesOptions(values.category.id)}
                            label="Subcategories"
                            name="subcategory"
                            placeholder="Select a subcategory"
                            value={values.subcategory}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={errors.category}
                            touched={touched.subcategory}
                            isRequired
                            color="primary1"
                            bold={false}
                          />
                          {/* {errors.subcategory && touched.subcategory ? (
                            <Typography margin="5px 0px" strong error>
                              {errors.subcategory}
                            </Typography>
                          ) : null} */}
                        </Item>
                      </GridTree>
                    </Card>
                    {/* <Card padding="24px 32px" margin="16px 0 0"> */}
                    {/* <Item>
                        <Label
                          id="courseinfo.learnerType"
                          htmlFor="learnerType"
                          isRequired
                          color="primary1"
                          bold={false}
                        >
                          Learner Type
                        </Label>
                        <RadioContainer>
                          <RadioButton
                            field={{
                              name: 'ageType',
                              value: values.ageType,
                              onChange: () => {
                                setFieldValue('ageType', 'studying')
                                setFieldValue('category', '')
                                setFieldValue('subcategory', '')
                              },
                            }}
                            id="studying"
                            label="Studying Age"
                            labelId="courseinfo.studying"
                          />
                        </RadioContainer>
                        <RadioContainer>
                          <RadioButton
                            field={{
                              name: 'ageType',
                              value: values.ageType,
                              onChange: () => {
                                setFieldValue('ageType', 'working')
                                setFieldValue('category', '')
                                setFieldValue('subcategory', '')
                              },
                            }}
                            id="working"
                            label="Working Age"
                            labelId="courseinfo.working"
                          />
                        </RadioContainer>
                      </Item> */}
                    {/* </Card> */}
                    <Card padding="24px 32px" margin="16px 0 0">
                      {/* <Label id="courseinfo.courseimage" h4 bold margin="0 0 24px" isRequired>
                        Course Image
                      </Label> */}
                      <Typography id="courseinfo.courseimage" h4 bold margin="0 0 24px">
                        Course Information
                      </Typography>
                      <Itemradio>
                        {/* Course Image */}
                        <RadioContainer>
                          <RadioButton
                            field={{
                              name: 'courseImageType',
                              value: courseImageTypeState,
                              onChange: () => {
                                setCourseImageTypeState('chooseFile')
                                setFieldValue('courseImageType', 'chooseFile')
                              },
                            }}
                            id="chooseFile"
                            label="Choose from file"
                            labelId="courseinfo.Choosefromfile"
                          />
                        </RadioContainer>

                        <RadioContainer>
                          <RadioButton
                            field={{
                              name: 'courseImageType',
                              value: courseImageTypeState,
                              onChange: () => {
                                setCourseImageTypeState('useTemplate')
                                setFieldValue('courseImageType', 'useTemplate')
                              },
                            }}
                            id="useTemplate"
                            label="Use Template"
                            labelId="courseinfo.UseTemplate"
                          />
                        </RadioContainer>
                      </Itemradio>

                      {courseImageTypeState === 'chooseFile' ? (
                        <>
                          <UploadImageWrapper>
                            <div className="image">
                              <UploadImage
                                id="courseinfo.courseimage.website"
                                // label="For website"
                                aspect={1000 / 524}
                                cropModal
                                name="image"
                                isCrop
                                cropLabel="Update Course Image"
                                value={values.image}
                                afterUpload={(file) => {
                                  setFieldValue('image', file.name)
                                }}
                                onChange={setFieldValue}
                                styleUploadImage={{
                                  gridTemplateColumns: '1fr',
                                }}
                                isRequired
                                color="primary1"
                                bold={false}
                              />
                              {errors.image && touched.image ? (
                                <Typography margin="5px 0px" strong error>
                                  {errors.image}
                                </Typography>
                              ) : null}
                            </div>
                          </UploadImageWrapper>
                        </>
                      ) : (
                        <>
                          <UploadImageWrapper>
                            <div className="image">
                              <div style={{ position: 'relative' }}>
                                <UploadImageUsetemplate
                                  id="courseinfo.courseimage.website"
                                  // label="For website"
                                  aspect={1000 / 524}
                                  cropModal
                                  name="courseImageTemplate.coverImage"
                                  isCrop
                                  cropLabel="Update Course Image"
                                  value={values.courseImageTemplate.coverImage}
                                  afterUpload={(file) => {
                                    setFieldValue('courseImageTemplate.coverImage', file.name)
                                    setdefaultCourseImg(file.name)
                                  }}
                                  onChange={(e) => {
                                    // console.log('++++++' + e.target.value)
                                    setHandleUploadImgTemplate(e.target.value)
                                  }}
                                  // onChange={setFieldValue}
                                  styleUploadImage={{
                                    gridTemplateColumns: '1fr',
                                  }}
                                  isRequired
                                  color="primary1"
                                  bold={false}
                                  canvasRef={canvasRef}
                                  courseNameColor={colorCourseName}
                                  instructorNameColor={colorInstructorName}
                                  positionColor={colorPositionName}
                                  courseNameState={courseNameState}
                                  instructorNameState={instructorNameState}
                                  positionState={positionState}
                                  defaultCourseImg={defaultCourseImg} /// รูป  โหลดครั้งเเรก
                                  imgAvatar={handleUploadImgAvatar}
                                  imgtemplate={handleUploadImgTemplate} //รูปที่กด  upload  เอามาเเทน  value เนื่องจากกดเเล้วหาย
                                />

                                <DivAvatar>
                                  {/* <img alt="avatar" src={CameraIcon} width={50} height={50} /> */}
                                  <UploadAvatar
                                    showImage={false}
                                    center
                                    name="courseImageTemplate.profileImage"
                                    detail=""
                                    value={values.courseImageTemplate.profileImage}
                                    styleImageBox={{
                                      height: '130px',
                                      width: '130px',
                                      borderRadius: '50%',
                                    }}
                                    styleUploadImage={{ gridTemplateColumns: '1fr' }}
                                    afterUpload={(file) => {
                                      setFieldValue('courseImageTemplate.profileImage', file.name)
                                      setHandleUploadImgAvatar(file.name)
                                      setImgAvatarBeforUpload(handleUploadImgAvatar)
                                    }}
                                  ></UploadAvatar>
                                </DivAvatar>
                              </div>
                            </div>
                          </UploadImageWrapper>

                          <ImageTempleteDefault
                            setHandleUploadImgTemplate={setHandleUploadImgTemplate}
                            UploadImgTemplate={handleUploadImgTemplate}
                            setDefaultCourseImgInput={setDefaultCourseImgInput}
                            handleInitialCourseImg={handleInitialCourseImg}
                            defaultCourseImgIndex={defaultCourseImgIndex}
                            defaultCourseImg={defaultCourseImg}
                          />

                          <Item>
                            <Label
                              id="courseinfo.CourseName"
                              htmlFor="courseName"
                              color="primary1"
                              bold={false}
                            >
                              Course Name
                            </Label>

                            <CourseImgInput style={{ alignItems: 'flex-start' }}>
                              <div>
                                <Textarea
                                  id="courseName"
                                  name="courseImageTemplate.courseName"
                                  value={values.courseImageTemplate.courseName}
                                  status={errors.courseName && 'error'}
                                  messageStatus={errors.courseName}
                                  onChange={(e) => {
                                    // setFieldValue('courseImageTemplate.courseName', e.target.value)
                                    setCourseNameState(e.target.value)
                                    handleChange(e)
                                  }}
                                ></Textarea>
                              </div>
                              {/* ChromePicker */}

                              <CourseDivImgBtn style={{ marginTop: '5px' }}>
                                <Tooltip
                                  style={{ borderRadius: '50%' }}
                                  placement="bottom"
                                  trigger="click"
                                  onVisibilityChange={() => console.log(true)}
                                  tooltip={
                                    <ChromePicker
                                      courseNameColor={colorCourseName}
                                      instructorNameColor={colorInstructorName}
                                      positionColor={colorPositionName}
                                      statusInput={statusInput}
                                      handleSketchPickerCosrseImg={handleSketchPickerCosrseImg}
                                      isOpen={modalSketchPickerCosrseImg}
                                      handleCloseModal={() => setSketchPickerCosrseImg(false)}
                                    />
                                  }
                                >
                                  {/* <CourseImgBtnCou
                                    courseNameColor={colorCourseName}
                                    // type="button"
                                    onClick={() => {
                                      // setSketchPickerCosrseImg(true)
                                      setStatusInput('CourseName')
                                    }}
                                  ></CourseImgBtnCou> */}
                                  <Icon
                                    src={colorWheel}
                                    alt="online-icon"
                                    onClick={() => {
                                      setStatusInput('CourseName')
                                    }}
                                  />
                                </Tooltip>
                              </CourseDivImgBtn>
                              {/* {courseImageTemplate?.courseNameColor} */}
                            </CourseImgInput>
                          </Item>
                          <Item>
                            <Label
                              id="courseinfo.InstructorName"
                              htmlFor="instructorName"
                              color="primary1"
                              bold={false}
                            >
                              Instructor Name
                            </Label>
                            <CourseImgInput>
                              <Input
                                name="courseImageTemplate.instructorName"
                                maxLength="30"
                                value={values.courseImageTemplate.instructorName}
                                counter
                                status={errors.instructorName && 'error'}
                                messageStatus={errors.instructorName}
                                onChange={(e) => {
                                  setInstructorNameState(e.target.value)
                                  handleChange(e)
                                }}
                              />

                              <CourseDivImgBtn>
                                <Tooltip
                                  style={{ borderRadius: '50%' }}
                                  placement="bottom"
                                  trigger="click"
                                  onVisibilityChange={() => console.log(true)}
                                  tooltip={
                                    <ChromePicker
                                      courseNameColor={colorCourseName}
                                      instructorNameColor={colorInstructorName}
                                      positionColor={colorPositionName}
                                      statusInput={statusInput}
                                      handleSketchPickerCosrseImg={handleSketchPickerCosrseImg}
                                      isOpen={modalSketchPickerCosrseImg}
                                      handleCloseModal={() => setSketchPickerCosrseImg(false)}
                                    />
                                  }
                                >
                                  {/* <CourseImgBtnIns
                                    type="button"
                                    instructorNameColor={colorInstructorName}
                                    onClick={() => {
                                      setStatusInput('InstructorName')
                                    }}
                                  ></CourseImgBtnIns> */}
                                  <Icon
                                    src={colorWheel}
                                    alt="online-icon"
                                    onClick={() => {
                                      setStatusInput('InstructorName')
                                    }}
                                  />
                                </Tooltip>
                              </CourseDivImgBtn>
                            </CourseImgInput>
                          </Item>
                          <Item>
                            <Label
                              id="courseinfo.Position"
                              htmlFor="position"
                              color="primary1"
                              bold={false}
                            >
                              Position
                            </Label>
                            <CourseImgInput>
                              <Input
                                name="courseImageTemplate.position"
                                maxLength="30"
                                value={values.courseImageTemplate.position}
                                counter
                                status={errors.position && 'error'}
                                messageStatus={errors.position}
                                onChange={(e) => {
                                  setPositionState(e.target.value)
                                  handleChange(e)
                                }}
                              />

                              <CourseDivImgBtn>
                                <Tooltip
                                  style={{ borderRadius: '50%' }}
                                  placement="bottom"
                                  trigger="click"
                                  onVisibilityChange={() => console.log(true)}
                                  tooltip={
                                    <ChromePicker
                                      courseNameColor={colorCourseName}
                                      instructorNameColor={colorInstructorName}
                                      positionColor={colorPositionName}
                                      statusInput={statusInput}
                                      handleSketchPickerCosrseImg={handleSketchPickerCosrseImg}
                                      isOpen={modalSketchPickerCosrseImg}
                                      handleCloseModal={() => setSketchPickerCosrseImg(false)}
                                    />
                                  }
                                >
                                  {/* <CourseImgBtnPos
                                    positionColor={colorPositionName}
                                    type="button"
                                    onClick={() => {
                                      setStatusInput('Position')
                                    }}
                                  ></CourseImgBtnPos> */}
                                  <Icon
                                    src={colorWheel}
                                    alt="online-icon"
                                    onClick={() => {
                                      setStatusInput('Position')
                                    }}
                                  />
                                </Tooltip>
                              </CourseDivImgBtn>
                            </CourseImgInput>
                          </Item>
                        </>
                      )}

                      <IntroductionVideo
                        label="Introduction Video"
                        name="introVideo"
                        value={values.introVideo}
                        onChange={setFieldValue}
                        cover={values.coverImageVideo}
                        introVideoFilename={values.introVideoFilename}
                      />
                    </Card>
                    <Card padding="24px 32px" margin="16px 0 0">
                      <Typography id="courseinfo.difficulty" h4 bold margin="0 0 24px">
                        Course Difficulty
                      </Typography>
                      <Item>
                        <Label id="courseinfo.estimationtime" color="primary1" bold={false}>
                          Course Estimation Time
                        </Label>
                        <TimeInput>
                          <Input
                            min="0"
                            suffix={<Unit>hr</Unit>}
                            placeholder="HH"
                            type="number"
                            name="estimateTimeUsageHr"
                            value={values.estimateTimeUsageHr}
                            status={errors.estimateTimeUsageHr && 'error'}
                            messageStatus={errors.estimateTimeUsageHr}
                            onChange={handleChange}
                          />
                          <Input
                            min="0"
                            suffix={<Unit>mins</Unit>}
                            placeholder="mm"
                            type="number"
                            name="estimateTimeUsageMin"
                            value={values.estimateTimeUsageMin}
                            status={errors.estimateTimeUsageMin && 'error'}
                            messageStatus={errors.estimateTimeUsageMin}
                            onChange={handleChange}
                          />
                        </TimeInput>
                        <CustomReactSelect
                          id="courseinfo.level"
                          noMargin
                          label="Level"
                          name="level"
                          placeholder="Select level"
                          value={values.level}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.level}
                          touched={touched.level}
                          options={courseLevelOptions}
                          color="primary1"
                          bold={false}
                        />

                        {/* {errors.level && touched.level ? (
                          <Typography margin="5px 0px" strong error>
                            {errors.level}
                          </Typography>
                        ) : null} */}
                      </Item>
                    </Card>
                    <Card padding="24px 32px" margin="16px 0 0">
                      <Typography id="courseinfo.detail" h4 bold margin="0 0 24px">
                        Course Detail
                      </Typography>
                      <AddItemList
                        id="courseinfo.courseobjective"
                        // descId="courseinfo.courseobjective.desc"
                        label="Course Objective(s)"
                        // description="What will students learn from your course?"
                        value={values.objectives}
                        onBlur={setFieldTouched}
                        name="objectives"
                        placeholder="e.g. Learn some awesome skills"
                        color="primary1"
                        bold={false}
                        hideAdd={values.objectives.length === 5}
                      />
                      <AddItemList
                        id="courseinfo.targetstudent"
                        // descId="courseinfo.targetstudent.desc"
                        label="Target Student(s)"
                        // description="Who should be enrolling in this course?"
                        value={values.targetStudents}
                        onBlur={setFieldTouched}
                        name="targetStudents"
                        placeholder="e.g. Anyone who is willing to learn"
                        color="primary1"
                        bold={false}
                        hideAdd={values.targetStudents.length === 5}
                      />
                      <AddItemList
                        id="courseinfo.courserequirement"
                        label="Course Requirement(s)"
                        value={values.requirements}
                        onBlur={setFieldTouched}
                        name="requirements"
                        placeholder="e.g. Some basic knowledge of ..."
                        color="primary1"
                        bold={false}
                        hideAdd={values.requirements.length === 5}
                      />
                    </Card>

                    {props.isPublished ? (
                      <PublishedCourseSettingPage
                        isManagePage={isManagePage}
                        isApprove={isApprove}
                        isPublished={isPublished}
                        isHidden={isHidden}
                        publicMode={publicMode}
                        editCourse={editCourse}
                        unpublishCourse={unpublishCourse}
                        getCourse={getCourse}
                        deleteDraft={deleteDraft}
                        formikProps={{
                          values,
                          setFieldValue,
                          setFieldTouched,
                          errors,
                          touched,
                          handleChange,
                        }}
                        match={match}
                        history={history}
                      />
                    ) : (
                      <CourseSettingPage
                        isManagePage={isManagePage}
                        isApprove={isApprove}
                        autoApprove={isApprove}
                        isPublished={isPublished}
                        isHidden={isHidden}
                        publicMode={publicMode}
                        editCourse={editCourse}
                        unpublishCourse={unpublishCourse}
                        getCourse={getCourse}
                        deleteDraft={deleteDraft}
                        formikProps={{
                          values,
                          setFieldValue,
                          setFieldTouched,
                          errors,
                          touched,
                          handleChange,
                        }}
                        match={match}
                        history={history}
                        // formikProps={formikProps}
                      />
                    )}
                    <Footer>
                      {props.isPublished ? (
                        <Link
                          to={`/schools/${schoolId}/course-preview/${courseId}/lecture`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button cancelbtn type="button" id="button.preview" width={171}>
                            Preview
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      <Button id="button.save" type="submit" primary width={171}>
                        Save
                      </Button>
                    </Footer>
                  </Form>
                </Content>
              </>
            )
          }}
        />
      </LargeDesktop>
    </Container>
  )
}

CourseInformationPage.defaultProps = {
  courseCategories: [],
  courseLevels: [],
  getCourseCategories: () => ({}),
  getCourseLevels: () => ({}),
  getCourse: () => {},
  editCourse: () => {},
}

const mapState = ({
  CourseCategories: { courseCategories },
  DraftCourse: {
    draftInfo,
    draftInfo: { _teachers, publicMode, name, customTeacherList },
  },
  CourseLevels: { courseLevels },
  AppUser: { appUser },
  TeacherSchool: { teacherList },
  Language: { locale },
}) => ({
  courseCategories,
  courseLevels,
  locale,
  draftInfo,
  ...draftInfo,
  _teachers,
  customTeacherList,
  isPublished: false,
  appUser,
  teacherList: teacherList.map((item) => ({
    id: item.appUser.id,
    name: `${item.appUser.firstname} ${item.appUser.lastname}`,
    profileImage: item.appUser.profileImage,
    skill: '',
  })),
})

const mapDispatch = ({
  CourseCategories: { getCourseCategories },
  DraftCourse: { getDraft, updateDraft, deleteDraft },
  CourseLevels: { getCourseLevels },
  Alert,
  // AppUser: { fetchMe },
}) => ({
  //fetchMe
  getCourseCategories,
  updateCourse: updateDraft,
  deleteDraft,
  editCourse: updateDraft,
  getCourse: getDraft,
  getCourseLevels,
  Alert,
})
export default connect(mapState, mapDispatch)(CourseInformationPage)
