import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Container, Typography, Section } from './style'
import ErrorBoundary from '../errorBoundary'

class MessageChat extends Component {
  render() {
    const {
      snippet: {
        textMessageDetails: { messageText },
      },
      authorDetails: { displayName, profileImageUrl },
    } = this.props
    return (
      <ErrorBoundary>
        <Container column="2">
          <Avatar src={profileImageUrl} />
          <Section>
            <Typography strong color="#7E7E7E">
              {displayName}
            </Typography>
            <Typography color="#7E7E7E">{messageText}</Typography>
          </Section>
        </Container>
      </ErrorBoundary>
    )
  }
}

MessageChat.propTypes = {
  snippet: PropTypes.shape({
    textMessageDetails: PropTypes.shape({
      messageText: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  authorDetails: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    profileImageUrl: PropTypes.string.isRequired,
  }).isRequired,
}

export default MessageChat
