import Resource from '../utils/resource'

export default new Resource('/AppUsers', {
  login: {
    url: 'login',
    method: 'post',
  },
  logout: {
    url: 'logout',
    method: 'post',
  },
  facebookLogin: {
    url: 'auth-facebook',
    method: 'post',
  },
  googleLogin: {
    url: 'auth-google',
    method: 'post',
  },
  requestPasswordReset: {
    url: 'reset',
    method: 'post',
  },
  getSuggestionCoursesAPI: {
    url: 'me/suggestion-courses',
    method: 'get',
  },
  getMyFeeds: {
    url: 'me/my-feeds',
    method: 'get',
  },
  getBadge: {
    url: '{slug}/badges',
    method: 'get',
  },
  getBadgebyID: {
    url: '{slug}/badges/{fk}',
    method: 'get',
  },
  changePriority: {
    url: 'me/badges/change-priority',
    method: 'put',
  },
  setPassword: {
    url: 'reset-password?access_token={reset_password_token}',
    method: 'post',
  },
  changePassword: {
    url: 'change-password',
    method: 'post',
  },
  enrollTeacher: {
    url: '{userId}/enrollTeacher',
    method: 'post',
    defaultParams: {
      userId: 'me',
    },
  },
  postEvents: {
    url: 'me/events',
    method: 'post',
  },
  updateEvents: {
    url: 'me/events/{id}',
    method: 'put',
  },
  deleteEvents: {
    url: 'me/events/{id}',
    method: 'delete',
  },
  getEvents: {
    url: 'me/events',
    method: 'get',
  },
  getPoint: {
    url: '{userId}/pointTransactions',
    method: 'get',
    defaultParams: {
      userId: 'me',
    },
  },
  getUserMe: {
    url: 'userMe',
    method: 'get',
  },
  getCourseCatalog: {
    url: '{userId}/courseCatalog?filter={filter}',
    method: 'get',
    defaultParams: {
      userId: 'me',
    },
  },
  getSchools: {
    url: '{userId}/schoolMembers',
    method: 'get',
    defaultParams: {
      userId: 'me',
    },
  },
  getSchoolsCount: {
    url: '{userId}/schoolMembers/count',
    method: 'get',
    defaultParams: {
      userId: 'me',
    },
  },
  getSchoolStudents: {
    url: '{userId}/schoolStudents',
    method: 'get',
    defaultParams: {
      userId: 'me',
    },
  },
  joinSchoolStudent: {
    url: '{userId}/schoolStudents',
    method: 'post',
    defaultParams: {
      userId: 'me',
    },
  },
  changeEmail: {
    url: 'me/change-email',
    method: 'put',
  },
  deleteSchoolStudents: {
    url: 'me/schoolStudents/{id}',
    method: 'delete',
  },
  createSchool: {
    url: '{userId}/schools',
    method: 'post',
    defaultParams: {
      userId: 'me',
    },
  },
  getSubject: {
    url: '{userId}/subjects',
    method: 'get',
    defaultParams: {
      userId: 'me',
    },
  },
  getCoursePayments: {
    url: '{userId}/coursePayments',
    method: 'get',
    defaultParams: {
      userId: 'me',
    },
  },
  getSubjectIsDeleted: {
    url: '{userId}/subjects?filter={filter}',
    method: 'get',
    defaultParams: {
      userId: 'me',
    },
  },
  createSubject: {
    url: '{id}/subjects',
    method: 'post',
    defaultParams: {
      id: 'me',
    },
  },
  getAppUser: {
    url: '{id}',
    method: 'get',
  },

  // TODO
  getWorksheet: {
    url: '{id}/worksheets',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getCountWorksheet: {
    url: '{id}/worksheets/count',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getWorksheetIsDeleted: {
    url: '{id}/worksheets?filter={filter}',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getOwnedWorksheet: {
    url: '{id}/ownedWorksheets',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  createWorksheet: {
    url: '{id}/worksheets',
    method: 'post',
    defaultParams: {
      id: 'me',
    },
  },
  enrollCourse: {
    url: '{id}/enroll-new-course',
    method: 'post',
    defaultParams: {
      id: 'me',
    },
  },
  // enrollCourseByLink: {
  //   url: '{id}/enrollCourseByLink/{code}',
  //   method: 'post',
  //   defaultParams: {
  //     id: 'me',
  //   },
  // },
  postLikeApi: {
    url: '{id}/likeFeed/{feedId}',
    method: 'post',
    defaultParams: {
      id: 'me',
    },
  },
  getEnrollments: {
    url: '{id}/enrollments',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getEnrolledCourses: {
    url: '{id}/enrolledCourses',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getEnrolledCoursesSuccess: {
    url: '{id}/enrolledCourses/success',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getEnrolledSchools: {
    url: '{id}/enrolledSchools',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getEnrolledCourseDetail: {
    url: '{id}/enrolledCourses/{courseId}',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getSchoolMember: {
    url: '{id}/schoolMembers',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  updateSchoolMember: {
    url: 'me/schoolMembers/{id}',
    method: 'put',
  },
  deleteSchoolMember: {
    url: 'me/schoolMembers/{id}',
    method: 'delete',
  },
  acceptSchoolMember: {
    url: '{id}/schoolMember/{fk}/accept',
    method: 'patch',
  },
  rejectSchoolMember: {
    url: '{id}/schoolMember/{fk}/reject',
    method: 'delete',
  },
  getStudentAssignments: {
    url: 'me/assignments',
    method: 'get',
  },
  getMyAssignmentById: {
    url: 'me/myAssignments/{id}',
    method: 'get',
  },
  getCountTeachingCourses: {
    url: '{id}/teachingCourses/count',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getTeachingCourses: {
    url: '{id}/teachingCourses',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getTeachingCoursesDetail: {
    url: '{id}/teachingCourses/{courseId}',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  postFeed: {
    url: '{id}/feeds',
    method: 'post',
    defaultParams: {
      id: 'me',
    },
  },
  getFeeds: {
    url: '{id}/feeds',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  updateFeed: {
    url: '{id}/feeds/{feedId}',
    method: 'put',
    defaultParams: {
      id: 'me',
    },
  },
  deleteFeed: {
    url: '{id}/feeds/{feedId}',
    method: 'delete',
    defaultParams: {
      id: 'me',
    },
  },
  verifyTeacher: {
    url: '{id}/verifyTeacher',
    method: 'post',
    defaultParams: {
      id: 'me',
    },
  },
  getStudentSchedule: {
    url: 'me/enrolledCourses/schedules',
    method: 'get',
  },
  getStudentGrades: {
    url: 'me/studentGrades',
    method: 'get',
  },
  getTeacherSchedule: {
    url: 'me/teachingCourses/schedules',
    method: 'get',
  },
  getMyAssignments: {
    url: 'me/assignments?filter={filter}',
    method: 'get',
  },
  sendAnswers: {
    url: 'me/answers',
    method: 'post',
  },
  getAnswers: {
    url: 'me/answers',
    method: 'get',
  },
  sendRewardTransaction: {
    url: 'me/rewardTransactions',
    method: 'post',
  },
  getRewardTransaction: {
    url: 'me/rewardTransactions',
    method: 'get',
  },
  getMyAttendances: {
    url: 'me/attendanceItems',
    method: 'get',
  },
  setContentProgress: {
    url: 'me/contentProgresses',
    method: 'post',
  },
  getEnrolledCourseContent: {
    url: '{id}/enrolledCourseContent?courseId={courseId}',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getOwnedSubjects: {
    url: '{id}/ownedSubjects',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getLectureProgress: {
    url: '{id}/contentProgresses',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  checkIn: {
    url: 'me/checkIns',
    method: 'post',
  },
  getEnrolledCourseSkills: {
    url: '{id}/enrolledCourseSkill?courseId={courseId}',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getMyCourses: {
    url: 'me/myCourses',
    method: 'get',
  },
  getEducation: {
    url: 'me/educationHistories',
    method: 'get',
  },
  getWorkExp: {
    url: 'me/workExperiences',
    method: 'get',
  },
  getCertificates: {
    url: 'me/certificates',
    method: 'get',
  },
  getCertificateById: {
    url: 'me/certificates/{id}',
    method: 'get',
  },
  getEducationById: {
    url: 'me/educationHistories/{id}',
    method: 'get',
  },
  getWorkExpById: {
    url: 'me/workExperiences/{id}',
    method: 'get',
  },
  addEducation: {
    url: 'me/educationHistories',
    method: 'post',
  },
  addWorkExp: {
    url: 'me/workExperiences',
    method: 'post',
  },
  addCertificate: {
    url: 'me/certificates',
    method: 'post',
  },
  updateEducation: {
    url: 'me/educationHistories/{id}',
    method: 'put',
  },
  updateWorkExp: {
    url: 'me/workExperiences/{id}',
    method: 'put',
  },
  updateCertificate: {
    url: 'me/certificates/{id}',
    method: 'put',
  },
  deleteEducation: {
    url: 'me/educationHistories/{id}',
    method: 'delete',
  },
  deleteWorkExp: {
    url: 'me/workExperiences/{id}',
    method: 'delete',
  },
  deleteCertificate: {
    url: 'me/certificates/{id}',
    method: 'delete',
  },
  createLog: {
    url: 'me/logs',
    method: 'post',
  },
  getLog: {
    url: 'me/logs',
    method: 'get',
  },
  updateLog: {
    url: 'me/logs/{id}',
    method: 'put',
  },
  getMandatoryCourses: {
    url: 'me/mandatoryCourses',
    method: 'get',
  },
  getMyCourseOverview: {
    url: 'me/myCourses/{courseId}/overview',
    method: 'get',
  },
  update: {
    url: 'me',
    method: 'patch',
  },
  getStudentGroups: {
    url: 'me/studentGroups',
    method: 'get',
  },
  getCourseProgress: {
    url: 'me/getCourseLogs/{courseId}',
    method: 'get',
  },
  completeEnrolledCourse: {
    url: 'me/completeEnrolledCourse/{courseId}',
    method: 'post',
  },
  checkCoursePaymentStatus: {
    url: 'me/Courses/{courseId}/payment-status',
    method: 'get',
  },
  getNotifications: {
    url: 'me/Notifications',
    method: 'get',
  },
  getNotificationCount: {
    url: 'me/notifications/count',
    method: 'get',
  },
  markAllNotificationsRead: {
    url: 'me/notifications/maskReadAll',
    method: 'post',
  },
  updateNotification: {
    url: 'me/notifications/{fk}',
    method: 'put',
  },
  updatePlayerId: {
    url: 'me/oneSignalPlayers',
    method: 'post',
  },
  contractUs: {
    url: 'me/contractUs',
    method: 'post',
  },
  checkPrerequisite: {
    url: 'me/check-prerequisite',
    method: 'post',
  },
  sendVerifyEmail: {
    url: '{id}/send-verify-email',
    method: 'post',
  },
  sendVerifyEmailWithEmail: {
    url: 'send-verify-email-with-email',
    method: 'post',
  },

  getStudentCertificates: {
    url: '{id}/studentCertificates',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  getCertStatusByCourse: {
    url: '{id}/courses/{courseId}/cert-status',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  verifyEmail: {
    url: 'confirm',
    method: 'get',
  },
  getCourseRating: {
    url: '{id}/courseReviews',
    method: 'get',
    defaultParams: {
      id: 'me',
    },
  },
  postCourseRating: {
    url: '{id}/courseReviews',
    method: 'post',
    defaultParams: {
      id: 'me',
    },
  },
  putCourseRating: {
    url: '{id}/courseReviews/{fk}',
    method: 'put',
    defaultParams: {
      id: 'me',
    },
  },
  getMyFollows: {
    url: 'me/follow',
    method: 'get',
  },
  postMyFollows: {
    url: 'me/follow',
    method: 'post',
  },
  deleteMyFollows: {
    url: 'me/follow/{fk}',
    method: 'delete',
  },

  getStudentAcademyProfile: {
    url: '{id}/schoolStudents',
    method: 'get',
  },

  editStudentAcademyProfile: {
    url: '{id}/schoolStudents/{fk}',
    method: 'put',
  },
})
