import React, { useEffect, useState, useRef } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { FileAPI } from 'api'

import { connect } from 'react-redux'
import UploadComponent from '../uploadComponent'
import { loadCanvasImage } from './image'
import theme from 'theme'
import { Typography, Cropable, Modal } from '../index'
import {
  Content,
  UploadImageStyle,
  ImageBox,
  ImgIconCam,
  LabelCousenName,
  LabelInstructorName,
  // Description,
} from './style'
import { customUploadModal } from './customUploadModal'
import getImageUrl from '../../utils/getImageUrl'

import CourseImageIconCam from '../../images/CourseImageIconCam.png'
//  import { CourseImage } from '../../containers/courseInformationPage/dataCourseImg'

const UploadImageUsetemplate = ({
  // id,
  // label,
  // value,
  onChange,
  name,

  afterUpload,
  error,
  touched,

  cropShape = 'rect',
  aspect,
  grayBorder,
  styleImageBox = {},
  styleUploadImage = {},
  showImageFull = true,
  cropLabel = '',
  cropLabelId,
  cropModal,

  courseNameState,
  instructorNameState,
  positionState,
  defaultCourseImg, /// รูป  โหลดครั้งเเรก
  imgAvatar,
  courseNameColor,
  instructorNameColor,
  positionColor,
  imgtemplate, //รูปที่กด  upload  เอามาเเทน  value เนื่องจากกดเเล้วหาย
  // Alert,
  // showLoading = true,
  canvasRef,

  ...props
}) => {
  const thisPage = window.location.pathname
  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [fileType, setFileType] = useState(null)

  const onClickView = () => {
    setIsOpen(true)
  }

  const imageTemplatebeforeCanvan = useRef()

  useEffect(() => {
    const genCanvas = async () => {
      const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
        let words = text.split('\n')
        let line = ''

        for (let n = 0; n < words.length; n++) {
          let testLine = line + words[n] + ' '
          let metrics = context.measureText(testLine)
          let testWidth = metrics.width
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y - words.length * (lineHeight / 2))
            line = words[n] + ' '
            y += lineHeight
          } else {
            line = testLine
          }
        }
        context.fillText(line, x, y - words.length * (lineHeight / 2))
      }

      try {
        // if (ref.current) {
        if (canvasRef) {
          const canvas = canvasRef.current
          const context = canvas.getContext('2d')
          context.imageSmoothingQuality = 'high'
          context.imageSmoothingEnabled = false
          context.filter = 'blur(0px)'
          //context.clearRect(0, 0, canvas.width, canvas.height)

          let imageSources = []

          // ถ้ามีเเสดงว่า upload รูป
          if (imgtemplate !== '' && imgtemplate !== undefined) {
            // console.log(defaultCourseImg)
            if (defaultCourseImg?.includes('/')) {
              imageSources = [defaultCourseImg]
            } else {
              imageSources = [getImageUrl(defaultCourseImg, null, 1000, 524)]
            }
          }
          //ถ้าไม่มีเเสดงว่าไม่ได้ upload รูป ใช้รูปเริ่มต้น 3 รูป
          else {
            if (defaultCourseImg?.includes('/')) {
              imageSources = [defaultCourseImg]
            } else {
              imageSources = [getImageUrl(defaultCourseImg, null, 1000, 524)]
            }
          }

          //   console.log(imageSources)
          await Promise.all(imageSources.map((i) => loadCanvasImage(i)))
            .then((images) => {
              images.forEach((image) => {
                context.drawImage(image, 0, 0, canvas.width, canvas.height)
              })
            })

            .catch((err) => {
              console.error(err)
            })

          let ImgAvatarSources = [getImageUrl(imgAvatar, null, 1000, 524)]

          await Promise.all(ImgAvatarSources.map((i) => loadCanvasImage(i)))
            .then((images) => {
              images.forEach((image) => {
                context.save()
                context.beginPath()
                context.arc(100, 430, 65, 0, Math.PI * 2, true)
                //context.stroke()
                // context.closePath()
                context.clip()

                context.drawImage(image, 35, 365, 130, 130)

                context.beginPath()
                context.arc(0, 0, 25, 0, Math.PI * 2, true)
                context.clip()
                context.closePath()
                context.restore()

                // context.drawImage(image, 0, 0, 50, 50)
              })
            })
            .catch((err) => {
              console.error(err)
            })

          if (courseNameState !== undefined) {
            context.fillStyle = courseNameColor || '#FFF'
            context.font = `700  ${theme['text-6xl']} Sukhumvit Set`
            context.textAlign = 'center'
            // context.fillText(courseNameState, 500, 262)
            // console.log(courseNameState)

            let maxWidth = 200
            let lineHeight = 40
            let x = 500
            let y = 262

            wrapText(context, courseNameState, x, y, maxWidth, lineHeight)

            context.imageSmoothingEnabled = false
            context.filter = 'blur(0px)'
            context.fillStyle = instructorNameColor || '#FFF'
            context.font = `${theme['text-lg']} Sukhumvit Set`
            context.textAlign = 'start'
            context.fillText(instructorNameState, 180, 395)

            context.fillStyle = positionColor || '#FFF'
            context.font = `${theme['text-lg']} Sukhumvit Set`
            context.textAlign = 'start'
            context.fillText(positionState, 180, 432)

            // if (imageTemplatebeforeCanvan.current) {
            //   //console.log(onChange)
            //   const e = {
            //     target: {
            //       value: imageTemplatebeforeCanvan.current,
            //       name: 'image',
            //     },
            //   }
            //   // console.log('00000--' + imageTemplatebeforeCanvan.current)
            //   onChange(e)
            // }
          }
        }
      } catch (e) {
        console.log('gen canvas err', e)
      } finally {
        // console.log('success', canvasRef.current)
        // console.log('data1', canvasRef.current?.toDataURL)
        // console.log('data2', canvasRef.current?.toDataURL?.('image/jpeg')?.length)
      }
    }
    genCanvas()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    imgtemplate,
    defaultCourseImg,
    courseNameState,
    instructorNameState,
    positionState,
    courseNameColor,
    instructorNameColor,
    positionColor,
    imgAvatar,
  ])

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    context.imageSmoothingQuality = 'high'
    context.clearRect(0, 0, canvas.width, canvas.height)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgtemplate, thisPage])

  const renderLightbox = () => {
    const base64 = canvasRef.current.toDataURL('image/jpeg')
    return <Lightbox mainSrc={base64} onCloseRequest={() => setIsOpen(false)} />
  }

  const beforeCrop = (formData) => {
    const file = formData.get('file')
    const objectURL = URL.createObjectURL(file)
    setFile(objectURL)
    setFileType(file.type)
  }

  const afterCrop = async (blob) => {
    try {
      const file = await fetch(blob)
        .then((res) => res.blob())
        .then((blob) => {
          let filename = 'filename.jpeg'
          if (fileType === 'image/png') {
            filename = 'filename.png'
          }
          const newFile = new File([blob], filename, { type: fileType })
          return newFile
        })
      const formData = new FormData()
      formData.append('file', file)

      const res = await FileAPI.upload(formData)

      imageTemplatebeforeCanvan.current = res.result.files.file[0].name

      if (afterUpload) {
        afterUpload({ name: res.result.files.file[0].name })
      }

      if (onChange) {
        //  console.log('🌃', res.result.files.file[0].name)
        // console.log(res.result.files.file[0].name)
        //console.log(onChange)
        const e = {
          target: {
            value: res.result.files.file[0].name,
            name: 'image',
          },
        }

        onChange(e)
      }
    } catch (error) {
      throw error
    } finally {
      setFile(null)
    }
  }

  return (
    <Content value={imgtemplate}>
      {isOpen && renderLightbox()}
      {/* {label && (
        <Label
          id={id}
          htmlFor={name}
          isRequired={props.isRequired}
          bold={props.bold}
          color={props.color}
        >
          {label}
        </Label>
      )} */}

      <>
        <UploadImageStyle styleUploadImage={styleUploadImage} className="upload-image">
          <ImageBox style={styleImageBox} grayBorder={grayBorder} aspectRatio={aspect}>
            <>
              <LabelCousenName>
                <canvas
                  width="1000"
                  height="524"
                  style={{ width: '200%', height: '100%' }}
                  ref={canvasRef}
                  {...props}
                />
              </LabelCousenName>
              <LabelInstructorName></LabelInstructorName>

              <UploadComponent
                limitSize={5242880} // 5 MB
                afterUpload={(image) => {
                  onChange(name, image.name)
                }}
                beforeCrop={beforeCrop}
                acceptFileTypes={['image/jpeg', 'image/png']}
              >
                <ImgIconCam src={CourseImageIconCam} width="40px" alt="" />{' '}
              </UploadComponent>
            </>
          </ImageBox>
        </UploadImageStyle>

        <Modal
          isOpen={file}
          ariaHideApp={false}
          overlayStyle={customUploadModal.overlay}
          contentStyle={customUploadModal.content}
        >
          <Cropable
            isModal={cropModal}
            fileType={fileType}
            image={file}
            afterCrop={afterCrop}
            cropShape={cropShape}
            aspect={aspect}
            cropLabel={cropLabel}
            cropLabelId={cropLabelId}
            handleClearFile={() => setFile(null)}
          />
        </Modal>
      </>

      {error && touched && (
        <Typography margin="5px 0px" strong error>
          {error}
        </Typography>
      )}
      {showImageFull && (
        <Typography
          id="label.fullimage"
          className="image-view"
          color="primary13"
          onClick={onClickView}
        >
          Click to view full image
        </Typography>
      )}
    </Content>
  )
}

UploadImageUsetemplate.defaultProps = {
  label: '',
  detail: 'Click or Drag photo to this area to upload',
  onChange: () => null,
  cropShape: '',
  aspect: '',
}

const mapDispatch = ({ Alert }) => ({ Alert })

export default connect(null, mapDispatch)(UploadImageUsetemplate)
