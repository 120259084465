import { formatLabel } from './commaize'

const feePercentage = +process.env.REACT_APP_COURSE_FEE_PERCENTAGE

const getCoursePrice = (price) => {
  const fee = (feePercentage * price) / 100
  const schoolIncome = price - fee

  return {
    fee: formatLabel(fee),
    schoolIncome: formatLabel(schoolIncome),
  }
}

export default getCoursePrice
