/* eslint-disable no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { ClasswinEditor, Button, Typography } from '../../../components'
import QuestionsItem from '../../questionsItem'
import PlusIcon from 'images/icons/primary-plus.svg'

const ShortAnswer = ({
  title,
  choices,
  assessmentId,
  answers,
  id: questionId,
  getQuestionData,
  handleDeleteAnswer,
  handleAddAnswer,
  handleAutoSave,
  locale,
  setTitle,
  setChoices,
  setAnswers,
}) => {
  return (
    <>
      <Formik initialValues={{ title }} enableReinitialize>
        {() => (
          <Form>
            <Typography id="label.question" color="primary1" margin="0 0 8px">
              Question
            </Typography>
            <Field name="title">
              {({ field, form }) => (
                <ClasswinEditor
                  style={{ marginBottom: '0px' }}
                  id="title"
                  name="title"
                  value={field.value}
                  placeholder={locale === 'th' ? 'คำถาม' : 'Question'}
                  onChange={(value) => {
                    const e = {
                      target: {
                        name: 'title',
                        value,
                      },
                    }
                    form.handleChange(e)
                    setTitle(value)
                  }}
                />
              )}
            </Field>
            {answers.length > 0 && (
              <Typography id="course.content.answer" color="primary1" margin="12px 0 8px">
                Answer(s)
              </Typography>
            )}
            {answers.map((c, i) => (
              <QuestionsItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                index={i}
                title={c}
                handleDeleteAnswer={handleDeleteAnswer}
                assessmentId={assessmentId}
                choices={choices}
                answers={answers}
                questionId={questionId}
                getQuestionData={getQuestionData}
                handleAutoSave={handleAutoSave}
                isAnswer
                setChoices={setChoices}
                setAnswers={setAnswers}
              />
            ))}
            <Button
              id="button.add.answer"
              type="button"
              image={<img src={PlusIcon} alt="plus-blue-icon" />}
              whiteBorder
              margin={answers.length > 0 ? '4px 0 0' : '16px 0 0'}
              onClick={handleAddAnswer}
            >
              Add Answer
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapState = ({ Language: { locale } }) => ({ locale })

export default connect(mapState)(ShortAnswer)
