import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

import CancelIcon from '../../images/ic-cancelbtn-gray-24-px.svg'
import { Button, Icon } from '../../components'
import { Step1, Step2 } from './steps'
import ErrorBoundary from '../../components/errorBoundary'
import { DesktopAndTablet, SmallMobile } from 'utils/displayResponsive'

const steps = [
  {
    component: Step1,
  },
  {
    component: Step2,
  },
]

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px 24px 40px 24px;
  @media (max-width: 768px) {
    padding: 24px;
  }
`
const Header = styled.div`
  position: absolute;
  margin: 20px;
  right: 0;
  z-index: 301;
  @media (max-width: 768px) {
    margin: 15px;
  }
`

const StyledForm = styled(Form)`
  position: relative;
  background-color: white;
  align-self: center;
  border-radius: 8px;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

class SchoolCreateCourseLayout extends Component {
  state = {
    step: 1,
  }

  handleStep = (type) => (e) => {
    e.preventDefault()
    if (type === 'next') {
      return this.setState(({ step }) => {
        const addStep = step + 1
        if (addStep > steps.length) return null
        return { step: addStep }
      })
    }
    return this.setState(({ step }) => {
      const reduceStep = step - 1
      if (reduceStep === 0) return null
      return { step: reduceStep }
    })
  }

  onSubmit = async ({ category, subcategory, ...values }) => {
    const { handleModal } = this.props
    const {
      history,
      match: {
        params: { schoolId },
      },
      createMySchoolDraftCourse,
    } = this.props
    try {
      const data = {
        id: schoolId,
        name: values.title,
        // type: 'online',
        categoryId: category.id,
        subCategoryId: subcategory.id,
        ...values,
      }
      delete data.title
      const { id: courseId } = await createMySchoolDraftCourse(data)
      handleModal()
      history.replace(`/schools/${schoolId}/course-draft/${courseId}`)
    } catch (error) {
      throw error
    }
  }

  renderStepComponent = ({ step, ...props }) => {
    const { component } = steps[step - 1]
    return React.createElement(component, props)
  }

  render() {
    const { step } = this.state
    const { renderStepComponent: StepComponent } = this
    const { handleModal } = this.props
    return (
      <ErrorBoundary>
        <Formik
          enableReinitialize
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Required'),
            shortDescription: Yup.string().required('Required'),
            description: Yup.string(),
            courseCode: Yup.string().required('Required'),
            publicMode: Yup.string().required('Required'),
            category: Yup.string(),
            subcategory: Yup.string(),
          })}
          onSubmit={this.onSubmit}
          initialValues={{
            title: '',
            shortDescription: '',
            description: '',
            courseCode: '',
            publicMode: 'CODE',
            category: '',
            subcategory: '',
          }}
          render={(props) => (
            <StyledForm>
              <Header>
                <Icon
                  style={{ cursor: 'pointer' }}
                  size={25}
                  src={CancelIcon}
                  alt="icon"
                  onClick={handleModal}
                />
              </Header>
              <Content>
                <StepComponent step={step} formik={props} />
                <Footer>
                  {step !== 1 && (
                    <Button
                      id="button.back"
                      type="button"
                      whiteBorder
                      width="100%"
                      height={48}
                      marginText="0 0 2px"
                      onClick={this.handleStep('back')}
                    >
                      Back
                    </Button>
                  )}
                  {step === steps.length ? (
                    <Button
                      id="button.Create"
                      type="submit"
                      right
                      primary
                      width="100%"
                      height={48}
                      marginText="0 0 2px"
                    >
                      Create
                    </Button>
                  ) : (
                    <>
                      <DesktopAndTablet>
                        <Button
                          id="button.Next"
                          type="button"
                          right
                          width={240}
                          height={48}
                          fontSize={'text-base'}
                          primary={!(props.values.publicMode === '')}
                          onClick={this.handleStep('next')}
                          disabled={props.values.publicMode === ''}
                        >
                          Next
                        </Button>
                      </DesktopAndTablet>
                      <SmallMobile>
                        <Button
                          id="button.Next"
                          type="button"
                          right
                          width="100%"
                          primary={!(props.values.publicMode === '')}
                          onClick={this.handleStep('next')}
                          disabled={props.values.publicMode === ''}
                        >
                          Next
                        </Button>
                      </SmallMobile>
                    </>
                  )}
                </Footer>
              </Content>
            </StyledForm>
          )}
        />
      </ErrorBoundary>
    )
  }
}

const mapState = null
const mapDispatch = ({ MySchool: { createMySchoolDraftCourse } }) => ({ createMySchoolDraftCourse })

export default connect(mapState, mapDispatch)(withRouter(SchoolCreateCourseLayout))
