import React from 'react'
import styled from 'styled-components'
import { Typography } from 'components'
import ErrorBoundary from '../errorBoundary'
import { Radio } from 'antd'

const StyledLabel = styled((props) => <Typography {...props} />)`
  margin-bottom: 0;
`

const CustomRadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  labelId,
  fontSize = 'text-sm',
  color,
  ...props
}) => {
  return (
    <ErrorBoundary>
      <Radio
        name={name}
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <StyledLabel fontSize={fontSize} htmlFor={id} id={labelId} color={color}>
        {label}
      </StyledLabel>
    </ErrorBoundary>
  )
}

export default CustomRadioButton
