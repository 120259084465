import styled, { css } from 'styled-components'
import DatePicker from 'react-datepicker'
import theme from '../../theme'

const DateTime = styled(DatePicker)`
  width: 100%;
  padding: 7px 11px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  height: 40px;
  outline: none;
  margin: 0;
  color: ${theme.dark};
  font-size: ${({ theme }) => theme['text-sm']};
  line-height: 1.5;
  background-color: transparent;
  list-style: none;
  opacity: 80%;

  :hover {
    border-color: ${theme.primary1};
    opacity: 100%;
  }

  ::placeholder {
    font-weight: 400;
    color: ${theme.black38};
  }
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  border: 1px solid ${theme.black38};
  border-radius: 6px;

  :hover {
    border-color: ${theme.primary1};
    opacity: 100%;
  }

  ${({ status }) => {
    if (status === 'error') {
      return css`
        background-color: ${theme.white};
        border-color: ${theme.danger};
      `
    }
  }}
`

const Span = styled.span`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border: none;
  padding: 0 11px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: transparent;
`

export { DateTime, Wrapper, Span }
