/* eslint-disable no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import { v4 as uuid } from 'uuid'
import 'rc-time-picker/assets/index.css'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import TrashIcon from 'images/ic-bin-gray-24-px.svg'
import PlusIcon from 'images/ic-plus-white-24-px.svg'
// import TutorialIcon from 'images/Classwin - New icon_Tutorial.svg'
import { ClasswinEditor, Button, Icon, Input, Switch } from 'components'
import Typography from 'components/typography'

const validationSchema = yup.object().shape({
  username: yup.string().max(16),
  timeRequired: yup.string().when('isTimeRequired', {
    is: true,
    then: yup.string().required(),
  }),
  question: yup.object().when('isQuestionRequired', {
    is: true,
    then: yup.object().shape({
      choices: yup.array().min(1).required(),
    }),
  }),

  // password: yup
  //   .string()
  //   .min(8)
  //   .required(),
  // confirm_password: yup
  //   .string()
  //   .required()
  //   .oneOf([yup.ref('password'), null], 'Passwords must match'),
})
const Container = styled.div`
  min-width: 480px;
`

const Span = styled.span`
  display: flex;

  input[type='checkbox'] {
    accent-color: ${({ theme }) => theme.primary1};
    width: 15px;
    height: 15px;
  }
`
const Content = styled.div`
  padding: 12px 0 0 32px;
`

const DeleteQuestionButton = styled(Icon)`
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  float: right;
  margin: 0 5px 0 0;
  position: absolute;
  top: calc(50% - 11px);
  right: 0;
  cursor: pointer;
`
const EditorWrapper = styled.div`
  .ql-formats > button > svg {
    position: unset;
  }

  .editing-area .ql-container .ql-editor {
    min-height: 90px;
    max-width: 439px;
  }

  &.answer {
    .editing-area .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-radius: 6px;
      margin-left: 10px;
    }
    position: relative;
    .ql-toolbar.ql-snow {
      display: none;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-top: 1px solid #ccc;
    }
    .editing-area .ql-container .ql-editor {
      padding: 6px 11px;
      min-height: 40px;
      padding-right: 25px;
    }
  }

  &.explainer {
    .editing-area .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-radius: 6px;
    }
    grid-column: 2;
    margin: 5px 0 0 0;
    padding: 0 0 0 7%;
    .ql-toolbar.ql-snow {
      display: none;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-top: 1px solid #ccc;
    }
    .editing-area .ql-container .ql-editor {
      min-height: 30px;
    }
    .ql-editor {
      padding: 8px 15px;
    }
  }
`

// const SaveButton = styled(Button)`
//   position: absolute;
//   z-index: 10;
//   top: 10px;
//   right: 10px;
// `

const Grid = styled.div`
  display: grid;
  grid-template-columns: 12px 1fr;
  grid-gap: 0 10px;
  margin: 10px 0 0 0;
  align-items: center;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : 16)}px;
`
const TimerDesc = styled.div`
  font-size: ${(props) => props.theme['text-sm']};
  color: ${(props) => props.theme.black60};
`
const AnswerCheckbox = (props) => {
  return (
    <Field name={props.name}>
      {({ form }) => {
        return (
          <Span>
            <input
              // {...field}
              type="checkbox"
              checked={form.values.question.answer.includes(props.value)}
              onChange={async () => {
                const set = new Set(form.values.question.answer)
                if (set.has(props.value)) {
                  set.delete(props.value)
                } else {
                  set.add(props.value)
                }
                await form.setFieldValue(`question.answer`, Array.from(set))
                await form.submitForm()
                // form.setFieldTouched(`${field.name}`, true)
              }}
            />
          </Span>
        )
      }}
    </Field>
  )
}

// /////////////////////////main function////////////////////////////////////////////////////

const LectureSettingModal = (props) => {
  const handleAddChoice = async (form) => {
    const { setFieldValue, values } = form
    const uid = uuid()
    const newChoices = {
      uid,
      content: JSON.stringify({ content: '', delta: { ops: [{ insert: '' }] } }),
      explainer: JSON.stringify({ content: '', delta: { ops: [{ insert: '' }] } }),
      // "{\"content\":\"<p>Wrong</p>\",\"delta\":{\"ops\":[{\"insert\":\"Wrong\\n\"}]}}"
    }
    await setFieldValue('question.choices', [...values.question.choices, newChoices])
    values.question.choices = [...values.question.choices, newChoices]
    await form.submitForm()
  }

  const handleDeleteChoice = async (form, uid) => {
    const { setFieldValue, values } = form
    const newChoices = values.question.choices.filter((item) => item.uid !== uid)
    // const newAnswer = values.question.answer.filter((item) => item.uid !== uid)
    // setFieldValue('question.answer', newAnswer)
    setFieldValue('question.choices', newChoices)
    const set = new Set(values.question.answer)
    set.delete(uid)
    await form.setFieldValue(`question.answer`, Array.from(set))
    form.setFieldTouched(`question.answer`, true)
    await form.submitForm()
  }

  const handleSubmit = async (values) => {
    const { updateLecture, subjectId, lectureId, error } = props
    const {
      enableCompleteButton,
      isTimeRequired,
      timeRequired,
      durationPercentage,
      isQuestionRequired,
      question,
    } = values
    const data = { subjectId, lectureId, enableCompleteButton }
    if (enableCompleteButton) {
      if (isTimeRequired) {
        data.isTimeRequired = isTimeRequired
        data.timeRequired = timeRequired
        data.durationPercentage = durationPercentage
      } else {
        data.isTimeRequired = false
        data.timeRequired = null
        data.durationPercentage = null
      }
      if (isQuestionRequired) {
        data.isQuestionRequired = isQuestionRequired
        data.question = question
      } else {
        data.isQuestionRequired = false
        data.question = null
      }
    } else {
      data.isTimeRequired = false
      data.timeRequired = null
      data.durationPercentage = null
      data.isQuestionRequired = isQuestionRequired
      data.question = null
    }
    try {
      await updateLecture(data)
      // success({ title: 'Update Successfully' })
    } catch (err) {
      error({ title: 'Update Failed' })
      throw err
    }
  }

  const debounceInput = debounce(async (payload) => {
    try {
      await handleSubmit(payload)
    } catch (error) {
      throw error
    }
  }, 3000)

  const Checkbox = (prop) => {
    return (
      <Field name={prop.name}>
        {({ field, form }) => (
          <Span style={prop.style}>
            {/* <input
              {...field}
              type="checkbox"
              checked={field.value}
              onChange={async () => {
                await form.setFieldValue(prop.name, !field.value)
                await form.submitForm()
              }}
            /> */}
            <Switch
              type="checkbox"
              name="publish"
              //checked={publishable}
              //onChange={() => this.handlePublishCourse()}
              checked={field.value}
              // onChange={e => field.onChange(e)}
              onChange={async () => {
                await form.setFieldValue(prop.name, !field.value)
                await form.submitForm()
              }}
            />
          </Span>
        )}
      </Field>
    )
  }

  const {
    enableCompleteButton,
    isTimeRequired,
    timeRequired,
    isQuestionRequired,
    question,
    durationPercentage,
    selectType,
    locale,
    lectureType,
  } = props

  // const tutorialLink = 'http://classwin.co/create-lecture-complete/'
  return (
    <>
      <Container>
        <Formik
          initialValues={{
            enableCompleteButton: enableCompleteButton || true,
            isTimeRequired: isTimeRequired || false,
            timeRequired: timeRequired || '0',
            durationPercentage: durationPercentage || 95,
            isQuestionRequired: isQuestionRequired || false,
            lectureType: lectureType || false,
            question: question || {
              // title: JSON.stringify({
              //   content: '',
              // }),
              title: '{"content":"<p>Question</p>","delta":{"ops":[{"insert":"\\n"}]}}',
              choices: [
                {
                  content: JSON.stringify({ content: '', delta: { ops: [{ insert: '' }] } }),
                  explainer: JSON.stringify({ content: '', delta: { ops: [{ insert: '' }] } }),
                  uid: uuid(),
                },
              ],
              answer: [],
            },
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {(form) => (
            <div style={{ position: 'relative' }}>
              {/* <SaveButton primary type="submit" onClick={form.handleSubmit}>
                Save
              </SaveButton> */}
              {/* {lectureType === 'browse' && (
                <>
                  <Typography
                    id="course.content.lecture.completerules"
                    h4
                    margin="24px 0 21px"
                    // icon={
                    //   <a href={tutorialLink} target="_blank" rel="noreferrer">
                    //     <Icon style={{ width: '25px', height: '20px' }} src={TutorialIcon} />
                    //   </a>
                    // }
                  >
                    Lecture Completation Rules
                  </Typography>
                  <Item>
                    <Checkbox name="enableCompleteButton" />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography id="course.content.lecture.completerules.add" color="primary1">
                        Add lecture completion rules
                      </Typography>
                      <Typography color="primary1">
                        {locale === 'th'
                          ? '(กำหนดอัตราร้อยละที่ผู้เรียนต้องใช้ในการเรียนวิดีโอนี้)'
                          : '(Set percentage of the video’s duration to complete this video)'}
                      </Typography>
                    </div>
                  </Item>
                </>
              )} */}
              {form.values.enableCompleteButton && (
                <Content>
                  {/* {selectType === 'browse' && (
                    <Item>
                      <div style={{ margin: '12px 0 0 32px', width: 100 }}>
                        <Input
                          id=""
                          name="durationPercentage"
                          label=""
                          type="number"
                          htmlFor="durationPercentage"
                          defaultOpenValue={95}
                          value={form.values.durationPercentage}
                          onChange={(e) => {
                            const { value } = e.target
                            form.setFieldValue('durationPercentage', value)
                            const payload = {
                              ...form.values,
                              durationPercentage: value,
                            }
                            debounceInput(payload)
                          }}
                          suffix={'%'}
                          spanPadding="0 12px"
                        />
                      </div>
                    </Item>
                  )} */}
                  {/* {form.values.isTimeRequired && (
                    <div style={{ margin: '12px 0 0 32px', width: 100 }}>
                      <Input
                        id=""
                        name="durationPercentage"
                        label=""
                        type="number"
                        htmlFor="durationPercentage"
                        defaultOpenValue={95}
                        value={form.values.durationPercentage}
                        onChange={(e) => {
                          const { value } = e.target
                          form.setFieldValue('durationPercentage', value)
                          const payload = {
                            ...form.values,
                            durationPercentage: value,
                          }
                          debounceInput(payload)
                        }}
                        suffix={'%'}
                        spanPadding="0 12px"
                      />
                    </div>
                    // <TimePickerWrapper>
                    //   <TimePicker
                    //     defaultOpenValue={moment('00:01:00', 'HH:mm:ss')}
                    //     onChange={(e) => {
                    //       const value = e ? e.format() : e
                    //       form.setFieldValue('timeRequired', value)
                    //       const payload = {
                    //         ...form.values,
                    //         timeRequired: value,
                    //       }
                    //       debounceInput(payload)
                    //     }}
                    //     value={form.values.timeRequired ? moment(form.values.timeRequired) : null}
                    //     // value={moment('00:00', 'HH:mm')}
                    //     showSecond
                    //     placeholder="HH:mm:ss"
                    //     allowEmpty
                    //     // inputReadOnly={false}
                    //   />
                    // </TimePickerWrapper>
                  )} */}
                  {/* <Item>
                    <Checkbox style={{ marginTop: '5px' }} name="isQuestionRequired" />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography id="course.content.lecture.completerules.question">
                        Question
                      </Typography>
                      <Typography
                        id="course.content.lecture.completerules.question.desc"
                        style={{ color: theme.gray2, paddingLeft: '5px' }}
                      >
                        (Set question to complete this lecture)
                      </Typography>
                    </div>
                  </Item> */}
                  {form.values.isQuestionRequired && (
                    <>
                      <Typography
                        id="label.question"
                        style={{ cursor: 'pointer' }}
                        padding="10px 0px"
                        strong
                      >
                        Question
                      </Typography>

                      <Field name="question.title">
                        {({ field }) => (
                          <EditorWrapper>
                            <ClasswinEditor
                              style={{ marginBottom: '0px' }}
                              id="title"
                              name="title"
                              placeholder="Add question"
                              modules={{
                                toolbar: [
                                  // [{ header: 1 }, { header: 2 }],
                                  // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                  ['bold', 'italic'],
                                  // [{ color: [] }, { background: [] }],
                                  ['link', 'image', 'video'],
                                  ['code-block'],
                                ],
                              }}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                                const payload = {
                                  ...form.values,
                                  question: { ...form.values.question, title: value },
                                }
                                debounceInput(payload)
                              }}
                            />
                          </EditorWrapper>
                        )}
                      </Field>
                      <Typography
                        id="label.answers"
                        style={{ cursor: 'pointer' }}
                        padding="10px 0px"
                        strong
                      >
                        Answers
                      </Typography>
                      <Button
                        id="button.add.choice"
                        type="button"
                        primary
                        image={<img src={PlusIcon} alt="plus-icon" />}
                        onClick={() => handleAddChoice(form)}
                      >
                        Add Choice
                      </Button>
                      {form.values.question.choices.map((choice, index) => {
                        return (
                          <Grid key={index + ''}>
                            <AnswerCheckbox
                              name={`question.answer[${index}]`}
                              value={choice.uid}
                              submitForm={form.submitForm}
                            />
                            <Field name={`question.choices[${index}].content`}>
                              {({ field }) => (
                                <EditorWrapper className="answer">
                                  <ClasswinEditor
                                    style={{ marginBottom: '0px' }}
                                    id="title"
                                    name="title"
                                    modules={{
                                      toolbar: [['bold', 'italic'], ['code-block']],
                                    }}
                                    value={field.value}
                                    placeholder="Add an answer."
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value)
                                      const newForm = cloneDeep(form.values)
                                      newForm.question.choices[index].content = value
                                      debounceInput(newForm)
                                    }}
                                  />
                                  <DeleteQuestionButton
                                    src={TrashIcon}
                                    onClick={() => handleDeleteChoice(form, choice.uid)}
                                  />
                                </EditorWrapper>
                              )}
                            </Field>
                            <Button onClick={() => handleDeleteChoice(form, choice.uid)}>
                              delete
                            </Button>
                            <Field name={`question.choices[${index}].explainer`}>
                              {({ field }) => (
                                <EditorWrapper className="explainer">
                                  <ClasswinEditor
                                    style={{ marginBottom: '0px' }}
                                    id="title"
                                    name="title"
                                    modules={{
                                      toolbar: [['bold', 'italic'], ['code-block']],
                                    }}
                                    value={field.value}
                                    placeholder="Explain why this is or isn't the best answer."
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value)
                                      const newForm = cloneDeep(form.values)
                                      newForm.question.choices[index].explainer = value
                                      debounceInput(newForm)
                                    }}
                                  />
                                </EditorWrapper>
                              )}
                            </Field>
                          </Grid>
                        )
                      })}
                    </>
                  )}
                </Content>
              )}
            </div>
          )}
        </Formik>
      </Container>
    </>
  )
}

const mapState = ({ Subject: { lectureInfo }, Language: { locale } }) => ({
  ...lectureInfo,
  locale,
})
const mapDispatch = ({
  Subject: { getLectureInfo, updateLecture },
  Alert: { success, error },
}) => ({
  getLectureInfo,
  updateLecture,
  success,
  error,
})

export default connect(mapState, mapDispatch)(LectureSettingModal)
