import React from 'react'
import styled from 'styled-components'
import { Typography as _Typography } from 'components'

const Container = styled.div`
  width: 100%;
`
const Typography = styled((props) => <_Typography {...props} />)`
  font-size: 16px;
  font-family: 'Sarabun';
`

const Content = ({ text, style }) => {
  return (
    <Container style={style}>
      <Typography>{text}</Typography>
    </Container>
  )
}

export default Content
