import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import SchoolHeading from 'components/schoolHeading/loadable'

import isEmpty from 'lodash/isEmpty'
import store from '../../store'
import {
  Container,
  Content,
  Menu,
  Heading,
  Card,
  Icon,
  Cardmenu,
  //HeaderCard,
  HeaderMenu,
  TabBar,
} from './styles'
// import TabComponent from '../../components/tabComponent/loadable'
// import { tabs, mySchoolTabs } from './tabs'
import Typography from 'components/typography'
import ProfileImage from '../../components/profileImage'
import getImageUrl from '../../utils/getImageUrl'
import AcademyAnouncement from '../../images/icons/AcademyAnouncement.svg'
import AcademyActivities from '../../images/icons/AcademyActivities.svg'
import AcademyCourses from '../../images/icons/AcademyCourses.svg'
import AcademyAbout from '../../images/icons/AcademyAbout.svg'
import AcademyAnouncementBlue from '../../images/icons/AcademyAnouncementBlue.svg'
import AcademyActivitiesBlue from '../../images/icons/AcademyActivitiesBlue.svg'
import AcademyCoursesBlue from '../../images/icons/AcademyCoursesBlue.svg'
import AcademyAboutBlue from '../../images/icons/AcademyAboutBlue.svg'
import RecommendedCourses from 'components/schoolProfile/RecommendedCourses'
import { Custom } from 'utils/displayResponsive'
const Body = styled.div`
  overflow-x: hidden;
  margin: 0px;
  width: 100%;
`
const CardProfileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 38px;
`

const ProfileCol = styled.div`
  margin-right: 4px;
  border: 1px solid #fff;
  :last-child {
    margin-right: 0;
  }
`

const CircleNumber = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  font-size: ${({ theme }) => theme['text-lg']};
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #4a4a4a;
  background-color: ${(props) => props.theme.grey};
`

const SchoolLayout = (props) => {
  const {
    school,
    //schoolMember,
    status,
    match,
    children,
    location: { pathname },
    joinSchool,
    appUser,
    location,
    history,
    mySchoolList,
    // getSchoolMember,
    getMySchoolList,
    getSchoolBySlug,
    getSchoolTeacherMember,
    getSchoolById,
    getSchoolStudentsCount,
    schoolStudentsCount,
    deleteSchool,
    follows,
    isFollowing,
    postMyFollows,
    deleteMyFollows,
    getMyFollows,
  } = props

  const cardPictureRow = (school.schoolStudents || []).map(({ appUser }) => ({
    src: getImageUrl(appUser?.profileImage, 'https://via.placeholder.com/80', 80, 80),
  }))

  // const studentProfile = mySchoolList.filter((item) => {
  //   item.school.name === school.name ? item.school.schoolStudents : null
  // })
  //console.log(studentProfile)
  // cardPictureRow: item.school.schoolStudents.map(({ appUser }) => ({
  //   src: getImageUrl(
  //     appUser?.profileImage,
  //     'https://via.placeholder.com/80',
  //     80,
  //     80
  //   ),
  // })),
  const url = pathname.split('/').pop()

  const fetchSchool = useCallback(async () => {
    if (match.params.schoolSlug) {
      const { id } = await getSchoolBySlug(match.params.schoolSlug)
      // await getSchoolMember(id)
      await getSchoolBySlug(match.params.schoolSlug)
      await getSchoolTeacherMember({ schoolId: id })
      await getSchoolStudentsCount({ schoolId: id })
    }
    if (match.params.schoolId) {
      // await getSchoolMember(match.params.schoolId)
      await getSchoolById(match.params.schoolId)
      await getSchoolTeacherMember({ schoolId: match.params.schoolId })
      await getSchoolStudentsCount({ schoolId: match.params.schoolId })
    }
    await getMySchoolList()
  }, [
    getMySchoolList,
    getSchoolById,
    getSchoolBySlug,
    getSchoolStudentsCount,
    getSchoolTeacherMember,
    match.params.schoolId,
    match.params.schoolSlug,
  ])

  const onJoinSchool = () => {
    if (isEmpty(appUser)) {
      history.push({
        pathname: '/user/login',
        state: { from: location },
      })
    } else {
      joinSchool(school.id)
    }
  }
  const genNew = ({ src, ShowCss = false, key }) => {
    const _Parent = (children) => <ProfileCol key={key}>{children}</ProfileCol>
    const _ReturnValue = ShowCss ? (
      <CircleNumber key={key + src}>+{src}</CircleNumber>
    ) : (
      _Parent(
        <ProfileImage
          width={40}
          key={key + src}
          src={getImageUrl(src, 'https://via.placeholder.com/80', 80, 80)}
        />
      )
    )
    return _ReturnValue
  }
  const genCardProfileImage = (Items, numberOfStudent) => {
    const ShowCss = true
    let key
    if (Items.length < 3) {
      const returnValue = Items.map(({ src }, index) => {
        key = index
        if (index + 1 === Items.length) {
          return genNew({ src, key })
        }
        return genNew({ src, key })
      })
      return returnValue
    }
    // const NewItem = Items.slice(0, 3).map(({ src }, index) => {
    //   key = index
    //   return genNew({ src, key })
    // })
    const NewItem = Items.map(({ src }, index) => {
      key = index
      return genNew({ src, key })
    })
    key = numberOfStudent - 3
    const returnValue = [...NewItem, genNew({ src: numberOfStudent - 3, ShowCss, key })]
    return returnValue
  }

  const onChangePath = (path) => {
    history.push(`${match.url}/${path}`)
  }
  useEffect(() => {
    fetchSchool()
    getMyFollows()
  }, [fetchSchool, getMyFollows])

  const exitSchool = async () => {
    const mySchool = mySchoolList.find((sch) => sch.schoolId === school.id)
    await deleteSchool(mySchool.id)
  }

  const onFollowSchool = async () => {
    await postMyFollows({ followingId: school.id, class: 'school' })
  }
  const onUnFollowSchool = async () => {
    const followSchool = follows.find((follow) => follow.followingId === school.id)
    await deleteMyFollows(followSchool.id)
  }

  return (
    <Container column={1}>
      <Custom minWidth={768}>
        <Heading>
          <Typography
            id="AcademyProfile"
            fontSize="text-lg"
            nowrap
            width="100%"
            color="primary1"
            bold
          >
            Academy Profile
          </Typography>
        </Heading>
        <Content>
          <Menu>
            <SchoolHeading
              {...school}
              status={status}
              followed={isFollowing}
              joinSchool={onJoinSchool}
              numberOfStudent={schoolStudentsCount?.countCurrent}
              deleteSchool={exitSchool}
              onFollowSchool={onFollowSchool}
              onUnFollowSchool={onUnFollowSchool}
            />
            {/* <TabComponent
            style={{ paddingLeft: 0 }}
            tabData={match.path === '/:schoolSlug' ? tabs : mySchoolTabs}
            onChangeTab={onChangeTab}
          /> */}
            <Cardmenu>
              <div
                onClick={() => onChangePath('announcement')}
                style={{
                  backgroundColor: `${url === 'announcement' ? '#EBF4F9' : '#fff'}`,
                  padding: '12px 24px 12px 24px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: '6px 6px 0px 0px',
                }}
              >
                <Icon
                  src={url === 'announcement' ? AcademyAnouncementBlue : AcademyAnouncement}
                  alt="AcademyAnouncement"
                  style={{
                    width: 20,
                    height: 20,
                    right: '0px',
                  }}
                />
                <Typography
                  id="AcademyProfile.Announcement"
                  color={url === 'announcement' ? 'primary1' : 'black87'}
                  fontWeight={url === 'announcement' ? '700' : '400'}
                  flex
                >
                  Anouncement
                </Typography>
              </div>
              <div
                onClick={() => onChangePath('activities')}
                style={{
                  backgroundColor: `${url === 'activities' ? '#EBF4F9' : '#fff'}`,
                  padding: '12px 24px 12px 24px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Icon
                  src={url === 'activities' ? AcademyActivitiesBlue : AcademyActivities}
                  alt="AcademyActivities"
                  style={{
                    width: 20,
                    height: 20,
                    right: '0px',
                  }}
                />
                <Typography
                  id="AcademyProfile.Activities"
                  color={url === 'activities' ? 'primary1' : 'black87'}
                  fontWeight={url === 'activities' ? '700' : '400'}
                  flex
                >
                  Activities
                </Typography>
              </div>
              <div
                onClick={() => onChangePath('courses')}
                style={{
                  backgroundColor: `${url === 'courses' ? '#EBF4F9' : '#fff'}`,
                  padding: '12px 24px 12px 24px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Icon
                  src={url === 'courses' ? AcademyCoursesBlue : AcademyCourses}
                  alt="AcademyCourses"
                  style={{
                    width: 20,
                    height: 20,
                    right: '0px',
                  }}
                />
                <Typography
                  id="AcademyProfile.Courses"
                  color={url === 'courses' ? 'primary1' : 'black87'}
                  fontWeight={url === 'courses' ? '700' : '400'}
                  flex
                >
                  Courses
                </Typography>
              </div>
              <div
                onClick={() => onChangePath('about')}
                style={{
                  backgroundColor: `${url === 'about' ? '#EBF4F9' : '#fff'}`,
                  padding: '12px 24px 12px 24px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: '0px 0px 6px 6px',
                }}
              >
                <Icon
                  src={url === 'about' ? AcademyAboutBlue : AcademyAbout}
                  alt="AcademyAbout"
                  style={{
                    width: 20,
                    height: 20,
                    right: '0px',
                  }}
                />
                <Typography
                  id="AcademyProfile.About"
                  color={url === 'about' ? 'primary1' : 'black87'}
                  fontWeight={url === 'about' ? '700' : '400'}
                  flex
                >
                  About
                </Typography>
              </div>
            </Cardmenu>
            <Custom minWidth={1440}>
              <Card>
                <Typography id="AcademyProfile.Learners" color="primary1" bold schoolSeemore>
                  Learners
                </Typography>
                <CardProfileRow>
                  {genCardProfileImage(cardPictureRow, school.studentCount)}
                </CardProfileRow>
              </Card>
            </Custom>
          </Menu>
          <Body>{children}</Body>
        </Content>
      </Custom>
      <Custom maxWidth={767}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <SchoolHeading
            {...school}
            status={status}
            followed={isFollowing}
            joinSchool={onJoinSchool}
            numberOfStudent={schoolStudentsCount?.countCurrent}
            deleteSchool={exitSchool}
            onFollowSchool={onFollowSchool}
            onUnFollowSchool={onUnFollowSchool}
          />
          <div style={{ padding: '0px 16px 0px 16px' }}>
            <RecommendedCourses />
          </div>

          <TabBar
            //headBorder={headBorder}
            //ref={contRef}
            //hideShadow={hideShadow}
            // inline={inline}
            //style={style}
            //noneBorderBottom={noneBorderBottom}
            transparent
          >
            <HeaderMenu
              onClick={() => onChangePath('announcement')}
              activeText={url === 'announcement' ? true : false}
              //style={{ width: '100%' }}
            >
              <Typography
                id="AcademyProfile.Announcement"
                color={url === 'announcement' ? 'primary1' : 'black87'}
                fontWeight={url === 'announcement' ? '700' : '400'}
                fontSize="text-base"
              >
                Anouncement
              </Typography>
            </HeaderMenu>
            <HeaderMenu
              onClick={() => onChangePath('activities')}
              activeText={url === 'activities' ? true : false}
            >
              <Typography
                id="AcademyProfile.Activities"
                color={url === 'activities' ? 'primary1' : 'black87'}
                fontWeight={url === 'activities' ? '700' : '400'}
                fontSize="text-base"
              >
                Activities
              </Typography>
            </HeaderMenu>
            <HeaderMenu
              onClick={() => onChangePath('courses')}
              activeText={url === 'courses' ? true : false}
            >
              <Typography
                id="AcademyProfile.Courses"
                color={url === 'courses' ? 'primary1' : 'black87'}
                fontWeight={url === 'courses' ? '700' : '400'}
                fontSize="text-base"
              >
                Courses
              </Typography>
            </HeaderMenu>
            <HeaderMenu
              onClick={() => onChangePath('about')}
              activeText={url === 'about' ? true : false}
            >
              <Typography
                id="AcademyProfile.About"
                color={url === 'about' ? 'primary1' : 'black87'}
                fontWeight={url === 'about' ? '700' : '400'}
                fontSize="text-base"
              >
                About
              </Typography>
            </HeaderMenu>
          </TabBar>

          <Body>{children}</Body>
        </div>
      </Custom>
    </Container>
  )
}

const selection = store.select((models) => ({
  status: (state) => models.MySchool.status(state, { schoolId: state?.School?.school?.id }),
  isFollowing: (state) => models.AppUser.isFollowing(state, state?.School?.school?.id),
}))

const mapState = (state, props) => ({
  appUser: state.AppUser.appUser,
  school: state.School.school,
  mySchoolList: state.MySchool.mySchoolList,
  schoolMember: state.SchoolMember.schoolMember,
  schoolStudentsCount: state.SchoolMember.schoolStudentsCount,
  follows: state.AppUser.follows,
  ...selection(state, props),
})

const mapDispatch = ({ School, MySchool, Confirm, SchoolMember, AppUser }) => ({
  getSchoolById: School.getSchoolById,
  getSchoolBySlug: School.getSchoolBySlug,
  getMySchoolList: MySchool.getMySchoolList,
  joinSchool: MySchool.joinSchool,
  deleteSchool: MySchool.deleteSchool,
  getSchoolMember: SchoolMember.getSchoolMember,
  getSchoolTeacherMember: SchoolMember.getSchoolTeacherMember,
  getSchoolStudentsCount: SchoolMember.getSchoolStudentsCount,
  postMyFollows: AppUser.postMyFollows,
  deleteMyFollows: AppUser.deleteMyFollows,
  getMyFollows: AppUser.getMyFollows,
  Confirm,
})

export default connect(mapState, mapDispatch)(SchoolLayout)
