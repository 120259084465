import sortBy from 'lodash/sortBy'
import moment from 'moment'
import _orderBy from 'lodash/orderBy'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import appUserApi from 'api/user'
import { UserAPI, CourseAPI, FeedAPI } from '../../api'

export const MyCourse = {
  state: {
    modalLecture: false,
    hideArrow: false,
    myCourseList: {
      awaitingApprove: [],
      awaitingPay: [],
      completed: [],
      currentCourse: [],
    },
    myCourse: {},
    myCourseError: false,
    overview: {
      assignments: [],
      lectures: [],
      summary: {
        courseAssignment: {
          current: 1,
          full: 1,
        },
        courseProject: {
          current: 1,
          full: 1,
        },
        totalStudent: 0,
      },
    },
    lectures: [],
    preTest: {},
    postTest: {},
    quizzes: [],
    courseWorksheets: [],
    feeds: [],
    students: [],
    lectureProgress: null,
    courseLateProgress: {},
    filter: [false, false],
  },
  reducers: {
    setMyCourseList(state, payload) {
      return {
        ...state,
        myCourseList: payload,
      }
    },
    setMyCourse(state, payload) {
      return {
        ...state,
        myCourse: payload,
      }
    },
    setMyCourseError(state) {
      return {
        ...state,
        myCourseError: true,
      }
    },
    setOverview(state, payload) {
      return {
        ...state,
        overview: payload,
      }
    },
    setEnroll(state, payload) {
      return {
        ...state,
        lectures: payload.lectures,
        quizzes: payload.quizzes,
        preTest: payload.preTest,
        postTest: payload.postTest,
      }
    },
    setDiscussion(state, payload) {
      return {
        ...state,
        feeds: payload,
      }
    },
    setStudents(state, payload) {
      return {
        ...state,
        students: payload,
      }
    },
    setLectureProgress(state, payload) {
      return {
        ...state,
        lectureProgress: payload,
      }
    },
    setCourseWorksheets(state, payload) {
      return {
        ...state,
        courseWorksheets: payload,
      }
    },
    setCourseLateProgress(state, payload) {
      return {
        ...state,
        courseLateProgress: payload,
      }
    },
    setHideArrow(state, payload) {
      return {
        ...state,
        hideArrow: payload,
      }
    },
    setModalLecture(state, payload) {
      return {
        ...state,
        modalLecture: payload,
      }
    },
    setFilter(state, payload) {
      return {
        ...state,
        filter: payload,
      }
    },
  },
  effects: () => ({
    onHideArrow(boolean) {
      this.setHideArrow(boolean)
    },

    async deleteFeed({ id }) {
      try {
        await FeedAPI.delete({ id })
      } catch (error) {
        throw error
      }
    },
    async updateFeed(payload) {
      try {
        await FeedAPI.update(payload)
      } catch (error) {
        throw error
      }
    },
    async editCourse(payload) {
      await CourseAPI.editCourse(payload)
    },
    async getMyCourseList() {
      const filter = JSON.stringify({
        // include: ['publishedSubject', 'subCategory'],
      })

      try {
        const courses = await UserAPI.getMyCourses({ filter })
        if (courses.completed?.length > 0) {
          const courseReviews = await appUserApi.getCourseRating()

          let completedWithReviews = courses.completed
          if (courseReviews?.length > 0) {
            completedWithReviews = courses.completed.map((course) => {
              const review = courseReviews.find((item) => {
                return course.id === item.courseId
              })
              if (review) {
                return {
                  ...course,
                  reviewId: review.id,
                  rating: review.rating,
                  reviewComment: review.comment,
                }
              }
              return course
            })
          }

          const newCourses = { ...courses, completed: completedWithReviews }
          this.setMyCourseList(newCourses)
          return newCourses
        }

        this.setMyCourseList(courses)
        return courses
      } catch (error) {
        console.log(error)
      }
    },
    async getMyCourseById(courseId) {
      try {
        const { currentCourse = [], completed = [] } = await this.getMyCourseList()
        const courseIds = [...currentCourse, ...completed].map((item) => item.id)
        if (!courseIds.includes(courseId)) {
          this.setMyCourseError()
          // eslint-disable-next-line no-throw-literal
          throw { message: 'CANNOT_ACCESS' }
        }
        const filter = JSON.stringify({
          include: ['teachers', 'school', 'publishedSubject', 'subCategory'],
        })
        const course = await CourseAPI.findById({ id: courseId, filter })
        // console.log(course)
        if (course?.id) {
          const reviewFilter = JSON.stringify({
            where: { courseId },
          })
          const courseReviews = await appUserApi.getCourseRating({ filter: reviewFilter })
          if (courseReviews?.length > 0) {
            const newCourse = {
              ...course,
              reviewId: courseReviews[0].id,
              rating: courseReviews[0].rating,
            }
            this.setMyCourse(newCourse)
            return newCourse
          }
        }

        this.setMyCourse(course)
        return course
      } catch (e) {
        this.setMyCourseError()
        throw e
      }
    },
    async getMyCourseOverview(courseId) {
      const overview = await UserAPI.getMyCourseOverview({ courseId })
      this.setOverview(overview)
    },
    async getEnrolledCourseContent(courseId) {
      const filter = JSON.stringify({
        include: ['courseContent'],
      })
      const data = await UserAPI.getEnrolledCourseContent({ courseId, filter })
      this.setEnroll(data)
    },
    async getDiscussion(courseId) {
      const filter = JSON.stringify({
        where: { courseId },
        include: ['appUser'],
      })
      const data = await FeedAPI.find({ filter })
      this.setDiscussion(data)
    },
    async postDiscussion(obj) {
      await FeedAPI.create(obj)
      this.getDiscussion(obj.courseId)
    },
    async getStudentsInCourse(courseId) {
      const students = await CourseAPI.getStudentsInCourse({ id: courseId })
      this.setStudents(students)
    },
    async getLectureProgress({ courseId, lectureId }) {
      const filter = JSON.stringify({
        where: {
          courseId,
          lectureId,
        },
      })
      const result = await UserAPI.getLectureProgress({ id: 'me', filter })
      if (result) {
        if (result.length > 0) {
          this.setLectureProgress(result[0])
        } else if (result.length === 0) {
          this.setLectureProgress({
            courseId,
            lectureId,
            timeSpent: 0,
          })
        }
      }
      return result
    },
    async getLectureProgressResult({ courseId, lectureId }) {
      const filter = JSON.stringify({
        where: {
          courseId,
          lectureId,
        },
      })
      const result = await UserAPI.getLectureProgress({ id: 'me', filter })
      return result
    },
    async getCourseWorksheets(courseId) {
      const CourseAssignmentFilter = JSON.stringify({
        include: { assignmentWorksheets: 'publishedWorksheet' },
      })
      const res = await CourseAPI.getCourseAssignments({
        id: courseId,
        filter: CourseAssignmentFilter,
      })
      this.setCourseWorksheets(res)
    },
    async getCourseLateProgress(courseId) {
      const filter = JSON.parse(
        `{"where": {"namespace": "Start Content", "data.courseId": "${courseId}"}, "order": "createdAt DESC", "limit": 1, "skip": 0}`
      )
      const result = await UserAPI.getCourseProgress({ courseId, filter })
      this.setCourseLateProgress(result)
    },
    async completeEnrolledCourse({ courseId }) {
      try {
        await UserAPI.completeEnrolledCourse({ courseId })
      } catch (e) {
        throw e
      }
    },
    async getZoomSignature({ courseId, meetingId }) {
      try {
        const result = await CourseAPI.getZoomSignature({
          id: courseId,
          meetingNumber: meetingId,
          role: 0,
        })
        return result
      } catch (e) {
        throw e
      }
    },
  }),

  selectors: () => ({
    content() {
      return (rootState, props) => {
        const lectures = get(rootState.MyCourse.myCourse, 'publishedSubject.data.lectures', [])
        // const worksheets = get(rootState.MyCourse, 'courseWorksheets', [])
        const worksheets = get(rootState.MyCourse, 'quizzes', [])
        const content = [...lectures, ...worksheets].find((item) => item.id === props.lectureId)
        return content
      }
    },
    lectureStatus() {
      return (rootState, props) => {
        const lectures = get(rootState.MyCourse, 'lectures', [])
        const lecture = lectures.find((item) => item.content.lectureId === props.lectureId)
        return lecture || {}
      }
    },
    lectureSide() {
      return (rootState) => {
        const { preTest, courseLateProgress } = rootState.MyCourse
        const lectures = courseLateProgress?.summary?.lectures || []

        const quizzes = get(rootState.MyCourse, 'quizzes', [])
        const disabledContent = !isEmpty(preTest) && !preTest.hasAnswer
        const sortedList = sortBy(
          [
            ...lectures.map((item) => ({ ...item.content, isCompleted: item.isCompleted })),
            ...quizzes.map((item) => ({ ...item, name: item.title })),
          ],
          'priority'
        )

        // * Section
        const newList = sortedList.reduce((arr, item) => {
          if (item.type === 'paragraph') {
            const obj = {
              id: item.lectureId,
              title: item.name,
              description: item.description,
              img: item.image,
              created: moment(item.createdAt).format('DD MMM YYYY'),
              update: moment(item.updatedAt).format('DD MMM YYYY'),
              lectures: [],
              type: item.type,
            }
            return [...arr, obj]
          }

          // * lecture, quiz
          const obj = {
            id: item.lectureId || item.id,
            title: item.name,
            description: item.description,
            hasVideoLink: item.hasVideoLink,
            type: item.type === '' ? 'lecture' : item.type,
            isCompleted: item?.isCompleted,
            isPass: item?.isPass,
          }
          if (disabledContent) {
            obj.isActiveContent = false
            obj.isActivePosttest = false
            obj.isActivePretest = false
          }
          if (arr.length > 0) {
            const lastItem = arr.slice(-1)[0]
            const lecs = lastItem.lectures || []
            return [...arr.slice(0, -1), { ...lastItem, lectures: [...lecs, obj] }]
          }
          return []
        }, [])

        return newList
      }
    },
    enabledPostTest() {
      return (rootState) => {
        const { lectures, preTest } = rootState.MyCourse
        const list = lectures.map((item) => {
          return (
            item.content.progress === 1 &&
            (item.preTest ? item.preTest.hasAnswer : true) &&
            (item.postTest ? item.postTest.hasAnswer : true)
          )
        })
        return list.every((item) => item) && (preTest ? preTest.hasAnswer : true)
      }
    },
    lectures() {
      return (rootState) => {
        const lectures = get(rootState.MyCourse.myCourse, 'publishedSubject.data.lectures', [])
        const quizzes = get(rootState.MyCourse, 'quizzes', [])

        const newContent = _orderBy(
          [...lectures, ...quizzes.map((item) => ({ ...item, name: item.title }))],
          ['priority'],
          ['asc']
        )

        return newContent
      }
    },
  }),
}
