export const menus = [
  {
    id: 'label.overview',
    label: 'Overview',
    key: '0',
    component: null,
    path: '/overview',
  },
  {
    id: 'tab.label.Setting',
    label: 'Setting',
    key: '1',
    component: null,
    path: '/setting',
  },
  // {
  //   id: 'tab.label.Report',
  //   label: 'Report',
  //   key: '2',
  //   component: null,
  //   path: '/report',
  // },
]
