import { DraftAssignmentAPI } from 'api/index'

export const DraftAssignments = {
  state: {
    draftAssignment: {},
    courseDraftAssignments: [],
  },
  reducers: {
    setDraftAssignment(state, payload) {
      return {
        ...state,
        draftAssignment: payload,
      }
    },
    setCourseDraftAssignments(state, payload) {
      return {
        ...state,
        courseDraftAssignments: payload,
      }
    },
  },
  effects: () => ({
    async createDraftAssignment(payload) {
      try {
        const res = await DraftAssignmentAPI.create(payload)

        console.log('create draftassignment', res)
        return res
      } catch (error) {
        throw error
      }
    },
    async updateDraftAssignment(payload) {
      try {
        const res = await DraftAssignmentAPI.update(payload)
        console.log('update draftAssignment', res)
      } catch (error) {
        throw error
      }
    },
    async getCourseDraftAssignments(courseId) {
      const filter = JSON.stringify({ where: { courseId } })
      try {
        const res = await DraftAssignmentAPI.find({ filter })
        console.log('draft assignment list', res)
        await this.setCourseDraftAssignments(res)
      } catch (error) {
        throw error
      }
    },
    async getDraftAssignmentById(id) {
      try {
        const res = await DraftAssignmentAPI.findById({ id })
        this.setDraftAssignment(res)
        return res
      } catch (error) {
        throw error
      }
    },
    async publishAssignmentById(id) {
      try {
        const res = await DraftAssignmentAPI.publishAssignment({ id })
        return res
      } catch (error) {
        throw error
      }
    },
    async deleteDraftAsm(id) {
      try {
        const res = await DraftAssignmentAPI.delete({ id })
        console.log(res)
      } catch (error) {
        throw error
      }
    },
    async cloneDraftAsm(id) {
      try {
        const res = await DraftAssignmentAPI.cloneDraftAssignment({ id })

        console.log(res)
      } catch (error) {
        throw error
      }
    },
  }),
}
