/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { ContentProgressAPI, UserAPI } from '../../api'
import store from '../../store'
import ErrorBoundary from '../../components/errorBoundary'
import MyCourseLectureContentBodyLayout from './MyCourseLectureContentBodyLayout'
import styled from 'styled-components'
import { DesktopAndTablet, SmallMobile } from '../../utils/displayResponsive'
const Container = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 1024px) {
  }

  @media (max-width: 820px) {
    padding: 0px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }

  @media (max-width: 429px) {
    padding: 0px;
  }
`
const Right = styled.div`
  //width: 70%;
  width: calc(100% - 420px);
  min-height: 100%;
`
const Left = styled.div`
  //width: 30%;
  width: 420px;
  min-height: 150%;
  background-color: #1c1c1e;
  //min-height: calc(100vh - 42px);
`
const MINIMUM_COMPLETE_VIDEO = 0.95
// const displayWidth = 60
// const imageWidth = displayWidth * 2

class myCourseLectureContentLayout extends Component {
  state = {
    playedSeconds: 0,
    playedPercent: 0,
    start: false,
    isOpenModal: false,
    isNavigation: false,
    isOpenLecture: false,
    isOpenQuiz: false,
    quizModalProps: {},
    // isOpenSidebarDesktop: false,
  }

  componentDidMount() {
    const {
      getEnrolledCourseContent,
      getMyCourseById,
      getLectureProgress,
      match,
      getCourseWorksheets,
    } = this.props
    const { courseId, lectureId } = match.params
    //getMyCourseById(courseId)
    //getEnrolledCourseContent(courseId)
    //getLectureProgress({ courseId, lectureId })
    //getCourseWorksheets(courseId)
    //this.logStartLecture()
    this.testBoxRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { match, getLectureProgress } = this.props
    const { courseId, lectureId } = match.params
    // if (prevProps.match.params.lectureId !== match.params.lectureId) {
    //   if (!prevProps.content.enableCompleteButton) {
    //     this.sendContentProgress(prevProps)
    //   }
    //   getLectureProgress({ courseId, lectureId })
    //   this.logStartLecture()
    //   this.logEndLecture(prevProps)
    // }
  }

  componentWillUnmount() {
    const {
      content: { enableCompleteButton },
    } = this.props
    //if (!enableCompleteButton) this.sendContentProgress()
    //this.logEndLecture()
  }

  getMinimumCompleteVideo = () => {
    return this.props?.content?.durationPercentage > 0
      ? this.props?.content?.durationPercentage / 100
      : MINIMUM_COMPLETE_VIDEO
  }

  logStartLecture = () => {
    const { match, content } = this.props
    const { courseId, lectureId } = match.params
    let payload = {
      namespace: 'Start Content',
      title: `lecture ${lectureId}`,
      data: {
        type: 'lecture',
        courseId,
        contentId: lectureId,
      },
    }
    if (get(content, 'type', '') === 'quiz') {
      payload = {
        namespace: 'Start Content',
        title: `quiz ${lectureId}`,
        data: {
          type: 'quiz',
          courseId,
          contentId: lectureId,
        },
      }
    }
    UserAPI.createLog(payload)
  }

  logEndLecture = (props) => {
    const { match, content } = props || this.props
    const { courseId, lectureId } = match.params
    let payload = {
      namespace: 'End Content',
      title: `lecture ${lectureId}`,
      data: {
        type: 'lecture',
        courseId,
        contentId: lectureId,
      },
    }
    if (get(content, 'type', '') === 'quiz') {
      payload = {
        namespace: 'End Content',
        title: `quiz ${lectureId}`,
        data: {
          type: 'quiz',
          courseId,
          contentId: lectureId,
        },
      }
    }
    UserAPI.createLog(payload)
  }

  getContentType = () => {
    const contentStr = get(this.props, 'content.content')
    if (this.props?.content?.videoLink) {
      return 'video'
    }
    if (contentStr) {
      const content = JSON.parse(contentStr)
      if (content.videoName || !!this.props?.content?.videoLink) {
        return 'video'
      }
      return 'content'
    }
    return 'content'
  }

  getTimeByType = (type) => {
    const { playedSeconds } = this.state
    if (type === 'video') {
      return playedSeconds
    }
    return 0
  }

  getPercentByType = (type) => {
    const { playedPercent } = this.state
    const isTimeRequired = this.props.content?.isTimeRequired
    if (type === 'video') {
      if (isTimeRequired) {
        const minimumCompleteVideo = this.getMinimumCompleteVideo()
        return playedPercent >= minimumCompleteVideo ? 1 : playedPercent
      }
    }
    return 1
  }

  getIsCompleted = (type) => {
    // if (type === 'video') {
    //   return false
    // }
    return this.getPercentByType(type) === 1
  }

  getAllowShowContent = (props) => {
    const { lectureStatus, preTest } = props || this.props
    if (lectureStatus.preTest) {
      const hasAns = get(lectureStatus.preTest, 'hasAnswer', false)
      if (!hasAns) {
        return false
      }
    }
    if (!isEmpty(preTest)) {
      return preTest.hasAnswer
    }
    return true
  }

  sendContentProgress = async (props, timeSpent) => {
    if (!this.getAllowShowContent(props)) {
      return null
    }
    const { match } = props || this.props
    const isStudent = match.path === '/my-course/courses/:courseId/lecture/:lectureId/'
    const { lectureId, courseId } = match.params
    const studentId = JSON.parse(localStorage.getItem('accessToken')).userId
    const type = this.getContentType()

    if (isStudent) {
      const where = JSON.stringify({
        lectureId,
        courseId,
        studentId,
      })
      const data = {
        percent: this.getPercentByType(type),
        time: this.getTimeByType(type),
        timeSpent: timeSpent || 0,
        type,
        lectureId,
        courseId,
        studentId,
        isCompleted: this.getIsCompleted(type),
        where,
      }
      const result = await ContentProgressAPI.upsertWithWhere(data)
      return result
    }
    return null
  }

  sendTimeSpent = async ({ timeSpent, lectureId, courseId }) => {
    if (!this.getAllowShowContent()) {
      return null
    }
    const { match } = this.props
    const isStudent = match.path === '/my-course/courses/:courseId/lecture/:lectureId'
    const studentId = JSON.parse(localStorage.getItem('accessToken')).userId
    const type = this.getContentType()

    if (isStudent) {
      const where = JSON.stringify({
        lectureId,
        courseId,
        studentId,
        type,
      })
      const data = {
        percent: 0,
        time: 0,
        type,
        lectureId,
        courseId,
        studentId,
        where,
        timeSpent,
      }
      const result = await ContentProgressAPI.upsertWithWhere(data)
      return result
    }
    return null
  }

  openModal = () => {
    this.setState({ isOpenModal: true })
  }

  closeModal = () => {
    this.setState({ isOpenModal: false })
  }

  handleClick = () => {
    const {
      history,
      link,
      content: { id, hasAnswer },
    } = this.props
    const attemp = hasAnswer ? 1 : 0
    const prefixLink = link || `/my-course/assignments/${id}`
    if (attemp > 0) {
      history.push(`${prefixLink}/result`)
    } else {
      history.push(prefixLink)
    }
  }

  startAssessment = () => {
    const { link, content } = this.props
    this.setState({
      isOpenQuiz: true,
      quizModalProps: {
        link,
        contentId: content?.id,
        teachers: [],
        test: content,
      },
    })
  }

  onCloseQuiz = () => {
    this.setState({ isOpenQuiz: false, quizModalProps: {} })
  }

  viewResult = () => {
    const {
      history,
      link,
      content: { id },
    } = this.props
    const prefixLink = link || `/my-course/assignments/${id}`
    history.push(`${prefixLink}/result`)
  }

  formatTimer = (timeoutHour, timeoutMinute) => {
    let timer = `${timeoutHour} hr ${timeoutMinute} min`
    if (timeoutHour === 0) timer = `${timeoutMinute} min`
    if (timeoutMinute === 0) timer = `${timeoutHour} hr`
    return timer
  }

  handleNavigation = () => {
    this.setState(({ isNavigation }) => ({ isNavigation: !isNavigation }))
  }
  handleOpenNavigation = () => {
    this.setState(() => ({ isNavigation: true }))
  }
  handleCloseNavigation = () => {
    this.setState(() => ({ isNavigation: false }))
  }

  // Refactor lecture
  // Output: index of lecture current
  getLectures = () => {
    const { lectureSide } = this.props
    let lectureAll = []
    const result = { ...lectureSide }
    for (const key in result) {
      if (Object.hasOwnProperty.call(result, key)) {
        const { lectures } = result[key]
        lectureAll.push(...lectures)
      }
    }
    return lectureAll
  }

  onPrev = (lectureId) => {
    const lectureAll = this.getLectures()
    const index = lectureAll.map(({ id }) => id).indexOf(lectureId)
    if (index > 0) {
      const lecturePrev = lectureAll[index - 1]
      const {
        history,
        match: {
          params: { courseId },
        },
      } = this.props
      history.push(
        `/my-course/courses/${courseId}/lecture/${lecturePrev.id}?type=${lecturePrev.type}`
      )
    }
  }

  onNext = (lectureId) => {
    const lectureAll = this.getLectures()
    const index = lectureAll.map(({ id }) => id).indexOf(lectureId)
    const indexNextPage = index + 1
    if (indexNextPage < lectureAll.length) {
      const lectureNext = lectureAll[indexNextPage]
      const {
        history,
        match: {
          params: { courseId },
        },
      } = this.props
      history.push(
        `/my-course/courses/${courseId}/lecture/${lectureNext.id}?type=${lectureNext.type}`
      )
    }
  }

  render() {
    // const { lectureSide, content, courseName, match, postTest } = this.props
    const {
      content,
      lectureProgress,
      match,
      children,
      lectureStatus,
      teachers,
      preTest,
      success,
      lectureSide,
      courseName,
      modalLecture,
      setModalLecture,
    } = this.props
    const { start, playedPercent, isOpenModal } = this.state
    const { courseId } = match.params

    // const lectureAll = this.getLectures()
    // const index = lectureAll?.map(({ id }) => id).indexOf(content.id)
    // const indexMax = lectureAll?.length - 1
    // const isFirst = index === 0
    // const isLast = index === indexMax
    // const linkMainpage =
    //   match.path === '/schools/:schoolId/curriculum/manage/:courseId/lecture/:lectureId'
    //     ? `/schools/${match.params.schoolId}/curriculum/manage/${match.params.courseId}/lecture`
    //     : `/my-course/courses/${match.params.courseId}/lecture`
    return <ErrorBoundary>{children}</ErrorBoundary>
  }
}

myCourseLectureContentLayout.defaultProps = {
  content: { enableCompleteButton: false },
}

const selection = store.select((models) => ({
  lectureSide: models.MyCourse.lectureSide,
  lectureStatus: (state, props) =>
    models.MyCourse.lectureStatus(state, { lectureId: props.match.params.lectureId }),
  content: (state, props) =>
    models.MyCourse.content(state, { lectureId: props.match.params.lectureId }),
}))

const mapState = (state, props) => {
  return {
    courseId: state.MyCourse.myCourse.id,
    courseName: state.MyCourse.myCourse.name,
    teachers: get(state.MyCourse, 'myCourse.teachers', [{ firstname: '', lastname: '' }]),
    lectureProgress: state.MyCourse.lectureProgress,
    preTest: state.MyCourse.preTest,
    postTest: state.MyCourse.postTest,
    modalLecture: state.MyCourse.modalLecture,
    locale: state.Language.locale,
    ...selection(state, props),
  }
}

const mapDispatch = ({
  MyCourse: {
    getEnrolledCourseContent,
    getMyCourseById,
    getLectureProgress,
    getCourseWorksheets,
    setModalLecture,
  },
  Alert: { success },
}) => {
  return {
    getEnrolledCourseContent,
    getCourseWorksheets,
    getMyCourseById,
    getLectureProgress,
    success,
    setModalLecture,
  }
}

export default connect(mapState, mapDispatch)(myCourseLectureContentLayout)
