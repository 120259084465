import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import LibSidebar from 'react-sidebar'
import { connect } from 'react-redux'
import ErrorBoundary from '../../components/errorBoundary'
import { LayoutContainer, Content } from './styles'
import { UnauthorizedLayout } from './UnauthorizedLayout'
import SearchBar from '../../containers/searchBar/loadable'
import DrawerProfile from '../../components/DrawerProfile'
import { popupMenus } from '../../containers/searchBar/popupMenus'
import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'
import store from '../../store'
import isEmpty from 'lodash/isEmpty'

export class Layout extends Component {
  render() {
    const { authorized, sidebarOpen, toggle, appUser, isTeacher, match, location } = this.props
    if (!authorized) return <UnauthorizedLayout {...this.props} />
    const pattern = /\/my-course\/courses\/.+\/lecture\/.+/gm //
    const pattern2 = /\/my-course\/courses\/.+/gm
    const matchedPath = pattern.test(location?.pathname)
    const matchedPath2 = pattern2.test(location?.pathname)

    const courseLectureDetail = /\/my-course\/courses\/.+\/lecture\// //
    const matchedPath3 = courseLectureDetail.test(location?.pathname)

    const matchPathAll = matchedPath || matchedPath2 || matchedPath3
    const isTeacherPath = match.url.startsWith('/teacher') || match.url.startsWith('/schools')
    const pathname = get(location, 'pathname', false)
    const academy = get(appUser, 'schoolMembers')

    return (
      <ErrorBoundary>
        <LayoutContainer>
          <LargeDesktop>
            {/* <LibSidebar
              open={sidebarOpen}
              onSetOpen={toggle}
              pullRight
              sidebar={
                <DrawerProfile
                  onRequestClose={() => toggle(false)}
                  menu={popupMenus(isTeacherPath, appUser?.roles?.length, academy?.[0]?.schoolId)}
                  pathname={pathname}
                  isTeacher={isTeacher}
                />
              }
              styles={{
                root: {
                  overflow: 'unset',
                },
                sidebar: {
                  zIndex: 300,
                  position: 'fixed',
                  overflowY: 'hidden',
                },
                overlay: {
                  zIndex: 250,
                },
                content: {
                  position: 'absolute',
                  overflowY: 'unset',
                },
              }}
            >
              <Content>
                {!matchPathAll && <SearchBar />}
                {this.props.children}
              </Content>
            </LibSidebar> */}
            <Content>
              {!isEmpty(appUser) ? (
                <div style={{ marginBottom: matchPathAll ? 0 : 68 }}>
                  {!matchPathAll && <SearchBar />}
                </div>
              ) : (
                <div>{!matchPathAll && <SearchBar />}</div>
              )}
              {this.props.children}
              {sidebarOpen && (
                <div>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'fixed',
                      backgroundColor: 'transparent',
                      zIndex: 103,
                      top: 0,
                    }}
                    onClick={() => {
                      toggle(false)
                    }}
                  ></div>

                  <div
                    style={{
                      backgroundColor: 'red',
                      height: 'auto',
                      width: 240,
                      zIndex: 1000,
                      position: 'fixed',
                      right: 150,
                      top: 56,
                    }}
                  >
                    <DrawerProfile
                      onRequestClose={() => toggle(false)}
                      menu={popupMenus(
                        isTeacherPath,
                        appUser?.roles?.length,
                        academy?.[0]?.schoolId
                      )}
                      pathname={pathname}
                      isTeacher={isTeacher}
                    />
                  </div>
                </div>
              )}
            </Content>
          </LargeDesktop>
          <MobileAndTablet>
            <LibSidebar
              open={sidebarOpen}
              onSetOpen={toggle}
              pullLeft
              sidebar={
                <DrawerProfile
                  onRequestClose={() => toggle(false)}
                  menu={popupMenus(isTeacherPath, appUser?.roles?.length, academy?.[0]?.schoolId)}
                  pathname={pathname}
                  isTeacher={isTeacher}
                />
              }
              styles={{
                root: {
                  overflow: 'unset',
                },
                sidebar: {
                  zIndex: 300,
                  position: 'fixed',
                  overflowY: 'hidden',
                },
                overlay: {
                  zIndex: 250,
                },
                content: {
                  position: 'absolute',
                  overflowY: 'unset',
                },
              }}
            >
              <Content>
                {!matchPathAll && <SearchBar />}
                {this.props.children}
              </Content>
            </LibSidebar>
          </MobileAndTablet>
        </LayoutContainer>
      </ErrorBoundary>
    )
  }
}

Layout.defaultProps = {
  authorized: true,
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  authorized: PropTypes.bool,
}

const selection = store.select((models) => ({
  isTeacher: models.AppUser.isTeacher,
}))
const mapState = (state) => {
  return {
    sidebarOpen: state.Sidebar.sidebarOpen,
    appUser: state.AppUser.appUser,
    ...selection(state),
  }
}

const mapDispatch = (dispatch) => {
  return { toggle: dispatch.Sidebar.toggle, mediaQueryChanged: dispatch.Sidebar.mediaQueryChanged }
}

export default connect(mapState, mapDispatch)(Layout)
