export const customAddCourseModal = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(38, 38, 38, 0.6)',
    zIndex: 300,
  },
  content: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    border: 'none',
    transform: 'none',
    background: 'none',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '24px',
    maxHeight: '100vh',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}

export const mobileModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(38, 38, 38, 0.6)',
    zIndex: 300,
  },
  content: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    border: 'none',
    background: 'none',
    transform: 'none',
    overflow: 'hidden',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',

    maxWidth: '95%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
}
