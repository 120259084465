import React, { Suspense } from 'react'
import { Formik, Form, FieldArray, Field } from 'formik'
import { connect } from 'react-redux'
import { useEffectOnce } from 'react-use'

// import TutorialIcon from 'images/Classwin - New icon_Tutorial.svg'
// import { Button, Typography, Icon } from 'components'
import { Typography, Card } from 'components'
import MyLoader from './myLoader'
import { Container, Content, Grid, Header, Line } from './styled'
import InstructorFooter from './instructorFooter'
import { validationSchema } from './validationSchema'
import { DesktopAndTablet, SmallMobile } from 'utils/displayResponsive'

const InstructorCard = React.lazy(() => import('./instructorCard'))
export const CourseInstructorPage = (props) => {
  useEffectOnce(() => {
    const {
      getTeacherList,
      match: {
        params: { schoolId },
      },
    } = props

    getTeacherList(schoolId)
  })

  const handleSubmit = (values) => {
    const {
      match: {
        params: { courseId, schoolId },
      },
      // draftInfo
      publicMode,
      name,
      updateCourse,
    } = props

    updateCourse({
      name,
      id: courseId,
      schoolId,
      publicMode,
      _teachers: values.teachers,
      customTeacherList: values.customTeacherList,
    })
  }

  const {
    teacherList,
    // draftInfo: { _teachers },
    _teachers,
    customTeacherList,
    formRef,
  } = props
  // const tutorialLink = 'http://classwin.co/select-teacher/'
  return (
    <Container>
      <Card padding="24px 32px">
        <Formik
          ref={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            teachers: _teachers || [],
            customTeacherList: customTeacherList || [],
          }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(formikProps) => (
            <>
              {/* <SubHeader>
              <div className="wrapper">
                <div className="main-header">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography id="course.instructor.title" h3 className="title">
                        Instructor
                      </Typography>
                      <a href={tutorialLink} target="_blank" rel="noreferrer">
                        <Icon
                          style={{ width: '20px', height: '20px', margin: '10px' }}
                          src={TutorialIcon}
                        />
                      </a>
                    </div>
                    <Description id="course.instructor.desc">
                      Assign instructor to this course
                    </Description>
                  </div>
                  <Button
                    id="button.save"
                    type="submit"
                    primary
                    // primary={!formikProps.isSubmitting}
                    bold
                    onClick={formikProps.handleSubmit}
                    // disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </SubHeader> */}
              <Content>
                <Form>
                  <FieldArray name="teachers">
                    {({ name, ...arrayHelpers }) => {
                      return (
                        <>
                          <Header>
                            <Typography
                              id="course.instructor.instructor-list"
                              fontSize="text-xl"
                              bold
                              color="primary1"
                              margin="0 0 16px 0"
                            >
                              Instructor list
                            </Typography>
                            <Typography
                              id="course.instructor.inHouse-instructor"
                              color="primary1"
                              margin="0 0 12px 0"
                            >
                              In-House Instructor
                            </Typography>
                          </Header>
                          <Grid>
                            {/* {getIn(values, name).map((item, index) => ( */}
                            {teacherList &&
                              teacherList.length > 0 &&
                              teacherList.map((item, index) => (
                                <Suspense fallback={MyLoader} key={index.id}>
                                  <Field
                                    key={item.id}
                                    name={name}
                                    id={item.id}
                                    teacherName={item.name}
                                    teacherImage={item.profileImage}
                                    skill={item.skill}
                                    component={InstructorCard}
                                    arrayHelpers={arrayHelpers}
                                    index={index}
                                  />
                                </Suspense>
                              ))}
                          </Grid>
                        </>
                      )
                    }}
                  </FieldArray>
                </Form>
                <Line></Line>
                <SmallMobile>
                  <InstructorFooter
                    customList={formikProps.values.customTeacherList}
                    formikProps={formikProps}
                  />
                </SmallMobile>
              </Content>
              <DesktopAndTablet>
                <InstructorFooter
                  customList={formikProps.values.customTeacherList}
                  formikProps={formikProps}
                />
              </DesktopAndTablet>
            </>
          )}
        </Formik>
      </Card>
    </Container>
  )
}

const mapState = ({
  TeacherSchool: { teacherList },
  DraftCourse: {
    draftInfo: { _teachers, publicMode, name, customTeacherList },
  },
}) => ({
  teacherList: teacherList.map((item) => ({
    id: item.appUser.id,
    name: `${item.appUser.firstname} ${item.appUser.lastname}`,
    profileImage: item.appUser.profileImage,
    skill: '',
  })),
  _teachers,
  publicMode,
  name,
  customTeacherList,
})

const mapDispatch = ({ TeacherSchool: { getTeacherList }, DraftCourse: { updateDraft } }) => ({
  getTeacherList,
  updateCourse: updateDraft,
})

export default connect(mapState, mapDispatch)(CourseInstructorPage)
