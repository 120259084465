/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { CSSTransition } from 'react-transition-group'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import { Desktop, Mobile } from 'utils/displayResponsive'
import {
  Input,
  MediaPlayer,
  Container,
  InputWithLabel,
  Typography,
  Card,
  Button,
  Upload,
  UploadImage,
  Label,
  ClasswinEditor,
  //ButtonDialog,
  Icon as PureIcon,
  SwitchWithOnOff as Switch,
  Switch as SwitchPercent,
} from 'components'
import ConfirmModal from 'components/confirmModal/loadable'
import Alert from '../../../../components/alert/loadable'
import UploadVideo from 'components/uploadVideo'
import ErrorBoundary from 'components/errorBoundary'
import DeleteIcon from 'images/icons/TrashColor.svg'
import ArrowIcon from 'images/icons/CaretDown.svg'
import CancelIcon from 'images/icons/primary-small-x.svg'
import ButtonDialog from '../../../../containers/courseContentPage/contents/section/buttonDialog'
// import TutorialIcon from 'images/Classwin - New icon_Tutorial.svg'
import AddIcon from 'images/icons/primary-plus.svg'
import { createCurriculumData2 } from '../../../../static'
import getImageUrl from '../../../../utils/getImageUrl'
import closeIcon from 'images/icons/Gray-X.svg'
import DragIcon from 'images/icons/DotsSixVertical.svg'
import PlusIcon from 'images/ic-plus-white-24-px.svg'
import ParagraphIcon from 'images/icons/Black-Paragraph.svg'
import SelectedParagraphIcon from 'images/icons/Primary-Paragraph.svg'
import PDFIcon from 'images/icons/Black-PDF.svg'
import SelectedPDFIcon from 'images/icons/Primary-PDF.svg'
import UploadVideoIcon from 'images/icons/Black-UploadVideo.svg'
import SelectedUploadVideoIcon from 'images/icons/Primary-UploadVideo.svg'
import VideoLinkIcon from 'images/icons/Black-VideoLink.svg'
import SelectedVideoLinkIcon from 'images/icons/Primary-VideoLink.svg'
import _get from 'lodash/get'
import { notification } from 'antd'
import {
  Icon,
  IconArrow,
  File,
  AddFiles,
  CloseIcon,
  ContentBody,
  // Item,
  // InputRadio,
  Wrapper,
  // RadioLabel,
  SwitchRow,
  // Line,
  // FilenameBorder,
  UploadSection,
} from './style'
import LectureSettingModal from './lectureSettingModal'
import LectureSetting from './lectureSetting'

import {
  SectionWrapper,
  ButtonDialogWrapper,
  DragHandle,
  // StyledIoMdMenu,
  HoverCircleButton,
  CardTitle,
  Title,
  SeperateLine,
  IconSection,
} from '../../style'
import '../css-transition.css'
import theme from 'theme'
import SelectWithIcon from 'components/selectWithIcon/loadable'

const StyledContainer = styled(Card)`
  display: flex;
  /* position: relative; */
  border-color: ${({ isSelected }) => (isSelected ? theme.colors.secondary : 'none')};
  box-sizing: border-box;
  height: 72px;
  transition: box-shadow, all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 0;
  border-left-width: 16px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;

  :hover {
    /* -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(100, 100, 100, 0); */
    /* border-bottom: none; */
    /* margin-top: -4px; */
    /* transform: scale(1.015); */
    z-index: 1;
    border-color: ${theme.primary1};
  }
`

const StyledCard = styled.div`
  display: flex;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0 24px 0 16px;
  justify-content: space-between;

  i.deleteIcon {
    display: inline;
    align-self: center;
    margin-right: 30px;
    /* position: absolute;
    top: -10px;
    left: -30px; */
  }

  :hover i.deleteIcon {
  }

  i.eva-hover {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
`

const Content = styled.section`
  i.eva-hover {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
`
const Item = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : 16)}px;
`
const Span = styled.span`
  display: flex;

  input[type='checkbox'] {
    accent-color: ${({ theme }) => theme.primary1};
    width: 15px;
    height: 15px;
  }
`
const limitSize = 1572864000 // 1.5 GB
// const limitSizePreview = 314572800 // 300 MB

class Lecture extends Component {
  state = {
    showPreview: false,
    showVideoLink: false,
    showButtonAdd: false,
    isExpand: false,
    showConfirm: false,
    showModal: false,
    selectName: 'videoLink',
    selectType: '',
    selectNamePreview: 'preview',
    isHideControl: false,
    value: '',
    name: '',

    isAlert: false,
    isError: false,
    titleAlert: '',
    isLoadding: false,
    isIn: false,
    dataType: {},
    dataValue: {},
    isType: false,
    linkVideo: '',
    timeRequired1: 0,
  }

  debounceInput = debounce(async ({ value, name }) => {
    const { updateLecture, priority, subjectId, id: lectureId, handleAutoSave } = this.props
    const payload = {
      subjectId,
      lectureId,
      priority,
      [name]: value,
    }

    try {
      await updateLecture(payload)
      handleAutoSave(true)
    } catch (error) {
      handleAutoSave(false)
      throw error
    }
  }, 1000)

  debounceManyInput = debounce(async (data) => {
    const { updateLecture, priority, subjectId, id: lectureId, handleAutoSave } = this.props
    const payload = {
      subjectId,
      lectureId,
      priority,
      ...data,
    }

    try {
      await updateLecture(payload)
      handleAutoSave(true)
    } catch (error) {
      handleAutoSave(false)
      throw error
    }
  }, 1000)

  componentDidMount() {
    const { videoLink, preview, hidePlayerControl, coverImageVideo, lectureType, name } = this.props
    // console.log('link', videoLink)
    // console.log('props', this.props)

    this.setState({
      showVideoLink: videoLink && true,
      showPreview: preview && true,
      isHideControl: hidePlayerControl,
      selectType: lectureType,
      name: name,
      // selectName: /^https:\/\/vimeo.com/.test(videoLink) ? 'browse' : 'videoLink'
    })

    if (coverImageVideo) {
      this.setState({ selectName: 'browse' })
    } else {
      this.setState({ selectName: 'videoLink' })
    }

    if (preview?.type === 'upload') {
      this.setState({ selectNamePreview: 'browsePreview' })
    } else {
      this.setState({ selectNamePreview: 'preview' })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isDragging } = this.props
    const { isExpand } = this.state
    if (prevProps.isDragging !== isDragging) {
      // eslint-disable-next-line react/no-did-update-set-state
      if (isExpand && isDragging) this.setState({ isExpand: false })
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      name,
      description,
      lectureType,
      content,
      videoLink,
      videoFilename,
      coverImageVideo,
      preview,
      lectureSlide,
    } = nextProps

    return {
      name,
      description,
      lectureType,
      content,
      videoLink,
      videoFilename,
      coverImageVideo,
      preview,
      lectureSlide,
    }
  }

  handleShowButtonAdd = (showButtonAdd) => (e) => {
    //const { handleSelected, id: lectureId } = this.props
    e.stopPropagation()
    this.setState({ showButtonAdd })
    // handleSelected(lectureId)
  }

  inputChange = (e) => {
    // eslint-disable-next-line prefer-const
    let { value, name } = e.target
    if (name === 'name' && !value) value = 'Untitled'
    this.props.handleAutoSave(false)

    //this.setState({ value: value, name: name })
    //this.debounceInput({ value, name })
  }
  handleSave = () => {
    const { onChangeTitleName, priority } = this.props
    const { value, name } = this.state
    onChangeTitleName(value, priority)
    this.debounceInput({ value, name })
  }
  onChangeUploadingVideo = (data) => {
    this.props.handleAutoSave(false)
    this.debounceManyInput(data)
  }

  onIsHideChange = (checked) => {
    // console.log('checked', checked)
    this.setState({ isHideControl: checked })
    this.debounceInput({ value: checked, name: 'hidePlayerControl' })
  }

  videoSwitchChange = () => {
    this.setState(({ showVideoLink }) => ({ showVideoLink: !showVideoLink }))
  }

  previewSwitchChange = () => {
    this.setState(({ showPreview }) => ({ showPreview: !showPreview }))
  }

  handleExpandCard = () => {
    this.setState(({ isExpand }) => ({ isExpand: !isExpand }))
  }

  handleRemove = async () => {
    const { removeLectureById, subjectId, id: lectureId, onChange } = this.props
    this.setState({ isLoadding: true })
    const payload = {
      subjectId,
      lectureId,
    }
    try {
      await removeLectureById(payload)
    } catch (error) {
      throw error
    } finally {
      await onChange()
      await this.setState({ isLoadding: false })
    }
  }

  updateLecture = () => {
    const { attachments } = this.state
    this.debounceInput({ value: attachments, name: 'attachments' })
  }

  handleDeleteAttachment = async (attachmentId) => {
    const { id, deleteAttachmentById, onChange } = this.props
    try {
      await deleteAttachmentById({ id, fk: attachmentId })
    } catch (error) {
      throw error
    } finally {
      onChange()
    }
  }

  addAttachment = async ({ name, originalFilename }) => {
    const { id, createAttachment, onChange } = this.props
    const object = {
      id,
      name,
      originalFilename,
      type: 'file',
      url: name,
    }
    try {
      await createAttachment(object)
    } catch (error) {
      throw error
    } finally {
      onChange()
    }
  }

  handleCloseModal = () => {
    this.setState({ showConfirm: false })
  }

  handleShowSettingModal = () => {
    this.setState({ showModal: true })
  }

  handleCloseSettingModal = () => {
    this.setState({ showModal: false })
  }

  handleSelectType = (value) => {
    this.setState({ selectType: value })
  }

  handleCheckBox = (name) => {
    this.setState({ selectName: name })
  }

  handleCheckBoxPreview = (name) => {
    this.setState({ selectNamePreview: name })
  }
  onCloseAlert = () => {
    this.setState({ isAlert: false, text: '' })
  }

  render() {
    const {
      // attachments,
      // name,
      description,
      content,
      lectureType,
      videoLink,
      coverImageVideo,
      addNewContent,
      priority,
      dragHandle,
      isDragging,
      preview,
      lectureSlide,
      id: lectureId,
      subjectId,
      // getLectureInfo,
      handleSelected,
      isSelected,
      match: { path },
      enableCompleteButton,
      timeRequired,
      isTimeRequired,
      isQuestionRequired,
      locale,
      videoFilename,
      onChangeTitleName,
      onChangeLectureType,
      updateLecture,
      durationPercentage,
    } = this.props

    const {
      showButtonAdd,
      showConfirm,
      /*selectName, selectNamePreview,*/
      isHideControl,
      selectType,
      name,
      isAlert,
      isError,
      titleAlert,
      isLoadding,
      isType,
      dataValue,
      dataType,
      timeRequired1,
    } = this.state
    const isTh = locale === 'th'
    const isCourseSetting = /\/schools\/:schoolId\/curriculum\/manage.*/.test(path)
    // const tutorialLink = 'http://classwin.co/content-lecture/'
    const isJSON = (str) => {
      try {
        return JSON.parse(str).originalFilename
      } catch (error) {
        return str
      }
    }
    const options = [
      {
        id: 'course.content.lecture.uploadvideo',
        label: isTh ? 'อัปโหลดวิดีโอ' : 'Upload Video',
        value: 'browse',
        icon: UploadVideoIcon,
        selectedIcon: SelectedUploadVideoIcon,
      },
      {
        id: 'course.content.lecture.videolink',
        label: isTh ? 'เลือกวีดีโอจาก Youtube' : 'Video link from Youtube',
        value: 'videoLink',
        icon: VideoLinkIcon,
        selectedIcon: SelectedVideoLinkIcon,
      },
      {
        id: 'course.content.lecture.slide',
        label: isTh ? 'เลคเชอร์ สไลด์ (เลือกไฟล์PDF)' : 'Lecture Slide',
        value: 'lectureSlide',
        icon: PDFIcon,
        selectedIcon: SelectedPDFIcon,
      },
      {
        id: 'course.content.lecture.paragraph',
        label: isTh ? 'เนื้อหา' : 'Paragraph',
        value: 'paragraph',
        icon: ParagraphIcon,
        selectedIcon: SelectedParagraphIcon,
      },
    ]
    const Checkbox = (prop) => {
      return (
        <Field name={prop.name}>
          {({ field, form }) => (
            <>
              <Span style={prop.style}>
                <SwitchPercent
                  type="checkbox"
                  name="publish"
                  checked={field.value}
                  onChange={async () => {
                    await form.setFieldValue(prop.name, !field.value)
                    console.log('{field.value}', field.value)
                    console.log('prop', prop)

                    //await form.submitForm()
                  }}
                />
              </Span>
            </>
          )}
        </Field>
      )
    }

    //    console.log('enableCompleteButton', enableCompleteButton)
    return (
      <ErrorBoundary>
        <Desktop>
          <Formik
            initialValues={{
              name,
              description,
              content,
              videoLink,
              lectureType,
              preview,
              lectureSlide,
              coverImageVideo,
              videoFilename,

              enableCompleteButton:
                typeof enableCompleteButton === 'boolean' ? enableCompleteButton : true,
              isTimeRequired: isTimeRequired || false,
              timeRequired: timeRequired || '0',
              durationPercentage: durationPercentage || 95,
              isQuestionRequired: isQuestionRequired || false,
            }}
            enableReinitialize
            onSubmit={async (values) => {
              const data = {
                subjectId,
                lectureId,
                priority,
                ...values,
                durationPercentage: parseInt(values.durationPercentage),
              }
              try {
                await updateLecture(data)
                await this?.props?.init()
                // this.setState({
                //   isAlert: true,
                //   titleAlert: locale === 'en' ? 'Updated Successful' : 'บันทึกเรียบร้อย',
                // })
                notification.success({
                  message: locale === 'th' ? 'บันทึกเรียบร้อย' : 'Updated Successful',
                })
              } catch (error) {
                throw error
              }
            }}
          >
            {(form) => (
              <Form>
                <section style={{ position: 'relative' }}>
                  <StyledContainer
                    column="1"
                    bgColor="#fff"
                    padding="0px"
                    isSelected={isSelected(lectureId)}
                  >
                    <StyledCard
                      onClick={() => handleSelected(lectureId)}
                      // onMouseLeave={this.handleShowButtonAdd(false)}
                      clickable
                    >
                      {/* <IconArrow
                        src={ArrowIcon}
                        rotate="270deg"
                        alt="arrow-icon"
                        // onClick={this.handleExpandCard}
                        onClick={() => handleSelected('')}
                      /> */}
                      <Title>
                        {lectureType ? (
                          <PureIcon
                            src={_get(
                              options.find((item) => item.value === lectureType),
                              'selectedIcon',
                              ''
                            )}
                          />
                        ) : (
                          <PureIcon src={SelectedVideoLinkIcon} />
                        )}
                        <SeperateLine />
                        <Field name="name">
                          {({ field }) => <CardTitle>{field.value}</CardTitle>}
                        </Field>
                      </Title>

                      {/* <EvaIcon
                          fill="#4972FF"
                          name="file-text-outline"
                          size="medium" // small, medium, large, xlarge
                          animation={{
                            // type: 'flip', // zoom, pulse, shake, flip
                            hover: true,
                            infinite: false,
                          }}
                        /> */}

                      {/* <Button
                        transparent
                        onClick={async () => {
                          this.setState({
                            showModal: true,
                          })
                          await getLectureInfo({ lectureId, subjectId })
                        }}
                        type="button"
                        padding="7px 0 7px 13px"
                        icon={
                          <EvaIcon
                            fill="#707070"
                            name="options-2-outline"
                            size="small" // small, medium, large, xlarge
                            animation={{
                              // type: 'flip', // zoom, pulse, shake, flip
                              hover: true,
                              infinite: false,
                            }}
                          />
                        }
                      /> */}
                      <IconSection>
                        {/* <HoverCircleButton
                          type="button"
                          onClick={this.handleShowButtonAdd(!showButtonAdd)}
                          isActive={showButtonAdd}
                          // isDragging={isDragging}
                        >
                          <img src={AddIcon} alt="add-icon" />
                        </HoverCircleButton> */}
                        <Icon
                          // onClick={this.handleRemove}
                          onClick={(e) => {
                            e.stopPropagation()
                            this.setState({ showConfirm: true })
                          }}
                        >
                          <img src={DeleteIcon} width="23px" height="23px" alt="remove-icon" />
                        </Icon>
                        <DragHandle {...dragHandle}>
                          <img src={DragIcon} alt="drag-handle-icon" />
                        </DragHandle>
                      </IconSection>
                    </StyledCard>
                  </StyledContainer>
                </section>
                {/* {isExpand && ( */}
                {/* {isSelected(lectureId) && ( */}
                <CSSTransition
                  in={isSelected(lectureId)}
                  // appear={isSelected(lectureId)}
                  timeout={1000}
                  classNames="lecture-body"
                  unmountOnExit
                  onEnter={() => console.log('onEnter 🌅')}
                  onExited={() => console.log('onExited 🌅')}
                >
                  <ContentBody
                    // {...dragHandle}
                    isSelected={isSelected(lectureId)}
                    isCourseSetting={isCourseSetting}
                    isDragging={isDragging}
                    isDragDisabled
                    onMouseLeave={this.handleShowButtonAdd(false)}
                    column="1"
                    gap="15px"
                    padding="40px 50px 20px 25px"
                  >
                    <Card
                      padding="40px 32px"
                      style={{
                        border: '1px solid #253e87',
                        borderRadius: '4px',
                        marginBottom: '40px',
                      }}
                    >
                      <PureIcon
                        onClick={() => handleSelected('')}
                        style={{ width: 25, height: 25, position: 'absolute', right: 65, top: 60 }}
                        src={closeIcon}
                        alt="arrow-icon"
                      />
                      <Container column="1">
                        <Content
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: 8,
                            marginBottom: 28,
                          }}
                        >
                          <IconArrow
                            // onClick={this.handleExpandCard}
                            onClick={() => handleSelected('')}
                            src={ArrowIcon}
                            rotate="180deg"
                            alt="arrow-icon"
                          />
                          {/* <PureIcon
                                  src={LectureIcon}
                                  style={{ position: 'relative', marginRight: 7 }}
                                /> */}
                          <Typography
                            style={{ cursor: 'pointer' }}
                            onClick={this.handleExpandCard}
                            h4
                            bold
                            width="100%"
                          >
                            {isTh ? 'เลคเชอร์' : 'Lecture'}
                            {/* <CardTitle>{field.value}</CardTitle> */}
                          </Typography>
                          {/* <Button
                                transparent
                                onClick={async () => {
                                  this.setState({
                                    showModal: true,
                                  })
                                  await getLectureInfo({ lectureId, subjectId })
                                }}
                                type="button"
                                padding="7px 0 7px 13px"
                                icon={
                                  <EvaIcon
                                    fill="#707070"
                                    name="options-2-outline"
                                    size="small" // small, medium, large, xlarge
                                    animation={{
                                      // type: 'flip', // zoom, pulse, shake, flip
                                      hover: true,
                                      infinite: false,
                                    }}
                                  />
                                }></Button> */}
                          {/* <Icon
                                marginLeft="5px"
                                onClick={() => {
                                  this.setState({ showConfirm: true })
                                }}>
                                <img
                                  src={DeleteIcon}
                                  width="23px"
                                  height="23px"
                                  alt="remove-icon"
                                />
                              </Icon> */}
                          {/* <a
                                href={tutorialLink}
                                target="_blank"
                                rel="noreferrer"
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <PureIcon
                                  style={{ width: '20px', height: '20px' }}
                                  src={TutorialIcon}
                                />
                              </a> */}
                          {/* <div
                                  style={{
                                    justifyContent: 'flex-end',
                                    display: 'flex',
                                    marginLeft: 5,
                                  }}
                                >
                                  <IconArrow
                                    onClick={() => handleSelected('')}
                                    style={{ width: 25, height: 25, margin: 0 }}
                                    src={closeIcon}
                                    alt="arrow-icon"
                                  />
                                </div> */}
                        </Content>
                      </Container>
                      <Field name="lectureType">
                        {({ field, form }) => (
                          <>
                            <Typography id="label.type" margin="0px 0px 8px" color="primary1">
                              Type
                            </Typography>
                            <div style={{ width: '100%', marginBottom: '24px' }}>
                              <SelectWithIcon
                                options={options}
                                value={options.find(({ value }) => {
                                  return value === field.value
                                })}
                                onChange={({ value }) => {
                                  const data = {
                                    target: {
                                      name: 'lectureType',
                                      value,
                                    },
                                  }
                                  //this.inputChange(data)

                                  this.setState({
                                    dataType: data,
                                    isType: true,
                                    dataValue: value,
                                  })

                                  // form.handleChange(data)
                                  // this.handleSelectType(value)
                                  // onChangeLectureType(value, priority)
                                }}
                              />
                            </div>
                            <ConfirmModal
                              isOpen={isType}
                              onRequestClose={() => this.setState({ isType: false })}
                              title={locale === 'en' ? 'File will be deleted' : 'ไฟล์จะถูกลบ'}
                              subtitle={
                                locale === 'en'
                                  ? `This lecture's file will be delete. Are you sure you want to change the type?`
                                  : 'วีดีโอและไฟล์ของคำถามนี้จะถูกลบ คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนประเภท'
                              }
                              //titleId="modal.sure.delete"
                              btn1={
                                <Button
                                  primary
                                  onClick={() => {
                                    form.handleChange(dataType)
                                    this.handleSelectType(dataValue)
                                    onChangeLectureType(dataValue, priority)
                                    this.setState({ isType: false })
                                  }}
                                >
                                  {locale === 'en' ? 'Confirm' : 'ตกลง'}
                                </Button>
                              }
                              btn2={
                                <Button secondary onClick={() => this.setState({ isType: false })}>
                                  {locale === 'en' ? 'Cancel' : 'ยกเลิก'}
                                </Button>
                              }
                            />
                          </>
                        )}
                      </Field>
                      <Field name="name">
                        {({ field, form }) => {
                          return (
                            <>
                              <InputWithLabel
                                translateId="label.title"
                                id="name"
                                name="name"
                                label="Title"
                                type="text"
                                htmlFor="name"
                                value={field.value}
                                autoFocus
                                onChange={(e) => {
                                  //e.persist()

                                  form.handleChange(e)
                                  this.setState({ name: e?.target?.value })

                                  //this.inputChange(e)
                                  //this.setState({ value: e.target.value })

                                  //onChangeTitleName(e.target.value, priority)
                                }}
                                color="primary1"
                                bold={false}
                              />
                              {form.errors && (
                                <Typography color="danger">{form.errors[field.name]}</Typography>
                              )}
                            </>
                          )
                        }}
                      </Field>
                      <Wrapper />
                      {/* <Field name="description">
                        {({ field, form }) => (
                          <>
                            <InputWithLabel
                              translateId="label.desc"
                              id="description"
                              name="description"
                              label="Description"
                              type="text"
                              htmlFor="description"
                              value={field.value}
                              onChange={(e) => {
                                form.handleChange(e)
                                this.inputChange(e)
                              }}
                              color="primary1"
                              bold={false}
                            />
                            {form.errors && (
                              <Typography color="danger">{form.errors[field.name]}</Typography>
                            )}
                          </>
                        )}
                      </Field> */}

                      {selectType === 'videoLink' && (
                        <>
                          <Field name="videoLink">
                            {({ field, form }) => (
                              <>
                                <Wrapper />
                                {/* <Container
                                style={{
                                  gridTemplateColumns: '118px 50px 1fr',
                                  alignItems: 'center',
                                }}
                              >
                                <Label
                                  id="course.content.lecture.videolink"
                                  htmlFor="introVideo"
                                  color="primary1"
                                  bold={false}
                                >
                                  Video Link
                                </Label>
                              </Container>
                              <Item>
                              <Typography id="courseinfo.intorvideo.videoUrl">
                                Use YouTube Link
                              </Typography>*/}
                                <Typography
                                  id="courseinfo.intorvideo.youtube.desc"
                                  color="black38"
                                  fontSize="text-xs"
                                >
                                  (Copy a link from Youtube and paste the URL into this field)
                                </Typography>
                                {/* </Item> */}
                                <div style={{ marginTop: 8 }}>
                                  <Input
                                    id="introVideo"
                                    name="introVideo"
                                    placeholder="https://www.youtube.com/watch?v=xxx"
                                    value={field.value}
                                    full
                                    onChange={(e) => {
                                      const { value } = e.target
                                      const data = {
                                        target: {
                                          name: 'videoLink',
                                          value,
                                        },
                                      }
                                      form.handleChange(data)
                                      //this.inputChange(data)
                                      this.setState({ linkVideo: value })
                                      console.log('value', value)
                                    }}
                                  />
                                </div>
                                {selectType === 'videoLink' && field.value && (
                                  <>
                                    <Wrapper />
                                    <MediaPlayer
                                      url={field.value}
                                      width="100%"
                                      style={{
                                        background: 'black',
                                        borderRadius: 4,
                                        marginTop: 5,
                                      }}
                                      playerRef={(el) => (this.playerRef = el)}
                                      autoPlay
                                    />
                                  </>
                                )}
                                {selectType === 'videoLink' && field.value && (
                                  <SwitchRow>
                                    <Typography id="course.content.hideVideo" color="primary1">
                                      Hide player controls
                                    </Typography>
                                    <Switch
                                      checked={isHideControl}
                                      onChange={(checked) => this.onIsHideChange(checked)}
                                    />
                                  </SwitchRow>
                                )}
                              </>
                            )}
                          </Field>
                          <Field name="content">
                            {({ field, form }) => (
                              <>
                                {/* <Label htmlFor="content">Content</Label> */}
                                <ClasswinEditor
                                  translateId="course.content.lecture.content"
                                  style={{ marginTop: 24 }}
                                  label="Content"
                                  labelColor="primary1"
                                  labelBold={false}
                                  // labelFontSize="text-base"
                                  id="content"
                                  name="content"
                                  value={field.value}
                                  onChange={(value) => {
                                    const e = {
                                      target: {
                                        name: 'content',
                                        value,
                                      },
                                    }
                                    form.handleChange(e)
                                    // this.inputChange(e)
                                  }}
                                />
                                {form.errors && (
                                  <Typography color="danger">{form.errors[field.name]}</Typography>
                                )}
                              </>
                            )}
                          </Field>
                        </>
                      )}
                      {selectType === 'browse' && (
                        <>
                          <Field name="videoLink">
                            {({ field, form }) => (
                              <>
                                {/* <Container
                                  style={{
                                    gridTemplateColumns: '118px 50px 1fr',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Label
                                    id="browse.title"
                                    htmlFor="introVideo"
                                    fontSize="text-base"
                                  >
                                    Browse
                                  </Label>
                                </Container>

                                <Item style={{ marginBottom: 10, gap: 5 }}>
                                <Typography id="courseinfo.intorvideo.browse">
                                  Browse from your device
                                </Typography>*/}
                                <Wrapper />
                                <Typography
                                  id="courseinfo.intorvideo.browse.desc"
                                  color="black38"
                                  fontSize="text-xs"
                                  margin="0 0 8px"
                                >
                                  (Select file from your device to upload (limit 1.5 GB))
                                </Typography>
                                {/* </Item> */}
                                {selectType === 'browse' && field.value && (
                                  <MediaPlayer
                                    url={field.value}
                                    light={coverImageVideo ? getImageUrl(coverImageVideo) : false}
                                    width="100%"
                                    style={{
                                      background: theme.black87,
                                      marginBottom: 12,
                                    }}
                                  />
                                )}
                                {selectType === 'browse' && (
                                  <UploadSection>
                                    <UploadVideo
                                      limitSize={limitSize}
                                      afterUpload={(value) => {
                                        const data = {
                                          target: {
                                            name: 'videoLink',
                                            value: value.name,
                                          },
                                        }
                                        form.handleChange(data)

                                        const dataName = {
                                          target: {
                                            name: 'videoFilename',
                                            value: value.fileName,
                                          },
                                        }
                                        form.handleChange(dataName)

                                        this.onChangeUploadingVideo({
                                          videoLink: value.name,
                                          videoFilename: value.fileName,
                                        })
                                      }}
                                      videoFilename={form.values.videoFilename}
                                      fieldValue={field.value}
                                      closeIcon={
                                        <CloseIcon
                                          onClick={() => {
                                            const data = {
                                              target: {
                                                name: 'videoLink',
                                                value: '',
                                              },
                                            }
                                            form.handleChange(data)

                                            const dataName = {
                                              target: {
                                                name: 'videoFilename',
                                                value: '',
                                              },
                                            }
                                            form.handleChange(dataName)

                                            this.onChangeUploadingVideo({
                                              videoLink: '',
                                              videoFilename: '',
                                            })
                                          }}
                                        >
                                          <img src={CancelIcon} alt="cancel" />
                                        </CloseIcon>
                                      }
                                    />
                                  </UploadSection>
                                )}
                                {selectType === 'browse' && field.value && (
                                  <SwitchRow>
                                    <Typography id="course.content.hideVideo">
                                      Hide player controls
                                    </Typography>
                                    <Switch
                                      checked={isHideControl}
                                      onChange={(checked) => this.onIsHideChange(checked)}
                                    />
                                  </SwitchRow>
                                )}
                              </>
                            )}
                          </Field>
                          <Field name="coverImageVideo">
                            {({ field, form }) => (
                              <>
                                <Wrapper />
                                <Typography id="courseinfo.covervideo.desc" color="primary1">
                                  Cover image video (Optional)
                                </Typography>
                                <UploadImage
                                  name="coverImageVideo"
                                  cropLabel="Update Cover Image"
                                  value={field.value}
                                  afterUpload={(file) => {
                                    const e = {
                                      target: {
                                        name: 'coverImageVideo',
                                        value: file.name,
                                      },
                                    }
                                    form.handleChange(e)
                                    // this.inputChange(e)
                                  }}
                                  isCrop
                                  aspect={16 / 9}
                                  cropModal
                                />
                              </>
                            )}
                          </Field>
                          <Field name="content">
                            {({ field, form }) => (
                              <>
                                {/* <Label htmlFor="content">Content</Label> */}
                                <ClasswinEditor
                                  translateId="course.content.lecture.content"
                                  style={{ marginTop: 24 }}
                                  label="Content"
                                  labelColor="primary1"
                                  labelBold={false}
                                  // labelFontSize="text-base"
                                  id="content"
                                  name="content"
                                  value={field.value}
                                  onChange={(value) => {
                                    const e = {
                                      target: {
                                        name: 'content',
                                        value,
                                      },
                                    }
                                    form.handleChange(e)
                                    // this.inputChange(e)
                                  }}
                                />
                                {form.errors && (
                                  <Typography color="danger">{form.errors[field.name]}</Typography>
                                )}
                              </>
                            )}
                          </Field>
                          <Typography
                            id="course.content.lecture.completerules"
                            h4
                            margin="24px 0 21px"
                          >
                            Lecture Completation Rules
                          </Typography>
                          <Item>
                            {/* <Checkbox name="enableCompleteButton" value={enableCompleteButton} /> */}
                            <Field name="enableCompleteButton">
                              {({ field, form }) => (
                                <>
                                  <Span>
                                    <SwitchPercent
                                      type="checkbox"
                                      name="publish"
                                      checked={form.values.enableCompleteButton}
                                      onChange={async () => {
                                        await form.setFieldValue(
                                          'enableCompleteButton',
                                          !field.value
                                        )
                                        //await form.submitForm()
                                      }}
                                    />
                                  </Span>
                                </>
                              )}
                            </Field>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                id="course.content.lecture.completerules.add"
                                color="primary1"
                              >
                                Add lecture completion rules
                              </Typography>
                              <Typography color="primary1">
                                {locale === 'th'
                                  ? '(กำหนดอัตราร้อยละที่ผู้เรียนต้องใช้ในการเรียนวิดีโอนี้)'
                                  : '(Set percentage of the video’s duration to complete this video)'}
                              </Typography>
                            </div>
                          </Item>
                          <Item>
                            {/* <Checkbox name="isTimeRequired" />
                      <Item gap={5}>
                        <Typography
                          id="course.content.lecture.completerules.timer"
                          minWidth="fit-content"
                          color="primary1"
                        >
                          Timer
                        </Typography>
                        <TimerDesc>
                          {locale === 'th'
                            ? '(กำหนดอัตราร้อยละที่ผู้เรียนต้องใช้ในการเรียนวิดีโอนี้)'
                            : '(Set percentage of the video’s duration to complete this video)'}
                        </TimerDesc>

                      </Item> */}
                            <div style={{ margin: '12px 0 0 32px', width: 100 }}>
                              <Input
                                id=""
                                name="durationPercentage"
                                label=""
                                type="number"
                                htmlFor="durationPercentage"
                                defaultOpenValue={95}
                                value={form.values.durationPercentage}
                                onChange={(e) => {
                                  const { value } = e.target
                                  form.setFieldValue('durationPercentage', value)
                                  const payload = {
                                    ...form.values,
                                    durationPercentage: parseInt(value),
                                  }
                                  //debounceInput(payload)
                                }}
                                suffix={'%'}
                                spanPadding="0 12px"
                              />
                            </div>
                          </Item>
                        </>
                      )}
                      {selectType === 'lectureSlide' && (
                        <>
                          <Field name="lectureSlide">
                            {({ field, form }) => {
                              return (
                                <div>
                                  {/* <Label id="course.content.lecture.slide" color="primary1">
                                  Lecture Slide (Select PDF file)
                                </Label> */}
                                  <Wrapper />
                                  <Label
                                    descId="course.content.lecture.slide.desc"
                                    description="Select slide from your device to upload file to display together with the video clip"
                                    descFontsize="text-xs"
                                    margin="0 0 8px"
                                  />
                                  <AddFiles>
                                    <div className="file-uploaded-wrapper">
                                      {field.value && (
                                        <File>
                                          <Typography
                                            color="primary1"
                                            fontSize="text-xs"
                                            width="100%"
                                            numberOfLines={1}
                                          >
                                            {isJSON(field.value)}
                                            {/* {field.value.originalFilename || field.value} */}
                                          </Typography>
                                          <CloseIcon
                                            onClick={() => {
                                              const data = {
                                                target: {
                                                  name: field.name,
                                                  value: '',
                                                },
                                              }
                                              form.handleChange(data)
                                              // this.inputChange(data)
                                            }}
                                          >
                                            <img src={CancelIcon} alt="cancel" />
                                          </CloseIcon>
                                        </File>
                                      )}
                                    </div>
                                    <div className="upload-button">
                                      <Upload
                                        afterUpload={(value) => {
                                          const data = {
                                            target: {
                                              name: field.name,
                                              value: JSON.stringify({
                                                name: value.name,
                                                originalFilename: value.originalFilename,
                                              }),
                                            },
                                          }
                                          form.handleChange(data)
                                          // if (value) {
                                          //   console.log('value', value)
                                          //   form.handleChange('sss')
                                          // }
                                          //this.inputChange(data)
                                          // this.inputChange(slideName)
                                        }}
                                        acceptFileTypes={['application/pdf']}
                                      >
                                        <Button
                                          id="course.content.lecture.uploadfile"
                                          type="button"
                                          width={130}
                                          primary
                                          image={<img src={PlusIcon} alt="plus-icon" />}
                                        >
                                          Upload
                                        </Button>
                                      </Upload>
                                    </div>
                                  </AddFiles>
                                  {form.errors && (
                                    <Typography color="danger">
                                      {form.errors[field.name]}
                                    </Typography>
                                  )}
                                </div>
                              )
                            }}
                          </Field>
                          <Field name="content">
                            {({ field, form }) => (
                              <>
                                {/* <Label htmlFor="content">Content</Label> */}
                                <ClasswinEditor
                                  translateId="course.content.lecture.content"
                                  style={{ marginTop: 24 }}
                                  label="Content"
                                  labelColor="primary1"
                                  labelBold={false}
                                  // labelFontSize="text-base"
                                  id="content"
                                  name="content"
                                  value={field.value}
                                  onChange={(value) => {
                                    const e = {
                                      target: {
                                        name: 'content',
                                        value,
                                      },
                                    }

                                    form.handleChange(e)
                                    //this.inputChange(e)
                                  }}
                                />
                                {form.errors && (
                                  <Typography color="danger">{form.errors[field.name]}</Typography>
                                )}
                              </>
                            )}
                          </Field>
                        </>
                      )}
                      {selectType === 'paragraph' && (
                        <Field name="content">
                          {({ field, form }) => (
                            <>
                              {/* <Label htmlFor="content">Content</Label> */}
                              <ClasswinEditor
                                translateId="course.content.lecture.content"
                                style={{ marginTop: 24 }}
                                label="Content"
                                labelColor="primary1"
                                labelBold={false}
                                // labelFontSize="text-base"
                                id="content"
                                name="content"
                                value={field.value}
                                onChange={(value) => {
                                  const e = {
                                    target: {
                                      name: 'content',
                                      value,
                                    },
                                  }
                                  form.handleChange(e)
                                  //this.inputChange(e)
                                }}
                              />
                              {form.errors && (
                                <Typography color="danger">{form.errors[field.name]}</Typography>
                              )}
                            </>
                          )}
                        </Field>
                      )}

                      {/* <Line /> */}
                      {/* ///////////////////////////////////////////////////////////////////////// */}
                      {/* <Field name="preview">
                        {({ field, form }) => (
                          <>
                            <Wrapper />
                            <Container
                              style={{
                                gridTemplateColumns: '135px 50px 1fr',
                                alignItems: 'center',
                              }}
                            >
                              <Label
                                id="course.content.lecture.videolink.preview"
                                htmlFor="introVideoPreview"
                                fontSize="text-base"
                              >
                                Video Preview Link
                              </Label>
                            </Container>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Item>
                                <InputRadio
                                  type="radio"
                                  id="introVideoPreview"
                                  name="introVideoPreview"
                                  value="preview"
                                  onChange={(e) => this.handleCheckBoxPreview(e.target.value)}
                                  checked={selectNamePreview === 'preview'}
                                  style={{ marginTop: 5 }}
                                />
                                <RadioLabel>
                                  <Typography id="courseinfo.intorvideo.videoUrl">
                                    Use YouTube Link
                                  </Typography>
                                  <Typography id="courseinfo.intorvideo.youtube.desc" color="gray2">
                                    (Copy a link from Youtube and paste the URL into this field)
                                  </Typography>
                                </RadioLabel>
                              </Item>
                              {selectNamePreview === 'preview' && (
                                <div style={{ margin: '10px 0px 0px 20px' }}>
                                  <Input
                                    id="introVideoPreview"
                                    name="introVideoPreview"
                                    placeholder="https://www.youtube.com/watch?v=xxx"
                                    value={field.value.url}
                                    full
                                    onChange={(e) => {
                                      const data = {
                                        target: {
                                          name: 'preview',
                                          value: {
                                            type: 'link',
                                            url: e.target.value,
                                          },
                                        },
                                      }
                                      form.handleChange(data)
                                      this.inputChange(data)
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <Container>
                              {selectNamePreview === 'preview' && field.value.url && (
                                <MediaPlayer
                                  url={field.value.url}
                                  width="100%"
                                  style={{
                                    background: 'black',
                                    borderRadius: 4,
                                    marginTop: 5,
                                  }}
                                />
                              )}
                            </Container>
                            <Item style={{ marginTop: '10px' }}>
                              <InputRadio
                                type="radio"
                                id="browsePreview"
                                name="browsePreview"
                                value="browsePreview"
                                onChange={(e) => this.handleCheckBoxPreview(e.target.value)}
                                checked={selectNamePreview === 'browsePreview'}
                                style={{ marginTop: 5 }}
                              />
                              <RadioLabel>
                                <Typography id="courseinfo.intorvideo.browse">
                                  Browse from your device
                                </Typography>
                                <Typography id="courseinfo.intorvideo.browse.desc2" color="gray2">
                                  (Select file from your device to upload (limit 300 MB)
                                </Typography>
                              </RadioLabel>
                            </Item>
                            {selectNamePreview === 'browsePreview' && field.value.url && (
                              <Container>
                                <MediaPlayer
                                  url={field.value.url}
                                  light={coverImageVideo ? getImageUrl(coverImageVideo) : false}
                                  width="100%"
                                  style={{
                                    background: 'black',
                                    borderRadius: 4,
                                    marginTop: 5,
                                  }}
                                />
                              </Container>
                            )}
                            {selectNamePreview === 'browsePreview' && (
                              <div style={{ width: 'fit-content', margin: '10px 0px 0px 0px' }}>
                                <UploadVideo
                                  limitSize={limitSizePreview}
                                  afterUpload={(value) => {
                                    const data = {
                                      target: {
                                        name: 'preview',
                                        value: {
                                          type: 'upload',
                                          url: value.name,
                                        },
                                      },
                                    }
                                    form.handleChange(data)
                                    this.inputChange(data)
                                  }}
                                />
                              </div>
                            )}
                            {selectNamePreview === 'browsePreview' && (
                              <div style={{ marginTop: 5 }}>
                                <Typography id="courseinfo.covervideo.desc">
                                  Cover image video (Optional)
                                </Typography>
                                <UploadImage
                                  name="coverImageVideoPreview"
                                  value={field.value.image}
                                  afterUpload={(file) => {
                                    const e = {
                                      target: {
                                        name: 'preview',
                                        value: {
                                          type: field.value.type,
                                          url: field.value.url,
                                          image: file.name,
                                        },
                                      },
                                    }
                                    form.handleChange(e)
                                    this.inputChange(e)
                                  }}
                                  isCrop
                                  aspect={16 / 9}
                                  cropModal
                                />
                              </div>
                            )}
                          </>
                        )}
                      </Field> */}
                      {/* <Field name="attachment">
                        {({ field, form }) => (
                          <div>
                            <Label
                              id="course.content.lecture.attachment"
                              descId="course.content.lecture.attachment.desc"
                              noMargin
                              description="Select file from your device an upload as an attachment"
                              htmlFor="attachment"
                              fontSize="text-base"
                              descFontsize="text-sm"
                            >
                              Attachment
                            </Label>
                            <AddFiles>
                              <div className="file-uploaded-wrapper">
                                {(attachments || []).map((item, i) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <File key={i}>
                                    <span className="text">
                                      {item.originalFilename || item.name}
                                    </span>
                                    <CloseIcon onClick={() => this.handleDeleteAttachment(item.id)}>
                                      <img src={CancelIcon} alt="cancel" />
                                    </CloseIcon>
                                  </File>
                                ))}
                              </div>
                              <div className="upload-button">
                                <Upload
                                  afterUpload={(value) => this.addAttachment(value)}
                                  acceptFileTypes={[
                                    'application/pdf',
                                    'image/*',
                                    '.xlsx',
                                    '.xls',
                                    '.csv',
                                    '.doc',
                                    '.docx',
                                    '.ppt',
                                    '.pptx',
                                  ]}
                                >
                                  <StyledButton
                                    id="course.content.lecture.choosefile"
                                    type="button"
                                    width="140"
                                  >
                                    Choose File
                                  </StyledButton>
                                </Upload>
                              </div>
                            </AddFiles>
                            {form.errors && (
                              <Typography color="danger">{form.errors[field.name]}</Typography>
                            )}
                          </div>
                        )}
                      </Field> */}
                      {/* <Line /> */}
                      <LectureSetting
                        enableCompleteButton={enableCompleteButton}
                        timeRequired={timeRequired}
                        isTimeRequired={isTimeRequired}
                        isQuestionRequired={isQuestionRequired}
                        subjectId={subjectId}
                        lectureId={lectureId}
                        selectType={selectType}
                        lectureType={lectureType}
                        {...this.props}
                      />
                      <hr
                        style={{ color: '#cbd5e1', backgroundColor: '#cbd5e1', marginTop: '24px' }}
                      />
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
                        <Button id="button.save" type="submit" primary width={171} center>
                          Save
                        </Button>
                      </div>
                      <Alert
                        isOpen={isAlert}
                        error={isError}
                        onRequestClose={this.onCloseAlert}
                        title={titleAlert}
                      />
                    </Card>
                  </ContentBody>
                </CSSTransition>
              </Form>
            )}
          </Formik>
          {/* <Wrapper
            onMouseOver={this.handleShowButtonAdd(true)}
            onFocus={this.handleShowButtonAdd(true)}
            onBlur={this.handleShowButtonAdd(false)}
          /> */}
          <Alert
            isOpen={isLoadding}
            onRequestClose={() => this.setState({ isLoadding: false })}
            title={locale === 'en' ? 'Loading' : 'กำลังโหลด'}
            loading
          />
          {isSelected(lectureId) && (
            <SectionWrapper onMouseLeave={this.handleShowButtonAdd(false)}>
              <img src={AddIcon} alt="add-icon" />
              <ButtonDialogWrapper>
                <ButtonDialog
                  onClick={addNewContent}
                  data={createCurriculumData2}
                  priority={priority}
                />
              </ButtonDialogWrapper>
            </SectionWrapper>
          )}
        </Desktop>
        <Mobile>
          <div>
            <span className="emoji" role="img" aria-label="" aria-hidden={false}></span>
          </div>
        </Mobile>
        <ConfirmModal
          isOpen={showConfirm}
          onRequestClose={this.handleCloseModal}
          title={
            locale === 'en' ? 'Are you sure you want to delete?' : 'คุณแน่ใจหรือไม่ว่าต้องการลบ?'
          }
          // subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry…."
          btn1={
            <Button id="button.confirmModal" onClick={this.handleRemove} primary>
              Submit
            </Button>
          }
          btn2={
            <Button id="button.cancel" secondary onClick={this.handleCloseModal}>
              Cancel
            </Button>
          }
        />

        <LectureSettingModal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseSettingModal}
          subjectId={subjectId}
          lectureId={lectureId}
        />
      </ErrorBoundary>
    )
  }
}

Lecture.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  content: PropTypes.string,
  videoLink: PropTypes.string,
  attachments: PropTypes.array,
  coverImageVideo: PropTypes.string,
  preview: PropTypes.string,
}

Lecture.defaultProps = {
  content: '',
  description: '',
  videoLink: '',
  attachments: [],
  coverImageVideo: '',
  preview: '',
}

const mapState = ({ Language: { locale } }) => ({ locale })
const mapDispatch = ({
  DraftCourse: { getDraft, getDraftCourseStatus },
  Subject: {
    getLectureInfo,
    updateLecture,
    removeLectureById,
    createAttachment,
    deleteAttachmentById,
    getAssessment,
    fetchSubjectLecture,
  },
}) => ({
  getLectureInfo,
  updateLecture,
  removeLectureById,
  createAttachment,
  deleteAttachmentById,
  getDraftCourseStatus,
  getAssessment,
  getDraft,
  fetchSubjectLecture,
})

export default connect(mapState, mapDispatch)(withRouter(Lecture))
