import Resource from '../utils/resource'

export default new Resource('/Lectures', {
  getLectureById: {
    url: '{id}',
    method: 'get',
  },
  getAttachment: {
    url: '{id}/attachments',
    method: 'get',
  },
  createAttachment: {
    url: '{id}/attachments',
    method: 'post',
  },
  updateAttachment: {
    url: '{id}/attachments',
    method: 'patch',
  },
  deleteAttachment: {
    url: '{id}/attachments',
    method: 'delete',
  },
  deleteAttachmentById: {
    url: '{id}/attachments/{fk}',
    method: 'delete',
  },
  setLectureWorksheet: {
    url: '{id}/upsertLectureWorksheet',
    method: 'post',
  },
  deleteTest: {
    url: '{id}/tests/{fk}',
    method: 'delete',
  },
  // getTests: {
  //   url: '{id}/tests',
  //   method: 'get',
  // },
})
