import { connect } from 'react-redux'

import { CourseInstructorPage } from './index'

// let tmp
const mapState = ({
  TeacherSchool: { teacherList },
  SchoolCourse: {
    schoolCourse,
    schoolCourse: { teachers = [] },
  },
}) => {
  // tmp = teacherList.map((item) => ({
  //   id: item.appUser.id,
  //   name: `${item.appUser.firstname} ${item.appUser.lastname}`,
  //   profileImage: item.appUser.profileImage,
  //   skill: '',
  //   ...item.appUser,
  // }))
  return {
    ...schoolCourse,
    teacherList: teacherList.map((item) => ({
      id: item.appUser.id,
      name: `${item.appUser.firstname} ${item.appUser.lastname}`,
      profileImage: item.appUser.profileImage,
      skill: '',
    })),
    _teachers: teachers.map((teacher) => teacher.id),
    // _teachers: teachers,
  }
}

const mapDispatch = ({ TeacherSchool: { getTeacherList }, Course: { updateCourseById } }) => ({
  getTeacherList,
  updateCourse: ({ id, _teachers, customTeacherList }) => {
    // const teachers = tmp.filter((item) => _teachers.includes(item.id))
    // console.log('teachersss', _teachers)
    updateCourseById({ id, _teachers, customTeacherList })
  },
})

export default connect(mapState, mapDispatch)(CourseInstructorPage)
