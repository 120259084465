import styled from 'styled-components'
import { Typography, Container, Button } from '../../components'

const Heading = styled.header`
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(166, 166, 166, 0.5);
  padding: 10px 35px;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
`

const Section = styled.div`
  display: inline-flex;
`

const Icon = styled.img``

const _Button = styled(Button)`
  padding: 8px 25px;
  font-size: large;
  font-weight: 500;
`

export { Heading, Typography, Container, _Button as Button, Icon, Section }
