/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'

import SideBar from './sideBar'
import { accountsettingMenus, teacheraccountsettingMenus } from './menus'
import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'

import PageHeading from '../../components/pageHeading/loadable'
import Breadcrumb from '../../components/breadcrumb/loadable'
// import PageHeadingMobile from './pageHeadingMobile'
// import MobileLayout from './mobileLayout'
import ErrorBoundary from '../../components/errorBoundary'

const LayoutContainer = styled.div`
  display: flex;
  padding: 40px 150px;
  gap: 24px;
  /* @media (max-width: 1024px) {
    padding: 24px 50px;
  }
  @media (max-width: 768px) {
    padding: 24px 20px;
  } */
`

const accountSettingBreadcrumb = [
  {
    id: 'account.title',
    label: 'Account Setting',
    link: '/account-setting',
    mobileLabel: 'Account Setting',
    isMobile: true,
    isActive: true,
  },
]
const accountTeacherSettingBreadcrumb = [
  {
    id: 'account.title',
    label: 'Account Setting',
    link: '/teacher/account-setting',
    mobileLabel: 'Account Setting',
    isMobile: true,
    isActive: true,
  },
]

// <MobileLayout path={this.props.match.path} />

export class AccountSetting extends Component {
  // function AccountSetting({ children }) {
  // const { children } = this.props

  render() {
    const currentpath = this.props.location.pathname
    const isTeacherPath = currentpath.startsWith('/teacher')
    return (
      <ErrorBoundary>
        <LargeDesktop>
          {/* <Desktop> */}
          <PageHeading id="account.title" text="Account Setting" />
          <LayoutContainer>
            <SideBar menus={isTeacherPath ? teacheraccountsettingMenus : accountsettingMenus} />
            {this.props.children}
          </LayoutContainer>
          {/* </Desktop> */}
        </LargeDesktop>
        {isTeacherPath ? (
          <MobileAndTablet>
            {currentpath === '/teacher/account-setting' ? (
              <PageHeading id="account.title" text="Account Setting" />
            ) : (
              <Breadcrumb data={accountTeacherSettingBreadcrumb} />
            )}
            {this.props.children}
          </MobileAndTablet>
        ) : (
          <MobileAndTablet>
            {currentpath === '/account-setting' ? (
              <PageHeading id="account.title" text="Account Setting" />
            ) : (
              <Breadcrumb data={accountSettingBreadcrumb} />
            )}
            {this.props.children}
          </MobileAndTablet>
        )}
      </ErrorBoundary>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  const { Sidebar } = dispatch

  return {
    toggle: Sidebar.toggle,
  }
}

export default connect(mapState, mapDispatch)(AccountSetting)
