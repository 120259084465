import { SchoolAPI } from '../../api'

export const SchoolDashboard = {
  state: {
    data: null,
  },
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        data: payload,
      }
    },
  },
  effects: () => ({
    async getStat({ schoolId }) {
      try {
        const result = await SchoolAPI.getStat({ id: schoolId })

        this.setData(result)
        return
      } catch (e) {
        throw e
      }
    },
  }),
}
