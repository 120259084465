import React from 'react'
import PropTypes from 'prop-types'
import Card from './style'

function PureCard({ children, ...props }) {
  return <Card {...props}>{children}</Card>
}

PureCard.propTypes = {
  children: PropTypes.node.isRequired,
}

PureCard.defaultProps = {}

export default PureCard
