import React from 'react'
import PropTypes from 'prop-types'
import RnModal from 'react-modal'
import styled from 'styled-components'
import windowSize from 'react-window-size'
import './style.css'
import ErrorBoundary from '../errorBoundary'
import closeIcon from '../../images/ic-cancelbtn-gray-24-px.svg'

const CloseButton = styled.img`
  position: absolute;
  top: 15px;
  right: 20px;
  width: 25px;
  height: 25px;
  z-index: 410;
  cursor: pointer;
`
// const CustomModal = styled(RnModal)``

function Modal({ children, overlayStyle, contentStyle, shownXclose, ...props }) {
  const overlay = {
    backgroundColor: 'rgba(38, 38, 38, 0.6)',
    zIndex: 400,
    overflowY: 'auto',
    ...overlayStyle,
  }
  const content = {
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'hidden',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '8px',
    outline: 'none',

    // maxHeight: '95vh',
    // overflowY: 'auto',
    // width: windowWidth <= 768 ? '85%' : null,
    padding: `${shownXclose ? '35' : '20'}px 20px 20px 20px`,
    // margin: '0 10px',
    ...contentStyle,
  }
  return (
    <ErrorBoundary>
      <RnModal closeTimeoutMS={200} {...props} style={{ overlay, content }}>
        {shownXclose && (
          <CloseButton
            src={closeIcon}
            onClick={props.onClickClose}
            style={{ ...props.closeStyle }}
          />
        )}
        {children}
      </RnModal>
    </ErrorBoundary>
  )
}

Modal.defaultProps = {
  overlayStyle: {},
  contentStyle: {},
  shownXclose: false,
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
  overlayStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  shownXclose: PropTypes.bool,
}

export default windowSize(Modal)
