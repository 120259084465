import React from 'react'
import PropTypes from 'prop-types'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContainerCwEditor } from './style'

class CwEditor extends React.Component {
  state = {
    editorState: EditorState.createEmpty(),
  }

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.props.onChange(value)
      }
    )
  }

  render() {
    const { editorState } = this.state
    return (
      <ContainerCwEditor>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
      </ContainerCwEditor>
    )
  }
}

CwEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default CwEditor
