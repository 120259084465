import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Col = ({ children, xs, sm, md, lg, xl, xxl, ...props }) => {
  const _xxl = xxl || xl || lg || md || sm || xs || 4
  const _xl = xl || lg || md || sm || xs || 6
  const _lg = lg || md || sm || xs || 8
  const _md = md || sm || xs || 12
  const _sm = sm || xs || 24
  const _xs = xs || 24
  return (
    <CustomCol xs={_xs} sm={_sm} md={_md} lg={_lg} xl={_xl} xxl={_xxl} {...props}>
      {children}
    </CustomCol>
  )
}

Col.propTypes = {
  children: PropTypes.node,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
}

export default Col

const CustomCol = styled.div`
  display: block;
  overflow: hidden;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 ${(props) => (props.xs / 24) * 100}%;
  flex: 0 0 ${(props) => (props.xs / 24) * 100}%;
  max-width: ${(props) => (props.xs / 24) * 100}%;
  padding: ${(props) => props.rowGap}px ${(props) => props.columnGap}px;

  @media (min-width: 576px) {
    -ms-flex: 0 0 ${(props) => (props.sm / 24) * 100}%;
    flex: 0 0 ${(props) => (props.sm / 24) * 100}%;
    max-width: ${(props) => (props.sm / 24) * 100}%;
  }

  @media (min-width: 768px) {
    -ms-flex: 0 0 ${(props) => (props.md / 24) * 100}%;
    flex: 0 0 ${(props) => (props.md / 24) * 100}%;
    max-width: ${(props) => (props.md / 24) * 100}%;
  }

  @media (min-width: 992px) {
    -ms-flex: 0 0 ${(props) => (props.lg / 24) * 100}%;
    flex: 0 0 ${(props) => (props.lg / 24) * 100}%;
    max-width: ${(props) => (props.lg / 24) * 100}%;
  }

  @media (min-width: 1200px) {
    -ms-flex: 0 0 ${(props) => (props.xl / 24) * 100}%;
    flex: 0 0 ${(props) => (props.xl / 24) * 100}%;
    max-width: ${(props) => (props.xl / 24) * 100}%;
  }
  @media (min-width: 1600px) {
    -ms-flex: 0 0 ${(props) => (props.xxl / 24) * 100}%;
    flex: 0 0 ${(props) => (props.xxl / 24) * 100}%;
    max-width: ${(props) => (props.xxl / 24) * 100}%;
  }

  ${({ Padding }) => {
    if (Padding) {
      return css`
        @media (max-width: 429px) {
          padding: ${Padding};
        }
      `
    }
  }}
`
