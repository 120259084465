import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import './App.css'
import Routes from './routes'

class App extends Component {
  componentDidMount() {
    const { locale } = this.props
    if (locale === 'th') {
      moment.locale('th')
    } else {
      moment.locale('en')
    }
  }

  render() {
    return <Routes {...this.props} />
  }
}

const mapState = (state) => ({
  user: state.AppUser.appUser,
  // alert
  showAlert: state.Alert.isShow,
  loading: state.Alert.loading,
  success: state.Alert.success,
  error: state.Alert.error,
  title: state.Alert.title,
  description: state.Alert.description,
  showBtn: state.Alert.showBtn,
  btnText: state.Alert.btnText,
  onOk: state.Alert.onOk,
  stayOpen: state.Alert.stayOpen,

  // confirm
  showConfirm: state.Confirm.isShow,
  confirmTitle: state.Confirm.title,
  confirmSubtitle: state.Confirm.subtitle,
  onCancel: state.Confirm.onCancel,
  onConfirm: state.Confirm.onConfirm,
  onClearConfirm: state.Confirm.onClearConfirm,
  confirmText: state.Confirm.confirmText,
  cancelText: state.Confirm.cancelText,
  confirmButton: state.Confirm.confirmButton,
  cancelButton: state.Confirm.cancelButton,
  image: state.Confirm.image,
  title2: state.Confirm.title2,
  titleNoMargin: state.Confirm.titleNoMargin,
  titleNoMarginId: state.Confirm.titleNoMarginId,

  locale: state.Language.locale,
})

const mapDispatch = (dispatch) => ({
  toggleAlert: dispatch.Alert.toggle,
  toggleConfirm: dispatch.Confirm.toggle,
})

export default connect(mapState, mapDispatch)(App)
