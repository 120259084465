import styled from 'styled-components'
import { Container, Typography } from '../index'

const Wrapper = styled.div`
  background-color: #f3f3f3;
  padding: 16px 23px;
  margin-bottom: 16px;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  height: 57px;
`

const Countdown = styled(Typography)`
  position: absolute;
  font-size: ${({ theme }) => theme['text-base']};
`

const Key = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Icon = styled.img`
  position: absolute;
  width: 19px;
  right: 22px;
`

export { Container, Wrapper, Countdown, Icon, Key, Typography }
