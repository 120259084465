import React, { Component } from 'react'
import styled from 'styled-components'
// import { ArrowIcon } from '../styledComponents'

const Container = styled.div`
  padding: 10px 10px 0px 10px;
`
const Selector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // border-radius: 6px;
  // background-color: rgba(255, 255, 255, 0.15);
  padding: 10px;
  cursor: pointer;
  > span {
    color: #3d4453;
    font-size: 20px;
    font-weight: 600;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .dropdown {
    path {
      fill: white;
    }
    width: 24px;
    height: 24px;
  }
`

class RoleSelector extends Component {
  render() {
    const { name } = this.props
    return (
      <Container>
        <Selector>
          <span>{name}</span>
        </Selector>
      </Container>
    )
  }
}

export default RoleSelector

export const roleMenus = [
  {
    label: 'Instructor',
    link: '/teacher',
  },
  {
    label: 'Learner',
    link: '/browse/courses',
  },
]
