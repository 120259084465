import styled from 'styled-components'

export const Container = styled.div`
  &.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => (width === 'auto' ? width : width ? `${width}px` : '40px')};
    height: ${({ height }) => (height ? height : 40)}px;
    border-radius: 999px;
    position: relative;
    /* padding: 24px 32px; */
    /* border: 2px solid gray; */
    overflow: hidden;
    font-size: ${({ theme }) => theme['text-2xl']};
    user-select: none;
    :hover {
      background-color: ${({ hoverColor }) =>
        hoverColor ? hoverColor : 'rgba(95, 99, 104, 0.039)'};
    }
  }

  @keyframes useRippleAnimation {
    to {
      transform: scale(15);
      opacity: 0;
    }
  }
`
