import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from '../errorBoundary'
import Button from '../button'
import Typography from '../typography'
import { Container, Email, ContainerLogo, ClasswinLogo, ContainerLanguage } from './styles'
import { connect } from 'react-redux'
import LogoClasswin from '../../images/icons/Classwin logo_Classwin Horizontal - Blue 2.svg'

class ResponseForm extends Component {
  static propTypes = {
    subTitle: PropTypes.element,
    title: PropTypes.string,
    image: PropTypes.string,
    titleId: PropTypes.string,
    subTitleId: PropTypes.string,
  }
  onChangeLocale = (values) => {
    const { updateLocale } = this.props
    updateLocale(values)
  }
  render() {
    const {
      titleId,
      locale,
      isModal,
      subTitleId,
      title,
      subTitle,
      image,
      image1,
      btn,
      btn1,
      btn2,
      email = '',
      values = {},
    } = this.props

    return (
      <ErrorBoundary>
        <Container>
          <div styled={{ backgroundsColor: '#fff', zIndex: '1000' }}>
            <ContainerLogo>
              <ClasswinLogo src={LogoClasswin} alt="logo" shape-rendering="crispEdges" />
            </ContainerLogo>
            {!isModal && (
              <ContainerLanguage>
                <Typography
                  color={locale === 'th' ? 'primary1' : 'black38'}
                  onClick={() => this.onChangeLocale('th')}
                  style={{ cursor: 'pointer' }}
                >
                  TH
                </Typography>
                <Typography color="black38">|</Typography>
                <Typography
                  color={locale === 'en' ? 'primary1' : 'black38'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.onChangeLocale('en')}
                >
                  EN
                </Typography>
              </ContainerLanguage>
            )}
          </div>
          {image && <img src={image} alt="response-img" />}
          {image1 && <img src={image1} alt="response-img" width={160} />}
          <Typography
            id={titleId}
            fontSize="text-2xl"
            color="primary10"
            style={{ fontWeight: '700' }}
            padding="20px 0px 0px"
          >
            {title}
          </Typography>
          {email !== '' && (
            <Email color="primary10" fontSize="text-lg">
              {email}
            </Email>
          )}
          <Typography id={subTitleId} values={values} padding="0px 24px">
            {subTitle}
          </Typography>
          {btn && (
            <div>
              <Button secondary>Cancel</Button>
              <Button danger>Delete</Button>
            </div>
          )}
          {btn1 && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                marginTop: '73px',
              }}
            >
              <br />
              <div>{btn1}</div>
            </div>
          )}{' '}
          {btn2 && (
            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
              <br />
              <div>{btn2}</div>
            </div>
          )}
        </Container>
      </ErrorBoundary>
    )
  }
}
const mapState = ({ Language: { locale } }) => ({
  locale,
})
const mapDispatch = (dispatch) => {
  const {
    Language: { updateLocale },
  } = dispatch
  return {
    updateLocale,
  }
}
export default connect(mapState, mapDispatch)(ResponseForm)
