import React from 'react'
import styled from 'styled-components'
import { menusTeacher } from './menus'
import PageHeading from '../../components/pageHeading/loadable'
import Breadcrumb from '../../components/breadcrumb/loadable'
import SideBar from './sideBar'
import ErrorBoundary from '../../components/errorBoundary'
import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'

const breadcrumb = [
  {
    label: 'Tutorial',
    link: '/tutorialTeacher',
    mobileLabel: 'คู่มือการใช้งานสำหรับผู้สอน',
    isMobile: true,
    isActive: true,
  },
]

const LayoutContainer = styled.div`
  display: flex;
  padding: 40px 150px;
  gap: 24px;
`

const TutorialLayout = (props) => {
  const { children, history } = props
  // const selectPath = history.location.pathname.split('/')[1]
  return (
    <ErrorBoundary>
      <LargeDesktop>
        <PageHeading text="คู่มือการใช้งานสำหรับผู้สอน" />

        <LayoutContainer>
          <SideBar menus={menusTeacher} />
          {children}
        </LayoutContainer>
      </LargeDesktop>
      <MobileAndTablet>
        {history.location.pathname === '/tutorialTeacher' ? (
          <PageHeading text="คู่มือการใช้งานสำหรับผู้สอน" />
        ) : (
          <Breadcrumb data={breadcrumb} />
        )}
        {children}
      </MobileAndTablet>
    </ErrorBoundary>
  )
}

export default TutorialLayout
