import Resource from '../utils/resource'

export default new Resource('/ScheduleExceptions', {
  setScheduleExceptions: {
    url: '',
    method: 'patch',
  },
  getScheduleExceptions: {
    url: '?filter={filter}',
    method: 'get',
  },
})
