/* eslint-disable no-unused-vars */
import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
const Wrapper = styled.div`
  //width: 250px;
`

function LectureNew(props) {
  const { lecture, selectedId, sectionId, subjectId } = props
  const marginTop = lecture ? '16px' : '0px'

  return (
    <Droppable
      //droppableId="lecture"
      droppableId={sectionId}
      type="lecture"

      // ignoreContainerClipping={ignoreContainerClipping}
      // isDropDisabled={isDropDisabled}
      // isCombineEnabled={isCombineEnabled}
    >
      {(dropProvided, dropSnapshot) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: marginTop,
          }}
          ref={dropProvided.innerRef}
        >
          {lecture?.map(({ component: Component, ...data }, idx) => {
            return (
              <Draggable key={data.id} draggableId={data.id} index={idx}>
                {(dragProvided) => (
                  <section
                    key={data.id}
                    {...dragProvided.draggableProps}
                    ref={dragProvided.innerRef}
                  >
                    <Component
                      {...data}
                      dragHandle={dragProvided.dragHandleProps}
                      // isDragging={snapshot.isDragging}
                      //isDragging={isDragging}
                      isDragDisabled={(isDragDisabled) => isDragDisabled}
                      selectedId={selectedId}
                      subjectId={subjectId}
                    />
                  </section>
                )}
              </Draggable>
            )
          })}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default LectureNew
