/* eslint-disable no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import debounce from 'lodash/debounce'
import { Typography, Input } from '../index'
import ErrorBoundary from '../errorBoundary'
import deleteIcon from 'images/icons/TrashColor.svg'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding-bottom: 12px;
`
const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

const ArrangementChoiceInput = ({ choices, index, choice, handleDeleteChoice, setChoices }) => {
  const debounceInput = debounce(async ({ value }) => {
    try {
      const newChoice = choices
      newChoice[index] = value
      setChoices(newChoice)
    } catch (error) {
      throw error
    }
  })

  const inputChange = (e) => {
    const { value } = e.target
    debounceInput({ value })
  }

  return (
    <ErrorBoundary>
      <Container>
        <Formik
          initialValues={{
            choice,
          }}
          enableReinitialize
        >
          <Field name="choice">
            {({ field, form }) => {
              return (
                <Input
                  name="choice"
                  type="text"
                  value={field.value}
                  full
                  onChange={(e) => {
                    form.handleChange(e)
                    inputChange(e)
                  }}
                  spanPadding="0 24px"
                  prefix={<Typography color="black38">{letters[index]}</Typography>}
                />
              )
            }}
          </Field>
        </Formik>
        <DeleteIcon src={deleteIcon} alt="remove-icon" onClick={handleDeleteChoice(index)} />
      </Container>
    </ErrorBoundary>
  )
}

export default ArrangementChoiceInput
