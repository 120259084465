/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { Formik, Form } from 'formik'
// import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffectOnce } from 'react-use'
import * as Yup from 'yup'
import get from 'lodash/get'
import { notification } from 'antd'
import { Typography, Card, Input, Button } from 'components'
import Checkbox from './checkbox'
// import CustomReactSelect from 'components/customReactSelect'
// import { Button, Typography, Icon } from 'components'
// import TutorialIcon from 'images/Classwin - New icon_Tutorial.svg'
import TextInput from '../../components/textInput'
import {
  Container,
  Content,
  CurrencyContainer,
  Description,
  Grid,
  GridPackage,
  Itemradio,
  RadioContainer,
  TextLabel,
  PasswordInput,
  Footer,
} from './styled'
import RadioButton from 'components/customRadioButton'
import PricingSelector from './pricingSelector'
import FreeCourse from './freeCourse'
import PaidCourse from './paidCourse'
import PackageCourse from './packageCourse'

import { formatInput } from '../../utils/commaize'
import getCoursePrice from '../../utils/getCoursePrice'
import Eye from '../../images/icons/Eye.svg'
import EyeSlash from '../../images/icons/EyeSlash.svg'

// const Lable = styled.label`
//   display: block;
//   color: ${(props) => props.theme.primaryFont};
//   margin-bottom: 10px;
// `
const courseFeePercentage = +process.env.REACT_APP_COURSE_FEE_PERCENTAGE

const Currency = () => <CurrencyContainer>THB</CurrencyContainer>
const People = () => <CurrencyContainer>Learner</CurrencyContainer>

const PriceSchema = Yup.object().shape({
  label: Yup.string(),
  value: Yup.number().test({
    name: 'value',
    test: (value) => {
      //return value === 0 || value >= 300
      return value >= 300 && value <= 5000
    },
    message: 'must be a number that is 300 THB or at least 5000 THB',
  }),
})
const PassCodeSchema = Yup.string().required('Please Enter Your Password')
const PassCodeSchemaTh = Yup.string().required('กรุณาใส่รหัสผ่านของคุณ')

const PackagePriceSchema = Yup.array().of(
  Yup.object().shape({
    price: Yup.object().shape({
      label: Yup.string(),
      value: Yup.number().test({
        name: 'value',
        test: (value) => {
          return value >= 300
        },
        message: 'must be a number that is at least 300 THB',
      }),
    }),
    quantity: Yup.object().shape({
      label: Yup.string(),
      value: Yup.number().test({
        name: 'value',
        test: (value) => {
          return value >= 2
        },
        message: 'must be at least 2 people',
      }),
    }),
  })
)

const PackagePriceSchemaTh = Yup.array().of(
  Yup.object().shape({
    price: Yup.object().shape({
      label: Yup.string(),
      value: Yup.number().test({
        name: 'value',
        test: (value) => {
          return value >= 300
        },
        message: 'ต้องมีราคาอย่างน้อย 300 บาท',
      }),
    }),
    quantity: Yup.object().shape({
      label: Yup.string(),
      value: Yup.number().test({
        name: 'value',
        test: (value) => {
          return value >= 2
        },
        message: 'จำเป็นต้องมีผู้เรียน 2 คนขึ้นไป',
      }),
    }),
  })
)

export const CoursePricePage = (props) => {
  // const history = useHistory()
  const {
    match: {
      params: { courseId, schoolId },
    },
    updateCourse,
    getDraft,
    name,
    publicMode,
    schoolPackage,
    mySchoolDetail = {},
    formRef,
    isVerified,
    getCourse,
    course,
    draftInfo,
    updateDraft,
    isPublished,
    // success,
    Alert,
    locale,
    getMyCourseById,

    schoolCourse,
    // schoolCourse: { isHidden /* publicMode */ },
  } = props
  console.log('schoolCourse', schoolCourse)
  console.log('isPublished', isPublished)
  const freeCourseCount = get(mySchoolDetail, 'freeCourseCount', 0)
  const [pricing, setPricing] = useState('')
  const [assess, setAssess] = useState('LINK')
  const [isShowPasscode, setIsShowPasscode] = useState(true)
  const [isCheckBox, setIsCheckBox] = useState()
  const [passCode, setPassCoode] = useState('')
  const inputRef = useRef()
  // useEffectOnce(() => {
  //   getMyCourseById(courseId)
  // })
  useEffectOnce(() => {
    props.getOwnerDetails(props.match.params.schoolId)
  })
  useEffect(() => {
    if (Array.isArray(props.packagePrice) && props.packagePrice.length) {
      setPricing('PACKAGE_COURSE')
    } else if (props.price) {
      setPricing('PAID_COURSE')
    } else if (props.price === 0) {
      setPricing('FREE_COURSE')
    } else {
      setPricing('FREE_COURSE')
    }
    getDraft({ id: courseId })
  }, [publicMode, props.price, courseId, getDraft])
  //}, [publicMode, props.price, props.packagePrice, courseId, getDraft])

  useEffect(() => {
    setPassCoode(draftInfo.passCode)
    if (draftInfo.passCode === '') {
      setIsCheckBox(false)
    } else {
      setIsCheckBox(true)
    }
  }, [draftInfo.passCode])

  const subSchema = {}
  if (pricing === 'PAID_COURSE') {
    subSchema.price = PriceSchema
  }
  if (pricing === 'PACKAGE_COURSE') {
    subSchema.packagePrice = props.locale === 'en' ? PackagePriceSchema : PackagePriceSchemaTh
  }

  if (isCheckBox) {
    subSchema.passCode = props.locale === 'en' ? PassCodeSchema : PassCodeSchemaTh
  } else {
    if (subSchema.passCode) {
      delete subSchema.passCode
    }
  }

  const AllSchema = Yup.object().shape(subSchema)

  const handleVerifyButton = () => {
    // history.push(``)
    const win = window.open(`/schools/${schoolId}/payment/overview`, '_blank')
    win.focus()
  }

  const handleCoursePricing = ({ value }) => {
    setPricing(value)
  }

  const handleSubmit = (values) => {
    const packagePrice = values?.packagePrice?.map((item) => ({
      quantity: item.quantity.value,
      price: item.price.value,
    }))

    const price = values.price.value
    const payload = {
      id: courseId,
      schoolId,
      name,
      publicMode,
      // price: pricing === 'FREE_COURSE' ? 0 : pricing === 'PAID_COURSE' ? price : null,
      passCode: isCheckBox ? passCode : '',
      packagePrice: pricing === 'PACKAGE_COURSE' ? packagePrice : [],
    }
    console.log({ payload })
    if (pricing === 'FREE_COURSE') payload.price = 0
    else if (pricing === 'PAID_COURSE') payload.price = price
    else payload.price = null
    if (!isCheckBox) {
      setPassCoode('')
    }
    updateCourse(payload)
  }
  const defaultPackagePrice =
    publicMode === 'PUBLIC'
      ? [{ quantity: { label: '', value: undefined }, price: { label: '', value: undefined } }]
      : []
  const copyToClipboard = (e) => {
    inputRef.current.select()
    document.execCommand('copy')
    // e.target.focus()
  }
  const handleCopy = (e) => {
    copyToClipboard(e)
    notification.success({ message: locale === 'th' ? 'คัดลอก' : 'copide' })
    //notification.error({ message: locale === 'th' ? 'คัดลอก' : 'copide' })
  }

  return (
    <Container>
      <Formik
        ref={formRef}
        enableReinitialize // Control whether Formik should reset the form if initialValues changes (using deep equality).
        validationSchema={AllSchema}
        // validationSchema={isVerified ? AllSchema : null}
        initialValues={{
          // currency: ''
          // currencyOptions: []
          passCode: passCode,
          price:
            props.price >= 0
              ? {
                  // label: `${props.price === 0 ? 'Free' : formatInput(props.price)}`,
                  label: formatInput(props.price),
                  value: props.price,
                }
              : {
                  // label: `${props.price === 0 ? 'Free' : formatInput(props.price)}`,
                  label: '0',
                  value: 0,
                },
          priceOptions: [
            { label: 'Free', value: 0 },
            { label: '300', value: 300 },
            { label: '400', value: 400 },
            { label: '500', value: 500 },
            { label: '600', value: 600 },
          ],
          packagePrice:
            (props.packagePrice || []).length > 0
              ? (props.packagePrice || []).map((item) => ({
                  quantity: { value: item.quantity, label: formatInput(item.quantity) },
                  price: { value: item.price, label: formatInput(item.price) },
                }))
              : defaultPackagePrice,
        }}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          const priceObj = getCoursePrice(formikProps.values.price.value)
          const addPackage = () => {
            const { packagePrice } = formikProps.values
            const newPackagePrice = [
              ...packagePrice,
              {
                quantity: { label: '', value: undefined },
                price: { label: '', value: undefined },
              },
            ]

            formikProps.setFieldValue('packagePrice', newPackagePrice)
          }
          const onChangePackage = (key) => (index) => (field, value) => {
            if (value) {
              const { packagePrice } = formikProps.values
              const prevItem = packagePrice[index]
              const updatedItem = { ...prevItem, [key]: value }
              const newPackagePrice = [
                ...packagePrice.slice(0, index),
                updatedItem,
                ...packagePrice.slice(index + 1),
              ]

              formikProps.setFieldValue('packagePrice', newPackagePrice)
            }
          }
          const deletePackage = (index) => {
            const { packagePrice } = formikProps.values
            const newPackagePrice = [
              ...packagePrice.slice(0, index),
              ...packagePrice.slice(index + 1),
            ]
            formikProps.setFieldValue('packagePrice', newPackagePrice)
          }
          // const tutorialLink = 'http://classwin.co/tuition-fee/'

          return (
            <Form>
              <div
                style={{
                  //padding: '40px 150px 80px 150px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <Card padding="24px 32px">
                  <div style={{ height: '100%' }}>
                    {/* <SubHeader>
                <div className="wrapper">
                  <div className="main-header">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography id="course.tuitionfee.title" h3 className="title">
                          Tuition Fees
                        </Typography>
                        <a href={tutorialLink} target="_blank" rel="noreferrer">
                          <Icon
                            style={{ width: '20px', height: '20px', margin: '10px' }}
                            src={TutorialIcon}
                          />
                        </a>
                      </div>
                      <SubTitle id="course.tuitionfee.subtitle">Set up your tuition fee</SubTitle>
                    </div>
                    <Button
                      id="button.save"
                      type="submit"
                      primary
                      bold
                      onClick={formikProps.handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </SubHeader> */}
                    <Content>
                      {/* <div>
                  <TextLabel id="course.tuitionfee.fee">Fees</TextLabel>
                  <Description id="course.tuitionfee.fee.desc">
                    Set a tuition fee for this course
                  </Description>
                </div> */}
                      <PricingSelector
                        pricing={pricing}
                        handleSelect={handleCoursePricing}
                        publicMode={publicMode}
                      />
                    </Content>
                    {pricing === 'FREE_COURSE' && (
                      <FreeCourse {...{ pricing, schoolPackage, freeCourseCount }} />
                    )}

                    {pricing === 'PAID_COURSE' && (
                      <PaidCourse {...{ formikProps, isVerified, Currency, handleVerifyButton }} />
                    )}

                    {pricing === 'PACKAGE_COURSE' && (
                      <PackageCourse
                        {...{
                          formikProps,
                          onChangePackage,
                          Currency,
                          People,
                          addPackage,
                          deletePackage,
                        }}
                      />
                    )}

                    {/* <hr className="promotion" />
              <Content>
                <Lable>Promotion(s)</Lable>
                <div className="mock">
                  <br />
                  coming soon...
                  <br />
                </div>
              </Content> */}
                  </div>
                </Card>
                {pricing === 'PAID_COURSE' && (
                  <Card padding="24px 32px" margin="16px 0 0 0">
                    <Typography id="checkout.summary" fontSize="text-xl" color="primary1" superbold>
                      Summary
                    </Typography>
                    <Description id="course.tuitionfee.summary.desc" style={{ marginBottom: 24 }}>
                      Classwin will charge {courseFeePercentage}% of the total tuition fee, divided
                      as follow :
                    </Description>
                    <Grid>
                      <span>
                        <Typography fontSize="text-sm" color="black87">
                          Transaction fee ({courseFeePercentage}%)
                        </Typography>
                      </span>
                      <span>
                        <Typography fontSize="text-sm" color="black87">
                          Transfer ({100 - courseFeePercentage}%)
                        </Typography>
                      </span>
                      <span>
                        <Typography fontSize="text-sm" color="black87">
                          {priceObj.fee}
                        </Typography>
                      </span>
                      <span>
                        <Typography fontSize="text-sm" color="black87">
                          {priceObj.schoolIncome}
                        </Typography>
                      </span>
                    </Grid>
                    <Typography id="course.tuitionfee.remark" fontSize="text-sm" color="primary1">
                      Remark :
                    </Typography>
                    <Typography
                      id="course.tuitionfee.remark.transfer"
                      values={{ schoolIncome: priceObj?.schoolIncome }}
                      fontSize="text-sm"
                      color="primary1"
                    >
                      - Classwin will transfer the amount of {priceObj?.schoolIncome} baht per 1
                      enrollment to you within 30 days after cutting the monthly balance.
                    </Typography>
                    <Typography
                      id="course.tuitionfee.remark.currency"
                      fontSize="text-sm"
                      color="primary1"
                    >
                      - Classwin can only transfer in Thai baht currency to the account you have
                      provided.
                    </Typography>
                  </Card>
                )}
                {publicMode === 'PUBLIC' && pricing === 'PACKAGE_COURSE' && (
                  <Card padding="24px 32px" margin="16px 0 0 0">
                    <Typography id="checkout.summary" fontSize="text-lg" color="primary1" superbold>
                      Summary
                    </Typography>
                    <Description id="course.tuitionfee.summary.desc" style={{ marginBottom: 24 }}>
                      Classwin will charge {courseFeePercentage}% of the total tuition fee, divided
                      as follow :
                    </Description>

                    <div>
                      <GridPackage>
                        <span>
                          <Typography fontSize="text-sm" color="black87">
                            Package
                          </Typography>
                        </span>
                        <span>
                          <Typography fontSize="text-sm" color="black87">
                            Transaction fee ({courseFeePercentage}%)
                          </Typography>
                        </span>
                        <span>
                          <Typography fontSize="text-sm" color="black87">
                            Transfer ({100 - courseFeePercentage}%)
                          </Typography>
                        </span>
                        {formikProps.values.packagePrice.map(
                          (
                            { quantity: { value: quantity = 0 }, price: { value: price } },
                            index
                          ) => (
                            <React.Fragment key={index + ''}>
                              <span>
                                <Typography
                                  fontSize="text-sm"
                                  color="black87"
                                >{`${quantity} learners`}</Typography>
                              </span>
                              <span>
                                <Typography fontSize="text-sm" color="black87">
                                  {getCoursePrice(price).fee}
                                </Typography>
                              </span>
                              <span>
                                <Typography fontSize="text-sm" color="black87">
                                  {getCoursePrice(price).schoolIncome}
                                </Typography>
                              </span>
                            </React.Fragment>
                          )
                        )}
                      </GridPackage>
                    </div>
                    <Typography id="course.tuitionfee.remark" fontSize="text-sm" color="primary1">
                      Remark :
                    </Typography>
                    {/* <Typography
          id="course.tuitionfee.remark.transfer"
          values={{ schoolIncome: priceObj?.schoolIncome }}>
          - Classwin will transfer the amount of {priceObj?.schoolIncome} baht per 1 enrollment to
          you within 30 days after cutting the monthly balance.
        </Typography> */}
                    <Typography
                      id="course.tuitionfee.remark.currency"
                      fontSize="text-sm"
                      color="primary1"
                    >
                      - Classwin can only transfer in Thai baht currency to the account you have
                      provided.
                    </Typography>
                  </Card>
                )}
                <Card padding="24px 32px">
                  <div style={{ height: '100%' }}>
                    <Content>
                      <div>
                        <TextLabel>{locale === 'th' ? 'การเข้าถึง' : 'Access'}</TextLabel>
                      </div>
                      <Itemradio>
                        {/* Course Image */}

                        <RadioContainer>
                          <RadioButton
                            field={{
                              name: 'Access',
                              value: assess,
                              // onChange: (value) => {
                              //   setAssess(value?.target?.value)
                              // },
                            }}
                            id="LINK"
                            label="Choose from file"
                            labelId="CourseContent.link"
                            color="primary1"
                          />
                        </RadioContainer>
                      </Itemradio>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Input
                            readOnly
                            //inputRef={inputRef}
                            style={{
                              cursor: 'text',
                              textShadow: '0px 0px #4a4a4a',
                              color: 'transparent',
                              direction: 'ltr',
                              width: '100%',
                            }}
                            type="text"
                            //value="sss"
                            full
                            value={`${window.location.host}/courses/${draftInfo?.slug}`}
                            inputRef={inputRef}
                            // suffixHover="background: #f1f1f180;"
                          />

                          <Button
                            primary={isPublished === true ? true : false}
                            primary1={isPublished === undefined ? true : false}
                            style={{ width: '168px', height: '48px' }}
                            onClick={() => handleCopy()}
                            type="button"
                            disabled={isPublished === true ? false : true}
                          >
                            {locale === 'th' ? 'คัดลอกลิงก์' : 'Copy Link'}
                          </Button>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '12px',
                            //justifyContent: 'flex-start',
                            //alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Checkbox
                            name="isAcceptedTerm"
                            checked={isCheckBox}
                            onChange={(e) => {
                              setIsCheckBox(!isCheckBox)
                            }}
                          >
                            <Typography fontSize="text-sm" color="primary1">
                              {locale === 'th'
                                ? 'ต้องใช้รหัสผ่านในการลงทะเบียนเรียน'
                                : 'Password is required for enrollment.'}
                            </Typography>
                          </Checkbox>

                          <PasswordInput>
                            <Input
                              name="passCode"
                              readOnly={!isCheckBox}
                              style={{
                                cursor: 'text',
                                textShadow: '0px 0px #4a4a4a',
                                width: '100%',
                              }}
                              type={isShowPasscode ? 'password' : 'text'}
                              full
                              value={passCode}
                              onChange={(e) => {
                                setPassCoode(e.target.value)
                              }}
                              status={formikProps.errors.passCode && 'error'}
                              messageStatus={formikProps.errors.passCode}
                            />
                            {isShowPasscode && (
                              <img
                                src={Eye}
                                className="icon"
                                alt="showPassword"
                                onClick={() => setIsShowPasscode(false)}
                              />
                            )}
                            {!isShowPasscode && (
                              <img
                                src={EyeSlash}
                                className="icon"
                                alt="hidePassword"
                                onClick={() => setIsShowPasscode(true)}
                              />
                            )}
                          </PasswordInput>
                        </div>
                      </div>
                    </Content>
                    <Footer>
                      {isPublished ? (
                        <Link
                          to={`/schools/${schoolId}/course-preview/${courseId}/lecture`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button cancelbtn type="button" id="button.preview" width={171}>
                            Preview
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      <Button id="button.save" type="submit" primary width={171}>
                        Save
                      </Button>
                    </Footer>
                  </div>
                </Card>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

CoursePricePage.defaultProps = {
  packagePrice: [],
  schoolPackage: {},
}

const mapState = ({
  MySchool: { mySchoolDetail },
  DraftCourse: {
    draftInfo: { name, publicMode, price, packagePrice, school },
    draftInfo,
  },
  TeacherSchool: { ownerDetail },
  Language: { locale },
  teachingCourse: { invitationLink, course },
  SchoolCourse: { schoolCourse },
}) => ({
  name,
  publicMode,
  price,
  isVerified: ownerDetail && ownerDetail.isVerified,
  packagePrice,
  schoolPackage: school?.schoolPackage,
  mySchoolDetail,
  locale,
  invitationLink,
  course,
  draftInfo,
  schoolCourse,
})

const mapDispatch = ({
  DraftCourse: { updateDraft, getDraft },
  TeacherSchool: { getOwnerDetails },
  teachingCourse: { getInvitationLink, getCourse },
  SchoolCourse: { getMyCourseById },

  Alert,
}) => ({
  updateCourse: updateDraft,
  getDraft,
  getOwnerDetails,
  getInvitationLink,
  getCourse,
  // success,
  Alert,
  getMyCourseById,
})

export default connect(mapState, mapDispatch)(CoursePricePage)
