import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Typography } from '../components/index'

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${(props) => props.theme.danger};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  ${(props) => {
    if (props.primary) {
      return css`
        background: ${(props) => props.theme.primary2};
      `
    }
    return ``
  }}
  ${(props) => {
    if (props.width) {
      return css`
        width: ${props.width}px;
        height: ${props.width}px;
      `
    }
    return ``
  }}
  ${(props) => {
    if (props.margin) {
      return css`
        margin: ${props.margin};
      `
    }
    return ``
  }}
`

export const MiniBadge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #ff0000;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  font-size: 10px;
  margin-bottom: 3px;
  bottom: 8px;
  right: -8px;

  ${(props) => {
    if (props.primary) {
      return css`
        background: ${(props) => props.theme.primary2};
      `
    }
    return ``
  }}
  ${(props) => {
    if (props.width) {
      return css`
        width: ${props.width}px;
        height: ${props.width}px;
      `
    }
    return ``
  }}
  ${(props) => {
    if (props.margin) {
      return css`
        margin: ${props.margin};
      `
    }
    return ``
  }}
  ${(props) => {
    if (props.left) {
      return css`
        left: ${props.left};
      `
    }
    return ``
  }}
  ${(props) => {
    if (props.position) {
      return css`
        position: ${props.position};
      `
    }
    return ``
  }}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
  /* box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1); */
  background-color: white;
  @media (max-width: 768px) {
    padding: 10;
  }
`

export const Highlight = styled.span`
  color: ${(props) => props.theme.primary2};
`
export const Grey = styled.span`
  color: ${(props) => props.theme.gray2};
`

export const Danger = styled.span`
  color: ${(props) => props.theme.danger};
`

export const CustomLink = styled(Link)`
  text-decoration: none;
`

export const ArrowIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="#4a4a4a"
      fillRule="evenodd"
      d="M7.209 9h9.582a.5.5 0 0 1 .353.854l-4.79 4.78a.5.5 0 0 1-.707 0l-4.791-4.78A.5.5 0 0 1 7.209 9z"
    />
  </svg>
)

export const LightBtn = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: ${(props) => props.theme.primary2};
  width: ${(props) => props.width};

  &.inactive {
    color: ${(props) => props.theme.gray2};
  }
`
export const Heading = styled(Typography)`
  font-size: ${({ theme }) => theme['text-xl']};
  color: ${(props) => props.theme.primary};
  margin-bottom: 8px;
  /* font-weight: 500; */

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme['text-xl']};
    margin: 15px 0;
  }
`

export const Box = styled.div`
  border-radius: 12px;
  background-color: #ffffff;
`

export const Input = styled.input`
  border: 1px solid ${(props) => props.theme.gray2};
  padding: 11px 14px;
  border-radius: 2px;
  width: 100%;

  :focus {
    outline: none;
  }
`

export const Pill = styled.div`
  height: 40px;
  border-radius: 20px;
  padding: 0 16px;
  border: 1px solid ${(props) => props.theme.primary2};
  margin-bottom: 10px;
  color: ${(props) => props.theme.primary2};

  display: flex;
  align-items: center;
  justify-content: center;

  :not(:last-child) {
    margin-right: 10px;
  }

  > :first-child {
    color: ${(props) => props.theme.primary2};
    margin-right: 14px;
  }

  button {
    border: none;
    background: none;
    height: 24px;

    :focus {
      outline: none;
    }
  }
  ${(props) => {
    if (props.primary) {
      return css`
        border: 1px solid ${(props) => props.theme.primary7};
        background: ${(props) => props.theme.primary7};
        color: ${(props) => props.theme.white};
      `
    }
    if (props.secondary) {
      return css`
        border: 1px solid ${(props) => props.theme.primary1};
        background: ${(props) => props.theme.primary1};
        color: ${(props) => props.theme.white};
      `
    }
  }}
`
export const Error = styled.div`
  color: ${({ theme }) => theme.danger};
  font-size: ${({ theme }) => theme['text-xs']};
  margin-bottom: 10px;
`
export const PlainBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`

export const Footer = styled.footer`
  z-index: 1;
  bottom: 0;
  right: 0;
  width: calc(100%);
  background-color: #fff;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 50px;
  display: flex;
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  position: fixed;

  @media (max-width: 768px) {
    padding: 10px 15px;
    width: 100%;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;

  button {
    width: 100px;

    & + button {
      margin-left: 10px;
    }
  }

  .left-auto {
    margin-left: auto;
  }
`
export const HeadingWrapper = styled.div`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`

export const Label = styled.label`
  color: ${(props) => props.theme.gray2};

  ${(props) => {
    if (props.full) {
      return css`
        grid-column: 1 / -1;
      `
    }
    return ``
  }}
`

export const OverviewGrid = styled(Box)`
  padding: 10px;
  display: grid;
  grid-template-columns: max-content min-content;
  justify-content: space-between;

  .line {
    grid-column: 1 / -1;
    margin: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`
export const SubHeading = styled.p`
  color: ${(props) => props.theme.gray2};
  font-size: ${({ theme }) => theme['text-lg']};
`

export const Container = styled.div`
  padding: 40px 150px;

  @media (max-width: 1024px) {
    padding: 40px 50px;
  }
  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`
