import Resource from '../utils/resource'

export default new Resource('/CourseMarketplaces', {
  getSchoolCourseMarketplacesAll: {
    url: 'public',
    method: 'get',
  },
  getSchoolCourseMarketplacesAllCount: {
    url: 'public/count',
    method: 'get',
  },
  addToMarketplaces: {
    url: 'add',
    method: 'post',
  },
  getCourseMarketplacesCategory: {
    url: '{id}/category',
    method: 'get',
  },
})
