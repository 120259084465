import { UserAPI } from 'api'

const OneSignal = window.OneSignal || []

export const init = () => {
  OneSignal.push(function start() {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
      notifyButton: {
        enable: false,
      },
      allowLocalhostAsSecureOrigin: true,
    })
    OneSignal.showNativePrompt()
    OneSignal.getUserId().then((playerId) => {
      UserAPI.updatePlayerId({ playerId })
    })
  })
}

export const updatePlayerId = async () => {
  const playerId = await window.OneSignal.getUserId()
  await UserAPI.updatePlayerId({ playerId })
}

export default OneSignal
