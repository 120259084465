import React from 'react'
import Switch from 'react-switch'
import ErrorBoundary from '../../components/errorBoundary'
import { SwitchGroup, SwitchContainer } from './styles'
import { Typography } from '../../components'

const PeriodSwitch = ({ isYearly, setIsYearly }) => {
  const handleChange = (checked) => {
    setIsYearly(checked)
  }

  return (
    <ErrorBoundary>
      <SwitchGroup>
        <Typography h6 bold color="primary1" id="school.selectPackage.Monthly" label>
          Monthly
        </Typography>
        <SwitchContainer>
          <Switch
            className="react-switch-bg"
            onChange={handleChange}
            checked={isYearly}
            checkedIcon={false}
            uncheckedIcon={false}
            height={20}
            width={38}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.3)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            onColor="#CBD5E1"
            offColor="#CBD5E1"
            offHandleColor="#253E87"
            onHandleColor="#253E87"
          />
        </SwitchContainer>
        <div style={{ display: 'flex' }}>
          <Typography h6 bold color="primary1" id="school.selectPackage.Yearly" label>
            Yearly
          </Typography>
          <Typography
            color="black60"
            id="school.selectPackage.Yearlysub"
            fontSize="text-xs"
            margin="0 0 0 8px"
          >
            (Save 16%)
          </Typography>
        </div>
      </SwitchGroup>
    </ErrorBoundary>
  )
}

export default PeriodSwitch
