import styled from 'styled-components'
import Typography from '../../components/typography'

export const Container = styled.div`
  flex: 1;
  /* background: #fff; */
  padding: 40px 50px 40px 40px;
  width: 100%;
  height: 100%;
  /* box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px; */
  position: relative;
  /* height: calc(100vh - 80px - 50px); */
  /* overflow: scroll; */

  /* Hide scrollbar for Chrome, Safari and Opera */
  /* ::-webkit-scrollbar {
    display: none;
  } */

  /* Hide scrollbar for IE, Edge and Firefox */

  /* -ms-overflow-style: none; */
  /* IE and Edge */
  /* scrollbar-width: none; */
  /* Firefox */

  @media (max-width: 991px) {
    border-radius: 0px;
    height: calc(100vh - 100px);
    padding-bottom: 45px;
  }
`

export const SubHeader = styled.div`
  .wrapper {
    display: flex;
    position: relative;
    padding: 20px 45px;
    border-bottom: 1px solid #dedfe0;
    @media (max-width: 768px) {
      padding: 20px 15px;
    }
  }

  .main-header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin: 0px;
    }

    // button {
    //   /* padding: 12px; */
    //   padding-top: 7px;
    //   padding-bottom: 7px;
    //   /* max-height: 30px; */
    // }
  }
`

export const Content = styled.div`
  /* padding: 24px 32px 0 32px; */
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100% - 250px);
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 426px) {
    height: auto;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 15px;
`
export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme['text-xs']};
  /* line-height: 8px; */
  color: ${(props) => props.theme.gray2};
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const Line = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.black38};
  margin-top: 50px;
  width: 100%;
`
