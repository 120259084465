/* eslint-disable no-unused-vars */
import React from 'react'
// import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _ from 'lodash'
import LazyLoad from 'react-lazyload'
import { useMeasure } from 'react-use'

import { getPrice } from 'utils/utils'
import {
  GridItem,
  Content,
  Image,
  Name,
  Description,
  // Profile,
  IconWithText,
  StyledLink,
  Status,
  StatusFlag,
  Bottom,
  StarGroup,
} from './styles'

import { Typography, HoverVideoPlayer } from '../index'
// import OnlineIcon from '../../images/Classwin - New icon_2-Online Course - B.svg'
// import ClassIcon from '../../images/Classwin - New icon_Classroom.png'
import Vector from '../../images/icons/Vector.svg'
import FileText from '../../images/icons/FileText1.svg'
import classroom01 from '../../images/icons/classroom1-01.svg'
// import PublicIcon from '../../images/ic-public-white-40-px.svg'
// import HiddenIcon from '../../images/ic-hidden-white-40-px.svg'
// import PrivateIcon from '../../images/ic-private-white-40-px.svg'
import Flag from '../../images/icons/flag-draft.svg'
import Icon from '../icon/loadable'
import { courseTypes } from '../../constants'
import getImageUrl from '../../utils/getImageUrl'
import DefaultImage from '../../images/icon_Course_Default.png'
import { ReactComponent as EmptyStarIcon } from '../../images/EmptyStar.svg'
import { ReactComponent as FullStarIcon } from '../../images/FullStar.svg'
import theme from 'theme'

// const Progressbar = styled.div`
//   width: 100%;
//   height: 30px;
//   border-radius: 2px;
//   background-color: #f3f3f3;
//   margin: 10px 0px;
// `

// const Score = styled.div`
//   background-color: ${(props) => props.color};
//   padding: 2px;
//   width: ${(props) => props.score}%;
//   height: 100%;
//   text-align: center;
// `

// const displayWidth = 60
// const imageWidth = displayWidth * 2

const PriceText = ({ price, packagePrice = [] }) => {
  if (price === 0 || price === '0') {
    return (
      <Typography id="card.course.free" fontSize="text-sm" color="primary1">
        FREE
      </Typography>
    )
  }
  if (price > 0) {
    return (
      <Typography
        id="card.course.unit"
        fontSize="text-sm"
        color="primary1"
        values={{ price: getPrice(price, null, false, false) }}
      >
        {price} THB
      </Typography>
    )
  }

  if (Array.isArray(packagePrice) && packagePrice.length) {
    return (
      <Typography
        id="card.course.varyPrice"
        fontSize="text-sm"
        color="primary1"
        values={{
          price: getPrice(packagePrice?.[0]?.price, null, false, false),
          quantity: packagePrice?.[0]?.quantity,
        }}
      >
        {packagePrice?.[0]?.price} THB for {packagePrice?.[0]?.quantity} learners
      </Typography>
    )
  }

  return (
    <Typography id="" paragraph fontSize="text-sm">
      -
    </Typography>
  )
}

const CardCourse = ({
  name,
  type,
  shortDescription,
  price,
  image,
  link = '',
  // teachers,
  isDraft,
  lectureNum: lNumb,
  packagePrice,
  avgRating,
  subCategory,
  ...props
}) => {
  const [ref, { width }] = useMeasure()

  const checkIcon = (type) => {
    if (type === 'online') {
      return (
        <Icon
          src={Vector}
          alt="online-icon"
          style={{ width: '16px', height: '16px', marginRight: '4px', alignSelf: 'center' }}
        />
      )
    }
    if (type === 'classroom') {
      return (
        <Icon
          src={classroom01}
          alt="class-icon"
          style={{ width: '16px', height: '16px', marginRight: '4px', alignSelf: 'center' }}
        />
      )
    }
  }
  // /my-course/courses/62944e8a0bb063d34c4f5957/lecture
  const lectureNum =
    lNumb ||
    _get(props, 'publishedSubject.data.lectures', []).filter((lecture) => lecture.type === '')
      .length
  return (
    <LazyLoad height={336}>
      <GridItem style={props.style}>
        <StyledLink to={link}>
          <HoverVideoPlayer />
          <Image
            ref={ref}
            style={{ height: (524 * width) / 1000 }}
            //style={{ height: 300, width: 400 }}
            url={getImageUrl(image, DefaultImage)}
          />
        </StyledLink>
        {isDraft && (
          <>
            <StatusFlag src={Flag} />
            <Status>
              <Typography id="course.instructor.draft" bold color="white">
                Draft
              </Typography>
            </Status>
          </>
        )}

        <StyledLink to={link}>
          <Content>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Typography
                fontSize="text-xs"
                color="black87"
                padding="6.1875px 8.25px"
                style={{ backgroundColor: theme.whiteblue, borderRadius: '4.125px' }}
              >
                {subCategory?.name}
              </Typography>
              {!!avgRating && (
                <div style={{ display: 'flex', gap: '6px' }}>
                  <Typography
                    fontSize="text-xs"
                    color="primary1"
                    style={{ alignSelf: 'center' }}
                    bold
                  >
                    {avgRating}
                  </Typography>
                  <StarGroup>
                    {_.times(5, (index) => {
                      if (index < avgRating) {
                        return <FullStarIcon width={10} height={10} />
                      }
                      return <EmptyStarIcon width={10} height={10} />
                    })}
                  </StarGroup>
                </div>
              )}
            </div>
            <div>
              <Name label color="black100" style={{ marginBottom: '10px' }}>
                {name}
              </Name>
              <Description color="black87">{shortDescription}</Description>
            </div>
            <div
              style={{ border: `1.03125px solid ${theme.black10}`, margin: '10px 0px 10px 0px' }}
            ></div>
            <Bottom>
              <IconWithText start className="mode">
                <PriceText price={price} packagePrice={packagePrice} />
              </IconWithText>
              <IconWithText primary>
                <div style={{ display: 'flex' }}>
                  <Icon
                    src={FileText}
                    alt="file-text"
                    style={{
                      width: '16px',
                      height: '16px',
                      marginRight: '4px',
                      alignSelf: 'center',
                    }}
                  />
                  <Typography
                    label2
                    fontSize="text-xs"
                    // className="after"
                    id="card.course.total.lecture"
                    color="black60"
                    values={{ lectureCount: lectureNum }}
                  >
                    {lectureNum} Lectures
                  </Typography>
                </div>
                <div style={{ display: 'flex' }}>
                  {checkIcon(type)}
                  <Typography id={`card.course.type.${type}`} color="black60" fontSize="text-xs">
                    {courseTypes[type]}
                  </Typography>
                </div>
              </IconWithText>
            </Bottom>
          </Content>
        </StyledLink>
      </GridItem>
    </LazyLoad>
  )
}

export default CardCourse
