/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { v4 as uuid } from 'uuid'
import {
  Formik,
  Form,
  // Field
} from 'formik'
import styled, { css } from 'styled-components'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import DeleteIcon from 'images/icons/TrashColor.svg'
import ArrowIcon from 'images/icons/CaretDown.svg'
import closeIcon from 'images/icons/Gray-X.svg'
import {
  Typography,
  // Container,
  Dropdown,
  Card,
  // Input
  Button,
} from '../../components'
import ConfirmModal from 'components/confirmModal/loadable'
import ErrorBoundary from '../../components/errorBoundary'
import { ContainerQuestions, ContainerHeader, DragHandle, Wrapper, Content } from './style'
import { Icon, IconArrow } from '../courseContentPage/contents/lecture/style'
import {
  SingleChoice as MultipleChoice,
  ShortAnswer,
  // File,
  // Paragraph,
  PointImage,
  Arrangement,
} from './type'
import ArrangementIcon from 'images/icons/Black-Arrangement.svg'
import SelectedArrangementIcon from 'images/icons/Primary-Arrangement.svg'
import MuitipleChoicesIcon from 'images/icons/Black-MuitipleChoices.svg'
import SelectedMuitipleChoicesIcon from 'images/icons/Primary-MuitipleChoices.svg'
import PointOnImageIcon from 'images/icons/Black-PointOnImage.svg'
import SelectedPointOnImageIcon from 'images/icons/Primary-PointOnImage.svg'
import ShortAnswerIcon from 'images/icons/Black-ShortAnswer.svg'
import SelectedShortAnswerIcon from 'images/icons/Primary-ShortAnswer.svg'
import SelectWithIcon from 'components/selectWithIcon/loadable'
import DragIcon from 'images/icons/DotsSixVertical.svg'
import { CardTitle, IconSection, Title } from 'containers/courseContentPage/style'
import { notification } from 'antd'
const Circle = styled.div`
  width: 16px;
  height: 16px;
  // margin-right: 10px;
  border-radius: 50%;
  cursor: pointer;

  ${({ tag, theme }) => {
    let style = ''
    switch (tag) {
      case 'require':
        style = `
          background-color: ${theme.danger};
          border: none;
        `
        break
      case 'optional':
        style = `
          background-color: ${theme.darkorange};
          border: none;
        `
        break
      default:
        style = `
          background-color: transparent;
          border: 1px dashed ${theme.gray3};
        `
        break
    }
    return style
  }}
`

const QuestionCard = styled(Card)`
  position: relative;
  padding: 0 24px 0 8px;
  margin: 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-color: none;
  border-left-width: 1px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  //border-left-width: 16px;
  // box-shadow: none;
  // transition: box-shadow, all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 0;
  :hover {
    border-color: ${({ theme }) => theme.primary1};
  }
  ${({ selected, theme }) => {
    if (selected)
      return css`
        // -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
        // -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
        // box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
        // border-radius: 4px;
        // border-bottom: 1px solid rgba(100, 100, 100, 0);
        /* border-bottom: none; */
        /* margin-top: -4px; */
        // transform: scale(1.01) translateY(-3px);
        z-index: 4;
        border-color: ${theme.primary1};
        // .deleteIcon {
        //   display: flex;
        // }
      `
    return css``
  }}

  p.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

// const StyledTypography = styled(Typography)`
//   display: flex;
//   align-items: center;
//   max-width: calc(100% - 59.95px);
//   @media (max-width: 454px) {
//     max-width: 30%;
//   }
// `

const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

// const QuestionType = styled(Typography)`
//   @media (max-width: 454px) {
//     /* display: none; */
//     /* width: 0px; */
//     overflow: hidden;
//   }
// `

const optionsDropDown = [
  {
    label: () => (
      <Tag>
        <Circle tag="noTag" />
        <Typography id="SideQuestion.notag">No Tag</Typography>
      </Tag>
    ),
    value: 'noTag',
  },
  {
    label: () => (
      <Tag>
        <Circle tag="require" />
        <Typography id="SideQuestion.require">Required</Typography>
      </Tag>
    ),
    value: 'require',
  },
  {
    label: () => (
      <Tag>
        <Circle tag="optional" />
        <Typography id="SideQuestion.optional">Optional</Typography>
      </Tag>
    ),
    value: 'optional',
  },
]

class SideQuestion extends React.Component {
  state = {
    type: 'choice question',
    isExpand: false,
    isDropdown: false,
    value: '',
    isModalType: false,
    valueType: '',
    choices: [],
    answers: [],
    points: [],
    image: '',
    title: `{"content":"<p><br></p>","delta":{"ops":[{"insert":"\\n"}]}}`,
  }

  debounceInput = debounce(async (title, type) => {
    const {
      updateQuestion,
      id: questionId,
      assessmentId,
      // handleAutoSave,
      getQuestionData,
    } = this.props
    const { type: currentType } = this.state
    const payload = {
      assessmentId,
      questionId,
      type: type || currentType,
      title,
    }
    try {
      await updateQuestion(payload)
      // handleAutoSave(true)
    } catch (error) {
      // handleAutoSave(false)
      throw error
    } finally {
      await getQuestionData()
    }
  }, 800)

  debounceHandleInput = debounce(async ({ name, value }, type) => {
    const {
      updateQuestion,
      id: questionId,
      assessmentId,
      //  handleAutoSave,
      getQuestionData,
    } = this.props
    const payload = {
      assessmentId,
      type,
      questionId,
      [name]: value,
    }
    try {
      await updateQuestion(payload)

      // handleAutoSave(true)
    } catch (error) {
      // handleAutoSave(false)
      throw error
    } finally {
      await getQuestionData()
    }
  }, 1000)

  componentDidMount() {
    const { type, choices = [], answers = [], title, points, image } = this.props
    this.setState({ type, choices, answers, title, points, image })
  }
  // componentDidUpdate(prevProps, prevState) {
  //   console.log('prevState', prevState)
  //   console.log('state', this.state)
  // }
  handleInputChange = (e, type) => {
    const { name, value } = e.target
    this.debounceHandleInput({ name, value }, type)
  }

  handleAddChoice = async () => {
    const { id: questionId, assessmentId, getQuestionData } = this.props
    const { type, choices, answers } = this.state
    const uid = uuid()
    try {
      const obj = { content: `<p></p>`, delta: { ops: [{ insert: '' }] } }

      this.setState({
        choices:
          type === 'arrangement' ? [...choices, ''] : [...choices, JSON.stringify({ ...obj, uid })],
      })
      if (type === 'arrangement') {
        console.log('type if', type)
        const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
        const indexChoice = choices.length
        const choiceAdd = letters[indexChoice]
        this.setState({
          answers: [...answers, choiceAdd],
        })
      }
    } catch (error) {
      throw error
    }
  }

  handleAddAnswer = async () => {
    const { id: questionId, assessmentId, getQuestionData } = this.props
    const { answers } = this.state
    try {
      this.setState({ answers: [...answers, ''] })
    } catch (error) {
      throw error
    }
  }

  inputChange = (value, type) => {
    this.setState({ value: value, type: type })
  }

  handleDropdown = async () => {
    this.setState(({ isDropdown }) => ({ isDropdown: !isDropdown }))
  }

  handleRemove = async (e) => {
    const { deleteQuestion, id: questionId, assessmentId, getQuestionData } = this.props
    const payload = {
      assessmentId,
      questionId,
    }
    e.stopPropagation()
    try {
      await deleteQuestion(payload)
    } catch (error) {
      throw error
    } finally {
      await getQuestionData()
    }
  }

  handleExpandCard = () => {
    this.setState(({ isExpand }) => ({ isExpand: !isExpand }))
  }

  handleDeleteChoice = (index) => async () => {
    const { id: questionId, assessmentId } = this.props
    try {
      this.setState(({ choices }) => {
        const ch = _cloneDeep(choices)
        ch.splice(index, 1)
        return {
          choices: ch,
        }
      })
    } catch (error) {
      throw error
    }
  }

  handleDeleteAnswer = (index) => async () => {
    const { id: questionId, assessmentId } = this.props
    // const { answers } = this.state
    try {
      this.setState(({ answers }) => {
        const ch = _cloneDeep(answers)
        ch.splice(index, 1)
        return {
          answers: ch,
        }
      })
    } catch (error) {
      throw error
    }
  }

  reFormat = (value = '') => {
    const obj = { content: `<p>${value}</p>`, delta: { ops: [{ insert: '' }] } }
    return JSON.stringify(obj)
  }

  renderType = () => {
    const { type } = this.state
    let component = null

    switch (type) {
      case 'Fill a word/letter':
        component = ShortAnswer
        break
      case 'arrangement':
        component = Arrangement
        break
      case 'pointOnImage':
        component = PointImage
        break

      default:
        component = MultipleChoice
        break
    }
    return component
  }

  handleTagChange = async (value) => {
    const { id, worksheetId, updateQuestion, getQuestionData } = this.props
    try {
      const payload = {
        assessmentId: worksheetId,
        questionId: id,
        questionTag: value,
      }
      await updateQuestion(payload)
      await getQuestionData()
    } catch (error) {
      return error
    } finally {
      this.handleDropdown()
    }
    return null
  }
  handleType = async () => {
    const { type: currentType, valueType: newType, choices, answers, image, points } = this.state

    if (choices?.length > 0 || answers?.length > 0) {
      try {
        this.setState({ type: newType, choices: [], answers: [] })
      } catch (error) {
        throw error
      }
    } else if (currentType === 'pointOnImage' && (points?.length > 0 || image)) {
      try {
        this.setState({ type: newType, points: [], image: '' })
      } catch (e) {
        console.log(JSON.stringify(e.response))
      }
    } else {
      this.setState({ type: newType, points: [], image: '', choices: [], answers: [] })
    }
    this.setState({ isModalType: false })
  }

  handleTypeChange = (selectedOption) => {
    const { valueType } = this.state
    this.setState({ isModalType: true, valueType: selectedOption?.value })
  }
  setTitle = (value) => {
    this.setState({ title: value })
  }
  setChoices = (value) => {
    this.setState({ choices: value })
  }
  setAnswers = (value) => {
    this.setState({ answers: value })
  }
  setPoints = (value) => {
    this.setState({ points: value })
  }
  setImage = (value) => {
    this.setState({ image: value })
  }
  handleSave = async () => {
    const { locale, updateQuestion, id: questionId, assessmentId, Alert } = this.props
    const { choices, answers, points, title, type, image } = this.state

    if (type === 'pointOnImage') {
      if (!image) {
        Alert.error({ title: locale === 'en' ? 'Please insert picture.' : 'กรุณาใส่รูป' })
        return
      }
      try {
        const payload = {
          assessmentId,
          questionId,
          title,
          type,
          points: points,
          image,
        }
        if (payload.points.length > 0) {
          await updateQuestion(payload)
          notification.success({
            message: locale === 'th' ? 'บันทึกเรียบร้อย' : 'Updated Successful',
          })
        } else {
          notification.error({
            message: locale === 'th' ? 'กรุณาใส่คำตอบ' : 'Please provide an answer.',
          })
          // Alert.error({
          //   title: locale === 'en' ? 'Please provide an answer.' : 'กรุณาใส่คำตอบ',
          // })
        }
      } catch (error) {
        throw error
      }
    } else {
      try {
        const payload = {
          assessmentId,
          questionId,
          choices: choices,
          answers: answers,
          title,
          type,
          points: points,
        }
        if (payload.answers.length > 0) {
          await updateQuestion(payload)
          notification.success({
            message: locale === 'th' ? 'บันทึกเรียบร้อย' : 'Updated Successful',
          })
        } else {
          notification.error({
            message: locale === 'th' ? 'กรุณาใส่คำตอบ' : 'Please provide an answer.',
          })
          // Alert.error({
          //   title: locale === 'en' ? 'Please provide an answer.' : 'กรุณาใส่คำตอบ',
          // })
        }
      } catch (error) {
        throw error
      }
    }
  }
  render() {
    const {
      index,
      correctScore,
      dragHandle,
      questionTag,
      isSelected,
      handleSelect,
      id: questionId,
      match: { path },
      customStyle,
      locale,
      image: ImageApi,
    } = this.props
    const { type, isDropdown, isModalType, valueType, choices, answers, title, points, image } =
      this.state
    const isTh = locale === 'th'
    const options = [
      {
        id: 'label.multiplechoices',
        label: isTh ? 'ปรนัย' : 'Multiple Choices',
        value: 'choice question',
        icon: MuitipleChoicesIcon,
        selectedIcon: SelectedMuitipleChoicesIcon,
      },
      {
        id: 'label.shortanswer',
        label: isTh ? 'อัตนัย (คำตอบสั้น)' : 'Short answer',
        value: 'Fill a word/letter',
        icon: ShortAnswerIcon,
        selectedIcon: SelectedShortAnswerIcon,
      },
      {
        id: 'label.arrangement',
        label: isTh ? 'จัดเรียง' : 'Arrangement',
        value: 'arrangement',
        icon: ArrangementIcon,
        selectedIcon: SelectedArrangementIcon,
      },
      {
        id: 'label.pointimage',
        label: isTh ? 'ตำแหน่งรูป' : 'Point on Image',
        value: 'pointOnImage',
        icon: PointOnImageIcon,
        selectedIcon: SelectedPointOnImageIcon,
      },
    ]
    const selected = options.find(({ value }) => value === type)
    const Component = this.renderType()
    const regex = new RegExp(/<p>(.*?)<\/p>/g, 'i')
    const questionName = _get(
      JSON.parse(title).content.match(regex),
      `[1]`,
      JSON.parse(title).content
    ).replace(/<.*?>/g, '')
    const isCourseSetting = /\/schools\/:schoolId\/curriculum\/manage.*/.test(path)
    const titleModalEn =
      choices?.length > 0 || answers?.length > 0
        ? `This question's choices and answers will be delete. Are you sure you want to change the type?`
        : type === 'pointOnImage' && (points?.length > 0 || image)
        ? `This question's choices and answers will be delete. Are you sure you want to change the type?`
        : 'Are you sure you want to change the type?'
    const titleModalTh =
      choices?.length > 0 || answers?.length > 0
        ? 'ตัวเลือกและคำตอบจะถูกลบ แน่ใจไหมว่าจะเปลี่ยนประเภทคำถาม?'
        : type === 'pointOnImage' && (points?.length > 0 || image)
        ? 'รูปภาพและคำตอบจะถูก แน่ใจไหมว่าจะเปลี่ยนประเภทคำถาม?'
        : 'คุณแน่ใจไหมว่าจะเปลี่ยนประเภทคำถาม?'

    return (
      <ErrorBoundary>
        <Fragment>
          <Formik initialValues={{ correctScore }} enableReinitialize>
            {() => (
              <Wrapper>
                <Form>
                  <QuestionCard
                    onClick={() => handleSelect(questionId)}
                    selected={isSelected(questionId)}
                    clickable
                    column="1"
                    bgColor="#fff"
                    borderRadius="4px"
                    padding="0px"
                    margin="10px 0px"
                  >
                    <Title gap="8px">
                      <div>
                        <Circle tag={questionTag} onClick={this.handleDropdown} />
                      </div>
                      <CardTitle>
                        Q{`${index + 1}.`.padEnd(3)}
                        {questionName}
                      </CardTitle>
                    </Title>
                    <IconSection>
                      <img
                        src={_get(
                          options.find((item) => item.value === type),
                          'selectedIcon',
                          ''
                        )}
                        alt={_get(
                          options.find((item) => item.value === type),
                          'selectedIcon',
                          ''
                        )}
                      />

                      <Icon onClick={this.handleRemove}>
                        <img src={DeleteIcon} alt="remove-icon" width="23px" height="23px" />
                      </Icon>
                      <DragHandle {...dragHandle}>
                        <img src={DragIcon} alt="drag-handle-icon" />
                      </DragHandle>
                    </IconSection>
                  </QuestionCard>
                  {isSelected(questionId) && (
                    <ContainerQuestions
                      customStyle={customStyle}
                      selected={isSelected(questionId)}
                      isCourseSetting={isCourseSetting}
                      column="1"
                      gap="15px"
                      padding="40px 50px 20px 25px"
                    >
                      <Card
                        padding="40px 32px"
                        style={{
                          border: '1px solid #253e87',
                          borderRadius: '4px',
                          marginBottom: '40px',
                        }}
                      >
                        <Icon
                          onClick={() => handleSelect('')}
                          style={{
                            width: 25,
                            height: 25,
                            position: 'absolute',
                            right: 65,
                            top: 60,
                            zIndex: 5,
                          }}
                        >
                          <img src={closeIcon} alt="arrow-icon" width="25px" height="25px" />
                        </Icon>
                        <Content>
                          <IconArrow
                            onClick={() => handleSelect('')}
                            src={ArrowIcon}
                            rotate="180deg"
                            alt="arrow-icon"
                            style={{ margin: '0 5px 0 0' }}
                          />
                          <Typography
                            id="label.question.number"
                            values={{ number: index + 1 }}
                            h4
                            bold
                          >
                            Question {index + 1}
                          </Typography>
                          <Circle tag={questionTag} />
                        </Content>
                        <ContainerHeader>
                          <Typography id="label.type" margin="0 0 8px" color="primary1">
                            Type
                          </Typography>
                          <div style={{ width: '100%' }}>
                            <SelectWithIcon
                              options={options}
                              value={selected}
                              onChange={this.handleTypeChange}
                            />
                          </div>
                        </ContainerHeader>
                        <Component
                          {...this.props}
                          inputChange={this.inputChange}
                          handleDeleteChoice={this.handleDeleteChoice}
                          handleDeleteAnswer={this.handleDeleteAnswer}
                          handleAddChoice={this.handleAddChoice}
                          handleAddAnswer={this.handleAddAnswer}
                          type={this.state.type}
                          debounceInput={this.debounceInput}
                          value={this.state.value}
                          choices={choices}
                          answers={answers}
                          points={points}
                          image={image}
                          ImageApi={ImageApi}
                          setTitle={this.setTitle}
                          setChoices={this.setChoices}
                          setAnswers={this.setAnswers}
                          setPoints={this.setPoints}
                          setImage={this.setImage}
                        />
                        <hr
                          style={{
                            color: '#cbd5e1',
                            backgroundColor: '#cbd5e1',
                            marginTop: '24px',
                          }}
                        />
                        <div
                          style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}
                        >
                          <Button
                            id="button.save"
                            type="button"
                            primary
                            width={171}
                            center
                            onClick={this.handleSave}
                          >
                            Save
                          </Button>
                        </div>
                      </Card>
                    </ContainerQuestions>
                  )}
                </Form>
                {isDropdown && (
                  <Dropdown
                    onOutsite={() => this.setState({ isDropdown: false })}
                    options={optionsDropDown}
                    handleChange={this.handleTagChange}
                    searchable={false}
                    top={46}
                    left={23}
                  />
                )}
              </Wrapper>
            )}
          </Formik>
          <ConfirmModal
            isOpen={isModalType}
            onRequestClose={() => this.setState({ isModalType: false })}
            title={locale === 'en' ? titleModalEn : titleModalTh}
            btn1={
              <Button id="button.confirmModal" onClick={this.handleType} primary>
                Submit
              </Button>
            }
            btn2={
              <Button
                id="button.cancel"
                secondary
                onClick={() => this.setState({ isModalType: false })}
              >
                Cancel
              </Button>
            }
          />
        </Fragment>
      </ErrorBoundary>
    )
  }
}

const mapState = ({ AssessmentQuestion: { worksheetQuestions }, Language: { locale } }) => ({
  worksheetQuestions,
  locale,
})
const mapDispatch = (dispatch) => ({
  deleteQuestion: dispatch.AssessmentQuestion.deleteQuestion,
  updateQuestion: dispatch.AssessmentQuestion.updateQuestion,
  openConfirm: dispatch.Confirm.open,
  closeConfirm: dispatch.Confirm.close,
  Alert: dispatch.Alert,
})

export default connect(mapState, mapDispatch)(withRouter(SideQuestion))
