import React from 'react'
import { Typography } from 'components'
// import { Content, Row, TextLink } from './styled'
import { Content, Row } from './styled'

const FreeCourse = (props) => {
  const { pricing, schoolPackage, freeCourseCount } = props
  const isReachLimit = freeCourseCount >= schoolPackage?.courseNumber
  return (
    <Content>
      <Row>
        <div style={{ paddingTop: 10 }}>
          {/* <TextLabel id="course.tuitionfee.free">Free</TextLabel> */}
          {/* <Description id="course.tuitionfee.free.desc">
            Set a tuition fee for this course
          </Description> */}
          {pricing === 'FREE_COURSE' && (
            <>
              {isReachLimit && (
                <span style={{ display: 'flex' }}>
                  <Typography
                    id="course.tuitionfee.freelimit"
                    error={isReachLimit}
                    style={{ marginRight: '5px' }}
                    values={{ courseNumber: schoolPackage?.courseNumber }}
                  >
                    *You&apos;ve reached your free course limit!
                    {/* {schoolPackage?.name === 'partner' && <u>Upgrade your school package.</u>} */}
                  </Typography>
                  {/* {schoolPackage?.name === 'free' && (
                    <Typography h6 onClick={() => {}}>
                      upgrade your school package.
                    </Typography>
                  )}
                  {schoolPackage?.name === 'premium' && (
                    <Typography>To create this course, please change your tuition fee.</Typography>
                  )} */}
                </span>
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '11px' }}>
                <Typography
                  id="course.tuitionfee.freeCourse"
                  values={{ freeCourseCount, courseNumber: schoolPackage?.courseNumber }}
                  color="primary1"
                  fontSize="text-sm"
                >
                  `${freeCourseCount} / ${schoolPackage?.courseNumber}`
                </Typography>
                <Typography
                  id="course.tuitionfee.freeCourse.desc"
                  color="primary1"
                  fontSize="text-sm"
                  values={{ courseNumber: schoolPackage?.courseNumber }}
                >
                  You can create a maximum of - free courses
                </Typography>
              </div>
            </>
          )}
        </div>
      </Row>
    </Content>
  )
}

export default FreeCourse
