/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Typography } from 'components'
import IconDarkArrowDown from '../../../../images/icons/DarkIcon/Dark-arrowDown.svg'
import LectureItem from './LectureItem'
import useCollapse from 'react-collapsed'
const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 0px;
`
const ArrowIcon = styled.img`
  height: 14px;
  width: 14px;
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  ${({ isOpen }) => {
    if (isOpen) {
      return css`
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      `
    }
  }}
`

function LectureNavigation({ item, match }) {
  const [isOpen, setOpen] = useState(true)
  const { getCollapseProps, getToggleProps } = useCollapse({ isOpen })
  useEffect(() => {
    if (item?.contents?.find((lecture) => lecture.id === match.params.lectureId)) {
      setOpen(true)
    }
  }, [item?.contents, match.params.lectureId])
  return (
    <div
      key={item?.id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '8px',
        margin: '0px 0px 16px 0px',
      }}
    >
      <Button
        type="button"
        {...getToggleProps({
          onClick: () => setOpen((oldOpen) => !oldOpen),
        })}
      >
        <Typography color="dark3" left>
          {item?.name}
        </Typography>
        <ArrowIcon src={IconDarkArrowDown} alt="arrow-ic" isOpen={isOpen} />
      </Button>
      <section {...getCollapseProps()}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '12px',
          }}
        >
          {item?.contents?.map((content) => {
            return <LectureItem {...content} key={content?.id} />
          })}
        </div>
      </section>
    </div>
  )
}

export default withRouter(LectureNavigation)
