/* eslint-disable no-unused-vars */
import { Tooltip, Typography } from 'components'
import ClasswinEditor from '../classwinEditor'
import ClasswinEditorInput from '../classwinEditor/DiscussionFeed'
import React, { Fragment, useState } from 'react'
import getImageUrl from 'utils/getImageUrl'
import moment from 'moment'
import {
  CommentReply,
  ContainerComment,
  CommentReadOnly,
  LikeContainer,
  Like,
  ReplyContainer,
  Reply,
  ReplyText,
  ContainerCommentLevelOne,
  CommentFlex,
  PenIcon,
  ImageSize,
  // WrapImage,
} from './styles'
import MoreIcon from '../../../../../images/icons/MoreIcon.svg'
import LikeIcon from '../../../../../images/icons/LikeIcon-primary.svg'
import LikedIcon from '../../../../../images/icons/LikedIcon.svg'
import ReplyIcon from '../../../../../images/icons/ReplyIcon.svg'
import Pen from '../../../../../images/icons/PenIcon.svg'
import TooltipOption from './TooltipOption'
//import CommentItemLevelOne from './CommentItemLevelOne'
import DrawingModal from './DrawingModal'
import { Lightbox } from 'react-modal-image'
import theme from 'theme'
import styled from 'styled-components'
import WhiteLikeIcon from '../../../../../images/icons/DarkIcon/White-LikeIcon.svg'
import WhiteLikeIconFocus from '../../../../../images/icons/DarkIcon/White-LikeIconFocus.svg'
import CommentItemLevelOne from './CommentItemLevelOne'
import DarkCommentIcon from '../../../../../images/icons/DarkIcon/Dark-commentIcon.svg'
import WhiteCommentIcon from '../../../../../images/icons/DarkIcon/White-commentIcon.svg'
const CommentLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 0px 10px 40px;
  @media (max-width: 820px) {
  }
  @media (max-width: 425px) {
  }
`
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 0px 0px 8px;
  width: 95%;

  padding: 0px 0px 0px 0px;
  @media (max-width: 820px) {
  }
  @media (max-width: 425px) {
  }
`
const Desc = styled(Typography)`
  align-items: center;
  font-size: 12px;
  @media (max-width: 820px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 426px) {
    font-size: 12px;
  }
`
export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`
export const CommentItem = ({
  appUser,
  richMessage,
  isMe,
  isMyLike,
  countLikes,
  onLike,
  onSubmit,
  handleDel,
  id,
  course,
  Confirm,
  locale,
  updateFeed,
  setEditComment,
  comments,
  courseId,
  media,
  showReplyId,
  setReplyId,
  myProfileImage,
  setCommentId,
  commentTime,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [isReplyDrawing, setIsReplyDrawing] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const [textInput, setTextInput] = useState(richMessage)
  const [openModalImageId, setOpenModalImageId] = useState(null)
  const [drawingModalId, setDrawingModalId] = useState(null)
  const [drawingToggle, setDrawingToggle] = useState(false)
  const currentMessage = richMessage
  const onClickReply = (id) => {
    setCommentId('')
    setReplyId(id)
  }
  const handleEdit = async (richMessage) => {
    const payload = {
      appUser,
      isMe,
      isMyLike,
      countLikes,
      id,
      course,
      richMessage,
    }
    if (currentMessage === richMessage) {
      setIsEdit(false)
      return null
    }
    await Confirm.open({
      onConfirm: async () => {
        await updateFeed(payload)
        Confirm.close()
      },
      title: locale === 'en' ? 'Confirm edit' : 'ยืนยันการแก้ไข',
      subtitle:
        locale === 'en'
          ? 'Do you want to edit your comment?'
          : 'คุณต้องการแก้ไขข้อความแสดงความคิดเห็นใช่หรือไม่',
      onCancel: () => Confirm.close(),
    })
    setEditComment({ richMessage, id })
    setIsEdit(false)
  }
  const createdCommentTimes = moment(commentTime)
    .locale(locale === 'en' ? 'en' : 'th')
    .fromNow()
  // const createdTimes = moment(comments[0].createdAt)
  // console.log(appUser)
  // console.log(createdAt)

  return (
    <Fragment>
      <Layout>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            margin: '0px 0px 12px 0px',
          }}
        >
          <Avatar src={getImageUrl(appUser?.profileImage)} alt={'user'} />
          <div style={{ display: 'flex' }}>
            {/* <Typography style={{ color: theme.dark2, marginLeft: 0 }} bold>
                    {appUser?.firstname} {appUser?.lastname}
                  </Typography>
                  <Typography style={{ color: 'white', marginLeft: 5 }}>•</Typography>
                  <Typography style={{ color: theme.dark2, marginLeft: 0 }}>
                    {createdCommentTimes}
                  </Typography> */}
            <Desc color="dark2" bold>
              {`${appUser?.firstname} ${appUser?.lastname}`}
            </Desc>
            <Desc margin={'0px 5px 0px 5px'} color="dark2">
              •
            </Desc>
            <Desc margin={'0px'} color="dark2">
              {createdCommentTimes}
            </Desc>
            {/* {isMe && (
                    <Tooltip
                      className="post-menu"
                      placement="bottom"
                      trigger="click"
                      tooltipShown={isShown}
                      onVisibilityChange={() => setIsShown((prev) => !prev)}
                      style={{
                        marginLeft: 6,
                        opacity: showTooltip ? 1 : 0,
                        transition: '0.2s',
                        width: 30,
                      }}
                      tooltip={
                        <TooltipOption
                          handleDelete={() => {
                            setIsShown(false)
                            Confirm.open({
                              onCancel: () => Confirm.close(),
                              confirmText: locale === 'en' ? 'Confirm' : 'ยืนยัน',
                              cancelText: locale === 'en' ? 'Cancel' : 'ยกเลิก',
                              onConfirm: () => {
                                handleDel({ feedId: id, courseId: course && course.id }, 2)
                                Confirm.close()
                              },
                              title: locale === 'en' ? 'Confirm Delete' : 'ยืนยันการลบ',
                              subtitle:
                                locale === 'en'
                                  ? 'Do you want to delete your comment?'
                                  : 'คุณต้องการลบข้อความแสดงความคิดเห็นใช่หรือไม่',
                            })
                          }}
                          handleEdit={() => setIsEdit(true)}
                        />
                      }
                    >
                      <img
                        className="icon-more"
                        src={MoreIcon}
                        style={{ width: '16px', height: '20px' }}
                        alt="more-icon"
                      />
                    </Tooltip>
                  )} */}
          </div>
        </div>
        <CommentReply>
          <ContainerComment
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {/* {isEdit ? (
              <>
                <ClasswinEditor
                  value={textInput}
                  onChange={(value) => {
                    setTextInput(value)
                  }}
                  labelFontSize="text-base"
                  fontSize="text-sm"
                  noBorderRadius
                  autoHeight
                  showToolbar={false}
                  padding={0}
                  noPadding
                  backgroundFeed
                  isMainFeed
                  isComment
                  onBlur={() => setIsEdit(false)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.shiftKey === false && e.ctrlKey === false) {
                      const obj = JSON.parse(textInput)
                      if (obj.delta.ops.slice(-1)?.[0]?.insert) {
                        obj.delta.ops.slice(-1)[0].insert = obj.delta.ops
                          .slice(-1)?.[0]
                          ?.insert?.replaceAll(/\n+/g, '\n')
                      }
                      obj.content = obj.content.replaceAll('<p><br></p>', '')
                      const richMessage = JSON.stringify(obj)
                      if (!handleEdit) {
                        setTextInput(null)
                      }
                      handleEdit?.(richMessage)
                    }
                  }}
                />
              </>
            ) : ( */}
            <CommentFlex>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#5F5F61',
                  borderRadius: '8px',
                  padding: '0px 0px 0px 0px',
                  //width: '80%',
                }}
              >
                <CommentReadOnly txtlength={richMessage?.length}>
                  <ClasswinEditor
                    fontSize="text-sm"
                    isShowAll={false}
                    editorMaxHeight={50}
                    value={richMessage}
                    labelFontSize="text-base"
                    readOnly
                    autoHeight
                    isComment
                    padding="12px"
                    bulletPadding={12}
                    min={4}
                  />
                  {/* <ClasswinEditor
                      fontSize="text-sm"
                      //isShowAll={false}
                      style={{ marginLeft: 2 }}
                      //editorMaxHeight={3}
                      value={richMessage}
                      labelFontSize="text-base"
                      readOnly
                      noPadding
                      noBorderRadius
                      autoHeight
                      padding={0}
                      labelColor="white"
                      showAll={false}
                      //min={2}
                    /> */}
                </CommentReadOnly>
                {media && (
                  <div
                    style={{
                      margin: '8px 0px 0px 30px',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* <WrapImage></WrapImage> */}
                    <ImageSize
                      style={{ maxWidth: '80%' }}
                      src={getImageUrl(
                        media?.map((item) => {
                          return item.name
                        })
                      )}
                      alt=""
                      onClick={() => {
                        setOpenModalImageId(id)
                      }}
                    />
                    {openModalImageId === id && media && (
                      <Lightbox
                        // small={getImageUrl(
                        //   media?.map((item) => {
                        //     return item.name
                        //   })
                        // )}
                        large={getImageUrl(
                          media?.map((item) => {
                            return item.name
                          })
                        )}
                        // meta={() => {
                        //   return (
                        //     <PenIcon
                        //       src={Pen}
                        //       onClick={() => {
                        //         setOpenModalImageId(null)
                        //         setDrawingModalId(id)
                        //         setDrawingToggle(true)
                        //         setIsReplyDrawing(true)
                        //       }}
                        //     />
                        //   )
                        // }}
                        onClose={() => setOpenModalImageId(null)}
                        hideDownload
                      />
                    )}
                    <DrawingModal
                      isOpen={drawingModalId === id}
                      onClose={() => setDrawingModalId(null)}
                      image={media?.map((item) => {
                        return item.name
                      })}
                      drawingToggle={drawingToggle}
                      feedId={id}
                      courseId={courseId}
                      isReply={isReplyDrawing}
                    />
                  </div>
                )}
              </div>
            </CommentFlex>
            {/* )} */}
          </ContainerComment>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 8, marginLeft: 40 }}>
            <LikeContainer>
              {isMyLike ? (
                <Like src={WhiteLikeIconFocus} alt="Liked" onClick={() => onLike(id, 2)} />
              ) : (
                <Like src={WhiteLikeIcon} alt="Like" onClick={() => onLike(id, 2)} />
              )}
              <Typography margin="0 0 0 5px" color="white" strong lineHeight={1}>
                {`${countLikes}`}
              </Typography>
            </LikeContainer>
            <ReplyContainer
              onClick={() => {
                setReplyId(id)
                setTextInput(null)
                setIsEdit(false)
                onClickReply(id)
              }}
            >
              {showReplyId === id ? (
                <Reply src={WhiteCommentIcon} alt="Reply" style={{ marginLeft: '20px' }} />
              ) : (
                <Reply src={DarkCommentIcon} alt="Reply" style={{ marginLeft: '20px' }} />
              )}
              <ReplyText id="label.reply" h6 color="white" bold margin="0 0 0 8px">
                Reply
              </ReplyText>
            </ReplyContainer>
          </div>
        </CommentReply>
      </Layout>
      <ContainerCommentLevelOne>
        {comments?.map((comment, idx) => {
          return (
            <CommentItemLevelOne
              key={idx}
              {...comment}
              isMe={isMe}
              course={course}
              onLike={onLike}
              handleDel={handleDel}
              Confirm={Confirm}
              updateFeed={updateFeed}
              locale={locale}
            />
          )
        })}
      </ContainerCommentLevelOne>
      {showReplyId === id && (
        <CommentLayout>
          <Avatar src={getImageUrl(myProfileImage)} alt={'user'} />

          <ClasswinEditorInput
            placeholder={locale === 'en' ? 'Write comment...' : 'เขียนความคิดเห็นของคุณ...'}
            value={textInput}
            onChange={(value) => {
              setTextInput(value)
            }}
            showToolbar={false}
            noBorderRadius
            autoHeight
            isMainFeed
            isComment
            fontSize="text-sm"
            padding={0}
            color="#AAADB9"
            style={{
              width: '100%',
              color: '#fff',
              //caretColor: '#AAADB9',
              paddingRight: '12px',
              paddingLeft: '12px',
            }}
            backgroundFeed
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey === false && e.ctrlKey === false) {
                const obj = JSON.parse(textInput)
                if (obj.delta.ops.slice(-1)?.[0]?.insert) {
                  obj.delta.ops.slice(-1)[0].insert = obj.delta.ops
                    .slice(-1)?.[0]
                    ?.insert?.replaceAll(/\n+/g, '\n')
                }
                obj.content = obj.content.replaceAll('<p><br></p>', '')
                const richMessage = JSON.stringify(obj)
                onSubmit?.(
                  { courseId: courseId ? courseId : null, feedId: id, richMessage: richMessage },
                  true
                )
                setTextInput(null)
              }
            }}
          />
        </CommentLayout>
      )}
    </Fragment>
  )
}
