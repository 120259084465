import { SubjectAPI, WorksheetAPI } from '../../api'

export const Assessment = {
  state: {
    assessmentList: [],
    assessmentDetail: {},
    lectureTest: {},
  },
  reducers: {
    setAssessmentList(state, payload) {
      return {
        ...state,
        assessmentList: payload,
      }
    },
    setAssessmentDetail(state, payload) {
      return {
        ...state,
        assessmentDetail: {
          ...state.assessmentDetail,
          [payload.id]: payload,
        },
      }
    },
    setLectureTest(state, payload) {
      return {
        ...state,
        lectureTest: {
          ...state.lectureTest,
          [payload.id]: payload,
        },
      }
    },
    unsetLectureTest(state, payload) {
      const lectureTest = {
        ...state.lectureTest,
      }
      delete lectureTest[payload]
      return {
        ...state,
        lectureTest,
      }
    },
  },
  effects: (dispatch) => ({
    async getAssessmentList(subjectId) {
      const result = await SubjectAPI.getWorksheetList({ subjectId })
      this.setAssessmentList(result)
      return result

      // const assessmentFilter = JSON.stringify({
      //   include: 'publishedSubject',
      // })
      // const result = await SubjectAPI.getWorksheetList({ subjectId, filter: assessmentFilter })
      // console.log('assessment getAssessmentList', result)
      // const ofSubjects = result.publishedSubject.data.subjectWorksheets.map((item) => ({
      //   ...item.publishedWorksheet.data,
      //   publishedWorksheetId: item.publishedWorksheet.id,
      // }))
      // const assessmentList = [...ofSubjects]
      // this.setAssessmentList(assessmentList)
      // return result
    },
    async getAssessmentDetail({ assessmentId }) {
      // const result = await SubjectAPI.getWorksheetDetail({ subjectId, worksheetId: assessmentId })
      const result = await WorksheetAPI.findById({ id: assessmentId })
      this.setAssessmentDetail(result)
      return result
    },
    async upsertAssessment({ hideAlert, subjectId, name, description, assessmentId, type }) {
      if (assessmentId) {
        return this.updateAssessment({
          hideAlert,
          subjectId,
          name,
          description,
          type,
          id: assessmentId,
        })
      }

      // console.log('create worksheet🎸', {subjectId,
      //   name,
      //   description,
      //   type,})
      const result = await WorksheetAPI.create({
        subjectId,
        name,
        description,
        type,
      })
      return result
    },
    async updateAssessment({ hideAlert, ...data }) {
      try {
        const result = await WorksheetAPI.update(data)
        if (!hideAlert) {
          dispatch.Alert.success({ title: 'Assessment Updated Successfully' })
        }
        await this.getAssessmentDetail({ subjectId: data.subjectId, assessmentId: data.id })
        return result
      } catch (e) {
        dispatch.Alert.error({ title: 'Assessment Update Failed' })
        throw e
      }
    },
    async upsertLectureTest({ subjectId, lectureId, rank, type, ...payload }, state) {
      const { lectures } = state.Subject
      const lecture = lectures.find((item) => item.id === lectureId)
      const content = lectures.find(
        (item) => item.priority > lecture.priority && item.rank === +rank
      )
      const contentId = content.id
      const data = {
        lectureId: contentId,
        type,
        ...payload,
        where: JSON.stringify({ lectureId: contentId, type }),
        allowRetest: false,
        isShowScore: true,
        required: true,
        showAnswer: false,
        timeoutHour: 0,
        timeoutMinute: 0,
      }
      try {
        const result = await WorksheetAPI.upsertWithWhere(data)
        await this.getLectureTests({ subjectId, lectureId })
        dispatch.Alert.success({ title: 'Lecture Updated Successfully' })
        return result
      } catch (e) {
        dispatch.Alert.error({ title: 'Lecture Update Failed' })
      }
      return null
    },
    async getLectureTests({ subjectId, lectureId }) {
      const result = await SubjectAPI.getLectureTests({
        id: subjectId,
        lectureId,
      })
      result.forEach((item) => {
        this.setLectureTest(item)
      })
      return result
    },
    async deleteAssessment({ subjectId, assessmentId }) {
      const result = await WorksheetAPI.delete({ id: assessmentId })
      this.getAssessmentList(subjectId)
      return result
    },
  }),
  selectors: () => ({
    lectureTest() {
      return (rootState, props) => {
        const { lectures } = rootState.Subject
        const { lectureTest } = rootState.Assessment
        const lecture = lectures.find((item) => item.id === props.lectureId)
        const content = lectures.find(
          (item) => item.priority > lecture.priority && item.rank === +props.rank
        )
        const contentId = content.id

        const test = Object.values(lectureTest).find(
          (item) => item.lectureId === contentId && item.type === props.testType
        )
        return test
      }
    },
  }),
}
