import React from 'react'
import styled from 'styled-components'
import closeIcon from '../../images/ic-cancelbtn-gray-24-px.svg'

const FakeModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`
const FakeContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  border-radius: 10px;
`

const CloseButton = styled.img`
  position: absolute;
  top: 15px;
  right: 20px;
  width: 25px;
  height: 25px;
  z-index: 410;
  cursor: pointer;
`

const Modal = ({ isOpen, children, shownXclose, onClickClose, closeStyle }) => {
  if (isOpen) {
    return (
      <FakeModal>
        <FakeContent>
          {shownXclose && <CloseButton src={closeIcon} onClick={onClickClose} style={closeStyle} />}
          {children}
        </FakeContent>
      </FakeModal>
    )
  }
  return null
}

export default Modal
