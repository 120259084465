import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player/lazy'
import { canPlay } from 'react-player/lib/patterns'
import ReactLoading from 'react-loading'
import Typography from 'components/typography'
import ArrowIconPrev from '../../images/icons/ic-prev.png'
import ArrowIconNext from '../../images/icons/ic-next.png'
import ErrorBoundary from '../errorBoundary'
import theme from 'theme'

const TimeDisplay = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;
  color: ${theme.black10};
`
const Container = styled.div``
const ArrowContainer = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  position: absolute;
  top: 45%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;

  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  ${({ showArrow }) => {
    if (showArrow) {
      return css`
visibility: visible;
opacity: 1;

transition - delay: 0 s;
`
    }
  }}
`
const IconContainer = styled.div``
const IconArrow = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  ${({ isFirst, isLast }) => {
    if (isFirst || isLast) {
      return css`
        display: none;
      `
    }
    return css``
  }}
  :hover {
    opacity: 1;
    transition: 0.5s;
    background-color: ${theme.dark1};
  }
`

export const validateVideoUrl = (url) => {
  return Object.keys(canPlay).reduce((prev, key) => {
    const canPlayFn = canPlay[key]
    return prev || canPlayFn(url)
  }, false)
}
class MediaPlayer extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  state = {
    // start: false,
    // play: false,
    // pause: false,
    // ended: false,
    showArrow: false,
    duration: 0,
    error: false,
  }

  getWrappedInstance = () => this.player

  secondsToMinute = (sec) => {
    const padNum = (num) => (num / 100).toFixed(2).slice(2)
    const minute = Math.floor(sec / 60)
    const restSec = Math.floor(sec % 60)
    return `${padNum(minute)}:${padNum(restSec)}`
  }

  onError = (e) => {
    const errorString = JSON.stringify(e)
    const ignoreCase = /Seconds must be a positive number less than the duration/.test(errorString)
    if (!ignoreCase) {
      this.setState({
        error: true,
      })
    }
  }
  onStartPlayer = () => {
    // const { onStart } = this.props
    console.log('start')
    this.setState({
      showArrow: true,
    })
    // onStart?.()
    setTimeout(() => {
      this.setState({
        showArrow: false,
      })
    }, 5000)
  }

  onPlay = () => {
    this.setState({
      showArrow: true,
    })
    // onStart?.()
    // const next = document.getElementById('icon-container')
    // next.classList.add('fade-in')

    setTimeout(() => {
      this.setState({
        showArrow: false,
      })
    }, 5000)
  }
  onPause = () => {
    this.setState({
      showArrow: true,
    })
    // onStart?.()
  }

  render() {
    const {
      url,
      className,
      width,
      height,
      style,
      isShowVideoWidth,
      children,
      // onStart,
      isLast,
      isFirst,
      // onPlay,
      // onPause,
      onNext,
      isShowArrow = false,
      onPrev,
      lectureId,
      light = false,
      controls = true,
      autoPlay,

      ...props
    } = this.props
    const { error, showArrow } = this.state

    if (error) {
      return (
        <div
          style={{
            ...style,
            width,
            height: 340,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type="spinningBubbles" color="white" />
          <Typography
            id="mediaPlayer.processing"
            color="white"
            style={{
              marginTop: 5,
            }}
          >
            The video is processing{' '}
          </Typography>{' '}
        </div>
      )
    }

    return (
      <ErrorBoundary>
        <Container
          style={{
            position: 'relative',
            ...style,
          }}
        >
          {' '}
          {isShowArrow ? (
            <Fragment>
              <ArrowContainer showArrow={showArrow}>
                <IconContainer className="fade-in">
                  <IconArrow
                    isFirst={isFirst}
                    src={ArrowIconPrev}
                    id="prev"
                    alt="ic-prev"
                    onClick={() => {
                      // e.stopPropagation()
                      onPrev(lectureId)
                    }}
                  />{' '}
                </IconContainer>{' '}
                <IconContainer>
                  <IconArrow
                    isLast={isLast}
                    src={ArrowIconNext}
                    id="next"
                    alt="ic-next"
                    onClick={() => {
                      // e.stopPropagation()
                      onNext(lectureId)
                    }}
                  />{' '}
                </IconContainer>{' '}
              </ArrowContainer>{' '}
            </Fragment>
          ) : (
            <Fragment> </Fragment>
          )}{' '}
          <ReactPlayer
            className={className}
            ref={(el) => (this.props.playerRef = el)}
            url={url}
            width={width}
            height={height}
            onPlay={this.onPlay}
            onPause={this.onPause}
            onStart={this.onStartPlayer}
            playing={autoPlay}
            light={light}
            controls={controls} // eslint-disable-line
            onDuration={(sec) =>
              this.setState({
                duration: sec,
              })
            }
            onError={this.onError}
            progressInterval={1000}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 1,
                },
              },
            }}
            {...props}
          />{' '}
          {isShowVideoWidth && (
            <TimeDisplay> {`${this.secondsToMinute(this.state.duration)} mins`} </TimeDisplay>
          )}{' '}
          {typeof children === 'function' && children(this.secondsToMinute(this.state.duration))}{' '}
        </Container>{' '}
      </ErrorBoundary>
    )
  }
}

MediaPlayer.defaultProps = {
  width: '100%',
  isShowVideoWidth: true,
  playerRef: () => {},
  autoPlay: false,
}

MediaPlayer.propTypes = {
  url: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  isShowVideoWidth: PropTypes.bool,
  playerRef: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.func,
  className: PropTypes.string,
  autoPlay: PropTypes.bool,
}

export default MediaPlayer
