import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { ACCESS_TOKEN } from '../../constants'

function PrivateRoute({
  component: Component,
  user,
  permissions,
  currentUserRole,
  getPermissions,
  extendedProps = {},
  ...rest
}) {
  const isAuthenticated = localStorage.getItem(ACCESS_TOKEN)
  const isTeacherPath = /^\/teacher/.test(rest.path)
  const isSchoolPath = /^\/schools/.test(rest.path)
  const isSchoolPublicCoursePath = /^\/schools\/public/.test(rest.path)
  const isSchoolPrivateCoursePath = /^\/schools\/private/.test(rest.path)
  const isCheckoutPackagePath = /^\/schools\/:schoolId\/checkout\/package\/:packageId/.test(
    rest.path
  )

  const { schoolId } = rest.computedMatch.params
  useEffect(() => {
    if (isSchoolPath) {
      getPermissions({ schoolId })
    }
  }, [schoolId, isSchoolPath, getPermissions])

  // use for schools only
  const isPermit = () => {
    if (!permissions) {
      return true
    }
    if (!currentUserRole) {
      return true
    }
    if (/dashboard/.test(rest.path)) {
      return permissions.dashboard
    }
    if (/curriculum/.test(rest.path)) {
      if (/school-courses/.test(rest.path)) {
        return permissions.createAndViewCourse || permissions.viewCourse
      }
      if (/setting/.test(rest.path)) {
        return permissions.editCourse
      }
      return permissions.course
    }
    if (/member/.test(rest.path)) {
      if (/permission/.test(rest.path)) {
        return currentUserRole === 'owner'
      }
      return permissions.member
    }
    if (/setting/.test(rest.path)) {
      return permissions.member
    }
    if (/payment/.test(rest.path)) {
      return permissions.payment
    }
    if (/certificate/.test(rest.path)) {
      if (/template/.test(rest.path)) {
        return permissions.createEditCertificate
      }
      return permissions.certificate
    }
    if (/report/.test(rest.path)) {
      return permissions.report
    }
    if (/profile/.test(rest.path)) {
      return permissions.information
    }
    if (/packages/.test(rest.path)) {
      return permissions.packages
    }
    return true
  }
  const firstPermit = () => {
    const { schoolId } = rest.computedMatch.params
    const menuPermissions = {
      dashboard: permissions?.dashboard,
      course: permissions?.course,
      member: permissions?.member,
      payment: permissions?.payment,
      certificate: permissions?.certificate,
      report: permissions?.report,
      information: permissions?.information,
      packages: permissions?.packages,
    }
    const permit = Object.keys(menuPermissions).find((key) => menuPermissions?.[key] === true)
    switch (permit) {
      case 'dashboard':
        return `/schools/${schoolId}/dashboard/overview`
      case 'course':
        return `/schools/${schoolId}/curriculum`
      case 'member':
        return `/schools/${schoolId}/member/teacher`
      case 'payment':
        return `/schools/${schoolId}/payment/overview`
      case 'certificate':
        return `/schools/${schoolId}/certificate/program-certificate`
      case 'report':
        return `/schools/${schoolId}/report`
      case 'information':
        return `/schools/${schoolId}/profile`
      case 'packages':
        return `/schools/${schoolId}/packages/overview`
      default:
        return '/user/login'
    }
  }

  let canAccess = true
  if (isTeacherPath) {
    const myRoles = get(user, 'roles', []).map((item) => item.name)
    const isTeacher = myRoles.includes('teacher')
    canAccess = isTeacher
  }
  if (isSchoolPath) {
    const mySchools = get(user, 'schoolMembers', []).map((item) => item.schoolId)
    const { schoolId } = rest.computedMatch.params
    const accessSchool = mySchools.includes(schoolId)
    canAccess = accessSchool && isPermit()
  }
  if (isSchoolPublicCoursePath || isSchoolPrivateCoursePath || isCheckoutPackagePath) {
    const { schoolId } = rest.computedMatch.params

    const schoolMember = get(user, 'schoolMembers', []).find((item) => item?.schoolId === schoolId)

    canAccess = Boolean(schoolMember?.role === 'owner')
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated && canAccess ? (
          <Component {...props} {...extendedProps} />
        ) : isSchoolPath ? (
          <Redirect
            to={{
              pathname: firstPermit(),
              state: { from: props.location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

const mapState = (state) => {
  return {
    user: state.AppUser.appUser,
    permissions: state.Permissions.permissions,
    currentUserRole: state.Permissions.currentUserRole,
  }
}
const mapDispatch = (dispatch) => {
  return {
    getPermissions: dispatch.Permissions.getPermissions,
  }
}

export default connect(mapState, mapDispatch)(PrivateRoute)
