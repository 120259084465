import { DraftCourseAPI } from '../../api'

export const DraftCourse = {
  state: {
    draftInfo: {},
    draftSkill: [],
    draftCourseStatus: {},
    draftCategory: {},
  },
  reducers: {
    setDraftSkill(state, payload) {
      return {
        ...state,
        draftSkill: payload,
      }
    },
    setDraftInfo(state, payload) {
      return {
        ...state,
        draftInfo: payload,
      }
    },
    setDraftCourseStatus(state, payload) {
      return {
        ...state,
        draftCourseStatus: payload,
      }
    },
    setDraftCategory(state, payload) {
      return {
        ...state,
        draftCategory: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async getSkill(payload) {
      try {
        const result = await DraftCourseAPI.getSkill(payload)
        this.setDraftSkill(result)
        return result
      } catch (error) {
        throw error
      }
    },
    async deleteDraftCoursesSkill(payload) {
      try {
        await DraftCourseAPI.deleteDraftCoursesSkill(payload)
      } catch (error) {
        throw error
      }
    },
    async putDraftCoursesSkill(payload) {
      try {
        await DraftCourseAPI.putDraftCoursesSkill(payload)
      } catch (error) {
        throw error
      }
    },
    async publishCourse(id, state) {
      let locale = state.Language.locale
      try {
        const result = await DraftCourseAPI.publishCourse(id)
        await dispatch.Alert.success({
          title: locale === 'en' ? 'Course Published' : 'เผยแพร่คอร์สแล้ว',
        })
        return result
      } catch (error) {
        throw error
      }
    },
    async getDraft({ id }) {
      try {
        const filter = JSON.stringify({
          include: { school: 'schoolPackage' },
        })
        const res = await DraftCourseAPI.findById({ id, filter })
        this.setDraftInfo(res)
        return res
      } catch (error) {
        throw error
      }
    },
    async updateDraft({ id, notAlert, ...payload }, state) {
      let locale = state.Language.locale
      try {
        await DraftCourseAPI.updateDraftCourse({ id, ...payload })
        if (!notAlert)
          dispatch.Alert.success({
            title: locale === 'en' ? 'Updated Successful' : 'อัปเดตสำเร็จ',
          })

        this.getDraft({ id })
        this.getDraftCourseStatus({ id })
      } catch (error) {
        dispatch.Alert.error({ title: locale === 'en' ? 'Update Failed' : 'อัปเดตไม่สำเร็จ' })
        throw error
      }
    },
    async deleteDraft({ id }) {
      try {
        await DraftCourseAPI.delete({ id })
      } catch (error) {
        throw error
      }
    },
    async getDraftCourseStatus(id) {
      try {
        const result = await DraftCourseAPI.getDraftCourseStatus(id)
        this.setDraftCourseStatus(result)
      } catch (error) {
        throw error
      }
    },
    async getDraftCategory(id) {
      try {
        const result = await DraftCourseAPI.getCategory(id)
        this.setDraftCategory(result)
      } catch (error) {
        throw error
      }
    },
  }),
}
