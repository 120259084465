import React, { Fragment } from 'react'
import ErrorBoundary from '../errorBoundary'

import { Container } from './style'
import CardGreeting from '../cardGreeting'

const GreetingModal = ({ data, column, onClose }) => {
  return (
    <ErrorBoundary>
      <Fragment>
        <Container>
          <CardGreeting {...data} column={column} onClose={onClose} />
        </Container>
      </Fragment>
    </ErrorBoundary>
  )
}

export default GreetingModal
