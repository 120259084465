/* eslint-disable no-unused-vars */
// import AccountSettingIcon from 'images/Classwin - New icon_Account.svg'
// import LogoutIcon from 'images/Classwin - New icon_Log out.svg'
// import SwitchIcon from 'images/ic-switch-blue.png'
// import ProfileIcon from 'images/Classwin - New icon_Profile.svg'
// import ContactIcon from 'images/Classwin - New icon_Contact us.svg'
// import PurchaseHistoryIcon from 'images/Classwin - New icon_Enrollment.svg'
// import MyCourseIcon from 'images/Classwin_-_New_icon_My_course.svg'
// import SearchIcon from 'images/icon_Search.png'
// import CertificatesIcon from 'images/Classwin - New icon_Certificate.svg'
// import CourseReviewIcon from 'images/CourseReviewMenuIcon.svg'
// import SocialIcon from 'images/Social Icon_Website.svg'
// import TutorialIcon from 'images/Classwin_-_New_icon_Tutorial-09.png'
import SwitchInActive from 'images/icons/instructor-01.svg'
// import SearchInActive from 'images/icons/Classwin-SearchInActive.png'
// import SwitchActive from 'images/icons/Classwin-SwitchActive.png'
// import SearchActive from 'images/icons/Classwin-SearchActive.png'

// import StarInActive from 'images/icons/Classwin-StarInActive.png'
// import ProfileInActive from 'images/icons/Classwin-ProfileInActive.png'
// import CertificateInActive from 'images/icons/Classwin-CertificateInActive.png'
// import EnrollmentInActive from 'images/icons/Classwin-EnrollmentInActive.png'
// import SettingInActive from 'images/icons/Classwin-SettingInActive.png'
// import ContactUsInActive from 'images/icons/Classwin-ContactUsInActive.png'
// import MyCourseInActive from 'images/icons/Classwin-MyCourseInActive.png'
// import TutorialInActive from 'images/icons/Classwin-TutorialInActive.png'
// import LanguageInActive from 'images/icons/Classwin-LanguageInActive.png'
// import LogoutInActive from 'images/icons/Classwin-LogoutInActive.png'
// import ManageAcademyInActive from 'images/icons/Classwin-ManageAcademyInActive.png'
// import StarActive from 'images/icons/Classwin-StarActive.png'
// import ProfileActive from 'images/icons/Classwin-ProfileActive.png'
// import CertificateActive from 'images/icons/Classwin-CertificateActive.png'
// import EnrollmentActive from 'images/icons/Classwin-EnrollmentActive.png'
// import SettingActive from 'images/icons/Classwin-SettingActive.png'
// import ContactUsActive from 'images/icons/Classwin-ContactUsActive.png'
// import MyCourseActive from 'images/icons/Classwin-MyCourseActive.png'
// import TutorialActive from 'images/icons/Classwin-TutorialActive.png'
// import LanguageActive from 'images/icons/Classwin-LanguageActive.png'
// import LogoutActive from 'images/icons/Classwin-LogoutActive.png'
// import ManageAcademyActive from 'images/icons/Classwin-ManageAcademyActive.png'

import Mycourse from 'images/icons/icon-mycourse.svg'
import Browse from 'images/icons/icon-browse.svg'
import CourseReview from 'images/icons/icon-course-review.svg'
// import Profile from 'images/icons/icon-profile.svg'
import Certificate from 'images/icons/icon-certificate.svg'
import Enrollment from 'images/icons/icon-enrollment.svg'
// import AccountSetting from 'images/icons/icon-accountsetting.svg'
// import Contractus from 'images/icons/icon-contractus.svg'
// import Tutorial from 'images/icons/icon-tutorial.svg'
import Language from 'images/icons/icon-language.svg'
import Logout from 'images/icons/icon-logout.svg'
import Profileteacher from 'images/icons/icon-profileteacher.svg'
import AccountSettingteacher from 'images/icons/icon-gear.svg'
import ManageAcademy from 'images/icons/icon-manageacademy.svg'
import Contractusteacher from 'images/icons/icon-contractusteacher.svg'
import Learner from 'images/icons/icon-learner.svg'

import ProfileteacherInactive from 'images/icons/icon-profileteacherinactive.svg'
import BrowseInactive from 'images/icons/icon-browseinactive.svg'
import MyCourseInactive from 'images/icons/icon-mycourseinactive.svg'
import CourseReviewInactive from 'images/icons/icon-coursereviewinactive.svg'
// import ProfileInactive from 'images/icons/icon-profileinactive.svg'
import CertificateInactive from 'images/icons/icon-certificateinactive.svg'
import EnrollmentInactive from 'images/icons/icon-enrollmentinactive.svg'
// import AccountSettingInactive from 'images/icons/icon-accountsettinginactive.svg'
// import ContractusInactive from 'images/icons/icon-contractusinactive.svg'
// import TutorialInactive from 'images/icons/icon-tutorialinactive.svg'
import LanguageInactive from 'images/icons/icon-languageinactive.svg'
import LogoutInactive from 'images/icons/icon-logoutinactive.svg'
import AccountSettingteacherInactive from 'images/icons/icon-gearinactive.svg'
import ManageAcademyInactive from 'images/icons/icon-manageacademyinactive.svg'
import ContractusteacherInactive from 'images/icons/icon-contractusteacherinactive.svg'
import IconNewFeed from 'images/icons/Icon-Newfeed.svg'
import IconNewFeedBlue from 'images/icons/Icon-Newfeed-primary.svg'

import StudentLandingWhite from 'images/icons/IconLandingPage/StudentLandingWhite.svg'
import TeacherLandingWhite from 'images/icons/IconLandingPage/TeacherLandingWhite.svg'
import StudentLandingBlue from 'images/icons/IconLandingPage/IconForLearnerBlue.svg'
import TeacherLandingBlue from 'images/icons/IconLandingPage/IconForTeacherBlue.svg'

import BookGray from 'images/icons/book-gray.svg'
import BookWhite from 'images/icons/book-white.svg'
import InstructorGray from 'images/icons/instructor-gray.svg'
import InstructorWhite from 'images/icons/instructor-white.svg'

const studentMenus = (role, schoolId) => {
  let studentMenusList
  if (role) {
    studentMenusList = [
      {
        id: 'to-instructor',
        label: 'To instructor',
        link: schoolId ? `/schools/${schoolId}/dashboard/overview` : `/teacher/connections/school`,
        labelLearner: 'role.select.Learner',
        labelInstructor: 'role.select.Instructor',
        icon: BookWhite,
        activeIcon: BookWhite,
        icon2: InstructorGray,
        activeIcon2: InstructorGray,
      },

      {
        id: 'profile.menu',
        label: 'Profile',
        link: '/my-profile/profile',
        //icon: Profileteacher,
        icon: ProfileteacherInactive,
        activeIcon: ProfileteacherInactive,
      },
      {
        id: 'certificates.title',
        label: 'Certificates',
        link: '/my-certificates',
        //icon: Certificate,
        icon: CertificateInactive,
        activeIcon: CertificateInactive,
      },
      {
        id: 'enrollment.title',
        label: 'Enrollment',
        link: '/purchase-history',
        //icon: Enrollment,
        icon: EnrollmentInactive,
        activeIcon: EnrollmentInactive,
      },
      {
        id: 'courseReview.title',
        label: 'Course Review',
        link: '/course-review',
        //icon: CourseReview,
        icon: CourseReviewInactive,
        activeIcon: CourseReviewInactive,
      },
      {
        id: 'account.menu',
        label: 'Setting',
        link: '/account-setting',
        //icon: AccountSettingteacher,
        icon: AccountSettingteacherInactive,
        activeIcon: AccountSettingteacherInactive,
      },
      {
        id: 'searchbar.proflilepopup.menu.contact',
        label: 'Contact us',
        link: '/contact-us',
        //icon: Contractusteacher,
        icon: ContractusteacherInactive,
        activeIcon: ContractusteacherInactive,
      },
      {
        id: 'account.lang',
        label: 'Language',
        // link: '/account-setting',
        icon: Language,
        activeIcon: LanguageInactive,
      },
      {
        id: 'logout.title',
        label: 'Logout',
        link: '/logout',
        //icon: Logout,
        icon: LogoutInactive,
        activeIcon: LogoutInactive,
      },
      ///
      // {
      //   id: 'searchbar.allcourse',
      //   label: 'Browse',
      //   link: '/browse/courses',
      //   icon: Browse,
      //   activeIcon: BrowseInactive,
      // },
      // {
      //   id: 'searchbar.Newsfeed',
      //   label: 'NewFeed',
      //   link: '/browse/main',
      //   icon: IconNewFeed,
      //   activeIcon: IconNewFeedBlue,
      // },
      // {
      //   id: 'myCourse.title',
      //   label: 'My Course',
      //   link: '/my-course/courses',
      //   icon: Mycourse,
      //   activeIcon: MyCourseInactive,
      // },

      // {
      //   // id: 'searchbar.proflilepopup.menu.contact',
      //   label: 'Tutorial',
      //   link: 'http://classwin.co/my-course/',
      //   icon: Tutorial,
      //   activeIcon: TutorialInactive,
      // },
      // {
      //   id: 'become-instructor',
      //   label: 'Become instructor',
      //   link: `/teacher/connections/school`,
      //   icon: SwitchIcon,
      // },
    ]
  } else {
    studentMenusList = [
      {
        id: 'profile.menu',
        label: 'Profile',
        link: '/my-profile/profile',
        //icon: Profileteacher,
        icon: ProfileteacherInactive,
        activeIcon: ProfileteacherInactive,
      },
      {
        id: 'certificates.title',
        label: 'Certificates',
        link: '/my-certificates',
        //icon: Certificate,
        icon: CertificateInactive,
        activeIcon: CertificateInactive,
      },
      {
        id: 'enrollment.title',
        label: 'Enrollment',
        link: '/purchase-history',
        //icon: Enrollment,
        icon: EnrollmentInactive,
        activeIcon: EnrollmentInactive,
      },
      {
        id: 'courseReview.title',
        label: 'Course Review',
        link: '/course-review',
        //icon: CourseReview,
        icon: CourseReviewInactive,
        activeIcon: CourseReviewInactive,
      },
      {
        id: 'account.menu',
        label: 'Setting',
        link: '/account-setting',
        //icon: AccountSettingteacher,
        icon: AccountSettingteacherInactive,
        activeIcon: AccountSettingteacherInactive,
      },
      {
        id: 'searchbar.proflilepopup.menu.contact',
        label: 'Contact us',
        link: '/contact-us',
        //icon: Contractusteacher,
        icon: ContractusteacherInactive,
        activeIcon: ContractusteacherInactive,
      },
      {
        id: 'account.lang',
        label: 'Language',
        // link: '/account-setting',
        icon: Language,
        activeIcon: LanguageInactive,
      },
      {
        id: 'logout.title',
        label: 'Logout',
        link: '/logout',
        //icon: Logout,
        icon: LogoutInactive,
        activeIcon: LogoutInactive,
      },
      // {
      //   id: 'searchbar.allcourse',
      //   label: 'Browse',
      //   link: '/browse/courses',
      //   icon: Browse,
      //   activeIcon: BrowseInactive,
      // },
      // {
      //   id: 'myCourse.title',
      //   label: 'My Course',
      //   link: '/my-course/courses',
      //   icon: Mycourse,
      //   activeIcon: MyCourseInactive,
      // },
      // {
      //   id: 'courseReview.title',
      //   label: 'Course Review',
      //   link: '/course-review',
      //   icon: CourseReview,
      //   activeIcon: CourseReviewInactive,
      // },
      // {
      //   id: 'profile.menu',
      //   label: 'Profile',
      //   link: '/my-profile/profile',
      //   icon: Profileteacher,
      //   activeIcon: ProfileteacherInactive,
      // },

      // {
      //   id: 'certificates.title',
      //   label: 'Certificates',
      //   link: '/my-certificates',
      //   icon: Certificate,
      //   activeIcon: CertificateInactive,
      // },

      // {
      //   id: 'enrollment.title',
      //   label: 'Enrollment',
      //   link: '/purchase-history',
      //   icon: Enrollment,
      //   activeIcon: EnrollmentInactive,
      // },
      // {
      //   id: 'account.menu',
      //   label: 'Setting',
      //   link: '/account-setting',
      //   icon: AccountSettingteacher,
      //   activeIcon: AccountSettingteacherInactive,
      // },
      // {
      //   id: 'searchbar.proflilepopup.menu.contact',
      //   label: 'Contact us',
      //   link: '/contact-us',
      //   icon: Contractusteacher,
      //   activeIcon: ContractusteacherInactive,
      // },
      // {
      //   // id: 'searchbar.proflilepopup.menu.contact',
      //   label: 'Tutorial',
      //   link: 'http://classwin.co/my-course/',
      //   icon: Tutorial,
      //   activeIcon: TutorialInactive,
      // },
      // {
      //   id: 'account.lang',
      //   label: 'Language',
      //   icon: Language,
      //   activeIcon: LanguageInactive,
      // },
      // {
      //   id: 'become-instructor',
      //   label: 'Become instructor',
      //   link: `/teacher/connections/school`,
      //   icon: SwitchIcon,
      // },
      // {
      //   id: 'logout.title',
      //   label: 'Logout',
      //   link: '/logout',
      //   icon: Logout,
      //   activeIcon: LogoutInactive,
      // },
    ]
  }
  return studentMenusList
}
const teacherMenus = [
  {
    id: 'instructor.menu.toLearner',
    label: 'To learner',
    link: '/browse',
    icon: BookGray,
    activeIcon: BookGray,
    icon2: InstructorWhite,
    activeIcon2: InstructorWhite,
    labelLearner: 'role.select.Learner',
    labelInstructor: 'role.select.Instructor',
  },
  {
    id: 'profile.menu',
    label: 'Profile',
    link: '/teacher/my-profile/profile',
    icon: ProfileteacherInactive,
    activeIcon: ProfileteacherInactive,
  },

  {
    id: 'account.manageAcademy',
    label: 'Manage Academy',
    link: '/teacher/connections/school',
    icon: ManageAcademyInactive,
    activeIcon: ManageAcademyInactive,
  },
  {
    id: 'account.menu',
    label: 'Setting',
    link: '/teacher/account-setting',
    icon: AccountSettingteacherInactive,
    activeIcon: AccountSettingteacherInactive,
  },
  {
    id: 'searchbar.proflilepopup.menu.contact',
    label: 'Contact us',
    link: '/teacher/contact-us',
    icon: ContractusteacherInactive,
    activeIcon: ContractusteacherInactive,
  },
  // {
  //   id: 'tutorial.menu',
  //   label: 'Tutorial',
  //   link: 'https://classwin.co/create-course/',
  //   icon: Tutorial,
  //   activeIcon: TutorialInactive,
  // },
  {
    id: 'account.lang',
    label: 'Language',
    // link: '/account-setting',
    icon: LanguageInactive,
    activeIcon: LanguageInactive,
  },
  {
    id: 'logout.title',
    label: 'LogoutTeacher',
    link: '/logout',
    icon: LogoutInactive,
    activeIcon: LogoutInactive,
  },
]

const studentMenusLandingPage = (role, schoolId) => {
  let studentMenusList
  if (role) {
    studentMenusList = [
      {
        id: 'to-instructor',
        label: 'To instructor',
        link: schoolId ? `/schools/${schoolId}/dashboard/overview` : `/teacher/connections/school`,
        icon: SwitchInActive,
        activeIcon: SwitchInActive,
      },
      {
        id: 'searchbar.allcourse',
        label: 'Browse',
        link: '/browse/courses',
        icon: Browse,
        activeIcon: BrowseInactive,
      },
      {
        id: 'searchbar.Newsfeed',
        label: 'NewFeed',
        link: '/browse/main',
        icon: IconNewFeed,
        activeIcon: IconNewFeedBlue,
      },
      {
        id: 'myCourse.title',
        label: 'My Course',
        link: '/my-course/courses',
        icon: Mycourse,
        activeIcon: MyCourseInactive,
      },
      {
        id: 'courseReview.title',
        label: 'Course Review',
        link: '/course-review',
        icon: CourseReview,
        activeIcon: CourseReviewInactive,
      },
      {
        id: 'profile.menu',
        label: 'Profile',
        link: '/my-profile/profile',
        icon: Profileteacher,
        activeIcon: ProfileteacherInactive,
      },

      {
        id: 'certificates.title',
        label: 'Certificates',
        link: '/my-certificates',
        icon: Certificate,
        activeIcon: CertificateInactive,
      },
      {
        id: 'Landing.student',
        label: 'Student',
        link: '/landingStudent',
        icon: StudentLandingWhite,
        activeIcon: StudentLandingBlue,
      },
      {
        id: 'Landing.teacher',
        label: 'Teacher',
        link: '/landingTeacher',
        icon: TeacherLandingWhite,
        activeIcon: TeacherLandingBlue,
      },
      {
        id: 'enrollment.title',
        label: 'Enrollment',
        link: '/purchase-history',
        icon: Enrollment,
        activeIcon: EnrollmentInactive,
      },
      {
        id: 'account.menu',
        label: 'Setting',
        link: '/account-setting',
        icon: AccountSettingteacher,
        activeIcon: AccountSettingteacherInactive,
      },
      {
        id: 'searchbar.proflilepopup.menu.contact',
        label: 'Contact us',
        link: '/contact-us',
        icon: Contractusteacher,
        activeIcon: ContractusteacherInactive,
      },
      {
        id: 'account.lang',
        label: 'Language',
        // link: '/account-setting',
        icon: Language,
        activeIcon: LanguageInactive,
      },
      {
        id: 'logout.title',
        label: 'Logout',
        link: '/logout',
        icon: Logout,
        activeIcon: LogoutInactive,
      },
    ]
  } else {
    studentMenusList = [
      {
        id: 'searchbar.allcourse',
        label: 'Browse',
        link: '/browse/courses',
        icon: Browse,
        activeIcon: BrowseInactive,
      },
      {
        id: 'myCourse.title',
        label: 'My Course',
        link: '/my-course/courses',
        icon: Mycourse,
        activeIcon: MyCourseInactive,
      },
      {
        id: 'courseReview.title',
        label: 'Course Review',
        link: '/course-review',
        icon: CourseReview,
        activeIcon: CourseReviewInactive,
      },
      {
        id: 'profile.menu',
        label: 'Profile',
        link: '/my-profile/profile',
        icon: Profileteacher,
        activeIcon: ProfileteacherInactive,
      },

      {
        id: 'certificates.title',
        label: 'Certificates',
        link: '/my-certificates',
        icon: Certificate,
        activeIcon: CertificateInactive,
      },
      {
        id: 'Landing.student',
        label: 'Student',
        link: '/landingStudent',
        icon: StudentLandingWhite,
        activeIcon: StudentLandingBlue,
      },
      {
        id: 'Landing.teacher',
        label: 'Teacher',
        link: '/landingTeacher',
        icon: TeacherLandingWhite,
        activeIcon: TeacherLandingBlue,
      },
      {
        id: 'enrollment.title',
        label: 'Enrollment',
        link: '/purchase-history',
        icon: Enrollment,
        activeIcon: EnrollmentInactive,
      },
      {
        id: 'account.menu',
        label: 'Setting',
        link: '/account-setting',
        icon: AccountSettingteacher,
        activeIcon: AccountSettingteacherInactive,
      },
      {
        id: 'searchbar.proflilepopup.menu.contact',
        label: 'Contact us',
        link: '/contact-us',
        icon: Contractusteacher,
        activeIcon: ContractusteacherInactive,
      },
      {
        id: 'account.lang',
        label: 'Language',
        icon: Language,
        activeIcon: LanguageInactive,
      },
      // {
      //   id: 'become-instructor',
      //   label: 'Become instructor',
      //   link: `/teacher/connections/school`,
      //   icon: SwitchIcon,
      // },
      {
        id: 'logout.title',
        label: 'Logout',
        link: '/logout',
        icon: Logout,
        activeIcon: LogoutInactive,
      },
    ]
  }
  return studentMenusList
}
const teacherMenusLandingPage = [
  {
    id: 'instructor.menu.toLearner',
    label: 'To learner',
    link: '/browse',
    icon: Learner,
    activeIcon: Learner,
  },

  {
    id: 'profile.menu',
    label: 'Profile',
    link: '/teacher/my-profile/profile',
    icon: Profileteacher,
    activeIcon: ProfileteacherInactive,
  },
  {
    id: 'account.manageAcademy',
    label: 'Manage Academy',
    link: '/teacher/connections/school',
    icon: ManageAcademy,
    activeIcon: ManageAcademyInactive,
  },
  {
    id: 'account.menu',
    label: 'Setting',
    link: '/teacher/account-setting',
    icon: AccountSettingteacher,
    activeIcon: AccountSettingteacherInactive,
  },
  {
    id: 'Landing.student',
    label: 'Student',
    link: '/landingStudent',
    icon: StudentLandingWhite,
    activeIcon: StudentLandingBlue,
  },
  {
    id: 'Landing.teacher',
    label: 'Teacher',
    link: '/landingTeacher',
    icon: TeacherLandingWhite,
    activeIcon: TeacherLandingBlue,
  },
  {
    id: 'searchbar.proflilepopup.menu.contact',
    label: 'Contact us',
    link: '/teacher/contact-us',
    icon: Contractusteacher,
    activeIcon: ContractusteacherInactive,
  },
  {
    id: 'account.lang',
    label: 'Language',
    // link: '/account-setting',
    icon: Language,
    activeIcon: LanguageInactive,
  },
  {
    id: 'logout.title',
    label: 'LogoutTeacher',
    link: '/logout',
    icon: Logout,
    activeIcon: LogoutInactive,
  },
]

export const popupMenus = (isTeacherPath, role, schoolId) =>
  isTeacherPath ? teacherMenus : studentMenus(role, schoolId)

export const popupMenusLandingPage = (isTeacherPath, role, schoolId) =>
  isTeacherPath ? teacherMenusLandingPage : studentMenusLandingPage(role, schoolId)
