/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Typography } from 'components'
import Lecture from './Lecture'

import Icon from '../../../../components/icon/loadable'
import IconWhiteQuiz from '../../../../images/icons/DarkIcon/White-pen.svg'
import IconWhitePDF from '../../../../images/icons/DarkIcon/White-pdf.svg'
import IconWhiteLecture from '../../../../images/icons/DarkIcon/White-lecture.svg'
import IconWhiteVDO from '../../../../images/icons/DarkIcon/White-VDO.svg'
import IconWhiteUploadVDO from '../../../../images/icons/DarkIcon/White-uploadVDO.svg'
import IconWhiteCircle from '../../../../images/icons/DarkIcon/White-circle.svg'
import IconWhiteCircleCorrect from '../../../../images/icons/DarkIcon/White-circleCorrect.svg'
import IconWhiteArrowTop from '../../../../images/icons/DarkIcon/White-ArrowTop.svg'
function LectureMobile(props) {
  const { data, courseId, preTest, postTest, match, courseProject } = props
  const [isOpen, setIsOpen] = useState(false)

  let lectureDataNow = {}
  data?.map((item) => {
    item?.contents?.map((lecture) => {
      if (lecture?.id === match?.params?.lectureId) {
        lectureDataNow = lecture
      }
    })
  })

  const logoWhite = lectureDataNow?.isCompleted ? IconWhiteCircleCorrect : IconWhiteCircle
  const IconWhite =
    lectureDataNow?.type === 'quiz'
      ? IconWhiteQuiz
      : lectureDataNow?.lectureType === 'browse'
      ? IconWhiteUploadVDO
      : lectureDataNow?.lectureType === 'videoLink'
      ? IconWhiteVDO
      : lectureDataNow?.lectureType === 'lectureSlide'
      ? IconWhitePDF
      : lectureDataNow?.lectureType === 'paragraph'
      ? IconWhiteLecture
      : IconWhiteLecture
  return (
    <div>
      {isOpen ? (
        <div
          style={{
            backgroundColor: '#222223',
            height: '500px',
            // padding: '8px 16px 8px 16px',
            // overflowY: 'scroll',
          }}
        >
          <Lecture
            data={data}
            setIsDetail={setIsOpen}
            preTest={preTest}
            postTest={postTest}
            courseProject={courseProject}
            isLine
          />
        </div>
      ) : (
        <div
          style={{
            backgroundColor: '#111',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            padding: '8px 16px 8px 16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
            }}
          >
            <Icon src={logoWhite} size="16" style={{ margin: '4px 0px 0px 0px' }} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                height: '100%',
              }}
            >
              <Typography color="white" style={{ cursor: 'pointer' }} numberOfLines={1}>
                {lectureDataNow?.name}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Icon src={IconWhite} size="14" />
                <Typography color="white" style={{ cursor: 'pointer' }}>
                  {lectureDataNow?.type === 'quiz'
                    ? 'แบบทดสอบ'
                    : lectureDataNow?.lectureType === 'browse'
                    ? 'อัพโหลดวีดีโอ'
                    : lectureDataNow?.lectureType === 'videoLink'
                    ? 'Link วีดีโอ'
                    : lectureDataNow?.lectureType === 'lectureSlide'
                    ? 'ไฟล์ PDF'
                    : lectureDataNow?.lectureType === 'paragraph'
                    ? 'เนื้อหา'
                    : 'เนื้อหา'}
                </Typography>
                {/* <Typography color="white" style={{ cursor: 'pointer' }}>
                  | 30 min
                </Typography> */}
              </div>
            </div>
          </div>
          <Icon src={IconWhiteArrowTop} size="16" onClick={() => setIsOpen(true)} />
        </div>
      )}
    </div>
  )
}

export default withRouter(LectureMobile)
