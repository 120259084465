import React from 'react'
import RandomQuestions from 'components/randomQuestions/loadable'
import RandomAnswers from 'components/randomAnswers/loadable'
import TimerQuestions from 'components/timerQuestions/loadable'
import PeriodQuestions from 'components/periodQuestions/loadable'
import OptionalQuestions from 'components/optionalQuestions/loadable'
import SelectComposition from 'components/selectComposition/loadable'
import { Container /* Typography, Icon*/ } from 'components'
// import TutorialIcon from 'images/Classwin - New icon_Tutorial.svg'

const TestOption = ({ id, subjectId, handleChangePayload }) => {
  // const tutorialLink = 'http://classwin.co/quiz-condition/'
  return (
    <Container column="1">
      {/* <Typography
        id="course.content.option"
        h4
        strong
        margin="15px 0"
        flex
        reverse
        icon={
          <a
            href={tutorialLink}
            target="_blank"
            rel="noreferrer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon style={{ width: '25px', height: '20px' }} src={TutorialIcon} />
          </a>
        }
      >
        Options
      </Typography> */}

      <PeriodQuestions
        assessmentId={id}
        subjectId={subjectId}
        handleChangePayload={handleChangePayload}
      />
      <TimerQuestions
        assessmentId={id}
        subjectId={subjectId}
        handleChangePayload={handleChangePayload}
      />
      <RandomQuestions
        assessmentId={id}
        subjectId={subjectId}
        handleChangePayload={handleChangePayload}
      />
      <RandomAnswers
        assessmentId={id}
        subjectId={subjectId}
        handleChangePayload={handleChangePayload}
      />
      <SelectComposition
        assessmentId={id}
        subjectId={subjectId}
        handleChangePayload={handleChangePayload}
      />
      <OptionalQuestions
        assessmentId={id}
        subjectId={subjectId}
        handleChangePayload={handleChangePayload}
      />
    </Container>
  )
}

export default TestOption
