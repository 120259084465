import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Card, Container, Typography, List, ListItem, Input, Header } from './style'
import ErrorBoundary from '../errorBoundary'

const Dropdown = ({ searchable, onOutsite, options, style, handleChange, ...props }) => {
  const [items, setItems] = useState([])
  const containerRef = useRef(null)
  const [word, setWord] = useState('')

  useEffect(() => {
    setItems(options)
  }, [options])

  const styleInput = {
    border: 'none',
    borderRadius: '8px',
  }

  const onSelect = (value) => {
    handleChange(value)
  }

  const onFilter = (item) => {
    return item.value.includes(word)
  }

  const onInputChange = (value) => {
    const result = value.trim().toLowerCase()
    setWord(result)
  }

  const useOutsideAlerter = (ref) => {
    const handleCLickOutside = useCallback(
      function (event) {
        const isOutsite = ref.current && !ref.current.contains(event.target)
        isOutsite && onOutsite(isOutsite)
      },
      [ref]
    )
    useEffect(() => {
      document.addEventListener('mousedown', handleCLickOutside)
    }, [ref, handleCLickOutside])
  }

  useOutsideAlerter(containerRef)

  return (
    <ErrorBoundary>
      <Container style={style.container} ref={containerRef} {...props}>
        <Card>
          <Header>
            {searchable && (
              <Input
                autoFocus
                onChange={(e) => onInputChange(e.target.value)}
                styleInput={styleInput}
                placeholder="Search"
              />
            )}
          </Header>
          <List style={style.list}>
            {items.filter(onFilter).map(({ label: Label, value }) => (
              <ListItem searchable={searchable} key={value} onClick={() => onSelect(value)}>
                <Typography>
                  <Label />
                </Typography>
              </ListItem>
            ))}
          </List>
        </Card>
      </Container>
    </ErrorBoundary>
  )
}

Dropdown.propTypes = {
  style: PropTypes.object,
  searchable: PropTypes.bool,
  onOutsite: PropTypes.func,
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}

Dropdown.defaultProps = {
  onOutsite: () => {},
  handleChange: () => {},
  searchable: true,
  options: [],
  style: {},
}

export default Dropdown
