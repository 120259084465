import Resource from '../utils/resource'

export default new Resource('/Payments', {
  getPaymentLink: {
    url: '{id}/paymentLink',
    method: 'get',
  },
  getPaymentStatus: {
    url: '{id}/checkStatus',
    method: 'get',
  },
})
