import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'
import { CourseContentPage } from './index'

const mapState = ({
  SchoolCourse: { schoolCourse },
  Subject: { assessments, lectures },
  Language: { locale },
}) => ({
  draftInfo: schoolCourse,
  assessments,
  lectures,
  locale,
  isPublished: true,
})

const mapDispatch = ({
  SchoolCourse: { getMyCourseByIdObj, publishContent },
  Subject: {
    postSubjectLecture,
    fetchSubjectLecture,
    getAssessment,
    createAssessment,
    updateContentPriority,
  },
}) => ({
  getDraft: getMyCourseByIdObj,
  postSubjectLecture,
  fetchSubjectLecture,
  getAssessment,
  createAssessment,
  updateContentPriority,
  getDraftCourseStatus: () => ({}),
  publishContent,
})

// const mapState = ({ DraftCourse: { draftInfo }, Subject: { assessments, lectures } }) => ({
//     draftInfo,
//     assessments,
//     lectures,
//   })
//   const mapDispatch = ({
//     DraftCourse: { getDraft },
//     Subject: {
//       postSubjectLecture,
//       fetchSubjectLecture,
//       getAssessment,
//       createAssessment,
//       updateContentPriority,
//     },
//   }) => ({
//     getDraft,
//     postSubjectLecture,
//     fetchSubjectLecture,
//     getAssessment,
//     createAssessment,
//     updateContentPriority,
//   })

export default connect(mapState, mapDispatch)(withRouter(CourseContentPage))
