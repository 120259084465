import { Typography } from 'components/typography/style'
import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { Checkbox as CheckInput } from 'antd'
const CheckBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${({ background }) => {
    if (background === 'mobile') {
      return css`
        margin-bottom: 24px;
      `
    }
    return css``
  }}
  padding: 12px;
  border-radius: 6px;

  cursor: pointer;
  & > input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .ant-checkbox-input {
    border-color: #5a69fe !important;
    &:hover {
      border-color: #5a69fe !important;
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #5a69fe !important;
    &:hover {
      border-color: #5a69fe !important;
    }
  }
  .ant-checkbox-wrapper:hover {
    border-color: #5a69fe !important;
  }
  .ant-checkbox .ant-checkbox-wrapper {
    border-color: #5a69fe !important;
  }
  .ant-checkbox-inner {
    border-radius: 4px !important;
    &::after {
      top: 45%;
    }
    &:hover {
      /* background-color: #5a69fe !important; */
      border-color: #5a69fe !important;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #5a69fe !important;
  }
  .ant-checkbox-checked {
    border-radius: 4px !important;
    background-color: #5a69fe !important;
    &:hover {
      background-color: #5a69fe !important;
    }

    &::after {
      border-radius: 4px !important;
      border-color: #5a69fe !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5a69fe !important;
    border-color: #5a69fe !important;
    &:hover {
      background-color: #5a69fe !important;
    }
  }
`
const ContentText = styled.div`
  display: flex;
  justify-content: space-between;
`
const ContentDesc = styled.label`
  width: 300px;
`
const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 900px) {
    display: block;
  }
`
const CheckBoxDiv = styled.div`
  display: flex;
  align-items: flex-start;
`
function CheckBoxPure({
  id,
  label,
  isTitle = true,
  isAll = false,
  isSub = false,
  onClick,
  isChecked,
  textDesc = '',
  name,
}) {
  return (
    <Fragment>
      {isAll ? (
        <CheckBox>
          <CheckInput id={id} checked={isChecked} onClick={onClick} />
          <label htmlFor={id} style={{ display: 'inline-block', paddingLeft: 8 }}>
            <Typography id={label} color="dark1" bold>
              {label}
            </Typography>
          </label>
        </CheckBox>
      ) : (
        <CheckBox>
          <Row>
            <CheckBoxDiv style={{ paddingLeft: isSub ? (window.innerWidth > 900 ? 35 : 0) : 0 }}>
              <CheckInput id={id} onClick={() => onClick(name)} checked={isChecked} name={name} />
              <label
                htmlFor={id}
                style={{ display: 'inline-block', width: '100%', paddingLeft: 8 }}
              >
                <ContentText>
                  <Typography
                    id={label}
                    color={isTitle ? 'dark1' : 'gray6'}
                    bold={isSub ? false : true}
                  >
                    {label}
                  </Typography>
                </ContentText>
              </label>
            </CheckBoxDiv>
            {textDesc && (
              <ContentDesc for={id}>
                <Typography color="gray2" id={textDesc}>
                  {textDesc}
                </Typography>
              </ContentDesc>
            )}
          </Row>
        </CheckBox>
      )}
    </Fragment>
  )
}

export default CheckBoxPure
