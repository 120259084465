import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import requestNew from 'utils/requestNew'
import { UserAPI, SchoolAPI, StudentGradeAPI, DraftCourseAPI } from '../../api'

export const MySchool = {
  state: {
    mySchoolList: [],
    mySchoolMember: [],
    mySchoolRewards: [],
    mySchoolDraftCourses: [],
    myPreviewCertificate: null,
    myCertificates: [],
    mySchoolDetail: [],
    mySchoolGroup: [],
    mySchoolDraftCourse: {},
  },
  reducers: {
    setMySchoolGroup(state, payload) {
      return {
        ...state,
        mySchoolGroup: payload,
      }
    },
    setMyCertificates(state, payload) {
      return {
        ...state,
        myCertificates: payload,
      }
    },
    setCertificatesCount(state, payload) {
      return {
        ...state,
        certificatesCount: payload,
      }
    },
    setMySchoolList(state, payload) {
      return {
        ...state,
        mySchoolList: payload,
      }
    },
    setMySchoolMember(state, payload) {
      return {
        ...state,
        mySchoolMember: payload,
      }
    },
    setMySchoolRewards(state, payload) {
      return {
        ...state,
        mySchoolRewards: payload,
      }
    },
    setMySchoolCourse(state, payload) {
      return {
        ...state,
        mySchoolCourse: payload,
      }
    },
    setMySchoolCourseCount(state, payload) {
      return {
        ...state,
        mySchoolCourseCount: payload,
      }
    },
    setPreviewCertificate(state, payload) {
      return {
        ...state,
        myPreviewCertificate: payload,
      }
    },
    setMySchoolDetail(state, payload) {
      return {
        ...state,
        mySchoolDetail: payload,
      }
    },
    setMySchoolDraftCourse(state, payload) {
      return {
        ...state,
        mySchoolDraftCourse: payload,
      }
    },
    setMySchoolDraftCourses(state, payload) {
      return {
        ...state,
        mySchoolDraftCourses: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async getTemplateExcel(payload) {
      try {
        const res = await requestNew.get(`/schoolStudent/downloadExcell/${payload.schoolId}`, {
          responseType: 'blob',
        })
        if (res) {
          const url = URL.createObjectURL(res.data)
          const link = document.createElement('a')
          link.href = url
          link.download = 'Template_Excel.xlsx'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }
      } catch (error) {
        throw error
      }
    },
    async postTemplateExcel(payload) {
      try {
        const res = await requestNew.post(`/schoolStudent/uploadExcell/${payload.schoolId}`, {
          data: payload.data,
        })
        return res.data
      } catch (error) {
        throw error
      }
    },
    async getMySchoolGroup(payload) {
      try {
        const result = await SchoolAPI.getGroupById(payload)
        this.setMySchoolGroup(result)
      } catch (error) {
        throw error
      }
    },
    async genBlockchainAddress(payload) {
      try {
        const result = await SchoolAPI.genBlockchainAddress(payload)
        return result
      } catch (error) {
        throw error
      }
    },
    async postPdfCert(payload, state) {
      try {
        await StudentGradeAPI.sendPdfCertificate(payload)
        const certificates = cloneDeep(state.MySchool.myCertificates)
        const certificate = certificates.find((item) => item.id === payload.courseId)
        certificate.pdfUnreleasedCertificates = certificate.pdfUnreleasedCertificates.filter(
          (item) => !payload.studentIds.includes(item.studentId)
        )
        this.setMyCertificates(certificates)
      } catch (error) {
        throw error
      }
    },
    async postBlockchianCert(payload, state) {
      try {
        await StudentGradeAPI.saveBlockchianCert(payload)
        const certificates = cloneDeep(state.MySchool.myCertificates)
        const certificate = certificates.find((item) => item.id === payload.courseId)
        certificate.blockchainUnreleasedCertificates =
          certificate.blockchainUnreleasedCertificates.filter(
            (item) => !payload.studentIds.includes(item.studentId)
          )
        this.setMyCertificates(certificates)
      } catch (error) {
        throw error
      }
    },
    async getCertificates({ id, limit, skip }) {
      try {
        const filterObj = { limit, skip, order: 'createdAt DESC' }
        const filter = JSON.stringify(filterObj)
        const result = await SchoolAPI.getCertificates({ id, filter })
        this.setMyCertificates(result)
      } catch (error) {
        throw error
      }
    },
    async getCertificatesCount(id) {
      const res = await SchoolAPI.getCertificatesCount({ id })
      this.setCertificatesCount(res)
      return res
    },
    async updateSchool(payload) {
      try {
        await SchoolAPI.update(payload)
        dispatch.Alert.success({ title: 'School Updated Successfully' })
      } catch (error) {
        dispatch.Alert.error({ title: 'School Update Failed' })

        throw error
      }
    },
    async getCertificateTemplate(id) {
      try {
        const result = await SchoolAPI.findById({ id })
        return result.certificateTemplate
      } catch (error) {
        throw error
      }
    },
    async previewCertificate(data) {
      try {
        this.setPreviewCertificate(data)
      } catch (error) {
        throw error
      }
    },
    async getMySchoolList() {
      const filter = JSON.stringify({
        include: {
          relation: 'school',
          scope: {
            include: {
              relation: 'schoolStudents',
              scope: {
                where: {
                  isApproved: true,
                },
                include: 'appUser',
              },
            },
          },
        },
      })
      try {
        const schools = await UserAPI.getSchoolStudents({ filter })
        // const schools = await UserAPI.getEnrolledSchools({ filter })
        this.setMySchoolList(schools)
        return schools
      } catch (e) {
        throw e
      }
    },
    async joinSchool(schoolId) {
      const result = await UserAPI.joinSchoolStudent({ schoolId })
      this.getMySchoolList()
      return result
    },
    async deleteSchool(schoolStudentId) {
      const result = await UserAPI.deleteSchoolStudents({ id: schoolStudentId })
      this.getMySchoolList()
      return result
    },
    async getMySchoolMember(schoolId) {
      const result = await SchoolAPI.getStudentsDetail({ id: schoolId })
      this.setMySchoolMember(result)
      return result
    },
    async getMySchoolRewards(schoolId) {
      const result = await SchoolAPI.getRewards({ id: schoolId })
      this.setMySchoolRewards(result)
    },
    async getSchoolCourse(schoolId) {
      const filter = JSON.stringify({
        include: ['teachers', 'publishedSubject'],
      })
      const result = await SchoolAPI.getSchoolCourse({ id: schoolId, filter })
      this.setMySchoolCourse(result)
    },
    async getSchoolCourseByType({ schoolId, publicMode }) {
      const filter = JSON.stringify({
        where: { publicMode },
        include: [{ relation: 'teachers', scope: {} }, 'publishedSubject'],
      })

      const result = await SchoolAPI.getSchoolCourse({ id: schoolId, filter })
      this.setMySchoolCourse(result)
    },
    async getSchoolCourseByFilter({ schoolId, condition }) {
      const filter = JSON.stringify({
        where: { ...condition },
        include: ['teachers', 'publishedSubject'],
      })
      const result = await SchoolAPI.getSchoolCourse({ id: schoolId, filter })
      this.setMySchoolCourse(result)
    },
    async getSchoolCourseAll({ schoolId, condition, order = 'createdAt DESC', skip, limit }) {
      const filter = JSON.stringify({
        where: { ...condition },
        include: ['teachers', 'publishedSubject', 'subCategory'],
        order,
        skip,
        limit,
      })
      const result = await SchoolAPI.getSchoolCourseAll({ id: schoolId, filter })
      this.setMySchoolCourse(result)
    },
    async getSchoolCourseAllCount({ schoolId, condition }) {
      const where = JSON.stringify({
        ...condition,
      })
      const result = await SchoolAPI.getSchoolCourseAllCount({ id: schoolId, where })
      this.setMySchoolCourseCount(result)
    },
    async getMySchoolById(schoolId) {
      const filter = JSON.stringify({
        include: 'schoolPackage',
      })
      const result = await SchoolAPI.findById({ id: schoolId, filter })
      this.setMySchoolDetail(result)
    },
    async createMySchoolDraftCourse(data) {
      const result = await SchoolAPI.createDraft(data)
      this.setMySchoolDraftCourse(result)
      return result
    },
    async getMySchoolDraftCourses({ schoolId, condition }) {
      const filter = JSON.stringify({
        where: { schoolId, ...condition },
        include: { subject: 'lectures' },
      })
      const result = await DraftCourseAPI.find({ filter })
      const teacherList = await SchoolAPI.getTeachersDetail({ id: schoolId })
      const final = result.map((draft) => {
        const members = teacherList.filter((teacher) =>
          (draft._teachers || []).includes(teacher.id)
        )
        return { ...draft, _teachers: [...members] }
      })
      this.setMySchoolDraftCourses(final)
      return final
    },
  }),
  selectors: () => ({
    status() {
      return (rootState, props) => {
        const item = find(rootState.MySchool.mySchoolList, { schoolId: props.schoolId })
        if (item) {
          if (item.isApproved) {
            return 'Joined'
          }
          return 'Pending'
        }
        return 'Not join'
      }
    },
  }),
}
