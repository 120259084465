import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { getPersistor } from '@rematch/persist'
import { PersistGate } from 'redux-persist/lib/integration/react'
import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/react'

import 'intro.js/introjs.css'
import 'normalize.css'
import 'antd/dist/antd.css'
import { ThemeProvider } from 'styled-components'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import theme from './theme'
import { GlobalStyle } from './globalStyle'
import store from './store'
import LanguageProvider from './components/LanguageProvider'

const persistor = getPersistor()

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Sentry.Replay()],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
const Root = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <>
        <PersistGate persistor={persistor}>
          <LanguageProvider>
            <App />
          </LanguageProvider>
        </PersistGate>
        <GlobalStyle />
      </>
    </Provider>
  </ThemeProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
