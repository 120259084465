import React from 'react'
import MediaQuery from 'react-responsive'

export const Mobile = (props) => <MediaQuery {...props} maxWidth={768} />
export const MobileAndTablet = (props) => <MediaQuery {...props} maxWidth={1024} />
export const Tablet = (props) => <MediaQuery {...props} minWidth={430} maxWidth={768} />
export const SmallMobile = (props) => <MediaQuery {...props} maxWidth={429} />
export const Desktop = (props) => <MediaQuery {...props} minWidth={769} />
export const DesktopAndTablet = (props) => <MediaQuery {...props} minWidth={430} />
export const LargeDesktop = (props) => <MediaQuery {...props} minWidth={1025} />
export const CustomSmall = (props) => <MediaQuery {...props} maxWidth={991} />
export const CustomLarge = (props) => <MediaQuery {...props} minWidth={992} />
export const Large = (props) => <MediaQuery {...props} minWidth={1281} />
export const Medium = (props) => <MediaQuery {...props} minWidth={601} maxWidth={1280} />
export const Small = (props) => <MediaQuery {...props} maxWidth={600} />
export const AllTablet = (props) => <MediaQuery {...props} minWidth={769} maxWidth={1024} />
export const CustomMediumDesktop = (props) => (
  <MediaQuery {...props} minWidth={960} maxWidth={1375} />
)
export const TabletAndLargeTablet = (props) => (
  <MediaQuery {...props} minWidth={430} maxWidth={1024} />
)
export const CustomTablet = (props) => <MediaQuery {...props} minWidth={430} maxWidth={960} />
export const CustomLargeDesktop = (props) => <MediaQuery {...props} minWidth={1376} />
export const Custom = ({ minWidth, maxWidth, ...props }) => (
  <MediaQuery {...props} minWidth={minWidth || undefined} maxWidth={maxWidth || undefined} />
)
