import React from 'react'
import PropTypes from 'prop-types'
import Switch from './style'
import theme from 'theme'

function PureSwitch({
  checked,
  onChange,
  onColor,
  offColor,
  offHandleColor,
  onHandleColor,
  ...props
}) {
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      handleDiameter={20}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={40}
      onColor={onColor ? theme[onColor] : theme.primary1}
      offColor={offColor ? theme[offColor] : theme.gray10}
      offHandleColor={offHandleColor ? theme[offHandleColor] : theme.white}
      onHandleColor={onHandleColor ? theme[onHandleColor] : theme.white}
      className="react-switch"
      id="material-switch"
      {...props}
    />
  )
}

PureSwitch.propTypes = {
  props: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

PureSwitch.defaultProps = {
  props: {},
  checked: false,
  onChange: () => {},
}

export default PureSwitch
