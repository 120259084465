/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ErrorBoundary from '../errorBoundary'
import ProfileImage from '../profileImage'
import Tooltip from '../tooltip/loadable'
import AddCourseModal from '../addCourseModal'
import SchoolSelector from './schoolSelector'
import getImageUrl from '../../utils/getImageUrl'
import Logo from '../logo'
import { Icon, Typography, Button as CButton } from '../index'
import IconPlus from '../../images/PlusCircle.svg'
import get from 'lodash/get'

const Container = styled.div`
  /* background-image: linear-gradient(to top, #12348d, #598cff); */
  //background: #111;
  background: #fff;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  padding-top: 26px;
  z-index: 1000;
  @media (max-width: 1024px) {
    height: 100%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      height: 48px;
      display: flex;
      font-size: ${({ theme }) => theme['text-sm']};
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      color: white;
      text-decoration: none;
      transition: all 0.3s;
      border-left: 5px solid transparent;
      border-radius: 4px;
      &.active {
        padding-left: 10px;
        /* border-left: 5px solid white; */
        // background-color: #e0e6f6;
        div {
          color: #253e87;
          font-weight: 600;
        }
        background-color: #e0e6f6;
      }
      /* &:hover {
        div {
          color: #253e87;
          font-weight: 600;
        }
        background-color: #e0e6f6;
      } */
    }
  }
`
const CardImage = styled.div`
  text-align: center;
`
const Li = styled.li`
  /* :hover {
    //background-color: red;
    color: blue;
  } */
  && > a {
    margin-top: '2px';
  }
`

// const Button = styled((props) => <CButton {...props} />)`
//   justify-content: space-between;
//   font-weight: 300;
//   background-color: rgba(255, 255, 255, 0.1);
//   padding: 10px;
//   cursor: default;
//   :hover {
//     background-color: rgba(255, 255, 255, 0.2);
//   }
// `

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`

const BorderPackage = styled.div`
  cursor: pointer;
  padding: 6px 44px;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 10px;
    padding: 1px;
    background: linear-gradient(90deg, #6c51fe 3.11%, #40b1ff 100.44%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

const Placeholder = styled.div`
  width: 100%;
  height: 69px;
`

// const UpgradeWrapper = styled.div`
//   padding: 10px 10px;
//   position: fixed;
//   bottom: 0;
//   width: 100%;
//   backdrop-filter: blur(10px);

//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `

const displayWidth = 90
const imageWidth = displayWidth * 2

class SideBar extends Component {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
  }

  static propTypes = {
    menus: PropTypes.array.isRequired,
    schoolList: PropTypes.array,
  }

  state = {
    showSchoolPopup: false,
    openAddModal: false,
  }

  componentDidMount() {
    const { match, getSchoolList, isSchool } = this.props
    if (isSchool && match.params.schoolId) {
      getSchoolList()
    }
  }

  handleTogglePopup = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
  }

  handleModal = () => {
    this.setState(({ openAddModal }) => ({ openAddModal: !openAddModal }))
  }

  render() {
    const { menus, toggle, isSchool, mySchoolDetail, schoolList, teacherSidebar, styleContainer } =
      this.props
    const { logo, name, id, schoolPackage } = mySchoolDetail
    const { openAddModal } = this.state
    const pathname = get(location, 'pathname', false)

    const defaultLogo =
      'https://dev-api-classwin.dev.witsawa.com/api/Containers/witsawa-classwin/cover/b8dfabc5-d28c-4526-bee8-de52cba937b0.png?width=400&height=400'
    let sideMenus = menus
    let isOwner = false
    let canCreateCourse = false
    if (isSchool) {
      const currSchool = schoolList.find((item) => item.id === id)
      const myRole = (currSchool || {}).role
      isOwner = myRole === 'owner'
      canCreateCourse = isOwner ? true : currSchool?.permissions?.createAndViewCourse
      sideMenus = isOwner
        ? menus
        : menus.filter((item) => {
            let menuName = item.label.toLowerCase()
            return currSchool?.permissions[menuName]
          })
    }
    return (
      <ErrorBoundary>
        <Container isSchool={isSchool} style={styleContainer}>
          {/* <Logo type="primary2" width="125" height="62" margin="20px 35px" /> */}
          {isSchool && (
            <CardImage>
              <ProfileImage
                width={displayWidth}
                style={{ margin: 'auto', border: '1px solid #CBD5E1' }}
                src={getImageUrl(logo, defaultLogo, imageWidth, imageWidth)}
              />
              <Tooltip
                placement="bottom-start"
                trigger="click"
                className="school-list-popup"
                tooltipShown={this.state.showSchoolPopup}
                onVisibilityChange={() => this.handleTogglePopup('showSchoolPopup')}
              >
                <SchoolSelector name={name} />
              </Tooltip>
            </CardImage>
          )}

          {canCreateCourse && (
            <div style={{ padding: '24px 24px 20px' }}>
              {/* <Link
                to={{
                  pathname: `/schools/${params.schoolId}/curriculum/school-courses`,
                  state: { openModal: true },
                }}
              >
                <CButton id="createCourse" createCourse>
                  <img src={IconPlus} alt="createCourse" style={{ width: 20, height: 20 }} />
                  {locale === 'th' ? 'สร้างคอร์สใหม่' : 'Create Course'}
                </CButton>
              </Link> */}
              <CButton
                id="createCourse"
                createCourse
                onClick={() => this.setState({ openAddModal: true })}
                data-intro="course1"
                style={{ borderRadius: 4 }}
              >
                <img src={IconPlus} alt="createCourse" style={{ width: 20, height: 20 }} />
                <Typography id="createCourse" color="white" fontSize="text-sm" bold>
                  Create Course
                </Typography>
              </CButton>
            </div>
          )}
          <ul ref={this.textInput} style={{ padding: '0px 24px 20px' }}>
            {sideMenus.map((menu) => {
              const isSamePath = pathname.startsWith(menu.link)

              return (
                <Li key={menu.label}>
                  <NavLink onClick={() => teacherSidebar(false)} to={menu.link}>
                    <Icon size="24" margin="8" src={isSamePath ? menu.iconActive : menu.icon} />
                    <Typography
                      id={menu.id}
                      color={isSamePath ? 'primary1' : 'black87'}
                      bold={isSamePath ? true : false}
                      strong={isSamePath ? false : true}
                      //hoverColor="primary1"
                    >
                      {menu.label}
                    </Typography>
                  </NavLink>
                </Li>
              )
            })}
          </ul>
          <FlexCenter>
            <BorderPackage>
              <Typography
                //color="primary1"
                fontSize="text-lg"
                gradient
                superbold
                id="package.menu.sidebar"
                style={{ cursor: 'pointer' }}
                values={{
                  package:
                    schoolPackage?.name === 'contact'
                      ? 'CUSTOMIZE'
                      : schoolPackage?.name?.toUpperCase(),
                }}
              >
                {schoolPackage?.name?.toUpperCase()}
              </Typography>
            </BorderPackage>
          </FlexCenter>
          <Placeholder />
          {/* <UpgradeWrapper>
            <Button
              white
              nowrap
              full
              color="white"
              padding="3px 12px"
              onClick={() => {
                if (isOwner) {
                  this.props.handleOpenModal()
                }
              }}
            >
              <Typography
                color="white"
                bold
                id="package.menu.sidebar"
                values={{
                  package:
                    schoolPackage?.name === 'contact'
                      ? 'CUSTOMIZE'
                      : schoolPackage?.name?.toUpperCase(),
                }}
              >
                Package:
                {schoolPackage?.name?.toUpperCase()}
              </Typography>
            </Button>
          </UpgradeWrapper> */}
        </Container>
        <AddCourseModal isOpen={openAddModal} handleModal={this.handleModal} />
      </ErrorBoundary>
    )
  }
}

const mapState = ({ TeacherSchool: { schoolList }, MySchool: { mySchoolDetail }, ...state }) => ({
  mySchoolDetail,
  sidebarTeacherProfile: state.Sidebar.sidebarTeacherProfile,
  locale: state.Language.locale,
  schoolList: schoolList
    .filter((item) => item.isApprove)
    .map((item) => {
      return {
        ...item.school,
        role: item.role,
        isOwned: item.role === 'owner',
        isDefault: item.isDefault,
        permissions: item.permissions,
      }
    }),
})

const mapDispatch = (dispatch) => {
  const { Sidebar, TeacherSchool } = dispatch

  return {
    toggle: Sidebar.toggle,
    teacherSidebar: Sidebar.teacherSidebar,

    getSchoolList: TeacherSchool.getSchoolList,
  }
}

export default connect(mapState, mapDispatch)(withRouter(SideBar))
