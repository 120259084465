import React from 'react'
import styled from 'styled-components'
import ErrorBoundary from 'components/errorBoundary'
import EditIcon from '../../images/icons/Pencil.svg'
import EditColorIcon from '../../images/icons/PencilSimple.svg'
import BinIcon from '../../images/icons/BlackTrash.svg'
import BinColorIcon from '../../images/icons/TrashColor.svg'
import theme from 'theme'

const Container = styled.div`
  width: 154px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  position: absolute;
  top: -10px;
  right: -110px;
  /* padding: 10px 0px; */
  overflow: hidden;
  cursor: pointer;
  .link {
    cursor: pointer;
  }
`

// const Item = styled.div`
//   padding: 10px 20px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   font-size: ${({ theme }) => theme['text-sm']};
//   border-bottom: 1px solid ${theme.gray13};
//   :hover {
//     background-color: #f3f3f3;
//   }
//   :last-child {
//     border-bottom: none;
//   }
// `
const EditItem = styled.div`
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-image: url(${EditIcon});
  background-position: left;
  padding: 10px 0 10px 48px;
  background-position-x: 16px;

  span {
    font-size: ${({ theme }) => theme['text-sm']};
  }

  :hover {
    background-color: ${theme.primary12};
    border-radius: 8px;
    background-image: url(${EditColorIcon});

    span {
      color: ${theme.primary1};
      font-weight: 700;
    }
  }
`
const DeleteItem = styled.div`
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-image: url(${BinIcon});
  background-position: left;
  padding: 10px 0 10px 48px;
  background-position-x: 16px;

  span {
    font-size: ${({ theme }) => theme['text-sm']};
  }

  :hover {
    background-color: ${theme.primary12};
    border-radius: 8px;
    background-image: url(${BinColorIcon});

    span {
      color: ${theme.primary1};
      font-weight: 600;
    }
  }
`
const Popup = ({ list }) => {
  return (
    <ErrorBoundary>
      <Container>
        {/* {list.map(({ onClick, menu }, index) => (
          <Item key={index + ''} onClick={onClick}>
            {menu}
          </Item>
        ))} */}
        <EditItem onClick={list[0].onClick}>
          <span>{list[0].menu}</span>
        </EditItem>
        <DeleteItem onClick={list[1].onClick}>
          <span>{list[1].menu}</span>
        </DeleteItem>
      </Container>
    </ErrorBoundary>
  )
}

export default Popup
