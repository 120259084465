import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  ${({ height }) => {
    if (height) {
      return css`
        height: ${height}px;
      `
    } else {
      return css`
        height: 10px;
      `
    }
  }}
  ${({ borderRadius }) => {
    if (borderRadius) {
      return css`
        border-radius: ${borderRadius}px;
      `
    } else {
      return css`
        border-radius: 4px;
      `
    }
  }}
  ${({ background }) => {
    if (background) {
      return css`
        background-color: ${background};
      `
    } else {
      return css`
        background-color: #ececec;
      `
    }
  }}
`

const Bar = styled.div`
  ${({ boxShadowBar }) => {
    if (boxShadowBar) {
      return css`
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
      `
    } else {
      return css``
    }
  }}
  ${({ height }) => {
    if (height) {
      return css`
        height: ${height}px;
      `
    } else {
      return css`
        height: 10px;
      `
    }
  }}
    ${({ borderRadius }) => {
    if (borderRadius) {
      return css`
        border-radius: ${borderRadius}px;
      `
    } else {
      return css`
        border-radius: 4px;
      `
    }
  }}

  width: ${({ value }) => (value ? value : '0%')};
  background-color: black;
  ${(props) => {
    if (props.color) {
      return css`
        background-color: ${props.color};
      `
    }
    return css``
  }}
`

export { Wrapper, Bar }
