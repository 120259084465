import styled, { css } from 'styled-components'
import theme from 'theme'

const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ spanPadding }) => (spanPadding ? spanPadding : '1px')};
  // background-color: ${theme.gray5};
  border-color: ${({ status }) => (status === 'error' ? theme.danger : theme.black20)};
  border-style: solid;
  border-width: 1px 0px 1px 1px;
  color: ${theme.black38};
  font-weight: 500;
  opacity: 80%;

  position: relative;
  overflow: hidden;

  ${({ noPadding }) =>
    noPadding &&
    `
  padding: 0;
  align-items: unset;
  `}

  ${({ prefix, suffix, counter, suffixHover }) => {
    if (prefix) {
      return css`
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-width: 1px 0px 1px 1px;
      `
    }
    if (suffix) {
      return css`
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-width: 1px 1px 1px 0px;
        :hover {
          ${suffixHover}
        }
      `
    }
    if (counter) {
      return css`
        color: #c2c2c2;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-width: 1px 1px 1px 0px;
        width: 90px;
      `
    }
  }}

  ${({ bgSuffix }) => {
    if (bgSuffix) {
      return css`
        background-color: ${bgSuffix};
      `
    }
  }}

  ${({ status }) => {
    if (status === 'error') {
      return css`
        background-color: ${theme.white};
        border-color: ${theme.danger};
      `
    }

    if (status === 'warning') {
      return css`
        background-color: ${theme.white};
        border-color: ${theme.warning};
      `
    }
  }}
`

const Input = styled.input`
  width: 100%;
  padding: 6px 11px;
  border: ${(props) => (props.Border ? props.Border : `1px solid ${theme.black20}`)};
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  border-radius: 6px;
  outline: none;
  margin: 0;
  opacity: 80%;
  font-size: ${({ theme }) => theme['text-sm']};
  line-height: 1.5;
  list-style: none;
  height: ${(props) => (props.height ? props.height : '48px')};
  color: ${({ color, theme }) => (color ? theme[color] : theme.black87)};

  :hover {
    border-color: ${theme.primary1};
    opacity: 100%;
  }

  :hover + ${Span} {
    border-color: ${theme.primary1};
    opacity: 100%;
  }

  ${(props) => {
    if (props.textBase) {
      return css`
        font-size: ${({ theme }) => theme['text-base']};
        border: 1px solid ${theme.black20};
      `
    }
    if (props.modalman) {
      return css`
        border: 1px solid #cbd5e1;
        :hover {
          border-color: #cbd5e1 !important;
          opacity: 100%;
        }
      `
    }
  }}
  ::placeholder {
    font-weight: 400;
    color: ${theme.black38};
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        :hover {
          border-color: #a6a6a6;
          cursor: not-allowed;
        }

        :hover + ${Span} {
          border-color: #a6a6a6;
        }
      `
    }
  }}

  ${({ prefix, suffix, counter }) => {
    if (prefix && suffix) {
      return css`
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      `
    }
    if (prefix) {
      return css`
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      `
    }
    if (suffix) {
      return css`
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      `
    }
    if (counter) {
      return css`
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right-style: none;
      `
    }
  }}

  ${({ fontSize }) => {
    if (fontSize) {
      return css`
        font-size: ${fontSize};
        font-size: ${({ theme }) => theme[fontSize]};
      `
    }
  }}

  ${({ fontWeight }) => {
    if (fontWeight) {
      return css`
        font-weight: ${fontWeight};
      `
    }
    return css``
  }}

  ${({ readOnly }) => {
    if (readOnly) {
      return css`
        border-color: ${theme.gray3};
        background: ${theme.black10};
        color: ${theme.gray2};
        cursor: not-allowed;

        :hover {
          border-color: ${theme.gray3};
          opacity: 80%;
        }
      `
    }
  }}

  ${({ status }) => {
    if (status === 'error') {
      return css`
        background-color: ${theme.white};
        border-color: ${theme.danger};
      `
    }

    if (status === 'warning') {
      return css`
        background-color: ${theme.white};
        border-color: ${theme.warning};
      `
    }
  }}

  ${({ size }) => {
    if (size === 'small') {
      return css`
        padding: 3px 11px;
      `
    }

    if (size === 'large') {
      return css`
        padding: 9px 11px;
      `
    }
  }}

  ${({ padding }) => {
    if (padding) {
      return css`
        padding: ${padding};
      `
    }
    return css``
  }}

  ${({ noBorder }) => {
    if (noBorder) {
      return css`
        border: none;
      `
    }
    return css``
  }}

  ${({ lineHeight }) => {
    if (lineHeight) {
      return css`
        line-height: ${lineHeight};
      `
    }
  }}
`

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  ${({ full }) => {
    if (full) {
      return css`
        width: 100%;
      `
    }
    return css``
  }}
`

export { Input, Wrapper, Span }
