import styled from 'styled-components'

export const Content = styled.div`
  /* margin: 20px 0; */
  max-width: 900px;
  .image-view {
    font-size: ${({ theme }) => theme['text-xs']};
    margin: 10px 0 0 0;
    cursor: pointer;
    text-decoration: underline;
    display: ${(props) => (props.value ? 'block' : 'none')};
  }
`

export const UploadImageStyle = styled.div`
  margin: 10px 0 0 0;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 20px;

  p {
    margin: 20px 0 0 0;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

export const ImageBox = styled.div`
  border: 2px dashed #5788fc;
  padding: -1px;
  /* height: ${(props) => props.height}; */
  height: 80px;
  width: 80px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  img {
    height: calc(100% + 5px);
    width: calc(100% + 5px);
    border-radius: 50%;
  }
  .icon {
    height: 50px;
    width: 50px;
  }
`
