import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import _ from 'lodash'

const Row = ({ children, gutter = [16], ...props }) => {
  const columnGap = _.get(gutter, '[0]') / 2
  const rowGap = _.get(gutter, '[1]', gutter[0]) / 2
  return (
    <div>
      <CustomGrid rowGap={rowGap} columnGap={columnGap} {...props}>
        {React.Children.map(children, (child) => React.cloneElement(child, { rowGap, columnGap }))}
      </CustomGrid>
    </div>
  )
}

Row.propTypes = {
  children: PropTypes.node,
}

export default Row

const CustomGrid = styled.div`
  flex-flow: row wrap;
  display: flex;

  ${(props) => {
    if (props.margin) {
      return css`
        margin: -${(props) => props.rowGap}px -${(props) => props.columnGap}px;
      `
    }
    return ``
  }}
`
