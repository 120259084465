import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import Button from '../button'
import TextInput from '../textInput'
import Typography from '../typography'
// import LogoClasswind from '../../images/Classwin logo_Classwin Big - Blue.png'
// import { Mobile } from '../../utils/displayResponsive'
// import { ImageBox, Image } from './styles'

class ResetPasswordForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    onSubmit: () => {},
  }

  render() {
    const { onSubmit, locale } = this.props
    return (
      <div>
        <Typography id="forget.password" h2 padding="8px 0px 16px">
          Forget Password
        </Typography>
        {/* <Mobile>
          <ImageBox isModal={isModal}>
            <Image src={LogoClasswind} alt="logo" />
          </ImageBox>
        </Mobile> */}
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(
                <Typography
                  style={{ textTransform: 'none' }}
                  id="login.alert.email"
                  fontSize="text-xs"
                  color="danger"
                >
                  Email is incorrect. Please try again.
                </Typography>
              )
              .trim()
              .required(locale === 'th' ? 'จำเป็น' : 'Required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values)
            setSubmitting(false)
          }}
        >
          {(props) => {
            const { values, errors, handleChange, handleSubmit } = props
            return (
              <form onSubmit={handleSubmit} id="reset-password-form">
                <Typography id="login.label.forget.subtitle" label2 margin="0 0 20px">
                  Enter email to reset password.
                </Typography>
                <TextInput
                  id="label.email"
                  value={values.email}
                  error={errors.email}
                  name="email"
                  data-testid="reset-form-email-input"
                  placeholder="example@email.com"
                  onChange={handleChange}
                  style={{ padding: '0 5px' }}
                  isLogin
                  full
                />
                <Button
                  id="button.reset.password"
                  data-testid="reset-password-submit-button"
                  primary
                  full
                  type="submit"
                  style={{ marginTop: 20 }}
                >
                  Reset Password
                </Button>
              </form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

const mapState = (state) => ({
  locale: state.Language.locale,
})

export default connect(mapState)(ResetPasswordForm)
