import Resource from '../utils/resource'

export default new Resource('/DraftCourses', {
  //   getDraftCourse: {
  //     url: '{id}',
  //     method: 'get',
  //   },
  updateDraftCourse: {
    url: '{id}',
    method: 'patch',
  },
  getDraftCourseStatus: {
    url: '{id}/status',
    method: 'get',
  },
  getCategory: {
    url: '{id}/category',
    method: 'get',
  },
  publishCourse: {
    url: '{id}/course',
    method: 'post',
  },
  getSkill: {
    url: '{draftCourseId}/skills',
    method: 'get',
  },
  putDraftCoursesSkill: {
    url: '{draftCourseId}/skills/rel/{fk}',
    method: 'put',
  },
  deleteDraftCoursesSkill: {
    url: '{draftCourseId}/skills/rel/{fk}',
    method: 'delete',
  },
})
