import styled, { css } from 'styled-components'

const Card = styled.div`
  ${({ background }) => {
    if (background) {
      return css`
        background-color: ${background};
      `
    } else {
      return css`
        background-color: #fff;
      `
    }
  }}

  ${({ boxShadow }) => {
    if (boxShadow === 'none') {
      return css``
    } else {
      return css`
        box-shadow: 0px 5px 10px #00000029;
      `
    }
  }}
  border: 2.4px solid transparent;
  border-radius: 8px;

  width: 100%;

  ${({ height }) => {
    if (height) {
      return css`
        height: ${height}px;
      `
    }
    return css`
      height: auto;
    `
  }}

  ${({ width }) => {
    if (width) {
      return css`
        width: ${width};
      `
    }
    return css``
  }}

${({ maxWidth }) => {
    if (maxWidth) {
      return css`
        max-width: ${maxWidth};
      `
    }
    return css``
  }}

  ${({ padding }) => {
    if (typeof padding === 'string') {
      return css`
        padding: ${padding};
      `
    } else if (typeof padding === 'number') {
      return css`
        padding: ${padding}px;
      `
    }
    return css`
      padding: 10px;
    `
  }}
  

  ${({ margin }) => {
    if (margin) {
      return css`
        margin: ${margin};
      `
    }
    return css``
  }}

  
  ${({ border }) => {
    if (border) {
      return css`
        border: ${border};
      `
    }
    return css``
  }}



  ${({ clickable }) => {
    if (clickable) {
      return css`
        cursor: pointer;
        :hover {
          transition: fade 1.5s;
          box-shadow: 0px 5px 20px #c1c1c1;
        }
      `
    } else {
      return css``
    }
  }}

  ${({ disabled }) => {
    if (disabled) {
      return css`
        opacity: 0.5;
        cursor: not-allowed;
      `
    }
  }}
`

export default Card
