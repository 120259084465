import styled, { css } from 'styled-components'
import { Container as _Container } from 'components'
import { Icon } from '../../components'
import theme from 'theme'
const Container = styled(_Container)`
  /* max-width: 1128px; */
  padding: 0 0px;
  margin: 0 auto 0 auto;
  width: 100%;

  @media (max-width: 1024px) {
    padding: 0px 0px;
  }
  @media (max-width: 768px) {
    padding: 0 0px;
  }
`
const Content = styled.div`
  margin-top: 24px;
  margin: 20px 150px 0px 150px;
  @media (max-width: 1024px) {
    margin: 20px 50px;
    //width: 100%;
  }
  @media (max-width: 768px) {
    margin: 20px 20px;
    //width: 100%;
  }
  display: flex;
  flex-direction: row;
  gap: 24px;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* overflow: hidden; */
  border-radius: 8px;
  margin: 0px 0px 50px 0px;
`
const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-top: 1px solid ${(props) => props.theme.gray8};
  padding: 8px 150px;
  z-index: 5;
  height: 60px;
  @media (max-width: 1024px) {
    padding: 8px 50px;
  }
  @media (max-width: 768px) {
    padding: 8px 20px;
    button {
      padding: 6px;
    }
  }
`

const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 16px;
  padding: 24px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`
const Cardmenu = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`
const _Icon = styled(Icon)`
  // width: 30px;
  // height: 30px;
  margin-right: 8px;
  padding: ${(props) => (props.plus ? '6px' : '0px')};
  right: 0px;
  // min-width: 30px;
`
const HeaderCard = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  overflow-x: scroll;
  flex-direction: row;
  gap: 32px;
  @media (max-width: 768px) {
    gap: 12px;
    padding: 0px 16px 0px 16px;
    overflow: scroll;
  }
`
const TabBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  background-color: #fff;
  z-index: 5;
  gap: 20px;
  transition: all 0.3s;
  overflow-x: scroll;
  // box-shadow: ${(props) => !props.hideShadow && '0 5px 5px 0 rgba(0, 0, 0, 0.1)'};
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
  }
  border-bottom: ${(props) => props.noneBorderBottom && 'none !important'};

  .active {
    /* border-bottom:  3px solid ${(props) => props.theme.primary1}; */
    border-bottom: ${({ headBorder, theme }) =>
      headBorder ? `3px solid ${theme.primary1}` : 'none'};
    color: ${(props) => props.theme.primary1};
    font-weight: bold;
  }
  .div-active {
    //border-bottom: 3px solid ${(props) => props.theme.primary2};
  }
  .label-active {
    color: ${(props) => props.theme.primary2};
    font-weight: bold;
  }

  @media screen and (max-width: 1024px) {
    .overflowY {
      overflow-y: scroll;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0px 16px 0px 16px;

    /* justify-content: space-between; */
  }

  ${(props) => props.inline && 'padding: 0px 5px'};
`
const HeaderMenu = styled.div`
  min-width: 145px;
  height: 57px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #fff;
  //gap: 32px;
  ${({ activeText }) => {
    if (activeText) {
      return css`
        //width: 100%;
        border-bottom: 3px solid ${theme.primary1};
      `
    }
  }}
`
export {
  Container,
  Content,
  Menu,
  Heading,
  Card,
  _Icon as Icon,
  Cardmenu,
  HeaderCard,
  HeaderMenu,
  TabBar,
}
