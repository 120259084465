import React from 'react'
import RandomQuestions from 'components/randomQuestions/loadable'
import RandomAnswers from 'components/randomAnswers/loadable'
import TimerQuestions from 'components/timerQuestions/loadable'
import PeriodQuestions from 'components/periodQuestions/loadable'
import Modal from 'components/modal'
import { Container, Typography, Button } from 'components'

const TestOptionModal = ({ id, subjectId, showTestOptions, handleTestOptions }) => {
  return (
    <Modal
      isOpen={showTestOptions}
      onRequestClose={handleTestOptions}
      contentStyle={{ minWidth: 330 }}
    >
      <Container column="1">
        <Typography center h4 strong>
          Test Options
        </Typography>
        <br />
        <br />
        <PeriodQuestions assessmentId={id} subjectId={subjectId} />
        <TimerQuestions assessmentId={id} subjectId={subjectId} />
        <RandomQuestions assessmentId={id} subjectId={subjectId} />
        <RandomAnswers assessmentId={id} subjectId={subjectId} />
        <br />
        <br />
        <Button type="button" primary full onClick={handleTestOptions}>
          Close
        </Button>
      </Container>
    </Modal>
  )
}

export default TestOptionModal
