/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Typography } from 'components'
import { ContentProgressAPI, UserAPI } from '../../../../api'
import Icon from '../../../../components/icon/loadable'
// icon dark
import IconDarkQuiz from '../../../../images/icons/DarkIcon/Dark-pen.svg'
import IconDarkPDF from '../../../../images/icons/DarkIcon/Dark-pdf.svg'
import IconDarkLecture from '../../../../images/icons/DarkIcon/Dark-lecture.svg'
import IconDarkVDO from '../../../../images/icons/DarkIcon/Dark-VDO.svg'
import IconDarkUploadVDO from '../../../../images/icons/DarkIcon/Dark-uploadVDO.svg'
import IconDarkCircle from '../../../../images/icons/DarkIcon/Dark-circle.svg'
import IconDarkCircleCorrect from '../../../../images/icons/DarkIcon/Dark-circleCorrect.svg'

// icon white
import IconWhiteQuiz from '../../../../images/icons/DarkIcon/White-pen.svg'
import IconWhitePDF from '../../../../images/icons/DarkIcon/White-pdf.svg'
import IconWhiteLecture from '../../../../images/icons/DarkIcon/White-lecture.svg'
import IconWhiteVDO from '../../../../images/icons/DarkIcon/White-VDO.svg'
import IconWhiteUploadVDO from '../../../../images/icons/DarkIcon/White-uploadVDO.svg'
import IconWhiteCircle from '../../../../images/icons/DarkIcon/White-circle.svg'
import IconWhiteCircleCorrect from '../../../../images/icons/DarkIcon/White-circleCorrect.svg'
function LectureItem(props) {
  const {
    history,
    match,
    name,
    id,
    lectureType,
    type,
    isCompleted,

    getLectureProgress,
    isQuestionRequired,
    isTimeRequired,
    onNext,
  } = props
  const { courseId, lectureId } = match?.params
  const [enableCompleteButton, setEnableCompleteButton] = useState(false)
  const [playedSeconds, setPlayedSeconds] = useState(0)
  const [playedPercent, setPlayedPercent] = useState(0)

  const sendContentProgress = async (props, timeSpent) => {
    if (!this.getAllowShowContent(props)) {
      return null
    }
    const { match } = props || this.props
    const isStudent = match.path === '/my-course/courses/:courseId/lecture/:lectureId/'
    const studentId = JSON.parse(localStorage.getItem('accessToken')).userId

    const getTimeByType = (type) => {
      if (type === 'video') {
        return playedSeconds
      }
      return 0
    }

    const getPercentByType = (type) => {
      if (type === 'video') {
        if (isTimeRequired) {
          const minimumCompleteVideo = this.getMinimumCompleteVideo()
          return playedPercent >= minimumCompleteVideo ? 1 : playedPercent
        }
      }
      return 1
    }

    const getIsCompleted = (type) => {
      return getPercentByType(type) === 1
    }

    if (isStudent) {
      const where = JSON.stringify({
        lectureId,
        courseId,
        studentId,
      })
      const data = {
        percent: getPercentByType(type),
        time: getTimeByType(type),
        timeSpent: timeSpent || 0,
        type,
        lectureId,
        courseId,
        studentId,
        isCompleted: getIsCompleted(type),
        where,
      }
      const result = await ContentProgressAPI.upsertWithWhere(data)
      return result
    }
    return null
  }

  const handleClickToLecture = () => {
    history.push(`/my-course/courses/${match?.params?.courseId}/lecture/${id}`)
  }
  const IconDark =
    type === 'quiz'
      ? IconDarkQuiz
      : lectureType === 'browse'
      ? IconDarkUploadVDO
      : lectureType === 'videoLink'
      ? IconDarkVDO
      : lectureType === 'lectureSlide'
      ? IconDarkPDF
      : lectureType === 'paragraph'
      ? IconDarkLecture
      : IconDarkLecture

  const IconWhite =
    type === 'quiz'
      ? IconWhiteQuiz
      : lectureType === 'browse'
      ? IconWhiteUploadVDO
      : lectureType === 'videoLink'
      ? IconWhiteVDO
      : lectureType === 'lectureSlide'
      ? IconWhitePDF
      : lectureType === 'paragraph'
      ? IconWhiteLecture
      : IconWhiteLecture
  const focus = match?.params?.lectureId === id
  const focusColor = focus ? '#fff' : '#2C2B30'

  const logoDark = isCompleted ? IconDarkCircleCorrect : IconDarkCircle
  const logoWhite = isCompleted ? IconWhiteCircleCorrect : IconWhiteCircle
  return (
    <div
      style={{
        border: `1px solid ${focusColor}`,
        borderRadius: '8px',
        backgroundColor: '#2C2B30',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: '88px',
        padding: '12px 16px 12px 16px',
        gap: '8px',
        cursor: 'pointer',
      }}
      onClick={() => {
        handleClickToLecture()
        onNext
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          padding: '4px 0px 0px 0px',
        }}
      >
        <Icon src={focus ? logoWhite : logoDark} size="14" />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          height: '100%',
        }}
      >
        <Typography
          color={focus ? 'white' : 'dark2'}
          style={{ cursor: 'pointer' }}
          numberOfLines={1}
        >
          {name}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon src={focus ? IconWhite : IconDark} size="14" />
          <Typography color={focus ? 'white' : 'dark2'} style={{ cursor: 'pointer' }}>
            {type === 'quiz'
              ? 'แบบทดสอบ'
              : lectureType === 'browse'
              ? 'อัพโหลดวีดีโอ'
              : lectureType === 'videoLink'
              ? 'Link วีดีโอ'
              : lectureType === 'lectureSlide'
              ? 'ไฟล์ PDF'
              : lectureType === 'paragraph'
              ? 'เนื้อหา'
              : 'เนื้อหา'}
          </Typography>
          {/* <Typography color={focus ? 'white' : 'dark2'} style={{ cursor: 'pointer' }}>
            | 30 min
          </Typography> */}
        </div>
      </div>
    </div>
  )
}
const mapDispatch = ({ MyCourse: { getLectureProgress, setModalLecture } }) => ({
  getLectureProgress,
  setModalLecture,
})
export default withRouter(connect(null, mapDispatch)(LectureItem))
