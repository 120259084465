import axios from 'axios'
import get from 'lodash/get'
import { ACCESS_TOKEN } from '../constants'

const baseURL = process.env.REACT_APP_API_URL

axios.defaults.baseURL = baseURL
function buildURLFromTemplate(data, options) {
  if (data instanceof FormData) {
    return {
      data,
      url: options.url,
    }
  }
  let outputData = { ...options.defaultParams, ...data }
  const outputURL = options.url.replace(/\{(.+?)\}/g, (m, label) => {
    const value = outputData[label]
    if (value !== undefined) {
      delete outputData[label]
    } else {
      throw new Error(`Cannot find ${label} in ${options.url}`)
    }
    return value
  })
  if (Array.isArray(data)) {
    outputData = data
  }
  return {
    data: outputData,
    url: outputURL,
  }
}

async function refreshToken(token) {
  const options = {
    method: 'post',
    url: '/AppUsers/refreshToken',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const response = await axios.request(options)
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response.data))
    return response.data
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      e.response = e.response.data.error
      // localStorage.clear()
    }
    throw e
  }
}

export default async (data, options, extraOptions) => {
  const config = {}
  const { data: outputData, url } = buildURLFromTemplate(data, options)
  config.url = url
  switch (options.method) {
    case 'post':
      config.method = 'post'
      config.data = outputData
      break
    case 'get':
      config.method = 'get'
      config.params = outputData
      break
    case 'put':
      config.method = 'put'
      config.data = outputData
      break
    case 'delete':
      config.method = 'delete'
      config.params = outputData
      break
    case 'patch':
      config.method = 'patch'
      config.data = outputData
      break
    default:
      throw new Error('Http method not support')
  }
  // return axios.request(config).then((res) => res.data);
  try {
    // set header
    config.headers = {
      ...options.headers,
    }
    const stringToken = localStorage.getItem(ACCESS_TOKEN)
    if (stringToken) {
      try {
        let token = JSON.parse(stringToken)
        // console.log('token', token)
        if (token.createdAt && Date.now() - token.createdAt >= token.TTL - 120) {
          // call refresh token
          token = await refreshToken(token.refreshToken.id)
          // token = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
          // localStorage.clear()
          // if (!token) {
          //   return Promise.reject(
          //     new Error({ response: { statusCode: 401 }, message: 'token is expired' })
          //   )
          // }
        }
        // check if require refresh token
        config.headers.Authorization = token.id
      } catch (e) {
        delete config.headers.Authorization
        throw e
      }
    }
    const configOptions = { ...config, ...extraOptions }
    const result = await axios.request(configOptions)
    return result.data
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      e.response = e.response.data.error
    }
    // console.log(e.config.url)
    if (
      e.config &&
      e.config.url &&
      (/AppUsers\/me$/.test(e.config.url) || /AppUsers\/userMe$/.test(e.config.url)) &&
      get(e.response, 'code') === 'AUTHORIZATION_REQUIRED'
    ) {
      console.log('401')
      window.location.pathname = '/logout'
    }
    throw e
  }
}
