import styled, { css } from 'styled-components'
import theme from '../../theme'
export const Typography = styled.p`
  display: block;
  line-height: 1.5;
  padding: 0px;
  color: ${({ color, theme }) => (color ? theme[color] : theme.dark)};
  font-size: ${({ theme }) => theme['text-sm']};
  margin-bottom: 0;
  word-break: break-word;
  hyphens: auto;

  svg {
    width: 20px;
    height: 20px;
    position: relative;
    right: ${(props) => (props.reverse ? null : '4px')};
    left: ${(props) => (props.reverse ? '4px' : null)};
    top: 6px;
  }

  img {
    width: 18px;
    height: 18px;
    position: relative;
    right: ${(props) => (props.reverse ? null : '5px')};
    left: ${(props) => (props.reverse ? '5px' : null)};
    /* top:4.5px; */
  }
  ${(props) => {
    if (props.Totalpoint) {
      return css`
        font-weight: bold;
        width: 120px;
        height: 25px;
        font-size: ${theme['text-sm']};
        background: ${theme.primary1};
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        color: ${theme.white};
        @media (min-width: 769px) {
          font-size: ${theme['text-base']}
          width: 161px;
          height: 40px;
        }
        @media (max-width: 768px) {
          font-size: ${theme['text-base']}
          width: 58px;
          height: 34px;
        }
      `
    }
  }}
  ${({ hoverText }) => {
    if (hoverText) {
      return css`
        color: ${theme.primary};
        :hover {
          text-decoration: underline;
        }
      `
    }
  }}
  ${({ hoverTextNoColor }) => {
    if (hoverTextNoColor) {
      return css`
        :hover {
          text-decoration: underline;
        }
      `
    }
  }}
  ${({ hoverColor }) => {
    if (hoverColor) {
      return css`
        :hover {
          color: ${({ theme }) => theme[hoverColor]};
          cursor: pointer;
        }
      `
    }
  }}

  ${({ margin }) => css`
    margin: ${margin};
  `}

  ${({ padding }) => css`
    padding: ${padding};
  `}

  ${({ width }) => css`
    width: ${width};
  `}
  ${({ minWidth }) => css`
    min-width: ${minWidth};
  `}

  ${({ maxWidth }) => css`
    max-width: ${maxWidth};
  `}

  ${({ align }) => css`
    text-align: ${align};
  `}
${({ textAlign }) => {
    if (textAlign === 'center') {
      return css`
        text-align: center;
      `
    } else if (textAlign === 'left') {
      return css`
        text-align: left;
      `
    } else if (textAlign === 'right') {
      return css`
        text-align: right;
      `
    } else {
      return css``
    }
  }}
  ${({ numberOfLines }) => {
    if (numberOfLines) {
      return css`
        display: -webkit-box;
        -webkit-line-clamp: ${numberOfLines};
        -webkit-box-orient: vertical;
        overflow: hidden;
      `
    }
  }}

  ${({
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    small,
    label,
    label2,
    label3,
    paragraph,
    error,
    title,
    title1,
    title2,
    schoolTitle,
    schoolSeemore,
    schoolReportTitle,
    schoolReportDescription,
    subtitle,
    nonebold,
    textcer,
    iconCer,
    courseTeacher,
    titleLanding,
    descLanding,
    gradient,
  }) => {
    if (h1)
      return css`
        font-size: ${({ theme }) => theme['text-xxl']};
        font-weight: bold;
        color: ${(props) => props.theme.primary1};

        @media (max-width: 425px) {
          font-size: ${({ theme }) => theme['text-7xl']};
        }
      `
    if (h2)
      return css`
        font-size: ${({ theme }) => theme['text-6xl']};
        font-weight: 600;
        color: ${(props) => props.theme.primary1};
        @media (max-width: 425px) {
          font-size: ${({ theme }) => theme['text-3xl']};
        }
      `
    if (h3)
      return css`
        font-size: ${({ theme }) => theme['text-2xl']};
        font-weight: 600;
        color: ${(props) => props.theme.dark};
        @media (max-width: 425px) {
          font-size: ${({ theme }) => theme['text-xl']};
        }
      `
    if (h4)
      return css`
        font-size: ${({ theme }) => theme['text-xl']};
        font-weight: 600;
        color: ${(props) => props.theme.primary1};
        @media (max-width: 425px) {
          font-size: ${({ theme }) => theme['text-lg']};
        }
      `
    if (h5)
      return css`
        font-size: ${({ theme }) => theme['text-lg']};
        color: ${(props) => props.theme.primary1};
      `
    if (h6)
      return css`
        font-size: ${({ theme }) => theme['text-xs']};
        color: ${(props) => props.theme.dark};
      `
    if (small)
      return css`
        font-size: ${({ theme }) => theme['text-xxs']};
        color: ${(props) => props.theme.gray2};
      `
    if (label)
      return css`
        font-size: ${({ theme }) => theme['text-sm']};
        font-weight: 600;
        color: ${(props) => props.theme.dark};
      `
    if (label2)
      return css`
        font-size: ${({ theme }) => theme['text-sm']};
        color: ${(props) => props.theme.gray2};
      `
    if (label3)
      return css`
        font-size: ${({ theme }) => theme['text-sm']};
        color: ${(props) => props.theme.gray6};
      `
    if (title)
      return css`
        font-size: ${({ theme }) => theme['text-lg']};
        color: ${(props) => props.theme.dark};
      `
    if (title1)
      return css`
        font-size: ${({ theme }) => theme['text-xl']};
        color: ${(props) => props.theme.dark};
        font-weight: 600;
      `
    if (title2)
      return css`
        font-size: ${({ theme }) => theme['text-lg']};
        color: ${(props) => props.theme.dark};
        font-weight: 600;
      `
    if (schoolSeemore) {
      return css`
        font-size: ${({ theme }) => theme['text-xl']};
        color: ${(props) => props.theme.primary1};
        @media (max-width: 425px) {
          font-size: ${({ theme }) => theme['text-sm']};
        }
      `
    }
    if (schoolTitle) {
      return css`
        font-size: ${({ theme }) => theme['text-2xl']};
        color: ${(props) => props.theme.primary1};
        font-weight: 600;
        @media (max-width: 425px) {
          font-size: ${({ theme }) => theme['text-lg']};
        }
      `
    }
    if (schoolReportTitle) {
      return css`
        font-size: ${({ theme }) => theme['text-lg']};
        color: ${(props) => props.theme.primary1};
        font-weight: 600;

        @media (max-width: 425px) {
          font-size: ${({ theme }) => theme['text-base']};
        }
      `
    }
    if (schoolReportDescription) {
      return css`
        font-size: ${({ theme }) => theme['text-base']};

        @media (max-width: 425px) {
          font-size: ${({ theme }) => theme['text-xs']};
        }
      `
    }
    if (subtitle)
      return css`
        font-size: ${({ theme }) => theme['text-lg']};
        color: ${(props) => props.theme.gray6};
      `
    if (paragraph)
      return css`
        font-size: ${({ theme }) => theme['text-sm']};
        color: ${(props) => props.theme.gray1};
      `
    if (error)
      return css`
        font-size: ${({ theme }) => theme['text-xs']};
        color: ${(props) => props.theme.danger};
        line-height: 20px;
      `
    if (nonebold)
      return css`
        font-size: ${({ theme }) => theme['text-base']};
      `
    if (textcer)
      return css`
        height: 30px;
        margin-top: 3px;
        font-size: ${({ theme }) => theme['text-xs']};
        padding: 6px 8px 6px 8px;
        background-color: ${(props) => props.theme.whiteblue};
        border-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `
    if (iconCer)
      return css`
        :hover {
          color: ${(props) => props.theme.darkorange};
        }
      `
    if (courseTeacher)
      return css`
        font-size: ${({ theme }) => theme['text-s']};
        color: ${(props) => props.theme.primary1};
      `
    if (titleLanding)
      return css`
        font-size: ${({ theme }) => theme['text-lg']};
        color: ${(props) => props.theme.primary1};
      `
    if (descLanding)
      return css`
        font-size: ${({ theme }) => theme['text-base']};
        color: ${(props) => props.theme.primary1};
      `
    if (gradient)
      return css`
        background: -webkit-linear-gradient(360deg, #6c51fe 3.11%, #40b1ff 100.44%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `
    return css``
  }}

  ${({ left, center, right }) => {
    if (left) {
      return css`
        text-align: left;
      `
    }
    if (center) {
      return css`
        text-align: center;
      `
    }
    if (right) {
      return css`
        text-align: right;
      `
    }
    return css``
  }}

  ${({ strong, thin, bold, superbold }) => {
    if (strong) {
      return css`
        font-weight: 500;
      `
    }

    if (thin) {
      return css`
        font-weight: 300;
      `
    }

    if (bold) {
      return css`
        font-weight: 600;
      `
    }
    if (superbold) {
      return css`
        font-weight: 700;
      `
    }
    return css``
  }}

  ${({ underline }) => {
    if (underline)
      return css`
        text-decoration: underline;
      `
  }}

  ${({ fontSize, theme }) => {
    if (fontSize) {
      return css`
        font-size: ${theme[fontSize] || theme['text-sm']};
      `
    }
  }}

  ${({ lineHeight }) => {
    if (lineHeight) {
      return css`
        line-height: ${lineHeight};
      `
    }
  }}


  ${({ fontWeight }) => {
    if (fontWeight) {
      return css`
        font-weight: ${fontWeight};
      `
    }
  }}

  ${({ color, theme }) => {
    if (color) {
      return css`
        color: ${theme[color]};
      `
    }
  }}

  ${({ inherit }) => {
    if (inherit)
      return css`
        color: inherit;
        line-height: inherit;
      `
  }}

  ${({ flex }) => {
    if (flex)
      return css`
        display: flex;
        align-items: center;
      `
  }}

  ${({ nowrap }) => {
    if (nowrap)
      return css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `
  }}

  ${({ wrap }) => {
    if (wrap)
      return css`
        white-space: nowrap;
      `
  }}

  ${({ pointer }) => {
    if (pointer) {
      return css`
        cursor: pointer;
      `
    }
  }}
`

export const Container = styled.div`
  padding: 20px;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 20px;
`

export const WithNoti = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  min-width: 135px;

  ${(props) => {
    if (props.minWidth) {
      return css`
        min-width: ${props.minWidth}px;
      `
    }
    return ``
  }}
`
export const BadgeContainer = styled.div`
  margin-left: 9px;
`
export const MiniBadge = styled.div`
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${theme.primary1};
  border-radius: 12px;
  width: 32px;
  height: 20px;
  font-size: 12px;
  margin-bottom: 3px;
  /* bottom: 8px;
  right: 8px; */
`
