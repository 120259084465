import React from 'react'
import { Steps } from 'intro.js-react'
import ErrorBoundary from '../errorBoundary'
import IntroContent from './content'

export { IntroContent }

const Intro = ({
  enabled,
  steps,
  onExit,
  initialStep = 0,
  options,
  locale,
  onChange,
  onBeforeExit,
  onStart,
}) => {
  const isTh = locale === 'th'

  return (
    <ErrorBoundary>
      <Steps
        enabled={enabled}
        steps={steps}
        onExit={onExit}
        initialStep={initialStep}
        options={{
          showStepNumbers: true,
          showBullets: false,
          exitOnOverlayClick: true,
          exitOnEsc: true,
          keyboardNavigation: false,
          disableInteraction: true,
          hidePrev: true,
          hideNext: true,
          nextLabel: isTh ? 'ถัดไป' : 'Next',
          prevLabel: isTh ? 'ย้อนกลับ' : 'Back',
          doneLabel: isTh ? 'เข้าใจแล้ว' : 'Done',
          ...options,
        }}
        onBeforeChange={onChange}
        onBeforeExit={onBeforeExit}
        onStart={onStart}
      />
    </ErrorBoundary>
  )
}

export default Intro
