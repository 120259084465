import getFilter from '../../utils/filterBrowseSchool'
import { SchoolAPI } from '../../api'
import moment from 'moment'

export const School = {
  state: {
    school: {},
    mySchoolFeed: {},
    RecommendedCourses: [],
    AnnouncementSchoolFeeds: [],
    schoolCoursesCount: 0,
    schoolCourses: [],
    schoolTags: [],
    schoolTransfers: [],
    schoolTransfersCount: 0,
    schoolTransferDetail: null,
    schoolProfile: null,
    isNameExists: false,
    dashboardGraphData: [],
  },
  reducers: {
    setSchoolEditPost(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        const checkId = payload.id === item.id
        return {
          ...item,
          richMessage:
            checkId && payload.richMessage !== item.richMessage
              ? payload.richMessage
              : item.richMessage,
          media: checkId && payload.media !== item.media ? payload.media : item.media,
        }
      })

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolEditCommentInformation(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return payload.id === reply.id ? { ...reply, richMessage: payload.richMessage } : reply
          }),
        }
      })
      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolEditCommentLevelOneInformation(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return {
              ...reply,
              comments: reply.comments.map((comment) => {
                return payload.id === comment.id
                  ? { ...comment, richMessage: payload.richMessage }
                  : comment
              }),
            }
          }),
        }
      })
      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setDeleteSchoolPost(state, payload) {
      const newState = state.mySchoolFeed.feeds.filter((item) => item.id !== payload.id)

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setDeleteSchoolComment(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.filter((reply) => reply.id !== payload.id),
        }
      })

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },

    setDeleteSchoolCommentLevelOne(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return {
              ...reply,
              comments: reply.comments.filter((comment) => comment.id !== payload.id),
            }
          }),
        }
      })
      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setRecommendedCourses(state, payload) {
      return {
        ...state,
        RecommendedCourses: payload,
      }
    },
    setSchoolList(state, payload) {
      return {
        ...state,
        schoolList: payload,
      }
    },
    setAnnouncementSchoolFeeds(state, payload) {
      return {
        ...state,
        AnnouncementSchoolFeeds: {
          feeds: payload.feeds,
          total: payload.total,
        },
      }
    },
    setLikeSchoolAnnouncementPost(state, payload) {
      const newState = state.AnnouncementSchoolFeeds.feeds.map((item) => {
        if (item.id === payload) {
          return {
            ...item,
            isMyLike: !item.isMyLike,
            countLikes: item.isMyLike ? item.countLikes - 1 : item.countLikes + 1,
          }
        }
        return item
      })

      return {
        ...state,
        AnnouncementSchoolFeeds: {
          feeds: newState,
          total: 1,
        },
      }
    },
    setMySchoolFeed(state, payload) {
      return {
        ...state,
        mySchoolFeed: {
          feeds: payload.feeds,
          total: payload.total,
        },
      }
    },
    setMoreMySchoolFeedList(state, payload) {
      return {
        ...state,
        mySchoolFeed: {
          feeds: [...state.mySchoolFeed.feeds, ...payload],
          total: state.mySchoolFeed.total,
        },
      }
    },
    setLikeSchoolPost(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        if (item.id === payload) {
          return {
            ...item,
            isMyLike: !item.isMyLike,
            countLikes: item.isMyLike ? item.countLikes - 1 : item.countLikes + 1,
          }
        }
        return item
      })

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolLikePost(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        if (item.id === payload) {
          return {
            ...item,
            isSchoolLike: !item.isSchoolLike,
            countLikes: item.isSchoolLike ? item.countLikes - 1 : item.countLikes + 1,
          }
        }
        return item
      })

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setLikeSchoolComment(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return reply.id === payload
              ? {
                  ...reply,
                  isMyLike: !reply.isMyLike,
                  countLikes: reply.isMyLike ? reply.countLikes - 1 : reply.countLikes + 1,
                }
              : reply
          }),
        }
      })

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolLikeComment(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return reply.id === payload
              ? {
                  ...reply,
                  isSchoolLike: !reply.isSchoolLike,
                  countLikes: reply.isSchoolLike ? reply.countLikes - 1 : reply.countLikes + 1,
                }
              : reply
          }),
        }
      })

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setLikeSchoolReply(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return {
              ...reply,
              comments: reply.comments.map((comment) => {
                return comment.id === payload
                  ? {
                      ...comment,
                      isMyLike: !comment.isMyLike,
                      countLikes: comment.isMyLike
                        ? comment.countLikes - 1
                        : comment.countLikes + 1,
                    }
                  : comment
              }),
            }
          }),
        }
      })

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolLikeReply(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return {
              ...reply,
              comments: reply.comments.map((comment) => {
                return comment.id === payload
                  ? {
                      ...comment,
                      isSchoolLike: !comment.isSchoolLike,
                      countLikes: comment.isSchoolLike
                        ? comment.countLikes - 1
                        : comment.countLikes + 1,
                    }
                  : comment
              }),
            }
          }),
        }
      })

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolComment(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.id === payload.feedId ? [...item.comments, payload] : item.comments,
        }
      })

      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolCommentReply(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: (item.comments || []).map((reply) => {
            return {
              ...reply,
              comments:
                reply.id === payload.feedId
                  ? [...(reply.comments || []), payload]
                  : reply.comments || [],
            }
          }),
        }
      })
      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolEditComment(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return payload.id === reply.id ? { ...reply, richMessage: payload.richMessage } : reply
          }),
        }
      })
      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolEditCommentLevelOne(state, payload) {
      const newState = state.mySchoolFeed.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return {
              ...reply,
              comments: reply.comments.map((comment) => {
                return payload.id === comment.id
                  ? { ...comment, richMessage: payload.richMessage }
                  : comment
              }),
            }
          }),
        }
      })
      return {
        ...state,
        mySchoolFeed: {
          feeds: newState,
          total: state.mySchoolFeed.total,
        },
      }
    },
    setSchoolCoursesCount(state, payload) {
      return {
        ...state,
        schoolCoursesCount: payload,
      }
    },
    setSchoolCourse(state, payload) {
      return {
        ...state,
        schoolCourses: payload,
      }
    },
    setSchoolListCount(state, payload) {
      return {
        ...state,
        setSchoolListCount: payload,
      }
    },
    setSchool(state, payload) {
      return {
        ...state,
        school: payload,
      }
    },
    setSchoolTags(state, payload) {
      return {
        ...state,
        schoolTags: payload,
      }
    },
    setSchoolTransfers(state, payload) {
      return {
        ...state,
        schoolTransfers: payload,
      }
    },
    setSchoolTransfersCount(state, payload) {
      return {
        ...state,
        schoolTransfersCount: payload,
      }
    },
    setSchoolTransferDetail(state, payload) {
      return {
        ...state,
        schoolTransferDetail: payload,
      }
    },
    setSchoolProfile(state, payload) {
      return {
        ...state,
        schoolProfile: payload,
      }
    },
    setIsNameExists(state, payload) {
      return {
        ...state,
        isNameExists: payload,
      }
    },
    setDashboardGraphData(state, payload) {
      return {
        ...state,
        dashboardGraphData: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async updateSchoolFeed(payload) {
      try {
        await SchoolAPI.updateSchoolFeed(payload)
      } catch (error) {
        throw error
      }
    },
    async deleteSchoolFeed({ schoolId, feedId }) {
      try {
        await SchoolAPI.deleteSchoolFeed({ id: schoolId, fk: feedId })
      } catch (error) {
        throw error
      }
    },
    async getAnnouncementSchoolFeeds({ feedType, schoolId, limit }) {
      const skip = 0
      try {
        const getFilter = JSON.stringify({
          limit,
          skip,
          order: 'createdAt DESC',
          where: {
            feedType,
            feedId: {
              exists: false,
            },
          },
          include: [
            {
              relation: 'school',
            },
          ],
        })
        const res = await SchoolAPI.getSchoolFeed({ filter: getFilter, id: schoolId })
        const result = { feeds: res, total: 1 }
        this.setAnnouncementSchoolFeeds(result)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getSchoolFeeds(payload) {
      const skip = 0
      try {
        const getFilter = JSON.stringify({
          limit: payload?.limit,
          skip,
          order: 'createdAt DESC',
          where: {
            feedType: payload?.feedType,
            feedId: {
              exists: false,
            },
          },
          include: [
            {
              relation: 'school',
            },
            {
              relation: 'comments',
              scope: {
                include: [
                  {
                    relation: 'appUser',
                  },
                  {
                    relation: 'school',
                  },
                  {
                    relation: 'comments',
                    scope: {
                      include: [
                        {
                          relation: 'appUser',
                        },
                        {
                          relation: 'school',
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        })
        const countsFilter = JSON.stringify({
          feedType: payload?.feedType,
          feedId: {
            exists: false,
          },
        })
        const res = await SchoolAPI.getSchoolFeed({ filter: getFilter, id: payload?.schoolId })
        const counts = await SchoolAPI.getSchoolCountFeed({
          where: countsFilter,
          id: payload?.schoolId,
        })
        const result = { feeds: res, total: counts.count }
        this.setMySchoolFeed(result)
      } catch (e) {
        console.log(e)
        throw e
      }
    },

    async getMoreSchoolFeeds({ skip = 0, limit = 5, schoolId, feedType }) {
      try {
        const getFilter = JSON.stringify({
          limit,
          skip,
          order: 'createdAt DESC',
          where: {
            feedType,
            feedId: {
              exists: false,
            },
          },
          include: [
            {
              relation: 'school',
            },
            {
              relation: 'comments',
              scope: {
                include: [
                  {
                    relation: 'appUser',
                  },
                  {
                    relation: 'school',
                  },
                  {
                    relation: 'comments',
                    scope: {
                      include: [
                        {
                          relation: 'appUser',
                        },
                        {
                          relation: 'school',
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        })
        const res = await SchoolAPI.getSchoolFeed({ filter: getFilter, id: schoolId })
        this.setMoreMySchoolFeedList(res)
      } catch (e) {
        throw e
      }
    },
    async getSchoolList(payload) {
      const { params, limit, skip } = payload
      const filterObj = { ...getFilter(params), order: 'createdAt DESC', limit, skip }
      const filter = JSON.stringify(filterObj)
      const schools = await SchoolAPI.getBrowseSchool({ filter })
      this.setSchoolList(schools)
      return schools
    },
    async getSchoolListCount(payload) {
      const { params } = payload
      const filterObj = { ...getFilter(params) }
      const where = filterObj.where ? JSON.stringify(filterObj.where) : null
      const count = await SchoolAPI.getBrowseSchoolCount({ where })
      this.setSchoolListCount(count)
      return count
    },
    async postMySchoolFeed(payload) {
      try {
        const result = await SchoolAPI.postSchoolFeed({ id: payload.schoolId, ...payload })
        return result
      } catch (e) {
        throw e
      }
    },
    async postSchoolLike({ schoolId, feedId }) {
      try {
        await SchoolAPI.postSchoolLike({ id: schoolId, feedId })
      } catch (err) {
        throw err
      }
    },
    async getSchoolById(schoolId) {
      const filter = JSON.stringify({
        include: [
          {
            relation: 'courses',
            scope: {
              where: {
                publicMode: 'PUBLIC',
              },
              include: ['teachers', 'subCategory'],
            },
          },
          {
            relation: 'schoolStudents',

            scope: {
              limit: 3,
              include: 'appUser',
            },
          },
        ],
      })
      const school = await SchoolAPI.findById({ id: schoolId, filter })
      this.setSchool(school)
      return school
    },
    async getSchoolBySlug(schoolSlug) {
      const filter = JSON.stringify({
        include: [
          {
            relation: 'courses',
            scope: {
              where: {
                publicMode: 'PUBLIC',
              },
              include: 'teachers',
            },
          },
        ],
        where: {
          slug: schoolSlug,
        },
      })
      const school = await SchoolAPI.findOne({ filter })
      this.setSchool(school)
      return school
    },
    async updateSchoolTags(payload) {
      try {
        await SchoolAPI.updateSchoolTags(payload)
        dispatch.Alert.success({ title: 'Update Successfully' })
      } catch (e) {
        throw e
      }
    },
    async getSchoolTags(id) {
      try {
        const schoolTags = await SchoolAPI.getSchoolTags({ id })
        this.setSchoolTags(schoolTags)
        return schoolTags
      } catch (e) {
        throw e
      }
    },
    async getSchoolTransfers(payload) {
      const filter = JSON.stringify({
        order: payload?.order || 'updatedAt DESC',
        limit: payload?.limit || 0,
        skip: payload?.skip || 0,
      })
      try {
        const result = await SchoolAPI.getSchoolTransfers({ id: payload.schoolId, filter })
        this.setSchoolTransfers(result)
        return result
      } catch (error) {
        throw error
      }
    },
    async getSchoolTransfersCount({ schoolId }) {
      try {
        const result = await SchoolAPI.getSchoolTransfersCount({ id: schoolId })
        this.setSchoolTransfersCount(result.count)
        return result
      } catch (error) {
        throw error
      }
    },
    async getSchoolTransferDetail(payload) {
      const filter = JSON.stringify({
        order: payload?.order || 'createdAt DESC',
        limit: payload?.limit || 0,
        skip: payload?.skip || 0,
      })
      try {
        const result = await SchoolAPI.getSchoolTransferDetail({
          id: payload.schoolId,
          schoolTransferId: payload.transferId,
          filter,
        })
        this.setSchoolTransferDetail(result)
        return result
      } catch (error) {
        throw error
      }
    },
    async getSchoolCourseReport(id) {
      try {
        await SchoolAPI.getSchoolCourseReport({ id })
      } catch (e) {
        throw e
      }
    },
    async getRecommendedCourses(payload) {
      const filter = JSON.stringify({
        limit: 3,
        skip: 0,
        include: ['teachers', 'subCategory'],
        where: {
          publicMode: 'PUBLIC',
        },
      })
      const result = await SchoolAPI.getSchoolCourse({
        id: payload.id,
        filter: filter,
      })
      this.setRecommendedCourses(result)
      return result
    },
    async getSchoolCourse(payload) {
      const where = { publicMode: 'PUBLIC' }
      if (payload.searchedWord) {
        const or = [
          {
            name: {
              like: payload?.searchedWord,
              options: 'i',
            },
          },
          {
            description: {
              like: payload?.searchedWord,
              options: 'i',
            },
          },
          {
            shortDescription: {
              like: payload?.searchedWord,
              options: 'i',
            },
          },
        ]
        where.or = or
      }

      const filter = JSON.stringify({
        order: payload?.order,
        limit: payload?.limit,
        skip: payload?.skip,
        include: ['teachers', 'subCategory'],
        where: where,
      })
      const result = await SchoolAPI.getSchoolCourse({
        id: payload.id,
        filter: filter,
      })
      this.setSchoolCourse(result)
      return result
    },
    async getSchoolCoursesCount(id) {
      const result = await SchoolAPI.countCourse({
        id,
        where: {
          publicMode: 'PUBLIC',
        },
      })
      this.setSchoolCoursesCount(result.count)
      return result
    },
    async getSchoolProfile(id) {
      const result = await SchoolAPI.getSchoolProfile({ id })
      this.setSchoolProfile(result)
      return result
    },

    async updateSchoolProfile(payload) {
      try {
        await SchoolAPI.updateSchoolProfile(payload)
        await this.getSchoolProfile(payload.id)
      } catch (e) {
        throw e
      }
    },
    async checkSchoolNameExists(payload) {
      try {
        const result = await SchoolAPI.checkSchoolNameExists(payload)
        this.setIsNameExists(result.exists)
        return result
      } catch (e) {
        throw e
      }
    },
    async getDashboardGraphData({ schoolId, period }) {
      let params = {}
      switch (period) {
        case '1m':
          params = {
            start: moment().subtract(1, 'months').toISOString(),
            end: moment().toISOString(),
          }
          break
        case '3m':
          params = {
            start: moment().subtract(3, 'months').toISOString(),
            end: moment().toISOString(),
          }
          break
        case '6m':
          params = {
            start: moment().subtract(6, 'months').toISOString(),
            end: moment().toISOString(),
          }
          break
        case 'year':
          params = {
            start: moment().subtract(1, 'years').toISOString(),
            end: moment().toISOString(),
          }
          break
        // case 'week'
        default:
          params = {
            start: moment().subtract(1, 'weeks').toISOString(),
            end: moment().toISOString(),
          }
          break
      }

      try {
        const result = await SchoolAPI.getSchoolDashboardGraph({ id: schoolId, ...params })
        // console.log('result', result)
        this.setDashboardGraphData(result)
        return result
      } catch (e) {
        throw e
      }
    },
  }),
}
