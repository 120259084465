import Resource from '../utils/resource'

export default new Resource('/DraftAssignments', {
  publishAssignment: {
    url: '{id}/assignment',
    method: 'post',
  },
  cloneDraftAssignment: {
    url: '{id}/clone',
    method: 'post',
  },
})
