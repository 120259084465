import React, { useState } from 'react'
import styled from 'styled-components'
import ErrorBoundary from 'components/errorBoundary'
//import { Typography } from 'components'
import ShareModal from './ShareModal'
//import { useWindowSize } from 'react-use'
import shareIcon from '../../images/icons/Share.svg'
//import { LargeDesktop } from '../../utils/displayResponsive'
const Container = styled.div`
  // width: 100%;
  display: flex;
  cursor: pointer;
  gap: 10px;
  /* margin-right: 20px; */
  @media (max-width: 1024px) {
    margin-right: 0px;
    gap: 0px;
  }
`
const ShareIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const Share = ({ courseSlug, locale }) => {
  //const { width } = useWindowSize()
  const [openModal, setOpenModal] = useState(false)
  const url = `https://${window.location.host}/courses/${courseSlug}`
  // test url
  // const url = `https://dev.new-client.classwin.dev.witsawa.com/courses/${courseSlug}`

  return (
    <ErrorBoundary>
      <Container onClick={() => setOpenModal(true)}>
        <ShareIcon src={shareIcon} />
        {/* <LargeDesktop>
          <Typography
            id="course.detail.share"
            color="primary1"
            fontSize="text-sm"
            fontWeight={600}
            numberOfLines={1}
          >
            Share
          </Typography>
        </LargeDesktop> */}
      </Container>
      <ShareModal isOpen={openModal} url={url} setOpenModal={setOpenModal} locale={locale} />
    </ErrorBoundary>
  )
}

export default Share
