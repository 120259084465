import React from 'react'
import PropTypes from 'prop-types'
import Input from '../input'
import Label from '../label'

import Container from './style'

function InputWithLabel({ translateId, label, htmlFor, labelFontSize, ...props }) {
  return (
    <Container>
      <Label
        id={translateId}
        htmlFor={htmlFor}
        fontSize={labelFontSize}
        color={props.color}
        bold={props.bold}
      >
        {label}
      </Label>
      <Input {...props} />
    </Container>
  )
}

InputWithLabel.propTypes = {
  label: PropTypes.string,
}

InputWithLabel.defaultProps = {
  label: 'default',
}

export default InputWithLabel
