import * as Yup from 'yup'
import moment from 'moment'

const validationDate = (field) => {
  return Yup.date()
    .required('Required')
    .test({
      exclusive: false,
      message: 'Date is invalid',
      params: {
        reference: Yup.ref(field).path,
      },
      test(value) {
        const startDate = value
        const endDate = this.resolve(Yup.ref(field))
        return moment(startDate).isSameOrBefore(endDate, 'date')
      },
    })
}
export const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  slug: Yup.string()
    .matches(/^[\u0E00-\u0E7Fa-zA-Z0-9'\w-]+$/, { message: 'Invalid format' })
    .required('Required'),
  shortDescription: Yup.string().required('Required'),
  courseCode: Yup.string()
    .required('Required')
    .matches(/[\w-]+$/, 'Only alphabets are allowed for this field'),
  // image: Yup.string().required('Required'),
  description: Yup.string()
    .required('Required') // ใช้ไม่ได้เพราะมี value คือ <tag>
    .notOneOf([`{"content":"<p><br></p>","delta":{"ops":[{"insert":"\n"}]}}`], {
      message: 'Required',
    }),

  category: Yup.object()
    .shape({
      id: Yup.string(),
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Required'),
  subcategory: Yup.object()
    .shape({
      id: Yup.string(),
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Required'),
  level: Yup.string(),
  // .min(1, 'Required')
  // .required('Required'),
  objective: Yup.array()
    // .min(1)
    .of(
      Yup.string()
        // .min(1)
        .notRequired()
    ),

  target: Yup.array()
    // .min(1)
    .of(
      Yup.string()
        // .min(1)
        .notRequired()
    ),
  requirement: Yup.array()
    // .min(1)
    .of(
      Yup.string()
        // .min(1)
        .notRequired()
    ),
  type: Yup.object(),
  registerStartDate: Yup.date().when('type', {
    is: (val) => val.value !== 'online',
    then: validationDate('registerEndDate'),
    otherwise: Yup.date().notRequired(),
  }),
  registerEndDate: Yup.date().when('type', {
    is: (val) => {
      //   console.log('registerEndDate', val)
      return val.value !== 'online'
    },
    then: Yup.date().required('Required'),
    otherwise: Yup.date().notRequired(),
  }),
  maxStudent: Yup.string(),
})

export const validationSchemaWithoutSlug = Yup.object().shape({
  teachers: Yup.array().of(Yup.string().required('Require')).required('Require'),

  title: Yup.string().required('Require'),
  shortDescription: Yup.string().required('Require'),
  courseCode: Yup.string().required('Require'),
  level: Yup.string(),
  description: Yup.string().required('Required'),
  // image: Yup.string().required('Required'),
  ageType: Yup.object()
    .default(undefined)
    .nullable()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Required'),
  category: Yup.object()
    .default(undefined)
    .nullable()
    .shape({
      id: Yup.string(),
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Required'),

  subcategory: Yup.object()
    .default(undefined)
    .nullable()
    .shape({
      id: Yup.string(),
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Required'),

  // .min(1, 'Required')
  // .required('Required'),
  objective: Yup.array()
    // .min(1)
    .of(
      Yup.string()
        // .min(1)
        .notRequired()
    ),

  target: Yup.array()
    // .min(1)
    .of(
      Yup.string()
        // .min(1)
        .notRequired()
    ),
  requirement: Yup.array()
    // .min(1)
    .of(
      Yup.string()
        // .min(1)
        .notRequired()
    ),
  type: Yup.object(),
  registerStartDate: Yup.date().when('type', {
    is: (val) => val.value !== 'online',
    then: validationDate('registerEndDate'),
    otherwise: Yup.date().notRequired(),
  }),
  registerEndDate: Yup.date().when('type', {
    is: (val) => {
      //   console.log('registerEndDate', val)
      return val.value !== 'online'
    },
    then: Yup.date().required('Required'),
    otherwise: Yup.date().notRequired(),
  }),
  maxStudent: Yup.string(),
})
