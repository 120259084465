import React, { useCallback, useEffect, useState } from 'react'

import TextInput from '../../../../../components/textInput'
import DrawingAnswer from './DrawingAnswer'
import FakeModal from 'components/fakeModal'
import { Button, Typography } from 'components'
import { fabric } from 'fabric'
import getImageUrl from 'utils/getImageUrl'
import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import * as yup from 'yup'
import urltoFile from 'utils/urlToFile'
import { FileAPI } from 'api'
import reactImageSize from 'react-image-size'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components'

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`
const Title = styled(Typography)`
  font-size: 18px;
  margin-bottom: 10px;
  @media (max-width: 820px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 426px) {
    font-size: 16px;
  }
`
const DrawingModal = ({
  isOpen,
  onClose,
  image,
  drawingToggle,
  locale,
  feedId,
  courseId,
  postComment,
  appUser,
  setComment,
  setCommentReply,
  isReply,
  userFirstname,
  profileImage,
}) => {
  const [canvas, setCanvas] = useState()
  const { id: userId } = appUser
  const validationSchema = yup.object().shape({
    caption: yup.string().required(locale === 'th' ? 'จำเป็น' : 'Required'),
  })
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const initCanvas = () => {
    return new fabric.Canvas('feed-drawing', {
      backgroundColor: '#FFF',
      selection: false,
      controlsAboveOverlay: true,
      centeredScaling: true,
      allowTouchScrolling: true,
    })
  }
  const getDimensionImg = async () => {
    const { width, height } = await reactImageSize(getImageUrl(image))
    setWidth(width)
    setHeight(height)
  }

  const initImage = useCallback(
    async (canvas) => {
      const url = getImageUrl(image)
      const response = await fetch(url)

      fabric.Image.fromURL(
        response.url,
        function (img) {
          img.set({ left: 0, top: 0 }).scale(1)
          canvas?.add(img)
          canvas?.centerObject(img)
          canvas?.renderAll()
        },
        { crossOrigin: 'Anonymous' }
      )
    },
    [image]
  )
  useEffectOnce(() => {
    getDimensionImg()
  })
  useEffect(() => {
    setCanvas(initCanvas())
  }, [isOpen])

  useEffect(() => {
    initImage(canvas)
  }, [canvas, initImage])

  const onSubmit = async (value) => {
    const base64 = canvas.discardActiveObject().toDataURL()
    const file = await urltoFile(base64, 'image.jpg', 'image/jpg')
    const formData = new FormData()
    formData.append('file', file)
    const fileRes = await FileAPI.upload(formData)
    let obj
    if (courseId) {
      obj = {
        richMessage: JSON.stringify({
          content: `<p>${value?.caption}</p>`,
          delta: { ops: [{ insert: `${value?.caption}\n` }] },
        }),
        media: [
          {
            name: fileRes.result.files.file[0].name,
            originalFilename: fileRes.result.files.file[0].originalFilename,
          },
        ],
        appUserId: userId,
        courseId,
        feedId,
      }
    } else {
      obj = {
        richMessage: JSON.stringify({
          content: `<p>${value?.caption}</p>`,
          delta: { ops: [{ insert: `${value?.caption}\n` }] },
        }),
        media: [
          {
            name: fileRes.result.files.file[0].name,
            originalFilename: fileRes.result.files.file[0].originalFilename,
          },
        ],
        appUserId: userId,
        feedId,
      }
    }
    const res = await postComment(obj)
    isReply
      ? setCommentReply({ isMyLike: false, appUser, ...res })
      : setComment({
          isMyLike: false,
          appUser,
          ...res,
        })
    onClose()
  }
  return (
    <FakeModal isOpen={isOpen} onClickClose={onClose} shownXclose>
      <Title color="primary1" bold>
        {locale === 'en' ? 'Answer this post' : 'ตอบกลับโพสต์นี้'}
      </Title>
      <div style={height > 400 ? { overflow: 'scroll', height: 400 } : undefined}>
        <canvas id={'feed-drawing'} width={width} height={height} />
      </div>
      <DrawingAnswer canvas={canvas} toggle={drawingToggle} />
      <Formik
        initialValues={{ caption: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => {
          const { values, errors, handleChange, isSubmitting } = props
          return (
            <>
              <Form>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Avatar src={getImageUrl(profileImage)} alt={userFirstname} />
                  <div style={{ flex: '1 1 auto' }}>
                    <TextInput
                      type="text"
                      name="caption"
                      value={values.caption}
                      error={errors.caption}
                      hideLabel
                      // id="login.change.password"
                      placeholder={
                        locale === 'en' ? 'Write comment...' : 'เขียนความคิดเห็นของคุณ...'
                      }
                      onChange={handleChange}
                      isLogin
                      full
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    // id="login.change.title"
                    type="submit"
                    minWidth={150}
                    primary
                    onClick={() => {
                      const stroke = canvas?._objects.filter((x) => {
                        return x.stroke !== null
                      })
                      const group2 = new fabric.Group(stroke)
                      canvas.renderAll()
                      canvas.add(group2)
                    }}
                    disabled={isSubmitting}
                  >
                    Answer
                  </Button>
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
    </FakeModal>
  )
}
const mapState = ({ AppUser: { appUser }, Language: { locale } }) => ({
  appUser: appUser,
  locale,
})

const mapDispatch = ({ AppUser: { postComment, setComment, setCommentReply } }) => ({
  postComment,
  setComment,
  setCommentReply,
})
export default connect(mapState, mapDispatch)(DrawingModal)
