/* eslint-disable no-unused-vars */
import { Tooltip, Typography } from 'components'

import ClasswinEditor from '../classwinEditor'
import React, { Fragment, useState } from 'react'
import getImageUrl from 'utils/getImageUrl'
import moment from 'moment'
import {
  Avatar,
  CommentFlex,
  CommentReadOnly,
  CommentReply,
  ContainerComment,
  Like,
  LikeContainer,
  ImageSize,
  Desc,
} from './styles'
import TooltipOption from './TooltipOption'
import MoreIcon from '../../../../../images/icons/MoreIcon.svg'
import LikeIcon from '../../../../../images/icons/LikeIcon-primary.svg'
import LikedIcon from '../../../../../images/icons/LikedIcon.svg'
import { connect } from 'react-redux'
import { Lightbox } from 'react-modal-image'
import theme from 'theme'
import WhiteLikeIcon from '../../../../../images/icons/DarkIcon/White-LikeIcon.svg'
import WhiteLikeIconFocus from '../../../../../images/icons/DarkIcon/White-LikeIconFocus.svg'
// import DrawingModal from './DrawingModal'
const CommentItemLevelOne = ({
  appUser,
  richMessage,
  userMe,
  isMyLike,
  countLikes,
  onLike,
  setEditCommentLevelOne,
  handleDel,
  id,
  course,
  Confirm,
  locale,
  updateFeed,
  media,
  updatedAt,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const currentMessage = richMessage
  const [textInput, setTextInput] = useState(richMessage)
  const [openModalImageId, setOpenModalImageId] = useState(null)
  const isMe = userMe.id == appUser.id
  // const [drawingModalId, setDrawingModalId] = useState(null)
  // const [drawingToggle, setDrawingToggle] = useState(false)
  // const [isReplyDrawing, setIsReplyDrawing] = useState(false)
  const createdCommentTimes = moment(updatedAt)
    .locale(locale === 'en' ? 'en' : 'th')
    .fromNow()
  const handleEdit = async (richMessage) => {
    const payload = {
      appUser,
      isMe,
      isMyLike,
      countLikes,
      id,
      course,
      richMessage,
    }

    if (currentMessage === richMessage) {
      setIsEdit(false)
      return null
    }
    await Confirm.open({
      onConfirm: async () => {
        await updateFeed(payload)
        Confirm.close()
      },
      title: locale === 'en' ? 'Confirm edit' : 'ยืนยันการแก้ไข',
      subtitle:
        locale === 'en'
          ? 'Do you want to edit your comment?'
          : 'คุณต้องการแก้ไขข้อความแสดงความคิดเห็นใช่หรือไม่',
      onCancel: () => Confirm.close(),
    })
    setEditCommentLevelOne({ richMessage, id })
    setIsEdit(false)
  }
  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: 12,
          paddingRight: 0,
          paddingLeft: '40px',
          gap: '8px',
          width: '100%',
        }}
      >
        <Avatar src={getImageUrl(appUser?.profileImage)} alt={'user'} />
        <CommentReply isCommentLevelOne>
          <ContainerComment
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {isEdit ? (
              <ClasswinEditor
                value={textInput}
                onChange={(value) => {
                  setTextInput(value)
                }}
                labelFontSize="text-base"
                fontSize="text-sm"
                noBorderRadius
                autoHeight
                isMainFeed
                showToolbar={false}
                padding={0}
                backgroundFeed
                onBlur={() => setIsEdit(false)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.shiftKey === false && e.ctrlKey === false) {
                    const obj = JSON.parse(textInput)
                    if (obj.delta.ops.slice(-1)?.[0]?.insert) {
                      obj.delta.ops.slice(-1)[0].insert = obj.delta.ops
                        .slice(-1)?.[0]
                        ?.insert?.replaceAll(/\n+/g, '\n')
                    }
                    obj.content = obj.content.replaceAll('<p><br></p>', '')
                    const richMessage = JSON.stringify(obj)
                    if (!handleEdit) {
                      setTextInput(null)
                    }
                    handleEdit?.(richMessage)
                  }
                }}
              />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  <Desc color="dark2" bold>
                    {`${appUser?.firstname} ${appUser?.lastname}`}
                  </Desc>
                  <Desc margin={'0px 5px 0px 5px'} color="dark2">
                    •
                  </Desc>
                  <Desc margin={'0px'} color="dark2">
                    {createdCommentTimes}
                  </Desc>
                  {/* {isMe && (
                    <Tooltip
                      className="post-menu"
                      placement="bottom"
                      trigger="click"
                      tooltipShown={isShown}
                      onVisibilityChange={() => setIsShown((prev) => !prev)}
                      style={{
                        marginLeft: 6,
                        opacity: showTooltip ? 1 : 0,
                        transition: '0.2s',
                        width: 30,
                      }}
                      tooltip={
                        <TooltipOption
                          handleDelete={() => {
                            setIsShown(false)
                            Confirm.open({
                              onCancel: () => Confirm.close(),
                              confirmText: locale === 'en' ? 'Confirm' : 'ยืนยัน',
                              cancelText: locale === 'en' ? 'Cancel' : 'ยกเลิก',
                              onConfirm: () => {
                                handleDel({ feedId: id, courseId: course && course.id }, 2)
                                Confirm.close()
                              },
                              title: locale === 'en' ? 'Confirm delete' : 'ยืนยันการลบ',
                              subtitle:
                                locale === 'en'
                                  ? 'Do you want to delete your comment?'
                                  : 'คุณต้องการลบข้อความแสดงความคิดเห็นใช่หรือไม่',
                            })
                          }}
                          handleEdit={() => setIsEdit(true)}
                        />
                      }
                    >
                      <img
                        className="icon-more"
                        style={{ width: '16px', height: '20px' }}
                        src={MoreIcon}
                        alt="more-icon"
                      />
                    </Tooltip>
                  )} */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#5F5F61',
                    borderRadius: '8px',
                    padding: '0px 0px 0px 0px',
                    width: '100%',
                  }}
                >
                  <CommentReadOnly txtlength={richMessage?.length} style={{ marginLeft: 10 }}>
                    <ClasswinEditor
                      fontSize="text-sm"
                      isShowAll={false}
                      editorMaxHeight={50}
                      value={richMessage}
                      labelFontSize="text-base"
                      readOnly
                      autoHeight
                      isComment
                      padding="12px"
                      bulletPadding={12}
                      min={4}
                    />
                  </CommentReadOnly>
                  {media && (
                    <div style={{ marginTop: 8 }}>
                      {/* <WrapImage></WrapImage> */}
                      <ImageSize
                        src={getImageUrl(
                          media?.map((item) => {
                            return item.name
                          })
                        )}
                        alt=""
                        onClick={() => {
                          setOpenModalImageId(id)
                        }}
                      />
                      {openModalImageId === id && media && (
                        <Lightbox
                          // small={getImageUrl(
                          //   media?.map((item) => {
                          //     return item.name
                          //   })
                          // )}
                          large={getImageUrl(
                            media?.map((item) => {
                              return item.name
                            })
                          )}
                          // meta={() => {
                          //   return (
                          //     <span
                          //       style={{
                          //         color: 'red',
                          //         cursor: 'pointer',
                          //         // width: 50,
                          //         // zIndex: 100,
                          //       }}
                          //       onClick={() => {
                          //         setOpenModalImageId(null)
                          //         setDrawingModalId(id)
                          //         setDrawingToggle(true)
                          //         setIsReplyDrawing(true)
                          //       }}
                          //     >
                          //       [icon]
                          //     </span>
                          //   )
                          // }}
                          onClose={() => setOpenModalImageId(null)}
                          hideDownload
                        />
                      )}
                      {/* <DrawingModal
                      isOpen={drawingModalId === id}
                      onClose={() => setDrawingModalId(null)}
                      image={media}
                      drawingToggle={drawingToggle}
                      feedId={id}
                      courseId={course?.id}
                      isReply={isReplyDrawing}
                    /> */}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* {isMe && (
              <Tooltip
                className="post-menu"
                placement="bottom"
                trigger="click"
                tooltipShown={isShown}
                onVisibilityChange={() => setIsShown((prev) => !prev)}
                style={{
                  marginTop: 5,
                  marginLeft: 6,
                  opacity: showTooltip ? 1 : 0,
                  transition: '0.2s',
                  width: 30,
                }}
                tooltip={
                  <TooltipOption
                    handleDelete={() => {
                      setIsShown(false)
                      Confirm.open({
                        onCancel: () => Confirm.close(),
                        confirmText: locale === 'en' ? 'Confirm' : 'ยืนยัน',
                        cancelText: locale === 'en' ? 'Cancel' : 'ยกเลิก',
                        onConfirm: () => {
                          handleDel({ feedId: id, courseId: course && course.id }, 3)
                          Confirm.close()
                        },
                        title:
                          locale === 'en'
                            ? 'Do you want to delete your comment?'
                            : 'คุณต้องการลบข้อความแสดงความคิดเห็นใช่หรือไม่',
                      })
                    }}
                    handleEdit={() => setIsEdit(true)}
                  />
                }
              >
                <img className="icon-more" src={MoreIcon} alt="more-icon" />
              </Tooltip>
            )} */}
          </ContainerComment>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '12px',
              marginLeft: 0,
              marginBottom: 0,
            }}
          >
            <LikeContainer>
              {isMyLike ? (
                <Like src={WhiteLikeIconFocus} alt="Liked" onClick={() => onLike(id, 3)} />
              ) : (
                <Like src={WhiteLikeIcon} alt="Like" onClick={() => onLike(id, 3)} />
              )}
              <Typography margin="0 0 0 8px" color="white" bold lineHeight={1}>
                {`${countLikes}`}
              </Typography>
            </LikeContainer>
          </div>
        </CommentReply>
      </div>
    </Fragment>
  )
}
const mapState = ({ AppUser: { appUser } }) => ({
  userMe: appUser,
})
const mapDispatch = ({ AppUser: { setEditCommentLevelOne } }) => ({ setEditCommentLevelOne })
export default connect(mapState, mapDispatch)(CommentItemLevelOne)
