export const menus = [
  {
    label: 'Information',
    key: '0',
    component: null,
    path: '/information',
  },
  {
    label: 'Setting',
    key: '1',
    component: null,
    path: '/setting',
  },
]
