import React from 'react'
import styled from 'styled-components'
import { menus, menusTeacher } from './menus'
import PageHeading from '../../components/pageHeading/loadable'
import Breadcrumb from '../../components/breadcrumb/loadable'
import SideBar from './sideBar'
import ErrorBoundary from '../../components/errorBoundary'
import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'

const editProfileBreadcrumb = [
  {
    id: 'profile.edit.title',
    label: 'Edit Profile',
    link: '/profile/edit',
    mobileLabel: 'Edit Profile',
    isMobile: true,
    isActive: true,
  },
]
const editTeacherBreadcrumb = [
  {
    id: 'profile.edit.title',
    label: 'Edit Profile',
    link: '/teacher/profile/edit',
    mobileLabel: 'Edit Profile',
    isMobile: true,
    isActive: true,
  },
]

const LayoutContainer = styled.div`
  display: flex;
  padding: 40px 150px;
  gap: 24px;
`
// color: '${(props) => props.theme.gray6}';

const EditProfileLayout = (props) => {
  const { children, history } = props
  const selectPath = history.location.pathname.split('/')[1]
  return (
    <ErrorBoundary>
      <LargeDesktop>
        <PageHeading id="profile.edit.title" text="Edit Profile" />
        {/* <Breadcrumb
          data={editProfileBreadcrumb}
          link="/profile"
          firstLabel="Profile"
          firstLabelId="profile.menu"
        /> */}
        <LayoutContainer>
          <SideBar menus={selectPath === 'profile' ? menus : menusTeacher} />
          {children}
        </LayoutContainer>
      </LargeDesktop>
      <MobileAndTablet>
        {history.location.pathname === '/profile/edit' ||
        history.location.pathname === '/teacher/profile/edit' ? (
          <PageHeading id="profile.edit.title" text="Edit Profile" />
        ) : (
          <Breadcrumb
            data={selectPath === 'profile' ? editProfileBreadcrumb : editTeacherBreadcrumb}
          />
        )}
        {children}
      </MobileAndTablet>
    </ErrorBoundary>
  )
}

export default EditProfileLayout
