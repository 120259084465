import React from 'react'
import styled from 'styled-components'
import { Card, Typography, Input } from '../index'
const Container = styled.div`
  display: flex;
  position: absolute;
  min-width: 115px;
  max-height: 329px;
  min-height: 84px;
  z-index: 999;
  top: ${({ top }) => (top ? top : 40)}px;
  left: ${({ left }) => (left ? left : 47)}px;
`

const List = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
`

const ListItem = styled.li`
  border-bottom: 1px solid top;
  padding: 8px 11px;
  cursor: pointer;

  &:first-child {
    border-top: ${(props) =>
      props.searchable ? 'none' : `1px solid ${(props) => props.theme.gray9}`};
    &:hover {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &:last-child {
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: ${(props) => props.theme.whiteblue};
    p {
      color: ${(props) => props.theme.primary1};
      font-weight: 600;
    }
  }
`

const _Card = styled((props) => <Card {...props} />)`
  padding: 0;
  border: none;
`

const _Input = styled((props) => <Input {...props} />)``

const Header = styled.div``

export { Container, _Card as Card, Typography, List, ListItem, _Input as Input, Header }
