import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import uniqBy from 'lodash/uniqBy'

import PageHeading from 'components/pageHeading/loadable'
import ErrorBoundary from 'components/errorBoundary'
// import Select from 'components/select/loadable'
import PurchaseGrid from 'components/purchaseGrid/loadable'
import Empty from 'components/empty'
import Loader from '../../components/loader'
import EmptyProfile from '../../images/icons/EmptyProfile.svg'

const Container = styled.div`
  padding: 32px 142px;
  @media (max-width: 1024px) {
    padding: 32px 42px;
  }
  @media (max-width: 768px) {
    padding: 32px 12px;
  }
`
const EmptyCard = styled.div`
  background-color: white;
  border-radius: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 10px #00000029;
`
// const Heading = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
//   background: #fff;
//   border-top: 1px solid ${(props) => props.theme.gray8};
//   padding: 10px 50px;
//   z-index: 5;
//   min-height: 60px;
//   @media (max-width: 768px) {
//     padding: 10px 15px;
//     button {
//       padding: 6px;
//     }
//   }
// `
const optionData = [
  {
    value: 'All Course',
    label: 'All Courses',
  },
  {
    value: 'classroom',
    label: 'Classroom',
  },
  {
    value: 'online',
    label: 'Online',
  },
]
const optionDataTH = [
  {
    value: 'All Course',
    label: 'คอร์สทั้งหมด',
  },
  {
    value: 'classroom',
    label: 'คลาสรูม',
  },
  {
    value: 'online',
    label: 'ออนไลน์',
  },
]

class PurchaseHistory extends Component {
  state = {
    filter: this.props.locale === 'en' ? optionData[0] : optionDataTH[0],
    loading: true,
  }

  componentDidMount() {
    const { getEnrollments, getCoursePayments } = this.props
    Promise.all([getCoursePayments(), getEnrollments()]).finally(() =>
      this.setState({ loading: false })
    )
  }

  handleChange = (obj) => {
    this.setState({ filter: obj })
  }

  render() {
    const { filter } = this.state
    const { enrollments: list, coursePayments } = this.props
    const enrollments =
      filter.value === 'All Course'
        ? list
        : list.filter(({ course }) => course.type === filter.value)
    const payments =
      filter.value === 'All Course'
        ? coursePayments.map((item) => ({ ...item, payments: [item] }))
        : coursePayments
            .map((item) => ({ ...item, payments: [item] }))
            .filter(({ course }) => course.type === filter.value)

    const newList = uniqBy([...payments, ...enrollments], 'courseId')
    // console.log({ enrollments, coursePayments })
    if (this.state.loading) {
      return <Loader />
    }
    return (
      <ErrorBoundary>
        <PageHeading id="enrollment.title" text="Enrollment" />
        {/* <Heading>
          <Select
            value={filter}
            onChange={this.handleChange}
            options={this.props.locale === 'en' ? optionData : optionDataTH}
          />
        </Heading> */}
        <Container>
          {newList && newList.length > 0 ? (
            <PurchaseGrid enrollments={newList} link="/my-course/courses" />
          ) : (
            <EmptyCard>
              <Empty
                image={EmptyProfile}
                title="No enrollment."
                desc="There is no enrollment at the moment."
                Custom2
                reversepicture
              />
            </EmptyCard>
          )}
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapState = ({ AppUser: { enrollments, coursePayments }, Language: { locale } }) => ({
  enrollments,
  locale,
  coursePayments,
})

const mapDispatch = ({ AppUser: { getEnrollments, getCoursePayments } }) => ({
  getEnrollments,
  getCoursePayments,
})

export default connect(mapState, mapDispatch)(PurchaseHistory)
