import axios from 'axios'
import { ACCESS_TOKEN } from '../constants'

const BASE_URL = process.env.REACT_APP_API_URL_NESTJS_API
const instance = axios.create({
  baseURL: BASE_URL,
})

instance.interceptors.request.use(
  function (config) {
    const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN))
    return {
      ...config,
      headers: {
        ...config.headers,
        Accept: 'application/json',
        Authorization: token ? `Bearer ${token.id}` : '',
      },
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
export default instance
export const fetcher = async (url, config) => {
  const res = await instance.get(url, config)
  return res.data
}
