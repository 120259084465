import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ErrorBoundary from '../errorBoundary'
import Modal from '../modal/loadable'
import { customModalStyle, mobileModalStyle } from './customModalStyle'
import { Desktop, Mobile } from '../../utils/displayResponsive'
import { Box } from '../styledComponents'
import ConfirmImage from '../../images/icons/Question.svg'
import Typography from '../typography/index'
import windowSize from 'react-window-size'

const Container = styled(Box)`
  max-width: 450px;
  padding: 50px 40px;
  text-align: center;

  button {
    width: 180px;
  }

  img {
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding: 40px 20px;
    max-width: none;
    button {
      width: 130px;
    }

    img {
      margin-bottom: 30px;
    }
  }
`

const Title = styled(Typography)`
  font-size: ${({ theme }) => theme['text-3xl']};
  margin-bottom: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.primary1};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme['text-base']};
  }
`

const Subtitle = styled(Typography)`
  font-size: ${({ theme }) => theme['text-sm']};
  color: ${(props) => props.theme.black87};
  margin-bottom: 60px;
`

const TitleNoMargin = styled(Typography)`
  margin-bottom: 0px;
  font-weight: 500;
  font-size: ${({ theme }) => theme['text-xl']};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme['text-base']};
  }
`

const Image = styled.img`
  width: 60%;
  height: auto;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};

  @media (max-width: 768px) {
    justify-content: center;
    span {
      margin-left: 15px;
    }
  }
`

class ConfirmModal extends Component {
  render() {
    const {
      isOpen,
      title,
      title2,
      titleId,
      subtitle,
      subtitleId,
      btn1,
      btn2,
      onRequestClose,
      onAfterClose,
      image,
      titleNoMargin,
      titleNoMarginId,
      windowWidth,
      windowHeight,
    } = this.props
    const landscape = windowHeight < windowWidth
    const width = landscape ? '50vw' : '90vw'
    return (
      <ErrorBoundary>
        <Desktop>
          <Modal
            isOpen={isOpen}
            overlayStyle={customModalStyle.overlay}
            contentStyle={customModalStyle.content}
            onRequestClose={onRequestClose}
            onAfterClose={onAfterClose}
          >
            <Container>
              <Image src={image ? image : ConfirmImage} alt="confirm-img" />
              <Title id={titleId}>{title}</Title>
              <TitleNoMargin id={titleNoMarginId}>{titleNoMargin}</TitleNoMargin>
              <Title color="primary" style={{ marginBottom: '50px' }}>
                {title2}
              </Title>
              <Subtitle id={subtitleId}>{subtitle}</Subtitle>
              <ButtonContainer center={!btn1 || !btn2}>
                {btn2}
                <span>{btn1}</span>
              </ButtonContainer>
            </Container>
          </Modal>
        </Desktop>
        <Mobile>
          <Modal
            isOpen={isOpen}
            overlayStyle={mobileModalStyle.overlay}
            contentStyle={{ ...mobileModalStyle.content, width: width }}
            onRequestClose={onRequestClose}
            onAfterClose={onAfterClose}
          >
            <Container>
              <Image src={image ? image : ConfirmImage} alt="confirm-img" />
              <Title>{title}</Title>
              <TitleNoMargin>{titleNoMargin}</TitleNoMargin>
              <Title color="primary" style={{ marginBottom: '30px' }}>
                {title2}
              </Title>
              <Subtitle>{subtitle}</Subtitle>
              <ButtonContainer center={!btn1 || !btn2}>
                {btn2}
                <span>{btn1}</span>
              </ButtonContainer>
            </Container>
          </Modal>
        </Mobile>
      </ErrorBoundary>
    )
  }
}

ConfirmModal.propTypes = {
  btn1: PropTypes.element,
  btn2: PropTypes.element,
}

export default windowSize(ConfirmModal)
