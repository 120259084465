import { SchoolPackageAPI, SchoolAPI } from '../../api'

export const SchoolPackage = {
  state: {
    schoolPackages: [],
    schoolPackage: null,
    packagePayment: null,
    yearMonth: false,
    currentPackage: {},
    cards: [],
  },
  reducers: {
    setState(state, { name, payload }) {
      return {
        ...state,
        [name]: payload,
      }
    },
    setPackagePayment(state, payload) {
      return {
        ...state,
        packagePayment: payload,
      }
    },
    setYearMonth(state, payload) {
      return {
        ...state,
        yearMonth: payload,
      }
    },
    setCards(state, payload) {
      return {
        ...state,
        cards: payload,
      }
    },
  },
  effects: () => ({
    async getSchoolPackages() {
      try {
        const payload = await SchoolPackageAPI.find({
          filter: JSON.stringify({ order: 'priority ASC' }),
        })
        this.setState({ name: 'schoolPackages', payload })
      } catch (error) {
        throw error
      }
    },
    async getSchoolPackageById(id) {
      try {
        const payload = await SchoolPackageAPI.findById({ id })
        this.setState({ name: 'schoolPackage', payload })
      } catch (err) {
        throw err
      }
    },
    async subscribePackage({ schoolId, ...payload }) {
      const result = await SchoolAPI.subscribePackage({
        id: schoolId,
        ...payload,
      })
      return result
    },
    async getSchoolCurrentPackage({ schoolId }) {
      try {
        const payload = await SchoolAPI.getCurrentPackage({
          id: schoolId,
        })
        this.setState({ name: 'currentPackage', payload })
      } catch (err) {
        throw err
      }
    },
    async checkSchoolPaymentStatus({ schoolId }) {
      const result = await SchoolAPI.checkSchoolPaymentStatus({ id: schoolId })
      this.setPackagePayment(result)
      return result
    },
    async getCards({ schoolId }) {
      const result = await SchoolAPI.getCards({ id: schoolId })
      this.setCards(result)
      return result
    },
    async newCard(payload) {
      try {
        await SchoolAPI.newCard(payload)
        await this.getCards({ schoolId: payload.id })
      } catch (e) {
        throw e
      }
    },
  }),
}
