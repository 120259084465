import React from 'react'
import { Typography, Button } from 'components'

//import CommentIcon from '../../../../../images/icons/ChatDots.svg'
import WhiteComment from '../../../../../images/icons/DarkIcon/White-comment.svg'
import WhiteCommentFocus from '../../../../../images/icons/DarkIcon/White-commentFocus.svg'
import styled from 'styled-components'
export const CommentButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`
const CommentButton = ({ onClick, showed }) => {
  return (
    <CommentButtonContainer>
      <Button commentButton onClick={onClick} padding="0px">
        <img
          src={showed ? WhiteCommentFocus : WhiteComment}
          alt="Comment Icon"
          style={{ width: '22px', height: '22px' }}
        />
        {showed ? (
          <Typography color="white" id="button.comment" bold>
            Comment
          </Typography>
        ) : (
          <Typography color="white" id="button.comment">
            Comment
          </Typography>
        )}
      </Button>
    </CommentButtonContainer>
  )
}
export default CommentButton
