import { UserAPI } from '../../api'

export const Permissions = {
  state: {
    permissions: null,
    currentUserRole: '',
  },
  reducers: {
    setPermissions(state, payload) {
      return {
        ...state,
        permissions: payload,
      }
    },
    setCurrentUserRole(state, payload) {
      return {
        ...state,
        currentUserRole: payload,
      }
    },
  },
  effects: () => ({
    async getPermissions({ schoolId }) {
      const res = await UserAPI.getSchools({
        filter: JSON.stringify({
          include: 'school',
          where: { schoolId },
        }),
      })
      if (res.length <= 0) {
        this.setPermissions(null)
        this.setCurrentUserRole('')
        return null
      }

      const filtered = res.filter((item) => item.isApprove)
      if (filtered.length <= 0) {
        this.setPermissions(null)
        this.setCurrentUserRole('')
        return null
      }

      const result =
        filtered[0].role === 'owner'
          ? {
              dashboard: true,
              course: true,
              createAndViewCourse: true,
              viewCourse: true,
              editCourse: true,
              member: true,
              payment: true,
              exportTeacher: true,
              addNewTeacher: true,
              approveLearner: true,
              exportLearner: true,
              addNewLearner: true,
              certificate: true,
              createEditCertificate: true,
              releaseCertificate: true,
              report: true,
              exportReports: true,
              manageEmails: true,
              information: true,
              packages: true,
              searchGruops: true,
              addNewGruops: true,
              searchLearnerInClass: true,
              exportLearnerInClass: true,
            }
          : filtered[0].permissions

      // const result = filtered[0].permissions
      this.setPermissions(result)
      this.setCurrentUserRole(filtered[0].role)
      return { permissions: result, currentUserRole: filtered[0].role }
    },
  }),
}
