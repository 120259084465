import get from 'lodash/get'
import qs from 'qs'
import appUserApi from 'api/user'
import { FeedAPI, UserAPI } from 'api'
import { ACCESS_TOKEN } from '../../constants'

const initialState = {
  appUser: {},
  profile: {},
  myFeedLists: {},
  showAllComments: false,
  cookiePolicy: true,
  suggestionCourses: [],
  workExperience: {},
  workExperiences: [],
  education: {},
  educations: [],
  certificate: {},
  certificates: [],
  enrollments: [],
  studentGrades: [],
  myStudentGrade: [],
  events: [],
  point: 0,
  enrolledCourses: [],
  teacherSchedule: [],
  assignments: [],
  studentGroups: [],
  badge: [],
  badgeItem: {},
  courseGrade: [],
  coursePayments: [],
  signUpResult: {},
  studentCertificates: [],
  studentInAcademyProfile: {},
  schoolTutorial: {
    skipedDashboard: false,
    skipedCourse: false,
    skipedMember: false,
    skipedPayment: false,
    skipedCertificate: false,
    skipedReport: false,
    skipedInformation: false,
  },
  certStatus: {},
  follows: [],
}

export const AppUser = {
  state: initialState,
  reducers: {
    setState(state, { name, payload }) {
      return { ...state, [name]: payload }
    },
    clearToken() {
      localStorage.clear()
      return initialState
    },
    setCookiePolicy(state, payload) {
      return { ...state, cookiePolicy: payload }
    },
    setBadge(state, payload) {
      return { ...state, badge: payload }
    },
    setBadgeItem(state, payload) {
      return { ...state, badgeItem: payload }
    },
    setAppUser(state, payload) {
      return { ...state, appUser: payload }
    },
    setProfile(state, payload) {
      return { ...state, profile: payload }
    },
    setWorkExperience(state, payload) {
      return { ...state, workExperience: payload }
    },
    setWorkExperiences(state, payload) {
      return { ...state, workExperiences: payload }
    },
    clearWorkExperience(state) {
      return { ...state, workExperience: {} }
    },
    setEducation(state, payload) {
      return { ...state, education: payload }
    },
    setEducations(state, payload) {
      return { ...state, educations: payload }
    },
    clearEducation(state) {
      return { ...state, education: {} }
    },
    setCertificate(state, payload) {
      return { ...state, certificate: payload }
    },
    setCertificates(state, payload) {
      return { ...state, certificates: payload }
    },
    clearCertificate(state) {
      return { ...state, certificate: {} }
    },
    setEnrollments(state, payload) {
      return { ...state, enrollments: payload }
    },
    setAssignments(state, payload) {
      return { ...state, assignments: payload }
    },
    setPoint(state, payload) {
      return {
        ...state,
        point: payload.length > 0 ? payload.pop().balance : 0,
      }
    },
    setStudentGrades(state, payload) {
      return {
        ...state,
        studentGrades: payload,
      }
    },
    setEvents(state, payload) {
      return {
        ...state,
        events: payload,
      }
    },
    setEnrolledCourses(state, payload) {
      return {
        ...state,
        enrolledCourses: payload,
      }
    },
    setTeacherSchedule(state, payload) {
      return {
        ...state,
        teacherSchedule: payload,
      }
    },
    setStudentGroup(state, payload) {
      return {
        ...state,
        studentGroups: payload,
      }
    },
    setCourseProgress(state, payload) {
      return {
        ...state,
        courseProgress: payload,
      }
    },
    setCoursePayments(state, payload) {
      return {
        ...state,
        coursePayments: payload,
      }
    },
    setStudentCertificates(state, payload) {
      return {
        ...state,
        studentCertificates: payload,
      }
    },
    setSchoolTutorial(state, payload) {
      return {
        ...state,
        schoolTutorial: payload,
      }
    },
    setSkipSchoolTutorial(state, payload) {
      return {
        ...state,
        schoolTutorial: {
          ...state.schoolTutorial,
          [payload]: true,
        },
      }
    },
    setCertStatus(state, payload) {
      return {
        ...state,
        certStatus: payload,
      }
    },
    setSuggestionCourses(state, payload) {
      return {
        ...state,
        suggestionCourses: payload,
      }
    },
    setMyFeedLists(state, payload) {
      return {
        ...state,
        myFeedLists: payload,
      }
    },
    setMoreMyFeedList(state, payload) {
      return {
        ...state,
        myFeedLists: {
          feeds: [...state.myFeedLists.feeds, ...payload.feeds],
          total: state.myFeedLists.total,
        },
      }
    },
    setLikePost(state, payload) {
      const newState = state.myFeedLists.feeds.map((item) => {
        if (item.id === payload) {
          return {
            ...item,
            isMyLike: !item.isMyLike,
            countLikes: item.isMyLike ? item.countLikes - 1 : item.countLikes + 1,
          }
        }
        return item
      })

      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },
    setLikeComment(state, payload) {
      const newState = state.myFeedLists.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return reply.id === payload
              ? {
                  ...reply,
                  isMyLike: !reply.isMyLike,
                  countLikes: reply.isMyLike ? reply.countLikes - 1 : reply.countLikes + 1,
                }
              : reply
          }),
        }
      })

      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },
    setLikeReply(state, payload) {
      const newState = state.myFeedLists.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return {
              ...reply,
              comments: reply.comments.map((comment) => {
                return comment.id === payload
                  ? {
                      ...comment,
                      isMyLike: !comment.isMyLike,
                      countLikes: comment.isMyLike
                        ? comment.countLikes - 1
                        : comment.countLikes + 1,
                    }
                  : comment
              }),
            }
          }),
        }
      })

      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },
    setComment(state, payload) {
      const newState = state.myFeedLists.feeds.map((item) => {
        return {
          ...item,
          comments: item.id === payload.feedId ? [...item.comments, payload] : item.comments,
        }
      })

      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },
    setCommentReply(state, payload) {
      const newState = state.myFeedLists.feeds.map((item) => {
        return {
          ...item,
          comments: (item.comments || []).map((reply) => {
            return {
              ...reply,
              comments:
                reply.id === payload.feedId
                  ? [...(reply.comments || []), payload]
                  : reply.comments || [],
            }
          }),
        }
      })
      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },
    setEditComment(state, payload) {
      const newState = state.myFeedLists.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return payload.id === reply.id ? { ...reply, richMessage: payload.richMessage } : reply
          }),
        }
      })
      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },
    setEditCommentLevelOne(state, payload) {
      const newState = state.myFeedLists.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return {
              ...reply,
              comments: reply.comments.map((comment) => {
                return payload.id === comment.id
                  ? { ...comment, richMessage: payload.richMessage }
                  : comment
              }),
            }
          }),
        }
      })
      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },
    setDeletePost(state, payload) {
      const newState = state.myFeedLists.feeds.filter((item) => item.id !== payload.id)

      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },
    setDeleteComment(state, payload) {
      const newState = state.myFeedLists.feeds.map((item) => {
        return {
          ...item,
          comments:
            item.courseId === payload.courseId
              ? item.comments.filter((reply) => reply.id !== payload.id)
              : item.comments,
        }
      })

      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },

    setDeleteCommentLevelOne(state, payload) {
      const newState = state.myFeedLists.feeds.map((item) => {
        return {
          ...item,
          comments: item.comments.map((reply) => {
            return {
              ...reply,
              comments:
                reply.courseId === payload.courseId
                  ? reply.comments.filter((comment) => comment.id !== payload.id)
                  : reply.comments,
            }
          }),
        }
      })
      return {
        ...state,
        myFeedLists: {
          feeds: newState,
          total: state.myFeedLists.total,
        },
      }
    },
    setFollows(state, payload) {
      return {
        ...state,
        follows: payload,
      }
    },
    setStudentInAcademyProfile(state, payload) {
      return {
        ...state,
        studentInAcademyProfile: payload,
      }
    },
  },

  effects: (dispatch) => ({
    async editStudentInAcademyProfile(payload) {
      try {
        //console.log(payload)
        const result = await appUserApi.editStudentAcademyProfile(payload)
        return result
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getStudentInAcademyProfile(payload) {
      try {
        const result = await appUserApi.getStudentAcademyProfile(payload)
        this.setStudentInAcademyProfile(result)
        // /console.log(result)
      } catch (e) {
        console.log(e)
        throw e
      }
    },

    async logout() {
      try {
        await UserAPI.logout()
      } catch (e) {
        throw e
      }
    },
    async postDiscussion(obj) {
      await FeedAPI.create(obj)
      // this.getDiscussion(obj.courseId)
    },
    async postComment(obj) {
      try {
        const res = await FeedAPI.create(obj)
        return res
      } catch (e) {
        throw e
      }
    },
    async deleteFeed({ id }) {
      try {
        await FeedAPI.delete({ id })
      } catch (error) {
        throw error
      }
    },
    async getSuggestionCourses() {
      try {
        const res = await appUserApi.getSuggestionCoursesAPI()
        this.setSuggestionCourses(res)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getMyFeeds({ courseId }) {
      const skip = 0
      const limit = 5
      try {
        const getFilter = JSON.stringify({
          limit,
          skip,
          courseId,
        })
        const res = await appUserApi.getMyFeeds({ filter: getFilter })
        this.setMyFeedLists(res)
      } catch (e) {
        console.log(e)
        throw e
      }
    },

    async getMoreMyFeeds({ skip = 0, limit = 5, courseId }) {
      try {
        const getFilter = JSON.stringify({
          limit,
          skip,
          courseId,
        })
        const res = await appUserApi.getMyFeeds({ filter: getFilter })
        this.setMoreMyFeedList(res)
      } catch (e) {
        throw e
      }
    },
    async updateFeed(payload) {
      try {
        return await FeedAPI.update(payload)
      } catch (error) {
        throw error
      }
    },
    async getBadge(slug) {
      try {
        const badges = await appUserApi.getBadge({ slug })
        this.setBadge(badges)
      } catch (error) {
        throw error
      }
    },
    async getBadgeItem({ username, badgeId }) {
      try {
        const badge = await appUserApi.getBadgebyID({ slug: username, fk: badgeId })
        this.setBadgeItem(badge)
      } catch (error) {
        throw error
      }
    },
    async changePriority(payload, state) {
      let locale = state.Language.locale
      try {
        await appUserApi.changePriority(payload)

        dispatch.Alert.success({ title: locale === 'en' ? 'Updated Successful' : 'อัปเดตสำเร็จ' })
      } catch (error) {
        dispatch.Alert.error({ title: locale === 'en' ? 'Update Failed' : 'อัปเดตไม่สำเร็จ' })
        throw error
      }
    },
    async getAssignments(payload) {
      try {
        const filter = qs.stringify({
          type: 'practice',
          where: payload,
        })
        const assignments = await appUserApi.getMyAssignments({ filter })
        this.setAssignments(assignments)
      } catch (error) {
        throw error
      }
    },
    async getTeacherSchedule() {
      try {
        const result = await appUserApi.getTeacherSchedule()
        this.setTeacherSchedule(result)
      } catch (error) {
        throw error
      }
    },
    async getEnrolledCourses(id) {
      try {
        const payload = {
          id,
          filter: {
            fields: ['name', 'image', 'id'],
            include: [
              {
                relation: 'assignments',
                scope: {
                  fields: ['title', 'type', 'id'],
                  include: {
                    relation: 'answers',
                    scope: {
                      fields: ['score', 'attemp', 'totalScore'],
                      order: 'attemp DESC',
                      limit: 1,
                      where: { appUserId: id },
                    },
                  },
                },
              },
              {
                relation: 'attendances',
                scope: {
                  fields: ['id'],
                  include: {
                    relation: 'attendanceItems',
                    scope: {
                      where: { studentId: id },
                      fields: ['isCome'],
                    },
                  },
                },
              },
            ],
          },
        }
        const result = await appUserApi.getEnrolledCourses(payload)
        this.setEnrolledCourses(result)
      } catch (error) {
        throw error
      }
    },
    async acceptSchoolMember(payload) {
      try {
        await appUserApi.acceptSchoolMember(payload)
      } catch (error) {
        throw error
      }
    },
    async rejectSchoolMember(payload) {
      try {
        await appUserApi.rejectSchoolMember(payload)
      } catch (error) {
        throw error
      }
    },
    async changeEmail(payload) {
      try {
        await appUserApi.changeEmail(payload)
        dispatch.Alert.success({
          title: 'Change Email Successfully',
          description:
            'We have sent a new e-mail verification, please check your e-mail inbox to activate your Classwin account',
        })
        return 'success'
      } catch (err) {
        console.error(err)
        dispatch.Alert.error({ title: 'Change Email Error' })
        return 'error'
      }
    },
    async getEvents() {
      const result = await appUserApi.getEvents()
      this.setEvents(result)
    },
    async postEvents(payload) {
      const result = await appUserApi.postEvents(payload)
      this.setEvents(result)
    },
    async updateEvents(payload) {
      await appUserApi.updateEvents(payload)
    },
    async deleteEvents(id) {
      await appUserApi.deleteEvents({ id })
    },
    async getStudentGrades() {
      const payload = {
        filter: JSON.stringify({ include: { course: ['school'] }, where: { isApproved: true } }),
      }
      const result = await appUserApi.getStudentGrades(payload)
      this.setStudentGrades(result)
    },
    async getStudentGradesByCourse({ courseId }) {
      const payload = {
        filter: JSON.stringify({ where: { courseId } }),
      }
      const result = await appUserApi.getStudentGrades(payload)
      this.setState({ name: 'courseGrade', payload: result })
    },
    async getPoint() {
      const result = await appUserApi.getPoint()
      this.setPoint(result)
    },
    async fetchMe() {
      try {
        const me = await appUserApi.getUserMe()
        const { schoolTutorial } = me
        dispatch.AppUser.setSchoolTutorial(schoolTutorial)
        dispatch.AppUser.setAppUser(me)
        return me
      } catch (e) {
        throw e
      }
    },
    async findById(id = 'me') {
      try {
        const payload = {
          id,
          filter: JSON.stringify({ include: ['workExperiences', 'educationHistories'] }),
        }
        const profile = await appUserApi.findById(payload)
        dispatch.AppUser.setProfile(profile)
        return profile
      } catch (error) {
        console.log('error: ', error)
        return null
      }
    },
    async findBySlug(slug) {
      try {
        let profile
        if (slug) {
          const payload = {
            filter: JSON.stringify({
              where: {
                slug,
              },
              include: ['workExperiences', 'educationHistories'],
            }),
          }
          profile = await appUserApi.findOne(payload)
        } else {
          const payload = {
            id: 'me',
            filter: JSON.stringify({ include: ['workExperiences', 'educationHistories'] }),
          }
          profile = await appUserApi.findById(payload)
        }
        dispatch.AppUser.setProfile(profile)
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async login(payload, state) {
      try {
        let auth
        if (state.Liff.isLiff) {
          const { liff } = window
          const profile = await liff.getProfile()
          auth = await appUserApi.login({ ...payload, tokenLineID: profile.userId })
        } else {
          auth = await appUserApi.login(payload)
        }
        localStorage.setItem(ACCESS_TOKEN, JSON.stringify(auth))
        dispatch.AppUser.setAppUser(auth)
        const log = {
          namespace: 'Session',
          title: 'Logged In',
          data: {
            isLoggedIn: true,
          },
        }
        await UserAPI.createLog(log)
        return auth
      } catch (error) {
        let desc
        let btnText
        let showBtn
        let buttonStatus
        let onOk = () => {}
        console.log(error.response)
        if (error.response && error.response?.code === 'LOGIN_FAILED_EMAIL_NOT_VERIFIED') {
          const {
            details: { userId },
            message,
          } = error.response

          desc = message
          onOk = async () => {
            console.log(error.response)
            dispatch.Alert.toggle(false)
            await this.sendVerifyEmail(userId)
          }
          btnText = 'Resend verification email'
          showBtn = true
          // buttonStatus = { error: false, success: true, stayOpen: true }
          buttonStatus = { stayOpen: true }
        } else if (error.response) {
          desc =
            error.response.code === 'LOGIN_FAILED'
              ? 'Please check your email and password'
              : error.response.message
        } else {
          desc = error.message
        }
        dispatch.Alert.error({
          title: 'Login Failed',
          description: desc,
          showBtn,
          onOk,
          btnText,
          ...buttonStatus,
        })
        throw error
      }
    },
    async facebookLogin(payload) {
      try {
        const auth = await appUserApi.facebookLogin(payload)
        localStorage.setItem(ACCESS_TOKEN, JSON.stringify(auth))
        dispatch.AppUser.setAppUser(auth)
        const log = {
          namespace: 'Session',
          title: 'Logged In',
          data: {
            isLoggedIn: true,
          },
        }
        await UserAPI.createLog(log)
        return auth
      } catch (error) {
        throw error
      }
    },
    async googleLogin(payload) {
      try {
        const auth = await appUserApi.googleLogin(payload)
        localStorage.setItem(ACCESS_TOKEN, JSON.stringify(auth))
        dispatch.AppUser.setAppUser(auth)
        const log = {
          namespace: 'Session',
          title: 'Logged In',
          data: {
            isLoggedIn: true,
          },
        }
        await UserAPI.createLog(log)
        return auth
      } catch (error) {
        throw error
      }
    },
    async createTimer(payload) {
      try {
        const log = {
          namespace: 'TIMER_ASSIGNMENT',
          title: `assignment`,
          data: {
            timeout: payload.timeout,
            assignmentId: payload.assignmentId,
          },
        }
        await UserAPI.createLog(log)
      } catch (error) {
        throw error
      }
    },
    async getTimer(assignmentId) {
      try {
        const filter = JSON.stringify({
          where: {
            assignmentId,
          },
        })
        const userLogs = await UserAPI.getLog({ filter })
        const result = userLogs.filter(({ data }) => data.assignmentId === assignmentId)
        return result
      } catch (error) {
        throw error
      }
    },

    async signUp(payload, state) {
      try {
        const user = await appUserApi.create(payload)
        this.setState({ name: 'signUpResult', payload: user })
        return user
      } catch (error) {
        let locale = state.Language.locale
        let title = locale === 'en' ? 'Sign Up Failed' : 'ลงทะเบียนไม่สำเร็จ'
        let desc =
          locale === 'en'
            ? 'An account already exists with this email. Please select another email address.'
            : 'อีเมลนี้มีผู้ใช้อยู่ก่อนแล้วโปรดเลือกอีเมลอื่นที่แตกต่างกันในการลงทะเบียน'

        if (error.response) {
          // if (error.response.statusCode === 422) {
          //   title = 'The email address you have entered is already registered'
          // } else {
          // }
        } else {
          desc = error.message
        }
        dispatch.Alert.error({
          title,
          description: desc,
        })
        throw error
      }
    },
    async resetPassword(payload) {
      try {
        const result = await appUserApi.requestPasswordReset(payload)

        console.log({ result })
        return 'success'
      } catch (error) {
        if (error.response && error.response?.code === 'RESET_FAILED_EMAIL_NOT_VERIFIED') {
          return 'notVerified'
        }
        dispatch.Alert.error({ title: 'Your email address is not registered.' })
        return null
      }
    },
    async resetNewPassword(payload) {
      try {
        const user = await appUserApi.setPassword(payload)
        return user
      } catch (error) {
        console.log('error :', error)
        throw error
      }
    },
    async changePassword(payload) {
      try {
        await appUserApi.changePassword(payload)
        dispatch.Alert.success({ title: 'Change Password Successfully' })

        return 'success'
      } catch (error) {
        console.log('error :', error)
        dispatch.Alert.error({ title: 'Change Password Error' })

        throw error
      }
    },
    async updateProfile(payload) {
      try {
        const result = await appUserApi.update(payload)
        // dispatch.Alert.success({ title: 'Update Profile Successfully' })
        await this.fetchMe()
        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Update Profile Error' })
        throw error
      }
    },
    async getWorkExperience() {
      try {
        const result = await appUserApi.getWorkExp()
        console.log('result from getworkexp', result)
        dispatch.AppUser.setWorkExperiences(result)
        return result
      } catch (error) {
        console.log('error: ', error)
        throw error
      }
    },
    async getWorkExperienceById(payload) {
      try {
        const result = await appUserApi.getWorkExpById(payload)
        dispatch.AppUser.setWorkExperience(result)
        return result
      } catch (error) {
        console.log('error', error)
        throw error
      }
    },
    async addWorkExperience(payload) {
      try {
        const result = await appUserApi.addWorkExp(payload)
        console.log('addworkexp', result)
        // await this.getWorkExperience()
        dispatch.Alert.success({ title: 'Successfully saved' })
        return result
      } catch (error) {
        dispatch.Alert.error({
          title: 'Error occurred',
          description: `We can't add Work experience information`,
        })
        console.log('error', error)
        throw error
      }
    },
    async editWorkExperience(payload) {
      try {
        const result = await appUserApi.updateWorkExp(payload)
        console.log('editworkexp', result)
        dispatch.Alert.success({ title: 'Update Work Experience Successfully' })

        // await this.getWorkExperience()
        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Update Work Experience Error' })
        console.log('error', error)
        throw error
      }
    },
    async deleteWorkExperience(payload) {
      try {
        const result = await appUserApi.deleteWorkExp(payload)
        console.log('deleteWorkExp', result)
        return result
      } catch (error) {
        console.log('error', error)
        throw error
      }
    },
    async getEducation() {
      try {
        const result = await appUserApi.getEducation()
        console.log('result from getEducation', result)
        dispatch.AppUser.setEducations(result)
        return result
      } catch (error) {
        console.log('error: ', error)
        throw error
      }
    },
    async getEducationById(payload) {
      try {
        const result = await appUserApi.getEducationById(payload)
        console.log('result from getEducationById', result)
        dispatch.AppUser.setEducation(result)
        return result
      } catch (error) {
        console.log('error', error)
        throw error
      }
    },
    async addEducation(payload) {
      try {
        const result = await appUserApi.addEducation(payload)
        dispatch.Alert.success({ title: 'Successfully saved' })
        console.log('addEducation', result)
        return result
      } catch (error) {
        console.log('error', error)
        dispatch.Alert.error({
          title: 'Error occurred',
          description: `We can't add Education information`,
        })
        throw error
      }
    },
    async editEducation(payload) {
      try {
        const result = await appUserApi.updateEducation(payload)
        console.log('editEducation', result)
        dispatch.Alert.success({ title: 'Update Education Successfully' })

        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Update Education Error' })

        console.log('error', error)
        throw error
      }
    },
    async deleteEducation(payload) {
      try {
        const result = await appUserApi.deleteEducation(payload)
        console.log('deleteEducation', result)
        return result
      } catch (error) {
        console.log('error', error)
        throw error
      }
    },
    async getCertificate() {
      try {
        const result = await appUserApi.getCertificates()
        console.log('result from getCertificate', result)
        dispatch.AppUser.setCertificates(result)
        return result
      } catch (error) {
        console.log('error: ', error)
        throw error
      }
    },
    async getCertificateById(payload) {
      try {
        const result = await appUserApi.getCertificateById(payload)
        console.log('result from getCertificateById', result)
        dispatch.AppUser.setCertificate(result)
        return result
      } catch (error) {
        console.log('error', error)
        throw error
      }
    },
    async addCertificate(payload) {
      try {
        const result = await appUserApi.addCertificate(payload)
        dispatch.Alert.success({ title: 'Successfully saved' })
        console.log('addCertificate', result)
        return result
      } catch (error) {
        dispatch.Alert.error({
          title: 'Error occurred',
          description: `We can't add Certificate information`,
        })
        console.log('error', error)
        throw error
      }
    },
    async editCertificate(payload) {
      try {
        const result = await appUserApi.updateCertificate(payload)
        console.log('editCertificate', result)
        dispatch.Alert.success({ title: 'Update Certificate Successfully' })

        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Update Certificate Error' })

        console.log('error', error)
        throw error
      }
    },
    async deleteCertificate(payload) {
      try {
        const result = await appUserApi.deleteCertificate(payload)
        console.log('deleteCertificate', result)
        return result
      } catch (error) {
        console.log('error', error)
        throw error
      }
    },
    async getEnrollments() {
      try {
        const payload = {
          filter: JSON.stringify({ include: ['payments', 'course'], order: 'createdAt DESC' }),
        }

        const result = await appUserApi.getEnrollments(payload)
        this.setEnrollments(result)
      } catch (error) {
        throw error
      }
    },
    async postLike(feedId) {
      try {
        await appUserApi.postLikeApi({ feedId }, {})
      } catch (err) {
        throw err
      }
    },
    async getCoursePayments() {
      try {
        const payload = {
          filter: JSON.stringify({
            where: { productType: 'package', isSuccess: true },
            include: 'course',
          }),
        }
        const result = await appUserApi.getCoursePayments(payload)
        this.setCoursePayments(result)
      } catch (error) {
        throw error
      }
    },
    async getStudentGroups() {
      const filter = JSON.stringify({ include: ['school', 'students'] })

      try {
        const result = await appUserApi.getStudentGroups({ filter })
        this.setStudentGroup(result)
        return result
      } catch (error) {
        throw error
      }
    },
    async getCourseProgress(courseId) {
      // const filter = JSON.stringify({ include: 'school' })

      try {
        const result = await UserAPI.getCourseProgress(courseId)
        this.setCourseProgress(result)
        return result
      } catch (error) {
        throw error
      }
    },
    async getCertificateByCourse({ courseId }) {
      const payload = JSON.stringify({
        include: { course: 'school' },
        where: { courseId, isApproved: true },
      })
      try {
        const result = await UserAPI.getStudentGrades({ filter: payload })
        console.log('result 🆔', result)
        this.setState({ name: 'myStudentGrade', payload: result })
        return result
      } catch (error) {
        throw error
      }
    },
    async sendContractUsEmail(payload) {
      try {
        await UserAPI.contractUs({ ...payload })
      } catch (error) {
        throw error
      }
    },
    async sendVerifyEmail(userId) {
      try {
        await UserAPI.sendVerifyEmail({ id: userId })
      } catch (error) {
        throw error
      }
    },
    async sendVerifyEmailWithEmail(email) {
      console.log(email)
      try {
        await UserAPI.sendVerifyEmailWithEmail(email)
      } catch (error) {
        throw error
      }
    },
    async getMyProgramCertificates() {
      try {
        const result = await UserAPI.getStudentCertificates()
        this.setStudentCertificates(result)
        return result
      } catch (e) {
        throw e
      }
    },
    async getCertStatusByCourse({ courseId }) {
      try {
        const result = await UserAPI.getCertStatusByCourse({ courseId })
        this.setCertStatus(result)
        return result
      } catch (e) {
        throw e
      }
    },
    async postCourseRating(payload) {
      try {
        const result = await appUserApi.postCourseRating(payload)
        // console.log('postCourseRating', result)
        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Error occurred' })
        console.log('error', error)
        throw error
      }
    },
    async putCourseRating(payload) {
      try {
        const result = await appUserApi.putCourseRating(payload)
        // console.log('putCourseRating', result)
        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Error occurred' })
        console.log('error', error)
        throw error
      }
    },
    async getMyFollows() {
      try {
        const result = await appUserApi.getMyFollows()
        this.setFollows(result)
      } catch (error) {
        dispatch.Alert.error({ title: 'Error occurred' })
        console.log('error', error)
        throw error
      }
    },
    async postMyFollows(payload) {
      try {
        const result = await appUserApi.postMyFollows(payload)
        this.getMyFollows()
        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Error occurred' })
        console.log('error', error)
        throw error
      }
    },
    async deleteMyFollows(deleteMyFollows) {
      try {
        const result = await appUserApi.deleteMyFollows({ fk: deleteMyFollows })
        this.getMyFollows()
        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Error occurred' })
        console.log('error', error)
        throw error
      }
    },
  }),
  selectors: () => ({
    isTeacher() {
      return (rootState) => {
        const { appUser } = rootState.AppUser
        const isTeacher = get(appUser, 'roles', [])
          .map((role) => role.name)
          .includes('teacher')
        return isTeacher
      }
    },
    isFollowing() {
      return (rootState, props) => {
        const { follows } = rootState.AppUser
        const isFollowing = follows?.map((follow) => follow.followingId).includes(props)
        if (isFollowing) {
          return isFollowing
        } else {
          return false
        }
      }
    },
  }),
}

export const Teacher = {
  state: {
    verifyTeacherError: '',
  },
  reducers: {
    setVerifyTeacherError(state, payload) {
      return { ...state, verifyTeacherError: payload }
    },
  },
  effects: (dispatch) => ({
    async verifyTeacher(payload) {
      try {
        await appUserApi.verifyTeacher(payload)
      } catch (error) {
        dispatch.Teacher.setVerifyTeacherError('Please check your phone number and try again.')
        throw error
      }
    },
    async enrollTeacher(payload) {
      try {
        const result = await appUserApi.enrollTeacher(payload)
        console.log(result)
        return result
      } catch (error) {
        console.log(error)
        throw error
      }
    },
  }),
}
