import styled from 'styled-components'

const ImageRatio = styled.div`
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-image: ${(props) => `url('${props.src}')`};
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-top: ${(props) => (props.ratio || 1) * 100}%; /* 16:9 Aspect Ratio */
`

export default ImageRatio
