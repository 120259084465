export default (params) => {
  const { searchedWord, filter } = params
  const filterObj = {
    where: {
      and: [{ classType: 'premium' }],
    },
  }
  const filterString = JSON.stringify(filterObj)
  if (searchedWord) {
    const or = [
      {
        name: {
          like: searchedWord,
          options: 'i',
        },
      },
      {
        description: {
          like: searchedWord,
          options: 'i',
        },
      },
    ]
    filterObj.where.and = [...filterObj.where.and, { or }]
  }
  if (filter) {
    filterObj.where.and = [...filterObj.where.and, { privacy: filter }]
  }
  if (JSON.stringify(filterObj) === filterString) {
    return { where: { classType: 'premium' } }
  }
  return filterObj
}
