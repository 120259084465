import React from 'react'
// import Logo from '../logo'
import {
  UnauthorizedContainer,
  Hero,
  TabContainer,
  TabDetail,
  PrivacyAgreement,
  LinkText,
} from './styles'
import ErrorBoundary from '../errorBoundary'
import { Desktop, Tablet, SmallMobile } from 'utils/displayResponsive'
import VerifyCertificateMobile from 'components/verifyCertificateMobile'
import qs from 'qs'
import { Link } from 'react-router-dom'
// import ModalLogin from '../../components/modalLogin'

export const UnauthorizedLayout = ({ children }) => {
  // const routeLocation = {
  //   pathname: '/user/login',
  //   state: { from: '/browse/courses' },
  // }
  // const mobile = window.matchMedia(`(max-width: 768px)`)
  // const maxHeight = window.matchMedia(`(max-height: 768px)`)
  const query = qs.parse(location.search, { ignoreQueryPrefix: true })

  // const [tab, setTab] = useState('account')
  return (
    <ErrorBoundary>
      <UnauthorizedContainer>
        <Desktop>
          <Hero>{children}</Hero>
          <PrivacyAgreement>
            <div />
            <Link to="/articles/privacy-policy" target="_blank" rel="noopener noreferrer">
              <LinkText id="login.button.privacyPolicy">Privacy Policy</LinkText>
            </Link>
            <Link to="/articles/services-agreement" target="_blank" rel="noopener noreferrer">
              <LinkText id="login.button.servicesAgreement">Services Agreement</LinkText>
            </Link>
            <div />
          </PrivacyAgreement>
        </Desktop>
        <Tablet>
          <Hero>{children}</Hero>
          <PrivacyAgreement>
            <div />
            <Link to="/articles/privacy-policy" target="_blank" rel="noopener noreferrer">
              <LinkText id="login.button.privacyPolicy">Privacy Policy</LinkText>
            </Link>
            <Link to="/articles/services-agreement" target="_blank" rel="noopener noreferrer">
              <LinkText id="login.button.servicesAgreement">Services Agreement</LinkText>
            </Link>
            <div />
          </PrivacyAgreement>
        </Tablet>
        <SmallMobile>
          <TabContainer>
            {/* <TabHeader>
              <Tab
                onClick={() => history.push(`/user/login?tab=account`)}
                opacity={query.tab === 'account' || query.tab === undefined ? '1' : '0.75'}
              >
                <Typography
                  fontSize="text-lg"
                  color={query.tab === 'account' || query.tab === undefined ? 'primary2' : ''}
                  bold
                >
                  Account
                </Typography>
              </Tab>
              <Tab
                onClick={() => history.push(`/user/login?tab=certificate`)}
                opacity={query.tab === 'certificate' ? '1' : '0.75'}
              >
                <Typography
                  fontSize="text-lg"
                  color={query.tab === 'certificate' ? 'primary2' : ''}
                  bold
                >
                  Certificate
                </Typography>
              </Tab>
            </TabHeader> */}
            {query.tab === 'account' || query.tab === undefined ? (
              <TabDetail>{children}</TabDetail>
            ) : (
              <TabDetail overflow="scroll">
                <VerifyCertificateMobile />
              </TabDetail>
            )}
          </TabContainer>
        </SmallMobile>
      </UnauthorizedContainer>
    </ErrorBoundary>
  )
}
