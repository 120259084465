import styled, { css } from 'styled-components'
import { IoMdMenu } from 'react-icons/io'
import { Container } from '../../../components'
const InputIcon = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 0 16px;
`
const Icon = styled.i`
  display: flex;
  align-self: center;
  z-index: 1;
  cursor: pointer;
  margin-left: ${({ marginLeft }) => marginLeft};
`

const StyledIoMdMenu = styled(IoMdMenu)`
  font-size: ${({ theme }) => theme['text-xl']};
  fill: #929292;
  display: flex;
  /* margin-right: 20px; */
`
const ContentBody = styled(Container)`
  position: fixed;

  top: ${({ isCourseSetting }) => (isCourseSetting ? '56px' : '0px')};
  height: ${({ isCourseSetting }) => (isCourseSetting ? 'calc(100vh - 0px)' : '100%')};
  //right: 0;
  z-index: 3;
  top: 54px;
  right: 140px;

  // box-shadow: -4px 6px 15px rgba(0, 0, 0, 0.1);
  transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  /* transform: translateX(0vw); */
  overflow-y: scroll;
  overflow-x: hidden;
  // margin: 0;
  width: 43%;
  min-width: 400px;
  background-color: transparent;
  grid-template-rows: repeat(5, min-content) 1fr;
  ::-webkit-scrollbar {
    width: 0px;
  }
  ${({ isDragging }) => {
    if (isDragging)
      return css`
        display: none;
      `
    return css``
  }}
  @media (max-width: 1024px) {
    position: fixed;
    top: 40px;
    height: calc(100vh - 50px);
  }
`
const IconArrow = styled.img`
  transform: rotate(${({ rotate }) => rotate});
  cursor: pointer;
  width: 22px;
  height: 22px;
  align-self: center;
`
export { InputIcon, Icon, StyledIoMdMenu, ContentBody, IconArrow }
