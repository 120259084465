import ProfileBlack from '../../images/ProfileBlack.svg'
import InfoBlack from '../../images/InfoBlack.svg'

import ProfileActive from '../../images/UserCircleActive.svg'
import InfoActive from '../../images/InfoActive.svg'

export const menus = [
  {
    label: 'Cookie Policy',
    check: 'cookiePolicy',
    link: '/cookie/cookiePolicy',
    icon: ProfileBlack,
    iconActive: ProfileActive,
  },
  {
    label: 'Privacy Policy',
    check: 'privacyPolicy',
    link: '/cookie/privacyPolicy',
    icon: InfoBlack,
    iconActive: InfoActive,
  },
]
