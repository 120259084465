import moment from 'moment'
export const Language = {
  state: {
    locale: navigator.language.split('-')[0] || 'en',
  },
  reducers: {
    setLocale(state, payload) {
      return {
        ...state,
        locale: payload,
      }
    },
  },
  effects: () => ({
    async updateLocale(locale) {
      try {
        this.setLocale(locale)

        if (locale === 'th') {
          moment.locale('th')
        } else {
          moment.locale('en')
        }
      } catch (e) {
        throw e
      }
    },
  }),
}
