import React, { useState } from 'react'
import styled from 'styled-components'
import ClasswinEditor from 'components/classwinEditor/FeedEditor'

const Container = styled.div`
  display: flex;
  width: 120%;
`

function ClasswinInput({ locale, onSubmit, id, courseId }) {
  const [textInput, setTextInput] = useState()

  return (
    <Container>
      <ClasswinEditor
        placeholder={locale === 'en' ? 'Write comment...' : 'เขียนความคิดเห็นของคุณ...'}
        value={textInput}
        onChange={(value) => {
          setTextInput(value)
        }}
        showToolbar={false}
        noBorderRadius
        autoHeight
        style={{ width: '100%' }}
        fontSize="text-sm"
        padding={0}
        backgroundFeed
        isMainFeed
        isComment
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey === false && e.ctrlKey === false) {
            const obj = JSON.parse(textInput)
            if (obj.delta.ops.slice(-1)?.[0]?.insert) {
              obj.delta.ops.slice(-1)[0].insert = obj.delta.ops
                .slice(-1)?.[0]
                ?.insert?.replaceAll(/\n+/g, '\n')
            }
            obj.content = obj.content.replaceAll('<p><br></p>', '')
            const richMessage = JSON.stringify(obj)

            onSubmit?.({
              courseId: courseId ? courseId : null,
              feedId: id,
              richMessage: richMessage,
            })
            setTextInput(null)
          }
        }}
      />
    </Container>
  )
}

export default ClasswinInput
