import React from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'antd'
import {
  Card,
  Typography,
  Button,
  Header,
  Description,
  GroupDots,
  GroupButtons,
  Dots,
  Content,
  Slider,
  ContainerCarousel,
  ContainerContent,
  LangContainer,
} from './style'
import { DesktopAndTablet, SmallMobile } from 'utils/displayResponsive'
import { connect } from 'react-redux'

const CardGreeting = ({
  title,
  description,
  description2,
  titleId,
  descId,
  descId2,
  contents = [],
  btns = [],
  dots = [],
  component: Component,
  column,
  isLang = false,
  onClose,
  locale,
  profile,
}) => (
  <Card>
    <div
      style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer' }}
      onClick={onClose}
    >
      <img src="https://img.icons8.com/ios/20/undefined/delete-sign--v1.png" alt="iconClose" />
    </div>
    <Header>
      <Typography id={titleId} values={{ name: title }} h1>
        {title}
      </Typography>
      <Description id={descId} label1 fontSize="text-base" locale={locale} profile={profile}>
        {description}
      </Description>
      {description2 && (
        <Description id={descId2} style={{ color: '#253E87', fontWeight: 'bold' }}>
          {description2}
        </Description>
      )}
    </Header>
    {Component && <Component />}
    <DesktopAndTablet>
      <Content column={column} isLang={isLang}>
        {!Component && contents.length > 1 ? (
          contents.map((content, index) => (
            <Slider key={index} width={content?.width} maxWidth={content?.maxWidth} isLang={isLang}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {content?.image}
              </div>
              <ContainerContent>
                {content?.description}
                <div style={{ marginTop: 6 }}>{content?.subDescription}</div>
              </ContainerContent>
            </Slider>
          ))
        ) : (
          <div style={{ width: '100%' }}>
            {contents[0]?.image}
            {contents[0]?.description}
          </div>
        )}
      </Content>
    </DesktopAndTablet>
    <SmallMobile>
      {!Component && contents.length > 1 ? (
        <>
          {isLang ? (
            <LangContainer>
              {contents.map((content, index) => (
                <div
                  key={index}
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginBottom: 20,
                    justifyContent: 'center',
                  }}
                >
                  {content?.image}
                  {content?.description}
                  <div style={{ marginTop: 6 }}>{content?.subDescription}</div>
                </div>
              ))}
            </LangContainer>
          ) : (
            <ContainerCarousel>
              <Carousel autoplay dots={false} effect="fade">
                {contents.map((content, index) => (
                  <div key={index} style={{ width: '100%' }}>
                    {content?.image}
                    {content?.description}
                    <div style={{ marginTop: 6 }}>{content?.subDescription}</div>
                  </div>
                ))}
              </Carousel>
            </ContainerCarousel>
          )}
        </>
      ) : (
        <Content column={column}>
          <div style={{ width: '100%' }}>
            {contents[0]?.image}
            {contents[0]?.description}
          </div>
        </Content>
      )}
    </SmallMobile>

    <GroupDots>
      {dots.map((isDot, index) => (
        <Dots key={index} isDot={isDot} />
      ))}
    </GroupDots>

    <GroupButtons>
      {btns.map(({ text, action, type, id }, index) => (
        <Button
          key={index}
          primary
          fontSize="text-base"
          nowrap
          id={id}
          type={type}
          onClick={action}
        >
          {text}
        </Button>
      ))}
    </GroupButtons>
  </Card>
)

CardGreeting.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
  contents: PropTypes.array.isRequired,
  btns: PropTypes.array.isRequired,
  dots: PropTypes.array.isRequired,
}
const mapState = (state) => {
  return {
    locale: state.Language.locale,
  }
}

export default connect(mapState)(CardGreeting)
