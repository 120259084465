import Resource from '../utils/resource'

export default new Resource('/Courses', {
  putCourseSkill: {
    url: '{courseId}/skills/rel/{fk}',
    method: 'put',
  },
  deleteCourseSkill: {
    url: '{courseId}/skills/rel/{fk}',
    method: 'delete',
  },
  getCourseSkills: {
    url: '{id}/skills',
    method: 'get',
  },
  getCourseLivesById: {
    url: '{id}/courseLives/{fk}',
    method: 'get',
  },
  createCourseTransition: {
    url: '{id}/courseLives/{fk}/transition',
    method: 'post',
  },
  createCourseLives: {
    url: '{id}/courseLives',
    method: 'post',
  },
  getCourseLives: {
    url: '{id}/courseLives',
    method: 'get',
  },
  assignTeacher: {
    url: '{id}/teachers/rel/{fk}',
    method: 'put',
  },
  getMycourses: {
    url: '?filter={filter}',
    method: 'get',
  },
  getStudentReport: {
    url: '{id}/studentReport',
    method: 'get',
  },
  getCourse: {
    url: '{id}',
    method: 'get',
  },
  getCourseAssignments: {
    url: '{id}/assignments',
    method: 'get',
  },
  editCourse: {
    url: '{id}',
    method: 'patch',
  },
  duplicateCourse: {
    url: '{id}/duplicate',
    method: 'post',
  },
  getStudentsInCourse: {
    url: '{id}/students',
    method: 'get',
  },
  getCourseScheduleById: {
    url: '{id}/schedules',
    method: 'get',
  },
  getCourseScheduleByIdandDate: {
    url: '{id}/schedules?month={month}&year={year}',
    method: 'get',
  },
  getLecturesByCourseId: {
    url: '{id}/publishedSubject',
    method: 'get',
  },
  getCourseLectureByLectureId: {
    url: '{id}/lectures/{fk}',
    method: 'get',
  },
  updateCourseLectureById: {
    url: '{id}/lectures/{fk}',
    method: 'put',
  },
  updateCourseLectureContent: {
    url: '{id}/lectures/{fk}/contents',
    method: 'put',
  },
  removeCoureLectureById: {
    url: '{id}/lectures/{fk}',
    method: 'delete',
  },
  createLecture: {
    url: '{id}/lectures',
    method: 'post',
  },
  getMycourseswithDetails: {
    url: '{id}?filter={filter}',
    method: 'get',
  },
  getStudentByCourseId: {
    url: '{id}/students',
    method: 'get',
  },
  getAssignmentByCourseId: {
    url: '{id}/assignments',
    method: 'get',
  },
  getStudentSkill: {
    url: '{id}/studentSkills',
    method: 'get',
  },
  editContent: {
    url: '{id}/editContent',
    method: 'post',
  },
  getSeenTipLogs: {
    url: '{id}/getSeenTipLogs',
    method: 'get',
  },
  getCourseEvaluation: {
    url: '{id}/courseEvaluations',
    method: 'get',
  },
  putCourseEvaluation: {
    url: '{courseId}/courseEvaluations',
    method: 'put',
  },
  createCourseEvaluation: {
    url: '{courseId}/courseEvaluations',
    method: 'post',
  },
  getBrowseCourse: {
    url: 'browseCourse',
    method: 'get',
  },
  getBrowseCourseCount: {
    url: 'browseCourse/count',
    method: 'get',
  },
  getStudentCourseAssignmentScoresByCourseId: {
    url: '{id}/grades',
    method: 'get',
  },
  getCourseReportByCourseId: {
    url: '{id}/stat',
    method: 'get',
  },
  exportCourseGrade: {
    url: '{id}/reportGrade',
    method: 'get',
  },
  publishContent: {
    url: '{id}/publish-content',
    method: 'post',
  },
  getLearnersProgress: {
    url: '{id}/learnerProgress',
    method: 'get',
  },
  getInvitationLink: {
    url: '{id}/invitationLink',
    method: 'get',
  },
  updateInvitationLink: {
    url: '{id}/invitationLink',
    method: 'post',
  },
  checkInvitationLink: {
    url: '{id}/checkInvitationLink/{code}',
    method: 'post',
  },
  checkPromoCode: {
    url: '{id}/use-promocode',
    method: 'post',
  },
  getCourseNumberOfStudents: {
    url: '{id}/Enrollments/count',
    method: 'get',
  },
  getZoomSignature: {
    url: '{id}/zoom-signature',
    method: 'post',
  },
  getCourseMeetings: {
    url: '{id}/webinars',
    method: 'get',
  },
  addCourseMeeting: {
    url: '{id}/webinars',
    method: 'post',
  },
  getCourseMeetingById: {
    url: '{id}/webinars/{meetingId}',
    method: 'get',
  },
  deleteCourseMeetingById: {
    url: '{id}/webinars/{meetingId}',
    method: 'delete',
  },
  getStatus: {
    url: '{id}/status',
    method: 'get',
  },
  getCourseReviews: {
    url: '{id}/courseReviews',
    method: 'get',
  },
  getCourseReviewsCount: {
    url: '{id}/courseReviews/count',
    method: 'get',
  },
  cloneCourse: {
    url: '{id}/clone',
    method: 'post',
  },
})
