import Resource from '../utils/resource'

export default new Resource('/Assignments', {
  getAssignmentsById: {
    url: '{id}/assignmentWorksheets',
    method: 'get',
  },
  getStudent: {
    url: '{id}/studentAssignments',
    method: 'get',
  },
  addStudent: {
    url: '{id}/studentAssignments',
    method: 'post',
  },
  deleteStudent: {
    url: '{id}/studentAssignments/{fk}',
    method: 'delete',
  },
  getStats: {
    url: '{id}/stats',
    method: 'get',
  },
  getStatus: {
    url: '{id}/status',
    method: 'get',
  },
  getAssignmentsForStudent: {
    url: '{id}/student',
    method: 'get',
  },
  getAssignmentReportByAssignmentId: {
    url: '{id}/new-stat',
    method: 'get',
  },
  cloneAssignment: {
    url: '{id}/clone',
    method: 'post',
  },
})
