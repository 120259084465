import numeral from 'numeral'

export function formatLabel(value) {
  if (value === 0 || value === '0') {
    return '0'
  }
  if (value) {
    const format = numeral(value).format('0,0.00')
    return format
  }
  return '0'
}
export function formatLabelRadio(value) {
  if (value === 0 || value === '0') {
    return '0'
  }
  if (value) {
    const format = numeral(value).format('0,0')
    return format
  }
  return '0'
}
export function formatLabelRadioD(value) {
  if (value === 0 || value === '0') {
    return '0'
  }
  if (value) {
    const format = numeral(value).format('0,0.00')
    return format
  }
  return '0'
}
export function formatInput(value) {
  if (value === 0 || value === '0') {
    return '0'
  }
  if (value) {
    const format = numeral(value).format('0,0')
    return format
  }
  return '0'
}
export const ModalResize = (values) => {
  let isWidth
  if (values <= 430) {
    isWidth = '90%'
  } else {
    isWidth = 380
  }
  return isWidth
}
export const ModalResizeCongrat = (values) => {
  let isWidth
  let isHeight
  if (values <= 426) {
    isWidth = '90%'
    isHeight = 620
  } else if (values <= 768 && values > 426) {
    isWidth = '460px'
    isHeight = 620
  } else {
    isWidth = '650px'
    isHeight = 620
  }
  return { width: isWidth, height: isHeight, overflow: 'auto' }
}

export const sliceName = (firstName, lastName = '', cutLenght, limitLenght) => {
  const firstCharacter = firstName?.substring(0, 1)
  const restString = firstName?.substring(1)
  const firstNameConvert = firstCharacter?.toUpperCase() + restString

  const lastNameCharacter = lastName?.substring(0, 1)
  const restLastName = lastName?.substring(1)
  const lastNameConvert = lastNameCharacter?.toUpperCase() + restLastName

  if (`${firstNameConvert} ${lastNameConvert}`.length > limitLenght && limitLenght && cutLenght) {
    const sliceRes = `${firstNameConvert} ${lastNameConvert}`.slice(0, cutLenght)
    return sliceRes + '...'
  }

  return `${firstNameConvert} ${lastNameConvert}`
}
export const convertUpperCase = (stringToConvert, lastNameToConvert = '') => {
  const firstCharacter = stringToConvert?.substring(0, 1)
  const restString = stringToConvert?.substring(1)
  const firstName = firstCharacter?.toUpperCase() + restString

  const lastNameCharacter = lastNameToConvert?.substring(0, 1)
  const restLastName = lastNameToConvert?.substring(1)
  const lastName = lastNameCharacter?.toUpperCase() + restLastName
  return `${firstName} ${lastName}`
}
