import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ErrorMessage, Field } from 'formik'
import Modal from '../modal'
import DialogCondition from '../dialogCondition'
import Typography from '../typography'
import { Checkbox as CheckInput } from 'antd'

const Container = styled.div`
  margin: 16px 0;
`
const Label = styled.div`
  display: flex;
  align-items: flex-start;

  /* input {
    margin-right: 10px;
  } */

  .row {
    display: flex;
  }

  span {
    color: ${(props) => props.theme.primary1};
    margin: 0px 5px;
    font-size: ${({ theme }) => theme['text-sm']};
  }

  span:last-child {
    margin: 0px;
  }
  border-radius: 6px;
  .ant-checkbox-input {
    border-color: #253e87 !important;
    padding: 6px;
    &:hover {
      border-color: #253e87 !important;
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #253e87 !important;
    &:hover {
      border-color: #253e87 !important;
    }
  }
  .ant-checkbox-wrapper:hover {
    border-color: #253e87 !important;
  }
  .ant-checkbox .ant-checkbox-wrapper {
    border-color: #253e87 !important;
  }
  .ant-checkbox-inner {
    border-radius: 4px !important;
    &::after {
      top: 45%;
    }
    &:hover {
      border-color: #253e87 !important;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #253e87 !important;
  }
  .ant-checkbox-checked {
    border-radius: 4px !important;
    background-color: #253e87 !important;
    &:hover {
      background-color: #253e87 !important;
    }

    &::after {
      border-radius: 4px !important;
      border-color: #253e87 !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #253e87 !important;
    border-color: #253e87 !important;
    &:hover {
      background-color: #253e87 !important;
    }
  }
`

// const CheckboxInput = styled.div`
//   display: flex;
//   align-items: center;
// `
class Checkbox extends Component {
  state = {
    showCondition: false,
    type: '',
  }

  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
  }

  handleModal = (type) => (e) => {
    e.preventDefault()
    this.setState(({ showCondition }) => ({ showCondition: !showCondition, type }))
  }

  render() {
    const { name, locale } = this.props
    const { showCondition, type } = this.state
    return (
      <Field name={name}>
        {({ field, form }) => {
          return (
            <>
              <Container>
                <Label htmlFor={name}>
                  <CheckInput
                    style={{ marginRight: 6, borderColor: '#A9B6C6' }}
                    id={name}
                    checked={field.value}
                    onChange={form.handleChange}
                    {...this.props}
                  />

                  {/* <Typography className="row" label2>
                    I have read and accept
                    <Link onClick={this.handleModal('agreement')}>
                      <LinkText>Service Agreement</LinkText>
                    </Link>
                    and
                    <Link onClick={this.handleModal('policy')}>
                      <LinkText>Private Policy</LinkText>
                    </Link>
                    .
                  </Typography>
                   */}
                  <div style={{ lineHeight: '1' }}>
                    <Typography
                      id="signup.checkbox.title"
                      style={{ display: 'inline', color: '#000000' }}
                    >
                      I have read and accept
                    </Typography>
                    <span onClick={this.handleModal('agreement')}>
                      {locale === 'th' ? 'เงื่อนไขการใช้งาน' : 'Service Agreement'}
                    </span>
                    <Typography
                      id="signup.checkbox.and"
                      style={{ display: 'inline-flex', color: '#000000' }}
                    >
                      and
                    </Typography>
                    <span onClick={this.handleModal('policy')}>
                      {locale === 'th' ? 'นโยบายความเป็นส่วนตัว' : 'Private Policy'}
                    </span>
                    <Typography
                      id="signup.checkbox.end"
                      style={{ display: 'inline-flex', color: '#000000' }}
                    >
                      .
                    </Typography>
                  </div>
                </Label>
                <ErrorMessage
                  name="isAcceptedTerm"
                  render={(msg) => (
                    <div
                      style={{
                        marginTop: 5,
                        textTransform: 'none',
                        marginLeft: 23,
                      }}
                    >
                      <Typography fontSize="text-xs" color="danger">
                        {msg}
                      </Typography>
                    </div>
                  )}
                >
                  Can not be blank
                </ErrorMessage>
              </Container>

              <Modal
                isOpen={showCondition}
                onRequestClose={this.handleModal()}
                onClickClose={this.handleModal()}
                shownXclose
                contentStyle={{ width: '80%' }}
              >
                <DialogCondition type={type} />
              </Modal>
            </>
          )
        }}
      </Field>
    )
  }
}

export default Checkbox
