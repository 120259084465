import ProfileBlack from '../../images/ProfileBlack.svg'
import AcademyBlackIcon from '../../images/academyBlack.svg'
import MonitorPlayBlack from '../../images/MonitorPlayBlack.svg'
import UsersBlack from '../../images/UsersBlack.svg'
import CreditCardBlack from '../../images/CreditCardBlack.svg'
import InfoBlack from '../../images/InfoBlack.svg'

import ProfileActive from '../../images/UserCircleActive.svg'
import AcademyActive from '../../images/academyActive.svg'
import MonitorPlayActive from '../../images/MonitorPlayActive.svg'
import UsersActive from '../../images/UsersActive.svg'
import CreditCardActive from '../../images/CreditCardActive2.svg'
import InfoActive from '../../images/InfoActive.svg'

export const menus = [
  {
    // id: 'profile.profile.title',
    label: 'เข้าสู่โหมดผู้สอน',
    check: 'teacherMode',
    link: '/tutorialTeacher/teacherMode',
    icon: ProfileBlack,
    iconActive: ProfileActive,
  },
  {
    // id: 'profile.intro.title',
    label: 'การสร้าง Academy',
    check: 'createAcademy',
    link: '/tutorialTeacher/createAcademy',
    icon: AcademyBlackIcon,
    iconActive: AcademyActive,
  },
]

export const menusTeacher = [
  {
    // id: 'profile.profile.title',
    label: 'เข้าสู่โหมดผู้สอน',
    check: 'teacherMode',
    link: '/tutorialTeacher/teacherMode',
    icon: ProfileBlack,
    iconActive: ProfileActive,
  },
  {
    // id: 'profile.intro.title',
    label: 'การสร้าง Academy',
    check: 'createAcademy',
    link: '/tutorialTeacher/createAcademy',
    icon: AcademyBlackIcon,
    iconActive: AcademyActive,
  },
  {
    // id: 'profile.intro.title',
    label: 'การสร้างคอร์สเรียน',
    check: 'createCourse',
    link: '/tutorialTeacher/createCourse',
    icon: MonitorPlayBlack,
    iconActive: MonitorPlayActive,
  },
  {
    // id: 'profile.intro.title',
    label: 'เพิ่มผู้เรียนและผู้สอนในสถาบัน',
    check: 'institution',
    link: '/tutorialTeacher/institution',
    icon: UsersBlack,
    iconActive: UsersActive,
  },
  {
    // id: 'profile.intro.title',
    label: 'การรับเงินค่าคอร์ส',
    check: 'gettingCourse',
    link: '/tutorialTeacher/gettingCourse',
    icon: CreditCardBlack,
    iconActive: CreditCardActive,
  },
  {
    // id: 'profile.intro.title',
    label: 'โพสต์และแก้ไขข้อมูลใน Academy',
    check: 'academyInformation',
    link: '/tutorialTeacher/academyInformation',
    icon: InfoBlack,
    iconActive: InfoActive,
  },
]
