import appUserApi from '../../api/user'

export const Schedule = {
  state: {
    events: [],
  },
  reducers: {
    setEvent(state, payload) {
      return {
        ...state,
        events: payload,
      }
    },
  },
  effects: () => ({
    async fetchEvent() {
      const schedules = await appUserApi.getStudentSchedule()
      const events = await appUserApi.getEvents()
      const reformat = events.map((event) => {
        return {
          date: event.startTime,
          schedules: [
            {
              allDay: false,
              isDelete: false,
              ...event,
            },
          ],
        }
      })
      this.setEvent([...reformat, ...schedules])
    },
  }),
  selectors: (/* slice, createSelector, hasProps */) => ({
    myEvents() {
      return (rootState) => {
        const { events } = rootState.Schedule
        const newEvents = events.reduce((final, item) => {
          return [
            ...final,
            ...item.schedules.map((obj) => ({
              ...obj,
              start: new Date(obj.startTime),
              end: new Date(obj.endTime),
            })),
          ]
        }, [])
        return newEvents
      }
    },
  }),
}
