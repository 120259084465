import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'
import { Typography } from 'components/typography/style'
import logo from 'images/Classwin logo_Classwin Horizontal - Blue.png'
import 'moment/locale/th'

const Wrapper = styled.div`
  text-align: center;
`
const Logo = styled.img`
  width: 250px;
`

const formatTH = 'วัน ddddที่ D MMMM YYYY เวลา H:mm น.'
const formatEN = 'dddd Do MMMM YYYY h.mm A'

const ClosedServerModal = ({ startTH, endTH, startEN, endEN }) => {
  return (
    <Wrapper>
      <Logo alt="logo" src={logo} />
      <Typography h1 style={{ padding: '20px 0px 20px 0px' }}>
        แจ้งปิดปรับปรุงระบบ
      </Typography>
      <Typography bold style={{ marginBottom: 10 }}>
        เรียนผู้ใช้งานทุกท่าน
      </Typography>
      <Typography>
        บริษัท คลาสวิน จำกัด ขออภัยในความไม่สะดวก
        เนื่องจากบริษัทจะมีการดำเนินการพัฒนาระบบและเพิ่มประสิทธิภาพการบริการให้ดียิ่งขึ้น
        จึงจะปิดระบบใน
      </Typography>
      <Typography bold color="primary1" fontSize="text-lg" style={{ padding: '20px 0px 20px 0px' }}>
        {startTH} ถึง {endTH}
      </Typography>
      <Typography>ซึ่งจะส่งผลให้ผู้ใช้งานไม่สามารถใช้บริการคลาสวินตามวันและเวลาดังกล่าว</Typography>
      <Typography style={{ marginBottom: 10 }}>
        บริษัท คลาสวิน จำกัด กราบขออภัยเป็นอย่างสูงในความไม่สะดวกที่เกิดขึ้น
      </Typography>
      <Typography>ทีมงานคลาสวิน</Typography>
      <div style={{ padding: 10 }} />
      <hr />
      <div style={{ padding: 10 }} />
      <Typography bold style={{ marginBottom: 10 }}>
        Dear customers,
      </Typography>
      <Typography>
        We are sincerely apologize to announce that our website (www.classwin.com) will be
        undergoing a scheduled constructive maintenance on
      </Typography>
      <Typography bold color="primary1" fontSize="text-lg" style={{ padding: '20px 0px 20px 0px' }}>
        {startEN} to {endEN}
      </Typography>
      <Typography>
        to improve the service quality. As a result, our website will be temporarily unavailable
        during the mentioned date and time.
      </Typography>
      <Typography>We are deeply apologize for the inconvenience.</Typography>
      <Typography style={{ marginBottom: 10 }}>Best Regards,</Typography>
      <Typography>Classwin Team</Typography>
    </Wrapper>
  )
}
const mapState = ({ ClosedServer: { start, end } }) => ({
  startTH: moment(start).locale('th').format(formatTH),
  endTH: moment(end).locale('th').format(formatTH),
  startEN: moment(start).locale('en').format(formatEN),
  endEN: moment(end).locale('en').format(formatEN),
})

export default connect(mapState)(ClosedServerModal)
