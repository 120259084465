import React from 'react'
import PropTypes from 'prop-types'
import { LogoStyle } from './style'
import primary1 from '../../images/Classwin logo_Classwin Horizontal - Blue.svg'
import primary2 from '../../images/Classwin logo_Classwin Horizontal - White 2.png'
import primary3 from '../../images/Classwin-logo.svg'
import primary4 from '../../images/Classwin logo - Horizontal_Classwin C Square - Blue.png'
import secondary1 from '../../images/Classwin logo_Classwin C - Blue.svg'
import secondary2 from '../../images/Classwin logo_Classwin C - White.svg'
import secondary3 from '../../images/icons/Classwin-logo-small.svg'
import secondary4 from '../../images/ClasswinC.svg'
import primary5 from 'images/icons/IconLandingPage/ClasswinLogoLandingWhite.svg'

const images = {
  primary1: primary1,
  primary2: primary2,
  primary3: primary3,
  secondary1: secondary1,
  secondary2: secondary2,
  primary4: primary4,
  secondary3: secondary3,
  secondary4: secondary4,
  primary5: primary5,
}

function LogoClasswin({ type, ...props }) {
  return <LogoStyle src={images[type]} {...props} />
}

LogoClasswin.propTypes = {
  props: PropTypes.object,
}

LogoClasswin.defaultProps = {
  props: {},
}

export default LogoClasswin
