import styled from 'styled-components'
import ArrowIcon from '../../images/Classwin - New icon_Check - White.svg'

const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  // border: 1px solid ${(props) => props.theme.black20};
  // border-radius: 4px;

  :hover {
    // opacity: 0.8;
    // border-color: ${(props) => props.theme.black20};
  }
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 5%;
  padding-bottom: 12px;
`

const InputAddon = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  // background-color: rgba(87, 136, 252, 0.1);
  height: 100%;
  position: relative;

  input[name='answer'] {
    -webkit-appearance: none;
    /* background-color: #fafafa; */

    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    /* position: relative; */
    position: absolute;
    top: 45%;
    left: 0px;
    background-color: white;
    border: 0.5px solid ${(props) => props.theme.black20};
    &:checked {
      border: 0.5px solid ${(props) => props.theme.primary1};
      background-color: ${(props) => props.theme.primary1};
      &:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;

        background-image: url('${ArrowIcon}');
        background-size: 18px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        display: inline-block;
        width: 14px;
        height: 10px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
      }
    }
  }
`

export { InputGroup, InputAddon, Container, Item }
