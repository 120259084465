import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import ErrorBoundary from '../errorBoundary'
import Typography from '../typography'
import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'
import ArrowIcon from '../../images/ic-next-gray-24-px.svg'

const Active = styled(Typography)`
  color: ${(props) => props.theme.primary2};
  font-size: ${({ theme }) => theme['text-sm']};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;

  :hover {
    color: ${(props) => props.theme.primary1};
  }
`

const InActive = styled((props) => <Typography {...props} />)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`

const Container = styled.div`
  background-color: #fff;
  padding: 8px 150px;
  z-index: 4;
  box-shadow: ${({ shadow }) => (shadow ? '0 5px 5px 0 rgba(0, 0, 0, 0.1)' : 'none')};
  font-weight: 500;
  /* color: ; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 60px;
  border-bottom: ${({ border }) => (border ? '1px solid #eef0ff' : 'none')};
  @media (max-width: 1024px) {
    padding: 8px 50px;
  }
  @media (max-width: 768px) {
    padding: 20px 12px;
  }

  > div,
  > a {
    font-size: ${({ theme }) => theme['text-sm']};
    color: inherit;
    font-weight: 700;
    :not(:last-child) {
      margin-right: 4px;
    }
  }

  .flex {
    display: flex;
    align-items: center;

    img {
      margin-right: 4px;
      transform: translateY(-1px);
      height: 18px;
      width: 18px;
    }

    img.reverse {
      margin-right: 0px;
      transform: rotate(180deg);
    }
  }
`

const Link = ({ to, className, beforeLink, children }) => {
  const history = useHistory()
  const [allowLink, setAllowLink] = useState(false)

  const handleClick = () => {
    if (beforeLink) {
      beforeLink?.(setAllowLink)
    } else {
      history.push(to)
    }
  }
  useEffect(() => {
    if (allowLink) {
      history.push(to)
    }
  }, [history, to, allowLink])
  return (
    <div style={{ cursor: 'pointer' }} className={className} onClick={handleClick}>
      {children}
    </div>
  )
}

class Breadcrumb extends Component {
  static propTypes = {}

  render() {
    const {
      data,
      firstLabel,
      link = '/',
      shadow = true,
      border = false,
      firstLabelId,
      beforeLink,
    } = this.props
    return (
      <ErrorBoundary>
        <Container shadow={shadow} border={border}>
          <LargeDesktop>
            <Link to={link} beforeLink={beforeLink}>
              {/* <Typography label2 id={firstLabelId}>
                {firstLabel}
              </Typography> */}
              <Active id={firstLabelId}>{firstLabel}</Active>
            </Link>
            {data.map((item) => {
              return item.isActive ? (
                <div key={`${item.label}`} className="active flex">
                  <img src={ArrowIcon} alt="arrow-icon" />
                  <InActive id={item.id} label2 color="gray6">
                    {item.label}
                  </InActive>
                </div>
              ) : (
                <Link key={item.label} className="flex" to={item.link} beforeLink={beforeLink}>
                  <img src={ArrowIcon} alt="arrow-icon" />
                  <Active id={item.id}>{item.label}</Active>
                </Link>
              )
            })}
          </LargeDesktop>
          <MobileAndTablet>
            <Link to={link} beforeLink={beforeLink}>
              <Active id={firstLabelId}>{firstLabel}</Active>
            </Link>
            {data
              .filter((item) => item.isMobile)
              .map((item) => {
                return item.isActive ? (
                  <Link key={item.label} className="flex" to={item.link} beforeLink={beforeLink}>
                    <img src={ArrowIcon} alt="arrow-icon" />
                    <InActive id={item.id} margin="0px 0px 0px 4px" label2 color="primary1">
                      {item.mobileLabel || item.label}
                    </InActive>
                  </Link>
                ) : (
                  <Link key={item.label} className="flex" to={item.link} beforeLink={beforeLink}>
                    <img src={ArrowIcon} alt="arrow-icon" />
                    <Active id={item.id}>{item.label}</Active>
                  </Link>
                )
              })}
          </MobileAndTablet>
        </Container>
      </ErrorBoundary>
    )
  }
}

Breadcrumb.propTypes = {
  data: PropTypes.array,
}

Breadcrumb.defaultProps = {
  data: [],
}

export default Breadcrumb
