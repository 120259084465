import styled from 'styled-components'
import { Link } from 'react-router-dom'

const PasswordInput = styled.div`
  position: relative;

  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    padding-right: 40px;
  }

  .icon {
    position: absolute;
    top: 38px;
    right: 10px;
    width: 18px;
    height: 18px;
  }
`
const Grid = styled.div`
  float: left;
  width: 50%;
`
const Space = styled.div`
  padding: 0px 10px;
`
const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) => (props.isModal ? '0' : '50px')};
`
const Image = styled.img`
  width: 130px;
  height: 130px;
`
const Container = styled.div`
  overflow-y: scroll;
  display: flex;
  margin: 74px 0px 0px 0px;
`
const FormContainer = styled.div`
  //overflow-y: scroll;
  // padding: 0px 60px 45px 60px;
  padding: ${(props) => (props.isModal ? '0px' : '0px 60px 45px 60px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    height: 100%;
  }
`
const CustomLink = styled(Link)`
  color: red;
`

export const ClasswinLogo = styled.img`
  height: 40px;
  @media (max-width: 426px) {
    height: 36px;
  }
`
export const ContainerLogo = styled.div`
  position: absolute;
  left: 50px;
  top: 24px;
  display: flex;
  justify-content: space-between;
  width: 45px;
  @media (max-width: 429px) {
    top: 25px;
    left: 35px;
  }
`
export const ContainerLanguage = styled.div`
  position: absolute;
  right: 55px;
  top: 29px;
  display: flex;
  justify-content: space-between;
  width: 45px;
  @media (max-width: 426px) {
    top: 25px;
    right: 30px;
  }
`

export { FormContainer, Container, Image, ImageBox, Space, Grid, PasswordInput, CustomLink }
