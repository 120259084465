import React from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from '../errorBoundary'
import { Container, Wrapper, Countdown, Icon, Key } from './style.js'
import CopyIcon from '../../images/ic-copy.svg'

class StreamKey extends React.Component {
  state = {
    count: 15,
    isHide: true,
    copied: false,
  }

  countdown = () => {
    const myInterval = window.setInterval(() => {
      this.setState((prevState) => {
        if (prevState.count > 1) {
          return {
            count: prevState.count - 1,
            copied: false,
          }
        } else {
          // timeout
          window.clearInterval(myInterval)
          return { isHide: true, count: 15 }
        }
      })
    }, 1000)
  }

  handleShow = () => {
    this.setState({ isHide: false }, this.countdown)
  }

  handleCopy = async () => {
    const { streamKey } = this.props
    try {
      await navigator.clipboard.writeText(streamKey)
      this.setState({ copied: true })
    } catch (error) {
      console.error(error)
      console.error('Can not copy into clipbroad')
    }
  }

  render() {
    const { count, isHide, copied } = this.state
    const { streamKey } = this.props
    if (isHide) {
      return (
        <ErrorBoundary>
          <Container column="1" padding="0px">
            <Wrapper onClick={this.handleShow}>
              <Key h5 color="#585858" style={{ filter: 'blur(6px)' }}>
                {streamKey}
              </Key>
              <Key h5 strong color="#585858">
                Click to Show
              </Key>
            </Wrapper>
          </Container>
        </ErrorBoundary>
      )
    }
    return (
      <ErrorBoundary>
        <Container column="1" padding="0px">
          <Wrapper onClick={this.handleCopy}>
            <Countdown strong color="#A9A8A8">
              {count}
            </Countdown>
            <Key h5 color="#585858">
              {copied ? 'Copied' : streamKey}
            </Key>
            <Icon src={CopyIcon} alt="copy" />
          </Wrapper>
        </Container>
      </ErrorBoundary>
    )
  }
}

StreamKey.propTypes = {
  streamKey: PropTypes.string.isRequired,
}

export default StreamKey
