import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { FieldArray, Formik, Form } from 'formik'
import { darken } from 'polished'
import * as Yup from 'yup'
import get from 'lodash/get'

import { getShowTest } from '../../utils/utils'
import ErrorBoundary from '../../components/errorBoundary'
import TeacherLectureCreateEditForm from '../../components/teacherLectureCreateEditForm/loadable'
import { Footer } from '../../components/styledComponents'
import Button from '../../components/button'
import PlusIcon from '../../images/ic-plus-blue-24-px.svg'
// import store from '../../store'

const validationSchema = Yup.object().shape({
  forms: Yup.array()
    .of(
      Yup.object().shape({
        rank: Yup.number(),
        name: Yup.string().min(3, 'too short').required('Required'), // these constraints take precedence
        description: Yup.string(),
        // .required('Required')
        // .min(3, 'too short'),
        content: Yup.string().min(3, 'cmon').required('Required'), // these constraints take precedence
        videoLink: Yup.string().url('ex : http://youtube.com'),
      })
    )
    .required('Need at least one content') // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Need at least one content'),
})
const Container = styled.div`
  margin-top: 30px;
  padding-bottom: 80px;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`

const ButtonGroup = styled.div`
  margin-left: auto;

  button {
    width: 100px;

    & + button {
      margin-left: 10px;
    }
  }
`

const CreateContentButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 80px;
  width: calc(100vw - 200px);
  height: 80px;
  background-color: ${(props) => props.theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #fff;
  font-size: ${({ theme }) => theme['text-xl']};
  cursor: pointer;

  &:hover {
    ${(props) => css`
      background-color: ${darken(0.1, props.theme.primary)};
    `}
  }

  span {
    display: flex;
    background-color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  img {
    margin: auto;
    width: 24px;
    height: 24px;
  }
`

class TeacherCourseLectureEditContentTab extends Component {
  state = {
    // ! forms: [
    //   {
    //     id: 1,
    //   },
    // ],
  }

  // ! handleDelete = (id) => () => {
  //   const confirm = window.confirm('Are you sure')
  //   if (confirm) {
  //     this.setState((state) => ({ forms: state.forms.filter((form) => form.id !== id) }))
  //   }
  // }

  // ! addForm = () => {
  //   const lastId = this.state.forms[this.state.forms.length - 1].id
  //   const obj = { id: lastId + 1 }
  //   this.setState((state) => ({ forms: [...state.forms, obj] }))
  // }

  async componentDidMount() {
    const {
      getCourseLectureByLectureId,
      match: {
        params: { lectureId, courseId },
      },
    } = this.props
    await getCourseLectureByLectureId({ courseId, lectureId })
  }

  handleSubmit = (values) => {
    const { updateCourseLectureContent } = this.props
    updateCourseLectureContent(values.forms)
  }

  render() {
    const { lectures } = this.props

    if (!lectures || !lectures.contents) {
      return null
    }
    const contents = lectures.contents.map((item) => {
      const lecture = item
      const preTest = (item.lectureWorksheets || []).find((test) => test.type === 'pretest')
      const postTest = (item.lectureWorksheets || []).find((test) => test.type === 'posttest')
      lecture.preTest = get(preTest, 'publishedWorksheet.data')
      lecture.postTest = get(postTest, 'publishedWorksheet.data')
      lecture.showTest = getShowTest(!!preTest, !!postTest)
      return lecture
    })

    return (
      <ErrorBoundary>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ forms: [...contents] }}
          onSubmit={this.handleSubmit}
        >
          {({ values }) => {
            return (
              <Form>
                <FieldArray
                  name="forms"
                  render={({ move, push, form, remove }) => {
                    const formLength = form.values.forms.length
                    const editFromProps = { formLength, move, form }

                    return (
                      <>
                        <Container>
                          {values.forms.map((item, index) => (
                            <TeacherLectureCreateEditForm
                              formName={`forms.${index}`}
                              index={index}
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${item.id} ${index} course`}
                              // {...item}
                              handleDelete={remove}
                              {...editFromProps}
                              disabledAddTest
                            />
                          ))}
                        </Container>
                        <CreateContentButton
                          type="button"
                          onClick={() =>
                            push({
                              rank: values.forms.length,
                              // id: uniqueId(),
                              name: '',
                              description: '',
                              content: '',
                            })
                          }
                        >
                          <span>
                            <img src={PlusIcon} alt="plus-icon" />
                          </span>
                          Create Content
                        </CreateContentButton>
                      </>
                    )
                  }}
                />
                <Footer>
                  <ButtonGroup>
                    <Button type="button" secondary>
                      Cancel
                    </Button>
                    <Button type="submit" primary>
                      Save
                    </Button>
                  </ButtonGroup>
                </Footer>
              </Form>
            )
          }}
        </Formik>
      </ErrorBoundary>
    )
  }
}

// const selection = store.select((models) => ({
//   lectures: models.teachingCourse.lectures,
// }))

const mapState = (state) => ({
  appUser: state.AppUser,
  lectures: state.teachingCourse.lectureInfo,
})

const mapDispatch = (dispatch) => {
  const {
    AppUser: { login },
    teachingCourse: { getCourseLectureByLectureId, updateCourseLectureContent },
  } = dispatch
  return {
    login,
    getCourseLectureByLectureId,
    updateCourseLectureContent,
  }
}

export default connect(mapState, mapDispatch)(TeacherCourseLectureEditContentTab)
