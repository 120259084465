import Resource from '../utils/resource'

export default new Resource('/Schools', {
  updateSchoolFeed: {
    url: '{id}/feeds/{fk}',
    method: 'put',
  },
  deleteSchoolFeed: {
    url: '{id}/feeds/{fk}',
    method: 'delete',
  },
  postSchoolLike: {
    url: '{id}/likeFeed/{feedId}',
    method: 'post',
  },
  getSchoolFeed: {
    url: '{id}/feeds',
    method: 'get',
  },
  getSchoolCountFeed: {
    url: '{id}/feeds/count',
    method: 'get',
  },
  postSchoolFeed: {
    url: '{id}/feeds',
    method: 'post',
  },
  getOwnerDetails: {
    url: '{id}/schoolOwnerDetails',
    method: 'get',
  },
  getCurrentPackage: {
    url: '{id}/current-package',
    method: 'get',
  },
  createOwnerDetails: {
    url: '{id}/schoolOwnerDetails',
    method: 'post',
  },
  updateOwnerDetails: {
    url: '{id}/schoolOwnerDetails',
    method: 'put',
  },
  getTransactions: {
    url: '{id}/schoolTransactions',
    method: 'get',
  },
  updateSections: {
    url: '{id}/sections',
    method: 'put',
  },
  getSections: {
    url: '{id}/sections',
    method: 'get',
  },
  getGroupById: {
    url: '{id}/groups/{groupId}',
    method: 'get',
  },
  createGroups: {
    url: '{id}/groups',
    method: 'post',
  },
  getCertificates: {
    url: '{id}/certifications',
    method: 'get',
  },
  getCertificatesCount: {
    url: '{id}/certifications/count',
    method: 'get',
  },
  getpreviewCertificate: {
    url: '{id}/previewCertificate',
    method: 'get',
  },
  buySubject: {
    url: '{id}/schoolSubjects',
    method: 'post',
  },
  updateSubject: {
    url: '{id}/schoolSubjects/{sid}',
    method: 'patch',
  },
  deleteSubject: {
    url: '{id}/schoolSubjects/{sid}',
    method: 'delete',
  },
  getSubjects: {
    url: '{sid}/publishedSubjects',
    method: 'get',
  },
  getSchoolProfile: {
    url: '{id}',
    method: 'get',
  },
  updateSchoolProfile: {
    url: '{id}',
    method: 'patch',
  },
  getTeachers: {
    url: '{id}/schoolMembers',
    method: 'get',
  },
  inviteTeacher: {
    url: '{id}/inviteTeacher',
    method: 'post',
  },
  inviteStudent: {
    url: '{id}/inviteStudent',
    method: 'post',
  },
  countTeachers: {
    url: '{id}/schoolMembers/count',
    method: 'get',
  },
  countCourse: {
    url: '{id}/courses/count',
    method: 'get',
  },
  countDashboard: {
    url: '{id}/getDashboardData',
    method: 'get',
  },
  courseEnrollment: {
    url: '{id}/courses',
    method: 'get',
  },
  getStudentInSchool: {
    url: '{id}/schoolStudents',
    method: 'get',
  },
  getSchoolStudentsCount: {
    url: '{id}/schoolStudents/count',
    method: 'get',
  },
  getFilteredStudentInSchool: {
    url: '{id}/getFilteredSchoolStudents',
    method: 'get',
  },
  getStudentsDetail: {
    url: '{id}/students',
    method: 'get',
  },
  getStudentDetail: {
    url: '{id}/students/{fk}',
    method: 'get',
  },
  getRewards: {
    url: '{id}/rewards',
    method: 'get',
  },
  getTeachersDetail: {
    url: '{id}/members',
    method: 'get',
  },
  getEnrollments: {
    url: '{id}/enrollments',
    method: 'get',
  },
  getStat: {
    url: '{id}/dashboard',
    method: 'get',
  },
  getOverviewMonthly: {
    url: '{id}/stat/month',
    method: 'get',
  },
  getOverviewYearly: {
    url: '{id}/stat/year',
    method: 'get',
  },
  getSubjectStat: {
    url: '{id}/subjectStat',
    method: 'get',
  },
  getCourseStat: {
    url: '{id}/courseStat',
    method: 'get',
  },
  getSchoolCourse: {
    url: '{id}/courses',
    method: 'get',
  },
  getSchoolCourseAll: {
    url: '{id}/all-courses',
    method: 'get',
  },
  getSchoolCourseAllCount: {
    url: '{id}/all-courses/count',
    method: 'get',
  },
  getLastChildGroup: {
    url: '{id}/lastChildGroup',
    method: 'get',
  },
  genBlockchainAddress: {
    url: '{id}/genBlockchainAddress',
    method: 'post',
  },
  deleteSchoolMember: {
    url: '{id}/members/{fk}',
    method: 'delete',
  },
  deleteSchoolMemberRequest: {
    url: '{id}/SchoolMembers/{fk}',
    method: 'delete',
  },
  getBrowseSchool: {
    url: 'browseSchool?filter={filter}',
    method: 'get',
  },
  getBrowseSchoolCount: {
    url: 'browseSchool/count?where={where}',
    method: 'get',
  },
  // ! SchoolTags
  getSchoolTags: {
    url: '{id}/getSchoolTags',
    method: 'get',
  },
  updateSchoolTags: {
    url: '{id}/updateSchoolTags',
    method: 'put',
  },
  getStudentTags: {
    url: '{id}/getStudentTags',
    method: 'get',
  },
  updateStudentTags: {
    url: '{id}/updateStudentTags',
    method: 'put',
  },
  createDraft: {
    url: '{id}/draftCourses',
    method: 'post',
  },
  getDraftList: {
    url: '{id}/draftCourses',
    method: 'get',
  },
  deleteDraft: {
    url: '{id}/draftCourses/{fk}',
    method: 'delete',
  },
  getBanks: {
    url: '{schoolId}/schoolBanks',
    method: 'get',
  },
  sendVerifyDocument: {
    url: '{schoolId}/verify-document',
    method: 'post',
  },
  getSchoolCourseReport: {
    url: '{id}/courses/report',
    method: 'get',
  },
  subscribePackage: {
    url: '{id}/subscribePackage',
    method: 'post',
  },
  checkSchoolPaymentStatus: {
    url: '{id}/payment-status',
    method: 'get',
  },
  getCards: {
    url: '{id}/cards',
    method: 'get',
  },
  newCard: {
    url: '{id}/newCard',
    method: 'post',
  },
  getSchoolPayments: {
    url: '{id}/schoolPayments',
    method: 'get',
  },
  getSchoolIncomes: {
    url: '{id}/schoolIncomes',
    method: 'get',
  },
  getSchoolIncomeStat: {
    url: '{id}/schoolIncomeStat',
    method: 'get',
  },
  checkSchoolNameExists: {
    url: 'name-exists',
    method: 'post',
  },
  getSchoolTransfers: {
    url: '{id}/schoolTransfers',
    method: 'get',
  },
  getSchoolTransfersCount: {
    url: '{id}/schoolTransfers/count',
    method: 'get',
  },
  getSchoolTransferDetail: {
    url: '{id}/schoolTransfers/{schoolTransferId}/detail',
    method: 'get',
  },
  getSchoolDashboardGraph: {
    url: '{id}/dashboard-graph',
    method: 'get',
  },

  getLearnerGroups: {
    url: '{id}/learnerGroups',
    method: 'get',
  },

  createLearnerGroups: {
    url: '{id}/learnerGroups',
    method: 'post',
  },

  // deleteLearnerGroups: {
  //   url: '{id}/learnerGroups',
  //   method: 'delete',
  // },

  findLearnerGroupsbyId: {
    url: '{id}/learnerGroups/{fk}',
    method: 'get',
  },

  updateLearnerGroupsbyId: {
    url: '{id}/learnerGroups/{fk}',
    method: 'put',
  },

  deleteLearnerGroupsbyId: {
    url: '{id}/learnerGroups/{fk}',
    method: 'delete',
  },

  countLearnerGroups: {
    url: '{id}/learnerGroups/count',
    method: 'get',
  },
  exportStudents: {
    url: '{id}/exportStudents',
    method: 'get',
  },
})
