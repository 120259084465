import { CourseCategoriesAPI, CourseLevelAPI } from '../../api'

export const CourseCategories = {
  state: {
    courseCategories: [],
    categoriesMarketplace: [],
  },
  reducers: {
    setCourseCategories(state, payload) {
      return {
        ...state,
        courseCategories: payload,
      }
    },
    setCategoriesMarketplace(state, payload) {
      return {
        ...state,
        categoriesMarketplace: payload,
      }
    },
  },
  effects: () => ({
    async getCourseCategories(filterEmpty) {
      try {
        if (filterEmpty) {
          const filter = JSON.stringify({
            where: { courses: 'notEmpty', subcategories: 'haveCourse' },
            order: 'priority ASC',
            include: {
              relation: 'subcategories',
              scope: {
                order: 'priority ASC',
              },
            },
          })
          const res = await CourseCategoriesAPI.find({ filter })

          this.setCourseCategories(res)
        } else {
          const filter = JSON.stringify({
            include: {
              relation: 'subcategories',
              scope: {
                order: 'priority ASC',
              },
            },
          })
          const res = await CourseCategoriesAPI.find({ filter })
          this.setCourseCategories(res)
        }
      } catch (error) {
        throw error
      }
    },
    async getCourseCategoriesWithCount() {
      try {
        const res = await CourseCategoriesAPI.getCategories()
        this.setCourseCategories(res)
      } catch (error) {
        throw error
      }
    },
    async getMarketplaceCategoriesWithCount() {
      try {
        const res = await CourseCategoriesAPI.getCategorieMarketplace()
        this.setCategoriesMarketplace(res)
      } catch (error) {
        throw error
      }
    },
  }),
}

export const CourseLevels = {
  state: {
    courseLevels: [],
  },
  reducers: {
    setCourseLevels(state, payload) {
      return {
        ...state,
        courseLevels: payload,
      }
    },
  },
  effects: () => ({
    async getCourseLevels() {
      try {
        const res = await CourseLevelAPI.find()
        this.setCourseLevels(res)
      } catch (error) {
        throw error
      }
    },
  }),
}
