/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import _ from 'lodash'
import styled, { css } from 'styled-components'
// import PageHeading from '../../components/pageHeading/loadable'
import FilterMyCourse from 'components/FilterMyCourse'
import _orderBy from 'lodash/orderBy'
import moment from 'moment'
// import MyCoursePage from '../../containers/myCoursePage'
// import AssignmentPage from '../../containers/assignmentPage'
// import SchoolPage from '../../containers/schoolPage'
import theme from '../../theme'
import Typography from 'components/typography'
import TabComponent from 'components/tabComponent'
import get from 'lodash/get'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 34px;
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  //background: #fff;
  //border-top: 1px solid ${theme.gray8};

  padding: 0px 0px 0px 0px;
  height: 60px;
  @media (max-width: 1024px) {
    padding: 8px 30px;
  }
  @media (max-width: 768px) {
    padding: 8px 20px 8px 0px;
    button {
      padding: 6px;
    }
  }
  @media (max-width: 430px) {
    padding: 8px 0px;
  }
`

const Header = styled.div`
  height: 60;
  //padding: 15px 150px;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 150px;
  gap: 32px;
  @media (max-width: 1024px) {
    padding: 15px 0px;
  }
  @media (max-width: 768px) {
    padding: 15px 0px;
  }
`

const HeadDetail = styled.div`
  display: flex;
  align-items: center;
  //box-shadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)';
  padding: 0px 150px 0px 0px;
  @media (max-width: 1024px) {
    padding-right: 0px;
  }
  @media (max-width: 768px) {
    padding-right: 0px;
  }
  @media (max-width: 430px) {
    padding: 8px 20px;
  }
`
const TabCard = styled.div`
  ${({ border }) => {
    if (border) {
      return css`
        border-bottom: 2px solid ${theme.primary1};
      `
    }
  }}
  //border-bottom:  2px solid ${theme.primary1};
  padding: 4px 8px;
`
const MyCourseLayout = (props) => {
  const {
    match,
    //isLiff,
    locale,
    myCourseList,
    getMyCourseList,
    getMyAssignmentList,
    myAssignmentList,
    filter,
    setFilter,
    children,
  } = props
  const locationPath = props.location.pathname.split('/').slice(-1)[0]
  const [activePath, setActivePath] = useState(
    `/${props.location.pathname.split('/').slice(-1)[0]}`
  )
  const { currentCourse, completed } = myCourseList
  useEffect(() => {
    getMyCourseList()
    getMyAssignmentList()
  }, [getMyCourseList, getMyAssignmentList])

  const countCourse = (currentCourse, completed) => [currentCourse.length, completed.length]
  const amountCourse = countCourse(currentCourse, completed)

  const tabData = [
    {
      id: 'tab.label.Course',
      label: 'Courses',
      key: '0',
      component: null,
      path: '/courses',
      style: { height: 30 },
    },
    // {
    //   id: 'tab.label.Assignment',
    //   label: 'Assignment',
    //   key: '1',
    //   component: null,
    //   path: '/assignments',
    // },
    {
      id: 'tab.label.Academy',
      label: 'Academy',
      key: '2',
      component: null,
      path: '/schools',
    },
  ]

  const valueSelect = [
    { label: locale === 'en' ? 'In Progress' : 'กำลังเรียน', value: 'In Progress' },
    { label: locale === 'en' ? 'Complete' : 'เรียนจบแล้ว', value: 'Complete' },
  ]
  const valueSelectCourse = valueSelect.map((status, i) => {
    return {
      ...status,
      amountCourse: amountCourse[i],
    }
  })
  const handleChange = (index) => {
    filter[index] = !filter[index]
    setFilter([filter[0], filter[1]])
  }

  const currentAssignment = myAssignmentList.filter(({ attemp, dueDate }) =>
    dueDate ? attemp === 0 && moment().isBefore(dueDate) : attemp === 0
  )
  const countCourseAssignment = (currentCourse, completed) => [
    currentCourse.length,
    completed.length,
  ]
  const completedAssignment = myAssignmentList.filter(({ attemp }) => attemp > 0)
  const amountCourseAssignment = countCourseAssignment(currentAssignment, completedAssignment)

  const valueSelectCourseAssignment = valueSelect.map((status, i) => {
    return {
      ...status,
      amountCourse: amountCourseAssignment[i],
    }
  })
  const onChangeTab = (path) => {
    props.history.push(match.url + path)
    setActivePath(path)
  }
  const pathname = get(location, 'pathname', false)

  return (
    <Container>
      <div style={{ padding: '0px 150px 0px 150px' }}>
        <Typography
          fontSize="text-2xl"
          nowrap
          width="100%"
          color="primary10"
          superbold
          id="myCourse.title"
        >
          คอร์สเรียนของฉัน
        </Typography>
      </div>
      <Head>
        <Header>
          {/* {filter[0] === filter[1] && (
            <Typography id="myCourse.select.all" color="primary1" h4>
              All Courses
            </Typography>
          )}
          {filter[0] === true && filter[1] === false && (
            <Typography id="myCourse.status.inprogress" color="primary1" h4>
              In Progress
            </Typography>
          )}
          {filter[0] === false && filter[1] === true && (
            <Typography id="myCourse.status.complete" color="primary1" h4>
              Complete
            </Typography>
          )} */}
          {tabData.map((item, index) => {
            return (
              <TabCard
                key={index}
                onClick={() => onChangeTab(item.path)}
                border={locationPath === item.path.slice(1) ? true : false}
              >
                <Typography
                  id={item.id}
                  color="black60"
                  superbold={locationPath === item.path.slice(1) ? true : false}
                  fontSize="text-base"
                  style={{ cursor: 'pointer' }}
                >
                  {item.label}
                </Typography>
              </TabCard>
            )
          })}
          {/* <TabComponent tabData={tabData} onChangeTab={onChangeTab} headBorder /> */}
        </Header>
        <HeadDetail>
          {locationPath === 'courses' ? (
            <FilterMyCourse value={valueSelectCourse} handleChange={handleChange} filter={filter} />
          ) : locationPath === 'assignments' ? (
            <FilterMyCourse
              value={valueSelectCourseAssignment}
              handleChange={handleChange}
              filter={filter}
            />
          ) : null}
        </HeadDetail>
      </Head>

      {children}
    </Container>
  )
}

const mapState = (state) => ({
  isLiff: state.Liff.isLiff,
  locale: state.Language.locale,
  myCourseList: state.MyCourse.myCourseList,
  myAssignmentList: _orderBy(
    state.MyAssignment.myAssignmentList.filter((item) => moment().isAfter(item.publishDate)),
    ['publishDate'],
    ['asc']
  ),
  filter: state.MyCourse.filter,
})

const mapDispatch = (dispatch) => ({
  getMyCourseList: dispatch.MyCourse.getMyCourseList,
  getMyAssignmentList: dispatch.MyAssignment.getMyAssignmentList,
  setFilter: dispatch.MyCourse.setFilter,
})

export default connect(mapState, mapDispatch)(MyCourseLayout)
