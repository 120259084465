import React, { Component } from 'react'
import { Typography } from '../'
import PropTypes from 'prop-types'
import { Container, Label, Input, ErrorMsg, Icon } from './styles'
import theme from 'theme'

export class TextInput extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    containerStyle: PropTypes.object,
    onChange: PropTypes.func,
    type: PropTypes.string,
    label: PropTypes.string,
    isLogin: PropTypes.bool,
  }

  static defaultProps = {
    type: 'text',
  }

  render() {
    const {
      id,
      value,
      error,
      containerStyle,
      onChange,
      name,
      type,
      label,
      placeholder,
      hideLabel,
      required,
      altName,
      icon,
      inputStyle,
      isLogin,
      ...props
    } = this.props
    return (
      <Container style={containerStyle} {...props}>
        <Label htmlFor={label || name}>
          {hideLabel || (
            <div className="color">
              {icon && <Icon src={icon} alt="icon" />}
              <Typography id={id} style={{ color: theme.primary1, marginBottom: 5 }}>
                {altName || label || name}
              </Typography>
              {required ? <span style={{ color: theme.danger }}> *</span> : null}
            </div>
          )}
          <Input
            id={name}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            error={error}
            style={inputStyle}
            isLogin={isLogin}
            {...props}
          />
          {error && <ErrorMsg>{error}</ErrorMsg>}
        </Label>
      </Container>
    )
  }
}

export default TextInput
