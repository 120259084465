import { connect } from 'react-redux'

import { CourseSettingPage } from './index'

const mapState = ({
  SchoolCourse: {
    schoolCourse: {
      type,
      name,
      publicMode,
      publishStartDate,
      publishEndDate,
      registerStartDate,
      registerEndDate,
      maxStudent,
      isHidden,
      autoApprove,
      baseCourseIds,
      studentCount,
    },
  },
  TeacherSchool: { teacherList },
}) => ({
  isHidden,
  type,
  name,
  publicMode,
  publishStartDate,
  publishEndDate,
  registerStartDate,
  registerEndDate,
  maxStudent,
  isPublished: true,
  autoApprove,
  baseCourseIds,
  studentCount,
  teacherList,
})
const mapDispatch = ({
  SchoolCourse: { getMyCourseById, deleteCourse },
  Course: { updateCourseById },
  teachingCourse: { editCourse },
  Confirm,
  TeacherSchool: { getTeacherList },
}) => ({
  updateCourse: updateCourseById,
  deleteDraft: deleteCourse,
  unpublishCourse: updateCourseById,
  editCourse,
  getCourse: getMyCourseById,
  Confirm,
  getTeacherList,
})

export default connect(mapState, mapDispatch)(CourseSettingPage)
