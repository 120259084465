import React from 'react'
import ReactModal from 'react-modal'
import {
  FacebookShareButton,
  LineShareButton,
  //FacebookIcon,
  //LineIcon,
  TwitterShareButton,
  //TwitterIcon,
} from 'react-share'
import { Input, message } from 'antd'
import styled from 'styled-components'
import { useWindowSize } from 'react-use'
import ErrorBoundary from 'components/errorBoundary'
import { Typography } from 'components'
import theme from 'theme'
import closeIcon from '../../images/icons/Xicon.png'
import Icon from '../../components/icon/loadable'
import FacebookIcon from '../../images/icons/facebookicons.svg'
import LineIcon from '../../images/icons/lineicons.svg'
import TwitterIcon from '../../images/icons/twittericons.svg'
import CopyIcon from '../../images/icons/Group.svg'

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 32px 27px;
  //background-color: red;
  .ant-input {
    color: ${theme.black60};
    background-color: #f9fafb;
  }
`
const CloseIcon = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`
const ButtonRow = styled.div`
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 0 5px 20px 5px;
  //border-bottom: 1px solid ${({ theme }) => theme.gray3};
  margin-bottom: 5px;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`

const ShareButton = ({ icon, label }) => {
  return (
    <ButtonContainer>
      {icon}
      <Typography color="black87" fontWeight={500} center nowrap>
        {label}
      </Typography>
    </ButtonContainer>
  )
}

const inputStyle = {
  borderRadius: 4,
  border: `1px solid ${theme.black38}`,
  boxShadow: 'none',
  paddingRight: 3,
  height: 40,
  backgroundColor: '#F9FAFB',
}
// const buttonStyleProps = {
//   id: 'course.detail.share.modal.copy',
//   secondary: true,
//   height: 30,
//   width: 100,
//   fontStyle: { fontSize: 14, fontWeight: 700 },
//   style: { borderRadius: 8 },
// }

const ShareModal = ({ isOpen, setOpenModal, url, locale }) => {
  const { width } = useWindowSize()
  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: 104,
    },
    content: {
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      margin: 'auto',
      border: 'none',
      borderRadius: 10,
      background: '#ffffff',
      width: width < 540 ? '90%' : 500,
      height: 'fit-content',
      padding: 0,
    },
  }

  const onClose = () => {
    setOpenModal(false)
  }

  const onFocus = (event) => {
    event.target.select()
  }

  const onCopy = () => {
    window.navigator.clipboard.writeText(url)
    message.info(locale === 'th' ? 'คัดลอกลิงก์แล้ว' : `Url copied to clipboard.`)
  }

  return (
    <ErrorBoundary>
      <ReactModal isOpen={isOpen} style={modalStyle} onRequestClose={onClose}>
        <Container>
          <CloseIcon src={closeIcon} onClick={onClose} />
          <Typography
            id="course.detail.share.modal.title"
            color="primary1"
            fontSize="text-lg"
            fontWeight={600}
            lineHeight="32px"
            margin="0 0 27px 0"
          >
            Share
          </Typography>
          {/* <Typography id="course.detail.share.modal.shareTo" color="dark1" margin="0 0 12px 0">
            Share to
          </Typography> */}
          <ButtonRow>
            <FacebookShareButton url={url}>
              <ShareButton icon={<img src={FacebookIcon} alt="facebook" />} label="Facebook" />
            </FacebookShareButton>
            <LineShareButton url={url}>
              <ShareButton icon={<img src={LineIcon} alt="line" />} label="Line" />
            </LineShareButton>
            <TwitterShareButton url={url}>
              <ShareButton icon={<img src={TwitterIcon} alt="twitter" />} label="Twitter" />
            </TwitterShareButton>
          </ButtonRow>
          <Typography
            id="course.detail.share.modal.or"
            color="primary1"
            style={{ marginBottom: '4px' }}
            fontSize="text-sm"
          >
            Page Link
          </Typography>
          <Input
            value={url}
            suffix={<Icon src={CopyIcon} style={{ padding: '4px' }} onClick={onCopy} />}
            onFocus={onFocus}
            style={inputStyle}
          />
        </Container>
      </ReactModal>
    </ErrorBoundary>
  )
}

export default ShareModal
