import styled, { css } from 'styled-components'
import Typography from '../typography'

export const EmptyStyle = styled.div`
  display: flex;
  position: relative;
  ${({ responsivePadding, responsivePaddingMan }) => {
    if (responsivePaddingMan) {
      return css`
        padding: 0 0 0 0;
      `
    } else if (responsivePadding) {
      return css`
        padding: 20px 30px 30px 30px;
        @media (max-width: 768px) and (min-width: 426px) {
          padding: 10px 30px 30px 30px;
        }

        @media (max-width: 425px) {
          padding: 0 30px 30px 30px;
        }
      `
    } else {
      return css`
        padding: 30px;
      `
    }
  }}
  margin: ${(props) => (props.responsivePaddingMan ? '0 0 0 0' : '20px')};
  flex: 1;
  height: ${(props) => (props.height ? props.height : '100%')};
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  button {
    margin-top: 25px;
  }

  .full-image {
    // width: 50%;
    // height: 50%;
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 15px;
  }

  @media (max-width: 600px) and (min-width: 429px) {
    .full-image {
      margin-bottom: 10px;
      max-height: 200px;
      max-width: 200px;
    }
  }

  @media (max-width: 429px) {
    .full-image {
      margin-bottom: 10px;
      max-height: 150px;
      max-width: 150px;
    }
  }

  @media (max-width: 1024px) {
    .no-support {
      margin-bottom: 10px;
      max-height: 100%;
      max-width: 100vw;
    }
  }

  ${({ small }) => {
    if (small) {
      return css`
        .full-image {
          max-width: 200px;
          max-height: 200px;
          ${'' /* margin-bottom: 15px; */}
        }
        .title {
          font-size: ${({ theme }) => theme['text-sm']};
        }
        .desc {
          font-size: ${({ theme }) => theme['text-xs']};
          margin-top: 5px;
        }
      `
    }
    return css``
  }}

  ${({ medium }) => {
    if (medium) {
      return css`
        .full-image {
          max-width: 258px;
          max-height: 200px;
          margin-bottom: 15px;
        }
        .title {
          font-size: ${({ theme }) => theme['text-xl']};
        }
        .desc {
          font-size: ${({ theme }) => theme['text-sm']};
          margin-top: 5px;
        }
      `
    }
    return css``
  }}

  ${({ large }) => {
    if (large) {
      return css`
        .full-image {
          max-width: 600px;
          // max-height: 600px;
          max-height: 33vh;
          margin-bottom: 15px;
        }

        @media (max-width: 768px) and (min-width: 429px) {
          .full-image {
            max-width: 400px;
            max-height: 400px;
            margin-bottom: 15px;
          }
        }

        @media (max-width: 429px) {
          .full-image {
            max-width: 250px;
            max-height: 250px;
            margin-bottom: 15px;
          }
        }
      `
    }
    return css``
  }}

  ${({ primary }) => {
    if (primary) {
      return css`
        .title {
          color: ${({ theme }) => theme['primary1']};
          font-size: ${({ theme }) => theme['text-2xl']};
          font-weight: 700;
        }
        .desc {
          color: ${({ theme }) => theme['primary1']};
          font-size: ${({ theme }) => theme['text-m']};
          font-weight: 400;
        }
      `
    }
    return css``
  }}

  ${({ Custom1 }) => {
    if (Custom1) {
      return css`
        .full-image {
          max-width: 300px;
          // max-height: 600px;
          max-height: 33vh;
          margin-top: 64px;
          margin-bottom: 35px;
        }
        .title {
          color: ${({ theme }) => theme['primary1']};
          font-size: ${({ theme }) => theme['text-2xl']};
        }
        .desc {
          width: 100%;
          color: ${({ theme }) => theme['black60']};
          font-size: ${({ theme }) => theme['text-base']};
          margin-top: 24px;
        }
      `
    }
    return css``
  }}
  ${({ Custom2 }) => {
    if (Custom2) {
      return css`
        .title {
          color: ${({ theme }) => theme['primary1']};
          font-size: ${({ theme }) => theme['text-2xl']};
          font-weight: 700;
        }
        .desc {
          color: ${({ theme }) => theme['black60']};
          font-size: ${({ theme }) => theme['text-base']};
          font-weight: 400;
        }
        @media (max-width: 429px) {
          .title {
            font-size: ${({ theme }) => theme['text-xl']};
          }
          .desc {
            font-size: ${({ theme }) => theme['text-sm']};
          }
        }
      `
    }
    return css``
  }}
`

export const Desc = styled(Typography)`
  ${({ desclong }) => {
    if (desclong) {
      return css`
        width: 80%;
        @media (max-width: 768px) and (min-width: 426px) {
          width: 90%;
        }

        @media (max-width: 425px) {
          width: 100%;
        }
      `
    } else {
      return css`
        width: 300px;
      `
    }
  }}
  font-size: ${({ theme }) => theme['text-lg']};
  ${({ customMarginDesc }) => {
    if (customMarginDesc) {
      return css`
        margin: 20px 0 30px 0;
        @media (max-width: 768px) and (min-width: 426px) {
          margin: 15px 0 20px 0;
        }

        @media (max-width: 425px) {
          margin: 10px 0 20px 0;
        }
      `
    } else {
      return css`
        margin: 10px 0 0 0;
      `
    }
  }}
  @media (max-width: 425px) {
    font-size: ${({ theme }) => theme['text-sm']};
  }
  white-space: pre-line;
`

export const Image = styled.img``

export const EmptyCard = styled.div`
  margin: ${({ noMargin }) => (noMargin ? '0px' : '40px')};
  ${({ responsivePaddingMan }) => {
    if (responsivePaddingMan) {
      return css`
        margin: 0 0 92px 0;
      `
    }
  }}
  /* background-color: white; */
  border-radius: 8px;

  @media (max-width: 768px) {
    /* padding: 32px 16px; */
    margin: ${({ noMargin }) => (noMargin ? '0px' : '24px')};
    ${({ responsivePaddingMan }) => {
      if (responsivePaddingMan) {
        return css`
          margin: 0 0 0 0;
        `
      }
    }}
  }

  @media (max-width: 426px) {
    margin: 0px;
  }
`
