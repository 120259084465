import React from 'react'

import PageHeading from '../../components/pageHeading/loadable'
import TabComponent from '../../components/tabComponent/loadable'

const TeacherMyCourseLayout = (props) => {
  const { match, children } = props
  const tabData = [
    {
      label: 'Courses',
      key: '0',
      component: null,
      path: '/courses',
    },
    // {
    //   label: 'Assignment',
    //   key: '1',
    //   component: null,
    //   path: '/assignments',
    // },
  ]
  const onChangeTab = (path) => {
    props.history.push(`${match.url}${path}`)
  }
  return (
    <>
      <PageHeading text="My Course" />
      <TabComponent tabData={tabData} onChangeTab={onChangeTab} />
      {children}
    </>
  )
}

export default TeacherMyCourseLayout
