/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react'
import { Typography, Button } from 'components'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import moment from 'moment'
import theme from 'theme'
import getImageUrl from 'utils/getImageUrl'
import ClasswinEditor from './classwinEditor'
import DrawingModal from './FeedComponent/DrawingModal'
import Pen from '../../../../images/icons/PenIcon.svg'
import { Lightbox } from 'react-modal-image'
import LikeButton from './FeedComponent/LikeButton'
import CommentButton from './FeedComponent/CommentButton'
import MoreMenu from './FeedComponent/MoreMenu'
import ClasswinInput from './classwinInputDiscussion'
import EditPostModal from './postFeedCard/EditPostModal'
import { Input } from 'antd'
import Comment from './FeedComponent/Comment'
const { TextArea } = Input

const Content = styled.div`
  //padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
`
const Card = styled.div`
  padding: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #2c2b30;
  border-radius: 8px;
`
const Header = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  justify-content: space-between;
`
const ImageFeedCourse = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  @media (max-width: 820px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 426px) {
    width: 40px;
    height: 40px;
  }
`
const ImageContainer = styled.div`
  position: relative;
`
const AvatarImg = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 0px;
  right: 2px;
  box-shadow: 0px 1px 4px 0px #00000026;
  border-radius: 50%;
  background-color: white;
  @media (max-width: 820px) {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 426px) {
    width: 18px;
    height: 18px;
  }
`
const CourseName = styled(Typography)`
  font-size: 16px;
  @media (max-width: 820px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 426px) {
    font-size: 16px;
  }
`
const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`
const Desc = styled(Typography)`
  align-items: center;
  font-size: 12px;
  @media (max-width: 820px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 426px) {
    font-size: 12px;
  }
`
const Body = styled.div`
  padding: 10px 0px 0px 0px;
`
const WrapImage = styled.div`
  margin-top: 10px;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  justify-content: center;
`
const PostImage = styled.img`
  max-width: 80%;
  width: 285px;
  height: 175px;
  object-fit: cover;
  @media (max-width: 920px) {
    max-width: 100%;
    height: 600px;
  }
  @media (max-width: 820px) {
    max-width: 100%;
    height: 500px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    height: 500px;
  }
  @media (max-width: 540px) {
    max-width: 100%;
    height: 400px;
  }
  @media (max-width: 426px) {
    max-width: 100%;
    height: 300px;
  }
`
const PenIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  top: -8px;
  right: 8px;
`
const CommentContainer = styled.div`
  padding: 0px 0px 0px;
  display: flex;
  flex-direction: column;
  @media (max-width: 426px) {
    padding: 10px 8px 0px;
  }
`
const CommentInput = styled(TextArea)`
  border: none !important;

  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
  ${({ editMode }) => {
    if (editMode) {
      return css`
        margin-left: 0px !important;
      `
    }
    return css`
      margin-left: 8px !important;
    `
  }}
  padding: 6px 16px !important;
  font-size: 12px !important;
  min-height: 30px !important;
  background-color: ${theme.gray13} !important;
  width: auto;
  border-radius: 20px !important;
  ::placeholder {
    color: #c2c2c2;
  }
`
const CommentStyle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: 820px) {
    margin-top: 10px;
  }
  @media (max-width: 425px) {
    margin-top: 10px;
  }
`
const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`
const CommentList = styled.div`
  .ant-input:focus {
    /* border: none !important;
    border-color: none !important;
    box-shadow: none !important;
    outline: none !important; */
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }
`
const ShowComment = ({
  locale,
  onSubmit,
  course,
  courseId,
  id,
  showed,
  userFirstname,
  profileImage,
}) => {
  if (showed) {
    return (
      <CommentContainer>
        <CommentStyle>
          <Avatar src={getImageUrl(profileImage)} alt={userFirstname} />
          <ClasswinInput
            locale={locale}
            onSubmit={onSubmit}
            course={course}
            courseId={courseId}
            id={id}
          />
        </CommentStyle>
      </CommentContainer>
    )
  }
  return null
}

function FeedDiscussion(props) {
  const {
    total,
    feeds,
    locale,
    myCourseList,
    courseId,
    appUser,
    setLikePost,
    setLikeComment,
    setLikeReply,
    postLike,
    postComment,
    setComment,
    setCommentReply,
    deleteFeed,
    setDeletePost,
    setDeleteComment,
    setDeleteCommentLevelOne,
    updateFeed,
    Confirm,
    setEditComment,
  } = props
  const { profileImage, id: userId } = appUser
  const [openModalImageId, setOpenModalImageId] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [editingFeedDetail, setEditingFeedDetail] = useState(null)
  const [drawingModalId, setDrawingModalId] = useState(null)
  const [drawingToggle, setDrawingToggle] = useState(false)
  const [showCommentId, setCommentId] = useState('')
  const [showReplyId, setReplyId] = useState('')
  const onClickComment = (id) => {
    setCommentId(id)
    setReplyId('')
  }

  const myCourseIds = useMemo(() => {
    return [...myCourseList.currentCourse, ...myCourseList.completed].map((item) => item.id)
  }, [myCourseList])
  const onLike = async (feedId, level = 1) => {
    try {
      if (level === 1) {
        setLikePost(feedId)
      } else if (level === 2) {
        setLikeComment(feedId)
      } else if (level === 3) {
        setLikeReply(feedId)
      }
      await postLike(feedId)
    } catch (e) {
      throw e
    }
  }

  const onSubmit = async ({ richMessage, courseId, feedId }, isReply = false) => {
    if (richMessage?.length > 0 && richMessage !== '\n') {
      let obj
      if (courseId) {
        obj = {
          richMessage,
          appUserId: userId,
          courseId,
          feedId,
        }
      } else {
        obj = {
          richMessage,
          appUserId: userId,
          feedId,
        }
      }
      const res = await postComment(obj)
      isReply
        ? setCommentReply({ isMyLike: false, appUser, ...res })
        : setComment({
            isMyLike: false,
            appUser,
            ...res,
          })
    }
  }
  const handleDel = async ({ feedId, courseId }, level = 1) => {
    try {
      await deleteFeed({ id: feedId })
      if (level === 1) {
        setDeletePost({ id: feedId })
      } else if (level === 2) {
        setDeleteComment({ id: feedId, courseId })
      } else if (level === 3) {
        setDeleteCommentLevelOne({ id: feedId, courseId })
      }
    } catch (e) {
      throw e
    }
  }
  return (
    <Content>
      <EditPostModal isOpen={isEdit} setIsOpen={setIsEdit} data={editingFeedDetail} />
      {(feeds || []).map(
        ({
          appUser,
          course,
          comments,
          createdAt,
          id,
          media,
          richMessage,
          isMyLike,
          countLikes,
        }) => {
          const createdTimes = moment(createdAt)
            .locale(locale === 'en' ? 'en' : 'th')
            .fromNow()
          // const numArr = idx % 5
          //const isMe = userId === appUser?.id
          return (
            <Card key={id}>
              {/* <Header>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ImageContainer>
                    {course ? (
                      <ImageFeedCourse src={getImageUrl(course?.image)} alt={course?.name} />
                    ) : (
                      <ImageFeedCourse src={getImageUrl(appUser?.profileImage)} />
                    )}
                    {course && (
                      <AvatarImg
                        src={getImageUrl(appUser?.profileImage)}
                        alt={appUser?.profileImage}
                      />
                    )}
                    <ImageFeedCourse src={getImageUrl(appUser?.profileImage)} />
                  </ImageContainer>
                  <Title>
                    <CourseName
                      bold
                      lineHeight={1}
                      numberOfLines={1}
                      style={{
                        cursor: 'pointer',
                        lineHeight: 1.5,
                        maxWidth: '700px',
                        minHeight: '18px',
                        color: theme.dark2,
                      }}
                      onClick={() =>
                        history.push(
                          myCourseIds.includes(course?.id)
                            ? `/my-course/courses/${course?.id}/discussion`
                            : `/schools/${course?.schoolId}/curriculum/manage/${course?.id}/discussion`
                        )
                      }
                    >
                      {course?.name}
                    </CourseName>
                    <div
                      style={{
                        display: 'flex',

                        alignItems: 'center',
                      }}
                    >
                      <Desc color="dark2" bold>
                        {`${appUser?.firstname} ${appUser?.lastname}`}
                      </Desc>
                      <Desc margin={'0px 5px 0px 5px'} color="dark2">
                        •
                      </Desc>
                      <Desc margin={'0px'} color="dark2">
                        {createdTimes}
                      </Desc>
                      <FollowButton
                        dataIntro="feed2"
                        isMe={isMe}
                        followed={followed}
                        onFollowFeed={onFollowFeed}
                        appUser={appUser}
                        handleOpenFollowModal={handleOpenFollowModal}
                      />
                    </div>
                  </Title>
                </div>
                <div>
                  <MoreMenu
                    isMe={userId === appUser?.id}
                    id={id}
                    course={course}
                    handleDel={handleDel}
                    setIsEdit={(isEdit) => {
                      setIsEdit(isEdit)
                      setEditingFeedDetail({ richMessage, media, id })
                    }}
                  />
                </div>
              </Header> */}
              {/* <Typography color="dark2" title numberOfLines={1}>
                {richMessage}
              </Typography> */}
              {/* <Body>
                <ClasswinEditor
                  fontSize="text-sm"
                  isShowAll={false}
                  editorMaxHeight={54}
                  value={richMessage}
                  labelFontSize="text-base"
                  readOnly
                  autoHeight
                  color="#AAADB9"
                  padding={0}

                  // labelColor="white"
                  //min={2}
                />
                {media && (
                  <div>
                    <WrapImage>
                      <PostImage
                        src={getImageUrl(
                          media?.map((item) => {
                            return item.name
                          })
                        )}
                        alt=""
                        onClick={() => setOpenModalImageId(id)}
                      />
                    </WrapImage>
                    {openModalImageId === id && media && (
                      <Lightbox
                        // small={getImageUrl(
                        //   media?.map((item) => {
                        //     return item.name
                        //   })
                        // )}
                        large={getImageUrl(
                          media?.map((item) => {
                            return item.name
                          })
                        )}
                        meta={() => {
                          return (
                            <PenIcon
                              src={Pen}
                              onClick={() => {
                                setOpenModalImageId(null)
                                setDrawingModalId(id)
                                setDrawingToggle(true)
                              }}
                            />
                          )
                        }}
                        onClose={() => setOpenModalImageId(null)}
                        hideDownload
                      />
                    )}
                    <DrawingModal
                      isOpen={drawingModalId === id}
                      onClose={() => setDrawingModalId(null)}
                      image={media?.[0]?.name}
                      drawingToggle={drawingToggle}
                      feedId={id}
                      courseId={courseId}
                      profileImage={profileImage}
                      userFirstname={appUser?.firstname}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    paddingLeft: 0,
                    paddingTop: 10,
                    //paddingBottom: 10,
                  }}
                >
                  <div style={{ display: 'flex', width: '75px' }}>
                    <LikeButton
                      isMyLike={isMyLike}
                      countLikes={countLikes}
                      id={id}
                      onLike={onLike}
                    />
                  </div>
                  <div
                          style={{
                            height: 20,
                            width: 0.5,
                            backgroundColor: theme.black38,
                            margin: 5,
                          }}
                        ></div>
                  <Typography style={{ padding: 5, color: theme.gray3 }}>|</Typography>
                  <CommentButton
                    showed={showCommentId === id}
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                    onClick={() => {
                      setCommentId(id)
                      onClickComment(id)
                    }}
                  />
                </div>
                <ShowComment
                  locale={locale}
                  onSubmit={onSubmit}
                  course={course}
                  courseId={courseId}
                  id={id}
                  profileImage={profileImage}
                  userFirstname={appUser?.firstname}
                  showed={showCommentId === id}
                />
                <CommentContainer>
                  <CommentList>
                    <Comment
                      comments={_.orderBy(comments, 'created', 'desc')}
                      userId={userId}
                      onLike={onLike}
                      handleDel={handleDel}
                      onSubmit={onSubmit}
                      updateFeed={updateFeed}
                      course={course}
                      locale={locale}
                      Confirm={Confirm}
                      setEditComment={setEditComment}
                      courseId={courseId}
                      showReplyId={showReplyId}
                      setReplyId={setReplyId}
                      myProfileImage={profileImage}
                      onClickComment={onClickComment}
                      showCommentId={showCommentId}
                      setCommentId={setCommentId}
                      // media={media}
                    />
                  </CommentList>
                </CommentContainer>
              </Body> */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', width: '100%' }}>
                  <ImageContainer>
                    <ImageFeedCourse src={getImageUrl(appUser?.profileImage)} />
                  </ImageContainer>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '12px' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',

                          alignItems: 'center',
                        }}
                      >
                        <Desc color="dark2" bold>
                          {`${appUser?.firstname} ${appUser?.lastname}`}
                        </Desc>
                        <Desc margin={'0px 5px 0px 5px'} color="dark2">
                          •
                        </Desc>
                        <Desc margin={'0px'} color="dark2">
                          {createdTimes}
                        </Desc>
                        {/* <FollowButton
                        dataIntro="feed2"
                        isMe={isMe}
                        followed={followed}
                        onFollowFeed={onFollowFeed}
                        appUser={appUser}
                        handleOpenFollowModal={handleOpenFollowModal}
                      /> */}
                      </div>
                      <div>
                        <MoreMenu
                          isMe={userId === appUser?.id}
                          id={id}
                          course={course}
                          handleDel={handleDel}
                          setIsEdit={(isEdit) => {
                            setIsEdit(isEdit)
                            setEditingFeedDetail({ richMessage, media, id })
                          }}
                        />
                      </div>
                    </div>
                    <ClasswinEditor
                      fontSize="text-sm"
                      isShowAll={false}
                      editorMaxHeight={54}
                      value={richMessage}
                      labelFontSize="text-base"
                      readOnly
                      autoHeight
                      color="#AAADB9"
                      padding={0}

                      // labelColor="white"
                      //min={2}
                    />
                    {media && (
                      <div>
                        <WrapImage>
                          <PostImage
                            src={getImageUrl(
                              media?.map((item) => {
                                return item.name
                              })
                            )}
                            alt=""
                            onClick={() => setOpenModalImageId(id)}
                          />
                        </WrapImage>
                        {openModalImageId === id && media && (
                          <Lightbox
                            // small={getImageUrl(
                            //   media?.map((item) => {
                            //     return item.name
                            //   })
                            // )}
                            large={getImageUrl(
                              media?.map((item) => {
                                return item.name
                              })
                            )}
                            meta={() => {
                              return (
                                <PenIcon
                                  src={Pen}
                                  onClick={() => {
                                    setOpenModalImageId(null)
                                    setDrawingModalId(id)
                                    setDrawingToggle(true)
                                  }}
                                />
                              )
                            }}
                            onClose={() => setOpenModalImageId(null)}
                            hideDownload
                          />
                        )}
                        <DrawingModal
                          isOpen={drawingModalId === id}
                          onClose={() => setDrawingModalId(null)}
                          image={media?.[0]?.name}
                          drawingToggle={drawingToggle}
                          feedId={id}
                          courseId={courseId}
                          profileImage={profileImage}
                          userFirstname={appUser?.firstname}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        paddingLeft: 0,
                        paddingTop: 0,
                        //paddingBottom: 10,
                      }}
                    >
                      <div style={{ display: 'flex', width: '75px' }}>
                        <LikeButton
                          isMyLike={isMyLike}
                          countLikes={countLikes}
                          id={id}
                          onLike={onLike}
                        />
                      </div>
                      {/* <div
                          style={{
                            height: 20,
                            width: 0.5,
                            backgroundColor: theme.black38,
                            margin: 5,
                          }}
                        ></div> */}
                      {/* <Typography style={{ padding: 5, color: theme.gray3 }}>|</Typography> */}
                      <CommentButton
                        showed={showCommentId === id}
                        style={{ justifyContent: 'center', alignItems: 'center' }}
                        onClick={() => {
                          setCommentId(id)
                          onClickComment(id)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <ShowComment
                  locale={locale}
                  onSubmit={onSubmit}
                  course={course}
                  courseId={courseId}
                  id={id}
                  profileImage={profileImage}
                  userFirstname={appUser?.firstname}
                  showed={showCommentId === id}
                />
                <CommentContainer>
                  <CommentList>
                    <Comment
                      comments={_.orderBy(comments, 'created', 'desc')}
                      userId={userId}
                      onLike={onLike}
                      handleDel={handleDel}
                      onSubmit={onSubmit}
                      updateFeed={updateFeed}
                      course={course}
                      locale={locale}
                      Confirm={Confirm}
                      setEditComment={setEditComment}
                      courseId={courseId}
                      showReplyId={showReplyId}
                      setReplyId={setReplyId}
                      myProfileImage={profileImage}
                      onClickComment={onClickComment}
                      showCommentId={showCommentId}
                      setCommentId={setCommentId}
                      // media={media}
                    />
                  </CommentList>
                </CommentContainer>
              </div>
            </Card>
          )
        }
      )}
    </Content>
  )
}

export default FeedDiscussion
