/* eslint-disable no-unused-vars */
import { Typography, ProfileImage } from 'components'
import React, { useState } from 'react'
import CheckIcon from 'images/icons/Check.svg'
import CloseIcon from 'images/icons/CloseWhite.png'
import { connect } from 'react-redux'
import { useWindowSize } from 'react-use'
import ArrowCollapse from '../../images/icons/ic-down_Primary1.svg'
import ArrowCollapseup from '../../images/icons/ic-arrowdownup.svg'
import {
  Collapse,
  Container,
  Item,
  Icon,
  ExpandImg,
  Options,
  AcademyCard,
  Padding,
  IconSelect,
  Image,
  SwitchMode,
  SwitchItemActive,
  SwitchItem,
} from './style'
import { Link, useHistory } from 'react-router-dom'
import Modal from 'components/modal/loadable'
import { ModalResize } from 'utils/commaize'
import getImageUrl from 'utils/getImageUrl'
import { Desktop, Mobile } from '../../utils/displayResponsive'
import SwitchInActive from 'images/icons/instructor-01.svg'

function DrawerProfile({
  menu = [],
  onRequestClose,
  updateLocale,
  locale,
  pathname,
  isTeacher,
  appUser,
  toggle,
  teacherSidebar,
}) {
  const options = [
    {
      label: () => '(EN) English',
      value: 'en',
    },
    {
      label: () => '(TH) ภาษาไทย',
      value: 'th',
    },
  ]
  const [isShow, setIsShow] = useState(true)
  const [isOpenLang, setIsOpenLang] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { width, height } = useWindowSize()
  const history = useHistory()
  const handleLanguageChange = (value) => {
    updateLocale(value)
    setIsOpenLang(false)
  }
  const { Panel } = Collapse
  const schoolMembers = appUser?.schoolMembers?.filter((item) => item.isApprove)
  return (
    <>
      {isShow && (
        <Container>
          <div>
            {/* <Desktop>
            <div
              style={{
                position: 'absolute',
                right: 21.25,
                top: 21,
                cursor: 'pointer',
                zIndex: 50,
              }}
              onClick={onRequestClose}
            >
              <Image src={CloseIcon} style={{ width: 14, height: 14 }} alt="close" />
            </div>
          </Desktop> */}
            {/* <Mobile>
            <div
              style={{
                position: 'absolute',
                left: 240,
                top: 21,
                cursor: 'pointer',
                zIndex: 50,
              }}
              onClick={onRequestClose}
            >
              <Image src={CloseIcon} style={{ width: 14, height: 14 }} alt="close" />
            </div>
          </Mobile> */}
            {menu?.map((item, idx) => {
              const isFirst = idx === 0
              const isSecond = idx === 1
              const isLangButton = item.label === 'Language'
              const isSamePath = pathname.startsWith(item.link)

              return isLangButton ? (
                <Collapse
                  bordered={false}
                  style={{ border: 'none' }}
                  expandIcon={() => (
                    <ExpandIcon isArrowChecked={isOpenLang} isActive={isOpenLang} />
                  )}
                  expandIconPosition="end"
                  isOpenLang={isOpenLang}
                  onChange={() => setIsOpenLang((prev) => !prev)}
                >
                  <Panel
                    style={{ borderBottom: 'none' }}
                    header={
                      <Item style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Icon src={isSamePath ? item.icon : item.activeIcon} />
                          <Typography
                            id={item.id}
                            // color={isOpenLang ? 'primary1' : 'white'}
                            color="primary1"
                            margin="0 0 0 16px"
                            fontWeight="500"
                          >
                            {options.find((lang) => lang.value === locale).label() ||
                              options[0].label()}
                          </Typography>
                        </div>
                      </Item>
                    }
                  >
                    {options.map((item, idx) => {
                      return (
                        <Options
                          key={idx}
                          onClick={() => handleLanguageChange(item.value)}
                          style={{ alignItems: 'center' }}
                        >
                          <Typography color="primary1" fontWeight="500">
                            {item.label()}
                          </Typography>
                          {locale === item.value && (
                            <Icon
                              src={CheckIcon}
                              style={{ width: 14, height: 12, marginLeft: 10 }}
                            />
                          )}
                        </Options>
                      )
                    })}
                  </Panel>
                </Collapse>
              ) : item.label === 'Tutorial' ? (
                <a href={item.link} target="_blank" rel="noreferrer">
                  <Item key={idx} isFirst={isFirst}>
                    <Icon src={item.icon} />
                    <Typography id={item.id} color="primary1" margin="0 0 0 16px" fontWeight="500">
                      {item.label}
                    </Typography>
                  </Item>
                </a>
              ) : item.label === 'To instructor' ? (
                <SwitchMode
                  onClick={() => {
                    if (schoolMembers?.length > 0) {
                      setIsOpenModal(true)
                      setIsShow(false)
                    } else {
                      history.push(item.link)
                    }
                    //onRequestClose()
                  }}
                >
                  <SwitchItemActive>
                    <Icon src={item.icon} />
                    <Typography
                      id={item.labelLearner}
                      color="white"
                      margin="0 0 0 8px"
                      fontWeight="600"
                      fontSize="text-sm"
                    >
                      {item.labelLearner}
                    </Typography>
                  </SwitchItemActive>
                  <SwitchItem>
                    <Icon src={item.icon2} />
                    <Typography
                      id={item.labelInstructor}
                      color="black38"
                      margin="0 0 0 8px"
                      fontWeight="600"
                      fontSize="text-sm"
                    >
                      {item.labelInstructor}
                    </Typography>
                  </SwitchItem>
                </SwitchMode>
              ) : item.label === 'To learner' ? (
                <>
                  {/* <Link to={item.link} onClick={onRequestClose}>
                <Item
                  key={idx}
                  isFirst={isFirst}
                  isSamePath={isSamePath}
                  isTeacher={isTeacher ? true : false}
                  underline={true}
                >
                  <IconSelect src={isSamePath ? item.activeIcon : item.icon} />
                  <Typography
                    id={item.id}
                    color={isSamePath ? 'primary1' : 'primary1'}
                    margin="0 0 0 16px"
                    fontWeight="400"
                    fontSize="text-sm"
                    bold
                  >
                    {item.label}
                  </Typography>
                </Item>
              </Link> */}
                  <SwitchMode
                    onClick={() => {
                      history.push(item.link)
                      //onRequestClose()
                    }}
                  >
                    <SwitchItem>
                      <Icon src={item.icon} />
                      <Typography
                        id={item.labelLearner}
                        color="black38"
                        margin="0 0 0 8px"
                        fontWeight="600"
                        fontSize="text-sm"
                      >
                        {item.labelLearner}
                      </Typography>
                    </SwitchItem>

                    <SwitchItemActive>
                      <Icon src={item.icon2} />
                      <Typography
                        id={item.labelInstructor}
                        color="white"
                        margin="0 0 0 8px"
                        fontWeight="600"
                        fontSize="text-sm"
                      >
                        {item.labelInstructor}
                      </Typography>
                    </SwitchItemActive>
                  </SwitchMode>
                </>
              ) : item.label === 'Logout' || item.label === 'LogoutTeacher' ? (
                // <div style={{ position: 'absolute', bottom: 10 }}>
                <div>
                  <Link to={item.link} onClick={onRequestClose}>
                    <Item
                      key={idx}
                      isFirst={isFirst}
                      isSamePath={isSamePath}
                      isTeacher={isTeacher ? true : false}
                      isSecond={isSecond}
                      // isLogoutStudent={item.label === 'Logout' ? true : false}
                      // isLogoutTeacher={item.label === 'LogoutTeacher' ? true : false}
                    >
                      <Icon src={isSamePath ? item.activeIcon : item.icon} />
                      <Typography
                        id={item.id}
                        color={isSamePath ? 'primary1' : 'primary1'}
                        margin="0 0 0 16px"
                        fontWeight="500"
                      >
                        {item.label}
                      </Typography>
                    </Item>
                  </Link>
                </div>
              ) : (
                <Link to={item.link} onClick={onRequestClose}>
                  <Item
                    key={idx}
                    isFirst={isFirst}
                    isSamePath={isSamePath}
                    isTeacher={isTeacher ? true : false}
                    isSecond={isSecond}
                  >
                    <Icon src={isSamePath ? item.activeIcon : item.icon} />
                    <Typography
                      id={item.id}
                      color={isSamePath ? 'primary1' : 'primary1'}
                      margin="0 0 0 16px"
                      fontWeight="500"
                    >
                      {item.label}
                    </Typography>
                  </Item>
                </Link>
              )
            })}
          </div>
        </Container>
      )}
      <Modal
        isOpen={isOpenModal}
        onRequestClose={() => {
          setIsOpenModal(false)
          setIsShow(true)
        }}
        onClickClose={() => {
          setIsOpenModal(false)
          setIsShow(true)
        }}
        contentStyle={{
          width: ModalResize(width),
          height: ModalResize(height) + 110,
          padding: '35px 20px 35px 24px',
          overflow: 'scroll',
        }}
        shownXclose
      >
        <Typography fontSize="text-2xl" color="primary1" bold margin={'-22px 0 10px'}>
          Select Academy
        </Typography>
        {schoolMembers?.map((item) => {
          return (
            <Link
              to={`/schools/${item.school.id}/dashboard/overview`}
              onClick={() => {
                onRequestClose()
                toggle(false)
                teacherSidebar(false)
              }}
              key={item.school.id}
            >
              <AcademyCard>
                <ProfileImage
                  width={48}
                  key={item.school.id + item.school.logo}
                  src={getImageUrl(item.school.logo, 'https://via.placeholder.com/80', 80, 80)}
                />
                <Padding />
                <Typography color="primary1" numberOfLines={1} bold fontSize="text-lg">
                  {item.school.name}
                </Typography>
              </AcademyCard>
            </Link>
          )
        })}
      </Modal>
    </>
  )
}

const ExpandIcon = ({ isActive, isArrowChecked }) => {
  if (isArrowChecked) {
    return <ExpandImg src={ArrowCollapse} alt="arrowcollapse" isActive={isActive} />
  }
  return <ExpandImg src={ArrowCollapse} alt="arrowcollapse" isActive={isActive} />
}
const mapState = (state) => ({
  appUser: state.AppUser.appUser,
  locale: state.Language.locale.split('-')[0],
})
const mapDispatch = ({ Language: { updateLocale }, Sidebar: { toggle, teacherSidebar } }) => ({
  updateLocale,
  toggle,
  teacherSidebar,
})

export default connect(mapState, mapDispatch)(DrawerProfile)
