import React, { Fragment, useEffect, useState } from 'react'
import { Field, connect } from 'formik'
import { useRouteMatch } from 'react-router-dom'
import { connect as withRedux } from 'react-redux'
import { useMedia } from 'react-use'
import { components } from 'react-select'
import { Wrapper, Form, Item, Badge } from './style'
import { UploadImage, Input, InputTag, Label, Typography } from '../../components'
import FieldInput from './FieldInput'
import theme from 'theme'

const BadgeAnddSkills = ({ formik, ...props }) => {
  const {
    skillCategory,
    getSkillCategory,
    putDraftCoursesSkill,
    getSkill,
    deleteDraftCoursesSkill,
    isManagePage,
    getCourseSkill,
    deleteCourseSkill,
    putCourseSkill,
  } = props
  const [skillSelected, setSkillSelected] = useState([])
  const {
    params: { courseId },
  } = useRouteMatch()

  const getDraffCourseData = async () => {
    try {
      const draftSkill = await getSkill({ draftCourseId: courseId })
      const result = draftSkill.map(({ name, id }) => ({ label: name, value: id }))
      return setSkillSelected(result)
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const getPublishCourseData = async () => {
    try {
      const courseSkill = await getCourseSkill(courseId)
      const result = courseSkill.map(({ name, id }) => ({ label: name, value: id }))
      return setSkillSelected(result)
    } catch (error) {
      console.error(error)
      return false
    }
  }

  useEffect(() => {
    getSkillCategory()
    if (isManagePage) {
      getPublishCourseData()
    } else {
      getDraffCourseData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMobile = useMedia('(max-width: 768px)')
  const styleUpload = {
    gridTemplateColumns: '1fr',
    margin: '0 30px 30px 0',
    width: isMobile ? '100%' : '500px',
  }

  const styleUploadImage = {
    gridTemplateColumns: '1fr',
    margin: isMobile ? '10px 0' : '0 30px 0 0',
  }

  const handleChange = (skills) => {
    console.log('skills', skills)
    setSkillSelected(skills)
  }

  const handleBlur = () => {
    skillSelected.map((skill) => {
      if (isManagePage) {
        const item = {
          courseId,
          skillId: skill.value,
        }
        putCourseSkill({ courseId, fk: skill.value, ...item })
      } else {
        const item = {
          draftCourseId: courseId,
          skillId: skill.value,
        }
        putDraftCoursesSkill({ draftCourseId: courseId, fk: skill.value, ...item })
      }
      return null
    })
  }

  // eslint-disable-next-line no-shadow
  const BadgeComponent = (props) => {
    const onMouseDown = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const innerProps = { onMouseDown }
    return (
      <Badge>
        <components.MultiValue
          style={{ backgroundColor: 'white' }}
          {...props}
          innerProps={innerProps}
          removeProps={{
            onClick: async () => {
              const { data } = props
              if (isManagePage) {
                const item = {
                  courseId,
                  skillId: data.value,
                }
                await deleteCourseSkill({ courseId, fk: data.value, ...item })
                await getPublishCourseData()
              } else {
                const item = {
                  draftCourseId: courseId,
                  skillId: data.value,
                }
                await deleteDraftCoursesSkill({ draftCourseId: courseId, fk: data.value, ...item })
                await getDraffCourseData()
              }
            },
          }}
        />
      </Badge>
    )
  }

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', fontSize: 16 }),
    option: (provided, { isSelected, isFocused }) => {
      return {
        ...provided,
        backgroundColor: isSelected ? 'white' : isFocused ? 'white' : null,
      }
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: `${theme.whiteblue}`,
        alignSelf: 'center',
        fontSize: 16,
      }
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: `${theme.primary1}`,
      border: 'none',
      background: `${theme.whiteblue}`,
    }),
  }

  return (
    <Fragment>
      <Typography
        id="courseinfo.badgeskill"
        bold
        margin="0 0 24px"
        color="primary1"
        fontSize="text-lg"
      >
        Badge & Skill
      </Typography>
      <Typography id="courseinfo.badgeskill.desc" margin="0 0 16px" color="primary1">
        Badge will display on student&#39;s badge menu when they complete this course
      </Typography>
      <Wrapper>
        <UploadImage
          // id="courseinfo.uploadbadge"
          // detail=""
          // label="Upload Badge"
          style={styleUpload}
          isCrop
          aspect={1 / 1}
          name="badgeImage"
          value={formik.values.badgeImage}
          onChange={formik.setFieldValue}
          styleUploadImage={styleUploadImage}
          afterUpload={(file) => {
            formik.setFieldValue('badgeImage', file.name)
          }}
          color="primary1"
          bold={false}
        />
        <Form>
          <Item>
            <Label id="courseinfo.badgename" htmlFor="badgeName" color="primary1" bold={false}>
              Badge Name
            </Label>
            <Input
              name="badgeName"
              value={formik.values.badgeName}
              status={formik.errors.badgeName && 'error'}
              messageStatus={formik.errors.badgeName}
              onChange={formik.handleChange}
            />
          </Item>
          <Item>
            <Label
              id="courseinfo.badge.desc"
              htmlFor="courseBadgeDesc"
              color="primary1"
              bold={false}
            >
              Description
            </Label>
            <Field name="badgeDescription" component={FieldInput} type="textarea" />
          </Item>
          <Item>
            <Label id="courseinfo.skill" htmlFor="skills" color="primary1" bold={false}>
              Skills
            </Label>
            <InputTag
              isClearable={false}
              isMulti
              value={skillSelected}
              options={skillCategory}
              handleBlur={handleBlur}
              handleChange={handleChange}
              closeMenuOnSelect={false}
              styles={colourStyles}
              components={{ MultiValue: BadgeComponent }}
            />
          </Item>
        </Form>
      </Wrapper>
    </Fragment>
  )
}

const reformat = (skills) => {
  return skills.map((skill) => ({
    label: skill.name,
    value: skill.id,
    options: skill.skills.map(({ id, name }) => ({ label: name, value: id })),
  }))
}

const mapState = ({ SkillCategory: { skillCategory } }) => {
  const _skillCategory = reformat(skillCategory)
  return {
    skillCategory: _skillCategory,
  }
}
const mapDispatch = ({
  SkillCategory: { getSkillCategory },
  DraftCourse: { putDraftCoursesSkill, getSkill, deleteDraftCoursesSkill },
  Course: { getCourseSkill, deleteCourseSkill, putCourseSkill },
}) => ({
  deleteDraftCoursesSkill,
  getSkillCategory,
  putDraftCoursesSkill,
  getSkill,
  getCourseSkill,
  deleteCourseSkill,
  putCourseSkill,
})

export default withRedux(mapState, mapDispatch)(connect(BadgeAnddSkills))
