import styled, { css } from 'styled-components'
import theme from 'theme'
import { Collapse as _Collapse } from 'antd'
import Card from 'components/card'

export const Container = styled.div`
  background-color: ${theme.white};
  //background-color: red;
  min-height: 100%;
  width: 240px;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
`
export const Item = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  padding: 13px 24px 10px 13px;
  //background-color: ${theme.white};
  background-color: transparent;
  cursor: pointer;
  ${({ isSamePath, isFirst, underline, isSecond, isTeacher }) => {
    // if (isFirst && isTeacher) {
    //   return css`
    //     border-bottom: 1px solid ${theme.white};
    //     margin-top: 16px;
    //     padding: 12px 24px;
    //   `
    // }
    if (underline) {
      return css`
        padding: 13px 24px 10px 13px;
        border-bottom: 1px solid #cbd5e1;
      `
    }
    if (isTeacher) {
      if (isSecond && isSamePath) {
        return css`
          background-color: ${theme.primary12};
          border-radius: 8px;
          //margin-top: 14px;
        `
      }

      if (isSecond) {
        return css`
          //margin-top: 14px;
        `
      }
    } else {
      if (isSecond && isSamePath) {
        return css`
          background-color: ${theme.primary12};
          border-radius: 8px;

          margin-top: 0px;
        `
      }
      if (isSecond) {
        return css`
          margin-top: 0px;
        `
      }
    }
    if (isFirst && isSamePath) {
      return css`
        // margin-top: 24px;
        background-color: ${theme.primary12};
        border-radius: 8px;
      `
    }
    if (isFirst) {
      return css`
        // margin-top: 25px;
      `
    }
    if (isSamePath) {
      return css`
        background-color: ${theme.primary12};
        border-radius: 8px;
      `
    }
    return css``
  }}/* ${({ isLogoutStudent }) => {
    if (isLogoutStudent) {
      return css`
        display: flex;
        align-items: end;
      `
    }
  }}
  ${({ isLogoutTeacher }) => {
    if (isLogoutTeacher) {
      return css`
        display: flex;
        align-items: end;
      `
    }
  }} */
`
export const Icon = styled.img`
  width: 16px;
  height: 16px;
`

export const IconSelect = styled.img`
  width: 32px;
  height: 32px;
`
export const Image = styled.img`
  display: flex;
  width: 16px;
  height: 16px;
`
// ${({ isOpenLang }) => {
//   if (isOpenLang) {
//     return css`
//       background-color: ${theme.white} !important;
//     `
//   }
//   return css`
//     background-color: ${theme.primary1};
//   `
// }}
export const Collapse = styled(_Collapse)`
  border-bottom: none !important;

  .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    background-color: ${theme.white};
    border: none !important;
    border-bottom: none !important;
    box-shadow: none !important;

    padding: 0px !important;
    p {
      width: 100% !important;
    }
  }
  .ant-collapse-content {
    border: none !important;
    border-bottom: none !important;
    padding: 0px !important;
    background-color: ${theme.white} !important;
    div {
      padding: 6px 0 6px 28px;
      z-index: 600;
    }
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none !important;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
  }
  .ant-collapse-expand-icon {
    align-self: center;
    margin-right: 24px;
  }
`
export const ExpandImg = styled.img`
  width: 16px;
  height: 16px;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  ${({ isActive }) => {
    if (isActive) {
      return css`
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(180deg);
      `
    }
  }}
`
export const Options = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
`
export const AcademyCard = styled(Card)`
  display: flex;
  align-items: center;
  margin-top: 18px;
  padding-left: 16px;
  font-size: ${theme['text-lg']};
  font-weight: 600;
  border: 2px solid transparent;
  height: 85px;
  color: ${theme.primary1};
  &:hover {
    border: 2px solid ${theme.primary1};
  }
`
export const Padding = styled.div`
  padding-left: 16px;
`
export const SwitchMode = styled.div`
  border-radius: 8px;
  border: 0.7px solid var(--black-black-38, #a9b6c6);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 4px 4px 4px 4px;
  gap: 0px;
  margin-bottom: 14px;
  cursor: pointer;
`
export const SwitchItemActive = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.secondary};
`
export const SwitchItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
