export default {
  colors: {
    primary: '#1145C9',
    secondary: '#253E87',
    hover: '#008DF4', // or light hover 10%
    highlight: '#00C0FF',
    text: '#000',
    disabled: '#9EA4BE', // or disabled 12%
  },

  primary: '#5A69FE',
  primaryFont: '#707070',

  'dodger-blue': '#5788fc',
  'denim-blue': '#4267b2',
  purple: '#442CD2',
  purple1: '#936FFF',
  blue: '#69A8E7',
  'sa-blue': '#7aa0fd',

  primary1: '#253e87',
  primary2: '#5346F9',
  primary3: '#abcceb',
  primary4: '#E0E6F6', //
  primary5: '#EEF0FF',

  primary7: '#1a337d',
  primary8: '#199ad4',

  primary10: '#3a5193',
  primary11: '#395092',
  primary12: '#E9F3F9',
  primary13: '#2086C6',
  primary14: '#E0E6F7',
  primary15: '#130387',
  primary16: '#151F44',

  secondary1: '#3a569f',
  secondary2: '#6380bf',
  secondary3: '#495aff',
  secondary4: '#159bd7',
  secondary5: '#fbc754',
  secondary6: '#fce146',
  secondary7: '#007dce',

  dark: '#4a4a4a',
  dark1: '#1F1F1F',
  dark2: '#AAADB9',
  dark3: '#5F5F61',
  black10: '#F9FAFB',
  black20: '#cbd5e1',
  black38: '#A9B6C6',
  black60: '#64748B',
  black87: '#3D4453',
  black100: '#0F172A',

  cyan2: '#79b6dd',

  grey: '#c2c2c2',
  gray1: '#8c8b8b',
  gray2: '#a6a6a6', //border & supWord
  gray3: '#cdcdcd',
  gray4: '#e8e8e8',
  gray5: '#D0D2D3',
  gray6: '#6F6F6F',
  gray7: '#f9f9f9',
  gray8: '#eef0ff',
  gray9: '#F4F2F3',
  gray10: '#EFEFEF',
  gray11: '#9E9E9E',
  gray12: '#ECF0F9', //hover
  gray13: '#F2F2F2',
  gray14: '#F8F8F8',

  gray19: '#00000020',
  gray25: '#00000010',

  white: '#fff',

  danger: '#CC0000',
  danger1: '#B00002',
  dangerDel: '#FF5151',
  success: '#4CD64C',
  success2: '#2abb2e',
  warning: '#fbc754',
  darkorange: '#ff8c00',
  whiteblue: '#EBF4F9',
  star: '#F9C554',

  'text-xxvs': '6px',
  'text-xxs': '10px',
  'text-xs': '12px',
  'text-sm': '14px',
  'text-base': '16px',
  'text-lg': '18px',
  'text-xl': '20px',
  'text-2xl': '24px',
  'text-3xl': '26px',
  'text-4xl': '28px',
  'text-5xl': '30px',
  'text-6xl': '32px',
  'text-7xl': '34px',
  'text-8xl': '36px',
  'text-9xl': '38px',
  'text-xxl': '40px',
  'text-x2xl': '42px',
  'text-x3xl': '44px',
  'text-x4xl': '46px',
  'text-x5xl': '48px',

  'text-weight7': '700',
}
