import React from 'react'
import PropTypes from 'prop-types'
import { Label, Description } from './style'
import { Typography } from '../index'

function PureLabel({
  id = '',
  descId = '',
  values = {},
  htmlFor,
  children,
  color = '',
  description,
  nowrap,
  bold = true,
  isRequired = false,
  fontSize,
  descFontsize,
  ...props
}) {
  return (
    <Label htmlFor={htmlFor} {...props}>
      <div style={{ display: 'flex' }}>
        <Typography
          id={id}
          values={values}
          paragraph
          bold={bold}
          nowrap={nowrap}
          color={color}
          inherit={color === ''}
          fontSize={fontSize}
        >
          {children}
        </Typography>
        {isRequired && <Typography color="danger">*</Typography>}
      </div>
      {description ? (
        <Description id={descId} fontSize={descFontsize}>
          {description}
        </Description>
      ) : null}
    </Label>
  )
}

PureLabel.propTypes = {
  htmlFor: PropTypes.string,
  props: PropTypes.object,
}

PureLabel.defaultProps = {
  props: {},
}

export default PureLabel
