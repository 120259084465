import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

import { UserAPI } from '../api'
import { ACCESS_TOKEN } from '../constants'

function Analytics({ location }) {
  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      const tokenStr = localStorage.getItem(ACCESS_TOKEN)
      const token = JSON.parse(tokenStr)
      if (token) {
        const log = {
          namespace: 'Page View',
          title: 'Leave or Reload',
          data: {
            location,
          },
        }
        UserAPI.createLog(log)
      }
    })
    return () => {
      window.removeEventListener('beforeunload', () => {})
    }
  }, [location])

  useEffect(() => {
    const tokenStr = localStorage.getItem(ACCESS_TOKEN)
    const token = JSON.parse(tokenStr)
    let data
    if (token) {
      data = { userId: token.userId, ...location }
    } else {
      data = { ...location }
    }
    mixpanel.track('page_view', data)
    if (token) {
      const log = {
        namespace: 'Page View',
        title: 'Page View',
        data: {
          location,
        },
      }
      UserAPI.createLog(log)
    }
    return () => {}
  }, [location])
  return <div />
}

export default withRouter(Analytics)
