import styled from 'styled-components'

const SectionWrapper = styled.section`
  padding: 0px;
  padding: ${({ padding }) => padding};
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  /* margin-bottom: 10px; */
  /* outline: 1px dashed #c1c1c1;
  opacity: 0.4;
  :hover {
    outline: 2px dashed #a1a1a1;
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    opacity: 1;
  } */
`

const ButtonDialogWrapper = styled.div`
  margin: 16px 0 0;
`

const Wrapper = styled.div`
  height: 12px;
`

const DragHandle = styled.div`
  align-self: center;
`

const SubHeader = styled.div`
  /* background-color: #fff;
  border-radius: 10px 10px 0 0; */
  border-bottom: 1px solid #dedfe0;
  .title {
    font-size: ${({ theme }) => theme['text-sm']};
    font-weight: 500;
  }

  padding: 0px;
`

const Content = styled.div`
  padding: 20px 45px;
`

const HoverCircleButton = styled.button`
  // position: absolute;
  // left: -25px;
  // bottom: -11px;
  // margin: auto;
  // border-radius: 50%;
  // border: 1px solid ${({ isActive }) => (isActive ? 'grey' : 'rgba(200,200,200,.3)')};
  // display: ${({ isDragging }) => (isDragging ? 'none' : 'flex')};
  // justify-content: center;
  // align-items: center;
  background-color: transparent;
  transition: border 0.3s;
  outline: none;
  border: none;
  padding: 0;
  img {
    width: 24px;
    height: 24px;
    filter: ${({ isActive }) => (isActive ? 'invert(50%)' : 'invert(10%)')};
    transform: ${({ isActive }) => (isActive ? 'rotate(45deg)' : null)};
    transition: transform 0.3s, filter 0.3s;
  }
`

const CardTitle = styled.span`
  // line-height: 2;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: bottom;
  position: relative;
  // max-width: calc(100% - 80px);

  font-size: ${({ theme }) => theme['text-sm']};
  color: ${({ theme }) => theme.black87};

  // &::before {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
`
const SeperateLine = styled.div`
  border-left: 2px solid ${({ theme }) => theme.black20};
  height: 32px;
`
const Title = styled.div`
  display: flex;
  width: ${({ width }) => (width ? width : '100%')};
  //max-width: calc(100% - 155px);
  gap: ${({ gap }) => (gap ? gap : '16px')};
  align-items: center;
`
const IconSection = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`

export {
  SectionWrapper,
  ButtonDialogWrapper,
  Wrapper,
  DragHandle,
  SubHeader,
  Content,
  HoverCircleButton,
  CardTitle,
  SeperateLine,
  Title,
  IconSection,
}
