import _get from 'lodash/get'
import { SchoolMemberAPI, SchoolAPI, StudentGroupAPI, SchoolStudentsAPI } from '../../api'
import appUserApi from '../../api/user'

export const SchoolMember = {
  state: {
    schoolMember: [],
    schoolStudent: [],
    schoolGroup: [],
    studentGroup: [],
    schoolTeacher: [],
    studentGroupById: {},
    groupMember: [],
    groupCourses: [],
    sections: [],
    filteredSchoolStudents: [],
    memberById: {},
  },
  reducers: {
    setSections(state, payload) {
      return {
        ...state,
        sections: payload,
      }
    },
    setSchoolTeacher(state, payload) {
      return {
        ...state,
        schoolTeacher: payload,
      }
    },
    setSchoolMember(state, payload) {
      return {
        ...state,
        schoolMember: payload,
      }
    },
    setSchoolStudent(state, payload) {
      return {
        ...state,
        schoolStudent: payload,
      }
    },
    setSchoolStudentMore(state, payload) {
      return {
        ...state,
        schoolStudent: [...state.schoolStudent, ...payload],
      }
    },
    setSchoolStudentsCount(state, payload) {
      return {
        ...state,
        schoolStudentsCount: payload,
      }
    },
    setFilteredSchoolStudents(state, payload) {
      return {
        ...state,
        filteredSchoolStudents: payload,
      }
    },
    setSchoolGroup(state, payload) {
      return {
        ...state,
        schoolGroup: payload,
      }
    },
    setStudents(state, payload) {
      return {
        ...state,
        studentGroup: payload,
      }
    },
    setStudentGroupById(state, payload) {
      return {
        ...state,
        studentGroupById: payload,
      }
    },
    setGroupMember(state, payload) {
      return {
        ...state,
        groupMember: payload,
      }
    },
    setMember(state, payload) {
      return {
        ...state,
        memberById: payload,
      }
    },
    resetGroupMember(state) {
      return {
        ...state,
        groupMember: [],
      }
    },
    setStudentGroupCourses(state, payload) {
      return {
        ...state,
        groupCourses: payload,
      }
    },
    resetGroupCourses(state) {
      return {
        ...state,
        groupCourses: [],
      }
    },
  },
  effects: (dispatch) => ({
    async createSchoolGroup(payload) {
      try {
        const result = await SchoolAPI.createGroups(payload)
        return result
      } catch (error) {
        throw error
      }
    },
    async updateSections(payload) {
      try {
        await SchoolAPI.updateSections(payload)
      } catch (error) {
        throw error
      }
    },
    async getSchoolSections(id) {
      try {
        const sections = await SchoolAPI.getSections({ id })
        this.setSections(sections)
      } catch (error) {
        throw error
      }
    },
    async getSchoolTeacher({ id }) {
      try {
        const teacher = await SchoolAPI.getTeachersDetail({ id })
        this.setSchoolTeacher(teacher)
      } catch (error) {
        throw error
      }
    },
    async deleteSchoolMember({ schoolId, memberId }) {
      const result = await SchoolAPI.deleteSchoolMember({ id: schoolId, fk: memberId })
      this.getSchoolMember(schoolId)
      return result
    },
    async deleteSchoolMemberRequest({ schoolId, memberId }) {
      const result = await SchoolAPI.deleteSchoolMemberRequest({ id: schoolId, fk: memberId })
      this.getSchoolMember(schoolId)
      return result
    },

    async removeSchoolStudent(payload) {
      try {
        await SchoolStudentsAPI.delete(payload)
      } catch (error) {
        throw error
      }
    },
    async getSchoolMember(schoolId) {
      try {
        const filter = JSON.stringify({
          include: ['appUser', 'school'],
          where: { schoolId },
        })
        const school = await SchoolMemberAPI.find({ filter })
        this.setSchoolMember(school)
      } catch (e) {
        throw e
      }
    },
    async getSchoolMemberById({ schoolId, memberId }) {
      try {
        const filter = JSON.stringify({
          include: ['appUser'],
          where: { schoolId },
        })
        const member = await SchoolMemberAPI.findById({
          id: memberId,
          filter,
        })
        this.setMember(member)
      } catch (e) {
        throw e
      }
    },

    async getSchoolTeacherMember({ schoolId }) {
      try {
        const filter = JSON.stringify({
          include: ['appUser', 'school'],
          where: { schoolId, or: [{ role: 'admin' }, { role: 'owner' }] },
        })
        const school = await SchoolMemberAPI.find({ filter })
        this.setSchoolMember(school)
      } catch (e) {
        throw e
      }
    },
    async inviteTeacher(payload) {
      const schoolId = payload.id
      try {
        const result = await SchoolAPI.inviteTeacher(payload)
        this.getSchoolMember(schoolId)
        dispatch.Alert.success({
          title: 'An invitation has been sent successfully',
        })
        return result
      } catch (error) {
        dispatch.Alert.error({
          title: 'Invitation Failed',
          description:
            error.response.message === 'Already requested the teacher'
              ? 'You have already sent an invitation to this email address'
              : 'Something went wrong',
        })
        throw error
      }
    },
    async inviteStudent(payload) {
      const schoolId = payload.id
      try {
        const result = await SchoolAPI.inviteStudent(payload)
        this.getSchoolStudent({ schoolId })
        dispatch.Alert.success({
          title: 'An invitation has been sent successfully',
        })
        return result
      } catch (error) {
        dispatch.Alert.error({
          title: 'Invitation Failed',
          description:
            error.response.message === 'Already requested the student'
              ? 'You have already sent an invitation to this email address'
              : 'Something went wrong',
        })
        throw error
      }
    },
    async getSchoolStudent({ schoolId, isApproved, isMore, page = 0 }) {
      const limit = 20
      const skip = page * limit
      try {
        let students = []
        if (typeof isApproved === 'boolean') {
          students = await SchoolAPI.getStudentInSchool({
            id: schoolId,
            filter: JSON.stringify({
              include: 'appUser',
              where: {
                isApproved,
              },
              limit,
              skip,
            }),
          })
        } else {
          const current = await SchoolAPI.getStudentInSchool({
            id: schoolId,
            filter: JSON.stringify({
              include: 'appUser',
              where: {
                isApproved: true,
              },
              limit,
              skip,
            }),
          })
          const pending = await SchoolAPI.getStudentInSchool({
            id: schoolId,
            filter: JSON.stringify({
              include: 'appUser',
              where: {
                isApproved: false,
              },
              limit,
              skip,
            }),
          })
          students = [...current, ...pending]
        }
        console.log('🍎 students', students.length)
        if (isMore) {
          this.setSchoolStudentMore(students)
        } else {
          this.setSchoolStudent(students)
        }
      } catch (error) {
        throw error
      }
    },
    async getSchoolStudentsCount({ schoolId }) {
      try {
        const countCurrent = await SchoolAPI.getSchoolStudentsCount({
          id: schoolId,
          where: JSON.stringify({
            isApproved: true,
          }),
        })
        const countPending = await SchoolAPI.getSchoolStudentsCount({
          id: schoolId,
          where: JSON.stringify({
            isApproved: false,
          }),
        })
        this.setSchoolStudentsCount({
          countCurrent,
          countPending,
        })
      } catch (error) {
        throw error
      }
    },
    async getFilteredStudentInSchool({ schoolId, filter }) {
      try {
        const result = await SchoolAPI.getFilteredStudentInSchool({
          id: schoolId,
          filter: JSON.stringify(filter),
        })
        console.log('result', result.filteredStudents)
        this.setFilteredSchoolStudents(result.filteredStudents)
      } catch (error) {
        throw error
      }
    },
    async updateSchoolStudent(payload) {
      try {
        await SchoolStudentsAPI.update(payload)
      } catch (error) {
        throw error
      }
    },
    async getSchoolGroup(id) {
      try {
        const { result } = await SchoolAPI.getLastChildGroup({ id })
        this.setSchoolGroup(result)
      } catch (error) {
        throw error
      }
    },
    async getSchoolGroupById(payload) {
      // const filter = JSON.stringify({ include: 'students' })
      // const filter = JSON.stringify({ include: { students: 'schoolStudents' } })
      const { id, schoolId } = payload
      console.log('🏆', schoolId)
      const filter = JSON.stringify({
        include: {
          relation: 'students',
          scope: {
            fields: [
              'title',
              'firstname',
              'lastname',
              'profileImage',
              'description',
              'backgroundImage',
              'email',
              'id',
            ],
            include: {
              relation: 'schoolStudents',
              scope: {
                where: { isApproved: true, schoolId },
              },
            },
          },
        },
      })

      try {
        const result = await StudentGroupAPI.getStudents({ id, filter })
        const students = result.students.map((student) => ({
          ...student,
          studentTags: _get(student, 'schoolStudents[0].studentTags', []),
        }))
        delete students.schoolStudents
        this.setGroupMember(students)
      } catch (error) {
        throw error
      }
    },
    async updateStudentGroup(payload) {
      try {
        await StudentGroupAPI.update(payload)
      } catch (error) {
        throw error
      }
    },
    async deleteStudentGroup({ id }) {
      try {
        await StudentGroupAPI.delete({ id })
      } catch (error) {
        throw error
      }
    },
    async createStudentGroup(payload) {
      try {
        const { id } = await StudentGroupAPI.create(payload)
        return id
      } catch (error) {
        throw error
      }
    },
    async addStudentGroup(payload) {
      try {
        await StudentGroupAPI.addStudents(payload)
      } catch (error) {
        throw error
      }
    },
    async getStudentGroup() {
      try {
        const payload = {
          filter: { where: { email: { neq: 'admin@classwin.com' } } },
        }
        const students = await appUserApi.find(payload)
        this.setStudents(students)
      } catch (error) {
        throw error
      }
    },
    async getStudentGroupCourses(id) {
      try {
        const courses = await StudentGroupAPI.getCourses({ id })
        // console.log('getStudentGroupCourses', courses)
        this.setStudentGroupCourses(courses)
        return courses
      } catch (error) {
        throw error
      }
    },
    async getStudentGroupById(id) {
      const filter = JSON.stringify({ include: 'school' })

      try {
        const students = await StudentGroupAPI.findById({ id, filter })
        this.setStudentGroupById(students)
      } catch (error) {
        throw error
      }
    },
    async addGroupCourses(payload) {
      try {
        const result = await StudentGroupAPI.addCourses(payload)
        console.log('addGroupCourses', result)
      } catch (error) {
        throw error
      }
    },
    async updatePermissionMember({ payload, id }) {
      try {
        await SchoolMemberAPI.update({
          permissions: payload,
          id,
        })
      } catch (e) {
        throw e
      }
    },
    async changeSchoolMemberRole(payload) {
      try {
        const result = await SchoolMemberAPI.update(payload)
        await this.getSchoolMember(result.schoolId)
      } catch (e) {
        throw e
      }
    },
  }),

  selectors: () => ({
    // schoolMemberRole() {
    //   return (rootState, props) => {
    //     const {  } = rootState.SchoolMember
    //     return test
    //   }
    // },
  }),
}
