import _ from 'lodash'

const removeSpacePattern = / +/g
const removeBackSlashAndSpacePattern = / \/ /g

function removeString(str, pattern) {
  return str.replace(pattern, '')
}

export function cardFormat(str) {
  const newStr = [...removeString(str, removeSpacePattern)]
  return _.chunk(newStr, 4)
    .map((el) => el.join(''))
    .join(' ')
}

export function expiryFormat(str) {
  const newStr = removeString(str, removeBackSlashAndSpacePattern)

  return _.chunk(newStr, 2)
    .map((el) => el.join(''))
    .join(' / ')
}

export function getFullName(value) {
  const firstname = _.get(value, 'firstname')
  const lastname = _.get(value, 'lastname')
  if (!firstname && !lastname) {
    return '-'
  }
  return `${firstname[0].toUpperCase()}${firstname
    .slice(1)
    .toLowerCase()} ${lastname[0].toUpperCase()}${lastname.slice(1).toLowerCase()}`
}

export function getShortName(value) {
  const firstname = _.get(value, 'firstname')
  const lastname = _.get(value, 'lastname')
  if (!firstname && !lastname) {
    return '-'
  }
  return `${firstname[0].toUpperCase()}${firstname
    .slice(1)
    .toLowerCase()} ${lastname[0].toUpperCase()}.`
}

export function getNameNotApprove(value) {
  const firstname = _.get(value, 'firstname')
  const lastname = _.get(value, 'lastname')
  if (!firstname && !lastname) {
    return '-'
  }
  return `${firstname[0].toUpperCase()}${firstname.slice(1)} ${lastname[0].toUpperCase()}.`
}

export function getPrice(num, currency = 'THB', hasDecimal = false, hasCurrency = true) {
  let price = Math.ceil(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const isFree = price === '0'

  if (hasDecimal) {
    price = Math.ceil(num)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  if (isFree) {
    return 'Free'
  }
  if (hasCurrency) {
    return `${price} ${currency}`
  }

  return price
}
export function getPricehasDecimal(num, currency = 'THB', hasDecimal = true, hasCurrency = false) {
  let price = Math.ceil(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const isFree = price === '0'

  if (hasDecimal) {
    price = Math.ceil(num)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  if (isFree) {
    return '0.00'
  }
  if (hasCurrency) {
    return `${price} ${currency}`
  }

  return price
}

export function getEstimatedTime(hour, minute) {
  return `${hour} Hr ${minute} Min`
}

export function getLevels(levels = []) {
  return levels.map((str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`).join(', ')
}

export function uniqueId() {
  return `id-${Math.random().toString(36).substr(2, 16)}`
}

export const getShowTest = (hasPreTest, hasPostTest) => {
  if (hasPreTest && hasPostTest) {
    return 'both'
  }
  if (hasPreTest) {
    return 'pretest'
  }
  if (hasPostTest) {
    return 'posttest'
  }
  return ''
}

export const getCardStatus = (message) => {
  switch (message) {
    case 'failed_fraud_check':
      return 'Card was marked as fraudulent.'
    case 'failed_processing':
      return 'General payment processing failure.'
    case 'insufficient_balance':
    case 'insufficient_fund':
      return 'Insufficient funds in the account or the card has reached the credit limit.'
    case 'invalid_account_number':
    case 'invalid_account':
      return 'Valid account for payment method not found.'
    case 'invalid_security_code':
      return 'Security code invalid or card did not pass preauthorization.'
    case 'payment_cancelled':
      return 'Payment cancelled by payer.'
    case 'payment_rejected':
      return 'Payment rejected by issuer.'
    case 'stolen_or_lost_card':
      return 'Card stolen or lost.'
    case 'timeout':
      return 'Payer did not take action before charge expiration.'
    default:
      return message
  }
}

export const getPromptpayStatus = (message) => {
  switch (message) {
    case 'failed_processing':
      return 'General payment processing failure.'
    case 'insufficient_balance':
      return 'Insufficient funds in the account or the payment method has reached its limit.'
    case 'payment_cancelled':
      return 'Payment cancelled by payer.'
    case 'Not Paid':
      return 'You have not paid'
    default:
      return message
  }
}
