import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Icon } from '../../../../components/index'
import { ContainerDialog, CardDialog } from './style'
import TutorialIcon from 'images/Classwin - New icon_Tutorial.svg'
import EvaIcon from 'react-eva-icons'
import styled from 'styled-components'
import List from './list'
const IconWrapper = styled.span`
  position: absolute;
  left: -4px;
  top: 27px;
  display: flex;
  i.eva-hover {
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
  }
  i:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 50%;
    border: 1px solid lightgrey;
    background-color: transparent;
    position: absolute;
    right: -1px;
  }
`

class PureButtonDialog extends Component {
  state = {
    // isShow: false,
    isShow: true,
  }

  handleShow = () => {
    this.setState(({ isShow }) => ({ isShow: !isShow }))
  }

  handleClick = (id) => {
    const { onClick, priority } = this.props
    // const { value } = e.target
    onClick(id, priority)
    // this.handleShow()
  }

  render() {
    const { data, addIcon, hasPreTest, hasPostTest, showIcon } = this.props
    const { isShow } = this.state
    const tutorialLink = 'http://classwin.co/course-section/'
    let filterData
    if (hasPreTest && hasPostTest)
      filterData = data.filter(({ title }) => title !== 'Pre-Test' && title !== 'Post-Test')
    else if (hasPreTest) filterData = data.filter(({ title }) => title !== 'Pre-Test')
    else if (hasPostTest) filterData = data.filter(({ title }) => title !== 'Post-Test')
    else filterData = data

    return (
      <ContainerDialog>
        {isShow && (
          <CardDialog>
            {addIcon && (
              <IconWrapper>
                <EvaIcon name="plus-outline" size="medium" fill="lightgrey" />
              </IconWrapper>
            )}
            <List
              data={filterData}
              onClick={this.handleClick}
              {...(showIcon
                ? {
                    icon: (
                      <a
                        href={tutorialLink}
                        target="_blank"
                        rel="noreferrer"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Icon style={{ width: '25px', height: '20px' }} src={TutorialIcon} />
                      </a>
                    ),
                  }
                : {})}
            />
          </CardDialog>
          // </OutsideAlerter>
        )}
      </ContainerDialog>
    )
  }
}

PureButtonDialog.propTypes = {
  // children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
}

PureButtonDialog.defaultProps = {
  data: [],
}

export default PureButtonDialog
