import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import { Input, Wrapper, Span } from './style'
import Typography from '../typography'

function PureInput({
  id,
  inputId,
  type,
  noPadding,
  name,
  value,
  onChange,
  full = false,
  max,
  min,
  styleInput = {},
  inputRef,
  placeholder,
  readOnly = false,
  color,
  ...props
}) {
  const unique = uuid()
  return (
    <Fragment>
      <Wrapper full={full}>
        {props.prefix && (
          <Span noPadding={noPadding} prefix {...props}>
            {props.prefix}
          </Span>
        )}
        <Input
          id={inputId ? inputId : `${id}-${unique}`}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          style={styleInput}
          min={min}
          max={max}
          readOnly={readOnly}
          ref={inputRef}
          placeholder={placeholder}
          color={color}
          {...props}
        />
        {props.suffix && (
          <Span suffix {...props}>
            {props.suffix}
          </Span>
        )}
        {props.counter && (
          <Span counter {...props}>
            {value.length} / {props.maxLength}
          </Span>
        )}
      </Wrapper>
      {props.status === 'error' && (
        <Typography margin="0px 0px 0px" strong error id={props.messageStatusId}>
          {props.messageStatus}
        </Typography>
      )}
      {props.status === 'warning' && (
        <Typography margin="0px 0px" strong color="warning">
          {props.messageStatus}
        </Typography>
      )}
    </Fragment>
  )
}

PureInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  props: PropTypes.object,
}

PureInput.defaultProps = {
  type: 'text',
  placeholder: '',
  props: {},
}

export default PureInput
