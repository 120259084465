import styled, { css } from 'styled-components'
import theme from 'theme'

export const Container = styled.div`
  border: ${({ bordered }) => (bordered ? 1 : 0)}px solid #f0f0f0;
  border-right: 0;
  border-bottom: 0;

  ${(props) => {
    if (props.full)
      return css`
        width: 100%;
      `
  }}

  position: relative;

  // th.ant-table-cell {
  //   background-color: #fff;
  // }

  thead.ant-table-thead > tr > th::before {
    display: none;
  }

  thead > tr > .ant-table-cell-fix-right {
    right: 0 !important;
  }

  .ant-table-cell-scrollbar {
    display: none;
  }

  .ant-table-content {
    border-radius: 8px !important;
  }

  tbody > tr > .ant-table-cell-fix-right {
    background-color: #fff;
  }

  .ant-table-ping-right tbody > tr > .ant-table-cell-fix-right-first {
    -webkit-box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
  }

  .ant-table-ping-right thead > tr > .ant-table-cell-fix-right-first {
    -webkit-box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
  }

  thead > tr > .ant-table-cell-fix-left {
  }

  tbody > tr > .ant-table-cell-fix-left {
    background-color: #fff;
  }

  .ant-table-ping-left tbody > tr > .ant-table-cell-fix-left-last {
    -webkit-box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
  }

  .ant-table-ping-left thead > tr > .ant-table-cell-fix-left-last {
    -webkit-box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
  }

  th {
    font-weight: 600;
  }

  th,
  td {
    padding: 20px;
    &.ant-table-cell-fix-right-first {
      padding: 0;
      background-color: #fff;
    }
    &.ant-table-cell-fix-left-last {
      background-color: #fff;
    }
  }

  tbody > tr {
    background-color: #fff;
    margin: 10px 0px;
  }

  tbody > .ant-table-placeholder {
    &:hover {
      background-color: ${({ theme }) => theme.gray12};
      * {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  thead > tr > th {
    border-bottom: 1px solid ${theme.gray4};
    border-right: ${({ bordered }) => (bordered ? 1 : 0)}px solid ${theme.gray4};
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    border-right: ${({ bordered }) => (bordered ? 1 : 0)}px solid #f0f0f0;
  }

  .ant-table-fixed-right {
    display: none;
  }
  .ant-table-fixed-left {
    display: none;
  }

  .ant-table-fixed {
    table-layout: fixed;
    width: 100%;
  }

  .ant-table-body {
    ::-webkit-scrollbar {
      width: 0px;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`

export const EmptyWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 20px;
  img {
    margin-bottom: 20px;
    height: 22vh;
    width: auto;
  }
`
