import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import get from 'lodash/get'
import LibSidebar from 'react-sidebar'
import ErrorBoundary from 'components/errorBoundary'
import { LayoutContainer, Content } from './styles'
import { UnauthorizedLayout } from 'components/layout/UnauthorizedLayout'
import SearchBar from 'containers/searchBar/loadable'
import DrawerProfile from 'components/DrawerProfile'
import { popupMenus } from 'containers/searchBar/popupMenus'
import store from 'store'
import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'

const mql = window.matchMedia(`(max-width: 768px)`)

export class TeacherLayout extends Component {
  constructor(props) {
    super(props)
    mql.addListener(props.mediaQueryChanged)
  }

  async componentDidMount() {
    const { getSchoolListCount, history } = this.props
    const { count } = await getSchoolListCount()
    if (count === 0) {
      history.push({
        pathname: '/teacher/connections/school',
        // search: '?query=abc',
        state: { isShow: true },
      })
    }
  }

  componentWillUnmount() {
    mql.removeListener(this.props.mediaQueryChanged)
  }

  render() {
    const { authorized, sidebarOpen, toggle, appUser, isTeacher, match, location } = this.props
    if (!authorized) return <UnauthorizedLayout {...this.props} />
    const isTeacherPath = match.url.startsWith('/teacher') || match.url.startsWith('/schools')
    const pathname = get(location, 'pathname', false)

    return (
      <ErrorBoundary>
        <LayoutContainer>
          <LargeDesktop>
            <LibSidebar
              open={sidebarOpen}
              onSetOpen={toggle}
              pullRight
              sidebar={
                <DrawerProfile
                  onRequestClose={() => toggle(false)}
                  menu={popupMenus(isTeacherPath, appUser?.roles?.length)}
                  pathname={pathname}
                  isTeacher={isTeacher}
                />
              }
              styles={{
                sidebar: {
                  zIndex: 300,
                },
                overlay: {
                  zIndex: 250,
                },
                content: {
                  position: 'absolute',
                  overflowY: 'auto',
                },
              }}
            >
              <Content>
                <SearchBar />
                {this.props.children}
              </Content>
            </LibSidebar>
          </LargeDesktop>
          <MobileAndTablet>
            <LibSidebar
              open={sidebarOpen}
              onSetOpen={toggle}
              pullLeft
              sidebar={
                <DrawerProfile
                  onRequestClose={() => toggle(false)}
                  menu={popupMenus(isTeacherPath, appUser?.roles?.length)}
                  pathname={pathname}
                  isTeacher={isTeacher}
                />
              }
              styles={{
                sidebar: {
                  zIndex: 300,
                },
                overlay: {
                  zIndex: 250,
                },
                content: {
                  position: 'absolute',
                  overflowY: 'auto',
                },
              }}
            >
              <Content>
                <SearchBar />
                {this.props.children}
              </Content>
            </LibSidebar>
          </MobileAndTablet>
        </LayoutContainer>
      </ErrorBoundary>
    )
  }
}

TeacherLayout.defaultProps = {
  authorized: true,
}

TeacherLayout.propTypes = {
  children: PropTypes.element.isRequired,
  authorized: PropTypes.bool,
}

const selection = store.select((models) => ({
  isTeacher: models.AppUser.isTeacher,
}))
const mapState = (state) => {
  return {
    sidebarOpen: state.Sidebar.sidebarOpen,
    appUser: state.AppUser.appUser,
    ...selection(state),
  }
}

const mapDispatch = (dispatch) => {
  return {
    toggle: dispatch.Sidebar.toggle,
    mediaQueryChanged: dispatch.Sidebar.mediaQueryChanged,
    getSchoolListCount: dispatch.TeacherSchool.getSchoolListCount,
  }
}

export default connect(mapState, mapDispatch)(TeacherLayout)
