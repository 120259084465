import { Icon } from 'components/icon/style'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import SearchIcon from '../../images/icon_Search.svg'
import CloseSearch from '../../images/icons/Classwin_CloseSearch2.png'
const Input = styled.input`
  flex: 1;
  background: #f9f9f9;
  color: ${(props) => props.theme.primary1};
  font-weight: 500;
  font-size: ${({ theme }) => theme['text-sm']};
  ${({ width }) => {
    if (width) {
      return css`
        width: ${width}px;
      `
    }
    return css``
  }}
  //color: ${(props) => props.theme.gray6};
  ${({ height }) => {
    if (height) {
      return css`
        height: ${height}px;
      `
    } else {
      return css`
        height: 48px;
      `
    }
  }}
  ${({ isBorder, borderRadius }) => {
    if (isBorder) {
      if (borderRadius) {
        return css`
          border-radius: ${borderRadius}px;
        `
      }
      return css`
        border-radius: 6px;
      `
    } else {
      return css`
        border-radius: 50px;
      `
    }
  }}
  
  border: none;
  outline: none;
  ${({ isIcon }) => {
    if (isIcon) {
      return css`
        padding-left: 50px;
      `
    } else {
      return css`
        padding-left: 18px;
      `
    }
  }}

  ${({ isPadding }) => {
    if (isPadding) {
      return css`
        padding-right: 100px;
      `
    } else {
      return css`
        padding-left: 40px;
      `
    }
  }}

  ${(props) => {
    if (props.leftDropdown) {
      return css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `
    }
    return css``
  }}
  ${({ isLanding }) => {
    if (!isLanding) {
      return css`
        @media (max-width: 426px) {
          ${({ isStyle }) => {
            if (isStyle) {
              return css``
            } else {
              return css`
                margin-right: 4px;
                padding-right: 8px;
              `
            }
          }}
        }
      `
    }
  }}
  @media (max-width: 768px) {
    /* font-size: ${({ theme }) => theme['text-xs']}; */
    ${({ isStyle }) => {
      if (isStyle) {
        return css``
      } else {
        return css`
          margin-right: 10px;
        `
      }
    }}
  }
  &::placeholder {
    color: ${(props) => props.theme.black38};
    font-weight: 500;
    font-size: ${({ theme }) => theme['text-sm']};
  }
`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  margin-left: -18px;
  ${({ width, isLanding }) => {
    if (isLanding && width) {
      return css`
        width: ${width}%;
      `
    } else if (width) {
      return css`
        width: ${width};
      `
    }
    return css``
  }}
  ${({ isLanding }) => {
    if (isLanding) {
      return css`
        @media (min-width: 769px) {
          margin-left: 0px;
          margin-right: 10px;
        }
      `
    }
  }}
`
class SearchInput extends Component {
  render() {
    const {
      placeholder = 'Search Course',
      refs,
      onClick,
      onClickCustom,
      isBorder = false,
      isIcon = false,
      isStyle = false,
      isPadding = false,
      autoFocus,
      handleOnClose,
      width,
      isLanding = false,
      smallMode = false,
    } = this.props

    return (
      <SearchContainer width={width} isLanding={isLanding}>
        {isIcon && (
          <Icon
            src={SearchIcon}
            alt="search-icon"
            style={{ position: 'relative', cursor: 'pointer', left: 40 }}
            size={16}
            onClick={onClick || onClickCustom}
          />
        )}
        <Input
          ref={refs}
          placeholder={placeholder}
          {...this.props}
          autoFocus={autoFocus}
          onBlur={handleOnClose}
          isStyle={isStyle}
          isPadding={isPadding}
          isBorder={isBorder}
          isLanding={isLanding}
          borderRadius={80}
          height={40}
        />
        {smallMode && (
          <Icon
            src={CloseSearch}
            alt="search-icon"
            style={{ position: 'absolute', cursor: 'pointer', right: 30 }}
            size={20}
            onClick={handleOnClose}
          />
        )}
      </SearchContainer>
    )
  }
}

export default SearchInput
