import React from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from '../errorBoundary'
import { Wrapper, Bar } from './style'

class ProgressBar extends React.Component {
  render() {
    const { color, value } = this.props
    return (
      <ErrorBoundary>
        <Wrapper {...this.props}>
          <Bar value={value} color={color} {...this.props} />
        </Wrapper>
      </ErrorBoundary>
    )
  }
}

ProgressBar.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default ProgressBar
