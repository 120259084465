import React, { useState } from 'react'
import styled from 'styled-components'
import getImageUrl from 'utils/getImageUrl'
import {
  Avatar,
  Typography,
  Modal,
  Tooltip,
  Input,
  Textarea,
  Label,
  Button,
  UploadAvatar,
} from 'components'
import * as Yup from 'yup'
import { Form, FieldArray, Field, Formik } from 'formik'
import MoreIcon from 'images/icons/DotsThreeVertical.svg'
import _get from 'lodash/get'
import Popup from './popup'
// import { DesktopAndTablet, SmallMobile } from 'utils/displayResponsive'
import { connect } from 'react-redux'
import IconPlus from '../../images/icons/primary-plus.svg'

const Container = styled.div`
  /* position: sticky; */
  /* bottom: 0; */
  padding-top: 7px;
  height: auto;
  /* border-top: solid 1px #dedfe0; */
  /* background-color: #fff; */
  z-index: 2;
  /* overflow-y: scroll; */

  @media (max-width: 768px) {
    padding: 15px 20px;
  }
  @media (max-width: 426px) {
    padding: 0;
    border-top: none;
  }

  .icon-bg {
    border-radius: 50%;
    position: absolute;
    bottom: 2px;
    right: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 426px) {
    height: auto;
  }
  /* .customModal img {
    width: 30px;
  } */
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  /* position: sticky; */
  top: 0;
  background: #fff;
  z-index: 2;
  /* padding-top: 15px; */

  label {
    margin-bottom: 0;
  }

  button {
    /* margin-left: 20px; */
    @media (max-width: 426px) {
      align-self: flex-start;
    }
  }
  @media (max-width: 426px) {
    padding-top: 0;
    padding-bottom: 16px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 15px;
`

const ModalCotainer = styled.div`
  /* display: flex; */
  padding: 44px 40px 24px;

  .title {
    margin-bottom: 20px;
  }
`

// const Divider = styled.div`
//   margin: 24px 0;
//   border-top: solid 1px #dedfe0;
// `

const Right = styled.div`
  margin-left: 32px;
  flex: 1;
  > label > div > p {
    font-weight: 400;
  }

  .add-button {
    margin: 16px 0 0 auto;
  }
`

const InstructorFooter = ({
  customList,
  //formikProps: { setFieldValue },
  locale,
  setFieldValue,
}) => {
  const [seletedCard, setSeletedCard] = useState({ profileImage: '', name: '', index: -1 })
  const [showModal, setShowModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const handleOpenModal = () => {
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
    // setSeletedCard({ profileImage: '', name: '', index: -1 })
  }

  const afterUpload = ({ name: profileImage }) => {
    setSeletedCard({ ...seletedCard, profileImage })
    setFieldValue(`customTeacherList[${seletedCard.index}].profileImage`, profileImage)
    // const { profileImage } = this.seletedCard
    // updateProfile({ profileImage })
    // this.setState({ profileImage })
  }

  const styleImageBox = {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
  }
  const styleUploadImage = {
    margin: '0px',
  }
  const translate = {
    name: locale === 'th' ? 'ชื่อ' : 'name',
    description: locale === 'th' ? 'คำอธิบาย' : 'description',
  }
  return (
    <Container>
      {/* <SmallMobile>
        <Divider />
      </SmallMobile> */}
      <Form>
        <FieldArray name="customTeacherList">
          {(arrayHelpers) => (
            <React.Fragment>
              <Header>
                {/* <Label
                  bold
                  id="course.instructor.instructor-list"
                  description="This list of instructors will be displayed on course detail page."
                  descId="course.instructor.instructor-list.desc"
                >
                  Instructor detail
                </Label> */}
                <Typography
                  id="course.instructor.guest-instructor"
                  color="primary1"
                  margin="0 0 12px 0"
                >
                  Guest Instructor
                </Typography>
                {/* <SmallMobile>
                  <Button
                    primary
                    type="button"
                    onClick={() => setShowAddModal(true)}
                    width="100px"
                    // padding="0"
                    // id="button.add2"
                  >
                    <Typography id="button.add2" color="white" superbold fontSize="text-base">
                      + Add
                    </Typography>
                  </Button>
                </SmallMobile>
                <DesktopAndTablet>
                  <Button
                    primary
                    type="button"
                    onClick={() => setShowAddModal(true)}
                    width="100px"
                    // id="button.add2"
                  >
                    <Typography id="button.add2" color="white" superbold fontSize="text-base">
                      + Add
                    </Typography>
                  </Button>
                </DesktopAndTablet> */}
              </Header>
              <Grid>
                {customList.map((teacher, index) =>
                  teacher.id ? null : (
                    <CustomCard
                      id={teacher.id}
                      key={teacher.id}
                      {...teacher}
                      index={index}
                      arrayHelpers={arrayHelpers}
                      handleOpenModal={handleOpenModal}
                      setSeletedCard={setSeletedCard}
                    />
                  )
                )}
                <Card background={true}>
                  <Image
                    src={IconPlus}
                    alt="more-icon"
                    width={30}
                    height={30}
                    onClick={() => setShowAddModal(true)}
                    style={{ cursor: 'pointer' }}
                  />
                </Card>
              </Grid>
              <Modal
                isOpen={showModal}
                shownXclose
                contentStyle={{ width: '85%', maxWidth: '768px', padding: 0, border: 'none' }}
                onClickClose={handleCloseModal}
                onRequestClose={handleCloseModal}
              >
                <ModalCotainer>
                  <Typography
                    id="course.instructor.edit-modal.title"
                    fontSize="text-xl"
                    superbold
                    color="primary1"
                    margin="0 0 32px 0"
                  >
                    Edit instructor detail
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <UploadAvatar
                        name="logo"
                        grayBorder
                        detail=""
                        value={getImageUrl(
                          _get(customList, `[${seletedCard.index}].profileImage`, undefined)
                        )}
                        styleImageBox={styleImageBox}
                        styleUploadImage={styleUploadImage}
                        afterUpload={afterUpload}
                      />
                    </div>
                    <Right>
                      <Field name={`customTeacherList[${seletedCard.index}].name`}>
                        {({ field, form: { errors } }) => (
                          <React.Fragment>
                            <Label
                              id="label.name"
                              htmlFor="firstname"
                              fontSize="text-sm"
                              color="primary1"
                            >
                              Name
                            </Label>
                            <Input
                              {...field}
                              status={
                                errors[`customTeacherList[${seletedCard.index}].name`] && 'error'
                              }
                              placeholder={translate.name}
                              messageStatus={errors[`customTeacherList[${seletedCard.index}].name`]}
                              Border={({ theme }) => `1px solid ${theme.black20}`}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                      <br />
                      <Field name={`customTeacherList[${seletedCard.index}].description`}>
                        {({ field, form: { errors } }) => (
                          <React.Fragment>
                            <Label
                              id="label.desc"
                              htmlFor="firstname"
                              fontSize="text-sm"
                              color="primary1"
                            >
                              Description
                            </Label>
                            <Textarea
                              {...field}
                              status={
                                errors[`customTeacherList[${seletedCard.index}].name`] && 'error'
                              }
                              placeholder={translate.description}
                              messageStatus={errors[`customTeacherList[${seletedCard.index}].name`]}
                              Border={({ theme }) => `1px solid ${theme.black20}`}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                      <Button
                        primary
                        type="button"
                        onClick={() => setShowModal(false)}
                        width="100px"
                        className="add-button"
                        // padding="0"
                        // id="button.add2"
                      >
                        <Typography id="button.save" color="white" superbold fontSize="text-base">
                          Save
                        </Typography>
                      </Button>
                    </Right>
                  </div>
                </ModalCotainer>
              </Modal>
              <AddModal
                isOpen={showAddModal}
                setShowAddModal={setShowAddModal}
                arrayHelpers={arrayHelpers}
                locale={locale}
              />
            </React.Fragment>
          )}
        </FieldArray>
      </Form>
    </Container>
  )
}

const mapState = (state) => {
  return {
    locale: state.Language.locale,
  }
}

export default connect(mapState)(InstructorFooter)

const Card = styled.div`
  display: flex;
  overflow: hidden;
  min-height: 150px;
  min-width: 150px;
  flex-direction: column;
  /* border: 2px solid ${({ id }) => (id ? '#5A69FE' : '#f4f2f3')}; */
  border: 1px solid ${(props) => props.theme.primary1};
  border-radius: 8px;
  padding: 8px 8px 8px 8px;
  background: ${({ background }) => (background ? 'rgba(37, 62, 135, 0.05)' : 'white')};
  justify-content: ${({ background }) => (background ? 'center' : '')};
  align-items: ${({ background }) => (background ? 'center' : '')};
  /* position: relative;
@media (max-width: 426px) {
  :first-child {
    margin: 0px 0px 0 0;
  }
  width: 100%;
  margin: 8px 0px;
  justify-content: space-between;
} */
  /* ::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  bottom: 0;
  left: 0;
  border-left: 10px solid ${({ id }) => (id ? '#5A69FE' : '#f4f2f3')};
} */

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    @media (max-width: 320px) {
      /* width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    }
  }

  .avatar {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
const Image = styled.img`
  /* filter: grayscale(1) opacity(0.7); */
  /* transform: rotate(90deg); */
  /* margin-left: 10px; */
  /* padding: 10px; */
  border-radius: 20px;
  width: ${({ width }) => (width ? width : '16px')};
  height: ${({ height }) => (height ? height : '16px')};
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;

  :hover {
    border: 0px solid transparent;
    background-color: ${(props) => props.theme.primary5};
  }
`

const CustomCard = ({
  profileImage,
  name,
  index,
  arrayHelpers,
  handleOpenModal,
  setSeletedCard,
  id,
  description,
}) => {
  const [showPopup, setShowPopup] = useState(false)
  const displayWidth = 65
  const imageWidth = displayWidth * 2
  const handleEdit = () => {
    setSeletedCard({
      profileImage,
      name,
      index,
    })
    handleOpenModal()
    setShowPopup(false)
  }

  const handleDelete = () => {
    setShowPopup(false)
    arrayHelpers.remove(index)
  }
  const ConvertNameMobile = (values) => {
    if (values?.length > 19) {
      return values.slice(0, 20) + '...'
    }
    return values
  }
  const popupMenu = [
    {
      menu: 'edit',
      onClick: handleEdit,
    },
    {
      menu: 'remove',
      onClick: handleDelete,
    },
  ]

  return (
    <Card id={id}>
      <Tooltip
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        placement="bottom"
        trigger="click"
        tooltipShown={showPopup}
        onVisibilityChange={setShowPopup}
        tooltip={<Popup list={popupMenu} />}
      >
        <Image src={MoreIcon} alt="more-icon" />
      </Tooltip>
      <Wrapper>
        <Avatar
          width={displayWidth}
          // height={65}
          containerStyle={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
          clasName="avatar"
          src={getImageUrl(
            profileImage,
            `https://via.placeholder.com/${imageWidth}`,
            imageWidth,
            imageWidth
          )}
        />
        <Typography color="primary1">{ConvertNameMobile(name)}</Typography>
        <Typography fontSize="text-xs" color="primary1">
          {ConvertNameMobile(description)}
        </Typography>
      </Wrapper>
    </Card>
  )
}

const AddModal = ({ isOpen, setShowAddModal, arrayHelpers, locale }) => {
  const [state, setState] = useState({
    profileImage: 'bfc636d2-68d8-449a-9595-dd0dfcfddd17.png',
    name: '',
    description: '',
  })
  const handleAddInstructor = () => {
    arrayHelpers.push(state)
    setShowAddModal(false)
    setState({
      profileImage: '',
      name: '',
      description: '',
    })
  }

  const styleImageBox = {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
  }

  const styleUploadImage = {
    margin: '0px',
  }
  const translate = {
    name: locale === 'th' ? 'ชื่อ' : 'name',
    description: locale === 'th' ? 'คำอธิบาย' : 'description',
  }
  return (
    <Modal
      isOpen={isOpen}
      shownXclose
      contentStyle={{ width: '85%', maxWidth: '768px', padding: 0, border: 'none' }}
      onClickClose={() => {
        setShowAddModal(false)
      }}
      onRequestClose={() => {
        setShowAddModal(false)
      }}
      onAfterClose={() => {
        setState({
          profileImage: 'bfc636d2-68d8-449a-9595-dd0dfcfddd17.png',
          name: '',
          description: '',
        })
      }}
    >
      <ModalCotainer>
        <Typography
          id="course.instructor.add-modal.title"
          fontSize="text-xl"
          superbold
          color="primary1"
          margin="0 0 32px 0"
        >
          Add instructor detail
        </Typography>
        <Formik
          initialValues={{ name: '' }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
          })}
          onSubmit={handleAddInstructor}
        >
          {(props) => {
            const { errors, touched, isSubmitting, handleChange, handleSubmit } = props
            return (
              <div style={{ display: 'flex' }}>
                <div>
                  <UploadAvatar
                    name="logo"
                    grayBorder
                    detail=""
                    value={getImageUrl(state.profileImage)}
                    styleImageBox={styleImageBox}
                    styleUploadImage={styleUploadImage}
                    afterUpload={({ name: profileImage }) => setState({ ...state, profileImage })}
                  />
                </div>
                <Right>
                  <Label id="label.name" htmlFor="Name" fontSize="text-sm" color="primary1">
                    Name
                  </Label>
                  <Input
                    name="name"
                    value={state.name}
                    placeholder={translate.name}
                    onChange={(e) => {
                      handleChange(e)
                      setState({ ...state, name: e.target.value })
                    }}
                    status={touched.name && errors.name && 'error'}
                    messageStatus={errors.name}
                    Border={({ theme }) => `1px solid ${theme.black20}`}
                  />
                  <br />
                  <Label id="label.desc" htmlFor="firstname" fontSize="text-sm" color="primary1">
                    Description
                  </Label>
                  <Textarea
                    name="description"
                    placeholder={translate.description}
                    value={state.description}
                    onChange={(e) => setState({ ...state, description: e.target.value })}
                    Border={({ theme }) => `1px solid ${theme.black20}`}
                  />
                  <Button
                    id="button.add"
                    className="add-button"
                    primary
                    type="submit"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    width="120px"
                  >
                    Add
                  </Button>
                </Right>
              </div>
            )
          }}
        </Formik>
      </ModalCotainer>
    </Modal>
  )
}
