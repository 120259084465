import styled from 'styled-components'
import background from '../../images/background.jpg'
// export const Logo = styled.img.attrs({
//   src: logo,
//   alt: 'logo',
// })`
//   position: absolute;
//   bottom: 10px;
//   left: 10px;
//   width: 158px;
//   height: 62px;
// `
export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: none;
`

export const Content = styled.div`
  /* background-color: #fafafa; */
  //position: absolute;
  // left: 200px;
  //left: 0;
  //right: 0;
  //top: 0;
  //bottom: 0;
  //flex: 1;
  display: flex;
  flex-direction: column;

  margin-left: 200;
  // & > :last-child {
  //   margin-top: auto;
  // }
  @media (max-width: 768px) {
    left: 0;
  }
`

export const Hero = styled.div`
  background-image: linear-gradient(to top, #12348dd9, #598cffd9), url(${background});
  background-size: cover;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
`
export const FormContainer = styled.div`
  border-radius: 8px;
  position: absolute;
  min-width: 400px;
  width: 50%;
  z-index: 2;
  right: 5%;
  background-color: white;
  height: 120%;
  top: -10%;
`
export const UnauthorizedContainer = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.44), #000000);
  position: absolute;
  padding: 120px 150px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Image = styled.div`
  background-image: ${(props) => `url(${props.src})`};
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  /* @media (min-width: 425) { */
  height: 135px;
  /* } */
  @media (min-width: 540px) {
    height: 200px;
  }
  @media (min-width: 768px) {
    height: 300px;
  }
  @media (min-width: 1024px) {
    height: 200px;
  }
  @media (max-width: 280px) {
    height: 120px;
  }
`
