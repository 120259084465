import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import { Row, Col, Empty, Typography, Card } from '../index'
import ContenLoader from './ContenLoader'
import Loader from '../../components/loader'
import EmptyFolder from '../../images/EmptyFolder.svg'

const EmptyCard = styled.div`
  background-color: white;
  border-radius: 8px;
`
const ListView = ({
  id,
  data,
  dataLength,
  renderItem,
  emptyTitle,
  emptyDesc,
  fetchMoreData,
  skip,
  EmptyButton,
  addCard,
  addCardVisible = true,
  ...props
}) => {
  const [hasMore, setHasMore] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [loading, setLoading] = useState(false)

  const [newSkip, setNewSkip] = useState(skip)
  const history = useHistory()
  const { location } = history

  useEffect(() => {
    setLoading(true)
    setHasMore(true)
    setNewSkip(skip)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setLoadMore(false)
    setLoading(false)
  }, [data])

  const next = async () => {
    if (data.length >= dataLength) {
      setHasMore(false)
      return
    }

    setTimeout(async () => {
      if (!loadMore) {
        await setLoadMore(true)
        await fetchMoreData(0, skip + newSkip)
        await setNewSkip(skip + newSkip)
      }
    }, 500)
  }

  const renderEndMessage = () => {
    // return (
    //   <div style={{ textAlign: 'center', margin: 20 }}>
    //     <Typography id="label.end.oflist" color="gray6">
    //       You’ve reached the end of the list
    //     </Typography>
    //   </div>
    // )
    return (
      <div style={{ textAlign: 'center', margin: 40 }}>
        <Typography color="primaryFont" strong>
          Powered by Classwin Co.,Ltd.
        </Typography>
      </div>
    )
  }

  if (!data || loading) {
    return <ContenLoader />
  }

  if (_.isEmpty(data)) {
    return (
      <Card>
        <EmptyCard>
          <Empty
            Custom2
            noMargin
            small
            id={id}
            title={emptyTitle}
            desc={emptyDesc}
            btn={EmptyButton}
            image={EmptyFolder}
            style={{ margin: 0 }}
          />
        </EmptyCard>
      </Card>
    )
  }

  return (
    <div>
      <InfiniteScroll
        style={{ overflow: 'hidden' }}
        dataLength={data.length}
        next={next}
        hasMore={hasMore}
        loader={<Loader />}
      >
        <Row gutter={[32]}>
          {addCard && addCardVisible ? <Col {...props}>{addCard()}</Col> : <div />}
          {data.map((item, index) => {
            return (
              <Col key={index.toString()} {...props} Padding="8px 16px">
                {renderItem(item)}
              </Col>
            )
          })}
        </Row>
        {!hasMore && renderEndMessage()}
      </InfiniteScroll>
    </div>
  )
}

ListView.defaultProps = {
  emptyTitle: 'No course found',
  emptyDesc: 'There is no course found. Please check your keyword and search again.',
  skip: 20,
}

export default ListView
