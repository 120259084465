/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Field, Form, Formik } from 'formik'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import DotIcon from 'images/icons/DotsSixVertical.svg'
import { CSSTransition } from 'react-transition-group'
import { createCurriculumData2 } from '../../../../static'
import { Desktop, Mobile } from '../../../../utils/displayResponsive'
import { Container, Input, Typography, Button, Alert, InputWithLabel } from '../../../../components'
import ButtonDialog from './buttonDialog'
import ConfirmModal from '../../../../components/confirmModal/loadable'
import ErrorBoundary from '../../../../components/errorBoundary'
import DeleteIcon from 'images/icons/TrashColor.svg'
import AddIcon from 'images/icons/primary-plus.svg'
import SectionIcon from 'images/icons/Section.svg'
import closeIcon from 'images/icons/Gray-X.svg'
import { InputIcon, Icon, ContentBody, IconArrow } from './style'
import {
  SectionWrapper,
  ButtonDialogWrapper,
  HoverCircleButton,
  IconSection,
  SeperateLine,
  Title,
} from '../../style'
import PureIcon from 'components/icon'
import ArrowIcon from 'images/icons/CaretDown.svg'
// import { Card, ButtonDialog, Alert, Empty } from '../../components'

const Card = styled.div`
  display: flex;
  background-color: transparent;
  //background-color: yellow;
  position: relative;
  /* padding-bottom: 10px;
  margin-bottom: 10px; */
`
const DragHandle = styled.div`
  align-self: center;
`
const Content = styled.section`
  i.eva-hover {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
`
// const StyledIoMdMenu = styled(DotsSixVertical)`
//   font-size: ${({ theme }) => theme['text-sm']};
//   fill: #cdcdcd;
//   display: flex;
// `

class Section extends Component {
  state = {
    showRemove: false,
    showButtonAdd: false,
    showConfirm: false,
    isLoadding: false,

    isAlert: false,
    isError: false,
    titleAlert: '',
  }

  debounceInput = debounce(async (name) => {
    const { updateLecture, priority, subjectId, id: lectureId, handleAutoSave, locale } = this.props
    try {
      const payload = {
        subjectId,
        lectureId,
        priority,
        name,
      }
      await updateLecture(payload)
      handleAutoSave(true)
      this.setState({
        isAlert: true,
        titleAlert: locale === 'en' ? 'Updated Successful' : 'บันทึกเรียบร้อย',
      })
    } catch (error) {
      handleAutoSave(false)
      throw error
    }
  }, 1000)

  inputChange = (e) => {
    let { value: name } = e.target
    if (!name) name = 'Untitled'
    this.props.handleAutoSave(false)
    this.debounceInput(name)
  }

  handleMourseHoverOn = () => {
    this.setState({ showRemove: true })
  }

  handleMourseHoverOff = () => {
    this.setState({ showRemove: false })
  }

  handleCloseModal = () => {
    this.setState({ showConfirm: false })
  }

  handleRemove = async () => {
    const { removeLectureById, subjectId, id: lectureId, onChange } = this.props
    this.setState({ isLoadding: true })
    const payload = {
      subjectId,
      lectureId,
    }
    try {
      await removeLectureById(payload)
    } catch (error) {
      throw error
    } finally {
      onChange()
      this.setState({ showConfirm: false })
      this.setState({ isLoadding: false })
    }
  }
  onCloseAlert = () => {
    this.setState({ isAlert: false, text: '' })
  }
  render() {
    const { showRemove, showButtonAdd, showConfirm, isLoadding, isAlert, isError, titleAlert } =
      this.state
    const {
      name,
      dragHandle,
      isDragging,
      addNewContent,
      priority,
      onChangeTitleName,
      locale,
      isSelected,
      id: lectureId,

      match: { path },
      handleSelected,
      selectedId,
    } = this.props

    const isCourseSetting = /\/schools\/:schoolId\/curriculum\/manage.*/.test(path)
    return (
      <ErrorBoundary>
        <Desktop>
          <Formik
            initialValues={{
              name,
            }}
            enableReinitialize
            onSubmit={async (values) => {
              this.debounceInput(values?.name)
            }}
          >
            {() => (
              <Form>
                <Card
                  onMouseLeave={this.handleMourseHoverOff}
                  onClick={() => {
                    this.setState({ showButtonAdd: !showButtonAdd })
                    handleSelected(lectureId)
                  }}
                  isSelected={isSelected(lectureId)}
                >
                  <Title width="min-content">
                    <PureIcon src={SectionIcon} />
                    <SeperateLine />
                  </Title>

                  <InputIcon onMouseEnter={this.handleMourseHoverOn}>
                    <Field name="name">
                      {({ field, form }) => {
                        return (
                          <>
                            <Input
                              style={{
                                width: '100%',
                                backgroundColor: 'transparent',
                              }}
                              id="name"
                              name="name"
                              value={field.value}
                              //onChange={(e) => {}}
                              type="text"
                              placeholder="Section"
                              fontSize="text-lg"
                              fontWeight="bold"
                              lineHeight={2}
                              padding="0px"
                              autoFocus
                              noBorder
                              color="primary1"
                            />
                          </>
                        )
                      }}
                    </Field>
                  </InputIcon>
                  <IconSection>
                    {showRemove && (
                      <IconSection>
                        {/* <HoverCircleButton
                                type="button"
                                //onClick={this.handleShowButtonAdd(!showButtonAdd)}
                                isActive={showButtonAdd}
                                isDragging={isDragging}
                              >
                                <img src={AddIcon} alt="add-icon" />
                              </HoverCircleButton> */}
                        <Icon onClick={() => this.setState({ showConfirm: true })}>
                          <img src={DeleteIcon} alt="remove-icon" width="23px" height="23px" />
                        </Icon>
                      </IconSection>
                    )}
                    <DragHandle {...dragHandle}>
                      <img src={DotIcon} alt="drag-handle-icon" />
                    </DragHandle>
                  </IconSection>

                  {/* <Typography color="danger">{form.errors[field.name]}</Typography> */}
                </Card>
                {/* <CSSTransition
                  in={isSelected(lectureId)}
                  // appear={isSelected(lectureId)}
                  timeout={10}
                  classNames="section-body"
                  unmountOnExit
                  onEnter={() => console.log('onEnter 🌅')}
                  onExited={() => console.log('onExited 🌅')}
                >
                  <ContentBody
                    // {...dragHandle}
                    isSelected={isSelected(lectureId)}
                    isCourseSetting={isCourseSetting}
                    isDragging={isDragging}
                    isDragDisabled
                    column="1"
                    gap="15px"
                    padding="40px 50px 20px 25px"
                  >
                    <Card
                      padding="40px 32px 40px 32px"
                      style={{
                        border: '1px solid #253e87',
                        borderRadius: '4px',
                        marginBottom: '40px',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '40px 32px 40px 32px',
                        backgroundColor: 'white',
                      }}
                    >
                      <PureIcon
                        onClick={() => handleSelected('')}
                        style={{
                          width: 25,
                          height: 25,
                          position: 'absolute',
                          right: 15,
                          top: 20,
                        }}
                        src={closeIcon}
                        alt="arrow-icon"
                      />
                      <Field name="name">
                        {({ field, form }) => {
                          return (
                            <>
                              {' '}
                              <Container column="1">
                                <Content
                                  style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    gap: 8,
                                    marginBottom: 28,
                                  }}
                                >
                                  <IconArrow
                                    // onClick={this.handleExpandCard}
                                    onClick={() => handleSelected('')}
                                    src={ArrowIcon}
                                    rotate="180deg"
                                    alt="arrow-icon"
                                  />

                                  <Typography
                                    style={{ cursor: 'pointer' }}
                                    onClick={this.handleExpandCard}
                                    h4
                                    bold
                                    width="100%"
                                  >
                                    {locale === 'en' ? 'Section' : 'บทเรียน'}
                                  </Typography>
                                </Content>
                              </Container>
                              <InputWithLabel
                                translateId="label.title"
                                id="name"
                                name="name"
                                label="Title"
                                type="text"
                                htmlFor="name"
                                placeholder="Section"
                                value={field.value}
                                autoFocus
                                onChange={(e) => {
                                  form.handleChange(e)
                                  //this.inputChange(e)
                                  //this.setState({ value: e.target.value })
                                  //onChangeTitleName(e.target.value, priority)
                                }}
                                // color="primary1"
                                bold={false}
                              />
                              {form.errors && (
                                <Typography color="danger">{form.errors[field.name]}</Typography>
                              )}
                            </>
                          )
                        }}
                      </Field>
                      

                      <hr
                        style={{
                          color: '#cbd5e1',
                          backgroundColor: '#cbd5e1',
                          marginTop: '24px',
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '12px',
                        }}
                      >
                        <Button id="button.save" type="submit" primary width={171} center>
                          Save
                        </Button>
                      </div>
                      <Alert
                        isOpen={isAlert}
                        error={isError}
                        onRequestClose={this.onCloseAlert}
                        title={titleAlert}
                      />
                    </Card>
                  </ContentBody>
                </CSSTransition> */}
                {selectedId === lectureId && (
                  <SectionWrapper>
                    <img src={AddIcon} alt="add-icon" />
                    <ButtonDialogWrapper>
                      <ButtonDialog
                        onClick={addNewContent}
                        data={createCurriculumData2}
                        priority={priority}
                      >
                        <img src={AddIcon} alt="add-icon" />
                      </ButtonDialog>
                    </ButtonDialogWrapper>
                  </SectionWrapper>
                )}
              </Form>
            )}
          </Formik>
          {/* <Wrapper
              onMouseOver={this.handleShowButtonAdd(true)}
              onFocus={this.handleShowButtonAdd(true)}
              onBlur={this.handleShowButtonAdd(false)}
            /> */}
        </Desktop>
        <Mobile>section mobile</Mobile>
        <Alert
          isOpen={isLoadding}
          onRequestClose={() => this.setState({ isLoadding: false })}
          title={locale === 'en' ? 'Loading' : 'กำลังโหลด'}
          loading
        />
        <ConfirmModal
          isOpen={showConfirm}
          onRequestClose={this.handleCloseModal}
          title={
            locale === 'en' ? 'Are you sure you want to delete?' : 'คุณแน่ใจหรือไม่ว่าต้องการลบ?'
          }
          // subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry…."
          btn1={
            <Button id="button.confirmModal" onClick={this.handleRemove} primary>
              Submit
            </Button>
          }
          btn2={
            <Button id="button.cancel" secondary onClick={this.handleCloseModal}>
              Cancel
            </Button>
          }
        />
      </ErrorBoundary>
    )
  }
}

Section.propTypes = {
  name: PropTypes.string.isRequired,
}

Section.defaultProps = {}

const mapState = ({ Language: { locale } }) => ({
  locale,
})

const mapDispatch = ({ Subject: { updateLecture, removeLectureById, fetchLectureInfo } }) => ({
  updateLecture,
  removeLectureById,
  fetchLectureInfo,
})

export default connect(mapState, mapDispatch)(withRouter(Section))
