import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ErrorBoundary from '../../components/errorBoundary'
import Typography from '../../components/typography'

// import logo from '../../images/logo-classwin-158-px.svg'

const Container = styled.div`
  min-width: 268px;
  height: 96px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  ul {
    list-style: none;
    /* margin-left: 50px;
    margin-top: 40px; */
    a {
      height: 48px;
      display: flex;
      font-size: ${({ theme }) => theme['text-sm']};
      justify-content: flex-start;
      align-items: center;
      /* padding-left: 24px; */
      border-left: 6px solid transparent;
      color: ${(props) => props.theme.primary1};
      text-decoration: none;
      transition: all 0.3s;
      opacity: 0.9;

      &.active {
        /* padding-left: 24px; */
        font-weight: bold;
        /* border-left: 6px solid ${(props) => props.theme.primary2}; */
        background-color: ${(props) => props.theme.primary12};
        p {
          color: ${(props) => props.theme.primary1};
          font-weight: 700;
        }
      }
      &:hover {
        opacity: 1;
        color: ${(props) => props.theme.primary1};
      }
    }
  }
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin: 0px 10px;
`

class SideBar extends Component {
  static propTypes = {
    menus: PropTypes.array.isRequired,
  }

  render() {
    const { menus, toggle, location } = this.props
    const locationPath = location.pathname.split('/').slice(-1)[0]

    return (
      <ErrorBoundary>
        <Container>
          <ul>
            {menus.map((menu) => {
              return (
                <li key={menu.label}>
                  <NavLink
                    onClick={() => {
                      toggle(false)
                    }}
                    to={menu.link}
                  >
                    <Icon
                      src={locationPath === menu.check ? menu.iconActive : menu.icon}
                      alt="createCourse"
                    />

                    <Typography id={`profile.${menu.label.toLowerCase()}.title`}>
                      {menu.label}
                    </Typography>
                  </NavLink>
                </li>
              )
            })}
          </ul>
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  const { Sidebar } = dispatch

  return {
    toggle: Sidebar.toggle,
  }
}

export default connect(mapState, mapDispatch)(withRouter(SideBar))
