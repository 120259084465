// import uniqBy from 'lodash/uniqBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
// import _flatMap from 'lodash/flatMap'
import _orderBy from 'lodash/orderBy'

import userApi from '../../api/user'
import {
  FeedAPI,
  CourseAPI,
  UserAPI,
  AnswerAPI,
  AssignmentsAPI,
  AssignmentWorksheetAPI,
  StudentAssignmentAPI,
  PublishedWorksheetAPI,
  EnrollmentAPI,
} from '../../api'
import { getShowTest } from '../../utils/utils'

export const teachingCourse = {
  state: {
    lectures: [],
    lectureInfo: {},
    feeds: [], // discussion
    course: {}, //
    courseAssignments: [],
    assessmentList: [],
    students: [], // students in this course
    studentsId: [], // studentsId in this course
    studentListCount: 0,
    studentInfo: {},
    courseEvaluation: {},
    studentList: [],
    assignmentDetail: {},
    assignmentStatus: {},
    teachers: [],
    teachersId: [],
    teacherCourseError: false,
    assignmentAnswer: {},
    learnersProgress: {},
    invitationLink: {},
    enrollmentRequests: [],
    totalStudent: 0,
  },
  reducers: {
    setState(state, { name, payload }) {
      return {
        ...state,
        [name]: payload,
      }
    },
    setMyCourses(state, payload) {
      return {
        ...state,
        myCourses: payload,
      }
    },
    setMyCourseCount(state, payload) {
      return {
        ...state,
        myCourseCount: payload,
      }
    },
    setDiscussion(state, payload) {
      return {
        ...state,
        feeds: payload,
      }
    },
    setAssessmentList(state, payload) {
      return {
        ...state,
        assessmentList: payload,
      }
    },
    setStudentList(state, payload) {
      return {
        ...state,
        studentList: payload,
      }
    },
    setStudentListMore(state, payload) {
      return {
        ...state,
        studentList: [...state.studentList, ...payload],
      }
    },
    setStudentListCount(state, payload) {
      return {
        ...state,
        studentListCount: payload,
      }
    },
    setCourse(state, payload) {
      return {
        ...state,
        course: payload,
        teachers: payload.teachers,
      }
    },
    setTeachersId(state, payload) {
      return {
        ...state,
        teachersId: payload,
      }
    },
    setCourseAssignments(state, payload) {
      return {
        ...state,
        courseAssignments: payload,
      }
    },
    setStudents(state, payload) {
      return {
        ...state,
        students: payload,
      }
    },
    setStudentInfo(state, payload) {
      return {
        ...state,
        studentInfo: payload,
      }
    },
    setCourseEvaluation(state, payload) {
      return {
        ...state,
        courseEvaluation: payload,
      }
    },
    setAssignmentDetail(state, payload) {
      return {
        ...state,
        assignmentDetail: payload,
      }
    },
    setAssignmentStatus(state, payload) {
      return {
        ...state,
        assignmentStatus: payload,
      }
    },
    setLectures(state, payload) {
      return {
        ...state,
        lectures: payload,
      }
    },
    setTeacherCourseError(state) {
      return {
        ...state,
        teacherCourseError: true,
      }
    },
    setAssignmentAnswer(state, payload) {
      return {
        ...state,
        assignmentAnswer: payload,
      }
    },
    setCourseLectureInfo(state, payload) {
      return {
        ...state,
        lectureInfo: payload,
      }
    },
    setLearnersProgress(state, payload) {
      return {
        ...state,
        learnersProgress: payload,
      }
    },
    setInvitationLink(state, payload) {
      return {
        ...state,
        invitationLink: payload,
      }
    },
    setTotalStudent(state, payload) {
      return {
        ...state,
        totalStudent: payload,
      }
    },
    setStudentId(state, payload) {
      return {
        ...state,
        studentsId: payload,
      }
    },
  },

  effects: (dispatch) => ({
    async getCourse(courseId) {
      try {
        const MyCoursefiltr = JSON.stringify({
          include: ['teachers', 'school', 'publishedSubject'],
        })
        const res = await CourseAPI.getCourse({ id: courseId, filter: MyCoursefiltr })
        // console.log('teachingCourse -- getCourse', res)

        this.setCourse(res)
        // this.setTeachersId(res)
      } catch (e) {
        this.setTeacherCourseError()
        throw e
      }
    },
    async getTeachersId(courseId) {
      try {
        const MyCoursefiltr = JSON.stringify({
          include: ['teachers', 'school', 'publishedSubject'],
        })
        const { teachers } = await CourseAPI.getCourse({ id: courseId, filter: MyCoursefiltr })
        // console.log('teachingCourse -- getCourse', res)
        const teachersId = teachers?.map((item) => item.id)

        this.setTeachersId(teachersId)
      } catch (e) {
        this.setTeacherCourseError()
        throw e
      }
    },

    async getCourseAssignments(courseId) {
      const CourseAssignmentFilter = JSON.stringify({
        include: { assignmentWorksheets: 'publishedWorksheet' },
      })
      // { assignmentWorksheets: 'publishedWorksheet' },
      const res = await CourseAPI.getCourseAssignments({
        id: courseId,
        filter: CourseAssignmentFilter,
      })
      this.setCourseAssignments(res)
    },
    async fetchMyCourses(additionalFilter) {
      const MyCoursefiltr = JSON.stringify({
        include: ['teachers', 'subCategory'],
        order: 'createdAt DESC',
        ...additionalFilter,
      })
      const res = await userApi.getTeachingCourses({ filter: MyCoursefiltr })
      this.setMyCourses(res)
      return res
    },
    async fetchMyCourseCount(schoolId) {
      const where = JSON.stringify(schoolId)
      const res = await userApi.getCountTeachingCourses({ where })
      this.setMyCourseCount(res)
      return res
    },
    async getDiscussion(courseId) {
      const discussFilter = JSON.stringify({
        where: { courseId },
        include: ['appUser'],
      })
      const data = await FeedAPI.find({ filter: discussFilter })

      this.setDiscussion(data)
    },
    async postDiscussion(obj) {
      await FeedAPI.create(obj)
      this.getDiscussion(obj.courseId)
    },
    async getAssessmentList(courseId) {
      const assessmentFilter = JSON.stringify({
        include: 'publishedSubject',
      })
      const result = await CourseAPI.findById({ id: courseId, filter: assessmentFilter })
      const ofSubjects = result.publishedSubject.data.subjectWorksheets.map((item) => ({
        ...item.publishedWorksheet.data,
        publishedWorksheetId: item.publishedWorksheet.id,
      }))
      const assessmentList = [...ofSubjects]
      this.setAssessmentList(assessmentList)
      return result
    },
    async createAssignment({ assessmentId, selectedStudents, ...data }) {
      const publishedWorksheet = await PublishedWorksheetAPI.create({
        version: '1.0.0',
        description: 'create assignment',
        worksheetId: assessmentId,
      })
      const result = await AssignmentsAPI.create(data)
      await AssignmentWorksheetAPI.create({
        assignmentId: result.id,
        publishedWorksheetId: publishedWorksheet.id,
      })
      if (data.isIndividaul) {
        await Promise.all(
          selectedStudents.map((item) =>
            AssignmentsAPI.addStudent({
              id: result.id,
              courseId: data.courseId,
              studentId: item,
            })
          )
        )
      }
      return result
    },
    async updateAssignment({ selectedStudents, deletingStudents, ...data }) {
      try {
        const result = await AssignmentsAPI.update(data)
        await this.getAssignmentDetail({ courseId: data.courseId, assignmentId: data.id })
        if (data.isIndividaul) {
          await Promise.all(
            selectedStudents.map((item) =>
              StudentAssignmentAPI.upsertWithWhere({
                where: JSON.stringify({
                  courseId: data.courseId,
                  studentId: item,
                  assignmentId: data.id,
                }),
                courseId: data.courseId,
                studentId: item,
                assignmentId: data.id,
              })
            )
          )
          await Promise.all(
            deletingStudents.map((item) =>
              AssignmentsAPI.deleteStudent({
                id: data.id,
                fk: item,
              })
            )
          )
        }
        dispatch.Alert.success({ title: 'Assignment Updated Successfully' })
        return result
      } catch (e) {
        dispatch.Alert.error({ title: 'Assignment Update Failed' })
        throw e
      }
    },
    async getStudentList({ courseId, limit = 0, skip = 0 }) {
      const getFilter = JSON.stringify({
        include: {
          relation: 'enrollments',
          scope: { where: { isApproved: true, courseId } },
        },
        limit,
        skip,
      })
      const result = await CourseAPI.getStudentsInCourse({
        id: courseId,
        filter: getFilter,
      })
      this.setStudentList(result)

      return result
    },
    async getStudentListCount({ courseId }) {
      const getFilter = JSON.stringify({
        include: {
          relation: 'enrollments',
          scope: { where: { isApproved: true, courseId } },
        },
      })
      const result = await CourseAPI.getStudentsInCourse({
        id: courseId,
        filter: getFilter,
      })
      this.setStudentListCount(result.length)

      return result
    },
    async getStudentListMore({ courseId, limit = 0, skip = 0 }) {
      const getFilter = JSON.stringify({
        include: {
          relation: 'enrollments',
          scope: { where: { isApproved: true, courseId } },
        },
        limit,
        skip,
      })
      const result = await CourseAPI.getStudentsInCourse({
        id: courseId,
        filter: getFilter,
      })

      this.setStudentListMore(result)

      return result
    },
    async getStudentReport({ courseId, limit, skip }) {
      const res = await CourseAPI.getStudentReport({ id: courseId, limit, skip })
      // console.log('getStudentInCourse - teachingCoursing', res.students)
      this.setStudents(res.students)
      this.setTotalStudent(res.count)
    },
    async getStudentReportForId({ courseId }) {
      const res = await CourseAPI.getStudentReport({ id: courseId })

      const studentsId = res?.students?.map((item) => item.student.id)
      this.setStudentId(studentsId)
    },
    async getStudentReportById({ courseId, studentId }) {
      const res = await CourseAPI.getStudentReport({ id: courseId, studentId })
      // this.setStudents(res.students)
      // console.log('getStudentReportById', res)
      this.setStudents(res.students)
      this.setCourse(res.course)
    },
    async getStudentInfo(id) {
      const res = await UserAPI.getAppUser({ id })
      // console.log('👨‍🏫 getStudentInfo', res)
      this.setStudentInfo(res)
    },
    async getCourseEvaluation(id) {
      try {
        const res = await CourseAPI.getCourseEvaluation({ id })
        this.setCourseEvaluation(res)
      } catch (e) {
        this.setCourseEvaluation({})
        throw e
      }
    },
    async putCourseEvaluation({ courseId, id, ...data }) {
      try {
        let res
        if (id) {
          res = await CourseAPI.putCourseEvaluation({
            courseId,
            id,
            ...data,
          })
        } else {
          res = await CourseAPI.createCourseEvaluation({
            courseId,
            ...data,
          })
        }
        dispatch.Alert.success({ title: 'Evaluation Saved Successfully' })
        this.getCourseEvaluation(courseId)
        return res
      } catch (e) {
        dispatch.Alert.error({ title: 'Failed to save' })
        throw e
      }
    },
    async getAssignmentDetail({ courseId, assignmentId }) {
      const assignmentFilter = JSON.stringify({
        include: [
          { assignmentWorksheets: 'publishedWorksheet' },
          // {'assignmentWorksheets': 'assignment'},
          'studentAssignments',
          'course',
        ],
        where: {
          id: assignmentId,
        },
      })
      const result = await CourseAPI.getCourseAssignments({
        id: courseId,
        filter: assignmentFilter,
      })
      this.setAssignmentDetail(result[0])
      return result
    },
    async getAssignmentOverview({ assignmentId }) {
      const result = await AssignmentsAPI.getStatus({
        id: assignmentId,
      })
      await this.setAssignmentStatus(result)
      return result
    },
    async getLecturesByCourseId(courseId) {
      const {
        data: { lectures },
      } = await CourseAPI.getLecturesByCourseId({ id: courseId })
      // console.log('teachingCourse - getLecturesByCourseId', lectures)
      this.setLectures(lectures)
    },
    async getAssignmentStudentAnswer({ assignmentId, studentId }) {
      const assignmentFilter = JSON.stringify({
        include: [
          {
            relation: 'answers',
            scope: {
              order: 'attemp DESC',
              limit: 1,
              where: {
                appUserId: studentId,
              },
              include: 'appUser',
            },
          },
          {
            assignmentWorksheets: 'publishedWorksheet',
          },
          'course',
        ],
      })
      const result = await AssignmentsAPI.findById({
        id: assignmentId,
        filter: assignmentFilter,
      })
      this.setAssignmentAnswer(result)
      return result
    },
    // async createCourseLecture() {
    async createCourseLecture(payload, state) {
      // TODO : wait for courseAPI
      const result = await CourseAPI.createLecture(payload)
      if (state.Assessment.lectureTest.pretest) {
        dispatch.Assessment.unsetLectureTest('pretest')
      }
      if (state.Assessment.lectureTest.posttest) {
        dispatch.Assessment.unsetLectureTest('posttest')
      }
      return result
    },
    async getCourseLectureByLectureId({ courseId, lectureId }) {
      try {
        const res = await CourseAPI.getCourseLectureByLectureId({
          id: courseId,
          fk: lectureId,
        })
        this.setCourseLectureInfo(res)
      } catch (e) {
        throw e
      }
    },
    async updateCourseLecture(payload, state) {
      const courseId = state.teachingCourse.course.id
      const lectureId = state.teachingCourse.lectureInfo.id
      try {
        await CourseAPI.updateCourseLectureById({
          id: courseId,
          fk: lectureId,
          ...payload,
        })
        dispatch.Alert.success({ title: 'Lecture Update Successfully' })
      } catch (e) {
        dispatch.Alert.error({ title: 'Lecture Update Failed' })
        throw e
      }
    },
    async updateCourseLectureContent(payload, state) {
      const courseId = state.teachingCourse.course.id
      const lectureId = state.teachingCourse.lectureInfo.id
      try {
        await CourseAPI.updateCourseLectureContent({
          id: courseId,
          fk: lectureId,
          contents: payload.map((item) => {
            const data = { ...item }
            delete data.isActiveContent
            delete data.isActivePosttest
            delete data.isActivePretest
            return data
          }),
        })
        dispatch.Alert.success({ title: 'Lecture Updated Successfully' })
      } catch (e) {
        dispatch.Alert.error({ title: 'Lecture Update Failed' })
        throw e
      }
    },

    async updateAnswerScore(payload) {
      if (payload.correctScore >= payload.value) {
        await AnswerAPI.updateAnswerScoreById({
          id: payload.id,
          fk: payload.questionId,
          score: payload.value,
        })
      } else {
        dispatch.Alert.error({
          title: 'The score must be less than or equal to the total score',
        })
      }
    },
    async updateAnswerGrade(payload) {
      try {
        await AnswerAPI.updateAnswerGradeById({
          id: payload.id,
        })
        dispatch.Alert.success({ title: 'Grade Updated Successfully' })
      } catch (e) {
        dispatch.Alert.error({ title: 'Grade Update Failed' })
        throw e
      }
    },
    async removeLectureById({ courseId, lectureId }) {
      await CourseAPI.removeCoureLectureById({ id: courseId, fk: lectureId })
      this.getLecturesByCourseId(courseId)
    },
    async getLearnersProgress({ courseId, ...query }) {
      try {
        const res = await CourseAPI.getLearnersProgress({ id: courseId, ...query })
        this.setLearnersProgress(res)
        return res
      } catch (error) {
        throw error
      }
    },
    async getInvitationLink(courseId) {
      const link = await CourseAPI.getInvitationLink({ id: courseId })
      this.setInvitationLink(link)
    },
    async updateInvitationLink({ courseId, lifeTime }) {
      await CourseAPI.updateInvitationLink({ id: courseId, lifeTime })
    },
    async editCourse(payload) {
      await CourseAPI.editCourse(payload)
    },
    async getCourseStudentEnroll({ courseId }) {
      const enrollFilter = JSON.stringify({
        fields: ['id', 'enrollments'],
        include: {
          relation: 'enrollments',
          scope: {
            where: { isApproved: false },
            include: {
              relation: 'appUser',
              scope: { fields: ['firstname', 'lastname', 'profileImage', 'id', 'email'] },
            },
          },
        },
      })
      const { enrollments } = await CourseAPI.findById({
        id: courseId,
        filter: enrollFilter,
      })
      this.setState({ name: 'enrollmentRequests', payload: enrollments })
    },
    async approveEnrollment({ enrollmentId }) {
      try {
        await EnrollmentAPI.approve({ id: enrollmentId })
      } catch (e) {
        throw e
      }
    },
    async deleteEnrollment({ enrollmentId }) {
      try {
        await EnrollmentAPI.delete({ id: enrollmentId })
      } catch (e) {
        throw e
      }
    },
    async addCourseMeeting({ courseId, meetingNumber }) {
      try {
        const result = await CourseAPI.addCourseMeeting({
          id: courseId,
          meetingId: meetingNumber,
        })
        // eslint-disable-next-line no-console
        console.log('create new zoom meeting', result)
      } catch (e) {
        throw e
      }
    },
  }),
  selectors: () => ({
    lectures() {
      return (rootState) => {
        const lectures = get(rootState.Course.courseDetail, 'publishedSubject.data.lectures', [])
        // const subjectWorksheets = get(rootState.Course.courseDetail, 'publishedSubject.data.subjectWorksheets', [])
        // const merged = _flatMap(subjectWorksheets, ({ publishedWorksheet:{data}, ...rest}) =>
        // ({...data, ...rest})
        // );
        const merged = rootState.teachingCourse.courseAssignments
          .filter((item) => item.type !== 'practice')
          .map((item) => ({
            ...item,
            name: item.title,
          }))
        const pretest = merged.filter((item) => item.type === 'pretest')
        const posttest = merged.filter((item) => item.type === 'posttest')
        const newContent = _orderBy(
          [
            ...lectures,
            ...merged.filter((item) => item.type !== 'pretest' && item.type !== 'posttest'),
          ],
          ['priority'],
          ['asc']
        )
        // console.log('newContent', [...pretest, ...newContent, ...posttest])
        return [...pretest, ...newContent, ...posttest]
      }
    },
    content() {
      return (rootState, props) => {
        const lectures = get(rootState.teachingCourse.course, 'publishedSubject.data.lectures', [])
        const content = lectures.find((item) => item.id === props.lectureId)
        return content
      }
    },
    courseTest() {
      return (rootState, props) => {
        // const lectures = get(rootState.teachingCourse.course, 'publishedSubject.data.subjectWorksheets', [])
        const lectures = rootState.teachingCourse.courseAssignments || []
        const content = lectures.find((item) => item.id === props.lectureId)
        return content
      }
    },
    lectureStatus() {
      return (rootState, props) => {
        const lectures = get(rootState.teaching, 'lectures', [])
        const lecture = lectures.find((item) => item.content.lectureId === props.lectureId)
        return lecture || {}
      }
    },
    lectureSide() {
      return ({ teachingCourse: { lectures, courseAssignments } }) => {
        const reformatedCourseAssignments = courseAssignments
          .filter((item) => item.type !== 'practice')
          .map((item) => ({
            ...item,
            name: item.title,
          }))
        const quiz = reformatedCourseAssignments.filter((item) => item.type === 'quiz')
        const pretest = reformatedCourseAssignments.filter((item) => item.type === 'pretest')
        const posttest = reformatedCourseAssignments.filter((item) => item.type === 'posttest')
        const sortedList = sortBy([...lectures, ...quiz], 'priority')
        const newList = [...pretest, ...sortedList, ...posttest].reduce((arr, item) => {
          if (item.type === 'pretest' || item.type === 'posttest') {
            const lect = {
              id: item.id,
              title: item.name,
              description: item.description || '',
              isActiveContent: true,
              type: item.type,
            }
            const obj = {
              id: item.id,
              title: item.name,
              description: item.description || '',
              img: item.image || '',
              created: moment(item.createdAt).format('DD MMM YYYY'),
              updated: moment(item.updatedAt).format('DD MMM YYYY'),
              lectures: [lect],
              type: item.type,
            }
            return [...arr, obj]
          }
          if (item.type === 'paragraph') {
            const obj = {
              id: item.id,
              title: item.name,
              description: item.description || '',
              img: item.image || '',
              created: moment(item.createdAt).format('DD MMM YYYY'),
              updated: moment(item.updatedAt).format('DD MMM YYYY'),
              lectures: [],
              type: 'paragraph',
            }
            return [...arr, obj]
          }

          // item.type === '' // lecture
          const hasPretest = !!item.preTest
          const hasPosttest = !!item.postTest
          const obj = {
            type: item.type === '' ? 'lecture' : item.type,
            id: item.id,
            title: item.name,
            description: item.description || '',
            hasPretest,
            hasPosttest,
            // isActivePretest: hasPretest && !item.preTest.hasAnswer,
            isActivePretest: hasPretest,
            // isActivePosttest: hasPretest
            //   ? item.preTest.hasAnswer
            //   : hasPosttest && !item.postTest.hasAnswer,
            isActivePosttest: hasPosttest,
            // isActiveContent: hasPretest ? item.preTest.hasAnswer : true,
            isActiveContent: true,
            preTest: get(item, 'preTest', {}),
            postTest: get(item, 'postTest', {}),
          }
          if (arr.length > 0) {
            const lastItem = arr.slice(-1)[0]
            const lecs = lastItem.lectures || []
            return [...arr.slice(0, -1), { ...lastItem, lectures: [...lecs, obj] }]
          }
          return []
        }, [])
        // console.log('👹👿🤢teachingCourse lectureSide', newList)
        return newList
      }
    },
    lectureList() {
      return ({ teachingCourse: { lectures } }) => {
        const sortedList = sortBy(lectures, 'priority')
        const newList = sortedList.reduce((arr, item) => {
          if (item.type === 'paragraph') {
            const obj = {
              id: item.id,
              name: item.name,
              description: item.description,
              img: item.image || '',
              created: moment(item.createdAt).format('DD MMM YYYY'),
              update: moment(item.updateAt).format('DD MMMM YYYY'),
              lectures: [],
            }
            return [...arr, obj]
          }

          const preTest = (item.lectureWorksheets || []).find((test) => test.type === 'pretest')
          const postTest = (item.lectureWorksheets || []).find((test) => test.type === 'posttest')

          const lecture = {
            id: item.id,
            name: item.name,
            description: item.description,
            content: item.content,
            hasPretest: !!preTest,
            hasPosttest: !!postTest,
            preTest,
            postTest,
            isActivePretest: true,
            isActivePosttest: true,
            isActiveContent: true,
            showTest: getShowTest(!!preTest, !!postTest),
          }

          if (arr.length > 0) {
            const lastItem = arr.slice(-1)[0]
            const lecs = lastItem.lectures || []
            return [...arr.slice(0, -1), { ...lastItem, lectures: [...lecs, lecture] }]
          }
          return []
        }, [])

        return newList
      }
    },
    lectureTest() {
      return ({ teachingCourse: { lectures } }, { testId, lectureId, rank }) => {
        const lecture = lectures.find((item) => item.id === lectureId)
        if (lecture) {
          const content = sortBy(lectures, 'priority').find(
            (item) => item.priority > lecture.priority && item.rank === +rank
          )
          const lectureWorksheet = get(content, 'lectureWorksheets', []).find(
            (test) => test.id === testId
          )
          return get(lectureWorksheet, 'publishedWorksheet.data', {})
        }
        return {}
      }
    },

    newAssignments() {
      return (rootState) => {
        const {
          courseEvaluation: { assignments },
          courseAssignments,
        } = rootState.teachingCourse

        const filteredCourseAssignment = filter(courseAssignments, (item) => {
          return item.type === 'practice'
        })

        const mergedList = map(filteredCourseAssignment, (item) => {
          return {
            ...item,
            ...find(assignments, (e) => {
              return e.assignmentId === item.id
            }),
          }
        })

        return mergedList
      }
    },
    newAssessments() {
      return (rootState) => {
        const {
          courseEvaluation: { preTests = [], postTests = [], quizzes = [] },
          courseAssignments,
        } = rootState.teachingCourse

        // ?
        const filteredArray = filter(courseAssignments, (item) => {
          return item.type === 'pretest' || item.type === 'posttest' || item.type === 'quiz'
        })
        const mergedPreTests = map(filteredArray, (item) => {
          return {
            ...find(preTests, (e) => {
              return item.id === e._id && item.type === 'pretest'
            }),
            ...item,
          }
        })
        const mergedQuizzes = map(mergedPreTests, (item) => {
          return {
            ...find(quizzes, (e) => {
              return item.id === e.id && item.type === 'quiz'
            }),
            ...item,
          }
        })
        const mergedPostTests = map(mergedQuizzes, (item) => {
          return {
            ...find(postTests, (e) => {
              return item.id === e._id && item.type === 'posttest'
            }),
            ...item,
          }
        })
        return mergedPostTests
      }
    },
    courseAssignmentList() {
      return (rootState) => {
        const { courseAssignments } = rootState.teachingCourse
        return courseAssignments
          .filter((item) => item.type === 'practice')
          .map((item) => {
            return {
              id: item.id,
              title: item.title,
              description: item.instruction,
              createdDate: moment(item.createdAt).format('DD . MM . YYYY'),
              deadline: item.dueDate ? moment(item.dueDate).format('DD . MM . YYYY') : '',
              correct: 0,
              incorrect: 0,
              courseId: item.courseId,
            }
          })
      }
    },
    assignmentOverview() {
      return (rootState) => {
        const { assignmentStatus } = rootState.teachingCourse
        const submitList = get(assignmentStatus, 'submitList', [])
        const notSubmitList = get(assignmentStatus, 'notSubmitList', [])
        return {
          totalStudent: submitList.length + notSubmitList.length,
          submitted: submitList.length,
          unsubmitted: notSubmitList.length,
          list: submitList.map((item) => {
            return {
              studentId: item.id,
              profileImage: item.profileImage,
              name: `${item.firstname} ${item.lastname}`,
              isGraded: item.answer.isGraded,
              score: item.answer.score,
              totalScore:
                item.answer.assignment.assignmentWorksheets[0].publishedWorksheet.data.totalScore,
              date: item.answer.createdAt
                ? moment(item.answer.createdAt).format('DD / MM / YYYY')
                : '',
              submitted: true,
            }
          }),
          notSubmitList: notSubmitList.map((item) => {
            return {
              studentId: item.id,
              profileImage: item.profileImage,
              name: `${item.firstname} ${item.lastname}`,
              // score: item.answer.score,
              // totalScore: item.answer.totalScore,
              // date: item.answer.createdAt
              //   ? moment(item.answer.createdAt).format('DD / MM / YYYY')
              //   : '',
              submitted: false,
            }
          }),
        }
      }
    },
    studentAnswer() {
      return (rootState) => {
        const { assignmentAnswer } = rootState.teachingCourse
        const student = get(assignmentAnswer, 'answers[0].appUser', {})
        const answer = get(assignmentAnswer, 'answers[0]', {})
        const totalScore = get(
          assignmentAnswer,
          'assignmentWorksheets[0].publishedWorksheet.data.totalScore',
          []
        )
        const questions = get(
          assignmentAnswer,
          'assignmentWorksheets[0].publishedWorksheet.data.questions',
          []
        )
        return {
          studentInfo: {
            profileImage: !isEmpty(student) ? student.profileImage : '',
            name: !isEmpty(student) ? `${student.firstname} ${student.lastname}` : '-',
            totalScore: !isEmpty(answer) ? `${answer.score} / ${totalScore}` : '-',
            submittedDate: answer.createdAt
              ? moment(answer.createdAt).format('DD / MM / YYYY')
              : '-',
          },
          title: assignmentAnswer.title,
          answers: questions.map((item) => {
            const ansQuestion = (answer.answers || []).find((ans) => ans.questionId === item.id)
            if (!ansQuestion) {
              return {
                title: item.title,
              }
            }
            return {
              title: item.title,
              number: item.number,
              type: item.type,
              questionId: item.id,
              score: ansQuestion.score,
              answers: ansQuestion.answers,
              choices: item.choices,
              isCorrect: ansQuestion.isCorrect,
              correctAns: item.answers,
            }
          }),
          score: answer.score || 0,
          totalScore: totalScore || 0,
        }
      }
    },
  }),
}
