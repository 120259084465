import get from 'lodash/get'
// import sortBy from 'lodash/sortBy'

import _flatMap from 'lodash/flatMap'
import _orderBy from 'lodash/orderBy'
import { ACCESS_TOKEN, GOOGLE_API_KEY } from '../../constants'
import getFilter from '../../utils/filterBrowseCourse'
import { CourseAPI, CourseLevelAPI } from '../../api'

export const Course = {
  state: {
    courseDetail: {},
    studentAssignmentScores: {},
    courseReport: {},
    courseLevels: [],
    broadcast: {},
    broadcastList: [],
    chatList: [],
    stream: {},
    courseSkill: [],
    numberOfStudents: 0,
    courseMeetings: [],
    meeting: [],
    courseReviews: [],
    courseReviewsCount: 0,
  },
  reducers: {
    setState(state, { name, payload }) {
      return { ...state, [name]: payload }
    },
    setCourseSkill(state, payload) {
      return {
        ...state,
        courseSkill: payload,
      }
    },
    setChatList(state, payload) {
      return {
        ...state,
        chatList: payload,
      }
    },
    setBroadcastList(state, payload) {
      return {
        ...state,
        broadcastList: payload,
      }
    },
    setBroadcast(state, payload) {
      return {
        ...state,
        broadcast: payload,
      }
    },
    setStream(state, payload) {
      return {
        ...state,
        stream: payload,
      }
    },
    setCourseList(state, payload) {
      return {
        ...state,
        courseList: payload,
      }
    },
    setCourseListCount(state, payload) {
      return {
        ...state,
        courseListCount: payload,
      }
    },
    setCourseDetail(state, payload) {
      return {
        ...state,
        courseDetail: payload,
      }
    },
    setStudentAssignmentScores(state, payload) {
      return {
        ...state,
        studentAssignmentScores: payload,
      }
    },
    setCourseReport(state, payload) {
      return {
        ...state,
        courseReport: payload,
      }
    },
    setCourseLevels(state, payload) {
      return {
        ...state,
        courseLevels: payload,
      }
    },
    setCourseReviews(state, payload) {
      return {
        ...state,
        courseReviews: payload,
      }
    },
    setCourseReviewsCount(state, payload) {
      return {
        ...state,
        courseReviewsCount: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async putCourseSkill(payload) {
      try {
        await CourseAPI.putCourseSkill(payload)
      } catch (error) {
        throw error
      }
    },
    async deleteCourseSkill(payload) {
      try {
        await CourseAPI.deleteCourseSkill(payload)
      } catch (error) {
        throw new Error(error)
      }
    },
    async getCourseSkill(courseId) {
      try {
        const result = await CourseAPI.getCourseSkills({ id: courseId })
        this.setCourseSkill(result)
        return result
      } catch (error) {
        throw new Error(error)
      }
    },
    async getTokenGoogle() {
      try {
        const accessTokenGoogle = window.localStorage.getItem('accessTokenGoogle')
        const isExpired = await this.checkTokenExpired(accessTokenGoogle)
        if (isExpired) {
          window.localStorage.removeItem('accessTokenGoogle')
          return null
        }
        return accessTokenGoogle
      } catch (error) {
        throw new Error(error)
      }
    },
    checkTokenExpired(accessTokenGoogle) {
      const url = `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessTokenGoogle}`
      return new Promise((resolve, reject) => {
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            if (data.error === 'invalid_token') {
              return resolve(true)
            }
            return resolve(false)
          })
          .catch((error) => {
            return reject(error)
          })
      })
    },
    async getChatMessage(chatId) {
      const accessTokenGoogle = await this.getTokenGoogle()
      const url = `https://www.googleapis.com/youtube/v3/liveChat/messages?liveChatId=${chatId}&part=id%2C%20snippet%2CauthorDetails&key=${GOOGLE_API_KEY}`
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessTokenGoogle}`,
          Accept: 'application/json',
        },
      }
      return new Promise((resolve, reject) => {
        fetch(`${url}`, options)
          .then((res) => res.json())
          .then((data) => {
            const chat = data.items
            this.setChatList(chat)
            return resolve(chat)
          })
          .catch((error) => {
            return reject(error)
          })
      })
    },
    async getBroadcastById(broadcastId) {
      const accessTokenGoogle = await this.getTokenGoogle()
      const url = `https://www.googleapis.com/youtube/v3/liveBroadcasts?part=snippet%2CcontentDetails%2Cstatus&key=${GOOGLE_API_KEY}`
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessTokenGoogle}`,
          Accept: 'application/json',
        },
      }
      return new Promise((resolve, reject) => {
        fetch(`${url}&id=${broadcastId}`, options)
          .then((res) => res.json())
          .then((data) => {
            const broadcast = data.items[0]
            this.setBroadcast(broadcast)
            return resolve(broadcast)
          })
          .catch((error) => {
            return reject(error)
          })
      })
    },
    async onAuthGoogle(payload) {
      const { courseId, schoolId, type } = payload
      const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
      const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URL
      const scope = 'https://www.googleapis.com/auth/youtube'
      const responseType = 'token'
      const state = JSON.stringify({ schoolId, courseId, type })
      window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&state=${state}`
    },
    // async getBroadcastList(status) {
    //   const accessTokenGoogle = await this.getTokenGoogle()
    //   const url = `https://www.googleapis.com/youtube/v3/liveBroadcasts?part=id%2Csnippet%2CcontentDetails%2Cstatus&broadcastStatus=${status}&broadcastType=all&key=${GOOGLE_API_KEY}`
    //   const options = {
    //     headers: {
    //       Authorization: `Bearer ${accessTokenGoogle}`,
    //       Accept: 'application/json',
    //     },
    //   }
    //   return new Promise((resolve, reject) => {
    //     fetch(url, options)
    //       .then((res) => res.json())
    //       .then((data) => {
    //         if (data.error) throw data.error
    //         const broadcastList = data.items
    //         this.setBroadcastList(broadcastList)
    //         return resolve(broadcastList)
    //       })
    //       .catch((error) => {
    //         if (error.code === 403) {
    //           // User is not enable youtube live
    //           dispatch.Alert.error({
    //             title: error.message,
    //             description: `Please enable at https://www.youtube.com/features`,
    //           })
    //         }
    //         if (error.code === 401) {
    //           // Clear access token google
    //           window.localStorage.removeItem('accessTokenGoogle')
    //         }
    //         throw reject(error)
    //       })
    //   })
    // },
    async getStreamById(streamId) {
      const accessTokenGoogle = await this.getTokenGoogle()
      const url = `https://www.googleapis.com/youtube/v3/liveStreams?part=id%2Csnippet%2Ccdn%2CcontentDetails%2Cstatus&key=${GOOGLE_API_KEY}`
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessTokenGoogle}`,
          Accept: 'application/json',
        },
      }
      return new Promise((resolve, reject) => {
        fetch(`${url}&id=${streamId}`, options)
          .then((res) => res.json())
          .then((data) => {
            const stream = data.items[0]
            this.setStream(stream)
            return resolve(stream)
          })
          .catch((error) => {
            return reject(error)
          })
      })
    },
    async transition(payload) {
      const accessTokenGoogle = await this.getTokenGoogle()
      const { status, broadcastId } = payload
      if (!broadcastId) throw new Error('Require `broadcastId`')
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessTokenGoogle}`,
          Accept: 'application/json',
        },
      }
      return new Promise((resolve, reject) => {
        fetch(
          `https://www.googleapis.com/youtube/v3/liveBroadcasts/transition?broadcastStatus=${status}&id=${broadcastId}&part=id%2C%20snippet%2C%20contentDetails%2C%20status&key=${GOOGLE_API_KEY}`,
          options
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.error) throw data.error
            return resolve(data)
          })
          .catch((error) => {
            dispatch.Alert.error({
              title: error.message,
            })
            throw reject(error)
          })
      })
    },
    async createCourseTransition(payload) {
      try {
        const accessTokenGoogle = await this.getTokenGoogle()
        const _payload = payload
        _payload.youtubeAccessToken = accessTokenGoogle
        const result = await CourseAPI.createCourseTransition(_payload)
        return result
      } catch (error) {
        throw error
      }
    },
    async getCourseLivesById(payload) {
      try {
        const accessTokenGoogle = await this.getTokenGoogle()
        const _payload = payload
        _payload.youtubeAccessToken = accessTokenGoogle
        const result = await CourseAPI.getCourseLivesById(_payload)
        const { broadcastData, streamData } = result
        this.setBroadcast(broadcastData)
        this.setStream(streamData)
        return result
      } catch (error) {
        throw error
      }
    },
    async getCourseLives(id) {
      try {
        const accessTokenGoogle = await this.getTokenGoogle()
        const payload = {
          id,
          youtubeAccessToken: accessTokenGoogle,
        }
        const result = await CourseAPI.getCourseLives(payload)
        this.setBroadcastList(result)
      } catch (error) {
        throw error
      }
    },
    async createCourseLives(payload) {
      try {
        const accessTokenGoogle = await this.getTokenGoogle()
        const _payload = payload
        _payload.youtubeAccessToken = accessTokenGoogle
        const result = await CourseAPI.createCourseLives(_payload)
        return result
      } catch (error) {
        throw new Error(error)
      }
    },
    async updateCourseById(payload, state) {
      let locale = state.Language.locale
      try {
        await CourseAPI.update(payload)
        await dispatch.SchoolCourse.getMyCourseById(payload.id)
        dispatch.Alert.success({ title: locale === 'en' ? 'Updated Successful' : 'อัปเดตสำเร็จ' })
      } catch (error) {
        dispatch.Alert.error({ title: locale === 'en' ? 'Update Failed' : 'อัปเดตไม่สำเร็จ' })
        throw error
      }
    },
    async getCourseCatalog(payload) {
      const { params, limit, skip } = payload
      const filterObj = { ...getFilter(params), order: 'createdAt DESC', limit, skip }
      console.log('filterObj', filterObj)
      const filter = JSON.stringify(filterObj)
      const courses = await CourseAPI.getBrowseCourse({ filter })
      this.setCourseList(courses)
    },
    async getCourseCatalogCount(payload) {
      const { params } = payload
      const filterObj = { ...getFilter(params) }
      const where = JSON.stringify(filterObj.where)
      const courses = await CourseAPI.getBrowseCourseCount({ where })
      this.setCourseListCount(courses)
    },
    async getCourseById(courseId) {
      const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN)) || {}
      const appUserId = token.userId
      const filter = JSON.stringify({
        include: [
          'teachers',
          'school',
          'level',
          'publishedSubject',
          'level',
          {
            relation: 'enrollments',
            scope: {
              where: {
                appUserId: appUserId || null,
              },
            },
          },
        ],
      })
      const course = await CourseAPI.findById({ id: courseId, filter })
      this.setCourseDetail(course)
    },
    async getCourseBySlug(courseSlug) {
      try {
        const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN)) || {}
        const appUserId = token.userId
        const filter = JSON.stringify({
          include: [
            'teachers',
            'mainCategory',
            'subCategory',
            // ['school', 'schoolPackages'],
            {
              relation: 'school',
              scope: { include: 'schoolPackage' },
            },
            'level',
            'publishedSubject',
            'level',
            {
              relation: 'enrollments',
              scope: {
                where: {
                  appUserId: appUserId || null,
                },
              },
            },
          ],
          where: {
            slug: courseSlug,
          },
        })
        const course = await CourseAPI.findOne({ filter })
        this.setCourseDetail(course)
        return course
      } catch (e) {
        throw e
      }
    },
    async getCourseBySlugReturn(courseSlug) {
      try {
        const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN)) || {}
        const appUserId = token.userId
        const filter = JSON.stringify({
          include: [
            'teachers',
            // ['school', 'schoolPackages'],
            {
              relation: 'school',
              scope: { include: 'schoolPackage' },
            },
            'level',
            'publishedSubject',
            'level',
            {
              relation: 'enrollments',
              scope: {
                where: {
                  appUserId: appUserId || null,
                },
              },
            },
          ],
          where: {
            slug: courseSlug,
          },
        })
        const course = await CourseAPI.findOne({ filter })
        return course
      } catch (e) {
        throw e
      }
    },
    async getStudentCourseAssignmentScoresByCourseId({ courseId, filter }) {
      const studentAssignmentScores = await CourseAPI.getStudentCourseAssignmentScoresByCourseId({
        id: courseId,
        filter,
      })
      this.setStudentAssignmentScores(studentAssignmentScores)
    },
    async getCourseReportByCourseId(courseId) {
      const courseReport = await CourseAPI.getCourseReportByCourseId({
        id: courseId,
      })
      this.setCourseReport(courseReport)
    },
    async getCourseLevels() {
      try {
        const res = await CourseLevelAPI.find()
        this.setCourseLevels(res)
      } catch (error) {
        throw error
      }
    },
    async checkInvitationLink({ courseId, code }) {
      const result = await CourseAPI.checkInvitationLink({ id: courseId, code })
      return result
    },
    async getCourseStudentNumber({ courseId }) {
      try {
        const payload = JSON.stringify({ where: { isApproved: true } })
        const result = await CourseAPI.getCourseNumberOfStudents({
          id: courseId,
          filter: payload,
        })
        const { count } = result
        this.setState({ name: 'numberOfStudents', payload: count })
      } catch (e) {
        throw e
      }
    },
    async getCourseMeetings({ courseId }) {
      try {
        const result = await CourseAPI.getCourseMeetings({ id: courseId })
        this.setState({ name: 'courseMeetings', payload: result })
      } catch (e) {
        throw e
      }
    },
    async getCourseMeetingById({ courseId, meetingId }) {
      try {
        const result = await CourseAPI.getCourseMeetingById({ id: courseId, meetingId })
        this.setState({ name: 'meeting', payload: result })
        return result
      } catch (e) {
        throw e
      }
    },
    async deleteCourseMeetingById({ courseId, meetingId }) {
      try {
        const result = await CourseAPI.deleteCourseMeetingById({ id: courseId, meetingId })
        this.setState({ name: 'meeting', payload: result })
        return result
      } catch (e) {
        throw e
      }
    },
    async getCourseStatus({ courseId }) {
      try {
        const result = await CourseAPI.getStatus({ id: courseId })
        this.setState({ name: 'courseStatus', payload: result })
        return result
      } catch (e) {
        throw e
      }
    },
    async getCourseReviews({ courseId, limit = 0 }) {
      // console.log('limit', limit)
      const filter = JSON.stringify({
        include: {
          relation: 'appUser',
          scope: {
            fields: ['id', 'firstname', 'lastname', 'profileImage', 'slug'],
          },
        },
        order: ['rating DESC', 'updatedAt DESC'],
        limit,
      })
      try {
        const result = await CourseAPI.getCourseReviews({ id: courseId, filter })
        this.setCourseReviews(result)
        return result
      } catch (e) {
        throw e
      }
    },
    async getCourseReviewsCount({ courseId }) {
      try {
        const result = await CourseAPI.getCourseReviewsCount({ id: courseId })
        this.setCourseReviewsCount(result.count)
        return result.count
      } catch (e) {
        throw e
      }
    },
  }),
  selectors: (/* slice, createSelector, hasProps */) => ({
    enrolledStatus() {
      return (rootState) => {
        const enrollment = get(rootState.Course.courseDetail, 'enrollments.0')
        const teachers = get(rootState.Course.courseDetail, 'teachers')
        const price = get(rootState.Course.courseDetail, 'price', 0)
        const publicMode = get(rootState.Course.courseDetail, 'publicMode', 'CODE')
        const numberOfStudents = get(rootState.Course, 'numberOfStudents', 0)
        const packageStudentNumber = get(
          rootState.Course.courseDetail,
          'school.schoolPackage.studentNumber',
          50
        )
        const userId = get(rootState.AppUser.appUser, 'id')
        if ((teachers || []).map((item) => item.id).includes(userId)) {
          return 'teaching'
        }
        if (enrollment) {
          if (enrollment.isApproved && enrollment.isPaid) {
            return 'enrolled'
          }
          if (!enrollment.isApproved) {
            return 'awaiting'
          }
          if (!enrollment.isPaid) {
            return 'notPaid'
          }
        }
        if (publicMode === 'CODE' && price === 0 && numberOfStudents >= packageStudentNumber) {
          return 'full'
        }

        return 'notEnroll'
      }
    },
    lectures() {
      return (rootState) => {
        const lectures = get(rootState.Course.courseDetail, 'publishedSubject.data.lectures', [])
        // console.log('tesssst', lectures)
        const subjectWorksheets = get(
          rootState.Course.courseDetail,
          'publishedSubject.data.subjectWorksheets',
          []
        )

        // console.log('rootState.Course.courseDetail', rootState.Course.courseDetail)

        const merged = _flatMap(
          subjectWorksheets.filter((item) => item.type !== 'practice'),
          ({ publishedWorksheet: { data }, ...rest }) => ({
            ...data,
            ...rest,
          })
        )

        const pretest = merged.filter((item) => item.type === 'pretest')
        const posttest = merged.filter((item) => item.type === 'posttest')
        // console.log('pretest', ...posttest)
        const newContent = _orderBy(
          [
            ...lectures,
            ...merged.filter((item) => item.type !== 'pretest' && item.type !== 'posttest'),
          ],
          ['priority'],
          ['asc']
        )
        // console.log('newContent', [...pretest, ...newContent, ...posttest])
        return [...pretest, ...newContent, ...posttest]
        // const sectionNum = 0
        // const lectureNum = 0
        // return sortBy(lectures, ['priority']).map((lecture) => {
        //   let number
        //   if (lecture.type === 'paragraph') {
        //     sectionNum += 1
        //     number = sectionNum
        //   } else {
        //     lectureNum += 1
        //     number = lectureNum
        //   }
        //   return {
        //     ...lecture,
        //     number,
        //   }
        // })
      }
    },
  }),
}
