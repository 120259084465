import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Avatar from '../avatar/loadable'
import { Typography } from '../index'

const GridItem = styled.div`
  width: 100%;
  min-width: 264px;
  //max-width: 273px;
  //height: 332px;
  //max-height: 600px;
  margin: 0px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  position: relative;
  ${({ ProfileCourse }) => {
    if (ProfileCourse) {
      return css`
        width: 33.33%;
        justify-content: flex-start;
      `
    }
  }}
  background-color: #fff;
  /* border: 1px solid #cdcdcd; */
  box-shadow: 0px 7px 6px -3.09375px rgba(0, 0, 0, 0.1),
    0px 4.125px 6.1875px -2.0625px rgba(0, 0, 0, 0.1);
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  :hover {
    /* box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 12px 6px -3.09375px rgba(0, 0, 0, 0.1),
      0px 4.125px 6.1875px -2.0625px rgba(0, 0, 0, 0.1);
    /* border: 1px solid #cdcdcd; */
    transform: translateY(-5px);
    @media (max-width: 1439px) {
      transform: translateY(0px);
    }
  }
  @media (max-width: 1439px) {
    margin: 0px 0px 16px 0px;
  }
  @media (max-width: 767px) {
    margin: 0px 0px 10px 0px;
  }
`

const Content = styled.div`
  padding: 16px;
  /* padding-bottom: 0; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Image = styled.div`
  width: 100%;
  background-image: ${(props) => `url(${props.url})`};
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 768px) {
    max-width: -webkit-fill-available;
  }
`

const Name = styled(Typography)`
  white-space: nowrap;
  font-size: ${({ theme }) => theme['text-sm']};
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
`

const Description = styled(Typography)`
  color: ${(props) => props.theme.black87};
  font-size: ${({ theme }) => theme['text-xs']};
  overflow: hidden;
  text-overflow: ellipsis;
  height: 36px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const Profile = styled(Avatar)`
  position: absolute;
  top: auto;
  right: 16px;
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.4);
`

const IconWithText = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${(props) => (props.primary ? props.theme.primary2 : props.theme.grey)};
  font-size: ${({ theme }) => theme['text-sm']};
  img {
    margin-right: 5px;
  }
  ${({ start }) => {
    if (start) {
      return css`
        justify-content: flex-start;
      `
    }
  }}
  .after {
    position: relative;
    padding: 0 16px 0 0;
  }
  .after:after {
    content: '';
    position: absolute;
    height: 15px;
    width: 1px;
    background-color: ${(props) => props.theme.gray6};
    top: 3px;
    right: 8px;
  }

  &.mode img {
    filter: invert(40%);
  }
`

const Subject = styled.div`
  color: white;
  font-size: ${({ theme }) => theme['text-sm']};
  position: absolute;
  left: 16px;
  top: 155px;
`

// /* const StyledLink = styled.a` */
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
`

const Status = styled.div`
  /* background-color: rgba(0, 0, 0, 0.3); */
  /* backdrop-filter: blur(8px); */
  /* height: 40px; */
  top: 3px;
  right: 10px;
  border-radius: 2px;
  position: absolute;
  padding: 7px;
  /* box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15); */
  color: white;
  font-weight: 600;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  img {
    position: absolute;
    height: 55px;
    top: -14px;
    right: -2px;
  }
`
const StatusFlag = styled.img`
  position: absolute;
  height: 62px;
  top: -4px;
  right: 3px;
`
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`
const StarGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6px;
`
export const EmptyStyle = styled.div`
  display: flex;
  position: relative;
  ${({ responsivePadding, responsivePaddingMan }) => {
    if (responsivePaddingMan) {
      return css`
        padding: 0 0 0 0;
      `
    } else if (responsivePadding) {
      return css`
        padding: 20px 30px 30px 30px;
        @media (max-width: 768px) and (min-width: 426px) {
          padding: 10px 30px 30px 30px;
        }

        @media (max-width: 425px) {
          padding: 0 30px 30px 30px;
        }
      `
    } else {
      return css`
        padding: 30px;
      `
    }
  }}
  margin: ${(props) => (props.responsivePaddingMan ? '0 0 0 0' : '20px')};
  flex: 1;
  height: ${(props) => (props.height ? props.height : '100%')};
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  button {
    margin-top: 25px;
  }

  .full-image {
    // width: 50%;
    // height: 50%;
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 15px;
  }

  @media (max-width: 600px) and (min-width: 429px) {
    .full-image {
      margin-bottom: 10px;
      max-height: 200px;
      max-width: 200px;
    }
  }

  @media (max-width: 429px) {
    .full-image {
      margin-bottom: 10px;
      max-height: 150px;
      max-width: 150px;
    }
  }

  ${({ small }) => {
    if (small) {
      return css`
        .full-image {
          max-width: 200px;
          max-height: 200px;
          ${'' /* margin-bottom: 15px; */}
        }
        .title {
          font-size: ${({ theme }) => theme['text-sm']};
        }
        .desc {
          font-size: ${({ theme }) => theme['text-xs']};
          margin-top: 5px;
        }
      `
    }
    return css``
  }}

  ${({ medium }) => {
    if (medium) {
      return css`
        .full-image {
          max-width: 258px;
          max-height: 200px;
          margin-bottom: 15px;
        }
        .title {
          font-size: ${({ theme }) => theme['text-xl']};
        }
        .desc {
          font-size: ${({ theme }) => theme['text-sm']};
          margin-top: 5px;
        }
      `
    }
    return css``
  }}

  ${({ large }) => {
    if (large) {
      return css`
        .full-image {
          max-width: 600px;
          // max-height: 600px;
          max-height: 33vh;
          margin-bottom: 15px;
        }

        @media (max-width: 768px) and (min-width: 429px) {
          .full-image {
            max-width: 400px;
            max-height: 400px;
            margin-bottom: 15px;
          }
        }

        @media (max-width: 429px) {
          .full-image {
            max-width: 250px;
            max-height: 250px;
            margin-bottom: 15px;
          }
        }
      `
    }
    return css``
  }}

  ${({ primary }) => {
    if (primary) {
      return css`
        .title {
          color: ${({ theme }) => theme['primary1']};
          font-size: ${({ theme }) => theme['text-2xl']};
          font-weight: 700;
        }
        .desc {
          color: ${({ theme }) => theme['primary1']};
          font-size: ${({ theme }) => theme['text-m']};
          font-weight: 400;
        }
      `
    }
    return css``
  }}

  ${({ Custom1 }) => {
    if (Custom1) {
      return css`
        .full-image {
          max-width: 300px;
          // max-height: 600px;
          max-height: 33vh;
          margin-top: 64px;
          margin-bottom: 35px;
        }
        .title {
          color: ${({ theme }) => theme['primary1']};
          font-size: ${({ theme }) => theme['text-2xl']};
        }
        .desc {
          width: 100%;
          color: ${({ theme }) => theme['black60']};
          font-size: ${({ theme }) => theme['text-base']};
          margin-top: 24px;
        }
      `
    }
    return css``
  }}
  ${({ Custom2 }) => {
    if (Custom2) {
      return css`
        .title {
          color: ${({ theme }) => theme['primary1']};
          font-size: ${({ theme }) => theme['text-2xl']};
          font-weight: 700;
        }
        .desc {
          color: ${({ theme }) => theme['black60']};
          font-size: ${({ theme }) => theme['text-base']};
          font-weight: 400;
        }
        @media (max-width: 429px) {
          .title {
            font-size: ${({ theme }) => theme['text-xl']};
          }
          .desc {
            font-size: ${({ theme }) => theme['text-sm']};
          }
        }
      `
    }
    return css``
  }}
`

export const Desc = styled(Typography)`
  ${({ desclong }) => {
    if (desclong) {
      return css`
        width: 80%;
        @media (max-width: 768px) and (min-width: 426px) {
          width: 90%;
        }

        @media (max-width: 425px) {
          width: 100%;
        }
      `
    } else {
      return css`
        width: 300px;
      `
    }
  }}
  font-size: ${({ theme }) => theme['text-lg']};
  ${({ customMarginDesc }) => {
    if (customMarginDesc) {
      return css`
        margin: 20px 0 30px 0;
        @media (max-width: 768px) and (min-width: 426px) {
          margin: 15px 0 20px 0;
        }

        @media (max-width: 425px) {
          margin: 10px 0 20px 0;
        }
      `
    } else {
      return css`
        margin: 10px 0 0 0;
      `
    }
  }}
  @media (max-width: 425px) {
    font-size: ${({ theme }) => theme['text-sm']};
  }
`

export const ImageEmpty = styled.img``

export const EmptyCard = styled.div`
  margin: ${({ noMargin }) => (noMargin ? '0px' : '40px')};
  ${({ responsivePaddingMan }) => {
    if (responsivePaddingMan) {
      return css`
        margin: 0 0 92px 0;
      `
    }
  }}
  /* background-color: white; */
  border-radius: 8px;

  @media (max-width: 767px) {
    /* padding: 32px 16px; */
    margin: ${({ noMargin }) => (noMargin ? '0px' : '24px')};
    ${({ responsivePaddingMan }) => {
      if (responsivePaddingMan) {
        return css`
          margin: 0 0 0 0;
        `
      }
    }}
  }

  @media (max-width: 426px) {
    margin: 0px;
  }
`
export {
  GridItem,
  Content,
  Image,
  Bottom,
  Name,
  Description,
  Profile,
  IconWithText,
  Subject,
  StyledLink,
  Status,
  StatusFlag,
  StarGroup,
}
