import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorBoundary from '../../../../../components/errorBoundary'
import EditIcon from '../../../../../images/icons/EditIcon-grey.png'
import BinIcon from '../../../../../images/icons/DeleteIcon-grey.png'
import EditIconPrimary from '../../../../../images/icons/EditIcon-primary.png'
import BinIconPrimary from '../../../../../images/icons/DeleteIcon-primary.png'
const Container = styled.div`
  width: 150px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  right: -64px;
  position: absolute;
`

const Item = styled.div`
  padding: 10px 0 10px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${({ theme }) => theme['text-sm']};

  img {
    width: 20px;
    margin-right: 14px;
  }

  /*:hover {
    background-color: #E9F3F9;
    font-weight: bold;
    color: ${({ theme }) => theme.primary1};
    img {
     stroke: red;
    }    
  } */
`
const Divider = styled.hr`
  border: 0px;
  border-bottom: 1px solid #f3f3f3;
`
export const PlainBtn = styled.button`
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  width: 150px;

  div {
    padding: 10px 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: ${({ theme }) => theme['text-sm']};
    img {
      width: 20px;
      margin-right: 14px;
    }
    color: ${({ theme }) => theme.black87};

    :hover {
      width: 150px;
      background-color: #e9f3f9;
      color: ${({ theme }) => theme.primary1};
      font-weight: bold;
      .edit-icon {
        background-image: url(${EditIconPrimary});
        background-size: 20px;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }
      .bin-icon {
        background-image: url(${BinIconPrimary});
        background-size: 20px;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }
    }
  }
`
const EditIconHover = styled.div`
  background-image: url(${EditIcon});
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
`
const BinIconHover = styled.div`
  background-image: url(${BinIcon});
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
`

// const ImageToggleOnMouseOver = ({ primaryImg, secondaryImg }) => {
//   const imageRef = useRef(null)

//   return (
//     <img
//       onFocus={() => {
//         imageRef.current.src = secondaryImg
//       }}
//       onBlur={() => {
//         imageRef.current.src = primaryImg
//       }}
//       src={primaryImg}
//       alt=""
//       ref={imageRef}
//     />
//   )
// }

// const EditIconChange = () => {
//   return (
//     <ImageToggleOnMouseOver primaryImg={EditIcon} secondaryImg={EditIconPrimary} alt="edit-icon" />
//   )
// }
// const BinIconChange = () => {
//   return (
//     <ImageToggleOnMouseOver primaryImg={BinIcon} secondaryImg={BinIconPrimary} alt="bin-icon" />
//   )
// }

class TooltipOption extends Component {
  static propTypes = {}

  render() {
    const { handleDelete, handleEdit, locale } = this.props

    return (
      <ErrorBoundary>
        <Container>
          <PlainBtn onClick={() => handleEdit()}>
            <Item style={{ borderRadius: '8px 8px 0 0' }}>
              {/* <img src={EditIcon} alt="edit-icon" className='edit-icon'
              onMouseOver={this.src = {EditIconPrimary}}
              onMouseOut={this.src = {EditIcon}}/> */}
              {/* <img src={EditIcon} alt="edit-icon-primary" className='edit-icon-primary' /> */}
              {/* <img src={EditIcon} alt="edit-icon" className='edit-icon'/> */}
              <EditIconHover className="edit-icon" style={{ marginRight: 10 }} />
              {locale === 'en' ? 'Edit' : 'แก้ไข'}
            </Item>
          </PlainBtn>

          <Divider />
          <PlainBtn onClick={() => handleDelete()}>
            <Item style={{ borderRadius: '0 0 8px 8px' }}>
              {/* <img src={BinIcon} alt="bin-icon" className='bin-icon'
                onMouseOver={this.src = require({BinIconPrimary})}
                onMouseOut={this.src = require({BinIcon})} /> */}
              {/* <img src={BinIcon} alt="bin-icon" className='bin-icon-primary'/> */}
              {/* <img src={BinIcon} alt="bin-icon" className='bin-icon'/> */}
              <BinIconHover className="bin-icon" style={{ marginRight: 10 }} />
              {locale === 'en' ? 'Delete' : 'ลบ'}
            </Item>
          </PlainBtn>
        </Container>
      </ErrorBoundary>
    )
  }
}
const mapState = (state) => ({
  locale: state.Language.locale,
})

export default connect(mapState)(TooltipOption)
