/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Typography } from 'components'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import LectureDetailMobile from './LectureDetailMobile'
import Discussion from './Discussion'
const HeaderMenu = styled.div`
  cursor: pointer;
  gap: 32px;
  padding: 0px 20px 12px 20px;
  display: flex;
  justify-content: center;
  ${({ activeText }) => {
    if (activeText) {
      return css`
        border-bottom: 2px solid white;
      `
    }
  }}
`
const HeaderCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 0px 16px 0px 16px;
  margin: 0px 0px 20px 0px;
`
function DetailContentMobile(props) {
  const { data, preTest, postTest, courseId, locale } = props
  const [menu, setMenu] = useState('lecture')
  return (
    <div style={{ backgroundColor: '#222223', height: '100vh' }}>
      <HeaderCard>
        <HeaderMenu
          onClick={() => setMenu('lecture')}
          activeText={menu === 'lecture' ? true : false}
        >
          <Typography
            color={menu === 'lecture' ? 'white' : 'Dark2'}
            nonebold
            bold={menu === 'lecture' ? true : false}
          >
            {locale === 'en' ? 'Lecture' : 'เลคเชอร์'}
          </Typography>
        </HeaderMenu>
        <HeaderMenu
          onClick={() => setMenu('discussion')}
          activeText={menu === 'discussion' ? true : false}
        >
          <Typography
            color={menu === 'discussion' ? 'white' : 'Dark2'}
            nonebold
            bold={menu === 'discussion' ? true : false}
          >
            {locale === 'en' ? 'Discussion' : 'บอร์ดสนทนา'}
          </Typography>
        </HeaderMenu>
      </HeaderCard>
      {menu === 'lecture' && (
        <div style={{ backgroundColor: '#222223' }}>
          <LectureDetailMobile data={data} preTest={preTest} postTest={postTest} isMobile />
        </div>
      )}
      {menu === 'discussion' && (
        <div style={{ backgroundColor: '#222223', padding: '0px 16px 0px 16px' }}>
          <Discussion courseId={courseId} isMobile />
        </div>
      )}
    </div>
  )
}

const mapState = ({ Language: { locale } }) => ({ locale })
export default connect(mapState, null)(DetailContentMobile)
