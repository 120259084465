import styled from 'styled-components'
import { Card, Typography, Button } from '../index'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 106;
  align-items: center;
  justify-content: center;
`

const _Card = styled(Card)`
  padding: 40px;
  max-width: 900px;
  margin: 0 30px;
  width: 100%;
  //height: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: none;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  min-width: 200px;
`

const ImageInfo = styled.div`
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  width: 100%;
  height: 317px;
  background-position: center;
  background-size: contain;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
`

const Description = styled(Typography)`
  max-width: fit-content;
`

export { Container, _Card as Card, Typography, Image, Button, Header, Description, ImageInfo }
