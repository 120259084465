export const tabs = [
  {
    id: 'tab.label.Overview',
    label: 'Overview',
    key: '0',
    component: null,
    path: '/overview',
  },
  {
    id: 'tab.label.TransferHistory',
    label: 'Transfer History',
    key: '1',
    component: null,
    path: '/transfer-history',
  },
  {
    id: 'tab.label.Bankaccount',
    label: 'Bank Account',
    key: '2',
    component: null,
    path: '/bank-account',
  },
]
