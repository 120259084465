import styled from 'styled-components'
import { Button, Container, Card } from '../index'

const ContainerDialog = styled(Container)`
  display: flex;
  justify-content: center;
  // position: fixed;
  margin: 16px 0 0 0px;
  background-color: transparent;
`
const ButtonDialog = styled(Button)`
  position: absolute;
`
const CardDialog = styled(Card)`
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  /* position: absolute; */
  /* -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2); */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: fit-content;
  height: fit-content;
  /* left: 69px;
  top: -4px; */
  z-index: 1;
`

export { ContainerDialog, ButtonDialog, CardDialog }
