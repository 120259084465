/* eslint-disable no-unused-vars */
import React, { useMemo, createRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { Typography } from 'components'
import LectureItemDetail from './LectureItemDetail'
import styled, { css } from 'styled-components'
import Icon from '../../../../components/icon/loadable'
import ArrowRightWhite from '../../../../images/icons/arrowRightWhite.svg'
const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  ::-webkit-scrollbar {
    height: 6px;
  }
  margin: 16px 4px 16px 0px;
`
function LectureDetail(props) {
  const { data, setIsDetail, locale, onNext, match } = props

  const lectureData = useMemo(() => {
    let data1 = []
    data?.map((item) => {
      item?.contents?.map((lecture) => {
        data1.push(lecture)
      })
    })
    return data1
  }, [data])

  const arrLength = lectureData.length
  const elRefs = React.useRef([])

  if (elRefs.current.length !== arrLength) {
    // add or remove refs
    elRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef())
  }

  const executeScroll = (i) => {
    elRefs?.current?.[i]?.current?.scrollIntoView?.({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    })
  }
  useEffect(() => {
    {
      lectureData?.map((item, i) => {
        if (item?.id === match?.params?.lectureId) {
          elRefs?.current?.[i]?.current?.scrollIntoView?.({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          })
        }
      })
    }
  }, [match?.params?.lectureId, lectureData])
  return (
    <div>
      <div style={{ display: ' flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography color="white" title bold>
          {locale === 'en' ? 'Lecture' : 'เลคเชอร์'}
        </Typography>
        <div
          style={{
            display: ' flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setIsDetail(true)
          }}
        >
          <Typography color="white" style={{ cursor: 'pointer' }}>
            {locale === 'en' ? 'See more' : 'ดูเพิ่มเติม'}
          </Typography>
          <div style={{ margin: '0px 0px 0px 0px' }}>
            <Icon src={ArrowRightWhite} size="16" />
          </div>
        </div>
      </div>
      <Content>
        {lectureData?.map((item, i) => {
          return (
            <div onClick={() => executeScroll(i)} key={item?.id} ref={elRefs.current[i]}>
              <LectureItemDetail {...item} key={item?.id} onNext={onNext} />
            </div>
          )
        })}
      </Content>
    </div>
  )
}

const mapState = (state, props) => {
  return {
    locale: state.Language.locale,
  }
}

export default connect(mapState, null)(LectureDetail)
