import React from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import classNames from 'classnames'
import ErrorBoundary from '../errorBoundary'
import './styles.css'

const Tooltip = ({ children, tooltip, className, style, ...props }) => (
  <ErrorBoundary>
    <TooltipTrigger
      {...props}
      tooltip={({ tooltipRef, getTooltipProps }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: classNames({ 'tooltip-container': true, [className]: true }),
          })}
        >
          {tooltip}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          style={style}
          {...getTriggerProps({
            ref: triggerRef,
            className: 'tooltip',
          })}
        >
          {children}
        </span>
      )}
    </TooltipTrigger>
  </ErrorBoundary>
)

export default Tooltip
