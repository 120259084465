import { FileAPI } from '../api'

const getImageUrl = (image, defaultImage, width, height) => {
  if (image && image.includes('facebook')) return image
  if (image && image.includes('http')) return image
  return image ? FileAPI.getImageUrl(image, { width, height }) : defaultImage
}

export default getImageUrl

export const getRawFileUrl = (file) => (file ? FileAPI.getRaw(file) : undefined)
