import styled, { css } from 'styled-components'
import theme from 'theme'

export const ButtonStyle = styled.button`
  display: flex;
  padding: 8px 16px;
  border-radius: ${({ borderRadius }) => borderRadius || 8}px;
  border-style: none;
  color: white;
  /* border: 1px solid transparent; */
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme, fontSize }) => theme[fontSize] || theme['text-base']};
  font-weight: 500;
  outline: none;
  cursor: pointer;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.5s;
  /* width: fit-content; */
  width: 100%;
  height: fit-content;
  ${({ minWidth }) => {
    if (minWidth) {
      return css`
        min-width: ${minWidth}px;
      `
    }
    return css``
  }}

  ${({ minHeight }) => {
    if (minHeight) {
      return css`
        min-height: ${minHeight}px;
      `
    }
    return css`
      min-height: 40px;
    `
  }}
  ${({ maxHeight }) => {
    if (maxHeight) {
      return css`
        max-height: ${maxHeight}px;
      `
    }
    return css`
      max-height: 40px;
    `
  }}
  font-weight: 600;
  @media (max-width: 430px) {
    font-size: ${({ theme }) => theme['text-xs']};
    padding: 6.5px 16px;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-left: ${(props) => (props.reverse ? '0px' : '4px')};
    margin-right: ${(props) => (props.reverse ? '4px' : '0px')};
    position: relative;
    right: ${(props) => (props.reverse ? null : '5px')};
    left: ${(props) => (props.reverse ? '5px' : null)};
    top: 1;
  }

  img {
    width: ${(props) => (props.sizeImg ? `${props.sizeImg}px` : '24px')};
    height: ${(props) => (props.sizeImg ? `${props.sizeImg}px` : '24px')};
    margin-left: ${(props) => (props.reverse ? '0px' : '4px')};
    margin-right: ${(props) => (props.reverse ? '4px' : '0px')};
    position: relative;
    right: ${(props) => (props.reverse ? null : '5px')};
    left: ${(props) => (props.reverse ? '5px' : null)};
    /* top:-1px; */
  }

  // &:hover {
  //   font-weight: 450;
  //   transform: scale(1.02);
  //   transition-timing-function: ease-in-out;
  //   opacity: 1;
  //   color: red;
  // }

  @media (max-width: 430px) {
    font-size: ${({ theme }) => theme['text-xs']};
    ${({ padding }) => {
      if (padding) {
        return css`
          padding: ${padding};
        `
      }
      return css`
        padding: 6.5px 16px;
      `
    }}
  }

  ${(props) => {
    if (props.primary) {
      return css`
        background: ${theme.primary1};
        color: ${theme.white};
        /* &:hover {
          background: #283d7b;
        } */
      `
    }
    if (props.secondary) {
      return css`
        background: ${theme.primary4};
        color: ${theme.primary1};
      `
    }
    if (props.download) {
      return css`
        justify-content: center;
        background: ${theme.white};
        color: ${theme.primary1};
        border: 1px solid #d9d9d9;
      `
    }
    if (props.pending) {
      return css`
        background: rgb(37, 62, 135, 0.25);
        color: #fff;
      `
    }

    if (props.primary2) {
      return css`
        background: ${theme.primary4};
        color: #253e87;
        &:hover {
          color: #253e87;
          background: ${theme.primary4};
        }
        &:focus {
          color: #253e87;
          background: ${theme.primary4};
        }
      `
    }

    if (props.subscribe) {
      return css`
        background: ${theme.primary4};
        color: #253e87;
        &:hover {
          background: ${theme.white};
          color: ${theme.danger};
        }
      `
    }

    if (props.newPrimaryButton) {
      return css`
        background: #395092;
        color: #fff;
      `
    }

    if (props.whiteBorder) {
      return css`
        background: ${theme.white};
        color: #253e87;
        border: 2px solid #253e87;
        &:hover {
          background: ${theme.white};
          color: #1c2e65;
          border: 2px solid #1c2e65;
        }
      `
    }

    if (props.onlyTextSecondary) {
      return css`
        color: ${theme.primary1};
        background: transparent;
        &:hover {
          background: transparent;
        }
      `
    }

    if (props.onlyTextDisabled) {
      return css`
        background: transparent;
        &:disabled {
          color: ${theme.grey};
          cursor: not-allowed;
        }
      `
    }
    if (props.onlyTextFollow) {
      return css`
        color: ${theme.primary13};
        background: transparent;
        &:hover {
          background: transparent;
        }
      `
    }

    if (props.onlyText) {
      return css`
        color: ${theme.primary};
        background: transparent;
        &:hover {
          background: #eef0ff;
        }
      `
    }
    if (props.commentButton) {
      return css`
        color: ${theme.primary};
        background: transparent;
      `
    }
    if (props.verify) {
      return css`
        background: transparent;
        color: ${theme.primary2};
        border: 1px solid ${theme.primary2};
        &:hover {
          color: ${theme.primary2};
          border: 1px solid ${theme.primary2};
        }
      `
    }
    if (props.report) {
      return css`
        background: #eef0ff;
        color: ${theme.primary2};
        /* border: 1px solid ${theme.primary2}; */
        &:hover {
          color: ${theme.primary2};
          /* border: 1px solid ${theme.primary2}; */
        }
      `
    }
    if (props.createCourse) {
      return css`
        background: #253e87;
        color: white;
      `
    }
    if (props.disabled) {
      return css`
        &:disabled {
          background: #39509250;
          color: ${theme.white};
          cursor: not-allowed;
        }
      `
    }
    if (props.primaryDisabled) {
      return css`
        background: #39509250;
        color: ${theme.white};
        cursor: not-allowed;
      `
    }

    if (props.date) {
      return css`
        background: ${theme.primary1};
        color: ${theme.white};
        &:hover {
          background: ${theme.primary7};
        }
      `
    }

    if (props.menu) {
      return css`
        font-weight: bold;
        color: ${theme.black100};
        background: ${theme.white};
        &:hover {
          color: ${theme.primary1};
          text-decoration: underline;
          text-decoration-thickness: 2.5px;
          text-decoration-color: ${theme.primary1};
          text-underline-offset: 2px;
        }
      `
    }

    if (props.menuLanding) {
      return css`
        font-weight: bold;
        color: ${theme.black100};
        background: none;
        &:hover {
          color: ${theme.primary1};
          text-decoration: underline;
          text-decoration-thickness: 2.5px;
          text-decoration-color: ${theme.primary1};
          text-underline-offset: 2px;
        }
      `
    }

    if (props.success) {
      return css`
        background-color: ${theme.success};
        color: ${theme.white};
        &:hover {
          background-color: ${theme.success2};
        }
      `
    }

    if (props.recheck) {
      return css`
        background-color: ${theme.white};
        border: 2px solid ${theme.primary1};
        color: ${theme.primary1};
        @media (min-width: 560px) {
          width: 160px;
          height: 40px;
        }
      `
    }

    if (props.cancelcer) {
      return css`
        background-color: ${theme.white};
        border: 2px solid ${theme.primary1};
        font-size: ${({ theme }) => theme['text-base']};
        color: ${theme.primary1};
        @media (min-width: 768px) {
          width: 240px;
          height: 40px;
        }
        @media (max-width: 767px) {
          width: 115px;
          height: 40px;
        }
      `
    }

    if (props.createcer) {
      return css`
        background-color: #253e87;
        font-size: ${({ theme }) => theme['text-base']};
        color: ${theme.white};
        @media (min-width: 768px) {
          width: 240px;
          height: 40px;
        }
        @media (max-width: 767px) {
          width: 115px;
          height: 40px;
        }
      `
    }

    if (props.issue) {
      return css`
        display: flex;
        margin-top: 16px;
        @media (max-width: 767px) {
          margin-top: 10px;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
          margin-top: 7px;
        }
        background-color: ${theme.white};
        border: 2px solid ${theme.primary1};
        color: ${theme.primary1};
        width: 100%;
        height: 40px;
      `
    }

    if (props.submit) {
      return css`
        background-color: #395092;
        color: ${theme.white};
        @media (min-width: 560px) {
          width: 160px;
          height: 40px;
        }
      `
    }
    if (props.submitans) {
      return css`
        background-color: #395092;
        color: ${theme.white};
        width: 100%;
        height: 40px;
      `
    }
    if (props.submitAns) {
      return css`
        background: ${theme.primary1};
        color: ${theme.white};
        &:hover {
          background: ${theme.primary1};
        }
      `
    }

    if (props.createCer) {
      return css`
        margin: 60px 0 0 0;
        width: 300px;
        background: ${theme.primary11};
        color: ${theme.white};
        &:hover {
          background: ${theme.primary11};
        }
      `
    }

    if (props.submmittest) {
      return css`
        width: 200px;
        background: #395092;
        color: ${theme.white};
        &:hover {
          background: #283d7b;
        }
        @media (max-width: 768px) {
          width: 150px;
        }
      `
    }
    if (props.addProfile) {
      return css`
        width: 220px
        background: ${theme.primary1};
        color: ${theme.white};
        &:hover {
          background: ${theme.primary1};
        }
      `
    }

    if (props.successSecondary) {
      return css`
        background: ${theme.white};
        color: ${theme.success};
        border: 1px solid ${theme.success};
        &:hover {
          color: ${theme.success};
          border: 1px solid ${theme.success};
        }
      `
    }
    if (props.danger) {
      return css`
        background-color: ${theme.danger};
        color: ${theme.white};
        // border: 1px solid ${theme.danger};
        // &:hover {
        //   border: 2px solid ${theme.danger};
        // }
      `
    }

    if (props.dangerFill) {
      return css`
        background-color: ${theme.danger};
        color: ${theme.white};
        border: 2px solid ${theme.danger};
      `
    }
    if (props.dangerSecond) {
      return css`
        background-color: ${theme.white};
        color: ${theme.danger};
        border: 1px solid ${theme.danger};
      `
    }
    if (props.dangerBold) {
      return css`
        background-color: ${theme.white};
        color: ${theme.danger};
        border: 2px solid ${theme.danger};
      `
    }
    if (props.warning) {
      return css`
        background-color: ${theme.warning};
        color: ${theme.white};
        // &:hover {
        //   border: 2px solid ${theme.warning};
        // }
      `
    }
    if (props.arrow) {
      return css`
        background-color: rgba(87, 136, 252, 0.1);
        color: ${theme.primary2};

        // &:hover {
        //   border: 2px solid rgba(87, 136, 252, 0.1);
        // }
      `
    }
    if (props.light) {
      return css`
        background-color: 'transparent';
        color: ${theme.gray2};
        border: 1px solid ${theme.gray2};
      `
    }

    if (props.facebook) {
      return css`
        background-color: ${theme['denim-blue']};
        color: ${theme.white};
        // &:hover {
        //   border: 2px solid ${theme['denim-blue']};
        // }
      `
    }

    if (props.ocean) {
      const distination = '223deg'
      const start = '#69A7E7 0%'
      const middle = '#442CD2 100%'
      const end = '#442CD2 100%'
      return css`
        background-color: ${theme.primary2};
        background-image: linear-gradient(${distination}, ${start}, ${middle}, ${end});
        background-image: -webkit-linear-gradient(${distination}, ${start}, ${middle}, ${end});
        background-image: -moz-linear-gradient(${distination}, ${start}, ${middle}, ${end});
        background-image: -o-linear-gradient(${distination}, ${start}, ${middle}, ${end});
        color: white;
      `
    }
    if (props.rose) {
      const distination = '217deg'
      const start = '#CD5B5B 0%'
      const end = '#EB0000 100%'
      return css`
        background-color: ${theme.primary};
        background-image: linear-gradient(${distination}, ${start}, ${end});
        background-image: -webkit-linear-gradient(${distination}, ${start}, ${end});
        background-image: -moz-linear-gradient(${distination}, ${start}, ${end});
        background-image: -o-linear-gradient(${distination}, ${start}, ${end});
        color: white;
      `
    }
    if (props.plusCircle) {
      return css`
        width: 100%;
        display: flex;
        background-color: ${theme.white};
      `
    }

    if (props.transparent) {
      return css`
        background-color: transparent;
        color: ${theme.gray6};
        :hover {
          background-color: #eef0ff;
          color: #5346f9;
          ${'' /* text-decoration: underline; */}
          ${'' /* text-decoration-thickness: 1px; */}
          ${'' /* text-decoration-color: ${theme.primary2}; */}
        }
      `
    }

    if (props.white) {
      return css`
        background-color: transparent;
        color: ${theme.white};
        :hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      `
    }

    if (props.selected) {
      return css`
        background-color: ${theme.primary4};
        color: ${theme.primary1};
        cursor: default;
      `
    }
    if (props.cancelbtn) {
      return css`
        border: 2px solid ${theme.primary1};
        color: ${theme.primary1};
        background-color: ${theme.white};
      `
    }
    if (props.confirmbtn) {
      return css`
        background-color: ${theme.primary11};
      `
    }
    if (props.view) {
      return css`
        background-color: ${theme.primary14};
        color: ${theme.primary11};
      `
    }
    if (props.disabled1) {
      return css`
        background: ${theme.whiteblue};
      `
    }
    if (props.searchbar) {
      if (props.path) {
        return css`
          font-weight: 600;
          color: ${theme.primary1};
          background: ${theme.white};

          &:hover {
            color: ${theme.primary1};
            font-weight: 600;
          }
        `
      }
      return css`
        font-weight: 500;
        color: ${theme.black60};
        background: ${theme.white};
        /* &:hover,
        &:focus {
          color: ${theme.primary1};
          text-decoration: underline;
          text-decoration-thickness: 2.5px;
          text-decoration-color: ${theme.primary1};
          text-underline-offset: 2px;
          font-weight: 600;
        } */

        &:hover {
          color: ${theme.primary1};
          font-weight: 600;
        }
      `
    }
    if (props.searchbarLandingPage) {
      if (props.pathLandingPage) {
        return css`
          background: transparent;
          font-weight: 600;
          font-size: 14px;
          color: ${theme.white};
        `
      }
      return css`
        background: transparent;
        font-weight: 400;
        font-size: 14px;
        color: ${theme.white};
        &:hover {
          color: ${theme.white};
          font-weight: 600;
        }
      `
    }
    return css``
  }}

  ${(props) => {
    if (typeof props.width === 'number') {
      return css`
        width: ${props.width}px;
      `
    } else if (typeof props.width === 'string') {
      return css`
        width: ${props.width};
      `
    }
    return ''
  }}

    ${(props) => {
    if (typeof props.heightButton === 'number') {
      return css`
        height: ${props.heightButton}px;
      `
    } else if (typeof props.heightButton === 'string') {
      return css`
        height: ${props.heightButton};
      `
    }
    return ''
  }}

  ${(props) => {
    if (props.height) {
      return css`
        height: ${props.height}px;
      `
    }
    return ''
  }}

  ${(props) => {
    if (props.underline) {
      return css`
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: ${theme.primary1};
      `
    }
    return ''
  }}

  ${(props) => {
    if (props.bold) {
      return css`
        font-weight: 500;
        &:hover {
          font-weight: 700;
        }
      `
    }
    return ''
  }}

  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
  ${({ color }) => color && `color: ${theme[color]};`}
  ${(props) => props.full && 'width: 100%;'}

  ${({ image, icon }) => {
    if (image || icon) {
      return css`
        padding: 6px 18px;

        // @media (max-width: 425px) {
        //   padding: 8px 19px 10px;

        //   svg {
        //     width: 15px;
        //     height: 15px;
        //   }

        //   img {
        //     width: 17px;
        //     height: 17px;
        //     top: 2.5px;
        //   }
        // }
      `
    }
    return css``
  }}

  ${({ padding }) => {
    if (padding) {
      return css`
        padding: ${padding};
      `
    }
    return css``
  }}

  ${({ margin }) => {
    if (margin) {
      return css`
        margin: ${margin};
      `
    }
    return css``
  }}

  ${({ dialog }) => {
    if (dialog) {
      return css``
    }
    return css``
  }}


  ${({ left, center, right }) => {
    if (left) {
      return css`
        justify-self: start;
      `
    }
    if (center) {
      return css`
        justify-self: center;
      `
    }

    if (right) {
      return css`
        justify-self: end;
      `
    }
    return css``
  }}

  ${(props) => {
    if (props.size === 'small') {
      return css`
        font-size: ${({ theme }) => theme['text-xs']};
        padding: 6px 16px;

        svg {
          width: 16px;
          height: 16px;
          top: 4px;
        }

        img {
          width: 14px;
          height: 18px;
        }

        &:hover {
          svg {
            top: 3.5px;
          }
        }

        @media (max-width: 430px) {
          font-size: ${({ theme }) => theme['text-xs']};
          padding: 6px 13px;
        }
      `
    }

    return css``
  }}

  ${({ rounded }) => {
    if (rounded)
      return css`
        border-radius: 999rem;
      `
    return css``
  }}
`

export const Container = styled.div`
  padding: 48px;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 20px;
`
