import React, { useState } from 'react'
import styled from 'styled-components'
import ReactLoading from 'react-loading'

import theme from 'theme'
import UploadComponent from 'components/uploadComponent'
import Button from 'components/button'
import Typography from 'components/typography'
import Container from 'components/container'
import MediaPlayer from 'components/mediaPlayer'
import Input from 'components/input'
import PlusIcon from '../../images/ic-plus-white-24-px.svg'

const Loading = styled.div`
  display: flex;
  align-items: center;
  max-height: 40px;
`
const UploadContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`
const FilenameBorder = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  border: 1px solid ${({ theme }) => theme.black38};
  border-radius: 8px;
  width: 100%;
`
const Content = styled.div`
  background-color: ${(props) => props.theme.whiteblue};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 3px 12px;
  min-width: 120px;
  gap: 8px;
`
const UploadVideo = ({ children, ...props }) => {
  const [loading, setLoading] = useState(false)
  // console.log('fieldValue', props.fieldValue, props.videoFilename)
  return (
    <UploadContainer>
      {!children && (
        <FilenameBorder>
          {(props.fieldValue || props.videoFilename) && (
            <Content>
              <Typography color="primary1" fontSize="text-xs" width="100%" numberOfLines={1}>
                {props.videoFilename || 'video.mp4'}
              </Typography>
              {props.closeIcon}
            </Content>
          )}
          {loading && (
            <Loading>
              <ReactLoading type="bubbles" color={theme.black38} />
              <Typography
                id="button.browse.uploading"
                color="primary1"
                bold
                style={{ marginLeft: 10 }}
                width="100px"
              >
                Uploading
              </Typography>
            </Loading>
          )}
        </FilenameBorder>
      )}
      <UploadComponent
        limitSize={314572800} // 300 MB
        {...props}
        showLoading={false}
        acceptFileTypes={['video/*']}
        onStartLoad={() => {
          props.afterUpload({ name: '', fileName: '' })
          setLoading(true)
        }}
        onStopLoad={() => setLoading(false)}
      >
        {children ? (
          <>
            {children}
            {loading && (
              <Loading>
                <ReactLoading type="bubbles" color={theme.black38} />
                <Typography
                  id="button.browse.uploading"
                  color="primary1"
                  style={{ marginLeft: 10 }}
                  width="65px"
                >
                  Uploading
                </Typography>
              </Loading>
            )}
          </>
        ) : (
          <Button
            id="button.browse.video"
            type="button"
            primary
            width={130}
            image={<img src={PlusIcon} alt="plus-icon" />}
          >
            Upload
          </Button>
        )}
      </UploadComponent>
    </UploadContainer>
  )
}

const UploadVideoFullOptions = ({ defaultValue, setValue, error }) => {
  const [url, setUrl] = useState(defaultValue)

  return (
    <React.Fragment>
      <Container column="1">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UploadVideo
            afterUpload={(file) => {
              setValue(file.name)
              setUrl(file.name)
            }}
          />
          <Typography h6 margin="0 10px" width="15px">
            or
          </Typography>
          <Input
            id="introVideo"
            name="introVideo"
            placeholder="URL"
            value={url}
            onChange={(e) => {
              setValue(e.target.value)
              setUrl(e.target.value)
            }}
            full
          />
        </div>
      </Container>
      {error && <Typography color="danger">{error}</Typography>}
      <Container style={{ marginTop: 20 }}>
        {url && (
          <MediaPlayer
            url={url}
            width="100%"
            style={{
              background: 'black',
              borderRadius: 4,
            }}
          />
        )}
      </Container>
    </React.Fragment>
  )
}

export default UploadVideo
export { UploadVideoFullOptions }
