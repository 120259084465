import Resource from '../utils/resource'

const baseURL = process.env.REACT_APP_API_URL
const container = 'witsawa-classwin'
const resource = new Resource(`/Containers/${container}/files`, {
  upload: {
    url: `/Containers/${container}/upload`,
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data',
    },
  },
  download: {
    url: `/Containers/${container}/download/{file}`,
    method: 'post',
  },
  playvideo: {
    url: `/Containers/${container}/playvideo/{file}`,
    method: 'get',
  },
  rawFile: {
    url: `/Containers/${container}/raw/{file}`,
    method: 'get',
  },
  uploadVideo: {
    url: `/VideoContainers/video/upload`,
    method: 'post',
  },
  checkStatusVideo: {
    url: `/VideoContainers/check/{videoId}/status`,
    method: 'get',
  },
  getFile: {
    url: `/Containers/${container}/files/{file}`,
    method: 'get',
  },
})

resource.getUrl = (file) => `${baseURL}/Containers/${container}/download/${file}`
resource.getImageUrl = (file, { width = 400, height = 400 }) =>
  `${baseURL}/Containers/${container}/cover/${file}?width=${width}&height=${height}`
resource.getVideo = (file) => `${baseURL}/Containers/${container}/playvideo/${file}`
resource.getRaw = (file) => `${baseURL}/Containers/${container}/raw/${file}`

export default resource
