import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from './style'
import ErrorBoundary from '../errorBoundary'

function PureIcon({ label, size, onClick, ...props }) {
  if (label) {
    return (
      <ErrorBoundary>
        <Icon size={size} {...props} onClick={onClick} />
        <span style={{ fontSize: 16, fontWeight: 700, color: '#253E87' }}>{label}</span>
      </ErrorBoundary>
    )
  } else {
    return (
      <ErrorBoundary>
        <Icon size={size} {...props} onClick={onClick} />
      </ErrorBoundary>
    )
  }
}

PureIcon.propTypes = {
  props: PropTypes.object,
}

PureIcon.defaultProps = {
  props: {},
}

export default PureIcon
