import React from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import TimePicker from 'rc-time-picker'
import { v4 as uuid } from 'uuid'
import 'rc-time-picker/assets/index.css'
import moment from 'moment'
import { connect } from 'react-redux'
import TrashIcon from '../../../../images/ic-bin-gray-24-px.svg'
import Modal from '../../../../components/modal/loadable'
import { ClasswinEditor, Button, Icon } from '../../../../components'
import { customModalStyle } from './modalStyles'
import Typography from '../../../../components/typography'

const validationSchema = yup.object().shape({
  username: yup.string().max(16),
  timeRequired: yup.string().nullable().when('isTimeRequired', {
    is: true,
    then: yup.string().required(),
  }),
  question: yup.object().when('isQuestionRequired', {
    is: true,
    then: yup.object().shape({
      choices: yup.array().min(1).required(),
    }),
  }),

  // password: yup
  //   .string()
  //   .min(8)
  //   .required(),
  // confirm_password: yup
  //   .string()
  //   .required()
  //   .oneOf([yup.ref('password'), null], 'Passwords must match'),
})
const Container = styled.div`
  min-width: 480px;
`

const Span = styled.span`
  margin-right: 15px;
`
const Content = styled.div`
  border-radius: 10px;
  background-color: ${(props) => props.theme.gray7};

  padding: 10px;
  margin: 10px 0 0 25px;
`

const DeleteQuestionButton = styled(Icon)`
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  float: right;
  margin: 0 5px 0 0;
  position: absolute;
  top: calc(50% - 11px);
  right: 0;
  cursor: pointer;
`
const EditorWrapper = styled.div`
  .ql-formats > button > svg {
    position: unset;
  }

  .editing-area .ql-container .ql-editor {
    min-height: 90px;
    max-width: 439px;
  }

  &.answer {
    .editing-area .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-radius: 6px;
    }
    position: relative;
    .ql-toolbar.ql-snow {
      display: none;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-top: 1px solid #ccc;
    }
    .editing-area .ql-container .ql-editor {
      min-height: 40px;
      padding-right: 25px;
    }
  }

  &.explainer {
    .editing-area .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-radius: 6px;
    }
    grid-column: 2;
    margin: 5px 0 0 0;
    padding: 0 0 0 7%;
    .ql-toolbar.ql-snow {
      display: none;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-top: 1px solid #ccc;
    }
    .editing-area .ql-container .ql-editor {
      min-height: 30px;
    }
    .ql-editor {
      padding: 8px 15px;
    }
  }
`

const SaveButton = styled(Button)`
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 12px 1fr;
  grid-gap: 0 10px;
  margin: 10px 0 0 0;
`
const Checkbox = (props) => {
  return (
    <Field name={props.name}>
      {({ field }) => (
        <Span>
          <input
            {...field}
            type="checkbox"
            checked={field.value}
            // onChange={e => field.onChange(e)}
            onChange={field.onChange}
          />
        </Span>
      )}
    </Field>
  )
}

const AnswerCheckbox = (props) => {
  return (
    <Field name={props.name}>
      {({ form }) => {
        return (
          <Span>
            <input
              // {...field}
              type="checkbox"
              checked={form.values.question.answer.includes(props.value)}
              onChange={() => {
                const set = new Set(form.values.question.answer)
                if (set.has(props.value)) {
                  set.delete(props.value)
                } else {
                  set.add(props.value)
                }
                form.setFieldValue(`question.answer`, Array.from(set))
                // form.setFieldTouched(`${field.name}`, true)
              }}
            />
          </Span>
        )
      }}
    </Field>
  )
}

const LectureSettingModal = (props) => {
  const handleAddChoice = (form) => {
    const { setFieldValue, values } = form
    const uid = uuid()
    const newChoices = {
      uid,
      content: JSON.stringify({ content: '', delta: { ops: [{ insert: '' }] } }),
      explainer: JSON.stringify({ content: '', delta: { ops: [{ insert: '' }] } }),
      // "{\"content\":\"<p>Wrong</p>\",\"delta\":{\"ops\":[{\"insert\":\"Wrong\\n\"}]}}"
    }
    setFieldValue('question.choices', [...values.question.choices, newChoices])
  }

  const handleDeleteChoice = (form, uid) => {
    const { setFieldValue, values } = form
    const newChoices = values.question.choices.filter((item) => item.uid !== uid)
    // const newAnswer = values.question.answer.filter((item) => item.uid !== uid)
    // setFieldValue('question.answer', newAnswer)
    setFieldValue('question.choices', newChoices)
    const set = new Set(values.question.answer)
    set.delete(uid)
    form.setFieldValue(`question.answer`, Array.from(set))
    form.setFieldTouched(`question.answer`, true)
  }

  const handleSubmit = async (values) => {
    const { updateLecture, subjectId, lectureId, success, error } = props
    const { enableCompleteButton, isTimeRequired, timeRequired, isQuestionRequired, question } =
      values
    const data = { subjectId, lectureId, enableCompleteButton }
    if (enableCompleteButton) {
      if (isTimeRequired) {
        data.isTimeRequired = isTimeRequired
        data.timeRequired = timeRequired
      } else {
        data.isTimeRequired = false
        data.timeRequired = null
      }
      if (isQuestionRequired) {
        data.isQuestionRequired = isQuestionRequired
        data.question = question
      } else {
        data.isQuestionRequired = false
        data.question = null
      }
    } else {
      data.isTimeRequired = false
      data.timeRequired = null
      data.isQuestionRequired = isQuestionRequired
      data.question = null
    }
    try {
      await updateLecture(data)
      success({ title: 'Update Successfully' })
    } catch (err) {
      error({ title: 'Update Failed' })
      throw err
    }
  }
  const { enableCompleteButton, isTimeRequired, timeRequired, isQuestionRequired, question } = props
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        overlayStyle={customModalStyle.overlay}
        contentStyle={customModalStyle.content}
        ariaHideApp={false}
      >
        <Container>
          <Formik
            initialValues={{
              enableCompleteButton: enableCompleteButton || false,
              isTimeRequired: isTimeRequired || false,
              timeRequired: timeRequired || '',
              isQuestionRequired: isQuestionRequired || false,
              question: question || {
                title: JSON.stringify({
                  content: '<p>add question</p>',
                }),
                choices: [
                  {
                    content: JSON.stringify({ content: '', delta: { ops: [{ insert: '' }] } }),
                    explainer: JSON.stringify({ content: '', delta: { ops: [{ insert: '' }] } }),
                    uid: uuid(),
                  },
                ],
                answer: [],
              },
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {(form) => (
              <>
                <SaveButton primary type="submit" onClick={form.submitForm}>
                  Save
                </SaveButton>
                <Typography h4 margin="0 0 10px 0">
                  Lecture Completation Rules
                </Typography>
                <Typography>
                  <Checkbox name="enableCompleteButton" />
                  click button to complete lecture
                </Typography>
                {form.values.enableCompleteButton && (
                  <Content>
                    <Typography>
                      <Checkbox name="isTimeRequired" />
                      set time required for completation
                    </Typography>
                    {form.values.isTimeRequired && (
                      <div style={{ padding: '10px 0', display: 'flex', alignItems: 'center' }}>
                        <TimePicker
                          // value={moment(`${this.state.min}:${this.state.sec}`, "mm:ss")}
                          // showHour
                          defaultOpenValue={moment('00:01', 'HH:mm')}
                          onChange={(e) => {
                            const value = e ? e.format() : e
                            form.setFieldValue('timeRequired', value)
                          }}
                          value={form.values.timeRequired ? moment(form.values.timeRequired) : null}
                          // value={moment('00:00', 'HH:mm')}
                          showSecond={false}
                          placeholder="HH:MM"
                          allowEmpty
                          // inputReadOnly={false}
                        />
                        <p style={{ color: '#c7c7c7', marginBottom: 0 }}>&nbsp;&nbsp;*hh:mm</p>
                      </div>
                    )}
                    <Typography>
                      <Checkbox name="isQuestionRequired" />
                      set a question
                    </Typography>
                    {form.values.isQuestionRequired && (
                      <>
                        <Typography
                          style={{ cursor: 'pointer' }}
                          padding="10px 0px"
                          // fontSize="text-sm"
                          strong
                        >
                          Question
                        </Typography>

                        <Field name="question.title">
                          {({ field }) => (
                            <EditorWrapper>
                              <ClasswinEditor
                                style={{ marginBottom: '0px' }}
                                id="title"
                                name="title"
                                modules={{
                                  toolbar: [
                                    // [{ header: 1 }, { header: 2 }],
                                    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    ['bold', 'italic'],
                                    // [{ color: [] }, { background: [] }],
                                    ['link', 'image', 'video'],
                                    ['code-block'],
                                  ],
                                }}
                                value={field.value}
                                onChange={(value) => {
                                  console.log('🏎', field.value)
                                  // field.onChange(value)
                                  form.setFieldValue(field.name, value)
                                }}
                              />
                            </EditorWrapper>
                          )}
                        </Field>
                        <Typography
                          style={{ cursor: 'pointer' }}
                          padding="10px 0px"
                          // fontSize="text-sm"
                          strong
                        >
                          Answers
                        </Typography>
                        <Button type="button" onClick={() => handleAddChoice(form)}>
                          Add Choice
                        </Button>
                        {form.values.question.choices.map((choice, index) => {
                          return (
                            <Grid key={index + ''}>
                              <AnswerCheckbox
                                name={`question.answer[${index}]`}
                                value={choice.uid}
                              />
                              <Field name={`question.choices[${index}].content`}>
                                {({ field }) => (
                                  <EditorWrapper className="answer">
                                    <ClasswinEditor
                                      style={{ marginBottom: '0px' }}
                                      id="title"
                                      name="title"
                                      modules={{
                                        toolbar: [['bold', 'italic'], ['code-block']],
                                      }}
                                      value={field.value}
                                      placeholder="Add an answer."
                                      onChange={(value) => {
                                        form.setFieldValue(field.name, value)
                                      }}
                                    />
                                    <DeleteQuestionButton
                                      src={TrashIcon}
                                      onClick={() => handleDeleteChoice(form, choice.uid)}
                                    />
                                  </EditorWrapper>
                                )}
                              </Field>
                              {/* <Button onClick={() => handleDeleteChoice(form, choice.uid)}>
                                delete
                              </Button> */}
                              <Field name={`question.choices[${index}].explainer`}>
                                {({ field }) => (
                                  <EditorWrapper className="explainer">
                                    <ClasswinEditor
                                      style={{ marginBottom: '0px' }}
                                      id="title"
                                      name="title"
                                      modules={{
                                        toolbar: [['bold', 'italic'], ['code-block']],
                                      }}
                                      value={field.value}
                                      placeholder="Explain why this is or isn't the best answer."
                                      onChange={(value) => {
                                        form.setFieldValue(field.name, value)
                                      }}
                                    />
                                  </EditorWrapper>
                                )}
                              </Field>
                            </Grid>
                          )
                        })}
                      </>
                    )}
                  </Content>
                )}
              </>
            )}
          </Formik>
        </Container>
      </Modal>
    </>
  )
}

const mapState = ({ Subject: { lectureInfo } }) => ({
  ...lectureInfo,
})
const mapDispatch = ({
  Subject: { getLectureInfo, updateLecture },
  Alert: { success, error },
}) => ({
  getLectureInfo,
  updateLecture,
  success,
  error,
})

export default connect(mapState, mapDispatch)(LectureSettingModal)
