import React from 'react'
import PropTypes from 'prop-types'
import { ButtonStyle } from './style'
import Typography from '../typography'

export function Button({
  id = '',
  children,
  fontStyle,
  isMobile = false,
  nowrap,
  marginText,
  values,
  ...props
}) {
  const childrenType = typeof children
  if (props.reverse) {
    return (
      <ButtonStyle {...props} disabled={props.disabled || props.primaryDisabled}>
        {childrenType === 'string' ? (
          <Typography
            id={id}
            style={{ display: 'flex', alignItems: 'center', ...fontStyle }}
            inherit
            margin={marginText}
            fontSize={props.fontSize || (isMobile ? 'text-xs' : 'text-base')}
            nowrap={nowrap}
            values={values}
          >
            {children}
          </Typography>
        ) : (
          children
        )}
        {props.icon && props.icon}
        {props.image && props.image}
      </ButtonStyle>
    )
  } else {
    return (
      <ButtonStyle {...props} disabled={props.disabled || props.primaryDisabled}>
        {props.image && props.image}
        {props.icon && props.icon}
        {childrenType === 'string' ? (
          <Typography
            id={id}
            inherit
            margin={marginText}
            fontSize={props.fontSize || (isMobile ? 'text-xs' : 'text-base')}
            style={{ display: 'flex', alignItems: 'center', ...fontStyle }}
            nowrap={nowrap}
            values={values}
          >
            {children}
          </Typography>
        ) : (
          children
        )}
        {props.badge && props.badge}
      </ButtonStyle>
    )
  }
}

Button.propTypes = {
  id: PropTypes.string,
  values: PropTypes.object,
  children: PropTypes.node,
  fontStyle: PropTypes.object,
  props: PropTypes.object,
}

Button.defaultProps = {
  props: {},
  children: null,
}

export default Button
