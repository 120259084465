/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { ClasswinEditor, Button, Typography } from 'components'
import ChoiceInput from 'components/ArrangementChoiceInput'
import ArrangementAnswerInput from 'components/ArrangementAnswerInput'
import PlusIcon from 'images/icons/primary-plus.svg'

const Arrangement = ({
  title,
  inputChange,
  assessmentId,
  id: questionId,
  getQuestionData,
  handleAddChoice,
  updateQuestion,
  answers,
  handleDeleteChoice,
  choices = [],
  handleAutoSave,
  locale,
  debounceInput,
  value,
  type,
  setTitle,
  setChoices,
  setAnswers,
}) => {
  const [input, setInput] = useState([])

  const handleSubmit = () => {
    debounceInput(value, type)
  }

  return (
    <Fragment>
      <Formik initialValues={{ title }} enableReinitialize>
        {() => (
          <Form>
            <Typography id="label.question" color="primary1" margin="0 0 8px">
              Question
            </Typography>
            <Field name="title">
              {({ field, form }) => (
                <ClasswinEditor
                  style={{ marginBottom: '0px' }}
                  isShowAll={false}
                  id="title"
                  name="title"
                  value={field.value}
                  placeholder={locale === 'th' ? 'คำถาม' : 'Question'}
                  onChange={(value) => {
                    const e = {
                      target: {
                        name: 'title',
                        value,
                      },
                    }
                    form.handleChange(e)
                    setTitle(value)
                  }}
                />
              )}
            </Field>
          </Form>
        )}
      </Formik>

      {choices.length > 0 && (
        <Typography id="course.content.answer" color="primary1" margin="12px 0 8px">
          Answer(s)
        </Typography>
      )}
      {choices.map((item, index) => (
        <ChoiceInput
          key={`${index}`}
          choices={choices}
          choice={item}
          index={index}
          assessmentId={assessmentId}
          questionId={questionId}
          updateQuestion={updateQuestion}
          handleDeleteChoice={handleDeleteChoice}
          getQuestionData={getQuestionData}
          handleAutoSave={handleAutoSave}
          setChoices={setChoices}
        />
      ))}
      <Button
        id="button.add.choice"
        type="button"
        image={<img src={PlusIcon} alt="plus-icon" />}
        disabled={choices?.length >= 8}
        onClick={handleAddChoice}
        whiteBorder
        margin={choices.length > 0 ? '4px 0 0' : '16px 0 0'}
      >
        Add Choice
      </Button>
      <ArrangementAnswerInput
        choices={choices}
        answers={answers}
        assessmentId={assessmentId}
        questionId={questionId}
        updateQuestion={updateQuestion}
        getQuestionData={getQuestionData}
        handleAutoSave={handleAutoSave}
        handleSubmitTitle={handleSubmit}
        setInput={setInput}
        input={input}
        setAnswers={setAnswers}
      />
    </Fragment>
  )
}

const mapState = ({ Language: { locale } }) => ({ locale })

export default connect(mapState)(Arrangement)
