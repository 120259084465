import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { ScheduleDateTime, Item, Line, Icon } from './style'
import CalendaIcon from '../../images/ic-calenda.svg'
import TimeIcon from '../../images/ic-time.svg'
import ErrorBoundary from '../errorBoundary'

class PureScheduleDateTime extends React.Component {
  state = {
    date: null,
    time: null,
  }

  render() {
    const { onChange } = this.props
    const { date, time } = this.state
    return (
      <ErrorBoundary>
        <ScheduleDateTime>
          <Item>
            <Icon src={CalendaIcon} />
            <DatePicker
              selected={date}
              onChange={(value) => {
                this.setState({ date: value }, () => {
                  // Return format date is YYYY-MM-DDT
                  const date = moment(value).format('YYYY-MM-DDT')
                  onChange(date, 'date')
                })
              }}
              className="date-time"
              dateFormat="dd MMM"
              placeholderText="Date"
              minDate={Date.now()}
            />
          </Item>
          <Line />
          <Item>
            <Icon src={TimeIcon} />
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              selected={time}
              onChange={(value) => {
                this.setState({ time: value }, () => {
                  // Return format time is HH:mm:ssZ
                  const time = moment(value).format('HH:mm:ssZ')
                  onChange(time, 'time')
                })
              }}
              className="date-time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              timeIntervals={30}
              placeholderText="Time"
            />
          </Item>
        </ScheduleDateTime>
      </ErrorBoundary>
    )
  }
}

PureScheduleDateTime.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default PureScheduleDateTime
