import React from 'react'
import { connect } from 'react-redux'
import CurrencyReactSelect from 'components/currencyReactSelect'
import { Typography } from 'components'
// import getCoursePrice from '../../utils/getCoursePrice'
import {
  AddButton,
  Content,
  Row,
  // TextLabel,
  // Description,
  DeleteButton,
  DeleteImage,
  // GridPackage as Grid,
  Icon,
} from './styled'
import DeleteIcon from '../../images/icons/Trash1.svg'
import PlusIcon from '../../images/icons/plus.svg'

const PackageCourse = (props) => {
  const {
    formikProps,
    onChangePackage,
    Currency,
    People,
    addPackage,
    deletePackage,
    // mySchoolDetail = {},
  } = props
  // const { name: schoolName, logo: schoolLogo } = mySchoolDetail

  return (
    <Content style={{ paddingTop: 16 }}>
      <Row>
        <div>
          <Typography
            id="course.tuitionfee.package"
            fontSize="text-sm"
            color="primary1"
            margin="0 0 16px 0"
          >
            Packages
          </Typography>
          {/* <Description id="course.tuitionfee.package.desc">
            Set a multiple price for a group of learners
          </Description> */}
        </div>
      </Row>
      <div>
        {(formikProps.values.packagePrice || []).map(({ quantity, price }, index) => {
          return (
            <Row
              key={`${index}`}
              style={{
                // height: 53,
                alignItems: 'baseline',
              }}
            >
              <CurrencyReactSelect
                style={{
                  display: 'grid',
                  gridTemplateColumns: '64px 1fr',
                  flexGrow: 1,
                  marginBottom: 12,
                  marginRight: 10,
                }}
                name={`packagePrice[${index}].quantity`}
                value={quantity}
                onChange={onChangePackage('quantity')(index)}
                onBlur={formikProps.setFieldTouched}
                error={formikProps.errors.packagePrice?.[index]?.quantity}
                touched={formikProps.touched.packagePrice?.[index]?.quantity}
                SelectHeader={People}
                placeholder="Learner"
              />
              <CurrencyReactSelect
                style={{
                  display: 'grid',
                  gridTemplateColumns: '64px 1fr',
                  flexGrow: 1,
                  marginBottom: 12,
                }}
                name={`packagePrice[${index}].price`}
                value={price}
                onChange={onChangePackage('price')(index)}
                onBlur={formikProps.setFieldTouched}
                error={formikProps.errors.packagePrice?.[index]?.price}
                touched={formikProps.touched.packagePrice?.[index]?.price}
                SelectHeader={Currency}
                placeholder="THB"
              />
              <DeleteButton
                type="button"
                className="deleteButton"
                onClick={() => deletePackage(index)}
              >
                <DeleteImage src={DeleteIcon}></DeleteImage>
              </DeleteButton>
            </Row>
          )
        })}
        <AddButton type="button" onClick={addPackage}>
          <Icon src={PlusIcon} alt="plus-icon" />
          <Typography fontSize="text-sm" color="primary1" id="profile.intro.add.package">
            Add Package
          </Typography>
        </AddButton>
      </div>
    </Content>
  )
}

// const mapState = ({ MySchool: { mySchoolDetail } }) => ({ mySchoolDetail })
const mapState = () => ({})

export default connect(mapState)(PackageCourse)
