import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import { Typography } from '../'

class Loader extends React.Component {
  render() {
    const { height = '90vh' } = this.props

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: height,
        }}
      >
        <BeatLoader color={'#5A69FE'} margin={5} size={25} />
        <Typography id="verifyEmail.loading" strong fontSize="text-lg" color="dark1">
          Please wait a moment
        </Typography>
      </div>
    )
  }
}

export default Loader
