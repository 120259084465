//import dashboardIcon from '../../images/ChartPieSlice.svg'
//import curriculumIcon from '../../images/MonitorPlay.svg'
//import paymentIcon from '../../images/CreditCard.svg'
// import rewardIcon from '../../images/ic-point-rewards-white-24-px.svg'
//import certificateIcon from '../../images/ChalkboardTeacher.svg'
//import memberIcon from '../../images/Users.svg'
// import schoolIcon from '../../images/Classwin - New icon_7-School Information.svg'
//import reportIcon from '../../images/Article.svg'
//import InformationIcon from '../../images/Info.svg'
//import packagesIcon from '../../images/CurrencyCircleDollar.svg'
// import MarketplaceIconInactive from '../../images/marketplacesvg.svg'

import DashBoardDefault from '../../images/teacherMenu/Dashboard-Default.svg'
import DashBoardActive from '../../images/teacherMenu/Dashboard-Active.svg'
import CourseDefault from '../../images/teacherMenu/Course-Default.svg'
import CourseActive from '../../images/teacherMenu/Course-Active.svg'
import MemberDefault from '../../images/teacherMenu/Member-Default.svg'
import MemberActive from '../../images/teacherMenu/Member-Active.svg'
import PaymentDefault from '../../images/teacherMenu/Payment-Default.svg'
import PaymentActive from '../../images/teacherMenu/Payment-Active.svg'
import CertificateDefault from '../../images/teacherMenu/Certificate-Default.svg'
import CertificateActive from '../../images/teacherMenu/Certificate-Active.svg'
import ReportDefault from '../../images/teacherMenu/Report-Default.svg'
import ReportActive from '../../images/teacherMenu/Report-Active.svg'
import InformationDefault from '../../images/teacherMenu/Information-Default.svg'
import InformationActive from '../../images/teacherMenu/Information-Active.svg'
import PackagesDefault from '../../images/teacherMenu/Packages-Default.svg'
import PackagesActive from '../../images/teacherMenu/Packages-Active.svg'
export const schoolMenus = (schoolId) => [
  // {
  //   label: 'Manage Academy',
  //   link: `/teacher/connections/school`,
  //   icon: schoolIcon,
  //   roles: ['owner', 'admin', 'member'],
  // },
  {
    id: 'instructor.menu.dashboard',
    label: 'Dashboard',
    link: `/schools/${schoolId}/dashboard`,
    icon: DashBoardDefault,
    iconActive: DashBoardActive,
    roles: ['owner', 'admin', 'member'],
  },
  {
    id: 'instructor.menu.course',
    label: 'Course',
    link: `/schools/${schoolId}/curriculum`,
    icon: CourseDefault,
    iconActive: CourseActive,
    roles: ['owner', 'admin', 'member'],
  },
  {
    id: 'instructor.menu.member',
    label: 'Member',
    link: `/schools/${schoolId}/member`,
    icon: MemberDefault,
    iconActive: MemberActive,
    roles: ['owner'],
  },
  {
    id: 'instructor.menu.payment',
    label: 'Payment',
    link: `/schools/${schoolId}/payment`,
    icon: PaymentDefault,
    iconActive: PaymentActive,
    roles: ['owner'],
  },
  {
    id: 'instructor.menu.certificate',
    label: 'Certificate',
    link: `/schools/${schoolId}/certificate`,
    icon: CertificateDefault,
    iconActive: CertificateActive,
    roles: ['owner', 'admin'],
  },
  {
    id: 'instructor.menu.report',
    label: 'Report',
    link: `/schools/${schoolId}/report`,
    icon: ReportDefault,
    iconActive: ReportActive,
    roles: ['owner', 'admin'],
  },
  {
    id: 'instructor.menu.infomation',
    label: 'Information',
    link: `/schools/${schoolId}/information`,
    icon: InformationDefault,
    iconActive: InformationActive,
    roles: ['owner'],
  },
  // {
  //   label: 'Forums',
  //   link: `/schools/${schoolId}/forums`,
  //   icon: profileIcon,
  //   roles: ['owner', 'admin', 'member'],
  // },
  {
    id: 'instructor.menu.packages',
    label: 'Packages',
    link: `/schools/${schoolId}/packages`,
    icon: PackagesDefault,
    iconActive: PackagesActive,
    roles: ['owner', 'admin'],
  },
  // {
  //   id: 'instructor.menu.marketplace',
  //   label: 'Marketplace',
  //   link: `/schools/${schoolId}/marketplace`,
  //   icon: MarketplaceIconInactive,
  //   roles: ['owner', 'admin'],
  // },
]
