import styled from 'styled-components'
import theme from '../../theme'
import { Container } from '../index'

const ContainerCwEditor = styled(Container)`
  border: 1px solid ${theme.grey};
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;

  :hover {
    opacity: 0.8;
    border-color: gray;
  }
`
export { ContainerCwEditor }
