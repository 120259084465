import Resource from '../utils/resource'

export default new Resource('/ScheduleMeta', {
  getScheduleMeta: {
    url: '?filter={filter}',
    method: 'get',
  },
  setScheduleMeta: {
    url: '',
    method: 'put',
  },
  updateScheduleMeta: {
    url: '{id}',
    method: 'patch',
  },
  removeScheduleMetaByID: {
    url: '{id}',
    method: 'delete',
  },
})
