import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IoIosArrowBack } from 'react-icons/io'
import Typography from '../../components/typography'
import theme from 'theme'

const CenterButton = styled.div`
  /* width: 50%; */
  margin: auto;
`
const Container = ({ children, onClick, from }) => {
  if (onClick) {
    return (
      <div style={{ marginTop: 'auto', cursor: 'pointer' }} onClick={onClick}>
        {children}
      </div>
    )
  }
  return (
    <Link
      style={{ marginTop: 'auto' }}
      to={{
        pathname: '/user/login',
        state: { from },
      }}
    >
      {children}
    </Link>
  )
}

function BackToLoginButton({ from, style, onClick }) {
  return (
    <div style={style}>
      <Container onClick={onClick} from={from}>
        <CenterButton>
          <Typography
            id="signup.goto.login"
            color="primary1"
            icon={
              <IoIosArrowBack
                style={{ color: theme.colors.secondary, fill: theme.colors.secondary }}
              />
            }
          >
            Go to Login page
          </Typography>
        </CenterButton>
      </Container>
    </div>
  )
}

export default BackToLoginButton
