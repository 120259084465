import React from 'react'
import styled, { css } from 'styled-components'
import { SmallMobile, TabletAndLargeTablet } from '../../utils/displayResponsive'
import Typography from '../../components/typography'
import Button from '../../components/button'
import Avatar from '../../components/avatar'
import getImageUrl from '../../utils/getImageUrl'
import CourseLink from 'components/courseLink/loadable'
import { useRouteMatch, useHistory } from 'react-router-dom'

const Content = styled.div`
  padding: 16px 16px 0px;
  // height: 170px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 16px 50px 0;
  }
`

const Name = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  @media (max-width: 1024px) {
    width: 550px;
  }
  @media (max-width: 430px) {
    width: 180px;
  }
`
const Description = styled(Typography)`
  /* // height: 32px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  @media (max-width: 1024px) {
    width: 450px;
  }
  @media (max-width: 430px) {
    width: 180px;
  }
  /* -webkit-line-clamp: 2; */
`

const Image = styled.div`
  height: 180px;
  background-image: ${(props) => `url(${props.src})`};
  background-position: center;
  background-size: cover;
  /* border-radius: 10px 10px 0 0; */
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 270px;
  }
`
const Hilight = styled(Typography)`
  ${({ schoolLink }) => {
    if (schoolLink) {
      return css`
        cursor: pointer;
      `
    }
    return css``
  }}
  min-width: 120px;
  max-width: 170px;
  padding-left: 10px;

  &.school {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (max-width: 500px) {
    min-width: 100px;
  }

  @media (max-width: 400px) {
    min-width: 80px;
  }
`

const Row = styled.div`
  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`
const AvatarContainer = styled.div`
  display: flex;
  @media (max-width: 430px) {
    padding: 10px 0px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 0 16px;
  }
`
const CourseLinkContainer = styled.div`
  @media (min-width: 769px) and (max-width: 1024px) {
    padding-top: 16px;
  }
`

const TabletDetail = ({ course, data }) => {
  const match = useRouteMatch()
  const history = useHistory()
  return (
    <>
      <SmallMobile>
        <div>
          <Image
            src={getImageUrl(
              course.flatImage || course.image,
              'https://via.placeholder.com/350x150'
            )}
          />
          <Content>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '100%', paddingRight: 20 }}>
                  <Name h4>{data.title}</Name>
                  <Description label2 seeMore numberOfLines={2}>
                    {data.shortDescription}
                  </Description>
                </div>
                <Button
                  id="button.course.setting"
                  primary
                  style={{ width: 220 }}
                  onClick={() => history.push(`${match.url}/setting`)}
                >
                  Course setting
                </Button>
              </div>
              <Row>
                <AvatarContainer>
                  <Avatar size="small" src={data.teacherImage} marginRight="10"></Avatar>
                  <div>
                    <Hilight
                      color="black"
                      onClick={
                        data.teacherId === undefined
                          ? null
                          : () => this.props.history.push(`/users/${data.teacherSlug}`)
                      }
                      schoolLink={data.teacherId !== undefined}
                    >
                      {data.teacherName}
                    </Hilight>
                    <Typography
                      id="label.instructor"
                      padding="4px 0px 0px 10px"
                      h6
                      color="primary1"
                    >
                      Instructor
                    </Typography>
                  </div>
                </AvatarContainer>
                <AvatarContainer>
                  <Avatar size="small" src={data.schoolLogo} marginRight="10"></Avatar>
                  <div>
                    <Hilight
                      className="school"
                      color="black"
                      onClick={() => this.props.history.push(`/${data.schoolSlug}`)}
                      schoolLink
                    >
                      {data.schoolName}
                    </Hilight>
                    <Typography id="label.academy" padding="4px 0px 0px 10px" h6 color="primary1">
                      Academy
                    </Typography>
                  </div>
                </AvatarContainer>
              </Row>
              <CourseLinkContainer>
                <CourseLink courseLink={`${window.location.host}/courses/${course.slug}`} />
              </CourseLinkContainer>
            </div>
          </Content>
        </div>
      </SmallMobile>
      <TabletAndLargeTablet>
        <div>
          <Image
            src={getImageUrl(
              course.flatImage || course.image,
              'https://via.placeholder.com/350x150'
            )}
          />
          <Content>
            <div>
              <Name h4>{data.title}</Name>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Description label2 seeMore numberOfLines={3}>
                    {data.shortDescription}
                  </Description>
                  <CourseLinkContainer>
                    <CourseLink courseLink={`${window.location.host}/courses/${course.slug}`} />
                  </CourseLinkContainer>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 50 }}>
                  <AvatarContainer>
                    <Avatar size="small" src={data.teacherImage} marginRight="10"></Avatar>
                    <div>
                      <Hilight
                        color="black"
                        onClick={
                          data.teacherId === undefined
                            ? null
                            : () => this.props.history.push(`/users/${data.teacherSlug}`)
                        }
                        schoolLink={data.teacherId !== undefined}
                      >
                        {data.teacherName}
                      </Hilight>
                      <Typography
                        id="label.instructor"
                        padding="4px 0px 0px 10px"
                        h6
                        color="primary1"
                      >
                        Instructor
                      </Typography>
                    </div>
                  </AvatarContainer>
                  <AvatarContainer>
                    <Avatar size="small" src={data.schoolLogo} marginRight="10"></Avatar>
                    <div>
                      <Hilight
                        className="school"
                        color="black"
                        onClick={() => this.props.history.push(`/${data.schoolSlug}`)}
                        schoolLink
                      >
                        {data.schoolName}
                      </Hilight>
                      <Typography id="label.academy" padding="4px 0px 0px 10px" h6 color="primary1">
                        Academy
                      </Typography>
                    </div>
                  </AvatarContainer>
                </div>
              </div>
              <Row></Row>
              <Button
                id="button.course.setting"
                primary
                style={{ width: 150, margin: '16px 0 0' }}
                onClick={() => history.push(`${match.url}/setting`)}
              >
                Course setting
              </Button>
            </div>
          </Content>
        </div>
      </TabletAndLargeTablet>
    </>
  )
}

export default TabletDetail
