import React, { useState } from 'react'
import _ from 'lodash'

import Up from '../../images/ic-sort-up.svg'
import UpSelect from '../../images/ic-sort-up-select.svg'
import Down from '../../images/ic-sort-down.svg'
import DownSelect from '../../images/ic-sort-down-select.svg'
import Typography from '../typography'

const MainTable = ({ title, data, setNewData, sorter }) => {
  const [sort, setsort] = useState(0)
  const onClick = () => {
    var newData = _.cloneDeep(data)
    if (sort === 0) {
      newData = _.orderBy(newData, sorter)
    } else if (sort === 1) {
      newData = _.orderBy(newData, sorter, 'desc')
    }

    setsort(sort === 1 ? 0 : sort + 1)
    setNewData(newData)
  }
  return (
    <div onClick={onClick} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography>{title}</Typography>
      <div style={{ display: 'grid', marginLeft: 10, cursor: 'pointer' }}>
        <img src={sort === 1 ? UpSelect : Up} style={{ width: 10 }} alt="" />
        <img src={sort === 2 ? DownSelect : Down} style={{ width: 10 }} alt="" />
      </div>
    </div>
  )
}

export default MainTable
