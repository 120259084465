export const menus = [
  {
    label: 'Overview',
    key: '0',
    component: null,
    path: '/overview',
  },
  {
    label: 'Setting',
    key: '1',
    component: null,
    path: '/setting',
  },
  {
    label: 'Report',
    key: '2',
    component: null,
    path: '/report',
  },
]
