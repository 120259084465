export default (params) => {
  const {
    searchedWord,
    categories = [],
    subCategories = [],
    levels = [],
    types = [],
    prices = [],
    ageType,
  } = params
  const filterObj = {
    where: {
      and: [
        {
          publicMode: 'PUBLIC',
          ageType,
        },
      ],
    },
  }
  // const filterString = JSON.stringify(filterObj)
  if (searchedWord) {
    const or = [
      {
        name: {
          like: searchedWord,
          options: 'i',
        },
      },
      {
        description: {
          like: searchedWord,
          options: 'i',
        },
      },
      {
        shortDescription: {
          like: searchedWord,
          options: 'i',
        },
      },
    ]
    filterObj.where.and = [...filterObj.where.and, { or }]
  }
  if (types.length > 0) {
    const or = types.map((item) => ({
      type: item,
    }))
    filterObj.where.and = [...filterObj.where.and, { or }]
  }
  if (prices.length > 0) {
    const or = prices.map((item) => {
      if (item === 'free') {
        return {
          price: 0,
        }
      }
      return {
        price: {
          gt: 0,
        },
      }
    })
    filterObj.where.and = [...filterObj.where.and, { or }]
  }
  if (levels.length > 0) {
    const or = levels.map((item) => {
      return {
        levelId: item,
      }
    })
    filterObj.where.and = [...filterObj.where.and, { or }]
  }
  if (categories.length > 0) {
    const or = categories.map((item) => {
      return {
        categoryId: item,
      }
    })
    filterObj.where.and = [...filterObj.where.and, { or }]
  }
  if (subCategories.length > 0) {
    const or = subCategories.map((item) => {
      return {
        subCategoryId: item,
      }
    })
    filterObj.where.and = [...filterObj.where.and, { or }]
  }
  // if (JSON.stringify(filterObj) === filterString) {
  //   return {}
  // }
  return filterObj
}
