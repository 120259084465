/* eslint-disable no-nested-ternary */
import React from 'react'
import ErrorBoundary from '../../components/errorBoundary'
import { ClasswinEditor, Input, Label, Textarea } from '../../components'

const FieldInput = ({
  id,
  inputId,
  translateId = '',
  field,
  label,
  type = 'text',
  quillStyle,
  form,
  maxLength,
  counter,
  colorLabel,
  placeholder,
  isRequired = false,
  height,
}) => (
  <ErrorBoundary>
    {type === 'text' ? (
      <div style={{ marginBottom: '20px' }}>
        <Label id={id} htmlFor={label} isRequired={isRequired} color={colorLabel}>
          {label}
        </Label>
        <Input
          height={height}
          id={id}
          inputId={inputId}
          type="text"
          status={form.errors[field.name] && form.touched[field.name] && 'error'}
          messageStatus={form.errors[field.name]}
          counter={counter}
          maxLength={maxLength}
          placeholder={placeholder}
          {...field}
        />
      </div>
    ) : type === 'textarea' ? (
      <div>
        <ClasswinEditor
          translateId={translateId}
          quillStyle={quillStyle}
          label={label}
          value={field.value}
          isShowAll={false}
          minModules
          // showToolbar={false}
          onChange={(value) => {
            const e = {
              target: {
                name: field.name,
                value,
              },
            }
            field.onChange(e)
          }}
        />
      </div>
    ) : type === 'Subtitle' ? (
      <div style={{ marginBottom: '20px' }}>
        <Label htmlFor={label}>{label}</Label>
        <Input
          id={id}
          name="shortDescription"
          maxLength="200"
          // value={values.shortDescription}
          counter
          status={form.errors[field.name] && form.touched[field.name] && 'error'}
          messageStatus={form.errors[field.name]}
          // onChange={handleChange}
          {...field}
        />
      </div>
    ) : type === 'textareaDesc' ? (
      <div style={{ marginBottom: '20px' }}>
        <Label id={id} htmlFor={label} isRequired={isRequired} color={colorLabel}>
          {label}
        </Label>
        <Textarea
          height={height}
          id={id}
          inputId={inputId}
          status={form.errors[field.name] && form.touched[field.name] && 'error'}
          messageStatus={form.errors[field.name]}
          counter={counter}
          showCount
          maxLength="100"
          placeholder={placeholder}
          {...field}
        />
      </div>
    ) : (
      <input type="file" {...field} />
    )}
  </ErrorBoundary>
)

export default FieldInput
