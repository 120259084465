/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
// import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Flex } from 'rebass'
import TextInput from '../textInput'
// import CarouselAntd from 'components/CarouselAntd'

import Checkbox from '../checkbox'
import ErrorBoundary from '../errorBoundary'
import Button from '../button'
import Modal from '../modal'
import DialogCondition from '../dialogCondition'
import {
  PasswordInput,
  Grid,
  Space,
  Container,
  FormContainer,
  CustomLink,
  ContainerLogo,
  ClasswinLogo,
  ContainerLanguage,
} from './styles'
import Typography from '../typography'
import Eye from '../../images/icons/Eye.svg'
import EyeSlash from '../../images/icons/EyeSlash.svg'
// import { LargeDesktop } from 'utils/displayResponsive'
import BackToLoginButton from 'components/BackToLogin'
import { connect } from 'react-redux'
// import { ImageConverter } from 'utils/ImageConverter'
import LogoClasswin from '../../images/icons/Classwin logo_Classwin Horizontal - Blue 2.svg'
import { DesktopAndTablet, SmallMobile } from 'utils/displayResponsive'
import ReCAPTCHA from 'react-google-recaptcha'

export class SignUpForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    onSubmit: () => {},
  }

  state = {
    isShowConfirmPassword: true,
    isShowPassword: true,
    showCondition: false,
    type: '',
  }

  handleShowPassword = () => {
    const { isShowPassword } = this.state
    this.setState({ isShowPassword: !isShowPassword })
  }

  handleShowConfirmPassword = () => {
    const { isShowConfirmPassword } = this.state
    this.setState({ isShowConfirmPassword: !isShowConfirmPassword })
  }

  handleModal = (type) => (e) => {
    e.preventDefault()
    this.setState(({ showCondition }) => ({ showCondition: !showCondition, type }))
  }
  onChangeLocale = (values) => {
    const { updateLocale } = this.props
    updateLocale(values)
  }
  renderLabel = () => {
    const { locale } = this.props
    return (
      <>
        I have read and accept{' '}
        <CustomLink onClick={this.handleModal('agreement')}>
          {locale === 'th' ? 'เงื่อนไขการใช้งาน' : 'Service Agreement'}
        </CustomLink>{' '}
        and{' '}
        <CustomLink onClick={this.handleModal('policy')}>
          {locale === 'th' ? 'นโยบายความเป็นส่วนตัว' : 'Private Policy'}
        </CustomLink>
        .
      </>
    )
  }

  render() {
    const { onSubmit, setTypeModal, locale, isModal } = this.props
    const { type, showCondition, isShowConfirmPassword, isShowPassword, from } = this.state

    return (
      <ErrorBoundary>
        <DesktopAndTablet>
          <div styled={{ backgroundsColor: '#fff', zIndex: '1000' }}>
            <ContainerLogo>
              <ClasswinLogo src={LogoClasswin} alt="logo" shape-rendering="crispEdges" />
            </ContainerLogo>
            {!isModal && (
              <ContainerLanguage>
                <Typography
                  color={locale === 'th' ? 'primary1' : 'black38'}
                  onClick={() => this.onChangeLocale('th')}
                  style={{ cursor: 'pointer' }}
                >
                  TH
                </Typography>
                <Typography color="black38">|</Typography>
                <Typography
                  color={locale === 'en' ? 'primary1' : 'black38'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.onChangeLocale('en')}
                >
                  EN
                </Typography>
              </ContainerLanguage>
            )}
          </div>
        </DesktopAndTablet>
        <SmallMobile>
          <div styled={{ backgroundsColor: '#fff', zIndex: '1000' }}>
            <ContainerLogo>
              <ClasswinLogo src={LogoClasswin} alt="logo" shape-rendering="crispEdges" />
            </ContainerLogo>
            {!isModal && (
              <ContainerLanguage>
                <Typography
                  color={locale === 'th' ? 'primary1' : 'black38'}
                  onClick={() => this.onChangeLocale('th')}
                  style={{ cursor: 'pointer' }}
                >
                  TH
                </Typography>
                <Typography color="black38">|</Typography>
                <Typography
                  color={locale === 'en' ? 'primary1' : 'black38'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.onChangeLocale('en')}
                >
                  EN
                </Typography>
              </ContainerLanguage>
            )}
          </div>
        </SmallMobile>
        <Container>
          {/* <Mobile>
          <ImageBox isModal={isModal}>
            <Image src={LogoClasswind} alt="logo" />
          </ImageBox>
        </Mobile> */}
          {/* <LargeDesktop>
            <CarouselAntd autoPlay items={ImageConverter(locale)} heightImage="580px" />
          </LargeDesktop> */}

          <FormContainer isModal={isModal}>
            <Typography id="signup.title" h2 padding="8px 0px 16px">
              Sign Up
            </Typography>
            <Formik
              initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                confirmPassword: '',
                isAcceptedTerm: false,
                token: '',
              }}
              validationSchema={Yup.object().shape({
                firstname: Yup.string().required(
                  <Typography
                    style={{ textTransform: 'none' }}
                    id="signup.alert.required"
                    fontSize="text-xs"
                    color="danger"
                  >
                    Required
                  </Typography>
                ),
                lastname: Yup.string().required(
                  <Typography
                    style={{ textTransform: 'none' }}
                    id="signup.alert.required"
                    fontSize="text-xs"
                    color="danger"
                  >
                    Required
                  </Typography>
                ),
                email: Yup.string()
                  .email(
                    <Typography
                      style={{ textTransform: 'none' }}
                      id="signup.alert.email"
                      fontSize="text-xs"
                      color="danger"
                    >
                      Email is incorrect. Please try again.
                    </Typography>
                  )
                  .required(
                    <Typography
                      style={{ textTransform: 'none' }}
                      id="signup.alert.required"
                      fontSize="text-xs"
                      color="danger"
                    >
                      Required
                    </Typography>
                  ),
                password: Yup.string()
                  .min(
                    8,
                    <Typography
                      style={{ textTransform: 'none' }}
                      id="signup.alert.minpassword"
                      fontSize="text-xs"
                      color="danger"
                    >
                      The password must contain at least 8 characters.
                    </Typography>
                  )
                  .required(
                    <Typography
                      style={{ textTransform: 'none' }}
                      id="signup.alert.required"
                      fontSize="text-xs"
                      color="danger"
                    >
                      Required
                    </Typography>
                  ),
                confirmPassword: Yup.string().oneOf(
                  [Yup.ref('password'), null],
                  <Typography
                    style={{ textTransform: 'none' }}
                    id="signup.alert.matchpassword"
                    fontSize="text-xs"
                    color="danger"
                  >
                    The password must be match.
                  </Typography>
                ),
                isAcceptedTerm: Yup.boolean().oneOf(
                  [true],
                  <Typography
                    style={{ textTransform: 'none' }}
                    id="signup.alert"
                    fontSize="text-xs"
                    color="danger"
                  >
                    You must accept service agreement and private policy.
                  </Typography>
                ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                console.log('values', values)
                const data = values
                delete data.confirmPassword
                delete data.isAcceptedTerm
                onSubmit(data)
                setSubmitting(false)
              }}
            >
              {(props) => {
                const { values, errors, handleChange, setFieldValue } = props
                return (
                  <Form>
                    <div style={{ marginBottom: 20, marginLeft: 5 }}>
                      <Typography style={{ color: '#A9B6C6' }} id="signup.label" label2>
                        (Please enter your fullname to appears on your certificate)
                      </Typography>
                    </div>
                    <Flex justifyContent="space-between">
                      <Grid>
                        <TextInput
                          id="signup.firstname"
                          name="firstname"
                          label="First name"
                          value={values.firstname}
                          error={errors.firstname}
                          data-testid="signup-form-firstname-input"
                          onChange={handleChange}
                          placeholder={locale === 'th' ? 'ชื่อ' : 'First name'}
                          containerStyle={{ flex: 1, marginRight: 10 }}
                          isLogin
                          full
                        />
                      </Grid>
                      <Space />
                      <Grid>
                        <TextInput
                          id="signup.lastname"
                          name="lastname"
                          label="Last name"
                          value={values.lastname}
                          error={errors.lastname}
                          data-testid="signup-form-lastname-input"
                          onChange={handleChange}
                          containerStyle={{ flex: 1 }}
                          placeholder={locale === 'th' ? 'นามสกุล' : 'Last name'}
                          isLogin
                          full
                        />
                      </Grid>
                    </Flex>
                    <TextInput
                      id="signup.email"
                      value={values.email}
                      error={errors.email}
                      name="email"
                      data-testid="signup-form-email-input"
                      placeholder="example@email.com"
                      onChange={handleChange}
                      isLogin
                      full
                    />
                    <PasswordInput>
                      <TextInput
                        id="signup.password"
                        value={values.password}
                        error={errors.password}
                        data-testid="signup-form-password-input"
                        type={isShowPassword ? 'password' : 'text'}
                        name="password"
                        placeholder="*********"
                        onChange={handleChange}
                        isLogin
                        full
                      />
                      {isShowPassword && (
                        <img
                          src={Eye}
                          className="icon"
                          alt="showPassword"
                          onClick={this.handleShowPassword}
                        />
                      )}
                      {!isShowPassword && (
                        <img
                          src={EyeSlash}
                          className="icon"
                          alt="hidePassword"
                          onClick={this.handleShowPassword}
                        />
                      )}
                    </PasswordInput>
                    <PasswordInput>
                      <TextInput
                        id="signup.confirm.password"
                        value={values.confirmPassword}
                        error={errors.confirmPassword}
                        data-testid="signup-form-confirmpassword-input"
                        type={isShowConfirmPassword ? 'password' : 'text'}
                        name="confirmPassword"
                        placeholder="*********"
                        onChange={handleChange}
                        isLogin
                        full
                      />
                      {isShowConfirmPassword && (
                        <img
                          src={Eye}
                          className="icon"
                          alt="showConfirmPassword"
                          onClick={this.handleShowConfirmPassword}
                        />
                      )}
                      {!isShowConfirmPassword && (
                        <img
                          src={EyeSlash}
                          className="icon"
                          alt="hideConfirmPassword"
                          onClick={this.handleShowConfirmPassword}
                        />
                      )}
                    </PasswordInput>
                    <div>
                      <Checkbox
                        name="isAcceptedTerm"
                        // error={errors.isAcceptedTerm}
                        data-testid="signup-form-accept-checkbox"
                        // label="I have read and accept Service &amp; Agreement and Private Policy."
                        label={this.renderLabel()}
                        locale={locale}
                      />
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        marginTop: 24,
                        marginBottom: 24,
                        justifyContent: 'center',
                      }}
                    >
                      <ReCAPTCHA
                        name="token"
                        size="normal"
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        onChange={(value) => {
                          console.log('Captcha value:', value)
                          setFieldValue('token', value)
                        }}
                      />
                      {errors.recaptcha && (
                        <Typography
                          color="danger"
                          fontSize="text-xs"
                          bold
                          textAlign="left"
                          margin="2px 0"
                        >
                          {errors.recaptcha}
                        </Typography>
                      )}
                    </div>
                    <Button
                      id="signup.create.account"
                      data-testid="signup-submit-button"
                      primary
                      // width="60%"
                      // margin="0 auto"
                      full
                      // type="submit"
                    >
                      Create Account
                    </Button>
                  </Form>
                )
              }}
            </Formik>
            <BackToLoginButton
              from={from}
              style={{ margin: '8px 0px 0px 0px', padding: '0px 0px 40px 0px' }}
              onClick={setTypeModal ? () => setTypeModal('login') : undefined}
            />
          </FormContainer>
          <Modal
            isOpen={showCondition}
            onRequestClose={this.handleModal()}
            shownXclose
            contentStyle={{ width: '80%' }}
          >
            <DialogCondition type={type} />
          </Modal>
        </Container>
      </ErrorBoundary>
    )
  }
}
const mapState = ({ Language: { locale } }) => ({
  locale,
})
const mapDispatch = (dispatch) => {
  const {
    Language: { updateLocale },
  } = dispatch
  return {
    updateLocale,
  }
}
export default connect(mapState, mapDispatch)(SignUpForm)
