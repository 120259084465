import React from 'react'
import { Redirect } from 'react-router-dom'

import TeacherLayout from 'layouts/teacherLayout'
import TeacherCourseDetailLayout from 'layouts/teacherCourseDetailLayout'
import TeacherCourseSettingLayout from 'layouts/teacherCourseSettingLayout'
import TeacherCourseLectureSettingLayout from 'layouts/teacherCourseLectureSettingLayout'

import TeacherCourseLectureContent from 'components/teacherCourseLectureContent/loadable'

import TeacherMyCourseLayout from 'layouts/teacherMyCourseLayout'
import TeacherCoursesPage from 'containers/teacherCoursesPage/loadable'
import TeacherSchedules from 'containers/teacherSchedules/loadable'
// import TeacherTestAssignmentPreview from 'containers/teacherTestAssignmentPreview/loadable'
// import TeacherSubjectLectureTestPreview from 'containers/teacherSubjectLectureTestPreview/loadable'
import TeacherCourseLectureTestPreview from 'containers/teacherCourseLectureTestPreview/loadable'

import TeacherCourseEditInformationTab from 'containers/teacherCourseEditInformationTab/loadable'
import TeacherCourseEditSettingTab from 'containers/teacherCourseEditSettingTab/loadable'
import AccountMainPage from 'containers/accountMainPage'
import TeacherCourseLectureTab from 'containers/teacherCourseLectureTab/loadable'
import TeacherCourseLectureEditInformationTab from 'containers/teacherCourseLectureEditInformationTab'
import TeacherCourseLectureEditContentTab from 'containers/teacherCourseLectureEditContentTab'

// import TeacherCreateLectureSetTestLayout from 'layouts/teacherCreateLectureSetTestLayout'
// import TeacherLectureSetTestQuestionsTab from 'containers/teacherLectureSetTestQuestionsTab/loadable'

import TeacherCreateCourseLecturePage from 'containers/teacherCreateCourseLecturePage/loadable'

// import TeacherSubjectAssessmentView from 'layouts/teacherSubjectAssessmentViewLayout/loadable'
// import TeacherSubjectAssessmentViewInfo from 'containers/teacherSubjectAssessmentViewInfo/loadable'
// import TeacherSubjectAssessmentViewQuestionsTab from 'containers/teacherSubjectAssessmentViewQuestionsTab'

import TeacherCourseAssignmentTab from 'containers/teacherCourseAssignmentTab/loadable'
import TeacherCourseDiscussionTab from 'containers/teacherCourseDiscussionTab/loadable'
import TeacherCourseEvaluationTab from 'containers/teacherCourseEvaluationTab/loadable'
import TeacherCourseGradesTab from 'containers/teacherCourseGradesTab/loadable'
import TeacherCourseReportTab from 'containers/teacherCourseReportTab/loadable'

import EvaluationSettingPage from 'containers/evaluationSettingPage/loadable'
import TeacherCourseEvaluationStudentPage from 'containers/teacherCourseEvaluationStudentPage/loadable'

import TeacherConnectionsLayout from 'layouts/teacherConnectionLayout/loadable'
import TeacherConnectionSchoolPage from 'containers/teacherConnectionSchoolPage/loadable'
import TeacherConnectionSubjectPage from 'containers/teacherConnectionSubjectPage/loadable'
import TeacherConnectionInvitationPage from 'containers/teacherConnectionInvitationPage/loadable'

import TeacherCourseAssignmentLayout from 'layouts/teacherCourseAssignmentLayout'
import TeacherCourseAssignmentOverviewTab from 'containers/teacherCourseAssignmentOverviewTab/loadable'
import TeacherCourseAssignmentSettingTab from 'containers/teacherCourseAssignmentSettingTab/loadable'
import TeacherCourseAssignmentReportTab from 'containers/teacherCourseAssignmentReportTab/loadable'
import TeacherCourseAssignmentCreate from 'containers/teacherCourseAssignmentCreate/loadable'
import TeacherCourseAssignmentViewAnswer from 'containers/teacherCourseAssignmentViewAnswer/loadable'
import MyCourseCreateTeacherCourse from 'containers/myCourseCreateTeacherCourse/loadable'

import AccountSettingLayout from 'layouts/accountSettingLayout'
import AccountGeneralInfoPage from 'containers/accountGeneralInfoPage/loadable'
import AccountChangePasswordPage from 'containers/accountChangePasswordPage/loadable'

import ProfilePage from 'containers/profilePage/loadable'
import ProfilePageAcademy from 'containers/profilePageAcademy/loadable'
import academypofileLayout from 'layouts/academypofileLayout/loadable'
import ContractUsPage from 'containers/contractUsPage/loadable'
import EditProfileLayout from 'layouts/editProfileLayout'
import EditProfileMainPage from 'containers/editProfileMainPage/loadable'
import EditProfileProfileTab from 'components/editProfileProfileTab/loadable'
import EditProfileIntroTab from 'components/editProfileIntroTab/loadable'
import EditProfileWorkExperienceTab from 'components/editProfileWorkExperienceTab/loadable'
import EditProfileEducationTab from 'components/editProfileEducationTab/loadable'
import EditProfileInterestsTab from 'components/editProfileInterestsTab/loadable'
import EditProfileContactTab from 'components/editProfileContactTab/loadable'

const teacherRoutes = {
  path: '/teacher',
  layout: TeacherLayout,
  routes: [
    {
      exact: true,
      subpath: '/',
      render: ({ match }) => <Redirect to={`${match.url}/connections`} />,
    },
    // ! edit lecture test
    // {
    //   subpath: '/materials/subjects/:subjectId/lectures/:lectureId/edit/content/:rank/:testType',
    //   layout: TeacherCreateLectureSetTestLayout,
    //   routes: [
    //     {
    //       exact: true,
    //       subpath: '/',
    //       render: ({ match }) => <Redirect to={`${match.url}/information`} />,
    //     },
    //     // {
    //     //   isPrivate: true,
    //     //   exact: true,
    //     //   subpath: '/information',
    //     //   component: TeacherLectureSetTestInformationTab,
    //     // },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/questions',
    //       component: TeacherLectureSetTestQuestionsTab,
    //     },
    //   ],
    // },
    // ! Subject Lecture Test Preview
    // {
    //   isPrivate: true,
    //   exact: true,
    //   subpath: '/materials/subjects/:subjectId/lectures/:lectureId/content/:rank/test/:testId',
    //   component: TeacherSubjectLectureTestPreview,
    // },
    // ? Lecture Content
    // {
    //   subpath: '/materials/subjects/:subjectId/assessments/:assessmentId',
    //   layout: TeacherSubjectAssessmentView,
    //   routes: [
    //     {
    //       exact: true,
    //       subpath: '/',
    //       render: ({ match }) => <Redirect to={`${match.url}/information`} />,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/information',
    //       component: TeacherSubjectAssessmentViewInfo,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/questions',
    //       component: TeacherSubjectAssessmentViewQuestionsTab,
    //     },
    //   ],
    // },
    // {
    //   subpath: '/materials/subjects/:subjectId/lectures/new/:testType',
    //   layout: TeacherCreateLectureSetTestLayout,
    //   routes: [
    //     {
    //       exact: true,
    //       subpath: '/',
    //       render: ({ match }) => <Redirect to={`${match.url}/information`} />,
    //     },
    //     // {
    //     //   isPrivate: true,
    //     //   exact: true,
    //     //   subpath: '/information',
    //     //   component: TeacherLectureSetTestInformationTab,
    //     // },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/questions',
    //       component: TeacherLectureSetTestQuestionsTab,
    //     },
    //   ],
    // },
    // {
    // ! Teacher Course Lecture
    {
      isPrivate: true,
      exact: true,
      subpath: '/my-course/courses/:courseId/lectures/:lectureId/content/:rank/test/:testId',
      component: TeacherCourseLectureTestPreview,
    },
    {
      isPrivate: true,
      exact: true,
      subpath: '/my-course/courses/:courseId/lecture/new',
      component: TeacherCreateCourseLecturePage,
    },
    {
      isPrivate: true,
      exact: true,
      // path: '/teacher/my-course/courses/:courseId/lecture/:lectureId',
      subpath: '/my-course/courses/:courseId/lecture/:lectureId',
      component: TeacherCourseLectureContent,
    },
    {
      isPrivate: true,
      exact: true,
      subpath: '/my-course/courses/new',
      component: MyCourseCreateTeacherCourse,
    },
    {
      path: '/teacher/my-course/courses/:courseId/lecture/:lectureId/edit',
      layout: TeacherCourseLectureSettingLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/information`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/information',
          component: TeacherCourseLectureEditInformationTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/content',
          component: TeacherCourseLectureEditContentTab,
        },
      ],
    },
    {
      subpath: '/my-course/courses/:courseId/edit',
      layout: TeacherCourseSettingLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/information`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/information',
          component: TeacherCourseEditInformationTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/setting',
          component: TeacherCourseEditSettingTab,
        },
      ],
    },
    {
      isPrivate: true,
      exact: true,
      path: '/teacher/my-course/courses/:courseId/evaluation/setting',
      component: EvaluationSettingPage,
    },
    {
      isPrivate: true,
      exact: true,
      path: '/teacher/my-course/courses/:courseId/evaluation/:studentId',
      component: TeacherCourseEvaluationStudentPage,
    },
    {
      isPrivate: true,
      exact: true,
      path: '/teacher/my-course/courses/:courseId/assignment/new',
      component: TeacherCourseAssignmentCreate,
    },
    {
      isPrivate: true,
      exact: true,
      path: '/teacher/my-course/courses/:courseId/assignment/:assignmentId/student/:studentId',
      component: TeacherCourseAssignmentViewAnswer,
    },
    {
      path: '/teacher/my-course/courses/:courseId/assignment/:assignmentId',
      layout: TeacherCourseAssignmentLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/overview`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/overview',
          component: TeacherCourseAssignmentOverviewTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/setting',
          component: TeacherCourseAssignmentSettingTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/report',
          component: TeacherCourseAssignmentReportTab,
        },
      ],
    },
    {
      subpath: '/my-course/courses/:courseId',
      layout: TeacherCourseDetailLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/lecture`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/lecture',
          component: TeacherCourseLectureTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/assignment',
          component: TeacherCourseAssignmentTab,
        },

        {
          isPrivate: true,
          exact: true,
          subpath: '/discussion',
          component: TeacherCourseDiscussionTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/evaluation',
          component: TeacherCourseEvaluationTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/grades',
          component: TeacherCourseGradesTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/report',
          component: TeacherCourseReportTab,
        },
      ],
    },
    {
      subpath: '/my-course',
      layout: TeacherMyCourseLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/courses`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/courses',
          component: TeacherCoursesPage,
        },
      ],
    },
    {
      path: '/teacher/profile/edit',
      layout: EditProfileLayout,
      routes: [
        {
          isPrivate: true,
          exact: true,
          subpath: '/',
          component: EditProfileMainPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/profile',
          component: EditProfileProfileTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/intro',
          component: EditProfileIntroTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/work-experience',
          component: EditProfileWorkExperienceTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/education',
          component: EditProfileEducationTab,
        },
        // {
        //   isPrivate: true,
        //   exact: true,
        //   subpath: '/certificates',
        //   component: EditProfileCertificatesTab,
        // },
        {
          isPrivate: true,
          exact: true,
          subpath: '/interests',
          component: EditProfileInterestsTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/contact',
          component: EditProfileContactTab,
        },
      ],
    },

    ///mmmm

    {
      subpath: '/my-profile',
      layout: academypofileLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/profile`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/profile',
          component: ProfilePage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/academy-profile',
          component: ProfilePageAcademy,
        },
      ],
    },

    {
      isPrivate: true,
      exact: true,
      subpath: '/contact-us',
      component: ContractUsPage,
    },
    {
      subpath: '/account-setting',
      layout: AccountSettingLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          component: AccountMainPage,
          //render: ({ match }) => <Redirect to={`${match.url}/general-information`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/general-information',
          component: AccountGeneralInfoPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/change-password',
          component: AccountChangePasswordPage,
        },
      ],
    },
    {
      isPrivate: true,
      exact: true,
      subpath: '/schedules',
      component: TeacherSchedules,
    },
    {
      subpath: '/connections',
      layout: TeacherConnectionsLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/school`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/school',
          component: TeacherConnectionSchoolPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/subject',
          component: TeacherConnectionSubjectPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/invitation',
          component: TeacherConnectionInvitationPage,
        },
      ],
    },
  ],
}

export default teacherRoutes
