/* eslint-disable no-unused-vars */
import React from 'react'
import { Icon, Typography } from 'components'
import styled, { css } from 'styled-components'
import WhiteLikeIcon from '../../../../../images/icons/DarkIcon/White-LikeIcon.svg'
import WhiteLikeIconFocus from '../../../../../images/icons/DarkIcon/White-LikeIconFocus.svg'

export const Like = styled(Icon)`
  cursor: pointer;
  width: 22px;
  height: 22px;
`
export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`
const LikeButton = ({ isMyLike, countLikes, id, onLike }) => {
  return (
    <LikeContainer>
      {isMyLike ? (
        <>
          <Like src={WhiteLikeIconFocus} alt="Liked" onClick={() => onLike(id, 1)} />
          <Typography
            id="button.like"
            fontSize="text-sx"
            margin="0 0 0 8px"
            color="white"
            bold
            lineHeight={1}
          >
            {`${countLikes}`}
          </Typography>
        </>
      ) : (
        <>
          <Like src={WhiteLikeIcon} alt="Like" onClick={() => onLike(id, 1)} />
          <Typography
            id="button.like"
            fontSize="text-sx"
            margin="0 0 0 8px"
            color="white"
            lineHeight={1}
          >
            {`${countLikes}`}
          </Typography>
        </>
      )}
    </LikeContainer>
  )
}
export default LikeButton
