/* eslint-disable no-unused-vars */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Typography } from 'components'
import Icon from '../../../../components/icon/loadable'
import IconDarkCircle from '../../../../images/icons/DarkIcon/Dark-circle.svg'
import IconDarkCircleCorrect from '../../../../images/icons/DarkIcon/Dark-circleCorrect.svg'
import IconWhiteCircle from '../../../../images/icons/DarkIcon/White-circle.svg'
import IconWhiteCircleCorrect from '../../../../images/icons/DarkIcon/White-circleCorrect.svg'

function PrePostTestItem(props) {
  const { history, match, title, isPass, id, numberOfQuestion, isPostTest } = props
  const handleClickToLecture = () => {
    history.push(`/my-course/courses/${match?.params?.courseId}/test/${id}`)
  }
  const focus = match?.params?.lectureId === id
  const focusColor = focus ? '#fff' : '#2C2B30'

  const logoDark = isPass ? IconDarkCircleCorrect : IconDarkCircle
  const logoWhite = isPass ? IconWhiteCircleCorrect : IconWhiteCircle
  return (
    <div
      style={{
        border: `1px solid ${focusColor}`,
        borderRadius: '8px',
        display: 'flex',
        backgroundColor: '#2C2B30',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: '72px',
        padding: '12px 16px 12px 16px',
        gap: '8px',
        margin: isPostTest ? '0px 0px 40px 0px' : '0px 0px 10px 0px',
      }}
      onClick={() => handleClickToLecture()}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          padding: '4px 0px 0px 0px',
          cursor: 'pointer',
        }}
      >
        <Icon src={focus ? logoWhite : logoDark} size="14" />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          height: '100%',
        }}
      >
        <Typography
          color={focus ? 'white' : 'dark2'}
          style={{ cursor: 'pointer' }}
          numberOfLines={1}
        >
          {title}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography color={focus ? 'white' : 'dark2'} style={{ cursor: 'pointer' }}>
            {numberOfQuestion} คำถาม
          </Typography>
          {/* <Typography color={focus ? 'white' : 'dark2'} style={{ cursor: 'pointer' }}>
            | 30 min
          </Typography> */}
        </div>
      </div>
    </div>
  )
}

export default withRouter(PrePostTestItem)
