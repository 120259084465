import React, { Component } from 'react'
import styled from 'styled-components'
import ErrorBoundary from '../errorBoundary'

const Image = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  border-radius: 50%;
  background-image: url(${(props) => props.src || 'https://via.placeholder.com/100'});
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
`

class ProfileImage extends Component {
  static propTypes = {}

  render() {
    return (
      <ErrorBoundary>
        <Image {...this.props} onClick={this.props.handleTogglePopup} />
      </ErrorBoundary>
    )
  }
}

export default ProfileImage
