import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Container, Text } from './style'
import ErrorBoundary from '../errorBoundary'

function PureAvatar({ label, justifyContent, flex, children, fontSize, containerStyle, ...props }) {
  return (
    <ErrorBoundary>
      <Container style={{ flex: flex, ...containerStyle }} justifyContent={justifyContent}>
        <Avatar {...props} onClick={props.handleTogglePopup} />
        {label && (
          <Text fontSize={fontSize ? fontSize : 'text-sm'} left {...props}>
            {label}
          </Text>
        )}
        {children}
      </Container>
    </ErrorBoundary>
  )
}

PureAvatar.propTypes = {
  props: PropTypes.object,
}

PureAvatar.defaultProps = {
  props: {},
}

export default PureAvatar
