import styled from 'styled-components'
import theme from 'theme'

const Content = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  .image-view {
    font-size: ${({ theme }) => theme['text-xs']};
    margin: 10px 0 0 0;
    cursor: pointer;
    display: ${(props) => (props.value ? 'block' : 'none')};
  }
`

const UploadImageStyle = styled.div`
  margin: ${({ styleUploadImage: { margin } }) => (margin ? margin : '10px 0 0 0')};
  outline: none;
  display: grid;
  grid-template-columns: ${({ styleUploadImage: { gridTemplateColumns } }) =>
    gridTemplateColumns ? gridTemplateColumns : '1fr 0.6fr'};
  gap: 67px;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

const ImageBox = styled.div`
  border: 2px solid ${(props) => (props.grayBorder ? theme.gray4 : theme['sa-blue'])};
  border-radius: 4px;
  height: ${(props) => props.height && props.height}px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  img {
    // width: 100%;
  }
  .icon {
    height: 50px;
    width: 50px;
  }
`

const Image = styled.img`
  height: auto;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: ${({ cropShape }) => (cropShape === 'round' ? '50%' : '2px')};
`

const UploadIcon = styled.div`
  position: absolute;
  bottom: 0%;
  right: -8%;
  cursor: pointer;
`

const Wrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
`

export { Content, UploadImageStyle, ImageBox, Image, UploadIcon, Wrapper }
