export const menus = [
  {
    id: 'tab.label.Lecture',
    label: 'Lecture',
    key: '0',
    component: null,
    path: '/lecture',
  },
  // {
  //   id: 'tab.label.Assignment',
  //   label: 'Assignment',
  //   key: '1',
  //   component: null,
  //   path: '/assignment',
  // },
  // {
  //   id: 'tab.label.Live',
  //   label: 'Live',
  //   path: '/live',
  //   key: '2',
  //   component: null,
  // },
  {
    id: 'tab.label.Discussion',
    label: 'Discussion',
    key: '3',
    component: null,
    path: '/discussion',
  },
  // {
  //   id: 'tab.label.Classmate',
  //   label: 'Classmates',
  //   key: '4',
  //   component: null,
  //   path: '/students',
  // },
  // {
  //   label: 'Overview',
  //   key: '5',
  //   component: null,
  //   path: '/overview',
  // },
]

export const menus2 = [
  {
    id: 'tab.label.Lecture',
    label: 'Lecture',
    key: '0',
    component: null,
    path: '/lecture',
  },
  {
    id: 'tab.label.Assignment',
    label: 'Assignment',
    key: '1',
    component: null,
    path: '/assignment',
  },
  // {
  //   id: 'tab.label.Live',
  //   label: 'Live',
  //   path: '/live',
  //   key: '2',
  //   component: null,
  // },
  {
    id: 'tab.label.Discussion',
    label: 'Discussion',
    key: '3',
    component: null,
    path: '/discussion',
  },
  {
    id: 'tab.label.Classmate',
    label: 'Classmates',
    key: '4',
    component: null,
    path: '/students',
  },
  // {
  //   label: 'Overview',
  //   key: '5',
  //   component: null,
  //   path: '/overview',
  // },
]

export const menus3 = [
  {
    id: 'tab.label.Lecture',
    label: 'Lecture',
    key: '0',
    component: null,
    path: '/lecture',
  },
  {
    id: 'tab.label.Assignment',
    label: 'Assignment',
    key: '1',
    component: null,
    path: '/assignment',
  },
]
