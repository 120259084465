import styled from 'styled-components'
import { Container, Typography } from '../index'

const Avatar = styled.img`
  width: 35px;
  height: 35px;
  background-color: silver;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
`

const _Container = styled(Container)`
  grid-template-columns: 54px 1fr;
  margin: 10px 0px;
  align-items: center;
`

const Section = styled.div`
  background-color: #ededed;
  width: fit-content;
  padding: 9px 12px;
  border-radius: 6px;
`

export { Avatar, _Container as Container, Typography, Section }
