import styled from 'styled-components'
import theme from 'theme'
const Footer = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: 10%;
  justify-content: center;
  z-index: 10;
`

const Container = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #000000ad;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Body = styled.div`
  background-color: #fff;
  position: relative;
  max-width: 775px;
  width: 100%;
  margin: auto;
  padding: 20px 32px 32px 32px;
  border-radius: 8px;
  .cropper {
    position: relative;
    width: 100%;
    height: 400px;
  }
  .header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: 20px 0 16px 0;
  }
  .footer {
    display: flex;
    justify-content: ${(props) => (props.hasCustomAspect ? 'space-between' : 'center')};
    align-items: center;
    /* padding: 16px; */
  }
  .aspect-ratio {
    display: flex;
    align-items: center;
  }
  .button {
    padding: 6px 70px;
  }
  .reactEasyCrop_CropArea {
    color: rgba(255, 255, 255, 0.8);
  }
`

const CloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 20px;
  img {
    height: 24px;
    width: 24px;
  }
`
const AspectRatio = styled.div`
  padding-left: 24px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 99px;
    height: 46px;
    cursor: pointer;
    border: 1.5px solid transparent;
    border-radius: 10px;
  }
  .four-to-three {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  .sixteen-to-nine {
    width: 44px;
    height: 44px;
    margin-right: 12px;
  }
  .active {
    background-color: ${theme.gray12};
    height: 40px;
  }
`
export { Footer, Container, Body, CloseIcon, AspectRatio }
