import styled, { css } from 'styled-components'
import theme from '../../theme'

const Textarea = styled.textarea`
  padding: 6px 11px;
  width: 100%;
  border: ${(props) => (props.Border ? props.Border : `1.3px solid ${theme.black38}`)};
  transition: all 0.2s;
  border-radius: 6px;
  outline: none;
  margin: ${(props) => (props.reduceMargin ? '0 0 -10px' : '0')};
  color: ${theme.black87};
  font-size: ${({ theme }) => theme['text-sm']};
  opacity: 80%;
  line-height: 1.5;
  list-style: none;

  :hover {
    border-color: ${theme.primary1};
    opacity: 100%;
  }

  ::placeholder {
    font-weight: 400;
    color: ${theme.black38};
  }

  ${({ readOnly }) => {
    if (readOnly) {
      return css`
        border-color: #cdcdcd;
        color: #cdcdcd;
        cursor: not-allowed;
      `
    }
  }}

  ${({ fontSize }) => {
    if (fontSize) {
      return css`
        font-size: ${fontSize};
      `
    }
  }}

  ${({ fontWeight }) => {
    if (fontWeight) {
      return css`
        font-weight: ${fontWeight};
      `
    }
    return css``
  }}

  ${({ readOnly }) => {
    if (readOnly) {
      return css`
        border-color: #cdcdcd;
        color: #cdcdcd;
        cursor: not-allowed;
      `
    }
  }}

  ${({ status }) => {
    if (status === 'error') {
      return css`
        background-color: #fff;
        border-color: ${theme.danger};
      `
    }

    if (status === 'warning') {
      return css`
        background-color: #fff;
        border-color: ${theme.warning};
      `
    }
  }}
  ${({ counter }) => {
    if (counter) {
      return css`
        resize: none;
      `
    }
  }}
`

export default Textarea
