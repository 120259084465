import React, { Component } from 'react'
import styled from 'styled-components'
// import { Link } from 'react-router-dom'

import { Typography } from '../../../components'
import ErrorBoundary from '../../../components/errorBoundary'
import CourseTypeCard from '../../../components/courseTypeCard/loadable'
//import publicImage from '../../../images/Classwin - New icon_3-PublicWorld.svg'
//import privateImage from '../../../images/Classwin - New icon_2-Private.svg'
import publicImage from '../../../images/PublicCourseType.svg'
import privateImage from '../../../images/PrivateCourseType.svg'

const Container = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding-bottom: 6px;

  @media (max-width: 576px) {
    padding: 0px;
    grid-template-columns: 1fr;
  }
`

const Wrapper = styled.div`
  position: relative;
`

const StyledTypography = styled((props) => <Typography {...props} />)`
  margin: 0px 0px 10px 0px;
  padding: 0px 29px 27px 0px;
  color: ${({ theme }) => theme['primary1']};
  @media (max-width: 576px) {
    padding: 0 30px 15px 0px;
    margin: 0;
  }
  @media (max-width: 375px) {
    font-size: ${({ theme }) => theme['text-base']};
    padding-bottom: 20px;
  }
`
// const Linking = styled(Link)`
//   text-decoration: underline !important;
// `

const COURSES = [
  {
    id: 'course.instructor.create.private',
    title: 'Private Course',
    type: 'private',
    descId: 'course.instructor.create.private.desc',
    description: 'Only learner in this acadamy or invited person can access to this course',
    publicMode: 'CODE',
    image: privateImage,
    disabled: false,
  },
  {
    id: 'course.instructor.create.public',
    title: 'Public Course',
    type: 'public',
    descId: 'course.instructor.create.public.desc',
    description: 'Anyone can discover this course in the classwin marketplace',
    publicMode: 'PUBLIC',
    image: publicImage,
    disabled: true,
    contactUs: true,
  },
]

class ChooseTypeCourse extends Component {
  state = {
    selected: null,
  }

  componentDidMount() {
    this.setState({ selected: 'private' })
  }

  onSelect =
    ({ type, publicMode }) =>
    () => {
      const {
        formik: { setFieldValue },
      } = this.props
      setFieldValue('publicMode', publicMode)
      this.setState({ selected: type })
    }

  render() {
    const { selected } = this.state
    const {
      formik: { errors, touched },
    } = this.props
    return (
      <ErrorBoundary>
        <Wrapper>
          <StyledTypography id="course.instructor.create.title" h3>
            What type of course are you making ?
          </StyledTypography>
          <Container>
            {COURSES.map((course) => (
              <CourseTypeCard
                selected={course.type === selected}
                key={course.type}
                onSelect={this.onSelect}
                disabled={course.disabled}
                contactUs={course.contactUs}
                {...course}
              />
            ))}
            <div></div>
            {/* <Linking to="/contact-us">Contact us</Linking> */}
          </Container>
          {errors.publicMode && touched.publicMode && (
            <>
              <br />
              <Typography center color="tomato">
                {errors.publicMode}
              </Typography>
            </>
          )}
        </Wrapper>
      </ErrorBoundary>
    )
  }
}

export default ChooseTypeCourse
