import styled, { css } from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  padding: 0px;
  margin: 0px;

  ${({ column }) => {
    if (column) {
      return css`
        grid-template-columns: repeat(${column}, minmax(100px, 1fr));
      `
    }
    return css``
  }}

  ${({ gap }) => {
    if (gap) {
      return css`
        grid-gap: ${gap};
      `
    }
    return css``
  }}

  ${({ margin }) => {
    if (margin) {
      return css`
        margin: ${margin};
      `
    }
    return css``
  }}

  ${({ padding }) => {
    if (padding) {
      return css`
        padding: ${padding};
      `
    }
    return css``
  }}

  ${(props) => {
    const { align, justify } = props
    if (align) {
      return css`
        align-items: ${align};
      `
    }

    if (justify) {
      return css`
        justify-contents: ${justify};
      `
    }
    return css``
  }}

  ${({ bgColor }) => {
    if (bgColor) {
      return css`
        background-color: ${bgColor};
      `
    }
    return css``
  }}

  ${({ borderRadius }) => {
    if (borderRadius) {
      return css`
        border-radius: ${borderRadius};
      `
    }
    return css``
  }}
`

export default Container
