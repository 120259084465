import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import { UserAPI } from '../../api'

export const MyAnswer = {
  state: {
    myAnswers: {},
  },
  reducers: {
    init() {
      return {
        myAnswers: {},
      }
    },
    setMyAnswer(state, payload) {
      const { assignmentId, questionId, priority, answers } = payload

      return {
        ...state,
        myAnswers: {
          ...state.myAnswers,
          [assignmentId]: {
            ...get(state.myAnswers, assignmentId, {}),
            [questionId]: {
              questionId,
              priority,
              answers,
            },
          },
        },
      }
    },
    clearAnswer(state, payload) {
      const newMyAnswers = { ...state.myAnswers }
      delete newMyAnswers[payload.assignmentId]
      return {
        ...state,
        myAnswers: newMyAnswers,
      }
    },
  },
  effects: () => ({
    async sendAnswer(assignmentId, rootState) {
      const answerObj = get(rootState.MyAnswer.myAnswers, assignmentId, {})
      const answers = Object.values(answerObj)
      try {
        const body = {
          assignmentId,
          answers,
        }

        const result = await UserAPI.sendAnswers(body)
        this.clearAnswer({ assignmentId })
        return result
      } catch (e) {
        console.error(e)
        throw e
      }
    },
  }),
  selectors: () => ({}),
}

export const MyAssignmentResult = {
  state: {
    answers: [],
    score: 0,
    totalScore: 0,
    assignment: {},
    isGraded: false,
  },
  reducers: {
    setAnswerObj(state, payload) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: () => ({
    async getMyAssignmentResultById(assignmentId) {
      const filter = JSON.stringify({
        where: {
          assignmentId,
        },
        include: {
          assignment: {
            assignmentWorksheets: 'publishedWorksheet',
          },
        },
        order: 'attemp DESC',
        limit: 1,
      })
      try {
        const answers = await UserAPI.getAnswers({ filter })
        if (answers.length > 0) {
          this.setAnswerObj(answers[0])
        }
      } catch (e) {
        throw e
      }
    },
  }),
  selectors: () => ({
    answers() {
      return (rootState) => {
        const answers = get(rootState.MyAssignmentResult, 'answers')

        const ansObj = groupBy(answers, 'questionId')

        const questions = get(
          rootState.MyAssignmentResult,
          'assignment.assignmentWorksheets[0].publishedWorksheet.data.questions',
          []
        )
        const sortedQuestions = sortBy(questions, ['priority'])
        return sortedQuestions
          .filter((item) => item.type !== 'content')
          .map((item) => ({
            ...item,
            correctAns: item.answers,
            checkedAns: get(ansObj, `${item.id}.0.checkedAns`, []),
            answers: get(ansObj, `${item.id}.0.answers`, []),
            isCorrect: get(ansObj, `${item.id}.0.isCorrect`, false),
          }))
      }
    },
  }),
}
