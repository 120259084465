import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'
import { Container } from 'components'
import UploadVideo from 'components/uploadVideo'
import { Typography, Input, MediaPlayer, Label, UploadImage } from '../../components'
import getImageUrl from '../../utils/getImageUrl'
import CancelIcon from 'images/icons/primary-small-x.svg'
import { validateVideoUrl } from 'components/mediaPlayer'

const CloseIcon = styled.div`
  display: flex;
  /* width: 100%; */
  margin-left: auto;
  flex-direction: row-reverse;
  height: max-content;
  cursor: pointer;
  /* fill: ${(props) => props.theme.primary}; */

  img {
    width: 10px;
    height: 10px;
  }
`
const Wrapper = styled.div``

const Content = styled.div`
  display: flex;
  flex-direction: row;
`

const InputRadio = styled.input`
  margin-right: 10px;
  accent-color: ${(props) => props.theme.primary1};
`

const Item = styled.div`
  display: flex;
  margin-right: 30px;
`

const RadioLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

class IntroductionVideo extends React.Component {
  state = {
    selectName: 'introVideo',
  }

  componentDidMount() {
    const { value } = this.props
    if (value) {
      this.setState({
        showVideoLink: true,
        selectName: /^https:\/\/vimeo.com/.test(value) ? 'browse' : 'introVideo',
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        showVideoLink: true,
        selectName: /^https:\/\/vimeo.com/.test(this.props.value) ? 'browse' : 'introVideo',
      })
    }
  }

  setShow = () => {
    const { showVideoLink } = this.state
    this.setState({ showVideoLink: !showVideoLink })
  }

  handleCheckBox = (name) => {
    this.setState({ selectName: name })
  }

  getVideoValue = (value) => {
    const linkType = /^https:\/\/vimeo.com/.test(value) ? 'browse' : 'introVideo'
    if (linkType === this.state.selectName) {
      return value
    }
    return undefined
  }

  render() {
    const { selectName } = this.state
    const { onChange, cover, introVideoFilename } = this.props

    return (
      <React.Fragment>
        <Field name="introVideo">
          {({ field }) => (
            <Wrapper style={{ marginBottom: 25 }}>
              <Container
                style={{
                  gridTemplateColumns: '118px 50px 1fr',
                  alignItems: 'center',
                  padding: '10px 0px',
                }}
              >
                <Label
                  id="courseinfo.intorvideo"
                  style={{ marginBottom: '0px' }}
                  htmlFor="introVideo"
                  color="primary1"
                  bold={false}
                >
                  Introduction Video
                </Label>
              </Container>
              <Content>
                <Item>
                  <InputRadio
                    type="radio"
                    id="introVideo"
                    name="introVideo"
                    value="introVideo"
                    onChange={(e) => this.handleCheckBox(e.target.value)}
                    checked={selectName === 'introVideo'}
                  />
                  <RadioLabel>
                    <Typography id="courseinfo.intorvideo.videoUrl" color="primary1">
                      Use video URL
                    </Typography>
                  </RadioLabel>
                </Item>
                <Item>
                  <InputRadio
                    type="radio"
                    id="browse"
                    name="browse"
                    value="browse"
                    onChange={(e) => this.handleCheckBox(e.target.value)}
                    checked={selectName === 'browse'}
                  />
                  <RadioLabel>
                    <Typography id="courseinfo.intorvideo.browse" color="primary1">
                      Browse from your device
                    </Typography>
                  </RadioLabel>
                </Item>
              </Content>
              {selectName === 'introVideo' && (
                <>
                  <div style={{ margin: '10px 0px 0px 0px' }}>
                    <Typography
                      id="courseinfo.intorvideo.youtube.desc"
                      color="black38"
                      style={{ marginBottom: '5px' }}
                    >
                      (Copy a link from Youtube and paste the URL into this field)
                    </Typography>
                    <Input
                      id="introVideo"
                      name="introVideo"
                      placeholder="https://www.youtube.com/watch?v=xxx"
                      value={this.getVideoValue(field.value)}
                      full
                      onChange={(e) => {
                        const { value } = e.target
                        onChange('introVideo', value)
                      }}
                    />
                  </div>
                </>
              )}

              <Container>
                {selectName === 'introVideo' &&
                  this.getVideoValue(field.value) &&
                  validateVideoUrl(field.value) && (
                    <MediaPlayer
                      url={field.value}
                      width="100%"
                      style={{
                        background: 'black',
                        borderRadius: 4,
                        marginTop: 5,
                      }}
                    />
                  )}
              </Container>

              {selectName === 'browse' && this.getVideoValue(field.value) && (
                <Container>
                  <MediaPlayer
                    url={field.value}
                    light={cover ? getImageUrl(cover) : true}
                    width="100%"
                    style={{
                      background: 'black',
                      borderRadius: 4,
                      marginTop: 5,
                    }}
                  />
                </Container>
              )}
              {selectName === 'browse' && (
                <div style={{ width: '100%', margin: '10px 0px 0px 0px' }}>
                  <Typography
                    id="courseinfo.intorvideo.browse.desc2"
                    color="black38"
                    style={{ marginBottom: '5px' }}
                  >
                    (Select file from your device to upload (limit 300 MB))
                  </Typography>
                  <UploadVideo
                    afterUpload={(file) => {
                      onChange('introVideo', file.name)
                      onChange('introVideoFilename', file.fileName)
                    }}
                    videoFilename={introVideoFilename}
                    fieldValue={this.getVideoValue(field.value)}
                    closeIcon={
                      <CloseIcon
                        onClick={() => {
                          onChange('introVideo', '')
                          onChange('introVideoFilename', '')
                        }}
                      >
                        <img src={CancelIcon} alt="cancel" />
                      </CloseIcon>
                    }
                  />
                </div>
              )}
            </Wrapper>
          )}
        </Field>
        <Field name="coverImageVideo">
          {({ field }) => (
            <Wrapper>
              {selectName === 'browse' && this.props.value && (
                <div style={{ marginTop: 5 }}>
                  <Typography id="courseinfo.covervideo.desc">
                    Cover image video (Optional)
                  </Typography>
                  <UploadImage
                    name="coverImageVideo"
                    value={field.value}
                    afterUpload={(file) => {
                      onChange('coverImageVideo', file.name)
                    }}
                    onChange={onChange}
                    isCrop
                    aspect={1000 / 524}
                    cropModal
                  />
                </div>
              )}
            </Wrapper>
          )}
        </Field>
      </React.Fragment>
    )
  }
}

export default IntroductionVideo
