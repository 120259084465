/* eslint-disable no-unused-vars */
import React from 'react'
import { Redirect } from 'react-router-dom'
import UserLayout from 'layouts/userLayout/loadable'
import LogInPage from 'containers/logInPage/loadable'
import SignUpPage from 'containers/signUpPage/loadable'
import ResetPasswordPage from 'containers/resetPasswordPage/loadable'
import ChangePasswordPage from 'containers/changePasswordPage/loadable'
import VerifyEmailPage from 'containers/verifyEmailPage/loadable'

const authRoutes = {
  path: '/user',
  layout: UserLayout,
  routes: [
    {
      exact: true,
      subpath: '/',
      render: ({ match }) => <Redirect to={`${match.url}/login`} />,
    },
    {
      exact: true,
      subpath: '/login',
      component: LogInPage,
      //render: () => <div>sss</div>,
    },
    {
      exact: true,
      subpath: '/register',
      component: SignUpPage,
    },
    {
      exact: true,
      subpath: '/forgotPassword',
      component: ResetPasswordPage,
    },
    {
      exact: true,
      subpath: '/changePassword',
      component: ChangePasswordPage,
    },
    {
      exact: true,
      subpath: '/verifyEmail',
      component: VerifyEmailPage,
    },
  ],
}

export default authRoutes
