import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Modal } from 'antd'
import { Button, Typography, ClasswinEditor, Upload } from 'components'
import getImageUrl from 'utils/getImageUrl'
import AddImage from '../../../../../images/icons/Image.svg'
import CloseIcon from 'images/icons/Close.png'

const AntModal = styled(Modal)`
  .ant-modal-header {
    border-radius: 8px !important;
  }
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-title {
    text-align: center !important;
  }
  .ant-modal-body {
    padding: 0px;
  }
`
const Line = styled.div`
  width: 98%;
  border-top: 1px solid #00000010;
  margin: 0px 0px 2px 5px;
`
const Content = styled.div`
  padding: 12px 16px 16px;
`
const UserContainer = styled.div`
  display: flex;
  width: 10%;
  align-items: flex-start;
  @media (max-width: 820px) {
    width: 12%;
  }
  @media (max-width: 768px) {
    width: 12%;
  }
  @media (max-width: 425px) {
    width: 15%;
  }
`
// const CardTool = styled.div`
//   padding: 8px;
//   border: 1px solid #cdcdcd;
//   margin: 8px 0;
//   border-radius: 8px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `
const Image = styled.div`
  height: 150px;
  background-image: ${(props) => `url(${props.src})`};
  background-position: center;
  background-size: cover;
`
const Avatar = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
`
const AddImageFake = styled.img`
  width: 35px;
  height: 35px;
`

const EditPostModal = ({
  locale,
  isOpen,
  setIsOpen,
  appUser,
  courseId,
  updateFeed,
  getMyFeeds,
  data,
}) => {
  const { profileImage } = appUser
  const [textInput, setTextInput] = useState('')
  const [photo, setPhoto] = useState(null)
  const handleCancel = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setTextInput(data?.richMessage)
    setPhoto(data?.media?.[0])
  }, [data])

  const handlePost = async () => {
    if (textInput.length > 0) {
      const obj = {
        id: data?.id,
        richMessage: textInput,
        appUserId: appUser.id,
        media: photo ? [photo] : null,
        courseId: courseId ? courseId : null,
      }
      await updateFeed(obj)
      setIsOpen(false)
      await getMyFeeds({ courseId })
    }
  }
  return (
    <AntModal
      visible={isOpen}
      centered
      footer={null}
      title={
        <Typography fontSize="text-lg" color="primary1" bold>
          {locale === 'en' ? 'Edit Conversation' : 'แก้ไขบทสนทนา'}
        </Typography>
      }
      onCancel={handleCancel}
      closeIcon={<img src={CloseIcon} alt="Close" style={{ width: 24, height: 24 }} />}
    >
      {/* <Content>
        <UserContainer>
          <Avatar src={getImageUrl(profileImage)} alt={appUser?.firstname} />
          <Typography
            margin="0 0 0 8px"
            bold
          >{`${appUser.firstname} ${appUser.lastname}`}</Typography>
        </UserContainer>
        <div style={{ width: '100%', margin: '24px 0' }}>
          <ClasswinEditor
            placeholder={
              locale === 'en' ? "Let's start your conversation ..." : 'มาเริ่มพูดคุยกันเถอะ ...'
            }
            value={textInput}
            onChange={(value) => {
              setTextInput(value)
            }}
            showToolbar={false}
            // labelFontSize="text-lg"
            noPadding
            noBorderRadius
            autoHeight
            fontSize="text-lg"
            padding={0}
          />
        </div>
        {photo && (
          <div>
            <img
              src={CloseIcon}
              alt="Close"
              onClick={() => setPhoto(null)}
              style={{
                width: 24,
                height: 24,
                position: 'absolute',
                right: 16,
                cursor: 'pointer',
              }}
            />
            <Image src={getImageUrl(photo.name)} />
          </div>
        )}
        <CardTool>
          <Typography>Add to your post</Typography>
          <div style={{ cursor: 'pointer' }}>
            <Upload
              afterUpload={(value) => {
                return setPhoto(value)
              }}
            >
              <AddImageFake src={AddImage} alt="add-image" />
            </Upload>
          </div>
        </CardTool>
        <Button primary width="100%" onClick={handlePost}>
          {locale === 'en' ? 'Save' : 'บันทึก'}
        </Button>
      </Content> */}
      <Content>
        <div style={{ display: 'flex' }}>
          <UserContainer>
            <Avatar src={getImageUrl(profileImage)} alt={appUser?.firstname} />
            {/* <Typography
            margin="0 0 0 8px"
            bold
          >{`${appUser.firstname} ${appUser.lastname}`}</Typography> */}
          </UserContainer>
          <div style={{ width: '85%', margin: '0 0 75px' }}>
            <ClasswinEditor
              placeholder={
                locale === 'en' ? "Let's start your conversation ..." : 'เขียนความคิดเห็นของคุณ ...'
              }
              value={textInput}
              onChange={(value) => {
                setTextInput(value)
              }}
              showToolbar={false}
              // labelFontSize="text-lg"
              noPadding
              noBorderRadius
              autoHeight
              fontSize="text-base"
              padding={0}
            />
          </div>
        </div>
        {photo && (
          <div>
            <img
              src={CloseIcon}
              alt="Close"
              onClick={() => setPhoto(null)}
              style={{
                width: 24,
                height: 24,
                position: 'absolute',
                right: 16,
                cursor: 'pointer',
              }}
            />
            <Image src={getImageUrl(photo.name)} />
          </div>
        )}
        {/* <CardTool>
          <Typography>
            {locale === 'en' ? 'Add image to your post' : 'เพิ่มรูปในโพสต์ของคุณ'}
          </Typography>
          <div style={{ cursor: 'pointer' }}>
            <UploadComponent
              afterUpload={(value) => {
                return setPhoto(value)
              }}
            >
              <AddImageFake src={AddImage} alt="add-image" />
            </UploadComponent>
          </div>
        </CardTool> */}
      </Content>
      <Line />
      <Content>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ cursor: 'pointer', marginTop: 2 }}>
            <Upload
              afterUpload={(value) => {
                return setPhoto(value)
              }}
            >
              <AddImageFake src={AddImage} alt="add-image" />
            </Upload>
          </div>
          <Button primary width="20%" onClick={handlePost}>
            {locale === 'en' ? 'Save' : 'บันทึก'}
          </Button>
        </div>
      </Content>
    </AntModal>
  )
}

const mapState = ({ AppUser: { appUser }, Language: { locale } }) => ({ appUser, locale })
const mapDispatch = ({ AppUser: { updateFeed, getMyFeeds } }) => ({
  updateFeed,
  getMyFeeds,
})

export default connect(mapState, mapDispatch)(EditPostModal)
