/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import { Typography } from '../index'
import ErrorBoundary from '../errorBoundary'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
`

const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']

const grid = 8
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const QuoteItem = styled.div`
  width: 48px;
  height: 48px;
  border: 1px solid grey;
  margin-bottom: ${grid}px;
  //background-color: lightblue;
  padding: ${grid}px;
  border-radius: 8px;
  border: 1px solid #cbd5e1;
  display: flex;
  justify-content: center;
  align-items: center;
`
const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  gap: '4px',
  overflow: 'auto',
})
// const getItemStyle = (isDragging, draggableStyle) => ({
//   // some basic styles to make the items look a bit nicer
//   userSelect: 'none',
//   padding: grid * 2,
//   margin: `0 ${grid}px 0 0`,

//   // change background colour if dragging
//   background: isDragging ? 'lightgreen' : 'grey',

//   // styles we need to apply on draggables
//   ...draggableStyle,
// })
function Quote({ quote, index }) {
  return (
    <Draggable draggableId={quote} index={index}>
      {(provided, snapshot) => (
        <QuoteItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <Typography color="primary1" fontSize="text-sm">
            {quote.toUpperCase()}
          </Typography>
        </QuoteItem>
      )}
    </Draggable>
  )
}

const QuoteList = React.memo(function QuoteList({ quotes }) {
  return quotes.map((quote, index) => <Quote quote={quote} index={index} key={quote} />)
})

const ArrangementAnswerInput = ({
  choices,
  answers = [],
  assessmentId,
  questionId,
  updateQuestion,
  getQuestionData,
  handleAutoSave,
  setInput,
  setAnswers,
}) => {
  const [state, setState] = useState({ quotes: [] })

  useEffect(() => {
    const ChoiceAns = letters.slice(0, choices?.length)
    if (answers?.length < 1) {
      setState({ quotes: ChoiceAns })
    } else if (answers?.length == choices?.length) {
      setState({ quotes: answers })
    } else {
      setState({ quotes: ChoiceAns })
    }
  }, [answers, choices])
  const [isWarning, setIsWarning] = useState(false)

  const debounceInput = debounce(async (quotes) => {
    handleAutoSave(false)

    const newAnswers = quotes
    const isValid =
      newAnswers?.length === choices?.length &&
      new Set(newAnswers).size === newAnswers?.length &&
      newAnswers.every((item) => letters.slice(0, choices?.length).includes(item))
    setIsWarning(!isValid)
    if (isValid) {
      try {
        const payload = {
          assessmentId,
          questionId,
          answers: newAnswers,
        }
        await updateQuestion(payload)
        handleAutoSave(true)
      } catch (error) {
        handleAutoSave(false)
        throw error
      } finally {
        await getQuestionData()
      }
    }
  }, 800)

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const quotes = reorder(state.quotes, result.source.index, result.destination.index)
    setState({ quotes })
    setInput(quotes)
    setAnswers(quotes)
  }
  return (
    <ErrorBoundary>
      <Container>
        <Typography id="label.arrangement.answer" color="primary1" fontSize="text-base">
          Please type the answer as item arrangement
        </Typography>
        <Formik
          initialValues={{
            answers,
          }}
          enableReinitialize
        >
          <Field name="answer">
            {({ field, form }) => {
              return (
                <div>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          <QuoteList quotes={state?.quotes} />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )
            }}
          </Field>
        </Formik>
        {isWarning && (
          <Typography id="label.arrangement.warning" error>
            This answer is not valid.
          </Typography>
        )}
      </Container>
    </ErrorBoundary>
  )
}

const mapState = ({ Language: { locale } }) => ({
  locale,
})
export default connect(mapState, null)(ArrangementAnswerInput)
