// import _get from 'lodash/get'
import { SchoolAPI } from '../../api'

export const SchoolLearnerGroups = {
  state: {
    schoolLearnerGroup: [],
    findLearnerGroups: [],
    learnerGroupDetail: [],
    schoolLearnerGroupsDetailFilter: [],
    countLearnerGroups: 0,
    paramsInUrl: [],
  },
  reducers: {
    setParamsInUrl(state, payload) {
      // console.log(state)
      return {
        ...state,
        paramsInUrl: payload,
      }
    },
    setSchoollearnerGroups(state, payload) {
      // console.log(state)
      return {
        ...state,
        schoolLearnerGroup: payload,
      }
    },
    setFindSchoolLearnerGroups(state, payload) {
      // console.log(state)
      return {
        ...state,
        findLearnerGroups: payload,
      }
    },
    setLearnerGroupDetail(state, payload) {
      // console.log(state)
      return {
        ...state,
        learnerGroupDetail: payload,
      }
    },
    setSchoolLearnerGroupsDetailFilter(state, payload) {
      // console.log(state)
      return {
        ...state,
        schoolLearnerGroupsDetailFilter: payload,
      }
    },

    setcountLearnerGroups(state, payload) {
      // console.log(state)
      return {
        ...state,
        countLearnerGroups: payload,
      }
    },
  },
  effects: () => ({
    async getSchoolLearnerGroups(id) {
      try {
        const data = await SchoolAPI.getLearnerGroups({ id })
        this.setSchoollearnerGroups(data)
      } catch (error) {
        throw error
      }
    },

    async getSchoolLearnerGroupsFilter(payload) {
      try {
        const data = await SchoolAPI.getLearnerGroups(payload)
        this.setSchoollearnerGroups(data)
      } catch (error) {
        throw error
      }
    },

    async getLearnerGroupDetail(payload) {
      try {
        const data = await SchoolAPI.getStudentInSchool(payload)
        this.setLearnerGroupDetail(data)
      } catch (error) {
        throw error
      }
    },

    async getSchoolLearnerGroupsDetailFilter(payload) {
      try {
        const data = await SchoolAPI.getLearnerGroups(payload)
        this.setSchoolLearnerGroupsDetailFilter(data)
        // console.log(data)
      } catch (error) {
        throw error
      }
    },

    async getLearnerGroupDetailFilter(payload) {
      try {
        const data = await SchoolAPI.getLearnerGroups(payload)
        this.setLearnerGroupDetail(data)
      } catch (error) {
        throw error
      }
    },

    async findSchoolLearnerGroups(payload) {
      try {
        // console.log(id)
        const data = await SchoolAPI.findLearnerGroupsbyId(payload)
        this.setFindSchoolLearnerGroups(data)
      } catch (error) {
        throw error
      }
    },
    async getSchoolcountLearnerGroups(id) {
      try {
        const count = await SchoolAPI.countLearnerGroups({ id })
        this.setcountLearnerGroups(count)
        //console.log(count)
      } catch (error) {
        throw error
      }
    },

    async createSchoolLearnerGroups(payload) {
      try {
        const result = await SchoolAPI.createLearnerGroups(payload)
        return result
      } catch (error) {
        throw error
      }
    },

    async updateSchoolLearnerGroupsbyId(payload) {
      try {
        const result = await SchoolAPI.updateLearnerGroupsbyId(payload)
        return result
      } catch (error) {
        throw error
      }
    },
    async deleteSchoolLearnerGroupsbyId(payload) {
      try {
        const result = await SchoolAPI.deleteLearnerGroupsbyId(payload)
        return result
      } catch (error) {
        throw error
      }
    },

    // GET /Schools/{id}/exportStudents
    async exportSchoolStudents(payload) {
      try {
        const result = await SchoolAPI.exportStudents(payload)
        // this.setLearnerGroupDetail(data)
        return result
      } catch (error) {
        throw error
      }
    },
  }),
  //
  selectors: () => ({
    // schoolMemberRole() {
    //   return (rootState, props) => {
    //     const {  } = rootState.SchoolMember
    //     return test
    //   }
    // },
  }),
}
