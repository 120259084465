import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
// import SchoolSettingSetting from 'containers/schoolSettingSetting/loadable'
import ErrorBoundary from '../../components/errorBoundary'
// import Breadcrumb from '../../components/breadcrumb/loadable'
// import { Typography } from '../../components'

// import TabComponent from '../../components/tabComponent/loadable'
// import { menus } from './menus'

// const TabStyle = {
//   backgroundColor: 'transparent',
//   boxShadow: '0 0 0 0',
//   padding: '0',
//   borderBottom: '1px solid rgba(194, 194, 194, 0.5)',
// }

const Container = styled.div`
  padding: 40px 50px;
  padding-bottom: 75px;

  min-height: calc(100vh - 117px);
  overflow-y: scroll;

  @media (max-width: 768px) {
    min-height: calc(100vh - 104px);
    padding: 40px 15px;
    padding-bottom: 75px;
  }
`

class SchoolSettingLayout extends Component {
  static propTypes = {}

  onChangeTab = (path) => {
    const { match } = this.props
    this.props.history.push(match.url + path)
  }

  render() {
    const { children } = this.props
    // const breadcrumb = [
    //   {
    //     label: 'Academy Setting',
    //     link: '',
    //     isActive: true,
    //   },
    // ]
    return (
      <ErrorBoundary>
        {/* <Breadcrumb firstLabel="Academy Profile" link="../" data={breadcrumb} /> */}
        <Container>
          {/* <TabComponent
            style={TabStyle}
            transparent
            tabData={menus}
            activeTab={0}
            onChangeTab={this.onChangeTab}
          />
          {children} */}
          {children}
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapState = (state) => ({
  appUser: state.AppUser.appUser,
})

const mapDispatch = (dispatch) => {
  const {
    AppUser: { login },
  } = dispatch
  return {
    login,
  }
}

export default connect(mapState, mapDispatch)(SchoolSettingLayout)
