import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ErrorBoundary from '../errorBoundary'
import styled from 'styled-components'
import theme from 'theme'

const Container = styled.div`
  div[class$='control'] {
    border-radius: 6px;
    min-height: 48px !important;
    border-color: ${(props) => (props.error ? props.theme.danger : props.theme.black20)};
    box-shadow: none;
    opacity: 80%;
    div {
      font-size: ${(props) => props.theme['text-base']};
      font-weight: 400;
    }
    :hover {
      border-color: ${(props) => props.theme.primary1};
      opacity: 100%;
    }
  }
  div[class$='placeholder'] {
    color: ${theme.black38};
  }
  div[class$='indicatorContainer'] > svg[class$='Svg'] {
    fill: ${(props) => (props.isDisabled ? props.theme.grey : props.theme.black60)};
  }
  div[class$='multiValue'] div:nth-child(2) {
    cursor: pointer;
    &:hover {
      background-color: ${theme.whiteblue};
    }
  }
  div[class$='multiValue'] svg[class$='Svg'] {
    width: 16px;
    height: 16px;
    fill: ${(props) => (props.isDisabled ? props.theme.grey : props.theme.primary1)};
  }
  div[class$='option'] {
    color: ${theme.black87};
    padding-left: 16px;
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    line-height: 32px;
    cursor: pointer;
    &:hover {
      background-color: ${theme.whiteblue};
      color: ${theme.primary1};
      font-weight: 700;
    }
  }
  div[class$='Group'] {
    padding: 0px;
  }
  div[class$='group'] {
    padding: 16px 22px 16px 16px;
    margin: 0px;
    align-self: center;
    height: 48px;
    line-height: 32px;
  }
  span[class$='indicatorSeparator'] {
    background-color: transparent;
  }
`
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  // backgroundColor: '#EBECF0',
  // borderRadius: '2em',
  color: `${theme.black87}`,
  display: 'inline-block',
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '1',
  minWidth: 1,
  // padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span style={groupBadgeStyles}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

const PureInputTag = ({
  options = [],
  handleChange = () => {},
  handleBlur = () => {},
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      '&:hover': {
        border: `1px solid ${theme.primary1}`,
      },
      '&:active': {
        border: `1px solid ${theme.primary1}`,
      },
      fontSize: 14,
    }),
    container: (provided, props) => ({
      ...provided,
      marginTop: '10px',
      width: props.styles?.width || '100%',
      minWidth: '250px',
      fontSize: 14,
      color: theme.primary1,
      // zIndex: 0,
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        fontSize: 14,
        color: theme.black38,
      }
    },
    option: (provided, { isSelected, isFocused }) => {
      return {
        ...provided,
        backgroundColor: isSelected ? '#2684FF' : isFocused ? theme.whiteblue : null,
      }
    },
    menu: (styles) => ({ ...styles, fontSize: 14 }),
    input: (styles) => ({
      ...styles,
      fontSize: 14,
      '& input': {
        font: 'inherit',
      },
    }),
  }
  return (
    <ErrorBoundary>
      <Container>
        <Select
          onBlur={handleBlur}
          options={options}
          onChange={handleChange}
          styles={customStyles}
          formatGroupLabel={formatGroupLabel}
          // menuIsOpen={true}
          {...props}
        />
      </Container>
    </ErrorBoundary>
  )
}

PureInputTag.propTypes = {
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}

export default PureInputTag
