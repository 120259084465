import { ProgramCertificateAPI, SchoolAPI } from 'api'

export const ProgramCertificate = {
  state: {
    programCertificateList: [],
    courses: [],
    programCertificate: {},
    certificateCourses: [],
    courseCertificates: [], // all certificates of a course
    url: null,
  },
  reducers: {
    setProgramCertificateList: (state, payload) => {
      return {
        ...state,
        programCertificateList: payload,
      }
    },
    setCourses: (state, payload) => {
      return {
        ...state,
        courses: payload,
      }
    },
    setProgramCertificate: (state, payload) => {
      return {
        ...state,
        programCertificate: payload,
      }
    },
    setCertificateCourses: (state, payload) => {
      return {
        ...state,
        certificateCourses: payload,
      }
    },
    setCourseCertificates: (state, payload) => {
      return {
        ...state,
        courseCertificates: payload,
      }
    },
    setUrl: (state, payload) => {
      return {
        ...state,
        url: payload,
      }
    },
  },
  effects: (dispatch) => ({
    onSetUrl(url) {
      this.setUrl(url)
    },
    async getProgramCertificate({ id }) {
      try {
        const res = await ProgramCertificateAPI.findById({ id })
        this.setProgramCertificate(res)
        return res
      } catch (e) {
        throw e
      }
    },
    async getProgramCertificates({ schoolId }) {
      try {
        const res = await ProgramCertificateAPI.find({
          filter: {
            where: {
              schoolId,
            },
          },
        })
        this.setProgramCertificateList(res)
        return res
      } catch (e) {
        throw e
      }
    },
    async createProgramCertificate({ data }) {
      try {
        const res = await ProgramCertificateAPI.create(data)
        await this.getProgramCertificates({ schoolId: data.schoolId })
        return res
      } catch (e) {
        throw e
      }
    },
    async getCourseCertificates({ courseId }) {
      try {
        const filter = JSON.stringify({ where: { courseIds: courseId } })
        const res = await ProgramCertificateAPI.find({ filter })
        this.setCourseCertificates(res)
        return res
      } catch (e) {
        throw e
      }
    },
    async editProgramCertificate({ data }) {
      try {
        const res = await ProgramCertificateAPI.update(data)
        await this.getProgramCertificates({ schoolId: data.schoolId })
        return res
      } catch (e) {
        throw e
      }
    },
    async deleteProgramCertificate(id) {
      try {
        await ProgramCertificateAPI.delete({ id })
      } catch (error) {
        throw error
      }
    },
    async getCourses({ schoolId, name, selectedCourseIds }) {
      const where = {}
      if (name) {
        where.name = {
          like: name,
          options: 'i',
        }
      }
      if (selectedCourseIds) {
        where.id = {
          nin: selectedCourseIds,
        }
      }
      try {
        const filter = JSON.stringify({
          where,
          limit: 5,
          skip: 0,
          fields: { id: true, name: true },
        })

        const result = await SchoolAPI.getSchoolCourse({ id: schoolId, filter })
        this.setCourses(result)
      } catch (e) {
        throw e
      }
    },
    async getCourseByIds({ schoolId, courseIds }) {
      try {
        const filter = JSON.stringify({
          where: {
            id: {
              inq: courseIds,
            },
          },
          fields: { id: true, name: true },
        })

        const result = await SchoolAPI.getSchoolCourse({ id: schoolId, filter })
        this.setCertificateCourses(result)
      } catch (e) {
        throw e
      }
    },
    async issueCertificate({ data, schoolId }) {
      try {
        await ProgramCertificateAPI.issueCertificate(data)
        dispatch.Alert.success({
          title: 'Release Successfully',
          description: "We've already send certificate to your students email.",
        })
        await this.getProgramCertificates({ schoolId })
      } catch (e) {
        dispatch.Alert.error({
          title: 'Release Fail',
        })
        throw e
      }
    },
  }),
}
