import React from 'react'
import styled, { css } from 'styled-components'
import { Container, Typography, Card, Button } from '../index'
import ErrorBoundary from 'components/errorBoundary'

const Image = styled.img`
  width: 100%;
  background-color: #c0c0c0;
  height: 159px;
`

const Label = styled.div`
  display: flex;
  padding: 0px 10px;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 8px;

  ${({ status }) => {
    if (status === 'started') {
      return css`
        background-color: #e41313;
        animation: liveStreaming 2s infinite;
      `
    }
    if (status === 'waiting') {
      return css`
        background-color: ${({ theme }) => theme.warning};
      `
    }
    if (status === 'ended')
      return css`
        background-color: #5f5f5f;
      `
    return css``
  }}
`

const Status = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 10px 13px 0px;
  /* position: absolute; */
  /* left: 11px;
  top: 10px; */
  border-radius: 8px 8px 0 0;
  z-index: 1;
  /* font-weight:  */
  background-color: #fff;
  ${({ status, hightligh }) => {
    if (hightligh) {
      if (status === 'started') {
        return css`
          border: solid 1px #e41313;
          ${'' /* animation: borderLiveStreaming 2s infinite; */}
        `
      }
      if (status === 'waiting') {
        return css`
          border: solid 1px ${({ theme }) => theme.warning};
        `
      }
      if (status === 'ended')
        return css`
          border: solid 1px #5f5f5f;
        `
    }
    return css``
  }}
  border-bottom: none;

  @keyframes liveStreaming {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  /* @keyframes borderLiveStreaming {
    0% {
      border: solid 1px #e41313ff;
    }

    50% {
      border: solid 1px #e41313aa;
    }

    100% {
      border: solid 1px #e41313ff;
    }
  } */
`

const _Container = styled((props) => <Container {...props} />)`
  padding: 30px 0px;
`

const Wrapper = styled.div`
  position: relative;
  margin: 20px 0 0;
  /* cursor: pointer; */
  /* border-bottom-left-radius: 0; */
  /* border-bottom-right-radius: 0; */
`

const Section = styled.section``

const _Card = styled((props) => <Card {...props} />)`
  /* border-top-left-radius: 0;
  border-top-right-radius: 0; */
  border-radius: 0 0 8px 8px;
  box-shadow: none;
  padding: 0 10px 10px 20px;

  ${({ status, hightligh }) => {
    if (hightligh) {
      if (status === 'started') {
        return css`
          border: solid 1px #e41313;
          ${'' /* animation: borderLiveStreaming 2s infinite; */}
        `
      }
      if (status === 'waiting') {
        return css`
          border: solid 1px ${({ theme }) => theme.warning};
        `
      }
      if (status === 'ended')
        return css`
          border: solid 1px #5f5f5f;
        `
    }
    return css``
  }}
  border-top: none;
`

const _Button = styled((props) => <Button {...props} />)`
  /* position: absolute; */
  /* right: 30px; */
  /* top: calc(50% - 20px); */
  margin-left: auto;
`

const TooltipWrapper = styled.div`
  width: 140px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  /* padding: 10px 0px; */
  overflow: hidden;
  .link {
    cursor: pointer;
  }
`

const Item = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme['text-sm']};

  :hover {
    background-color: #f3f3f3;
  }
`

const Popup = ({ list }) => {
  return (
    <ErrorBoundary>
      <TooltipWrapper>
        {list.map(({ onClick, menu }, index) => (
          <Item key={index} onClick={onClick}>
            {menu}
          </Item>
        ))}
      </TooltipWrapper>
    </ErrorBoundary>
  )
}

export {
  Status,
  Wrapper,
  Section,
  _Container as Container,
  Image,
  Typography,
  _Card as Card,
  _Button as Button,
  Label,
  Popup,
}
