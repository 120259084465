import React from 'react'
import styled, { css } from 'styled-components'
import theme from 'theme'
import Icon from '../icon'
import { Typography } from '../../components'

const Circle = styled.div`
  // margin: 10px;
  // height: 50px;
  // width: 50px;
  // border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: ${(props) => props.theme.primary1};
  cursor: pointer;
`

const IconPlus = styled(Icon)`
  width: 30px;
  height: 30px;
`

const Content = styled.div`
  width: 100%;
  // margin-bottom: 20px;
  .image-view {
    font-size: ${({ theme }) => theme['text-xs']};
    margin: 10px 0 0 0;
    cursor: pointer;
    display: ${(props) => (props.value ? 'block' : 'none')};
  }
`

const UploadImageStyle = styled.div`
  margin: ${({ styleUploadImage: { margin } }) => (margin ? margin : '10px 0 0 0')};
  outline: none;
  // display: grid;

  /*grid-template-columns: ${({ styleUploadImage: { gridTemplateColumns } }) =>
    gridTemplateColumns ? gridTemplateColumns : '1fr 0.6fr'};
  gap: 67px;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }*/
`

const ImageBox = styled.div`
  position: relative;
  // border: 2px dashed ${(props) => (props.grayBorder ? theme.gray4 : theme.primary1)};
  // border-radius: 8px;
  background-color: rgb(37, 62, 135, 0.05);
  height: ${(props) => props.height && props.height}px;
  justify-content: center;
  align-items: center;
  display: flex;

  ${({ aspectRatio }) => {
    if (aspectRatio) {
      return css`
        width: 100%;
        padding-top: ${100 / aspectRatio}%;
      `
    }
  }}
  img {
    // width: 100%;
  }
  .icon {
    height: 50px;
    width: 50px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ aspectRatio }) => {
    if (aspectRatio) {
      return css`
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      `
    }
  }}
`

const ImgIconCam = styled.img`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
`

const LabelCousenName = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // padding: 40px;
`

const LabelInstructorName = styled.label`
  text-align: left;
  position: absolute;
  bottom: 16%;
  left: 14%;
  @media (max-width: 1366px) {
    bottom: 22%;
    left: 21%;
  }
  @media (max-width: 1152px) {
    bottom: 27%;
    left: 25%;
  }
`

const DivBgCourseImg = styled.div`
  height: auto;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: ${({ cropShape }) => (cropShape === 'round' ? '50%' : '2px')};
  background: transparent
    url('https://dev-api-classwin.dev.witsawa.com/api/Containers/witsawa-classwin/cover/b0c550b0-cc98-494f-b45b-cbde5f804fb1.png?width=400&height=400')
    0% 0% no-repeat padding-box;
  background-size: 100% auto;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ aspectRatio }) => {
    if (aspectRatio) {
      return css`
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      `
    }
  }};
`

const Image = styled.img`
  height: auto;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: ${({ cropShape }) => (cropShape === 'round' ? '50%' : '2px')};
  ${({ aspectRatio }) => {
    if (aspectRatio) {
      return css`
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      `
    }
  }}
`

const ImageCanvas = styled.canvas`
  width: 1110px;
  height: 581px;
  border-radius: ${({ cropShape }) => (cropShape === 'round' ? '50%' : '2px')};
  ${({ aspectRatio }) => {
    if (aspectRatio) {
      return css`
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      `
    }
  }}
`

const Description = styled((props) => <Typography {...props} />)`
  font-size: ${({ theme }) => theme['text-base']};
  margin: 0 30px;
  color: ${(props) => props.theme.grey};
`

export {
  Circle,
  IconPlus,
  Content,
  UploadImageStyle,
  ImageBox,
  Image,
  ImgIconCam,
  DivBgCourseImg,
  Description,
  Container,
  LabelCousenName,
  LabelInstructorName,
  ImageCanvas,
}
