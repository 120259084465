import styled, { css } from 'styled-components'
import Typography from '../typography'

export const EmptyStyle = styled.div`
  display: flex;
  position: relative;
  padding: 30px;
  margin: 20px;
  flex: 1;
  height: ${(props) => (props.height ? props.height : '100%')};
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  button {
    margin-top: 25px;
  }

  .full-image {
    // width: 50%;
    // height: 50%;
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 15px;
  }

  @media (max-width: 768px) and (min-width: 429px) {
    .full-image {
      margin-bottom: 10px;
      max-height: 200px;
      max-width: 200px;
    }
  }

  @media (max-width: 429px) {
    .full-image {
      margin-bottom: 10px;
      max-height: 150px;
      max-width: 150px;
    }
  }

  ${({ small }) => {
    if (small) {
      return css`
        .full-image {
          max-width: 200px;
          max-height: 200px;
          ${'' /* margin-bottom: 15px; */}
        }
        .title {
          font-size: ${({ theme }) => theme['text-sm']};
        }
        .desc {
          font-size: ${({ theme }) => theme['text-xs']};
          margin-top: 5px;
        }
      `
    }
    return css``
  }}

  ${({ medium }) => {
    if (medium) {
      return css`
        .full-image {
          max-width: 258px;
          max-height: 200px;
          margin-bottom: 15px;
        }
        .title {
          font-size: ${({ theme }) => theme['text-xl']};
        }
        .desc {
          font-size: ${({ theme }) => theme['text-sm']};
          margin-top: 5px;
        }
      `
    }
    return css``
  }}

  ${({ large }) => {
    if (large) {
      return css`
        .full-image {
          max-width: 600px;
          // max-height: 600px;
          max-height: 33vh;
          margin-bottom: 15px;
        }

        @media (max-width: 768px) and (min-width: 429px) {
          .full-image {
            max-width: 400px;
            max-height: 400px;
            margin-bottom: 15px;
          }
        }

        @media (max-width: 429px) {
          .full-image {
            max-width: 250px;
            max-height: 250px;
            margin-bottom: 15px;
          }
        }
      `
    }
    return css``
  }}

  ${({ Custom1 }) => {
    if (Custom1) {
      return css`
        .full-image {
          max-width: 300px;
          // max-height: 600px;
          max-height: 33vh;
          margin-top: 75px;
        }
        .title {
          color: ${({ theme }) => theme['primary7']};
          font-size: ${({ theme }) => theme['text-6xl']};
        }
        .desc {
          width: 100%;
          color: ${({ theme }) => theme['black60']};
          font-size: ${({ theme }) => theme['text-lg']};
          margin-top: 5px;
        }
      `
    }
    return css``
  }}
`

export const Desc = styled(Typography)`
  width: 300px;
  font-size: ${({ theme }) => theme['text-lg']};
  margin-top: 10px;

  @media (max-width: 425px) {
    font-size: ${({ theme }) => theme['text-sm']};
  }
`

export const Image = styled.img``

export const EmptyCard = styled.div`
  flex: 1;
  margin: ${({ noMargin }) => (noMargin ? '0px' : '40px')};
  /* background-color: white; */
  border-radius: 8px;

  @media (max-width: 768px) {
    /* padding: 32px 16px; */
    margin: ${({ noMargin }) => (noMargin ? '0px' : '24px')};
  }

  @media (max-width: 426px) {
    margin: 0px;
  }
`
