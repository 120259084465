import React, { Component } from 'react'
import ErrorBoundary from '../errorBoundary'
import { Wrapper, Input, Icon } from './style'
import SendIcon from '../../images/ic-send.svg'

class InputChat extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Wrapper>
          <Input name="chat" id="chat" placeholder="Write a comment..." />
          <Icon src={SendIcon} alt="send" width="20px" />
        </Wrapper>
      </ErrorBoundary>
    )
  }
}

export default InputChat
