import { CourseMarketplacesAPI } from '../../api'
import get from 'lodash/get'
// import sortBy from 'lodash/sortBy'

import _flatMap from 'lodash/flatMap'
import _orderBy from 'lodash/orderBy'
// import { ACCESS_TOKEN } from '../../constants'
export const CourseMarketplaces = {
  state: {
    courseMarketplaces: [],
    courseMarketplacesCategory: [],
    courseMarketplacesAllCount: 0,
    courseMarketplacesById: {},
  },
  reducers: {
    setCourseMarketplaces(state, payload) {
      return {
        ...state,
        courseMarketplaces: payload,
      }
    },
    setCourseMarketplacesById(state, payload) {
      return {
        ...state,
        courseMarketplacesById: payload,
      }
    },
    setCourseMarketplacesAllCount(state, payload) {
      return {
        ...state,
        courseMarketplacesAllCount: payload,
      }
    },
    setCourseMarketplacesCategory(state, payload) {
      return {
        ...state,
        courseMarketplacesCategory: payload,
      }
    },
  },
  effects: () => ({
    async getCourseMarketplaces() {
      const filter = JSON.stringify({
        include: ['category', 'subCategory', 'school'],
        limit: 10,
        skip: 0,
      })
      try {
        const res = await CourseMarketplacesAPI.getSchoolCourseMarketplacesAll({ filter })
        this.setCourseMarketplaces(res)
      } catch (error) {
        throw error
      }
    },
    async getCourseMarketplacesfilter(filterData) {
      // const filter = JSON.stringify({
      //   include: [
      //     'teachers',
      //     // ['school', 'schoolPackages'],
      //     {
      //       relation: 'school',
      //       scope: { include: 'schoolPackage' },
      //     },
      //     'level',
      //     'publishedSubject',
      //     'level',
      //     {
      //       relation: 'enrollments',
      //       scope: {
      //         where: {
      //           appUserId: appUserId || null,
      //         },
      //       },
      //     },
      //   ],
      //   where: {
      //     slug: courseSlug,
      //   },
      // })
      const filter = JSON.stringify({
        include: ['category', 'subCategory', 'school'],
        ...filterData,
        limit: 10,
        skip: 0,
      })
      try {
        const res = await CourseMarketplacesAPI.getSchoolCourseMarketplacesAll({ filter })
        this.setCourseMarketplaces(res)
      } catch (error) {
        throw error
      }
    },
    async getCourseMarketplacesCount() {
      try {
        const res = await CourseMarketplacesAPI.getSchoolCourseMarketplacesAllCount()
        this.setCourseMarketplacesAllCount(res)
      } catch (error) {
        throw error
      }
    },
    async getCourseMarketplacesByid(courseId) {
      // const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN)) || {}
      // const appUserId = token.userId
      // const filter = JSON.stringify({
      //   include: [
      //     'teachers',
      //     'school',
      //     'level',
      //     'publishedSubject',
      //     'level',
      //     {
      //       relation: 'enrollments',
      //       scope: {
      //         where: {
      //           appUserId: appUserId || null,
      //         },
      //       },
      //     },
      //   ],
      // })
      const filter = JSON.stringify({
        include: ['category', 'subCategory', 'school'],
        limit: 10,
        skip: 0,
      })
      const course = await CourseMarketplacesAPI.findById({ id: courseId, filter })
      this.setCourseMarketplacesById(course)
    },
    async getTokenGoogle() {
      try {
        const accessTokenGoogle = window.localStorage.getItem('accessTokenGoogle')
        const isExpired = await this.checkTokenExpired(accessTokenGoogle)
        if (isExpired) {
          window.localStorage.removeItem('accessTokenGoogle')
          return null
        }
        return accessTokenGoogle
      } catch (error) {
        throw new Error(error)
      }
    },
    checkTokenExpired(accessTokenGoogle) {
      const url = `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessTokenGoogle}`
      return new Promise((resolve, reject) => {
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            if (data.error === 'invalid_token') {
              return resolve(true)
            }
            return resolve(false)
          })
          .catch((error) => {
            return reject(error)
          })
      })
    },
    async createCourseMarketplaces(payload) {
      console.log('payload', payload)
      try {
        const accessTokenGoogle = await this.getTokenGoogle()
        console.log('accessTokenGoogle', accessTokenGoogle)
        const _payload = payload
        _payload.youtubeAccessToken = accessTokenGoogle
        const result = await CourseMarketplacesAPI.addToMarketplaces(_payload)
        console.log('result', result)
        return result
      } catch (error) {
        throw new Error(error)
      }
    },
    async getCourseMarketplacesCategory(courseMarketplaceId) {
      try {
        const result = await CourseMarketplacesAPI.getCourseMarketplacesCategory({
          id: courseMarketplaceId,
        })
        this.setCourseMarketplacesCategory(result)
        return result
      } catch (error) {
        throw new Error(error)
      }
    },
  }),
  selectors: (/* slice, createSelector, hasProps */) => ({
    enrolledStatus() {
      return (rootState) => {
        const enrollment = get(
          rootState.CourseMarketplaces.courseMarketplacesById.course,
          'enrollments.0'
        )
        const teachers = get(rootState.CourseMarketplaces.courseMarketplacesById.course, 'teachers')
        const price = get(rootState.CourseMarketplaces.courseMarketplacesById.course, 'price', 0)
        const publicMode = get(
          rootState.CourseMarketplaces.courseMarketplacesById.course,
          'publicMode',
          'CODE'
        )
        const numberOfStudents = get(
          rootState.CourseMarketplaces.courseMarketplacesById.course,
          'numberOfStudents',
          0
        )
        const packageStudentNumber = get(
          rootState.CourseMarketplaces.courseMarketplacesById.course,
          'school.schoolPackage.studentNumber',
          50
        )
        const userId = get(rootState.AppUser.appUser, 'id')
        if ((teachers || []).map((item) => item.id).includes(userId)) {
          return 'teaching'
        }
        if (enrollment) {
          if (enrollment.isApproved && enrollment.isPaid) {
            return 'enrolled'
          }
          if (!enrollment.isApproved) {
            return 'awaiting'
          }
          if (!enrollment.isPaid) {
            return 'notPaid'
          }
        }
        if (publicMode === 'CODE' && price === 0 && numberOfStudents >= packageStudentNumber) {
          return 'full'
        }

        return 'notEnroll'
      }
    },
    lectures() {
      return (rootState) => {
        const lectures = get(
          rootState.CourseMarketplaces.courseMarketplacesById.course,
          'publishedSubject.data.lectures',
          []
        )
        // console.log('tesssst', lectures)
        const subjectWorksheets = get(
          rootState.CourseMarketplaces.courseMarketplacesById.course,
          'publishedSubject.data.subjectWorksheets',
          []
        )

        const merged = _flatMap(
          subjectWorksheets.filter((item) => item.type !== 'practice'),
          ({ publishedWorksheet: { data }, ...rest }) => ({
            ...data,
            ...rest,
          })
        )

        const pretest = merged.filter((item) => item.type === 'pretest')
        const posttest = merged.filter((item) => item.type === 'posttest')
        // console.log('pretest', ...posttest)
        const newContent = _orderBy(
          [
            ...lectures,
            ...merged.filter((item) => item.type !== 'pretest' && item.type !== 'posttest'),
          ],
          ['priority'],
          ['asc']
        )
        // console.log('newContent', [...pretest, ...newContent, ...posttest])
        return [...pretest, ...newContent, ...posttest]
        // const sectionNum = 0
        // const lectureNum = 0
        // return sortBy(lectures, ['priority']).map((lecture) => {
        //   let number
        //   if (lecture.type === 'paragraph') {
        //     sectionNum += 1
        //     number = sectionNum
        //   } else {
        //     lectureNum += 1
        //     number = lectureNum
        //   }
        //   return {
        //     ...lecture,
        //     number,
        //   }
        // })
      }
    },
  }),
}
