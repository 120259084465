import { connect } from 'react-redux'

import { CourseInformationPage } from './index'

const mapState = ({
  SchoolCourse: {
    schoolCourse,
    schoolCourse: { teachers = [], customTeacherList },
  },
  CourseCategories: { courseCategories },
  CourseLevels: { courseLevels },
  AppUser: { appUser },
  Language: { locale },
  TeacherSchool: { teacherList },
}) => ({
  courseCategories,
  courseLevels,
  locale,
  ...schoolCourse,
  _teachers: teachers.map((teacher) => teacher.id),
  customTeacherList,
  isPublished: true,
  appUser,
  teacherList: teacherList.map((item) => ({
    id: item.appUser.id,
    name: `${item.appUser.firstname} ${item.appUser.lastname}`,
    profileImage: item.appUser.profileImage,
    skill: '',
  })),
})

const mapDispatch = ({
  SchoolCourse: { getMyCourseById },
  Course: { updateCourseById },
  CourseCategories: { getCourseCategories },
  CourseLevels: { getCourseLevels },
  teachingCourse: { editCourse },
  Confirm,
  Alert,
  TeacherSchool: { getTeacherList },
}) => ({
  Alert,
  getMyCourseById,
  updateCourse: updateCourseById,
  getCourseLevels,
  getCourseCategories,
  Confirm,
  editCourse,
  unpublishCourse: updateCourseById,
  getCourse: getMyCourseById,
  deleteDraft: () => ({}),
  getTeacherList,
})

export default connect(mapState, mapDispatch)(CourseInformationPage)
