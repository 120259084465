import styled from 'styled-components'

const Container = styled.div``

const Image = styled.div`
  height: 180px;
  background-image: ${(props) =>
    `linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(${props.src})`};
  background-position: center;
  background-size: cover;
  /* border-radius: 10px 10px 0 0; */
`

export { Container, Image }
