import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { FileAPI } from 'api'
import { connect } from 'react-redux'
import UploadComponent from '../uploadComponent'
import { Typography, Cropable, Label, Modal } from '../index'
import {
  Content,
  UploadImageStyle,
  ImageBox,
  Image,
  Circle,
  IconPlus,
  // Description,
  Container,
} from './style'
import { customUploadModal } from './customUploadModal'
import getImageUrl from '../../utils/getImageUrl'
import PlusIcon from '../../images/icons/primary-plus.svg'

const UploadImage = ({
  id,
  label,
  value,
  onChange,
  name,
  height,
  afterUpload,
  error,
  touched,
  isCrop = false,
  cropShape = 'rect',
  aspect,
  grayBorder,
  styleImageBox = {},
  styleUploadImage = {},
  showImageFull = true,
  cropLabel = '',
  cropLabelId,
  cropModal,
  icon = PlusIcon,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [fileType, setFileType] = useState(null)

  const onClickView = () => {
    setIsOpen(true)
  }

  const renderLightbox = () => {
    return (
      <Lightbox
        mainSrc={getImageUrl(value, null, 1000, 524)}
        onCloseRequest={() => setIsOpen(false)}
      />
    )
  }

  const beforeCrop = (formData) => {
    const file = formData.get('file')
    const objectURL = URL.createObjectURL(file)
    setFile(objectURL)
    setFileType(file.type)
  }

  const afterCrop = async (blob) => {
    try {
      const file = await fetch(blob)
        .then((res) => res.blob())
        .then((blob) => {
          let filename = 'filename.jpeg'
          if (fileType === 'image/png') {
            filename = 'filename.png'
          }
          const newFile = new File([blob], filename, { type: fileType })
          return newFile
        })
      const formData = new FormData()
      formData.append('file', file)

      const res = await FileAPI.upload(formData)

      if (afterUpload) {
        afterUpload({ name: res.result.files.file[0].name })
      }

      if (onChange) {
        console.log('🌃', res.result.files.file[0].name)
        console.log(res.result.files.file[0].name)
        const e = {
          target: {
            value: res.result.files.file[0].name,
            name: 'image',
          },
        }
        onChange(e)
      }
    } catch (error) {
      throw error
    } finally {
      setFile(null)
    }
  }

  return (
    <Content value={value}>
      {isOpen && renderLightbox()}
      {label && (
        <Label
          id={id}
          htmlFor={name}
          isRequired={props.isRequired}
          bold={props.bold}
          color={props.color}
        >
          {label}
        </Label>
      )}
      {isCrop ? (
        cropModal ? (
          <>
            <UploadComponent
              limitSize={5242880} // 5 MB
              afterUpload={(image) => {
                onChange(name, image.name)
              }}
              beforeCrop={beforeCrop}
              acceptFileTypes={['image/jpeg', 'image/png']}
            >
              <UploadImageStyle styleUploadImage={styleUploadImage} className="upload-image">
                <ImageBox style={styleImageBox} grayBorder={grayBorder} aspectRatio={aspect}>
                  {value ? (
                    <Image
                      cropShape={cropShape}
                      src={getImageUrl(value, null, 1000, 524)}
                      alt="img-upload"
                      aspectRatio={aspect}
                    />
                  ) : (
                    <Container aspectRatio={aspect}>
                      <Circle>
                        <IconPlus src={icon} alt="plus-icon" />
                      </Circle>
                      <Typography id="button.upload.img" color="primary1">
                        Upload Image
                      </Typography>
                    </Container>
                  )}
                </ImageBox>
                {/* <Description label2>{''}</Description> */}
              </UploadImageStyle>
            </UploadComponent>
            <Modal
              isOpen={file}
              overlayStyle={customUploadModal.overlay}
              contentStyle={customUploadModal.content}
            >
              <Cropable
                isModal={cropModal}
                fileType={fileType}
                image={file}
                afterCrop={afterCrop}
                cropShape={cropShape}
                aspect={aspect}
                cropLabel={cropLabel}
                cropLabelId={cropLabelId}
                handleClearFile={() => setFile(null)}
              />
            </Modal>
          </>
        ) : (
          <>
            <Cropable
              isModal={cropModal}
              fileType={fileType}
              image={file}
              afterCrop={afterCrop}
              cropShape={cropShape}
              aspect={aspect}
              cropLabel={cropLabel}
              handleClearFile={() => setFile(null)}
            >
              <UploadComponent
                limitSize={5242880} // 5 MB
                afterUpload={(image) => {
                  onChange(name, image.name)
                }}
                beforeCrop={beforeCrop}
                acceptFileTypes={['image/jpeg', 'image/png']}
              >
                <UploadImageStyle styleUploadImage={styleUploadImage} className="upload-image">
                  <ImageBox style={styleImageBox} grayBorder={grayBorder} aspectRatio={aspect}>
                    {value ? (
                      <Image
                        cropShape={cropShape}
                        src={getImageUrl(value, null, 1000, 524)}
                        alt="img-upload"
                        aspectRatio={aspect}
                      />
                    ) : (
                      <Container aspectRatio={aspect}>
                        <Circle>
                          <IconPlus src={icon} alt="plus-icon" />
                        </Circle>
                        <Typography color="primary1">Upload Image</Typography>
                      </Container>
                    )}
                  </ImageBox>
                  {/* <Description label2>{''}</Description> */}
                </UploadImageStyle>
              </UploadComponent>
            </Cropable>
          </>
        )
      ) : (
        <UploadComponent
          afterUpload={(image) => {
            onChange(name, image.name)
          }}
          acceptFileTypes={['image/jpeg', 'image/png']}
        >
          <UploadImageStyle styleUploadImage={styleUploadImage} className="upload-image">
            <ImageBox height={height} grayBorder={grayBorder}>
              {value ? (
                <Image
                  className="image"
                  src={getImageUrl(value, null, 1000, 524)}
                  alt=""
                  style={{ height: height - 30, objectFit: 'cover' }}
                />
              ) : (
                <Container aspectRatio={aspect}>
                  <Circle>
                    <IconPlus src={icon} alt="plus-icon" />
                  </Circle>
                  <Typography color="primary1">Upload Image</Typography>
                </Container>
              )}
            </ImageBox>
            {/* <Description label2>{''}</Description> */}
          </UploadImageStyle>
        </UploadComponent>
      )}
      {error && touched && (
        <Typography margin="5px 0px" strong error>
          {error}
        </Typography>
      )}
      {showImageFull && (
        <Typography
          id="label.fullimage"
          className="image-view"
          color="primary13"
          onClick={onClickView}
        >
          Click to view full image
        </Typography>
      )}
    </Content>
  )
}

UploadImage.defaultProps = {
  label: '',
  detail: 'Click or Drag photo to this area to upload',
  onChange: () => null,
  cropShape: '',
  aspect: '',
}

const mapDispatch = ({ Alert }) => ({ Alert })

export default connect(null, mapDispatch)(UploadImage)
