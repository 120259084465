/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useDeepCompareEffect } from 'react-use'
import { FileAPI } from 'api'
import Alert from 'components/alert/loadable'

import ErrorBoundary from 'components/errorBoundary'

const UploadTag = styled.div`
  align-self: center;
`

const UploadComponent = ({
  readOnly,
  handleChange,
  acceptFileTypes,
  children,
  afterUpload,
  onStartLoad,
  onStopLoad,
  limitSize,
  showLoading = true,
  beforeCrop,
  fileExcel,
}) => {
  const [alert, setAlert] = useState({
    isShow: false,
    loading: false,
    success: false,
    error: false,
    title: '',
    description: '',
  })

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: acceptFileTypes,
    maxSize: limitSize,
    onDropRejected: (files) => {
      let result = {
        title: '',
        description: '',
      }
      files.map((file) =>
        file.errors.map(({ code, message: description }) => {
          console.log('ℹ️', code, description)
          switch (code) {
            case 'file-invalid-type':
              result = {
                title: 'File type is not support',
                description,
              }
              break
            case 'file-too-large': {
              const regex = /(\d+)([a-zA-Z\W]+)/g
              result = {
                title: 'File is too large',
                description: description.replace(
                  regex,
                  (match, p1) => `${(parseInt(p1) / 1000000).toFixed(2)} MB`
                ),
              }
              break
            }
            default:
              result = {
                title: 'Error',
                description,
              }
              break
          }
          return null
        })
      )
      // console.error(file)
      setAlert({
        ...alert,
        ...result,
      })
      return false
    },
  })
  useDeepCompareEffect(() => {
    const hasFile = acceptedFiles.length
    if (hasFile) {
      const formData = new FormData()
      const file = acceptedFiles[0]
      // if (file.size <= 209715200) { // 200 MB
      if (limitSize && file.size > limitSize) {
        setAlert({
          ...alert,
          title: 'Too large file',
          description: "You couldn't upload the file because it was too large",
        })
        return false
      }
      formData.append('file', file)
      if (/video\/*/.test(file.type)) {
        uploadVideo(formData)
      } else if (fileExcel) {
        upload(file)
      } else {
        beforeCrop ? beforeCrop(formData) : uploadFile(formData)
      }
    }
  }, [acceptedFiles])

  const uploadFile = async (formData, duration = '') => {
    try {
      if (showLoading) {
        setAlert({
          ...alert,
          title: 'Loading...',
          loading: true,
          success: false,
          error: false,
          isShow: true,
        })
      }
      const res = await FileAPI.upload(formData)
      console.log(res)

      if (afterUpload) {
        const data = {
          name: res.result.files.file[0].name,
          duration,
        }
        if (res.result.files.file[0].originalFilename)
          data.originalFilename = res.result.files.file[0].originalFilename
        afterUpload(data)
      }

      if (handleChange) {
        const e = {
          target: {
            value: res.result.files.file[0].name,
            name: 'filenameText',
          },
        }
        handleChange(e)
      }
    } catch (e) {
      throw e
    } finally {
      setAlert({
        ...alert,
        isShow: false,
      })
    }
  }

  const uploadVideo = async (formData) => {
    try {
      if (showLoading) {
        setAlert({
          ...alert,
          title: 'Loading...',
          loading: true,
          success: false,
          error: false,
          isShow: true,
        })
      }
      if (onStartLoad) {
        onStartLoad()
      }

      const res = await FileAPI.uploadVideo(formData)
      if (afterUpload) {
        const file = formData.get('file')
        afterUpload({
          name: `https://vimeo.com${res.uri.replace('/videos', '')}`,
          fileName: file.name,
        })
      }

      if (handleChange) {
        const e = {
          target: {
            value: `https://vimeo.com${res.uri.replace('/videos', '')}`,
            name: 'filenameText',
          },
        }
        handleChange(e)
      }
    } catch (e) {
      throw e
    } finally {
      if (onStopLoad) {
        onStopLoad()
      }
      setAlert({
        ...alert,
        isShow: false,
      })
    }
  }
  const upload = async (file, duration = '') => {
    try {
      if (showLoading) {
        setAlert({
          ...alert,
          title: 'Loading...',
          loading: true,
          success: false,
          error: false,
          isShow: true,
        })
      }
      console.log('file', file)
      afterUpload(file)
    } catch (e) {
      throw e
    } finally {
      setAlert({
        ...alert,
        isShow: false,
      })
    }
  }

  return (
    <ErrorBoundary>
      <UploadTag>
        <div {...getRootProps()}>
          {!readOnly && <input {...getInputProps()} />}
          {children}
        </div>
      </UploadTag>
      <Alert
        isOpen={alert.isShow}
        error={alert.error}
        success={alert.success}
        loading={alert.loading}
        title={alert.title}
        description={alert.description}
        btnText={alert.btnText}
        showBtn={alert.showBtn}
        stayOpen={alert.stayOpen}
      />
    </ErrorBoundary>
  )
}

UploadComponent.defaultProps = {
  limitSize: 104857600, // 100 MB
  acceptFileTypes: [
    'application/pdf',
    'image/*',
    'audio/*',
    'video/*',
    '.xlsx',
    '.xls',
    '.csv',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
  ],
}

UploadComponent.propTypes = {
  afterUpload: PropTypes.func,
  handleChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
  acceptFileTypes: PropTypes.arrayOf(
    PropTypes.oneOf([
      'application/pdf',
      'image/jpeg',
      'image/png',
      'image/*',
      'audio/*',
      'video/*',
      '.xlsx',
      '.xls',
      '.csv',
      '.doc',
      '.docx',
      '.ppt',
      '.pptx',
    ])
  ),
  limitSize: PropTypes.number,
}

export default UploadComponent
