/* eslint-disable no-unused-vars */
import React from 'react'
import { Typography } from 'components'
import styled, { css } from 'styled-components'
import Icon from '../../../../components/icon/loadable'
import IconClose from '../../../../images/icons/DarkIcon/White-close.svg'
import LectureNavigation from '../Lecture/LectureNavigation'
import PrePostTestItem from '../Lecture/PrePostTest'
import windowSize from 'react-window-size'

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 70px 0px 60px 0px;
  @media (max-width: 429px) {
    padding: 0px 16px 0px 16px;
    margin: 0px 0px 0px 0px;
    background-color: #222223;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  //padding: 20px 16px 20px 16px;
  min-height: 100%;
  width: 480px;
  @media (max-width: 429px) {
    width: 100%;
    background-color: #222223;
  }
`
function LectureDetailMobile(props) {
  const { setIsDetail, data, preTest, postTest, isMobile, courseProject, isLine } = props
  const heightWindow = window.innerHeight
  return (
    <Container>
      <Body height={heightWindow}>
        <div>
          {preTest && <PrePostTestItem {...preTest} key={preTest?.id} />}
          {data?.map((item) => {
            return <LectureNavigation item={item} key={item?.id} />
          })}
          {postTest && <PrePostTestItem {...postTest} key={postTest?.id} isPostTest />}
        </div>
      </Body>
    </Container>
  )
}

export default windowSize(LectureDetailMobile)
