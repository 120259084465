import { Button } from 'components'
import CheckBoxPure from 'components/CheckBoxPure'
import ErrorBoundary from 'components/errorBoundary'
import { Typography } from 'components/typography/style'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'

import getImageUrl from 'utils/getImageUrl'
import { Avatar } from 'components/avatar/style'

// const Heading = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
//   background: #fff;
//   border-top: 1px solid ${(props) => props.theme.gray8};
//   padding: 10px 50px;
//   z-index: 5;
//   min-height: 60px;

//   @media (max-width: 768px) {
//     padding: 10px 15px;
//     button {
//       padding: 6px;
//     }
//   }
// `
const Content = styled.div`
  padding: 40px 50px;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`
const MainContent = styled.div`
  border-radius: 8px;
  background-color: white;
  padding: 20px 30px 50px;
`
const LineSection = styled.hr`
  border: 0;
  height: 0;
  margin: 10px 0px 15px;
  border-top: 1px solid rgba(166, 166, 166, 0.5);
`
const FooterContent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 0px 0px;
`
const Member = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 20px;
`
const displayWidth = 40
const imageWidth = displayWidth * 2
const allFalse = {
  dashboard: false,
  course: false,
  createAndViewCourse: false,
  viewCourse: false,
  editCourse: false,
  member: false,
  payment: false,
  exportTeacher: false,
  addNewTeacher: false,
  approveLearner: false,
  exportLearner: false,
  addNewLearner: false,
  certificate: false,
  createEditCertificate: false,
  releaseCertificate: false,
  report: false,
  exportReports: false,
  manageEmails: false,
  information: false,
  packages: false,
}
const allTrue = {
  dashboard: true,
  course: true,
  createAndViewCourse: true,
  viewCourse: true,
  editCourse: true,
  member: true,
  payment: true,
  exportTeacher: true,
  addNewTeacher: true,
  approveLearner: true,
  exportLearner: true,
  addNewLearner: true,
  certificate: true,
  createEditCertificate: true,
  releaseCertificate: true,
  report: true,
  exportReports: true,
  manageEmails: true,
  information: true,
  packages: true,
}
function PermissionSetting({
  memberById,
  getSchoolMemberById,
  updatePermissionMember,
  Alert,
  locale,
}) {
  const params = useParams()
  const history = useHistory()
  const { appUser } = memberById
  const isTh = locale === 'th'

  const [checkBox, setCheckBox] = useState(allFalse)
  const [checkAll, setCheckAll] = useState(false)

  const onSelectAll = () => {
    setCheckAll(!checkAll)
    setCheckBox(allFalse)

    if (!checkAll) {
      setCheckBox(allTrue)
    }
  }
  const onCheckBox = (value) => {
    setCheckBox({
      ...checkBox,
      [value]: !checkBox?.[value],
    })
    onValidCourseCheckBox(value)
    onValidMemberCheckBox(value)
    onValidCertificateCheckBox(value)
    onValidReportCheckBox(value)
  }
  const onValidCourseCheckBox = (value) => {
    if (value === 'course' && checkBox?.course) {
      setCheckBox({
        ...checkBox,
        [value]: false,
        createAndViewCourse: false,
        viewCourse: false,
        editCourse: false,
      })
    } else if (value === 'course' && !checkBox?.course) {
      setCheckBox({
        ...checkBox,
        [value]: true,
        createAndViewCourse: true,
        viewCourse: true,
        editCourse: true,
      })
    } else if (
      value === 'createAndViewCourse' ||
      value === 'viewCourse' ||
      value === 'editCourse'
    ) {
      setCheckBox({
        ...checkBox,
        course: true,
        [value]: !checkBox?.[value],
      })
    }
  }
  const onValidMemberCheckBox = (value) => {
    if (value === 'member' && checkBox?.member) {
      setCheckBox({
        ...checkBox,
        [value]: false,
        exportTeacher: false,
        addNewTeacher: false,
        approveLearner: false,
        exportLearner: false,
        addNewLearner: false,
      })
    } else if (value === 'member' && !checkBox?.member) {
      setCheckBox({
        ...checkBox,
        [value]: true,
        exportTeacher: true,
        addNewTeacher: true,
        approveLearner: true,
        exportLearner: true,
        addNewLearner: true,
      })
    } else if (
      value === 'exportTeacher' ||
      value === 'addNewTeacher' ||
      value === 'approveLearner' ||
      value === 'exportLearner' ||
      value === 'addNewLearner'
    ) {
      setCheckBox({
        ...checkBox,
        member: true,
        [value]: !checkBox?.[value],
      })
    }
  }

  const onValidCertificateCheckBox = (value) => {
    if (value === 'certificate' && checkBox?.certificate) {
      setCheckBox({
        ...checkBox,
        [value]: false,
        createEditCertificate: false,
        releaseCertificate: false,
      })
    } else if (value === 'createEditCertificate' || value === 'releaseCertificate') {
      setCheckBox({
        ...checkBox,
        certificate: true,
        [value]: !checkBox?.[value],
      })
    } else if (value === 'certificate' && !checkBox?.certificate) {
      setCheckBox({
        ...checkBox,
        [value]: true,
        createEditCertificate: true,
        releaseCertificate: true,
      })
    }
  }
  const onValidReportCheckBox = (value) => {
    if (value === 'report' && checkBox?.report) {
      setCheckBox({
        ...checkBox,
        [value]: false,
        exportReports: false,
        manageEmails: false,
      })
    } else if (value === 'report' && !checkBox?.report) {
      setCheckBox({
        ...checkBox,
        [value]: true,
        exportReports: true,
        manageEmails: true,
      })
    } else if (value === 'exportReports' || value === 'manageEmails') {
      setCheckBox({
        ...checkBox,
        report: true,
        [value]: !checkBox?.[value],
      })
    }
  }

  const onConfirm = async (value) => {
    try {
      await updatePermissionMember(value)
      Alert.success({ title: isTh ? 'แก้ไขสำเร็จ' : 'Success' })
    } catch (e) {
      Alert.error({ title: isTh ? 'เกิดข้อผิดพลาด' : 'Error' })
      console.log(e)
    }
  }

  useEffect(() => {
    const getMemberPermission = async () => {
      await getSchoolMemberById(params)
    }
    getMemberPermission()
  }, [getSchoolMemberById, params])
  useEffect(() => {
    const getPermission = () => {
      setCheckBox(memberById?.permissions)
      const isAll = Object.values(memberById?.permissions || allFalse).every((value) => value)
      setCheckAll(isAll)
    }
    getPermission()
  }, [memberById])
  useEffect(() => {
    if (_.isEqual(checkBox, allTrue)) {
      setCheckAll(true)
    } else {
      setCheckAll(false)
    }
  }, [checkBox])

  return (
    <ErrorBoundary>
      <React.Fragment>
        <Content>
          <MainContent>
            <Member>
              <Avatar
                size="small"
                src={getImageUrl(
                  appUser?.profileImage,
                  `https://via.placeholder.com/${imageWidth}`,
                  imageWidth,
                  imageWidth
                )}
                alt="profile-img"
                margin="0 8px 0 0"
              />
              <Typography h3>{`${appUser?.firstname} ${appUser?.lastname}`}</Typography>
            </Member>
            <CheckBoxPure
              id="all"
              label={isTh ? 'เลือกทั้งหมด' : 'Select All'}
              onClick={onSelectAll}
              isAll={true}
              isChecked={checkAll}
            />
            <CheckBoxPure
              id="dashboard"
              label={isTh ? 'เมนูแดชบอร์ด' : 'Dashboard'}
              name="dashboard"
              isChecked={checkBox?.dashboard}
              textDesc={isTh ? 'อนุญาตให้เข้าถึงเมนูแดชบอร์ด' : 'Allow user to see dashboard'}
              onClick={onCheckBox}
            />
            <LineSection />

            <CheckBoxPure
              id="course"
              name="course"
              label={isTh ? 'เมนูคอร์ส' : 'Course'}
              isChecked={checkBox?.course}
              onClick={onCheckBox}
              textDesc={isTh ? 'อนุญาตให้เข้าถึงเมนูคอร์ส' : 'Allow user to access course menu'}
            />

            <CheckBoxPure
              id="createAndViewCourse"
              name="createAndViewCourse"
              label={isTh ? 'สร้างและเห็นคอร์สทั้งหมด' : 'Create and view all courses'}
              isSub={true}
              isChecked={checkBox?.createAndViewCourse}
              onClick={onCheckBox}
              textDesc={
                isTh
                  ? 'สามารถสร้างคอร์สใหม่และมองเห็นคอร์สทั้งหมดของสถาบันได้'
                  : 'Allow user to create a new course and see all courses in school includes other teacher courses'
              }
            />
            <CheckBoxPure
              id="viewCourse"
              name="viewCourse"
              label={isTh ? 'เห็นคอร์สทั้งหมด' : 'View all courses'}
              isSub={true}
              isChecked={checkBox?.viewCourse}
              onClick={onCheckBox}
              textDesc={
                isTh
                  ? 'สามารถมองเห็นคอร์สทั้งหมดของสถาบันได้'
                  : 'Allow user to see all courses in school includes other teacher courses'
              }
            />
            <CheckBoxPure
              id="editCourse"
              name="editCourse"
              label={isTh ? 'ตั้งค่าคอร์ส' : 'Edit all courses'}
              isSub={true}
              isChecked={checkBox?.editCourse}
              onClick={onCheckBox}
              textDesc={
                isTh
                  ? 'สามารถเข้าถึงการตั้งค่าคอร์สได้'
                  : 'Allow user to edit all courses in school includes other teacher courses'
              }
            />

            <LineSection />
            <CheckBoxPure
              id="member"
              label={isTh ? 'เมนูสมาชิก' : 'Member'}
              name="member"
              isChecked={checkBox?.member}
              onClick={onCheckBox}
              textDesc={isTh ? 'อนุญาตให้เข้าถึงเมนูสมาชิก' : 'Allow user to access member menu'}
            />
            <CheckBoxPure
              id="exportTeacher"
              label={isTh ? 'ดาวน์โหลดรายชื่อผู้สอน' : 'Export teacher list'}
              name="exportTeacher"
              isSub={true}
              isChecked={checkBox?.exportTeacher}
              onClick={onCheckBox}
              textDesc={
                isTh ? 'สามารถดาวน์โหลดรายชื่อผู้สอนได้' : 'Allow user to export teacher list'
              }
            />
            <CheckBoxPure
              id="addNewTeacher"
              label={isTh ? 'เชิญผู้สอน' : 'Add new teacher'}
              name="addNewTeacher"
              isSub={true}
              isChecked={checkBox?.addNewTeacher}
              onClick={onCheckBox}
              textDesc={isTh ? 'สามารถเชิญผู้สอนรายใหม่ได้' : 'Allow user to add a new teacher'}
            />
            <CheckBoxPure
              id="approveLearner"
              label={isTh ? 'รับหรือปฏิเสธผู้เรียน' : 'Approve learner'}
              name="approveLearner"
              isSub={true}
              isChecked={checkBox?.approveLearner}
              onClick={onCheckBox}
              textDesc={
                isTh
                  ? 'สามารถเลือกรับหรือปฏิเสธผู้เรียนรายใหม่ได้'
                  : 'Allow user to approve a new learner'
              }
            />
            <CheckBoxPure
              id="exportLearner"
              label={isTh ? 'ดาวน์โหลดรายชื่อผู้เรียน' : 'Export learner list'}
              name="exportLearner"
              isSub={true}
              isChecked={checkBox?.exportLearner}
              onClick={onCheckBox}
              textDesc={
                isTh ? 'สามารถดาวน์โหลดรายชื่อผู้เรียนได้' : 'Allow user to export learner list'
              }
            />
            <CheckBoxPure
              id="addNewLearner"
              label={isTh ? 'เชิญผู้เรียน' : 'Add new learner'}
              name="addNewLearner"
              isSub={true}
              isChecked={checkBox?.addNewLearner}
              onClick={onCheckBox}
              textDesc={isTh ? 'สามารถเชิญผู้เรียนรายใหม่ได้' : 'Allow user to add a new learner'}
            />
            <LineSection />
            <CheckBoxPure
              id="payment"
              label={isTh ? 'เมนูการชำระเงิน' : 'Payment'}
              name="payment"
              isChecked={checkBox?.payment}
              onClick={onCheckBox}
              textDesc={
                isTh ? 'อนุญาตให้เข้าถึงเมนูการชำระเงินได้' : 'Allow user to access Payment menu'
              }
            />
            <LineSection />
            <CheckBoxPure
              id="certificate"
              label={isTh ? 'เมนูประกาศนียบัตร' : 'Certificate'}
              name="certificate"
              isChecked={checkBox?.certificate}
              onClick={onCheckBox}
              textDesc={
                isTh ? 'อนุญาตให้เข้าถึงเมนูประกาศนียบัตร' : 'Allow user to access Certificate menu'
              }
            />
            <CheckBoxPure
              id="createEditCertificate"
              label={isTh ? 'สร้างและแก้ไขประกาศนียบัตร' : 'Create and edit certificate'}
              name="createEditCertificate"
              isChecked={checkBox?.createEditCertificate}
              onClick={onCheckBox}
              isSub={true}
              textDesc={
                isTh
                  ? 'สามารถสร้างและแก้ไขประกาศนียบัตรได้'
                  : 'Allow user to create and edit certificate'
              }
            />
            <CheckBoxPure
              id="releaseCertificate"
              label={isTh ? 'ออกประกาศนียบัตร' : 'Release certificate'}
              name="releaseCertificate"
              isChecked={checkBox?.releaseCertificate}
              onClick={onCheckBox}
              isSub={true}
              textDesc={isTh ? 'สามารถออกประกาศนียบัตรได้' : 'Allow user to release a certificate'}
            />
            <LineSection />

            <CheckBoxPure
              id="report"
              label={isTh ? 'เมนูรายงาน' : 'Report'}
              name="report"
              isChecked={checkBox?.report}
              onClick={onCheckBox}
              textDesc={isTh ? 'อนุญาตให้เข้าถึงเมนูรายงาน' : 'Allow user to access Report menu'}
            />
            <CheckBoxPure
              id="exportReports"
              label={isTh ? 'ดาวน์โหลดรายงาน' : 'Export courses report'}
              name="exportReports"
              isChecked={checkBox?.exportReports}
              onClick={onCheckBox}
              isSub={true}
              textDesc={isTh ? 'สามารถดาวน์โหลดรายงานได้' : 'Allow user to export courses report'}
            />
            <CheckBoxPure
              id="manageEmails"
              label={isTh ? 'จัดการอีเมล' : 'Manage emails'}
              name="manageEmails"
              isChecked={checkBox?.manageEmails}
              onClick={onCheckBox}
              isSub={true}
              textDesc={
                isTh
                  ? 'สามารถจัดการอีเมลผู้รับรายงานประจำสัปดาห์ได้'
                  : "Allow user to manage weekly report receiver's email"
              }
            />
            <LineSection />
            <CheckBoxPure
              id="information"
              label={isTh ? 'เมนูข้อมูล' : 'Information'}
              name="information"
              isChecked={checkBox?.information}
              onClick={onCheckBox}
              textDesc={
                isTh ? 'อนุญาตให้เข้าถึงเมนูข้อมูล' : 'Allow user to access Information menu'
              }
            />
            <LineSection />
            <CheckBoxPure
              id="packages"
              label={isTh ? 'เมนูแพ็กเกจ' : 'Packages'}
              name="packages"
              isChecked={checkBox?.packages}
              onClick={onCheckBox}
              textDesc={
                isTh ? 'อนุญาตให้เข้าถึงเมนูแพ็กเกจได้' : 'Allow user to access Packages menu'
              }
            />
            <LineSection />
            <FooterContent>
              <div style={{ marginRight: 32 }}>
                <Button id="button.cancel" secondary onClick={() => history.goBack()}>
                  Cancel
                </Button>
              </div>

              <Button
                id="button.confirm"
                primary
                onClick={() => onConfirm({ payload: checkBox, id: params?.memberId })}
              >
                Confirm
              </Button>
            </FooterContent>
          </MainContent>
        </Content>
      </React.Fragment>
    </ErrorBoundary>
  )
}
const mapState = ({ Language: { locale }, SchoolMember: { memberById } }) => ({
  locale,
  memberById,
})
const mapDispatch = ({ SchoolMember: { getSchoolMemberById, updatePermissionMember }, Alert }) => ({
  getSchoolMemberById,
  updatePermissionMember,
  Alert,
})
export default connect(mapState, mapDispatch)(PermissionSetting)
