/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useState } from 'react'
import styled from 'styled-components'
import ErrorBoundary from '../../../components/errorBoundary'
import { Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { withRouter } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { createCurriculumData2 } from '../../../static'
import PureIcon from 'components/icon'
import ArrowIcon from 'images/icons/CaretDown.svg'
import AddIcon from 'images/icons/primary-plus.svg'
import SectionIcon from 'images/icons/Section.svg'
import closeIcon from 'images/icons/Gray-X.svg'
import DeleteIcon from 'images/icons/TrashColor.svg'
import DotIcon from 'images/icons/DotsSixVertical.svg'
import ButtonDialog from './buttonDialog'
import { CSSTransition } from 'react-transition-group'
import { InputIcon, Icon, ContentBody, IconArrow } from './style'
import {
  Input,
  Card,
  Alert,
  Empty,
  Button,
  Typography,
  InputWithLabel,
  Container,
} from '../../../components'
import {
  SectionWrapper,
  ButtonDialogWrapper,
  HoverCircleButton,
  IconSection,
  SeperateLine,
  Title,
} from '../style'
import ConfirmModal from '../../../components/confirmModal/loadable'
import LectureNew from './LectureNew'
const CardSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  //background-color: yellow;
  position: relative;
  /* padding-bottom: 10px;
  margin-bottom: 10px; */
`
const Content = styled.section`
  i.eva-hover {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
`
const CourseListCard = styled(Card)`
  padding: 24px 24px 24px 16px;
  background-color: #e0e6f6;
  margin: 24px 0px 24px 0px;

  border-color: ${({ isSelected, theme }) => (isSelected ? theme.primary1 : 'none')};
  border-left-width: 16px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  :hover {
    border-color: ${({ theme }) => theme.primary1};
  }
`
// const CourseListCard = styled.div`
//   padding: 24px 24px 24px 16px;
//   background-color: #e0e6f6;
//   margin: 24px 0px 24px 0px;

//   border-color: ${({ isSelected, theme }) => (isSelected ? theme.primary1 : 'none')};
//   border-left-width: 16px;
//   :hover {
//     border-color: ${({ theme }) => theme.primary1};
//   }
// `
const DragHandle = styled.div`
  align-self: center;
`
function SectionNew(props) {
  const {
    item,
    name,
    index,
    isDragging,
    handleSelected,
    selectedId,
    id: lectureId,
    isSelected,
    addNewContent,
    priority,
    locale,

    match: { path },
    updateLecture,
    subjectId,
    removeLectureById,
    onChange,
  } = props
  //console.log('item', item)
  const [showConfirm, setShowConfirm] = useState(false)
  const [isLoadding, setIsLoadding] = useState(false)
  const [showButtonAdd, setShowButtonAdd] = useState(false)
  const [showRemove, setShowRemove] = useState(false)
  const [isAlert, setIsAlert] = useState(false)
  const [isError, setIsError] = useState(false)
  const [titleAlert, setTitleAlert] = useState('')
  const isCourseSetting = /\/schools\/:schoolId\/curriculum\/manage.*/.test(path)

  const debounceInput = debounce(async (name) => {
    try {
      const payload = {
        subjectId,
        lectureId,
        priority,
        name,
      }
      await updateLecture(payload)
      // handleAutoSave(true)
      await setTitleAlert(locale === 'en' ? 'Updated Successful' : 'บันทึกเรียบร้อย')
      await setIsAlert(true)
    } catch (error) {
      // handleAutoSave(false)
      throw error
    }
  }, 1000)
  const handleRemove = async () => {
    const payload = {
      subjectId,
      lectureId,
    }
    try {
      setIsLoadding(true)
      await removeLectureById(payload)
      const deleteLecture = item?.contents.map((item) => {
        const payload1 = {
          subjectId,
          lectureId: item?.id,
        }
        return removeLectureById(payload1)
      })
      await Promise.all(deleteLecture)
    } catch (error) {
      throw error
    } finally {
      onChange()

      setShowConfirm(false)
      setIsLoadding(false)
    }
  }
  const test = item?.id === selectedId ? [item] : null
  return (
    <Draggable draggableId={lectureId} index={index}>
      {(provided, snapshot, dragProvided) => (
        <ErrorBoundary>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
          >
            <CourseListCard isSelected={test?.length} isDragging={snapshot.isDragging}>
              <Formik
                initialValues={{
                  name,
                }}
                enableReinitialize
                onSubmit={async (values) => {
                  debounceInput(values?.name)
                }}
              >
                {() => (
                  <Form>
                    <>
                      <CardSection
                        onMouseLeave={() => setShowRemove(false)}
                        onClick={() => {
                          setShowButtonAdd(showButtonAdd)
                          handleSelected(lectureId)
                        }}
                        isSelected={isSelected(lectureId)}
                        isDragging={snapshot.isDragging}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <Title>
                            <PureIcon src={SectionIcon} />
                            <SeperateLine />

                            <InputIcon onMouseEnter={() => setShowRemove(true)}>
                              <Field name="name">
                                {({ field }) => {
                                  return (
                                    <div style={{ width: '100%' }}>
                                      <Input
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          backgroundColor: 'transparent',
                                        }}
                                        id="name"
                                        name="name"
                                        value={field.value}
                                        //onChange={(e) => {}}
                                        type="text"
                                        placeholder="Section"
                                        fontSize="text-lg"
                                        fontWeight="bold"
                                        lineHeight={2}
                                        padding="0px"
                                        autoFocus
                                        noBorder
                                        color="primary1"
                                      />
                                    </div>
                                  )
                                }}
                              </Field>
                            </InputIcon>
                          </Title>
                        </div>
                        {showRemove && (
                          <IconSection>
                            <IconSection>
                              <Icon onClick={() => setShowConfirm(true)}>
                                <img
                                  src={DeleteIcon}
                                  alt="remove-icon"
                                  width="23px"
                                  height="23px"
                                />
                              </Icon>
                            </IconSection>
                          </IconSection>
                        )}

                        <DragHandle isDragging={snapshot.isDragging} {...provided.dragHandleProps}>
                          <img src={DotIcon} alt="drag-handle-icon" />
                        </DragHandle>
                      </CardSection>
                      <CSSTransition
                        in={isSelected(lectureId)}
                        // appear={isSelected(lectureId)}
                        timeout={10}
                        classNames="section-body"
                        unmountOnExit
                        onEnter={() => console.log('onEnter 🌅')}
                        onExited={() => console.log('onExited 🌅')}
                      >
                        <ContentBody
                          // {...dragHandle}
                          isSelected={isSelected(lectureId)}
                          isCourseSetting={isCourseSetting}
                          isDragging={isDragging}
                          isDragDisabled
                          column="1"
                          gap="15px"
                          padding="40px 50px 20px 25px"
                        >
                          <Card
                            padding="40px 32px 40px 32px"
                            style={{
                              border: '1px solid #253e87',
                              borderRadius: '4px',
                              marginBottom: '40px',
                              display: 'flex',
                              flexDirection: 'column',
                              padding: '40px 32px 40px 32px',
                              backgroundColor: 'white',
                            }}
                          >
                            <PureIcon
                              onClick={() => handleSelected('')}
                              style={{
                                width: 25,
                                height: 25,
                                position: 'absolute',
                                right: 65,
                                top: 60,
                              }}
                              src={closeIcon}
                              alt="arrow-icon"
                            />
                            <Field name="name">
                              {({ field, form }) => {
                                return (
                                  <>
                                    {' '}
                                    <Container column="1">
                                      <Content
                                        style={{
                                          display: 'inline-flex',
                                          alignItems: 'center',
                                          gap: 8,
                                          marginBottom: 28,
                                        }}
                                      >
                                        <IconArrow
                                          // onClick={this.handleExpandCard}
                                          onClick={() => handleSelected('')}
                                          src={ArrowIcon}
                                          rotate="180deg"
                                          alt="arrow-icon"
                                        />
                                        {/* <PureIcon
                                src={LectureIcon}
                                style={{ position: 'relative', marginRight: 7 }}
                              /> */}
                                        <Typography
                                          style={{ cursor: 'pointer' }}
                                          //onClick={this.handleExpandCard}
                                          h4
                                          bold
                                          width="100%"
                                        >
                                          {/* {isTh ? 'เลคเชอร์' : 'Lecture'} */}
                                          {locale === 'en' ? 'Section' : 'บทเรียน'}
                                          {/* <CardTitle>{field.value}</CardTitle> */}
                                        </Typography>
                                      </Content>
                                    </Container>
                                    <InputWithLabel
                                      translateId="label.title"
                                      id="name"
                                      name="name"
                                      label="Title"
                                      type="text"
                                      htmlFor="name"
                                      placeholder="Section"
                                      value={field.value}
                                      autoFocus
                                      onChange={(e) => {
                                        form.handleChange(e)
                                        //this.inputChange(e)
                                        //this.setState({ value: e.target.value })
                                        //onChangeTitleName(e.target.value, priority)
                                      }}
                                      // color="primary1"
                                      bold={false}
                                    />
                                    {form.errors && (
                                      <Typography color="danger">
                                        {form.errors[field.name]}
                                      </Typography>
                                    )}
                                  </>
                                )
                              }}
                            </Field>
                            {/* <Field name="name">{({ field, form }) => <>ss</>}</Field> */}
                            {/* <Wrapper /> */}

                            <hr
                              style={{
                                color: '#cbd5e1',
                                backgroundColor: '#cbd5e1',
                                marginTop: '24px',
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '12px',
                              }}
                            >
                              <Button id="button.save" type="submit" primary width={171} center>
                                Save
                              </Button>
                            </div>
                            <Alert
                              isOpen={isAlert}
                              error={isError}
                              onRequestClose={() => {
                                setIsAlert(false)
                                setTitleAlert('')
                              }}
                              title={titleAlert}
                            />
                          </Card>
                        </ContentBody>
                      </CSSTransition>
                      {selectedId === lectureId && (
                        <SectionWrapper>
                          <img src={AddIcon} alt="add-icon" />
                          <ButtonDialogWrapper>
                            <ButtonDialog
                              onClick={addNewContent}
                              data={createCurriculumData2}
                              priority={priority}
                            >
                              <img src={AddIcon} alt="add-icon" />
                            </ButtonDialog>
                          </ButtonDialogWrapper>
                        </SectionWrapper>
                      )}
                    </>
                  </Form>
                )}
              </Formik>
              <LectureNew
                subjectId={subjectId}
                lecture={item?.contents}
                selectedId={selectedId}
                sectionId={lectureId}
              />
              <ConfirmModal
                isOpen={showConfirm}
                onRequestClose={() => setShowConfirm(false)}
                title={
                  locale === 'en'
                    ? 'Are you sure you want to delete?'
                    : 'คุณแน่ใจหรือไม่ว่าต้องการลบ?'
                }
                // subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry…."
                btn1={
                  <Button id="button.confirmModal" onClick={handleRemove} primary>
                    Submit
                  </Button>
                }
                btn2={
                  <Button id="button.cancel" secondary onClick={() => setShowConfirm(false)}>
                    Cancel
                  </Button>
                }
              />
              <Alert
                isOpen={isLoadding}
                onRequestClose={() => setIsLoadding(false)}
                title={locale === 'en' ? 'Loading' : 'กำลังโหลด'}
                loading
              />
            </CourseListCard>
          </div>
        </ErrorBoundary>
      )}
    </Draggable>
  )
}

const mapState = ({ Language: { locale } }) => ({
  locale,
})

const mapDispatch = ({ Subject: { updateLecture, removeLectureById, fetchLectureInfo } }) => ({
  updateLecture,
  removeLectureById,
  fetchLectureInfo,
})

export default connect(mapState, mapDispatch)(withRouter(SectionNew))
