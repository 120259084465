import React from 'react'
import styled from 'styled-components'
import { menus } from './menus'
import PageHeading from '../../components/pageHeading/loadable'
import Breadcrumb from '../../components/breadcrumb/loadable'
import SideBar from './sideBar'
import ErrorBoundary from '../../components/errorBoundary'
import { LargeDesktop, MobileAndTablet } from '../../utils/displayResponsive'

const breadcrumb = [
  {
    label: 'cookie',
    link: '/cookie',
    mobileLabel: 'นโยบายคุกกี้และข้อมูลส่วนตัว',
    isMobile: true,
    isActive: true,
  },
]

const LayoutContainer = styled.div`
  display: flex;
  padding: 40px 150px;
  gap: 24px;
`

const CookieLayout = (props) => {
  const { children } = props
  // const selectPath = history.location.pathname.split('/')[1]
  return (
    <ErrorBoundary>
      <LargeDesktop>
        <PageHeading text="นโยบายคุกกี้และข้อมูลส่วนตัว" />

        <LayoutContainer>
          <SideBar menus={menus} />
          {children}
        </LayoutContainer>
      </LargeDesktop>
      <MobileAndTablet>
        <Breadcrumb data={breadcrumb} />
        {children}
      </MobileAndTablet>
    </ErrorBoundary>
  )
}

export default CookieLayout
