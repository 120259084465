import React, { Component, Fragment } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import get from 'lodash/get'
// import PageHeading from '../../components/pageHeading/loadable'
//import { Link } from 'react-router-dom'
// import Breadcrumb from '../../components/breadcrumb'
import TabComponent from '../../components/tabComponent/loadable'
//import SideCourseHeading from '../../components/sideCourseHeading/loadable'
import getImageUrl from '../../utils/getImageUrl'
import { getShortName } from '../../utils/utils'
import { Empty } from '../../components/index'
//import { MobileAndTablet, Mobile } from '../../utils/displayResponsive'
//import Typography from '../../components/typography'
//import Avatar from '../../components/avatar'
// import Icon from '../../components/icon/loadable'
// import OnlineIcon from '../../images/icon_Online.png'
// import ClassIcon from '../../images/ic-classroom-blue-24-px.svg'
//import ArrowIcon from '../../images/ic-next-white-24-px.svg'
//import { courseTypes } from '../../constants'
import { menus } from './menus'
// eslint-disable-next-line import/no-unresolved
import CourseTitleHeading from '../../components/courseTitleHeading'
import CourseAlert from '../../components/courseAlert'
//import NextLecture from '../../components/NextLecture'
import isEqual from 'lodash/isEqual'
import YourProgress from '../../components/yourProgress'
import moment from 'moment'

const Container = styled.div`
  // display: grid;
  padding: 0px 32px;
  grid-template-columns: 1fr 265px;
  grid-gap: 35px;
  ${({ showSide }) => {
    if (!showSide)
      return css`
        grid-template-columns: minmax(0, 1fr);
      `
    return css``
  }}
  /* grid-template-columns: ${({ showSide }) =>
    !showSide ? '1fr' : 'calc(100% - 295px) 265px'}; */

    @media (max-width: 1024px) {
    display: block;
  }

  @media (max-width: 820px) {
    padding: 0px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }

  @media (max-width: 425px) {
    padding: 0px;
  }
`

// const Prev = styled.div`
//   width: 100%;
//   position: absolute;
//   z-index: 2;
//   img.reverse {
//     fill: #fff;
//     margin-right: 0px;
//     transform: rotate(180deg);
//     width: 30px;
//     margin: 5px 5px 5px 0px;
//   }
// `

// const Content = styled.div`
//   padding: 16px;
//   // height: 170px;
//   display: flex;
//   flex-direction: column;
//   background-color: #fff;
//   @media (min-width: 1024px) {
//     padding: 16px 50px;
//   }
//   // justify-content: space-between;
// `
// const Image = styled.div`
//   height: 180px;
//   background-image: ${(props) => `url(${props.src})`};
//   background-position: center;
//   background-size: cover;
//   /* border-radius: 10px 10px 0 0; */
// `
// const Name = styled(Typography)`
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   margin-bottom: 3px;
// `
// const Description = styled(Typography)`
//   /* // height: 32px; */
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   /* -webkit-line-clamp: 2; */
// `

// const Profile = styled(Avatar)`
//   position: absolute;
//   top: 150px;
//   right: 16px;
//   box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.4);
// `
// const IconWithText = styled.div`
//   display: flex;
//   align-items: center;
//   color: ${(props) => (props.primary ? props.theme.primary : props.theme.grey)};
//   font-size: ${({ theme }) => theme['text-sm']};
//   img {
//     margin-right: 5px;
//   }
// `
// const Bg = styled.div`
//   width: 100%;
//   height: 100px;
//   // background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 90%);
//   opacity: 0.4;
//   position: absolute;
// `

// const largeDesktop = window.matchMedia('(max-width: 1024px)')
const TabStyle = {
  backgroundColor: 'white',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  // boxShadow: '0 5px 5px 0 rgba(0,0,0,0.1)',
  // padding: `${largeDesktop.matches ? '0px 20px' : '0 50px'}`,
  // borderTop: '1px solid #eef0ff',
  '@media (max-width: 1024px)': {
    padding: '0 20px',
  },
}

const CourseCard = styled.div`
  width: 100%;
  padding: 0px 150px;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0px 0px;
  }
  @media (max-width: 920px) {
    width: 100%;
  }
  @media (max-width: 820px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0px 0px;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`

//const displayWidth = 60
//const imageWidth = displayWidth * 2

class MyCourseDetailLayout extends Component {
  state = {
    isOpenModal: false,
    isOpenedCongratulation: false,
    isOpenConfirmModal: false,
    isOpenReviewModal: false,

    isLoading: true,
    loading: true,
    test: {
      id: '',
      title: '',
      instruction: '',
      attemp: 0,
      type: '',
    },
  }

  getCourseAssignment() {
    const { myAssignmentList, match } = this.props
    const result = myAssignmentList.filter(
      (myAssign) =>
        myAssign.courseId === match.params.courseId && moment().isAfter(myAssign.publishDate)
    )
    //console.log({ myAssignmentList })
    return result
  }

  componentDidMount() {
    const { getMyCourseById, match, getCourseLateProgress } = this.props
    getMyCourseById(match.params.courseId)
    getCourseLateProgress(match.params.courseId)
    const { getMyAssignmentList } = this.props
    getMyAssignmentList().finally(() => this.setState({ loading: false }))
  }

  componentDidUpdate(prevProps) {
    const { getMyCourseById, match, location, courseLateProgress, enrollments } = this.props
    if (
      !isEqual(courseLateProgress, prevProps.courseLateProgress) ||
      !isEqual(enrollments, prevProps.enrollments)
    ) {
      const { courseAssignment, courseProject } = courseLateProgress?.summary?.summary || {}
      if (courseAssignment && courseProject && enrollments.length > 0) {
        let fullAssignment = false
        let fullProject = false
        if (courseAssignment) {
          fullAssignment = courseAssignment.full === courseAssignment.current
        }
        if (courseProject) {
          fullProject = courseProject.full === courseProject.current
        }
        // TODO
        // console.log(fullAssignment)
        // console.log(fullProject)
        this.setState({ isOpenedCongratulation: fullAssignment && fullProject })
      }
    }
    if (prevProps.location.pathname !== location.pathname) {
      getMyCourseById(match.params.courseId)
    }
  }

  onChangeTab = (path) => {
    const { match } = this.props
    this.props.history.push(match.url + path)
  }

  isShowSide = () => {
    const { location } = this.props
    const result = location.pathname.match(/\/my-course\/courses\/(:?[a-z0-9]*?)/i)
    const tabSelect = location.pathname.split('/').pop()
    if (tabSelect === 'lecture') {
      return result !== null
    }
    return false
  }

  render() {
    const {
      children,
      myCourse,
      myCourseError,
      //hideArrow,
      enrollments: [enrollment],
      courseGrade,
      certStatus,
      courseLateProgress,
    } = this.props
    const { isOpenedCongratulation } = this.state
    const teacherSlug = (get(myCourse, 'teachers') || []).map((item) => {
      return {
        id: item.id,
        slug: item.slug,
        firstname: item.firstname,
        lastname: item.lastname,
        profileImage: item.profileImage,
      }
    })
    const customTeacher = get(myCourse, 'customTeacherList')
    const TeachersList = (customTeacher || []).map((item) =>
      item.id ? teacherSlug.find((t) => t.id === item.id) : item
    )

    const defaultLogo =
      'https://dev-api-classwin.dev.witsawa.com/api/Containers/witsawa-classwin/cover/b8dfabc5-d28c-4526-bee8-de52cba937b0.png?width=400&height=400'
    const data = {
      title: myCourse.name,
      shortDescription: myCourse.shortDescription,
      image: getImageUrl(myCourse.image),
      teacherImage:
        getImageUrl(TeachersList[0]?.profileImage) ||
        getImageUrl(get(myCourse, 'teachers[0].profileImage')),
      teacherId: TeachersList[0]?.id || get(myCourse, 'teachers[0].id'),
      teacherSlug: TeachersList[0]?.slug || get(myCourse, 'teachers[0].slug'),
      teacherName:
        TeachersList[0]?.name ||
        getShortName(get(myCourse, 'teachers[0]', { firstname: '', lastname: '' })),
      schoolName: get(myCourse, 'school.name'),
      schoolId: get(myCourse, 'school.id'),
      schoolSlug: get(myCourse, 'school.slug'),
      schoolLogo: getImageUrl(get(myCourse, 'school.logo'), defaultLogo),
      type: myCourse.type,
      courseSlug: get(myCourse, 'slug'),
      estimateTimeUsageHr: get(myCourse, 'estimateTimeUsageHr'),
      estimateTimeUsageMin: get(myCourse, 'estimateTimeUsageMin'),
      category: get(myCourse, 'subCategory'),
    }

    // const breadcrumb = [
    //   {
    //     label: data.title,
    //     isMobile: true,
    //     isActive: true,
    //     link: '/my-course/courses',
    //   },
    // ]

    if (myCourseError) {
      return (
        <Empty title="Cannot access this course" desc="You have to enroll this course before." />
      )
    }
    const notiAss = menus.map((item) => {
      if (item.label === 'Assignment') {
        return { ...item, notiCount: this.getCourseAssignment().length }
      }
      return item
    })

    return (
      <Fragment>
        {/* <MobileAndTablet>
          {!hideArrow && (
            <Mobile>
              <Prev>
                <Bg>
                  {breadcrumb
                    .filter((item) => item.isMobile)
                    .map((item) => (
                      <Link key={item.label} className="flex" to={item.link}>
                        <img className="reverse" src={ArrowIcon} alt="arrow-icon" />
                      </Link>
                    ))}
                </Bg>
              </Prev>
            </Mobile>
          )}
          <div>
            {/* <Image
              src={getImageUrl(
                myCourse.flatImage || myCourse.image,
                'https://via.placeholder.com/350x150'
              )}
            />
            <Profile
              width={displayWidth}
              src={getImageUrl(
                data.teacherImage,
                `https://via.placeholder.com/${imageWidth}`,
                imageWidth,
                imageWidth
              )}
            /> 
             <Content>
              <IconWithText primary>
                <Icon src={data.type === 'online' ? OnlineIcon : ClassIcon} alt="online-icon" />
                <Typography color="primary2">{courseTypes[data.type]}</Typography>
              </IconWithText>
              <Link to={`/courses/${get(myCourse, 'slug')}`}>
                <Name h4>{data.title}</Name>
              </Link>
              <Description label2 seeMore numberOfLines={2}>
                {data.shortDescription}
              </Description>
            </Content> 
          </div>
        </MobileAndTablet> */}
        <YourProgress {...data} {...courseLateProgress} />
        <CourseAlert
          isOpenedCongratulation={isOpenedCongratulation}
          enrollment={enrollment}
          courseGrade={courseGrade}
          certStatus={certStatus}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CourseCard>
            <CourseTitleHeading {...data} {...courseLateProgress} />
            <div
              style={{
                boxShadow:
                  '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
                borderRadius: '10px',
                backgroundColor: 'white',
                paddingBottom: '20px',
                marginBottom: '80px',
              }}
            >
              <TabComponent
                style={TabStyle}
                transparent
                tabData={notiAss}
                activeTab={0}
                onChangeTab={this.onChangeTab}
                headBorder={true}
              />
              <div style={{ height: '20px' }}></div>
              <Container showSide={this.isShowSide()}>
                {children}
                {/* {this.isShowSide() && (
                <LargeDesktop>
                  <div>
                    <SideCourseHeading {...data} />
                  </div>
                </LargeDesktop>
              )} */}
              </Container>
            </div>
          </CourseCard>
        </div>
        {/* <NextLecture style={{ position: 'fixed', bottom: '0px' }} /> */}
        {/* <Container showSide={this.isShowSide()}>
          {children}
          {this.isShowSide() && (
            <LargeDesktop>
              <div style={{ gridColumn: '2' }}>
                <SideCourseHeading {...data} />
              </div>
            </LargeDesktop>
          )}
        </Container> */}
      </Fragment>
    )
  }
}

const mapState = ({
  MyCourse: { myCourse, myCourseError, hideArrow, courseLateProgress },
  Enrollment: { enrollments },
  AppUser: { courseGrade, certStatus },
  MyAssignment: { myAssignmentList },
}) => ({
  myCourse,
  myCourseError,
  hideArrow,
  courseLateProgress,
  enrollments,
  courseGrade,
  certStatus,
  myAssignmentList,
})

const mapDispatch = ({
  MyAssignment: { getMyAssignmentList },
  MyCourse: { getMyCourseById, getCourseLateProgress },
}) => ({
  getMyCourseById,
  getMyAssignmentList,
  getCourseLateProgress,
})

export default connect(mapState, mapDispatch)(MyCourseDetailLayout)
