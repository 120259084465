import { CourseMarketplacePayments } from '../../api'

export const CourseMarketplacePayment = {
  state: {
    courseMarketplacesPromptpayQrcode: {},
  },
  reducers: {
    // setCourseMarketplacesOrdersById(state, payload) {
    //   return {
    //     ...state,
    //     courseMarketplacesOrdersById: payload,
    //   }
    // },
    setCourseMarketplacesPromptpayQrcode(state, payload) {
      return {
        ...state,
        courseMarketplacesPromptpayQrcode: payload,
      }
    },
  },

  effects: () => ({
    async getCourseMarketplacesPromptpayQrcode(payload) {
      try {
        const course = await CourseMarketplacePayments.getCourseMarketplacesPromptpayQrcode(payload)
        this.setCourseMarketplacesPromptpayQrcode(course)
        return course
      } catch (error) {
        throw new Error(error)
      }
    },
  }),
}
