import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { Input, Label, Typography } from '../../components'
import { formatInput } from '../../utils/commaize'
import theme from 'theme'

const Container = styled.div`
  div[class$='control'] {
    height: 100%;
    border-radius: 0 8px 8px 0;
  }
  div[class$='indicatorContainer'] > svg[class$='Svg'] {
    fill: ${(props) => (props.isDisabled ? props.theme.grey : props.theme.primaryFont)};
  }
  .error {
    padding-top: 10px;
    color: ${(props) => props.theme.danger};
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

const TextLabel = styled(Label)`
  margin-bottom: 0px;
  display: block;
  p {
    color: ${(props) => props.theme.primaryFont};
  }
  font-size: ${({ theme }) => theme['text-sm']};
  grid-column: span 2;
`

const InputPrice = styled(Input)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 48px;
  border: 1px solid ${theme.black20};
  color: ${theme.black87};
`
const Description = styled(Typography)`
  font-size: ${({ theme }) => theme['text-xs']};
  line-height: 10px;
  margin-bottom: 15px;
  grid-column: span 2;
  color: ${(props) => props.theme.grey};
`

// with Formik
export const customReactSelect = ({
  id,
  descId,
  label,
  name,
  value,
  error,
  touched,
  disable = false,
  SelectHeader,
  isSelect,
  minValue = 0,
  minWidth = 250,
  containerStyle = {},
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: `${(props) => (props.error ? props.theme.danger : props.theme.black20)}`,
    }),
    container: (provided) => ({
      width: '33%',
      minWidth: `${minWidth}px`,
      ...provided,
      ...containerStyle,
    }),
    placeholder: (provided, props) => ({
      ...provided,
      fontSize: 14,
      color: props.isDisabled ? theme.black38 : theme.black20,
    }),
    //   dropdownIndicator: (provided, props) => ({
    //     ...provided,
    //     fill: props.isDisabled ? 'red' : '#707070',
    //     // transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    //   }),
  }
  const handleChange = (event) => {
    if (isSelect) {
      props.onChange(name, event)
    } else {
      let label
      let value = get(event, 'target.value', '')
      // if (value === 0 || value === '0') {
      //   label = 'Free'
      // }
      if (value) {
        value = value.replace(/\D/g, '')
        label = formatInput(value)
      } else {
        label = ''
      }
      const obj = { label, value: +value }
      props.onChange(name, obj)
    }
  }

  const handleBlur = () => {
    props.onBlur(name, true)
  }
  return (
    <Container {...props} style={{ ...props.style }}>
      {id && <TextLabel id={id}>{label}</TextLabel>}
      {descId && <Description id={descId}>Set a tuition fee for this course</Description>}
      {SelectHeader && <SelectHeader />}
      {isSelect ? (
        <Select
          isDisabled={props.isDisabled}
          isOptionDisabled={props.isOptionDisabled}
          styles={customStyles}
          id=""
          options={props.options}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          placeholder={props.placeholder}
          isSearchable={false}
        />
      ) : (
        <InputPrice
          disabled={disable}
          name={name}
          type="text"
          value={value?.label}
          min={minValue}
          onChange={handleChange}
        />
      )}
      {error && error.value && touched && <div className="error">{error.value}</div>}
    </Container>
  )
}

customReactSelect.defaultProps = {
  name: '',
  options: [
    { label: 'Technology', value: 'teachnology' },
    { label: 'Data', value: 'data' },
    { label: 'Design', value: 'design' },
  ],
  placeholder: 'Select...',
}

customReactSelect.propTypes = {
  name: PropTypes.string,
}

export default customReactSelect
