import sortBy from 'lodash/sortBy'
import { WorksheetAPI } from '../../api'

const initialValues = {
  noTag: { total: 0, selected: 0, items: [] },
  require: { total: 0, selected: 0, items: [] },
  optional: { total: 0, selected: 0, items: [] },
}

export const AssessmentQuestion = {
  state: {
    questions: {},
    newLectureQuestions: {},
    worksheetQuestions: [],
    selectQuestionTag: initialValues,
  },
  reducers: {
    setQuestionList(state, payload) {
      const q = payload.reduce((obj, item) => {
        return { ...obj, [item.id]: item }
      }, {})
      return {
        ...state,
        questions: {
          ...state.questions,
          ...q,
        },
      }
    },
    setSelectQuestionTag(state, payload) {
      return {
        ...state,
        selectQuestionTag: payload,
      }
    },
    setWorksheetQuestions(state, payload) {
      return {
        ...state,
        worksheetQuestions: payload,
      }
    },
    setQuestion(state, payload) {
      return {
        ...state,
        questions: {
          ...state.questions,
          [payload.id]: {
            ...state.questions[payload.id],
            ...payload,
          },
        },
      }
    },
    removeQuestion(state, payload) {
      const questions = {
        ...state.questions,
      }
      delete questions[payload]
      return {
        ...state,
        questions,
      }
    },
    setNewLectureQuestion(state, payload) {
      return {
        ...state,
        newLectureQuestions: {
          ...state.newLectureQuestions,
          [payload.id]: payload,
        },
      }
    },
    removeNewLectureQuestion(state, payload) {
      const newLectureQuestions = {
        ...state.newLectureQuestions,
      }
      delete newLectureQuestions[payload]
      return {
        ...state,
        newLectureQuestions,
      }
    },
    clearNewLectureQuestions(state) {
      return {
        ...state,
        newLectureQuestions: {},
      }
    },
  },
  effects: () => ({
    async getCountQuestions(id) {
      try {
        const { count } = await WorksheetAPI.getCountQuestions({ id })
        return count
      } catch (error) {
        throw error
      }
    },
    async handleSelectQuestionTag(payload) {
      try {
        this.setSelectQuestionTag(payload)
      } catch (error) {
        throw error
      }
    },
    async getQuestions({ assessmentId, limit = 0, skip = 0 }) {
      const filter = JSON.stringify({
        limit,
        skip,
        order: 'priority',
      })
      const result = await WorksheetAPI.getQuestionList({ id: assessmentId, filter })
      this.setWorksheetQuestions(result)
      this.setQuestionList(result)
      return result
    },
    async addQuestion({ assessmentId, ...data }) {
      const result = await WorksheetAPI.addQuestion({ id: assessmentId, ...data })
      this.setQuestion(result)
      return result
    },
    async updateQuestion({ assessmentId, questionId, ...data }) {
      try {
        const result = await WorksheetAPI.updateQuestion({ id: assessmentId, questionId, ...data })
        this.getQuestions({ assessmentId })
        return result
      } catch (e) {
        throw e
      }
    },
    async deleteQuestion({ assessmentId, questionId }) {
      const result = await WorksheetAPI.deleteQuestion({ id: assessmentId, questionId })
      this.removeQuestion(questionId)
      return result
    },
    async updateQuestionPriority({ id, questions }) {
      await WorksheetAPI.updateQuestionPriority({ id, questions })
      this.getQuestions({ assessmentId: id })
    },
  }),
  selectors: () => ({
    questions() {
      return (rootState, props) => {
        const { questions } = rootState.AssessmentQuestion
        const list = Object.values(questions).filter(
          (item) => item.worksheetId === props.assessmentId
        )
        return sortBy(list, 'priority')
      }
    },
    lectureTestQuestions() {
      return (rootState, props) => {
        const { questions } = rootState.AssessmentQuestion
        const { lectures } = rootState.Subject
        const { lectureTest } = rootState.Assessment
        const lecture = lectures.find((item) => item.id === props.lectureId)
        const content = lectures.find(
          (item) => item.priority > lecture.priority && item.rank === +props.rank
        )
        const contentId = content.id
        const test = Object.values(lectureTest).find(
          (item) => item.lectureId === contentId && item.type === props.testType
        )

        const list = Object.values(questions).filter((item) => item.worksheetId === test.id)
        return sortBy(list, 'priority')
      }
    },
    newLectureQuestions() {
      return (rootState, props) => {
        const { newLectureQuestions } = rootState.AssessmentQuestion
        const list = Object.values(newLectureQuestions).filter(
          (item) => item.worksheetId === props.assessmentId
        )
        return sortBy(list, 'priority')
      }
    },
  }),
}
