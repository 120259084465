import React, { useMemo } from 'react'
import ErrorBoundary from './errorBoundary'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

import en from '../languages/en-US.json'
import th from '../languages/th-TH.json'

const LanguageProvider = ({ locale, children }) => {
  const messages = { th, en }
  const finalLocale = useMemo(() => {
    let final = locale.split('-')[0] || 'en'
    if (final !== 'th' && final !== 'en') {
      final = 'en'
    }
    return final
  }, [locale])

  return (
    <ErrorBoundary>
      <IntlProvider locale={finalLocale} key={finalLocale} messages={messages[finalLocale]}>
        {React.Children.only(children)}
      </IntlProvider>
    </ErrorBoundary>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

const mapState = (state) => ({
  locale: state.Language.locale,
})

export default connect(mapState)(LanguageProvider)
