import React from 'react'
import styled from 'styled-components'
import background from '../../images/UnAuthBG.png'
import { Typography } from 'components'

// export const Logo = styled.img.attrs({
//   src: logo,
//   alt: 'logo',
// })`
//   position: absolute;
//   bottom: 10px;
//   left: 10px;
//   width: 158px;
//   height: 62px;
// `
export const LayoutContainer = styled.div`
  display: flex;
`

export const Content = styled.div`
  position: absolute;
  // overflow: auto;
  /* height: 100vh; */
  /* left: 200px; */
  left: 0;
  right: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  @media (max-width: 768px) {
    left: 0;
  }
`

export const Hero = styled.div`
  /* background-image: linear-gradient(to top, #12348dd9, #598cffd9), url(${background}); */
  /* background-size: cover; */
  border-radius: 14px;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 580px;
  max-width: 470px;
  top: 50%;
  left: 400px;
  transform: translate(-50%, -50%);
  /* margin: 0 15px; */
  background-color: rgba(255, 255, 255);

  backdrop-filter: blur(5px);
  /* padding: 20px; */

  @media screen and (max-width: 1024px) {
    padding: 0px;
    width: 70%;
    max-height: 580px;
  }
  @media screen and (max-width: 820px) {
    position: fixed;
    border-radius: 14px;
    width: 100%;
    height: 100%;
    max-height: 680px;
    max-width: 470px;
    top: 50%;
    left: 50%;
  }
  @media screen and (max-width: 426px) {
    padding: 0px;
    width: 95%;
    max-height: 560px;
  }
`
export const FormContainer = styled.div`
  border-radius: 8px;
  position: absolute;
  /* max-width: 360px; */
  width: 100%;
  z-index: 2;
  /* right: 5%; */
  height: 100%;
  /* top: -5%; */
`
export const UnauthorizedContainer = styled.div`
  /* background-image: url(${background}); */
  background-size: cover;
  background-image: linear-gradient(to bottom, #39509260, #2ca2da60), url(${background});
  height: 100vh;
  padding: 10px;
  @media (max-width: 820px) {
    background-position: 70%;
  @media (max-width: 768px) {
    background-position: 70%;
  @media (max-width: 426px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 80%;
  }
`
export const Container = styled.div`
  display: flex;
`
export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
`
export const TabHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  width: 50%;
  height: 48px;
  background-color: rgba(255, 255, 255);
  opacity: ${({ opacity }) => opacity};
`

export const TabDetail = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 650px;
  background-color: rgba(255, 255, 255);
  overflow: ${({ overflow }) => overflow};
`
export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 30px;

  @media (max-width: 426px) {
    position: absolute;
    bottom: 24px;
    right: 30px;
  }
`
export const PrivacyAgreement = styled.div`
  position: fixed;
  bottom: 45px;
  left: 175px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  max-width: 470px;
  @media screen and (min-width: 1600px) {
    bottom: 100px;
    left: 175px;
  }
  @media screen and (min-width: 1024px) {
    bottom: 45px;
    left: 175px;
  }
  @media screen and (max-width: 820px) {
    bottom: 200px;
    left: 180px;
  }
  @media screen and (max-width: 768px) {
    bottom: 125px;
    left: 155px;
  }
}
@media screen and (max-width: 540px) {
  bottom: 0px;
  left: 30px;
}
`
export const FooterContentRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

export const LinkText = styled((props) => <Typography {...props} />)`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.white};

  :hover {
    text-decoration: underline;
  }
`
