/* eslint-disable no-unused-vars */
import React from 'react'
import { Redirect } from 'react-router-dom'

import StudentLayout from 'components/layout'
import academypofileLayout from 'layouts/academypofileLayout/loadable'
import MyCourseLayout from 'layouts/myCourseLayout'
import MyCourseDetailLayout from 'layouts/myCourseDetailLayout'
import MyCourseDetailLayoutNew from 'layouts/myCourseDetailLayoutNew'
import myCourseLectureContentLayout from 'layouts/myCourseLectureContentLayout'
import SchoolLayout from 'layouts/schoolLayout'
import EditProfileLayout from 'layouts/editProfileLayout'
import BrowseLayout from 'layouts/browseLayout/loadable'
import LectureLayout from 'components/lectureLayout/loadable'
import SchoolProfileAbout from 'components/schoolProfile/schoolProfileAbout/loadable'
import SchoolProfileActivities from 'components/schoolProfile/schoolProfileActivities/loadable'
import SchoolProfileCourses from 'components/schoolProfile/schoolProfileCourses/loadable'
import SchoolProfileAnnouncement from 'components/schoolProfile/schoolProfileAnnouncement/loadable'
import NotificationPage from 'containers/notificationPage/loadable'
import CertificatesPage from 'containers/certificatesPage/loadable'
import MySchedulePage from 'containers/mySchedulePage/loadable'
import HomePage from 'containers/homePage/loadable'
import MainFeedPage from 'containers/mainFeedPage/loadable'
import BrowseCoursePage from 'containers/browseCoursePage/loadable'
import BrowseSchoolPage from 'containers/browseSchoolPage/loadable'
import CourseDetail from 'containers/courseDetail/loadable'
import CourseDetailAllReview from 'containers/courseDetailAllReview/loadable'
import CheckoutPage from 'containers/checkoutPage/loadable'
import ProfilePage from 'containers/profilePage/loadable'
import ProfilePageAcademy from 'containers/profilePageAcademy/loadable'
import CourseReviewPage from 'containers/courseReviewPage/loadable'
import ContractUsPage from 'containers/contractUsPage/loadable'
import BadgePage from 'containers/badgePage/loadable'
import BadgeEditPage from 'containers/badgeEditPage/loadable'
import BadgeDetailPage from 'containers/badgeDetailPage/loadable'
import EditProfileMainPage from 'containers/editProfileMainPage/loadable'
import MyCoursePage from 'containers/myCoursePage/loadable'
import AssignmentPage from 'containers/assignmentPage/loadable'
import CourseCollectionTab from 'containers/courseCollectionTab/loadable'
import CourseCollectionPage from 'containers/courseCollectionPage/loadable'
import SchoolPage from 'containers/schoolPage/loadable'
import MyCourseInfo from 'components/myCourseInfo/loadable'
import MyCourseLectureTab from 'components/myCourseLectureTab/loadable'
import MyCourseStudentTab from 'containers/myCourseStudentTab/loadable'
import MyCourseAssignmentTab from 'containers/myCourseAssignmentTab/loadable'
import MyCourseDiscussionTab from 'containers/myCourseDiscussionTab/loadable'
import MyCourseLiveTab from 'containers/myCourseLiveTab/loadable'
import ZoomSDKLive from 'containers/zoomSDKLive/loadable'
import EditProfileProfileTab from 'components/editProfileProfileTab/loadable'
import EditProfileIntroTab from 'components/editProfileIntroTab/loadable'
import EditProfileWorkExperienceTab from 'components/editProfileWorkExperienceTab/loadable'
import EditProfileEducationTab from 'components/editProfileEducationTab/loadable'
import EditProfileInterestsTab from 'components/editProfileInterestsTab/loadable'
import EditProfileContactTab from 'components/editProfileContactTab/loadable'
import EditProfileWorkExperienceFormTab from 'components/editProfileWorkExperienceFormTab/loadable'

import EditProfileEducationFormTab from 'components/editProfileEducationFormTab/loadable'
import EditProfileCertificatesFormTab from 'components/editProfileCertificatesFormTab/loadable'

import MyCourseLectureContent from 'components/myCourseLectureContent/loadable'
// import SchoolHomeTab from 'components/schoolHomeTab/loadable'
// import SchoolProfileTab from 'components/schoolProfileTab/loadable'
// import SchoolCourseTab from 'containers/schoolCourseTab/loadable'
// import SchoolMemberTab from 'containers/schoolMemberTab/loadable'
import TestAssignmentPage from 'containers/testAssignmentPage/loadable'
import TestAssignmentResultPage from 'containers/testAssignmentResultPage/loadable'
import AccountSettingLayout from 'layouts/accountSettingLayout'
import AccountMainPage from 'containers/accountMainPage'
import AccountGeneralInfoPage from 'containers/accountGeneralInfoPage/loadable'
import AccountChangePasswordPage from 'containers/accountChangePasswordPage/loadable'
import AccountNotificationPage from 'containers/accountNotificationPage/loadable'
import PurchaseHistoryPage from 'containers/purchaseHistoryPage'
import StudentCourseLiveStudio from 'containers/studentCourseLiveStudio/loadable'
//import SchoolLearnerTagsTab from 'containers/schoolLearnerTagsTab'
import YoutubeViewer from 'containers/youtubeViewer/loadable'

import TutorialLayout from 'layouts/tutorialLayout'
import TutorialMainPage from 'containers/tutorialMainPage/loadable'
import TeacherModeTab from 'components/teacherModeTab/loadable'
import createAcademyTab from 'components/createAcademyTab/loadable'
import createCourseTab from 'components/createCourseTab/loadable'
import institutionTab from 'components/institutionTab/loadable'
import gettingCourseTab from 'components/gettingCourseTab/loadable'
import academyInformationTab from 'components/academyInformationTab/loadable'

import CookieLayout from 'layouts/cookieLayout'
import CookieMainPage from 'containers/cookieMainPage/loadable'
import CookiePolicyTab from 'components/cookiePolicyTab/loadable'
import PrivacyPolicyTab from 'components/privacyPolicyTab/loadable'
import LandingPage from 'containers/landingPage/loadable'
import landingTeacherPage from 'containers/landingTeacherPage/loadable'
import landingStudentPage from 'containers/landingStudentPage/loadable'
import FindingCoursePage from 'containers/landingFindingCourse/loadable'
const schoolProfileRoutes = {
  path: '/:schoolSlug',
  layout: SchoolLayout,
  routes: [
    {
      exact: true,
      subpath: '/',
      render: ({ match }) => <Redirect to={`${match.url}/announcement`} />,
    },
    {
      exact: true,
      subpath: '/announcement',
      component: SchoolProfileAnnouncement,
    },
    {
      exact: true,
      subpath: '/activities',
      component: SchoolProfileActivities,
    },
    {
      exact: true,
      subpath: '/about',
      component: SchoolProfileAbout,
    },
    {
      exact: true,
      subpath: '/courses',
      component: SchoolProfileCourses,
    },
  ],
}

const studentRoutes = [
  {
    isPrivate: true,
    exact: true,
    path: '/my-course/courses/:courseId/live/:meetingId',
    component: ZoomSDKLive,
  },
  {
    path: '/',
    layout: StudentLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: LandingPage,
      },
      {
        exact: true,
        path: '/landingStudent',
        component: landingStudentPage,
      },
      {
        exact: true,
        path: '/landingTeacher',
        component: landingTeacherPage,
      },
      {
        exact: true,
        path: '/landingFindingCourse',
        component: FindingCoursePage,
      },
      {
        exact: true,
        path: '/users/:username',
        component: ProfilePage,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/users/:username/badge',
        component: BadgePage,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/users/:username/badge/edit',
        component: BadgeEditPage,
      },
      {
        exact: true,
        path: '/users/:username/badge/:badgeId',
        component: BadgeDetailPage,
      },
      {
        exact: true,
        path: '/courses/:courseSlug',
        component: CourseDetail,
      },
      {
        exact: true,
        path: '/courses/:courseSlug/reviews',
        component: CourseDetailAllReview,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/courses/:courseId/checkout',
        component: CheckoutPage,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/home',
        component: HomePage,
      },
      {
        path: '/browse',
        layout: BrowseLayout,
        routes: [
          {
            exact: true,
            subpath: '/',
            render: ({ match }) => <Redirect to={`${match.url}/courses`} />,
          },
          {
            exact: true,
            isPrivate: true,
            subpath: '/main',
            component: MainFeedPage,
          },
          {
            exact: true,
            isPrivate: true,
            subpath: '/courses',
            component: BrowseCoursePage,
          },
          {
            exact: true,
            isPrivate: true,
            subpath: '/schools',
            component: BrowseSchoolPage,
          },
        ],
      },
      {
        isPrivate: true,
        exact: true,
        path: '/my-certificates',
        component: CertificatesPage,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/my-schedule',
        component: MySchedulePage,
      },
      // ! TestAssignment

      {
        isPrivate: true,
        exact: true,
        path: '/my-course/courses/:courseId/test/:assignmentId',
        component: TestAssignmentPage,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/my-course/courses/:courseId/test/:assignmentId/result',
        component: TestAssignmentResultPage,
      },
      {
        path: '/my-course/courses/:courseId/students/:studentId',
        exact: true,
        isPrivate: true,
        component: ProfilePage,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/my-course/courses/:courseId/live/:liveId',
        component: StudentCourseLiveStudio,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/my-course/courses/:courseId/broadcast/:broadcastId',
        component: YoutubeViewer,
      },
      // {
      //   layout: LectureLayout,
      //   path: '/my-course/courses/:courseId/lecture/:lectureId',
      //   routes: [
      //     {
      //       isPrivate: true,
      //       exact: true,
      //       subpath: '/',
      //       component: MyCourseLectureContent,
      //     },
      //   ],
      // },
      {
        path: '/my-course/courses/:courseId/lecture/:lectureId',
        layout: myCourseLectureContentLayout,
        //layout: MyCourseDetailLayout,
        routes: [
          {
            isPrivate: true,
            exact: true,
            subpath: '/',
            component: MyCourseLectureContent,
          },
        ],
      },
      {
        path: '/my-course/courses/:courseId',
        layout: MyCourseDetailLayoutNew,
        //layout: MyCourseDetailLayout,
        routes: [
          {
            exact: true,
            subpath: '/',
            render: ({ match }) => <Redirect to={`${match.url}/lecture`} />,
          },
          {
            exact: true,
            subpath: '/discussion',
            render: ({ match }) => <Redirect to={`${match.url}/lecture`} />,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/lecture',
            component: MyCourseLectureTab,
          },
          // {
          //   isPrivate: true,
          //   exact: true,
          //   subpath: '/overview',
          //   // component: MyCourseOverviewTab,
          //   component: MyCourseInfo,
          // },
          // {
          //   isPrivate: true,
          //   exact: true,
          //   subpath: '/assignment',
          //   component: MyCourseAssignmentTab,
          // },
          // {
          //   isPrivate: true,
          //   exact: true,
          //   subpath: '/discussion',
          //   component: MyCourseDiscussionTab,
          // },
          // {
          //   isPrivate: true,
          //   exact: true,
          //   subpath: '/students',
          //   component: MyCourseStudentTab,
          // },
          // {
          //   isPrivate: true,
          //   exact: true,
          //   subpath: '/live',
          //   component: MyCourseLiveTab,
          // },
        ],
      },
      {
        isPrivate: true,
        exact: true,
        path: '/my-course/assignments/:assignmentId',
        component: TestAssignmentPage,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/my-course/assignments/:assignmentId/result',
        component: TestAssignmentResultPage,
      },
      {
        path: '/my-course/schools/:schoolId',
        layout: SchoolLayout,
        routes: [
          {
            exact: true,
            subpath: '/',
            render: ({ match }) => <Redirect to={`${match.url}/announcement`} />,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/announcement',
            component: SchoolProfileAnnouncement,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/activities',
            component: SchoolProfileActivities,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/about',
            component: SchoolProfileAbout,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/courses',
            component: SchoolProfileCourses,
          }, //

          // {
          //   isPrivate: true,
          //   exact: true,
          //   subpath: '/tags',
          //   component: SchoolLearnerTagsTab,
          // },
        ],
      },
      {
        isPrivate: true,
        exact: true,
        path: '/my-course/collection/:groupId',
        component: CourseCollectionPage,
      },
      {
        path: '/my-course',
        layout: MyCourseLayout,
        routes: [
          {
            exact: true,
            subpath: '/',
            render: ({ match }) => <Redirect to={`${match.url}/courses`} />,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/courses',
            component: MyCoursePage,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/Collection',
            component: CourseCollectionTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/assignments',
            component: AssignmentPage,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/schools',
            component: SchoolPage,
          },
        ],
      },
      {
        isPrivate: true,
        exact: true,
        path: '/course-review',
        component: CourseReviewPage,
      },
      // ! Profile
      {
        path: '/profile/edit/work-experience/:workExperienceId',
        exact: true,
        isPrivate: true,
        component: EditProfileWorkExperienceFormTab,
      },
      {
        path: '/profile/edit/education/:educationId',
        exact: true,
        isPrivate: true,
        component: EditProfileEducationFormTab,
      },
      // {
      //   path: '/profile/edit/certificates/:certificatesId',
      //   exact: true,
      //   isPrivate: true,
      //   component: EditProfileCertificatesFormTab,
      // },
      {
        path: '/my-certificates/:certificatesId',
        exact: true,
        isPrivate: true,
        component: EditProfileCertificatesFormTab,
      },
      {
        path: '/profile/edit',
        layout: EditProfileLayout,
        routes: [
          {
            isPrivate: true,
            exact: true,
            subpath: '/',
            component: EditProfileMainPage,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/profile',
            component: EditProfileProfileTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/intro',
            component: EditProfileIntroTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/work-experience',
            component: EditProfileWorkExperienceTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/education',
            component: EditProfileEducationTab,
          },
          // {
          //   isPrivate: true,
          //   exact: true,
          //   subpath: '/certificates',
          //   component: EditProfileCertificatesTab,
          // },
          {
            isPrivate: true,
            exact: true,
            subpath: '/interests',
            component: EditProfileInterestsTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/contact',
            component: EditProfileContactTab,
          },
        ],
      },
      ///aaa

      {
        path: '/my-profile',
        layout: academypofileLayout,
        routes: [
          {
            exact: true,
            subpath: '/',
            render: ({ match }) => <Redirect to={`${match.url}/profile`} />,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/profile',
            component: ProfilePage,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/academy-profile',
            component: ProfilePageAcademy,
          },
        ],
      },
      {
        path: '/account-setting',
        layout: AccountSettingLayout,
        routes: [
          {
            isPrivate: true,
            exact: true,
            subpath: '/',
            component: AccountMainPage,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/general-information',
            component: AccountGeneralInfoPage,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/change-password',
            component: AccountChangePasswordPage,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/notification',
            component: AccountNotificationPage,
          },
        ],
      },

      // {
      //   path: '/point-rewards',
      //   layout: PointRewardLayout,
      //   routes: [
      //     {
      //       exact: true,
      //       subpath: '/',
      //       render: ({ match }) => <Redirect to={`${match.url}/all-rewards`} />,
      //     },
      //     {
      //       isPrivate: true,
      //       exact: true,
      //       subpath: '/all-rewards',
      //       component: PointAllRewardsPage,
      //     },
      //     {
      //       isPrivate: true,
      //       exact: true,
      //       subpath: '/history',
      //       component: PointHistoryPage,
      //     },
      //   ],
      // },
      {
        isPrivate: true,
        exact: true,
        path: '/purchase-history',
        component: PurchaseHistoryPage,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/notifications',
        component: NotificationPage,
      },
      {
        isPrivate: true,
        exact: true,
        path: '/contact-us',
        component: ContractUsPage,
      },
      {
        path: '/tutorialTeacher',
        layout: TutorialLayout,
        routes: [
          {
            isPrivate: true,
            exact: true,
            subpath: '/',
            component: TutorialMainPage,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/teacherMode',
            component: TeacherModeTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/createAcademy',
            component: createAcademyTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/createCourse',
            component: createCourseTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/institution',
            component: institutionTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/gettingCourse',
            component: gettingCourseTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/academyInformation',
            component: academyInformationTab,
          },
        ],
      },
      {
        path: '/cookie',
        layout: CookieLayout,
        routes: [
          {
            isPrivate: true,
            exact: true,
            subpath: '/',
            component: CookieMainPage,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/cookiePolicy',
            component: CookiePolicyTab,
          },
          {
            isPrivate: true,
            exact: true,
            subpath: '/privacyPolicy',
            component: PrivacyPolicyTab,
          },
        ],
      },
      schoolProfileRoutes,
    ],
  },
]

export default studentRoutes
