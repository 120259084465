import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Page, Document } from 'react-pdf'
import moment from 'moment'
import { StudentCertificagteAPI } from 'api'
import ClasswinLogo from 'images/Classwin logo_Classwin Horizontal - Blue 2.png'
import Typography from 'components/typography'
import Button from 'components/button'
import Input from 'components/input'
import getFileUrl from 'utils/getFileUrl'
import Loader from 'components/loader'
import Empty from 'components/empty'
import ReCAPTCHA from 'react-google-recaptcha'
import ImageCertificate from '../../images/Classwin_certificateQRcode.png'
import {
  Container,
  Logo,
  FormFormik,
  PDFDocumentWrapper,
  BadgeImage,
  Flex,
  ValidCert,
  FlexTitle,
  Card,
  Title,
  ContainerModal,
  ImageModal,
  TitleModal,
  ImageContainer,
  Footer,
  TopFooter,
  BottomFooter,
} from './styles'
import { DesktopAndTablet, SmallMobile } from 'utils/displayResponsive'
import { Modal } from 'components'
import { ModalResize } from 'utils/commaize'
import { useWindowSize } from 'react-use'

const VerifyCertificatePage = () => {
  const history = useHistory()

  const location = useLocation()
  const querystring = qs.parse(location?.search, { ignoreQueryPrefix: true })
  const code = querystring?.code || ''
  const [shown, setShown] = useState(false)
  const [haveBadge, setHaveBadge] = useState(false)
  const [validCert, setValidCert] = useState('') // valid, expired
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState(null)
  const [showButton, setShowButton] = useState(false)
  const [error, setError] = useState('')
  const image = `${process.env.REACT_APP_API_URL}/StudentCertificates/${data?.certificate?.id}/certificate?type=link`
  const { width } = useWindowSize()
  const isMobile = width < 426
  function onChange(value) {
    console.log('Captcha value:', value)
  }

  const handleSubmit = async (value) => {
    try {
      setLoading(true)
      setShown(false)
      setShowButton(true)
      if (value.verifyCertId) {
        const result = await StudentCertificagteAPI.verify({
          code: value.verifyCertId,
          token: value.recaptcha,
        })
        const isExpired = result?.certificate?.expiredDate
          ? moment().isAfter(moment(result?.certificate?.expiredDate))
          : false
        setData(result)
        setShown(true)
        setHaveBadge(false)
        setValidCert(isExpired ? 'expired' : 'valid')
      } else {
        setShown(false)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError('NO RESULT')
    }
  }

  return (
    <Container>
      <Logo src={ClasswinLogo} />
      <DesktopAndTablet>
        <Typography
          id="verifyCertificate.title"
          color="primary1"
          fontSize="text-6xl"
          bold
          padding="16px 0"
        >
          Certificate Verification
        </Typography>
      </DesktopAndTablet>
      <SmallMobile>
        <Typography
          id="verifyCertificate.title"
          color="primary1"
          fontSize="text-2xl"
          bold
          padding="16px 0"
        >
          Certificate Verification
        </Typography>
      </SmallMobile>
      <Formik
        enableReinitialize
        initialValues={{ verifyCertId: code }}
        validationSchema={Yup.object().shape({
          verifyCertId: Yup.string().required('Required'),
          recaptcha: Yup.string().required('Required').nullable(),
        })}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
        render={({ values, handleChange, errors, setFieldValue }) => (
          <FormFormik>
            <Typography id="verifyCertificate.input" color="black38" padding="0 0 8px" center>
              Please insert certificate code here.
            </Typography>
            <Input
              name="verifyCertId"
              value={values.verifyCertId}
              placeholder="CW-XXXX-XXXX"
              onChange={handleChange}
              messageStatus={errors.verifyCertId}
              status={errors.verifyCertId && 'error'}
              style={{ textAlign: 'center', fontSize: 20 }}
            />

            <div style={{ display: 'grid', marginTop: 24, justifyContent: 'center' }}>
              <ReCAPTCHA
                size="normal"
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={(value) => {
                  onChange(value)
                  setFieldValue('recaptcha', value)
                }}
              />
              {errors.recaptcha && (
                <Typography color="danger" fontSize="text-xs" bold textAlign="left" margin="2px 0">
                  {errors.recaptcha}
                </Typography>
              )}
            </div>

            <Button
              id="verifyCertificate.button"
              type="submit"
              primary
              full
              margin="24px 0 0"
              style={{ height: 48 }}
              fontStyle={{ fontSize: 20 }}
            >
              Verify
            </Button>
          </FormFormik>
        )}
      />
      <div style={{ padding: '8px 0' }}>
        <Typography
          id="certificate.verify.getCode"
          color="primary1"
          bold
          margin="0 auto"
          hoverTextNoColor
          style={{ cursor: 'pointer' }}
          onClick={() => setIsOpen(true)}
        >
          How to find certificate code?
        </Typography>
      </div>
      {loading && <Loader />}
      {!loading && error && (
        <div style={{ marginTop: 20 }}>
          <Empty id={{ title: 'verifyCertificate.noResult' }} title="No Result" />
        </div>
      )}
      {shown && (
        <Card>
          {/* <div style={{ paddingRight: 40 }}> */}
          <div style={{ marginBottom: 24 }}>
            <FlexTitle>
              <Title color="primary1" bold style={{ marginTop: 0 }}>
                Certificate
              </Title>
              <ValidCert backgroundColor={validCert === 'valid' ? 'valid' : 'expired'}>
                {validCert === 'valid' ? (
                  <Title color="success2" bold style={{ marginTop: 0 }}>
                    Valid
                  </Title>
                ) : (
                  <Title color="danger" bold style={{ marginTop: 0 }}>
                    Expired
                  </Title>
                )}
              </ValidCert>
            </FlexTitle>

            <PDFDocumentWrapper>
              <Document file={getFileUrl(image)} loading={<Loader height="80vh" />}>
                <Page pageNumber={1} renderTextLayer={false} />
              </Document>
            </PDFDocumentWrapper>
          </div>
          <div>
            {haveBadge ? (
              <>
                <Title color="primary1" bold padding="0 0 8px">
                  Badge
                </Title>
                <BadgeImage />
              </>
            ) : null}
            <Title color="primary1" bold padding="0 0 8px" style={{ width: 300 }}>
              Detail
            </Title>
            {/* <Typography label3 thin>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry’s standard dummy text ever.
            </Typography> */}
            <Flex>
              <Typography label3 thin>
                Issued on:
              </Typography>
              <Typography label3 color="primary1" bold>
                {moment(data?.certificate?.certificatedDate).format('DD MMMM YYYY')}
              </Typography>
            </Flex>
            <Flex>
              <Typography label3 thin nowrap width="100px">
                Issued by:
              </Typography>
              <Typography label3 color="primary1" bold>
                {data?.certificate?.certificateTemplate?.schoolName}
              </Typography>
            </Flex>
            <Flex>
              <Typography label3 thin>
                Issued using:
              </Typography>
              <Typography label3 color="primary1" bold>
                Classwin
              </Typography>
            </Flex>
            <Flex>
              <Typography label3 thin>
                Issued to:
              </Typography>
              <Typography label3 color="primary1" bold>
                {data?.certificate?.certificateTemplate?.studentName}
              </Typography>
            </Flex>
            <Flex>
              <Typography label3 thin>
                Expiry on:
              </Typography>
              <Typography label3 color="primary1" bold>
                {data?.certificate?.expiredDate
                  ? moment(data?.certificate?.expiredDate).format('DD MMMM YYYY')
                  : 'No Expire'}
              </Typography>
            </Flex>
            <Flex>
              <Typography label3 thin>
                Status:
              </Typography>
              <Typography label3 color="primary1" bold>
                Verified
              </Typography>
            </Flex>
          </div>
        </Card>
      )}
      {showButton && (
        <div style={{ padding: '24px 0', width: '100%' }}>
          <Button
            id="go.to.classwin"
            secondary
            onClick={() => history.push('/')}
            style={{ maxWidth: 400, width: '100%', margin: '0 auto' }}
          >
            Enter Classwin
          </Button>
        </div>
      )}
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        onClickClose={() => setIsOpen(false)}
        contentStyle={{ width: ModalResize(width), padding: 24 }}
        shownXclose={true}
        closeStyle={isMobile ? { top: 8, right: 10 } : null}
      >
        <ContainerModal>
          <TitleModal>
            <Typography
              id="certificate.verify.getCode"
              color="primary1"
              bold
              fontSize="text-lg"
              center
              margin={isMobile ? '8px 0 0' : null}
            >
              How to find certificate code?
            </Typography>
          </TitleModal>
          <ImageContainer>
            <ImageModal src={ImageCertificate} alt="image-certificate" />
          </ImageContainer>
          <Footer>
            <TopFooter>
              <Typography id="certificate.modal.lineOne">
                คุณสามารถค้นหาเลขใบประกาศนียบัตร 10 หลัก
              </Typography>
              <Typography id="certificate.modal.lineTwo">
                ได้ที่มุมขวาล่างของใบประกาศนียบัตรอิเล็กทรอนิกส์
              </Typography>
            </TopFooter>
            <BottomFooter>
              <Typography id="certificate.modal.lineThree">
                หากคุณยังไม่มีใบประกาศนียบัตร
              </Typography>
              <Typography id="certificate.modal.lineFour">
                สามารถทำการดาวน์โหลดได้ที่เมนู ประกาศนียบัตร
              </Typography>
            </BottomFooter>
          </Footer>
        </ContainerModal>
      </Modal>
    </Container>
  )
}

export default VerifyCertificatePage
