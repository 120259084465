import styled, { css } from 'styled-components'
import { Checkbox as AntdCheckbox } from 'antd'

const Button = styled.button`
  width: 101px;
  height: 40px;
  background: ${(props) => props.color};
  border: 0px solid ${(props) => props.color};
  border-radius: 6px;
  align-self: center;
  &:hover {
    cursor: pointer;
  }
`
const Font = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0px;
  color: ${(props) => props.color};
`
const Label = styled.label`
  font-style: normal;
  font-weight: ${(props) => (props.check ? 700 : 400)};
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0px;
  color: ${(props) => (props.check ? '#253e87' : props.color || '#ffffff')};
  &:hover {
    cursor: pointer;
  }
`
const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
const Content = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 0;
  height: 30px;
  gap: 8px;
`
const Div = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px 0px 24px;
  &:hover {
    cursor: pointer;
  }
`
const Img = styled.img``

const Checkbox = styled.input`
  width: 1em;
  height: 1em;
  background-color: blue;
  &:hover {
    cursor: pointer;
  }
`
const CustomCheckbox = styled(AntdCheckbox)`
  ${(props) =>
    props.backgroundColor &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props.backgroundColor};
        border-color: ${props.backgroundColor};
      }
    `}
`
const NotificationContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  width: 320px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;

  @media (max-width: 768px) {
    width: 320px;
    box-shadow: 0 0 0 0 transparent;
  }

  @media (max-width: 426px) {
    width: 100vw;
    box-shadow: 0 0 0 0 transparent;
  }
`
export {
  Button,
  Font,
  Label,
  Wrapper,
  Content,
  Div,
  Img,
  Checkbox,
  CustomCheckbox,
  NotificationContainer,
}
