import React, { useState, useEffect } from 'react'
import Table from 'rc-table'
import EmptyFolder from '../../images/EmptyFolder.svg'
import { Container, EmptyWrapper } from './style'
import { Typography } from '../index'
import Sorter from './Sorter'

const noData = () => {
  return (
    <EmptyWrapper>
      <div>
        <img src={EmptyFolder} alt="empty" />
        {/* <Typography
          id="label.noinvoice"
          fontSize="text-base"
          color="primary1"
          margin="10px 0 0 0"
          bold
        >
          There’s no Invoices
        </Typography> */}
      </div>
    </EmptyWrapper>
  )
}

const MainTable = ({ columns, data, bordered, ...props }) => {
  const [newColumns, setNewColumns] = useState([])
  const [newData, setNewData] = useState([])

  useEffect(() => {
    const n = columns.map(({ title, ...column }) => {
      const newTitle = column.sorter ? (
        <Sorter title={title} sorter={column.sorter} setNewData={setNewData} data={data} />
      ) : (
        <Typography id={transTitle(title)} color="dark1" left>
          {title}
        </Typography>
      )
      return { title: newTitle, ...column }
    })
    setNewData(data)
    // console.log('column', n)
    // console.log('data', data)
    setNewColumns(n)
  }, [data, columns])

  const transTitle = (title) => {
    let id
    switch (title) {
      case 'Date':
        id = 'transaction.table.date'
        break
      case 'Transaction ID':
        id = 'transaction.table.transId'
        break
      case 'Course':
        id = 'transaction.table.course'
        break
      case 'Payer name':
        id = 'transaction.table.playerName'
        break
      case 'Amount':
        id = 'transaction.table.amount'
        break
      case 'Balance':
        id = 'transaction.table.balance'
        break
      case 'Status':
        id = 'transaction.table.status'
        break
      case 'Course Price':
        id = 'transaction.table.price'
        break
      case 'Income':
        id = 'transaction.table.income'
        break
      case 'Fee':
        id = 'transaction.table.fee'
        break
      default:
        id = ''
    }
    return id
  }

  return (
    <Container bordered={bordered} {...props}>
      <Table
        columns={newColumns}
        data={newData}
        tableLayout="fixed"
        scroll={{ y: 1500, x: newColumns.length * 150 }}
        rowKey="id"
        emptyText={noData}
      />
    </Container>
  )
}

export default MainTable
