import React from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-switch'
import theme from 'theme'
import { Typography } from '../index'

const SwitchWithOnOff = ({ checked, onChange, ...props }) => {
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      onColor={theme.primary13}
      offColor={theme.black20}
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      uncheckedIcon={
        <Typography center color="white" fontSize="text-xs" lineHeight="20px">
          OFF
        </Typography>
      }
      checkedIcon={
        <Typography center color="white" fontSize="text-xs" lineHeight="20px">
          ON
        </Typography>
      }
      width={55}
      height={22}
      handleDiameter={18}
      {...props}
    />
  )
}

SwitchWithOnOff.propTypes = {
  props: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

SwitchWithOnOff.defaultProps = {
  props: {},
  checked: false,
  onChange: () => {},
}

export default SwitchWithOnOff
