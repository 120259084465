/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { Typography, Button } from 'components'
import FeedDiscussion from './FeedDiscussion'
import { Input, Modal } from 'antd'
import CloseIcon from '../../../../images/icons/Close.png'
import AddImage from '../../../../images/icons/Image.svg'
import getImageUrl from 'utils/getImageUrl'
import ClasswinEditor from 'components/classwinEditor/FeedEditor'
import UploadComponent from '../../../../components/uploadComponent'
const AddImageFake = styled.img`
  width: 24px;
  height: 24px;
`
const AntModal = styled(Modal)`
  .ant-modal-header {
    border-radius: 8px !important;
  }
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-title {
    text-align: center !important;
  }
  .ant-modal-body {
    padding: 0px;
  }
`
const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`
const Content = styled.div`
  padding: 12px 16px 16px;
`
const UserContainer = styled.div`
  display: flex;
  width: 10%;
  align-items: flex-start;
  @media (max-width: 820px) {
    width: 12%;
  }
  @media (max-width: 768px) {
    width: 12%;
  }
  @media (max-width: 425px) {
    width: 15%;
  }
`
const Image = styled.div`
  /* width: 150px; */
  height: 150px;
  background-image: ${(props) => `url(${props.src})`};
  background-position: center;
  background-size: cover;
`
const Line = styled.div`
  width: 98%;
  border-top: 1px solid #00000010;
  margin: 0px 0px 2px 5px;
`
function Discussion(props) {
  const {
    courseId,
    getMyFeeds,
    getMyFollows,
    myFeedLists,
    locale,
    appUser,
    postDiscussion,
    myCourseList,
    setLikePost,
    setLikeComment,
    setLikeReply,
    postLike,
    postComment,
    setComment,
    setCommentReply,
    deleteFeed,
    setDeletePost,
    setDeleteComment,
    setDeleteCommentLevelOne,
    updateFeed,
    Confirm,
    setEditComment,
    isMobile,
  } = props
  useEffect(() => {
    getMyFeeds({ courseId })
    getMyFollows()
  }, [getMyFeeds, courseId, getMyFollows])
  const { feeds, total } = myFeedLists
  const { profileImage } = appUser
  const [isOpen, setIsOpen] = useState(false)
  const [textInput, setTextInput] = useState('')
  const [photo, setPhoto] = useState(null)
  const handleCancel = () => {
    setIsOpen(false)
  }
  const handlePost = async () => {
    if (textInput.length > 0) {
      const obj = {
        richMessage: textInput,
        appUserId: appUser.id,
        media: photo ? [photo] : null,
        courseId: courseId ? courseId : null,
      }
      await postDiscussion(obj)
      setIsOpen(false)
      await getMyFeeds({ courseId })
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        {isMobile ? (
          <Button
            style={{ width: '100%', margin: '14px 0px 0px 0px' }}
            onClick={() => {
              setIsOpen(true)
              setPhoto(null)
              setTextInput('')
            }}
          >
            <Typography color="black87">
              {locale === 'en' ? 'Start conversation' : 'เริ่มต้นสนทนา'}
            </Typography>
          </Button>
        ) : (
          <>
            <Typography color="white" title bold>
              {locale === 'en' ? 'Discussion' : 'บอร์ดสนทนา'}
            </Typography>
            <Button
              style={{ width: 'auto' }}
              onClick={() => {
                setIsOpen(true)
                setPhoto(null)
                setTextInput('')
              }}
            >
              <Typography color="black87">
                {locale === 'en' ? 'Start conversation' : 'เริ่มต้นสนทนา'}
              </Typography>
            </Button>
          </>
        )}
        <AntModal
          visible={isOpen}
          centered
          footer={null}
          title={
            <Typography fontSize="text-lg" bold color="primary1">
              {locale === 'en' ? 'Start Conversation' : 'เริ่มต้นบทสนทนา'}
            </Typography>
          }
          onCancel={handleCancel}
          closeIcon={<img src={CloseIcon} alt="Close" style={{ width: 24, height: 24 }} />}
        >
          <Content>
            <div style={{ display: 'flex' }}>
              <UserContainer>
                <Avatar src={getImageUrl(profileImage)} alt={appUser?.firstname} />
              </UserContainer>
              <div style={{ width: '85%', margin: '0 0 75px' }}>
                <ClasswinEditor
                  placeholder={
                    locale === 'en'
                      ? "Let's start your conversation ..."
                      : 'เขียนความคิดเห็นของคุณ ...'
                  }
                  value={textInput}
                  onChange={(value) => {
                    setTextInput(value)
                  }}
                  showToolbar={false}
                  // labelFontSize="text-lg"
                  noPadding
                  noBorderRadius
                  autoHeight
                  fontSize="text-base"
                  padding={0}
                />
              </div>
            </div>
            {photo && (
              <div>
                <img
                  src={CloseIcon}
                  alt="Close"
                  onClick={() => setPhoto(null)}
                  style={{
                    width: 24,
                    height: 24,
                    position: 'absolute',
                    right: 16,
                    cursor: 'pointer',
                  }}
                />
                <Image src={getImageUrl(photo.name)} />
              </div>
            )}
          </Content>
          <Line />
          <Content>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div
                style={{
                  cursor: 'pointer',
                  marginTop: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <UploadComponent
                  afterUpload={(value) => {
                    return setPhoto(value)
                  }}
                >
                  <AddImageFake src={AddImage} alt="add-image" />
                </UploadComponent>
              </div>
              <Button primary width="20%" onClick={handlePost}>
                Post
              </Button>
            </div>
          </Content>
        </AntModal>
      </div>
      <FeedDiscussion
        feeds={feeds}
        total={total}
        locale={locale}
        myCourseList={myCourseList}
        courseId={courseId}
        appUser={appUser}
        setLikePost={setLikePost}
        setLikeComment={setLikeComment}
        setLikeReply={setLikeReply}
        postLike={postLike}
        postComment={postComment}
        setComment={setComment}
        setCommentReply={setCommentReply}
        deleteFeed={deleteFeed}
        setDeletePost={setDeletePost}
        setDeleteComment={setDeleteComment}
        setDeleteCommentLevelOne={setDeleteCommentLevelOne}
        updateFeed={updateFeed}
        Confirm={Confirm}
        setEditComment={setEditComment}
      />
    </div>
  )
}

const mapState = ({
  AppUser: { appUser, myFeedLists, follows },
  Language: { locale },
  MyCourse: { myCourseList },
}) => ({
  appUser: appUser,
  locale,
  myFeedLists,
  myCourseList,
  follows,
})
const mapDispatch = ({
  Confirm,
  AppUser: {
    getMyFeeds,
    getMoreMyFeeds,
    postLike,
    postComment,
    setComment,
    updateFeed,
    deleteFeed,
    setEditComment,
    setDeleteComment,
    setLikePost,
    setLikeComment,
    setLikeReply,
    setDeleteCommentLevelOne,
    setCommentReply,
    setDeletePost,
    postMyFollows,
    deleteMyFollows,
    getMyFollows,
    updateProfile,

    postDiscussion,
  },
}) => ({
  Confirm,
  getMyFeeds,
  getMoreMyFeeds,
  postLike,
  setLikePost,
  setLikeComment,
  setLikeReply,
  postComment,
  setComment,
  updateFeed,
  deleteFeed,
  setDeleteComment,
  setEditComment,
  setDeleteCommentLevelOne,
  setCommentReply,
  setDeletePost,
  postMyFollows,
  deleteMyFollows,
  getMyFollows,
  updateProfile,

  postDiscussion,
})
export default connect(mapState, mapDispatch)(Discussion)
