/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import styled from 'styled-components'
import Sidebar from './sidebar'
import windowSize from 'react-window-size'
import { DesktopAndTablet, SmallMobile } from '../../utils/displayResponsive'
const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  @media (max-width: 1024px) {
  }

  @media (max-width: 820px) {
    padding: 0px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }

  @media (max-width: 429px) {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
`
const Right = styled.div`
  //width: 30%;
  width: 480px;
  min-height: 150%;
  background-color: #1c1c1e;
  //min-height: calc(100vh - 42px);
`
const Left = styled.div`
  min-width: calc(100% - 480px);
  width: calc(100% - 480px);
  min-height: 100%;
`

const myCourseDetailLayoutNew = ({ children, windowSize }) => {
  return (
    <Fragment>
      <Container>
        <DesktopAndTablet>
          <Left>{children}</Left>
          <Right>
            <Sidebar />
          </Right>
        </DesktopAndTablet>
        <SmallMobile>
          <div>{children}</div>
          <Sidebar />
        </SmallMobile>
      </Container>
    </Fragment>
  )
}

export default windowSize(myCourseDetailLayoutNew)
