import styled from 'styled-components'

export const Heading = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-top: 1px solid ${(props) => props.theme.gray8};
  padding: 10px 50px;
  z-index: 5;
  min-height: 60px;
  position: relative;
  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`
export const ContainerModal = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`
export const ContainerModalSuccess = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`
export const ButtonContainer = styled.div`
  width: 80%;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`
export const PageContainer = styled.div`
  padding: 40px 50px;
  /* background-color: #fafafa; */
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`
export const Container = styled.div`
  //background-color: #fff;
  border: 2.4px solid transparent;
  border-radius: 10px;
  height: auto;
  width: 100%;
  //padding: 20px 30px 25px 30px;
  // @media (max-width: 768px) {
  //   padding: 16px;
  // }
  // @media (max-width: 425px) {
  //   padding: 8px;
  // }
`

export const SwitchGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 700px) {
    justify-content: flex-start;
  }
`
export const SwitchContainer = styled.div`
  margin: 0px 7px;
  display: flex;
  .react-switch-bg {
    //background-color: #253e87 !important;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  // padding: 0 40px;
  padding: 32px 50px 40px 50px;
  margin-bottom: 16px;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  @media (max-width: 900px) {
    padding: 32px 30px 40px 50px;
  }
  // @media (max-width: 620px) {
  //   padding: 0 10px;
  // }

  @media (max-width: 560px) {
    flex-direction: column;
    justify-content: flex-start;
    //align-items: center;
    padding: 24px 24px;
  }

  @media (max-width: 425px) {
    padding: 24px;
  }
`

export const CustomizeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 32px 50px 40px 50px;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%);
  border-radius: 10px;

  @media (max-width: 900px) {
    padding: 32px 30px 40px 50px;
  }
  @media (max-width: 620px) {
    padding: 45px 10px 30px;
  }

  @media (max-width: 560px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 24px;
  }

  @media (max-width: 425px) {
    padding: 24px 15px;
  }
`
export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 560px) {
    justify-content: center;
  }
`

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  @media (max-width: 560px) {
    margin-bottom: 4px;
    margin-left: 4px;
  }
`
export const CheckContainer = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  margin-top: 3px;
  @media (max-width: 560px) {
    margin-right: 8px;
  }
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;

  @media (max-width: 860px) {
    // align-items: flex-end;
    margin-top: 13px;
  }
  @media (max-width: 560px) {
    width: 100%;
    align-items: center;
    margin-top: 16px;
  }
`
export const GroupContainerL = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 768px) {
    gap: 40px;
  }
  @media (max-width: 620px) {
    gap: 40px;
  }
`
export const GroupContainerR = styled.div`
  display: flex;
  //gap: 11px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 860px) {
    flex-direction: column;
  }
  @media (max-width: 560px) {
    width: 100%;
  }
`
export const IconContainer = styled.img`
  width: 100px;
  height: 100px;

  @media (max-width: 560px) {
    //margin-right: 16px;
  }
`
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ImagePlan = styled.img`
  width: 80px;
  height: 80px;
`
export const TypoGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  @media (max-width: 860px) {
    margin-right: 0px;
    //margin-bottom: 5px;
  }
`

export const Separator = styled.div`
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e8e8e8;
  margin: 16px 0px;
`
export const SeparatorMobile = styled.div`
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e8e8e8;
  margin: 16px 0px;
`

export const HolderDiv = styled.div`
  display: flex;
  align-items: center;
  height: 67.2px;
  @media (max-width: 1024px) {
    height: auto;
  }
`

export const DetailPack = styled.div`
  display: flex;
  gap: 64px;
  @media (max-width: 820px) {
    gap: 28px;
  }
`
