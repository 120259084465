import Resource from '../utils/resource'

export default new Resource('/Worksheets', {
  publishWorksheet: {
    url: '{id}/publishedWorksheets',
    method: 'post',
  },
  getCountQuestions: {
    url: '{id}/questions/count',
    method: 'get',
  },
  deleteWorkSheetById: {
    url: '{id}',
    method: 'patch',
  },
  deleteWorksheetSkills: {
    url: '{id}/worksheetSkills',
    method: 'delete',
  },
  deleteWorksheetSkillItem: {
    url: '{id}/worksheetSkills/{fk}',
    method: 'delete',
  },
  addQuestion: {
    url: '{id}/questions',
    method: 'post',
  },
  getQuestionList: {
    url: '{id}/questions',
    method: 'get',
  },
  updateQuestion: {
    url: '{id}/questions/{questionId}',
    method: 'put',
  },
  deleteQuestion: {
    url: '{id}/questions/{questionId}',
    method: 'delete',
  },
  updateQuestionPriority: {
    url: '{id}/questionPriority',
    method: 'post',
  },
})
