import styled from 'styled-components'
import { Input } from '../index'

const Wrapper = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0px;
  width: 100%;
  padding: 0px 10px;
`

const _Input = styled(Input)`
  background: #f9f9f9;
  box-shadow: inset 0 0 2px 0 rgba(208, 208, 208, 0.5);
  border-radius: 29.5px;
  border: none;
  width: 100%;
`

const Icon = styled.img`
  position: absolute;
  bottom: 6px;
  right: 28px;
`

export { Wrapper, _Input as Input, Icon }
