import styled, { css } from 'styled-components'
import { Card, Typography, Button } from '../index'
import theme from '../../theme'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  align-items: center;
  justify-content: center;
`

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ContainerCarousel = styled.div`
  width: 100%;
  padding: 0px 0 10px;
`
const _Card = styled(Card)`
  position: relative;
  padding: 40px;
  max-width: 1200px;
  margin: 0 30px;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  box-shadow: none;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 450px) {
    padding: 40px 20px 20px 20px;
    width: 500px;
    height: 700px;
  }
  @media (max-width: 1024px) and (min-width: 450px) {
    padding: 20px;
    width: 500px;
    height: 300px;
  }
  @media (max-width: 1024px) and (min-width: 450px) and (min-height: 450px) {
    padding: 20px;
    width: 100%;
    height: 600px;
  }
  @media (max-width: 720px) and (min-width: 540px) and (max-height: 360px) {
    padding: 20px;
    width: 500px;
    height: 250px;
  }
  @media (max-height: 450px) {
    padding: 20px;
    width: 500px;
    height: 330px;
    overflow-y: scroll;
  }
`

const Image = styled.div`
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  width: 100%;
  height: 317px;
  background-position: center;
  background-size: contain;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
`

const Description = styled(Typography)`
  @media screen and (max-width: 767px) {
    padding: 0 50px;
    margin: 10px 0 0 0;
  }
  @media screen and (max-width: 425px) {
    padding: 0;
    margin: 10px 0 10px 0;
  }
  @media (min-width: 768px) {
    padding: ${({ locale }) => (locale === 'th' ? '0 23vw' : '0 120px')};
    margin: 20px 0 10px 0;
  }
  @media (min-width: 1025px) {
    padding: ${({ locale }) => (locale === 'th' ? '0 120px' : '0 120px')};
  }
`
export const LangContainer = styled.div`
  width: 80%;
  //height: 80%;
  margin: 20px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const GroupDots = styled.div`
  display: flex;
  margin: 30px 0;

  @media screen and (max-width: 768px) {
    margin: 15px 0;
  }
  @media screen and (max-width: 425px) {
    margin: 5px 0;
  }
  @media (max-height: 425px) {
    margin: 5px 0;
  }
`
const GroupButtons = styled.div`
  gap: 16px;
  display: flex;
  @media screen and (max-width: 768px) {
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -webkit-flex: 1 1 5em;
    justify-content: center;
    align-items: center;
  }
`

const _Button = styled(Button)`
  //padding: 6px 64px;
  background-color: #253e87;
  transition: none;
  color: #fff;
  padding: 10px 64px;

  ${({ type }) => {
    let result = ''
    if (type === 'ghost') {
      result = `
        background-color: transparent;
        color: #253E87;
      `
    }
    if (type === 'white') {
      result = `
        background-color: white;
        color: #253E87;
        border: 1px solid ${theme.primary1};
        padding: 10px 84px;
      `
    }
    if (type === 'disabled') {
      result = `

          background-color: ${theme.gray5};
          color: ${theme.gray6};
          cursor: not-allowed;
 
      `
    }
    return result
  }}
`

const Dots = styled.div`
  //width: 7px;
  width: ${({ isDot }) => (isDot ? '25px' : '7px')};
  height: 7px;
  //border-radius: 50%;
  border-radius: ${({ isDot }) => (isDot ? '3px' : '50%')};
  background-color: ${({ isDot }) => (isDot ? '#253e87' : '#e0e2ed')};
  margin: 0 5px;
`

const _Typography = styled(Typography)`
  @media screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme['text-5xl']};
  }
  @media screen and (max-width: 426px) {
    font-size: ${({ theme }) => theme['text-2xl']};
  }
  @media (max-height: 450px) {
    font-size: ${({ theme }) => theme['text-2xl']};
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 284px;
  gap: 24px;
  @media (max-width: 768px) {
    height: auto;
  }

  /* @media screen and (max-width: 768px) {
    height: 280px;
    flex-direction: column;
    flex-wrap: wrap;
  } */
  @media screen and (max-height: 450px) {
    height: 128px;
    flex-direction: row;
    justify-content: center;
  }

  ${({ column }) => {
    if (column)
      return css`
        flex-direction: column;
        height: auto;
      `
    return css``
  }}
  ${({ isLang }) => {
    if (isLang) {
      return css`
        height: auto;
        width: 80%;
      `
    }
    return css``
  }}
`

const Slider = styled.div`
  ${({ isLang }) => {
    if (isLang) {
      return css`
        height: auto;
        //width: 40%;
        margin-top: 20px;
      `
    }
    return css`
      width: 80%;
      min-height: 280px;
      @media (max-width: 920px) {
        height: 300px;
      }
      @media (max-width: 768px) {
        height: 280px;
      }
    `
  }}
`

export {
  Container,
  _Card as Card,
  _Typography as Typography,
  Image,
  _Button as Button,
  Dots,
  Header,
  Description,
  GroupDots,
  GroupButtons,
  Content,
  Slider,
}
