/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import styled from 'styled-components'
import { createCurriculumAddTestData, disabledAddContentData } from '../../static'
import ErrorBoundary from '../../components/errorBoundary'
import { Mobile, Desktop } from '../../utils/displayResponsive'
import _ from 'lodash'
import { Card, ButtonDialog, Alert, Empty, Button } from '../../components'
import { Section, Lecture, PreTest, PostTest, Quiz } from './contents'
import image from '../../images/no-support-device.svg'
import ButtonDialogIcon from './buttonDialogIcon'
import SectionNew from './newCourseContent/SectionNew'
// const Description = styled(Typography)`
//   font-size: ${({ theme }) => theme['text-xs']};
//   /* line-height: 8px; */
//   color: ${(props) => props.theme.gray2};
//   padding: 0px 45px 10px;
// `
const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  background: white;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06);
  min-height: 56px;
  padding: 8px 50px;
  gap: 16px;
  align-items: center;
  z-index: 10;
  //position: sticky;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
`

const StyledCard = styled(Card)`
  /* overflow-y: scroll; */
  position: relative;
  // box-shadow: 0 2px 8px 2px rgba(20, 23, 28, 0.15);
  box-shadow: none;
  padding: 0px 150px 40px 150px;

  background-color: transparent;
  border-radius: 0px;
  width: ${({ isExpand }) => (isExpand ? 'calc(55% - 2px)' : '100%')};
  min-width: 550px;
  transition: width 1s cubic-bezier(0.23, 1, 0.32, 1);

  // ::after {
  //   content: '';
  //   bottom: 20px;
  //   left: 0;
  //   height: 20px;
  //   right: 0;
  //   background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  //   position: absolute;
  // }
`

const StyledContainer = styled.div`
  // grid-gap: 0px;
  // overflow-y: scroll;
  // height: calc(100vh - 300px);
  // padding: 10px 5px 10px 30px;

  @media (max-width: 991px) {
    height: calc(100vh);
  }
`
const CourseListCard = styled(Card)`
  padding: 24px 24px 24px 16px;
  background-color: #e0e6f6;

  border-color: ${({ isSelected, theme }) => (isSelected ? theme.primary1 : 'none')};
  border-left-width: 16px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  :hover {
    border-color: ${({ theme }) => theme.primary1};
  }
`
export class CourseContentPage extends React.Component {
  state = {
    contents: [],
    lectures: [],
    showAlert: false,
    titleAlert: '',
    isSaved: true,
    isDragging: false,
    selectedId: '',
    selectedIdType: '',
    isLoadding: false,
    isNewSection: false,
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    const {
      match: {
        params: { courseId },
      },
      getDraftCourseStatus,
    } = this.props
    try {
      await Promise.all([this.getLectureData(), this.getAssessmentData()])
      await getDraftCourseStatus({ id: courseId })
      this.reformat()
    } catch (error) {
      throw error
    }
  }

  handleSelected = (id) => {
    const { selectedId, isNewSection } = this.state
    if (selectedId === id) {
      this.setState({ selectedId: '', isNewSection: false })
    } else {
      this.setState({ selectedId: id })
    }
  }

  isSelected = (id) => {
    const { selectedId } = this.state

    return selectedId === id
  }

  handleAutoSave = (status) => {
    this.setState({ isSaved: status })
  }

  isExpand = () => {
    const { selectedId } = this.state
    return typeof selectedId === 'string' && selectedId !== ''
  }

  reformat = () => {
    const { assessments, lectures } = this.props
    const injectComponent = (contents) => {
      // console.log('contents', contents)
      return contents.map((content) => {
        let result = {}
        switch (content.type) {
          case 'paragraph':
            result = {
              ...content,
              onChangeTitleName: this.updateSectionName,
              component: Section,
            }
            break
          case '':
            result = {
              ...content,
              init: this.init,
              onChangeTitleName: this.updateSectionName,
              onChangeLectureType: this.updateLectureType,
              component: Lecture,
            }
            break
          case 'pretest':
            result = {
              ...content,
              init: this.init,
              component: PreTest,
            }
            break
          case 'posttest':
            result = {
              ...content,
              init: this.init,
              component: PostTest,
            }
            break
          case 'quiz':
            result = {
              ...content,
              onChangeTitleName: this.updateSectionName,
              component: Quiz,
            }
            break
          default:
            break
        }
        result.handleSelected = this.handleSelected
        result.isSelected = this.isSelected
        result.onChange = this.init
        result.handleAutoSave = this.handleAutoSave
        result.onClick = this.onClick
        result.addNewContent = this.addNewContent
        return result
      })
    }

    this.setState({
      contents: [...injectComponent(lectures), ...injectComponent(assessments)],
      lectures: orderBy(
        [
          ...injectComponent(lectures),
          ...injectComponent(assessments.filter(({ type }) => type === 'quiz')),
        ],
        'priority'
      ),
    })
  }

  getLectureData = async () => {
    const {
      getDraft,
      fetchSubjectLecture,
      match: {
        params: { courseId: id },
      },
    } = this.props
    try {
      const { subjectId } = await getDraft({ id })
      await fetchSubjectLecture(subjectId)
    } catch (error) {
      throw error
    }
  }

  getAssessmentData = async () => {
    const {
      getDraft,
      getAssessment,
      match: {
        params: { courseId: id },
      },
    } = this.props
    try {
      const { subjectId } = await getDraft({ id })
      await getAssessment({ subjectId })
    } catch (error) {
      throw error
    }
  }

  onClick = async (type) => {
    this.setState({ isLoadding: true })
    const {
      postSubjectLecture,
      createAssessment,
      postSubjectLectureWithPriority,
      draftInfo: { subjectId },
      //lectures,
    } = this.props
    const { contents, selectedId, lectures } = this.state

    let priority = this.state.contents.length
    const typeSelelet = lectures.filter((item) => item?.id === selectedId)

    let priorityUse
    if (typeSelelet[0]?.type === 'paragraph') {
      const section = lectures.filter((item) => item.type === 'paragraph')
      const position1 = section.findIndex((item) => item.id === selectedId)
      const sectionPriority = section[position1 + 1]
      priorityUse = sectionPriority?.priority - 1
    } else if (typeSelelet[0]?.type === '') {
      const lectureIndex = lectures.findIndex((item) => item.id === selectedId)
      const lecturesSliceIndex = lectures.slice(lectureIndex)
      const lecturePriority = lecturesSliceIndex.filter((item) => item.type === 'paragraph')
      priorityUse = lecturePriority[0]?.priority - 1
    } else if (typeSelelet[0]?.type === 'quiz') {
      const lectureIndex = lectures.findIndex((item) => item.id === selectedId)
      const lecturesSliceIndex = lectures.slice(lectureIndex)
      const lecturePriority = lecturesSliceIndex.filter((item) => item.type === 'paragraph')
      priorityUse = lecturePriority[0]?.priority - 1
    } else {
      priorityUse = priority += 1
    }

    const sectionPriorityPayload = selectedId ? priorityUse : (priority += 1)
    try {
      switch (type) {
        case 0: {
          const data = { name: `Untitled` }
          const payload = {
            subjectId,
            type: 'paragraph',
            priority: sectionPriorityPayload,
            //priority: (priority += 1),
            ...data,
          }
          const Subject = await postSubjectLecture(payload)

          this.setState({ selectedId: Subject.id, isNewSection: true })
          break
        }
        case 1: {
          const data = {
            name: 'Untitled',
            description: '',
            content: '',
            videoLink: '',
            preview: {},
            lectureSlide: '',
            attachments: [],
          }
          const payload = {
            subjectId,
            type: '',
            priority: (priority += 1),
            ...data,
          }
          await postSubjectLecture(payload)
          break
        }
        case 2: {
          const data = {
            name: 'Untitled',
            description: '',
            isRandomQuestion: false,
            isRandomAnswer: false,
            startDate: null,
            endDate: null,
            timeoutHour: 0,
            timeoutMinute: 0,
          }
          const payload = {
            subjectId,
            priority: (priority += 1),
            type: 'quiz',
            ...data,
          }
          await createAssessment(payload)
          break
        }
        case 3: {
          const isHave = contents.map(({ type: t }) => t).includes('pretest')
          if (isHave)
            return this.setState({ showAlert: true, titleAlert: 'Pre-test must have once only' })

          const data = {
            name: 'Untitled',
            description: '',
            isRandomQuestion: false,
            isRandomAnswer: false,
            startDate: null,
            endDate: null,
            timeoutHour: 0,
            timeoutMinute: 0,
          }
          const payload = {
            subjectId,
            type: 'pretest',
            ...data,
          }
          await createAssessment(payload)
          break
        }
        case 4: {
          const isHave = contents.map(({ type: t }) => t).includes('posttest')
          if (isHave)
            return this.setState({ showAlert: true, titleAlert: 'Post-test must have once only' })
          const data = {
            name: 'Untitled',
            description: '',
            isRandomQuestion: false,
            isRandomAnswer: false,
            startDate: null,
            endDate: null,
            timeoutHour: 0,
            timeoutMinute: 0,
          }
          const payload = {
            subjectId,
            type: 'posttest',
            ...data,
          }
          await createAssessment(payload)
          break
        }

        default:
          // content = { id: null, type: null, component: null, data: null, onChange: null }
          break
      }
    } catch (error) {
      throw error
    } finally {
      await this.init()
      await this.setState({ isLoadding: false })
    }
    return null
  }

  addNewContent = async (type, prt = 0) => {
    this.setState({ isLoadding: true })
    const {
      postSubjectLecture,
      createAssessment,
      draftInfo: { subjectId },
    } = this.props
    const { contents } = this.state
    const priority = prt
    try {
      switch (type) {
        case 0: {
          const data = { name: 'Untitled' }
          const payload = {
            subjectId,
            type: 'paragraph',
            priority,
            // priority: (priority += 1),
            ...data,
          }
          await postSubjectLecture(payload)

          break
        }
        case 1: {
          const data = {
            name: 'Untitled',
            description: '',
            content: '',
            videoLink: '',
            preview: {},
            lectureSlide: '',
            attachments: [],
          }
          const payload = {
            subjectId,
            type: '',
            // priority: (priority += 1),
            priority,
            ...data,
          }
          const Subject = await postSubjectLecture(payload)

          this.handleSelected(Subject.id)
          //this.setState({ selectedId: Subject.id })
          break
        }
        case 2: {
          const data = {
            name: 'Untitled',
            description: '',
            isRandomQuestion: false,
            isRandomAnswer: false,
            // startDate: new Date(),
            // endDate: new Date(),
            timeoutHour: 0,
            timeoutMinute: 0,
          }
          const payload = {
            subjectId,
            // priority: (priority += 1),
            priority,

            type: 'quiz',
            ...data,
          }
          const Subject = await createAssessment(payload)
          this.handleSelected(Subject.id)
          break
        }
        case 3: {
          const isHave = contents.map(({ type: t }) => t).includes('pretest')
          if (isHave)
            return this.setState({ showAlert: true, titleAlert: 'Pre-test must have once only' })

          const data = {
            name: 'Untitled',
            description: '',
            isRandomQuestion: false,
            isRandomAnswer: false,
            // startDate: new Date(),
            // endDate: new Date(),
            timeoutHour: 0,
            timeoutMinute: 0,
          }
          const payload = {
            subjectId,
            type: 'pretest',
            ...data,
          }
          await createAssessment(payload)
          break
        }
        case 4: {
          const isHave = contents.map(({ type: t }) => t).includes('posttest')
          if (isHave)
            return this.setState({ showAlert: true, titleAlert: 'Post-test must have once only' })
          const data = {
            name: 'Untitled',
            description: '',
            isRandomQuestion: false,
            isRandomAnswer: false,
            // startDate: new Date(),
            // endDate: new Date(),
            timeoutHour: 0,
            timeoutMinute: 0,
          }
          const payload = {
            subjectId,
            type: 'posttest',
            ...data,
          }
          await createAssessment(payload)
          break
        }

        default:
          // content = { id: null, type: null, component: null, data: null, onChange: null }
          break
      }
    } catch (error) {
      throw error
    } finally {
      await this.init()
      await this.updatePriority()
      await this.setState({ isLoadding: false })
    }
    return null
  }
  updateSectionName = (name, priority) => {
    this.setState((prevState) => {
      const index = prevState.lectures.findIndex((l) => l.priority === priority)
      const newLectures = [
        ...prevState.lectures.slice(0, index),
        { ...prevState.lectures[index], name },
        ...prevState.lectures.slice(index + 1),
      ]
      return { lectures: newLectures }
    })
  }
  updateLectureType = (lectureType, priority) => {
    this.setState((prevState) => {
      const index = prevState.lectures.findIndex((l) => l.priority === priority)
      const newLectures = [
        ...prevState.lectures.slice(0, index),
        { ...prevState.lectures[index], lectureType },
        ...prevState.lectures.slice(index + 1),
      ]
      return { lectures: newLectures }
    })
  }
  updatePriority = async () => {
    const { lectures } = this.state
    const {
      updateContentPriority,
      draftInfo: { subjectId },
    } = this.props
    const newPriority = lectures
      // .slice(dstIndex, srcIndex + 1)
      .map((item, index) => ({ ...item, priority: index + 1 }))
    updateContentPriority({
      subjectId,
      contents: newPriority.map(({ id, type, priority }) => ({
        id,
        type,
        priority,
      })),
    })

    this.setState({ lectures: [...newPriority] })
  }

  getNewPriority = (list, srcIndex, dstIndex) => {
    if (srcIndex < dstIndex) {
      // 1 2* 3 4 --> 1 3 4 2*
      const head = list.slice(0, srcIndex)
      const tail = list.slice(dstIndex + 1)
      const newPriority = list
        .slice(srcIndex, dstIndex + 1)
        .map((item, index) => ({ ...item, priority: srcIndex + index + 1 }))
      return { head, newPriority, tail }
    }
    if (dstIndex < srcIndex) {
      // 1 2 3 4* --> 1 4* 2 3
      const head = list.slice(0, dstIndex)
      const tail = list.slice(srcIndex + 1)
      const newPriority = list
        .slice(dstIndex, srcIndex + 1)
        .map((item, index) => ({ ...item, priority: dstIndex + index + 1 }))
      return { head, newPriority, tail }
    }
    return { head: [], newPriority: [], tail: [] }
  }

  onBeforeDragStart = () => {
    this.setState({ isDragging: true, selectedId: '' })
  }

  onDragStart = () => {
    // this.setState({ isDragging: true, selectedId: '' })
  }

  moveLecture(from, to, list) {
    const targetItem = list[from.index]?.contents[from.innerIndex]
    if (from.index === to.index) {
      const cooo_list = _.cloneDeep(list)
      const aaa = [
        ...list[from.index].contents.slice(0, from.innerIndex),
        ...list[from.index].contents.slice(from.innerIndex + 1),
      ]
      cooo_list[from.index].contents = aaa
      cooo_list[from.index].contents.splice(to.innerIndex, 0, targetItem)

      return cooo_list
    } else {
      const cuttedList = [
        ...list.slice(0, from.index),
        {
          ...list[from.index],
          contents: list[from.index]?.contents.filter((item, i) => i !== from.innerIndex),
        },
        ...list.slice(from.index + 1),
      ]
      const currentItem = [
        ...(list[to.index]?.contents || []).slice(0, to.innerIndex),
        targetItem,
        ...(list[to.index]?.contents || []).slice(to.innerIndex),
      ]
      const newList = [
        ...cuttedList.slice(0, to.index),
        { ...list[to.index], contents: currentItem },
        ...cuttedList.slice(to.index + 1),
      ]

      return newList
    }
  }
  onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result
    const { lectures } = this.state
    const {
      match: {
        params: { courseId },
      },
      getDraftCourseStatus,
    } = this.props
    // source === ตำแหน่งเริ่ม
    // destination === ตำแหน่งที่วาง
    this.setState({ isLoadding: true })
    //console.log('result', result)
    const {
      updateContentPriority,
      draftInfo: { subjectId },
    } = this.props
    if (type === 'lecture') {
      if (!destination) {
        this.setState({ isDragging: false, isLoadding: false })
        return
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        this.setState({ isDragging: false, isLoadding: false })
        return
      }
      // if (destination.index === 0) {
      //   this.setState({ isDragging: false })
      //   return
      // }
      const reformatLectures = this.reformatLecturesArr(lectures)

      const lecturesReduce = reformatLectures.reduce((list, arrLecture) => {
        if (arrLecture.type === 'paragraph') {
          return [...list, arrLecture]
        }
        const lastLecture = _.cloneDeep(list).slice(-1)?.[0]

        if (lastLecture) {
          lastLecture.contents = lastLecture.contents || []
          lastLecture.contents = [...lastLecture.contents, arrLecture]
          return [...list.slice(0, -1), lastLecture]
        }

        return list
      }, [])
      const sourceIndex = lecturesReduce?.findIndex((item) => item?.id === source?.droppableId)
      const sourceLectureIndex = source?.index
      const destinationIndex = lecturesReduce?.findIndex(
        (item) => item?.id === destination?.droppableId
      )
      const destinationLectureIndex = destination?.index

      const form = { index: sourceIndex, innerIndex: sourceLectureIndex }
      const to = { index: destinationIndex, innerIndex: destinationLectureIndex }

      const arr = this.moveLecture(form, to, lecturesReduce)
      const reformatSection = this.reformatLecturesArr(arr)
      const sectionReduce = reformatSection.reduce((list, arrSection) => {
        let arrLecture = [arrSection]
        arrSection?.contents?.map((item) => {
          arrLecture.push(item)
        })
        //console.log('arrLecture', arrLecture)
        //console.log('arrSection', arrSection)
        console.log('list', list)
        return [...list, ...arrLecture]
      }, [])
      const sectionReduce2 = sectionReduce?.map((item, idx) => {
        return { ...item, priority: idx + 1 }
      })
      await updateContentPriority({
        subjectId,
        contents: sectionReduce2.map(({ id, type, priority }) => ({
          id,
          type,
          priority,
        })),
      })
      await this.init()
      this.setState({ isDragging: false, isLoadding: false })
    } else if (type === 'section') {
      if (!destination) {
        this.setState({ isDragging: false, isLoadding: false })
        return
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        this.setState({ isDragging: false, isLoadding: false })
        return
      }
      const reformatLectures = this.reformatLecturesArr(lectures)

      const lecturesReduce = reformatLectures.reduce((list, arrLecture) => {
        if (arrLecture.type === 'paragraph') {
          return [...list, arrLecture]
        }
        const lastLecture = _.cloneDeep(list).slice(-1)?.[0]

        if (lastLecture) {
          lastLecture.contents = lastLecture.contents || []
          lastLecture.contents = [...lastLecture.contents, arrLecture]
          return [...list.slice(0, -1), lastLecture]
        }

        return list
      }, [])
      const newSection = [...lecturesReduce]
      const sectionMove = newSection.splice(result.source.index, 1)
      sectionMove.map((item) => {
        newSection.splice(result.destination.index, 0, item)
      })

      const reformatSection = this.reformatLecturesArr(newSection)
      const sectionReduce = reformatSection.reduce((list, arrSection) => {
        let arrLecture = [arrSection]
        arrSection?.contents?.map((item) => {
          arrLecture.push(item)
        })
        return [...list, ...arrLecture]
      }, [])

      const sectionReduce2 = sectionReduce?.map((item, idx) => {
        return { ...item, priority: idx + 1 }
      })

      await updateContentPriority({
        subjectId,
        contents: sectionReduce2.map(({ id, type, priority }) => ({
          id,
          type,
          priority,
        })),
      })
      await this.init()
      await this.setState({ isLoadding: false })
      // this.setState({ lectures: [...head, ...newPriority, ...tail], isDragging: false })

      // this.setState({ lectures: [...sectionReduce2], isDragging: false })
    } else {
      this.setState({ isDragging: false, isLoadding: false })

      return
    }
  }

  handlePublishContent = async () => {
    const {
      publishContent,
      match: {
        params: { courseId },
      },
    } = this.props

    await publishContent({ id: courseId })
  }
  reformatLecturesArr = (lectures) => {
    const newLectures = lectures.reduce((arr, item) => {
      if (item?.type === '') {
        const findLecture = lectures.find((o) => o.lectureId === item.id)
        return [...arr, { ...item, ...findLecture }]
      }
      return [...arr, item]
    }, [])
    return newLectures
  }

  render() {
    const {
      contents,
      lectures,
      showAlert,
      titleAlert,
      isDragging,
      selectedId,
      isLoadding,
      isNewSection,
    } = this.state
    const {
      draftInfo,
      locale,
      isPublished,
      match: {
        params: { courseId, schoolId },
      },
      draftInfo: { subjectId },
    } = this.props

    const hasPreTest = typeof contents.find(({ type }) => type === 'pretest') === 'object'
    const hasPostTest = typeof contents.find(({ type }) => type === 'posttest') === 'object'
    // const tutorialLink = 'http://classwin.co/course-information/'
    // const tutorialLinkCourseSeciton = 'http://classwin.co/course-section/'

    // const newLectures = lectures.reduce((arr, item, index) => {
    //   if (item.type === 'paragraph') {
    //     return [...arr, [{ ...item, index }]]
    //   }
    //   const lastSection = arr.slice(-1)[0] || []
    //   return [...arr.slice(0, -1), [...lastSection, { ...item, index }]]
    // }, [])
    if (isEmpty(draftInfo)) return null

    // console.log('lectures', lectures)
    // console.log('newLectures', newLectures)
    // const newSection = lectures.reduce((arr, item, index) => {
    //   console.log('arr', arr)
    //   console.log('item', item)
    //   console.log('index', index)
    // }, [])

    // const reformatLectures = this.useCallback(() => {
    //   const newLectures = lectures.reduce((arr, item) => {
    //     if (item?.type === '') {
    //       const findLecture = lectures.find((o) => o.lectureId === item.id)
    //       return [...arr, { ...item, ...findLecture }]
    //     }
    //     return [...arr, item]
    //   }, [])
    //   return newLectures
    // }, [lectures])

    const reformatLectures = this.reformatLecturesArr(lectures)

    const lecturesReduce = reformatLectures.reduce((list, arrLecture) => {
      if (arrLecture.type === 'paragraph') {
        return [...list, arrLecture]
      }
      //  console.log('arrLecture', arrLecture)
      const lastLecture = _.cloneDeep(list).slice(-1)?.[0]
      //  console.log('lastLecture', lastLecture)

      if (lastLecture) {
        lastLecture.contents = lastLecture.contents || []
        //lastLecture.contents = []
        lastLecture.contents = [...lastLecture.contents, arrLecture]
        return [...list.slice(0, -1), lastLecture]
      }

      return list
    }, [])
    //console.log('lectures', lectures)
    //console.log('lecturesReduce', lecturesReduce)
    const preTest = contents.filter(({ type }) => type === 'pretest')
    const postTest = contents.filter(({ type }) => type === 'posttest')

    return (
      <ErrorBoundary>
        <Desktop>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              padding: '0px 50px 40px 50px',
              borderRadius: '0px',
              width: '100%',
              minWidth: '500px',
            }}
          >
            {contents.length === 0 && (
              <ButtonDialog
                onClick={this.onClick}
                data={contents.length === 0 ? disabledAddContentData : createCurriculumAddTestData}
                hasPreTest={hasPreTest}
                hasPostTest={hasPostTest}
              />
            )}
            {contents.length > 0 && (
              <ButtonDialogIcon
                onClick={this.onClick}
                data={disabledAddContentData}
                hasPreTest={hasPreTest}
                hasPostTest={hasPostTest}
              />
            )}
            {/* <StyledCard border="none" isExpand={this.isExpand()}>
              <DragDropContext
                onDragEnd={this.onDragEnd}
                onDragStart={this.onDragStart}
                onBeforeDragStart={this.onBeforeDragStart}
              >
                <Droppable
                  droppableId="lecture"
                  isCombineEnabled
                  type="lecture"
                  //direction="horizon"
                >
                  {(provided) => {
                    return (
                      <StyledContainer ref={provided.innerRef} {...provided.droppableProps}>
                        {newLectures.map((item, idx) => {
                          const test = item.filter((data) => data.id === selectedId)

                          return (
                            <CourseListCard
                              key={idx}
                              margin="24px 0 0"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                              }}
                              // isSelected={
                              //   selectedId ? test.length : selectedId === '' ? ssss : false
                              // }
                              isSelected={test.length}
                            >
                              {item.map(({ component: Component, ...data }) => {
                                return (
                                  <Draggable key={data.id} draggableId={data.id} index={data.index}>
                                    {(dragProvided) => (
                                      <section
                                        key={data.id}
                                        {...dragProvided.draggableProps}
                                        ref={dragProvided.innerRef}
                                      >
                                        <Component
                                          {...data}
                                          dragHandle={dragProvided.dragHandleProps}
                                          // isDragging={snapshot.isDragging}
                                          isDragging={isDragging}
                                          isDragDisabled={(isDragDisabled) => isDragDisabled}
                                          selectedId={selectedId}
                                        />
                                      </section>
                                    )}
                                  </Draggable>
                                )
                              })}
                            </CourseListCard>
                          )
                        })}
                        {provided.placeholder}
                      </StyledContainer>
                    )
                  }}
                </Droppable>
              </DragDropContext>
            </StyledCard> */}
            <StyledCard border="none" isExpand={this.isExpand()}>
              <DragDropContext
                onDragEnd={this.onDragEnd}
                onDragStart={this.onDragStart}
                onBeforeDragStart={this.onBeforeDragStart}
              >
                <Droppable
                  droppableId="lecture"
                  isCombineEnabled
                  type="section"
                  //direction="horizon"
                >
                  {(provided) => {
                    return (
                      <StyledContainer ref={provided.innerRef} {...provided.droppableProps}>
                        {contents
                          .filter(({ type }) => type === 'pretest')
                          .map(({ component: Component, ...data }) => {
                            //console.log('data', data)
                            return (
                              <CourseListCard
                                margin="24px 0 0"
                                key={data.id}
                                isSelected={this.isSelected(data.id)}
                              >
                                <section>
                                  <Component
                                    {...data}
                                    selectedId={selectedId}
                                    postTest={postTest}
                                    isSelected={this.isSelected}
                                    handleSelected={this.handleSelected}
                                  />
                                </section>
                              </CourseListCard>
                            )
                          })}
                        {lecturesReduce.map((item, idx) => (
                          <SectionNew
                            {...item}
                            item={item}
                            key={item?.id}
                            index={idx}
                            handleSelected={this.handleSelected}
                            addNewContent={this.addNewContent}
                            selectedId={selectedId}
                            isDragging={isDragging}
                            subjectId={subjectId}
                            init={this.init}
                          />
                        ))}

                        {provided.placeholder}
                        {contents
                          .filter(({ type }) => type === 'posttest')
                          .map(({ component: Component, ...data }) => (
                            <CourseListCard
                              margin="24px 0 0"
                              key={data.id}
                              isSelected={this.isSelected(data.id)}
                            >
                              <section>
                                <Component
                                  {...data}
                                  selectedId={selectedId}
                                  preTest={preTest}
                                  isSelected={this.isSelected}
                                  handleSelected={this.handleSelected}
                                />
                              </section>
                            </CourseListCard>
                          ))}
                      </StyledContainer>
                    )
                  }}
                </Droppable>
              </DragDropContext>
            </StyledCard>
          </div>
          <Alert
            isOpen={isLoadding}
            onRequestClose={() => this.setState({ isLoadding: false })}
            title={locale === 'en' ? 'Loading' : 'กำลังโหลด'}
            loading
          />
        </Desktop>
        <Mobile>
          {/* <Card>
            <header>
              <Typography h4>Course Content mobile</Typography>
            </header>
          </Card> */}
          <Empty
            image={image}
            id={{ title: 'course.content.emptyFeatures.mobile' }}
            title="ON Features"
          />
        </Mobile>
        <Alert
          isOpen={showAlert}
          onRequestClose={() => this.setState({ showAlert: false })}
          title={titleAlert}
          error
        />
        {isPublished ? (
          <Footer>
            <Link
              to={`/schools/${schoolId}/course-preview/${courseId}/lecture`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button cancelbtn type="button" id="button.preview" width={171}>
                Preview
              </Button>
            </Link>
            <Button
              id="course.setting.button.update"
              type="button"
              primary
              width={171}
              onClick={this.handlePublishContent}
            >
              update course
            </Button>
          </Footer>
        ) : (
          <></>
        )}
      </ErrorBoundary>
    )
  }
}

const mapState = ({
  DraftCourse: { draftInfo },
  Subject: { assessments, lectures },
  Language: { locale },
}) => ({
  draftInfo,
  assessments,
  lectures,
  locale,
  isPublished: false,
})
const mapDispatch = ({
  DraftCourse: { getDraft, getDraftCourseStatus },
  Subject: {
    postSubjectLecture,
    fetchSubjectLecture,
    postSubjectLectureWithPriority,
    getAssessment,
    createAssessment,
    updateContentPriority,
  },
}) => ({
  getDraft,
  postSubjectLecture,
  fetchSubjectLecture,
  postSubjectLectureWithPriority,
  getAssessment,
  createAssessment,
  updateContentPriority,
  getDraftCourseStatus,
})

export default connect(mapState, mapDispatch)(withRouter(CourseContentPage))
