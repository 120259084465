/* eslint-disable no-unused-vars */
import React from 'react'
import { Typography } from 'components'
import styled, { css } from 'styled-components'
import Icon from '../../../../components/icon/loadable'
import IconClose from '../../../../images/icons/DarkIcon/White-close.svg'
import LectureNavigation from './LectureNavigation'
import PrePostTestItem from './PrePostTest'
import windowSize from 'react-window-size'

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 70px 0px 60px 0px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0px;
  }
  /* ${({ height }) => css`
    height: ${height}px;
  `} */
  height: 100vh;
  @media (max-width: 429px) {
    padding: 0px 0px 50px 0px;
    margin: 64px 0px 0px 0px;
    background-color: #222223;
    height: 500px;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  height: 100vh;
  background-color: #1c1c1e;
  padding: 20px 16px 20px 16px;
  min-height: 100%;
  width: 480px;
  //width: 480px;
  @media (max-width: 429px) {
    width: 100%;
    background-color: #222223;
  }
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 40px 16px 0px;
  position: absolute;
  background-color: #1c1c1e;
  width: 480px;
  @media (max-width: 429px) {
    width: 100%;
    background-color: #222223;
  }
`
const Layout = styled.div`
  position: fixed;
  z-index: 100;
  transition: 1s;
  right: -500px;
  ${({ isDetail }) => {
    if (isDetail) {
      return css`
        transform: translate(-500px, 0px);
      `
    } else {
      return css`
        transform: translate(0px, 0px);
      `
    }
  }}
  @media (max-width: 429px) {
    right: 0px;
    ${({ isDetail }) => {
      if (isDetail) {
        return css`
          transform: translate(0px, 0px);
        `
      } else {
        return css`
          transform: translate(0px, 0px);
        `
      }
    }}
  }
`
function Lecture(props) {
  const { setIsDetail, data, preTest, postTest, isMobile, courseProject, isDetail } = props
  const heightWindow = window.innerHeight
  return (
    <Layout isDetail={isDetail}>
      <Container>
        {isMobile ? (
          <div style={{ margin: '24px 0px 0px 0px' }}></div>
        ) : (
          <Header>
            <Typography color="white" h3>
              Lecture {courseProject?.current}/{courseProject?.full}
            </Typography>
            <div
              onClick={() => {
                setIsDetail(false)
              }}
              style={{ cursor: 'pointer' }}
            >
              <Icon src={IconClose} size="16" />
            </div>
          </Header>
        )}

        <Body height={heightWindow}>
          {preTest && <PrePostTestItem {...preTest} key={preTest?.id} />}
          {data?.map((item) => {
            return <LectureNavigation item={item} key={item?.id} />
          })}
          {postTest && <PrePostTestItem {...postTest} key={postTest?.id} isPostTest />}
        </Body>
      </Container>
    </Layout>
  )
}

export default windowSize(Lecture)
