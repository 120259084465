import Resource from '../utils/resource'

export default new Resource('/CourseCategories', {
  getCategories: {
    url: 'get-categories',
    method: 'get',
  },
  getCategorieMarketplace: {
    url: 'marketplace-categories',
    method: 'get',
  },
})
