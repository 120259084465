import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    font-family: 'Noto Sans Thai', sans-serif;
    color: ${(props) => props.theme.dark};
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 0;
    font-size: ${({ theme }) => theme['text-xs']};
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

 .ReactModal__Overlay > #modal-slide {
       opacity: 0;
  transform: translateY(300px);
  transition: all 0.8s ease-in-out;
    }
 
    .ReactModal__Overlay--after-open  > #modal-slide {
       opacity: 1;
  transform: translateY(0px);
    }

    .ReactModal__Overlay--before-close  > #modal-slide {
    opacity: 0;
    transition: 0.8s;
  transform: translateY(300px);
    } 
  
  .container-style {
    left: -75px !important;
    top: -5px !important;

    @media (max-width: 768px) {
      left: -125px !important;
      top: -10px !important;
    }

    @media (max-width: 450px) {
      left: -45px !important;
      top: -8px !important;
    }
  }

  .schedule-menu {
    z-index: 100;
    left: 10px !important;
    top: 15px !important;

  }

  .comment-menu {
    left: -65px !important;
    top: 5px !important;

    @media (max-width: 768px) {
      left: -95px !important;
    }
    @media (max-width: 400px) {
      left:  -95px !important;
    }
  }

  .post-menu {
    left: -65px !important;
    top: 5px !important;
  }

  .subject-menu {
    z-index: 100;

    @media (max-width: 768px) {
      left: -80px !important;
    }

  }

  .noti-menu {
    z-index: 100;
    left: -170px !important;
    top: 0px !important;

    @media (max-width: 768px) {
      left: 0 !important;
      top: 74px !important;
      transform: translate3d(0, 0, 0) !important;
    }
  }

  .profile-menu {
    z-index: 100;
    left: -50px !important;
    top: 10px !important;

    @media (max-width: 768px) {
      left: -60px !important;
    }
  }

  .role-menu {
    z-index: 100;
    left: 30px !important;
    top: 10px !important;

    @media (max-width: 768px) {
      left: -60px !important;
      top: 20px !important;
    }
  }

  .search-menu {
    z-index: 500;
    left: 50px !important;
    top: 25px !important;
  }

  .member-role-menu {
    top:-15px !important;
  }

  .school-list-popup {
    z-index: 500;
    // transform: translate3d(0, 0, 0) !important;
  }

  select.select-country-code {
    color: ${(props) => props.theme.black87};
    background-color: white;
    border: none;
    border-radius: 10px;
    outline: none;
    display: flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg stroke='black' fill='black' height='1em' viewBox='0 0 512 512' width='1emm' stroke-width='0' xmlns='http://www.w3.org/2000/svg'><path d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    padding: 5px 0;
    margin: 0 10px;
  }

  a:link,
  a:active,
  a:visited {
    text-decoration: none;
  }

  .recharts-legend-wrapper{
    bottom:-50px !important;
    display: flex !important;
    justify-content: center !important;
  }

  .line-progress{
    max-height: 20px;
  }
  .height-filter-course-dropdown {
    .ant-select-dropdown{
      border-radius: 4px;
    }
    .ant-cascader-menu {
      height: 460px;
      padding-top: 24px;
    }
    .ant-cascader-menu-item:hover {
      background: ${(props) => props.theme.whiteblue};
    }
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
      background-color: ${(props) => props.theme.whiteblue};
    }
  }
  .anticon.ant-notification-notice-icon-success > svg {
    fill: #52c41a;
  }
  .anticon.ant-notification-notice-icon-error > svg {
    fill: #ff4d4f;
  }
  .anticon.ant-notification-notice-icon-warning > svg {
    fill: #faad14;
  }
`
