import React, { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Typography, WithNoti, BadgeContainer, MiniBadge } from './style'

function PureTypography({ id = '', values = {}, children, ...props }) {
  const [showLess, setShowLess] = useState(true)
  const [height, setHeight] = useState(0)
  let ref = useRef()
  useLayoutEffect(() => {
    if (props.seeMore && ref.current) {
      setHeight(ref.current.clientHeight)
    }
  }, [children, props.seeMore])
  if (!children) return null
  if (!id) return <Typography {...props}>{children}</Typography>
  if (props.reverse) {
    return (
      <Typography {...props}>
        <FormattedMessage id={id} values={values} defaultMessage={children} />
        {props.image && props.image}
        {props.icon && props.icon}
      </Typography>
    )
  } else if (props.seeMore) {
    const { numberOfLines = 5 } = props
    return (
      <>
        <Typography
          ref={ref}
          className="seeMore"
          {...props}
          numberOfLines={height > 100 && showLess ? numberOfLines : undefined}
        >
          {props.image && props.image}
          {props.icon && props.icon}
          <FormattedMessage id={id} defaultMessage={children} values={values} />
        </Typography>
        {height > 100 && (
          <div onClick={() => setShowLess(!showLess)}>{showLess ? 'See more' : 'See less'}</div>
        )}
      </>
    )
  } else if (props.notiCount) {
    const { divClassName, labelClassName, notiCount, minWidth, ...rest } = props
    return (
      <WithNoti className={divClassName} minWidth={minWidth}>
        <Typography
          className={labelClassName}
          {...rest}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <FormattedMessage id={id} values={values} defaultMessage={children} />
          <BadgeContainer>
            <MiniBadge position="static">{notiCount}</MiniBadge>
          </BadgeContainer>
        </Typography>
      </WithNoti>
    )
  } else {
    return (
      <Typography {...props}>
        {props.image && props.image}
        {props.icon && props.icon}
        <FormattedMessage id={id} defaultMessage={children} values={values} />
      </Typography>
    )
  }
}

PureTypography.propTypes = {
  id: PropTypes.string,
  values: PropTypes.object,
  children: PropTypes.node,
  props: PropTypes.object,
}

PureTypography.defaultProps = {
  props: {},
  children: null,
}

export default PureTypography
