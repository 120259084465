export const Intro = {
  state: {
    openProfileMenu: false,
  },
  reducers: {
    _setOpenProfileMenu(state, payload) {
      return {
        ...state,
        openProfileMenu: payload,
      }
    },
  },
  effects: () => ({
    async setOpenProfileMenu(payload) {
      try {
        this._setOpenProfileMenu(payload)
      } catch (e) {
        throw e
      }
    },
  }),
}
