import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { FileAPI } from 'api'
import { connect } from 'react-redux'
import UploadComponent from '../uploadComponent'
import { Typography, Cropable, Label, Modal } from '../index'
import { Content, UploadImageStyle, ImageBox, Image, UploadIcon, Wrapper } from './style'
import { customUploadModal } from './customUploadModal'
import getImageUrl from '../../utils/getImageUrl'
/*import CameraIcon from '../../images/icon-camera.png'*/
import CameraIcon from '../../images/ic-new-camera.svg'

const UploadAvatar = ({
  id,
  label,
  showImage = true,
  value,
  onChange,
  name,
  afterUpload,
  error,
  touched,
  cropShape,
  aspect,
  grayBorder,
  styleImageBox = {},
  styleUploadImage = {},
  center,
  cropLabel = '',
  cropLabelId,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [fileType, setFileType] = useState(null)
  const wrapperWidth = parseInt(styleImageBox?.width || '200')
  const iconWidth = wrapperWidth * 0.3
  const onClickView = () => {
    setIsOpen(true)
  }

  const renderLightbox = () => {
    return <Lightbox mainSrc={getImageUrl(value)} onCloseRequest={() => setIsOpen(false)} />
  }

  const beforeCrop = (formData) => {
    const file = formData.get('file')
    const objectURL = URL.createObjectURL(file)
    setFile(objectURL)
    setFileType(file.type)
  }

  const afterCrop = async (blob) => {
    try {
      const file = await fetch(blob)
        .then((res) => res.blob())
        .then((blob) => {
          let filename = 'filename.jpeg'
          if (fileType === 'image/png') {
            filename = 'filename.png'
          }
          const newFile = new File([blob], filename, { type: fileType })
          return newFile
        })
      const formData = new FormData()
      formData.append('file', file)

      const res = await FileAPI.upload(formData)

      if (afterUpload) {
        afterUpload({ name: res.result.files.file[0].name })
      }

      if (onChange) {
        console.log('🌃', res.result.files.file[0].name)
        console.log(res.result.files.file[0].name)
        const e = {
          target: {
            value: res.result.files.file[0].name,
            name: 'image',
          },
        }
        onChange(e)
      }
    } catch (error) {
      throw error
    } finally {
      setFile(null)
    }
  }
  return (
    <Content value={value} center={center}>
      {isOpen && renderLightbox()}
      {label && (
        <Label id={id} htmlFor={name}>
          {label}
        </Label>
      )}
      <>
        <Wrapper width={wrapperWidth}>
          <UploadImageStyle styleUploadImage={styleUploadImage} className="upload-image">
            {showImage && (
              <ImageBox
                style={styleImageBox}
                grayBorder={grayBorder}
                aspectRatio={aspect}
                onClick={onClickView}
              >
                <Image cropShape={cropShape} src={getImageUrl(value)} alt="img-upload" />
              </ImageBox>
            )}
          </UploadImageStyle>

          <UploadIcon>
            <UploadComponent
              limitSize={5242880} // 5 MB
              afterUpload={(image) => {
                onChange(name, image.name)
              }}
              beforeCrop={beforeCrop}
              acceptFileTypes={['image/jpeg', 'image/png']}
            >
              <img alt="avatar" src={CameraIcon} width={iconWidth} height={iconWidth} />
            </UploadComponent>
          </UploadIcon>
        </Wrapper>
        <Modal
          isOpen={file}
          overlayStyle={customUploadModal.overlay}
          contentStyle={customUploadModal.content}
        >
          <Cropable
            isModal={true}
            fileType={fileType}
            image={file}
            afterCrop={afterCrop}
            cropShape={cropShape}
            aspect={aspect}
            cropLabel={cropLabel}
            cropLabelId={cropLabelId}
            handleClearFile={() => setFile(null)}
          >
            <div></div>
          </Cropable>
        </Modal>
      </>
      {error && touched && (
        <Typography margin="5px 0px" strong error>
          {error}
        </Typography>
      )}
    </Content>
  )
}

UploadAvatar.defaultProps = {
  label: '',
  detail: 'Click or Drag photo to this area to upload',
  onChange: () => null,
  cropShape: 'round',
  aspect: '1',
}

const mapDispatch = ({ Alert }) => ({ Alert })

export default connect(null, mapDispatch)(UploadAvatar)
