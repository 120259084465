import React, { useState, useRef, useLayoutEffect } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import ErrorBoundary from '../errorBoundary'
import Typography from '../typography'
import EvaIcon from 'react-eva-icons'
import { Custom } from '../../utils/displayResponsive'

const Div = styled.div`
  position: relative;
  /* margin: 0px 20px; */ //tabComponent
  // z-index: 5;
  /* overflow: ${(props) => (props.overflow ? 'auto' : 'initial')}; */

  @media screen and (max-width: 768px) {
    width: 100%;
    overflow: initial;
    margin: 0px;
  }
  @media screen and(max-width: 425px) {
    justify-content: space-evenly;
  }
`

const TabBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  background-color: ${(props) => (props.transparent ? '' : 'white')};
  z-index: 5;
  padding-left: 20px !important;
  gap: 20px;
  transition: all 0.3s;
  overflow-x: scroll;
  // box-shadow: ${(props) => !props.hideShadow && '0 5px 5px 0 rgba(0, 0, 0, 0.1)'};
  white-space: nowrap;
  @media (max-width: 430px) {
    justify-content: center;
  }
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
  }
  border-bottom: ${(props) => props.noneBorderBottom && 'none !important'};

  .active {
    /* border-bottom:  3px solid ${(props) => props.theme.primary1}; */
    border-bottom: ${({ headBorder, theme }) =>
      headBorder ? `3px solid ${theme.primary1}` : 'none'};
    color: ${(props) => props.theme.primary1};
    font-weight: bold;
  }
  .div-active {
    //border-bottom: 3px solid ${(props) => props.theme.primary2};
  }
  .label-active {
    color: ${(props) => props.theme.primary2};
    font-weight: bold;
  }

  @media screen and (max-width: 1024px) {
    .overflowY {
      overflow-y: scroll;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0px 20px;

    /* justify-content: space-between; */
  }

  ${(props) => props.inline && 'padding: 0px 5px'};
`

const TabLabel = styled(Typography)`
  text-align: center;
  // border-bottom: 3px solid transparent;
  max-width: min-content;
  line-height: 61px;
  // min-width: ${(props) => (props.minWidth ? props.minWidth : '100px')};
  min-width: ${({ headBorder }) => (headBorder ? '145px' : 'none')};
  color: ${(props) => props.theme.black60};
  height: 60px;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    flex: 1;
    text-align: center;
  }
`

const TabContent = styled.div``

const TabComponent = (props) => {
  // static propTypes = {}

  const [showButtonRight, setButtonRight] = useState(false)
  const [showButtonLeft, setButtonLeft] = useState(false)
  const contRef = useRef()

  useLayoutEffect(() => {
    const target = contRef ? contRef.current : document.body
    if (!target) {
      return true
    }

    const scrollWidth = target.scrollWidth
    const offsetWidth = target.offsetWidth
    const isScroll = offsetWidth < scrollWidth
    setButtonRight(isScroll)
    target.addEventListener('scroll', () => {
      if (target.scrollLeft === 0) {
        setButtonRight(true)
        setButtonLeft(false)
      }

      if (scrollWidth >= offsetWidth + Math.floor(target.scrollLeft)) {
        setButtonLeft(true)
        setButtonRight(false)
      }

      if (contRef.current.scrollLeft === 0) {
        setButtonLeft(false)
        setButtonRight(true)
      }
    })

    return target.removeEventListener('scroll', () => {})
  }, [])

  const ScrollButton = (props) => {
    const WrapperRight = styled.div`
      position: absolute;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      top: 0;
      right: 0;
      z-index: 6;
      height: 60px;
      width: 50px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
    `
    const WrapperLeft = styled.div`
      position: absolute;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      top: 0;
      left: 0;
      z-index: 6;
      height: 60px;
      width: 50px;
      transform: rotate(180deg);
      transform-origin: 50% 50%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
    `

    const Icon = styled.div`
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        display: flex;
      }
    `

    const { showButtonRight, showButtonLeft, page } = props

    const onClickRight = () => {
      contRef.current.scrollLeft += 1000
    }
    const onClickLeft = () => {
      contRef.current.scrollLeft -= 1000
    }
    return (
      <Custom maxWidth={767}>
        {showButtonRight && (
          <WrapperRight>
            <Icon onClick={onClickRight}>
              <EvaIcon name="arrow-ios-forward-outline" size="large" fill="#6F6F6F" />
            </Icon>
          </WrapperRight>
        )}

        {showButtonLeft && page === 'GroupMember' && (
          <WrapperLeft>
            <Icon onClick={onClickLeft}>
              <EvaIcon name="arrow-ios-forward-outline" size="large" fill="#6F6F6F" />
            </Icon>
          </WrapperLeft>
        )}
      </Custom>
    )
  }

  const genTab = (item, index, activeTab, minWidth, headBorder) => {
    const isActive = activeTab === index
    return (
      <TabLabel
        style={item.style}
        id={item.id}
        label2
        headBorder={headBorder}
        key={item.key}
        className={isActive ? 'active' : ''}
        divClassName={isActive ? 'div-active' : ''}
        labelClassName={isActive ? 'label-active' : ''}
        role="link"
        tabIndex={item.key}
        onClick={onClickFunc(index)}
        onKeyDown={() => {}}
        minWidth={minWidth}
        notiCount={item.notiCount}
      >
        {item.label}
      </TabLabel>
    )
  }

  const onClickFunc = (index) => () => {
    const { onChangeTab, tabData } = props
    onChangeTab(tabData[index].path)
  }

  const {
    tabData,
    location,
    match,
    style,
    hideShadow,
    // inline,
    minWidth,
    noneBorderBottom,
    overflow,
    headBorder,
    page = '',
  } = props

  let { activeTab } = props
  if (activeTab) {
    const tabIndex = tabData.findIndex((item) => item.path === activeTab)
    activeTab = tabIndex > 0 ? tabIndex : 0
  } else {
    // const tabIndex = tabData.findIndex((item) => location.pathname === `${match.url}${item.path}`)
    const tabIndex = tabData.findIndex((item) =>
      location.pathname.includes(`${match.url}${item.path}`)
    )
    activeTab = tabIndex > 0 ? tabIndex : 0
  }

  if (_.isEmpty(tabData)) {
    return <div />
  }
  return (
    <ErrorBoundary>
      <Div overflow={overflow}>
        <TabBar
          headBorder={headBorder}
          ref={contRef}
          hideShadow={hideShadow}
          // inline={inline}
          style={style}
          noneBorderBottom={noneBorderBottom}
          transparent
        >
          {tabData.map((item, index) => {
            return genTab(item, index, activeTab, minWidth, headBorder)
          })}
        </TabBar>
        <TabContent>{activeTab !== null && tabData[activeTab].component}</TabContent>

        <ScrollButton
          showButtonRight={showButtonRight}
          showButtonLeft={showButtonLeft}
          page={page}
        />
      </Div>
    </ErrorBoundary>
  )
}

TabComponent.defaultProps = {
  hideShadow: false,
  inline: false,
  tabData: [],
  minWidth: '',
}

TabComponent.propTypes = {
  tabData: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func,
  hideShadow: PropTypes.bool,
  inline: PropTypes.bool,
  minWidth: PropTypes.string,
  headBorder: PropTypes.bool,
}

export default withRouter(TabComponent)
