import axios from 'axios'

const mql = window.matchMedia(`(max-width: 1280px)`)
const isMobile = mql.matches

export const Sidebar = {
  state: {
    sidebarOpen: false,
    sidebarTeacherProfile: false,
    sidebarDocked: !isMobile,
  },
  reducers: {
    toggle(state, payload) {
      console.log('payload toggle', payload)
      return {
        ...state,
        sidebarOpen: payload,
      }
    },
    teacherSidebar(state, payload) {
      console.log('payload teacherSidebar', payload)
      return {
        ...state,
        sidebarTeacherProfile: payload,
      }
    },
    mediaQueryChanged(state) {
      return {
        ...state,
        sidebarDocked: !isMobile,
        sidebarOpen: false,
      }
    },
    hideSidebar(state) {
      return {
        ...state,
        sidebarDocked: false,
        sidebarOpen: false,
      }
    },
    showSidebar(state) {
      return {
        ...state,
        sidebarDocked: !isMobile,
        sidebarOpen: !isMobile,
      }
    },
    handleSidebar(state, payload) {
      return {
        ...state,
        ...payload,
      }
    },
  },
}
export const ModalState = {
  state: {
    showInstructor: false,
  },
  reducers: {
    onCloseShowInstructor(state) {
      return {
        ...state,
        showInstructor: false,
      }
    },
    onOpenShowInstructor(state) {
      return {
        ...state,
        showInstructor: true,
      }
    },
  },
}
export const Alert = {
  state: {
    isShow: false,
    loading: false,
    success: false,
    error: false,
    title: '',
    description: '',
  },
  reducers: {
    toggle(state, payload) {
      return {
        ...state,
        isShow: payload,
      }
    },
    success(state, payload) {
      return {
        ...state,
        title: payload.title,
        description: payload.description,
        loading: false,
        success: true,
        error: false,
        isShow: true,
      }
    },
    error(state, payload) {
      return {
        ...state,
        title: payload.title,
        description: payload.description,
        loading: false,
        success: payload.success || false,
        error: payload.error || true,
        isShow: true,
        showBtn: payload.showBtn,
        onOk: payload.onOk,
        btnText: payload.btnText,
        stayOpen: payload.stayOpen,
      }
    },
    loading(state, payload) {
      return {
        ...state,
        title: payload?.title || 'Loading...',
        description: payload?.description,
        loading: true,
        success: false,
        error: false,
        isShow: true,
      }
    },
  },
}

const initialConfirmState = {
  isShow: false,
  title: '',
  subtitle: '',
  onCancel: () => {},
  onConfirm: () => {},
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  confirmButton: true,
  cancelButton: true,
  image: '',
  title2: '',
  titleNoMargin: '',
  titleNoMarginId: '',
}
export const Confirm = {
  state: initialConfirmState,
  reducers: {
    open(state, payload) {
      return {
        ...initialConfirmState,
        ...payload,
        isShow: true,
      }
    },
    close(state) {
      return {
        ...state,
        isShow: false,
        onCancel: () => {},
        onConfirm: () => {},
      }
    },
    onClearConfirm() {
      return initialConfirmState
    },
  },
}

export const Liff = {
  state: {
    isLiff: false,
  },
  reducers: {
    setLiff(state, payload) {
      return { ...state, isLiff: payload }
    },
  },
}

export const ClosedServer = {
  state: {
    isClosed: false,
    start: '2021-03-14T18:00:00+07:00',
    end: '2021-03-16T06:00:00+07:00',
  },
  reducers: {
    setData(state, payload) {
      return { ...state, ...payload }
    },
  },
  effects: () => ({
    async getStatus() {
      try {
        const data = await axios({
          baseURL: process.env.REACT_APP_API_URL.slice(0, -4),
          url: '/server-status',
        }).then((res) => res.data)
        this.setData(data)
      } catch (e) {
        this.setData({
          isClosed: true,
        })
        throw e
      }
    },
  }),
}
