import Select from 'react-select'
import theme from 'theme'

const colourStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  placeholder: (styles, { selectProps: { isDisabled, feedList } }) => ({
    ...styles,
    fontSize: theme['text-sm'],
    color: isDisabled ? theme.grey : theme.black38,
    minWidth: '120px',
    top: feedList ? '41%' : '50%',
  }),
  dropdownIndicator: (styles, { selectProps: { isDisabled, feedList } }) => ({
    ...styles,
    padding: feedList ? 4 : 8,
    svg: {
      fill: isDisabled ? theme.grey : theme.black60,
    },
    '&:hover': {
      color: theme.primary1,
    },
  }),

  control: (styles, { selectProps: { width, heightButton, minHeight } }) => ({
    ...styles,
    cursor: 'pointer',
    backgroundColor: theme.white,
    borderRadius: '6px',
    // border: `1.3px solid ${error ? '#ff5151' : '#8C8B8B'}`,
    border: `1px solid ${theme.black20}`,
    width: width || '100%',
    minWidth: '60px',
    boxShadow: 'none',
    height: heightButton || '48px',
    minHeight: minHeight || '48px',
    '&:hover': {
      border: `1px solid ${theme.primary1}`,
    },
    '&:active': {
      border: `1px solid ${theme.primary1}`,
    },
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: theme['text-sm'],
      backgroundColor: isSelected ? theme.whiteblue : isFocused ? theme.whiteblue : null,
      color: isSelected ? theme.primary1 : isFocused ? theme.primary1 : null,
      fontWeight: isSelected ? '600' : isFocused ? '600' : '400',
      height: '48px',
      alignItems: 'center',
      display: 'flex',
      //backgroundColor: isFocused ? '#F3F3F3' : isSelected ? '#fff' : null,
      //color: isFocused ? '#5346F9' : null,
      //fontWeight: isFocused ? '900' : '300',
      svg: {
        fill: isSelected ? theme.primary1 : '#808080',
      },
    }
  },
  menuList: (styles, { selectProps: { height } }) => ({
    ...styles,
    height: height || 'auto',
    padding: '8px 0',
  }),
  // input: styles => ({ ...styles,  }),
  // placeholder: styles => ({ ...styles,  }),
  singleValue: (styles) => ({
    ...styles,
    color: theme.black87,
    fontSize: theme['text-sm'],
    // svg: {
    //   fill: theme.primary1,
    // },
  }),

  menu: (styles) => ({
    ...styles,
    zIndex: 100,
  }),
}

export { Select, colourStyles }
