import styled, { css } from 'styled-components'
import { Container, Label } from '../../../../components'

const Icon = styled.i`
  justify-self: end;
  z-index: 1;
  cursor: pointer;
  margin-left: ${({ marginLeft }) => marginLeft};
  display: flex;
  align-self: center;
`

const IconArrow = styled.img`
  transform: rotate(${({ rotate }) => rotate});
  cursor: pointer;
  width: 22px;
  height: 22px;
  align-self: center;
`

const AddFiles = styled.div`
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;

  // padding: 5px;
  // border: 1px solid ${(props) => props.theme.gray2};
  font-size: ${({ theme }) => theme['text-xxs']};
  transition: all 0.2s;
  outline: none;
  margin-bottom: 0px;
  margin-top: 8px;
  border-radius: 6px;
  .item {
    border: 1px solid rgba(194, 194, 194, 0.7);
  }

  .upload-button {
    margin-left: auto;
    /* margin-top: 10px; */
    display: inline;
  }

  .file-uploaded-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 140px);
    padding: 0px 12px;
    max-height: 40px;
    border: 1px solid ${(props) => props.theme.black20};
    border-radius: 6px;
  }

  ${(props) => {
    if (props.altstyle) {
      return css`
        padding: 12px 0;
        border: none;
        border-bottom: 1px solid rgba(194, 194, 194, 0.5);
      `
    }
    return ``
  }}
`

const File = styled.div`
  background-color: ${(props) => props.theme.whiteblue};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 3px 12px;
  min-width: 120px;
  gap: 8px;
`

const CloseIcon = styled.div`
  display: flex;
  /* width: 100%; */
  margin-left: auto;
  flex-direction: row-reverse;
  height: max-content;
  cursor: pointer;
  /* fill: ${(props) => props.theme.primary}; */

  img {
    width: 10px;
    height: 10px;
  }
`

const ContentBody = styled(Container)`
  position: fixed;
  top: ${({ isCourseSetting }) => (isCourseSetting ? '56px' : '0px')};
  height: ${({ isCourseSetting }) => (isCourseSetting ? 'calc(100vh - 0px)' : '100%')};
  //right: 0;
  z-index: 3;
  top: 54px;
  right: 140px;

  // box-shadow: -4px 6px 15px rgba(0, 0, 0, 0.1);
  transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  /* transform: translateX(0vw); */
  overflow-y: scroll;
  overflow-x: hidden;
  // margin: 0;
  width: 43%;
  min-width: 400px;
  background-color: transparent;
  grid-template-rows: repeat(5, min-content) 1fr;
  ::-webkit-scrollbar {
    width: 0px;
  }
  ${({ isDragging }) => {
    if (isDragging)
      return css`
        display: none;
      `
    return css``
  }}
  @media (max-width: 1024px) {
    position: fixed;
    top: 40px;
    height: calc(100vh - 50px);
  }
`
const Item = styled.div`
  display: flex;
`
const InputRadio = styled.input`
  margin-right: 10px;
`

const _Label = styled(Label)`
  display: block;
  margin-bottom: 15px;
  p {
    color: ${(props) => props.theme.primaryFont};
  }
  ${({ isDescription }) =>
    isDescription &&
    `
    margin-bottom: 0px;
    `}
`
const Wrapper = styled.div`
  height: 12px;
`

const RadioLabel = styled.div`
  display: flex;
  gap: 5px;
  @media (max-width: 1350px) {
    flex-direction: column;
  }
`

const SwitchRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 12px;
`
const Line = styled.div`
  margin: 20px -17px;
  height: 1px;
  background-color: ${(props) => props.theme.gray3};
  border: none;
`
const FilenameBorder = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  border: 1px solid ${({ theme }) => theme.black38};
  border-radius: 8px;
  width: 100%;
`
const UploadSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`
export {
  Icon,
  IconArrow,
  AddFiles,
  File,
  CloseIcon,
  ContentBody,
  Item,
  InputRadio,
  _Label as Label,
  Wrapper,
  RadioLabel,
  SwitchRow,
  Line,
  FilenameBorder,
  UploadSection,
}
