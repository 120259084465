import { SchoolAPI } from '../../api'

export const CourseStat = {
  state: {
    count: 0,
    page: 0,
    data: [],
    subject: null,
  },
  reducers: {
    setCount(state, payload) {
      return {
        ...state,
        count: payload,
      }
    },
    setPage(state, payload) {
      return {
        ...state,
        page: payload,
      }
    },
    setData(state, payload) {
      return {
        ...state,
        data: payload,
      }
    },
    setSubject(state, payload) {
      return {
        ...state,
        subject: payload,
      }
    },
  },
  effects: () => ({
    async getCourseStat({ schoolId }) {
      try {
        const result = await SchoolAPI.getCourseStat({
          id: schoolId,
        })

        this.setCount(result.count)
        this.setPage(result.page)
        this.setData(result.result)
        this.setSubject(result.subject)
        return
      } catch (e) {
        throw e
      }
    },
  }),
}
