import React from 'react'
import PropTypes from 'prop-types'
import { EmptyStyle, Desc, EmptyCard, Image } from './style'
import Typography from '../typography'
import EmptyImg from '../../images/Empty.svg'

function Empty({
  id,
  values = {},
  image,
  title,
  text,
  desc,
  titleIcon,
  hideImage,
  btn,
  widthTitle,
  noMargin,
  onImageLoaded,
  responsivePadding = false,
  customMarginDesc,
  responsivePaddingMan = false,
  reversepicture = false,
  desclong = false,
  className,
  ...props
}) {
  if (reversepicture) {
    return (
      <EmptyCard responsivePaddingMan={responsivePaddingMan} noMargin={noMargin}>
        <EmptyStyle
          responsivePaddingMan={responsivePaddingMan}
          responsivePadding={responsivePadding}
          {...props}
        >
          <br />
          {title && (
            <Typography
              values={values}
              id={id?.title}
              // h2
              // color="gray2"
              center
              // h4
              strong
              className="title"
              icon={titleIcon}
              flex={titleIcon}
              reverse={titleIcon}
              width={widthTitle}
            >
              {title}
            </Typography>
          )}
          {text && (
            <Typography
              values={values}
              id={id?.text}
              // h2
              // color="gray2"
              center
              // h4
              strong
              className="text"
              icon={titleIcon}
              flex={titleIcon}
              reverse={titleIcon}
              width={widthTitle}
            >
              {text}
            </Typography>
          )}
          {desc && (
            <Desc
              id={id?.desc}
              center
              h5
              thin
              color="#ADADAD"
              className="desc"
              desclong={desclong}
              customMarginDesc={customMarginDesc}
            >
              {desc}
            </Desc>
          )}
          {!hideImage && (
            <Image
              src={image || EmptyImg}
              alt="response-img"
              width={image ? '295px' : '150px'}
              className={className || 'full-image'}
              onLoad={onImageLoaded}
            />
          )}
          {btn && btn}
        </EmptyStyle>
      </EmptyCard>
    )
  } else {
    return (
      <EmptyCard noMargin={noMargin}>
        <EmptyStyle {...props}>
          {!hideImage && (
            <Image
              src={image || EmptyImg}
              alt="response-img"
              width={image ? '295px' : '150px'}
              className={className || 'full-image'}
              onLoad={onImageLoaded}
            />
          )}
          <br />
          {title && (
            <Typography
              values={values}
              id={id?.title}
              // h2
              color="primary1"
              center
              h3
              strong
              className="title"
              icon={titleIcon}
              flex={titleIcon}
              reverse={titleIcon}
              width={widthTitle}
              fontWeight={600}
            >
              {title}
            </Typography>
          )}
          {text && (
            <Typography
              values={values}
              id={id?.text}
              // h2
              color="black60"
              center
              // h4
              strong
              className="text"
              icon={titleIcon}
              flex={titleIcon}
              reverse={titleIcon}
              width={widthTitle}
              fontWeight={400}
            >
              {text}
            </Typography>
          )}
          {desc && (
            <Desc id={id?.desc} center h5 thin color="#ADADAD" className="desc">
              {desc}
            </Desc>
          )}
          {btn && btn}
        </EmptyStyle>
      </EmptyCard>
    )
  }
}
Empty.propTypes = {
  id: PropTypes.object,
  values: PropTypes.object,
  children: PropTypes.node,
  props: PropTypes.object,
}

Empty.defaultProps = {
  props: {},
  children: null,
}

export default Empty
