import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorBoundary from '../../components/errorBoundary'
import Breadcrumb from '../../components/breadcrumb/loadable'
import TabComponent from '../../components/tabComponent/loadable'
import { Heading } from '../../components/styledComponents'
import { menus } from './menus'

const TabStyle = {
  backgroundColor: 'transparent',
  boxShadow: '0 0 0 0',
  padding: '0',
  borderBottom: '1px solid rgba(194, 194, 194, 0.5)',
}

const Container = styled.div`
  padding: 30px;

  min-height: calc(100vh - 114px);
  padding-bottom: 110px;

  @media (max-width: 768px) {
    padding: 20px;
    padding-bottom: 80px;
    min-height: calc(100vh - 134px);
  }
`

class TeacherCourseLectureSettingLayout extends Component {
  static propTypes = {}

  componentDidMount = () => {
    const {
      getCourse,
      getCourseLectureByLectureId,
      match: {
        params: { lectureId, courseId },
      },
    } = this.props

    getCourse(courseId)
    getCourseLectureByLectureId({ courseId, lectureId })
  }

  onChangeTab = (path) => {
    const { match } = this.props
    this.props.history.push(match.url + path)
  }

  render() {
    const {
      children,
      course: { name: courseName = '', id: subjectId },
      lectureInfo: { name: lectureName = '' },
      lectureInfo,
    } = this.props
    console.log('lectureInfo', lectureInfo)

    const breadcrumb = [
      {
        label: courseName,
        link: `/teacher/my-course/courses/${subjectId}`,
        isMobile: true,
        isActive: false,
      },
      {
        label: 'Lecture',
        link: `/teacher/my-course/courses/${subjectId}/lecture`,
        isMobile: true,
        isActive: false,
      },
      {
        label: lectureName,
        link: `/teacher/my-course/courses/${subjectId}/lecture`,
        isMobile: true,
        isActive: false,
      },
      {
        label: 'Edit Lecture',
        link: '',
        isActive: true,
      },
    ]
    return (
      <ErrorBoundary>
        <Breadcrumb firstLabel="My Course" link="/teacher/my-course/courses/" data={breadcrumb} />
        <Container>
          <Heading>{lectureName}</Heading>
          <TabComponent
            style={TabStyle}
            transparent
            tabData={menus}
            activeTab={0}
            onChangeTab={this.onChangeTab}
          />
          {children}
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapState = (state) => ({
  appUser: state.AppUser.appUser,
  course: state.teachingCourse.course,
  lectureInfo: state.teachingCourse.lectureInfo,
})

const mapDispatch = (dispatch) => {
  const {
    AppUser: { login },
    teachingCourse: { getCourse, getCourseLectureByLectureId },
  } = dispatch
  return {
    login,
    getCourse,
    getCourseLectureByLectureId,
  }
}

export default connect(mapState, mapDispatch)(TeacherCourseLectureSettingLayout)
