import isEmpty from 'lodash/isEmpty'

import { UserAPI, SchoolAPI, CourseAPI, ScheduleMetaAPI, SchoolBankAPI } from '../../api'
import { Data } from '../../containers/SchoolMemberGroups/Data'
export const TeacherSchool = {
  state: {
    schoolList: [],
    subjectList: [],
    teacherList: [],
    schoolProfile: [],
    schoolInvite: [],
    schoolInviteCount: 0,
    schoolCount: 0,
    transaction: [],
    ownerDetail: {},
    schoolBank: [],
    incomeStat: {},
    memberGroups: Data,
    memberGroupslistInClass: [],
    listInClassId: [],
  },

  reducers: {
    ////  member groups teacher
    setMemberGroups(state, payload) {
      return {
        ...state,
        memberGroups: payload,
      }
    },

    setMemberGroupslistInClassId(state, payload) {
      return {
        ...state,
        listInClassId: payload,
      }
    },

    setMemberGroupslistInClass(state, payload) {
      console.log('11', state)
      return {
        ...state,
        memberGroupslistInClass: payload,
      }
    },

    ///////////////////////////////
    setSchoolBank(state, payload) {
      return {
        ...state,
        schoolBank: payload,
      }
    },
    setOwnerDetail(state, payload) {
      return {
        ...state,
        ownerDetail: payload,
      }
    },
    setTransaction(state, payload) {
      return {
        ...state,
        transaction: payload,
      }
    },
    setSchoolList(state, payload) {
      return {
        ...state,
        schoolList: payload,
      }
    },
    setSchoolCount(state, payload) {
      return {
        ...state,
        schoolCount: payload,
      }
    },
    setSubjectList(state, payload) {
      return {
        ...state,
        subjectList: payload,
      }
    },
    setTeacherList(state, payload) {
      return {
        ...state,
        teacherList: payload,
      }
    },
    setSchoolProfile(state, payload) {
      return {
        ...state,
        schoolProfile: payload,
      }
    },
    setSchoolInvite(state, payload) {
      return {
        ...state,
        schoolInvite: payload,
      }
    },
    setSchoolInviteCount(state, payload) {
      return {
        ...state,
        schoolInviteCount: payload,
      }
    },
    setIncomeStat(state, payload) {
      return {
        ...state,
        incomeStat: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async getSchoolBank(schoolId) {
      try {
        const result = await SchoolAPI.getBanks({ schoolId })
        this.setSchoolBank(result)
      } catch (error) {
        dispatch.Alert.error({ title: 'list school bank is fail' })
        throw error
      }
    },
    async createSchoolBank(payload) {
      try {
        await SchoolBankAPI.create(payload)
        dispatch.Alert.success({ title: 'Created' })
      } catch (error) {
        dispatch.Alert.error({ title: 'Create academy bank is fail' })
        throw error
      }
    },
    async updateSchoolBank(payload) {
      try {
        await SchoolBankAPI.update(payload)
        dispatch.Alert.success({ title: 'Updated' })
      } catch (error) {
        dispatch.Alert.error({ title: 'Update academy bank is fail' })
        throw error
      }
    },
    async getOwnerDetails(schoolId) {
      try {
        const result = await SchoolAPI.getOwnerDetails({ id: schoolId })
        this.setOwnerDetail(result)
        return result
      } catch (error) {
        this.setOwnerDetail({})
        throw error
      }
    },
    async createOwnerDetails(payload) {
      try {
        const id = payload.schoolId
        const result = await SchoolAPI.createOwnerDetails({ id, ...payload })
        dispatch.Alert.success({ title: 'Created' })
        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Create is fail' })
        throw error
      }
    },
    async updateOwnerDetails(payload) {
      try {
        const id = payload.schoolId
        // const detailId = payload.id
        const result = await SchoolAPI.updateOwnerDetails({ id, ...payload })
        dispatch.Alert.success({ title: 'Updated' })
        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Update is fail' })
        throw error
      }
    },
    async sendVerifyDocument(payload) {
      try {
        const id = payload.schoolId
        const result = await SchoolAPI.sendVerifyDocument({ id, ...payload })
        dispatch.Alert.success({ title: 'Send document successfully' })
        return result
      } catch (error) {
        dispatch.Alert.error({ title: 'Send document fail' })
        throw error
      }
    },
    async getTransactions({ schoolId, filter }) {
      try {
        const transactions = await SchoolAPI.getSchoolIncomes({ id: schoolId, filter })
        this.setTransaction(transactions)
      } catch (error) {
        throw error
      }
    },
    async getIncomeStat({ schoolId, month, year }) {
      try {
        const stat = await SchoolAPI.getSchoolIncomeStat({ id: schoolId, month, year })
        this.setIncomeStat(stat)
      } catch (error) {
        throw error
      }
    },
    async getSchoolList() {
      const result = await UserAPI.getSchools({
        filter: JSON.stringify({
          include: 'school',
        }),
      })
      this.setSchoolList(result)
      return result
    },
    async getSchoolListCount() {
      const result = await UserAPI.getSchoolsCount()
      this.setSchoolCount(result)
      return result
    },
    async getSubjectList(schoolId) {
      const filter = JSON.stringify({
        order: 'createdAt DESC',
      })
      console.log(schoolId)
      const mySubject = await UserAPI.getSubject({ filter })
      this.setSubjectList(mySubject)
      return mySubject
    },
    async getTeacherList(schoolId) {
      const result = await SchoolAPI.getTeachers({
        id: schoolId,
        filter: JSON.stringify({
          include: 'appUser',
          where: {
            isApprove: true,
          },
        }),
      })
      this.setTeacherList(result)
      return result
    },
    async getSchoolProfile(schoolId) {
      const filter = JSON.stringify({
        include: {
          relation: 'schoolMembers',
          scope: {
            where: {
              role: 'owner',
            },
          },
        },
      })
      const myProfile = await SchoolAPI.findById({ id: schoolId, filter })
      const owner = await UserAPI.findById({ id: myProfile.schoolMembers[0].appUserId })
      this.setSchoolProfile({ ...myProfile, ownerEmail: owner.email })
      return myProfile
    },
    async updateSchoolProfile(payload) {
      const { schoolId } = payload
      try {
        await SchoolAPI.updateSchoolProfile({ id: schoolId, ...payload })
        this.getSchoolProfile(schoolId)
        dispatch.Alert.success({ title: 'Academy Profile Updated Successfully' })
      } catch (e) {
        dispatch.Alert.error({
          title: 'Academy Profile Update Failed',
          description:
            e.response.details.context === 'School'
              ? 'This academy name is already taken.'
              : 'Something went wrong',
        })

        throw e
      }
    },
    async createCourse({ _teachers, schedule, ...payload }) {
      const result = await CourseAPI.create(payload)
      if (!isEmpty(schedule)) {
        await ScheduleMetaAPI.create({ ...schedule, courseId: result.id })
      }
      const promises = _teachers.map((item) => CourseAPI.assignTeacher({ id: result.id, fk: item }))
      await Promise.all(promises)
      return result
    },
    async assignTeacher(students) {
      try {
        const promise = students.map((student) => CourseAPI.assignTeacher(student))
        await Promise.all(promise)
      } catch (error) {
        throw error
      }
    },
    async getSchoolInvitation() {
      const result = await UserAPI.getSchools({
        filter: JSON.stringify({
          include: 'school',
          where: {
            isApprove: false,
          },
        }),
      })
      this.setSchoolInvite(result)
    },
    async updateSchoolInvitation() {
      const result = await UserAPI.getSchools({
        filter: JSON.stringify({
          include: 'school',
          where: {
            isApprove: false,
          },
        }),
      })
      this.setSchoolInvite(result)
    },
    async getSchoolInviteCount() {
      const result = await UserAPI.getSchoolsCount({
        where: JSON.stringify({
          isApprove: false,
        }),
      })
      this.setSchoolInviteCount(result.count)
    },
    async createMyschool(data) {
      const result = await UserAPI.createSchool(data)
      this.getSchoolList()
      return result
    },
  }),
  selectors: () => ({
    schoolOptions() {
      return (rootState) => {
        const { schoolList } = rootState.TeacherSchool
        return schoolList
          .filter((item) => item.role === 'owner' || item.role === 'admin')
          .map((item) => ({
            label: item.school.name,
            value: item.school.id,
          }))
      }
    },
  }),
}
