import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Typography, Icon } from 'components'
import { List, ListItem } from './style'

const StyledListItem = styled(ListItem)`
  display: flex;
  flex-direction: row;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  padding: 8px 30px 8px 30px;
  //border: 1px solid #253e87;
  border-radius: 10px;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  transition: all 0.1s ease;
  line-height: 1.5;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  width: 162px;
  height: 48px;
  gap: 8px;
  i {
    display: flex;
    margin: 0px 3px 0 0;
    align-items: center;
  }
  :hover {
    transform: scale(1.05);
    background-color: #ffffff;
  }
  :active {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.01);
    transform: scale(0.98) translateY(3px);
    background: #e0e6f6 0% 0% no-repeat padding-box;
  }

  ${({ disabled }) => {
    if (disabled)
      return css`
        filter: opacity(0.4);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.01);
        transform: none;
        background: #e8e8e8 0% 0% no-repeat padding-box;
        cursor: not-allowed;
        :hover {
          transform: scale(1);
        }
      `
  }}
`

function PureList({ onClick, data, icon }) {
  return (
    <List>
      {data.map(({ id, translate, title, name, fill, bgColor, disabled }) => (
        <StyledListItem
          key={id}
          value={id}
          onClick={disabled ? () => {} : () => onClick(id)}
          bgColor={bgColor}
          fill={fill}
          disabled={disabled}
        >
          <Icon src={name} style={{ position: 'relative', marginRight: 3 }} size={16} />
          <Typography id={translate} color="primary1" fontSize="text-xs">
            {title}
          </Typography>
        </StyledListItem>
      ))}
      {icon}
    </List>
  )
}

PureList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
  onClick: PropTypes.func.isRequired,
}

PureList.defaultProps = {
  data: [],
}

export default PureList
