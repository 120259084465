import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import BeatLoader from 'react-spinners/BeatLoader'

import theme from 'theme'
import Button from 'components/button'
import Modal from 'components/modal/loadable'
import checkIcon from 'images/newCheckCircle.svg'
import crossIcon from 'images/newXCircle.svg'

const Container = styled.div`
  position: relative;
  text-align: center;
  padding: 50px;
  > .title {
    font-size: ${({ theme }) => theme['text-2xl']};
    color: ${(props) => props.theme.primary1};
    font-weight: bold;
    margin-bottom: 24px;
    ${({ loading }) => {
      if (loading) {
        return css`
          color: white;
        `
      }
      return css``
    }}
  }
  > .description {
    font-size: ${({ theme }) => theme['text-sm']};
    color: #000000;
    margin-bottom: ${(props) => (props.showBtn ? '60px' : '0px')};
  }
  > button {
    /* width: 180px; */
  }
  .loading-style {
    margin: 0 auto;
  }
`
const Image = styled.img`
  margin-bottom: 47px;
  @media (max-width: 768px) {
    width: 190px;
  }
`

const icon = {
  success: checkIcon,
  error: crossIcon,
  loading: checkIcon,
}

function Alert({
  title,
  description,
  loading,
  success,
  error,
  btnText,
  showBtn,
  onOk,
  onRequestClose = () => {},
  ...props
}) {
  useEffect(() => {
    if (!loading) {
      let timer
      if (props.isOpen && !props.stayOpen) {
        timer = setTimeout(() => {
          onRequestClose()
        }, 5000)
      } else {
        clearTimeout(timer)
      }
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [props.isOpen])

  let type = 'success'
  if (success) {
    type = 'success'
  } else if (error) {
    type = 'error'
  } else if (loading) {
    type = 'loading'
  }
  const contentStyle = loading
    ? {
        backgroundColor: 'transparent',
        border: 'none',
        zIndex: 500,
      }
    : { backgroundColor: '#fff', zIndex: 500 }

  return (
    <Modal
      {...props}
      onRequestClose={loading ? () => {} : onRequestClose}
      shownXclose={loading ? false : true}
      onClickClose={loading ? () => {} : onRequestClose}
      overlayStyle={{ zIndex: 500 }}
      contentStyle={contentStyle}
    >
      <Container showBtn={showBtn} loading={loading}>
        {loading ? (
          <div style={{ marginBottom: 20 }}>
            <BeatLoader color={theme.primary} margin={5} size={25} />
          </div>
        ) : (
          <Image src={icon[type]} />
        )}
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        {/* <Button primary={success} danger={error} nowrap onClick={onOk} margin="auto"> */}
        {showBtn && (
          <Button secondary nowrap onClick={onOk} margin="auto">
            {btnText}
          </Button>
        )}
      </Container>
    </Modal>
  )
}

Alert.defaultProps = {
  title: 'Submitted successfully',
  description: '',
  success: false,
  error: false,
  btnText: 'OK',
  showBtn: false,
  onRequestClose: () => {},
}

Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  success: PropTypes.bool,
  error: PropTypes.bool,
  btnText: PropTypes.string,
  showBtn: PropTypes.bool,
  onRequestClose: PropTypes.func,
}

export default Alert
