import styled from 'styled-components'
import { Typography } from '../../components'

const Container = styled.div`
  // position: relative;
  // flex: 1;
  // min-width: 1px;
  width: 100%;
  // background: #fff;
  // box-shadow: 0 2px 8px 2px rgba(20, 23, 28, 0.15);
  // border-radius: 10px 10px 0 0;
  // height: calc(100vh - 100px);

  @media (max-width: 991px) {
    border-radius: 0px;
    box-shadow: none;
    z-index: 1;
    padding-bottom: 45px;
  }

  @media (max-width: 768px) {
    height: calc(100vh - 70px - 45.99px);

    /* height: auto; */
  }
  /* position: relative; */
  hr.objective {
    margin: 20px 0;

    height: 2px;
    background-color: #dedfe0;
    border: none;
  }
`

const Item = styled.div`
  margin-bottom: 18px;
`
const Itemradio = styled.div`
  margin: 18px 0px 18px 0px;
`

const TimeInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
  grid-gap: 17px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const CourseImgInput = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 50px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.black20};
  }

  textarea hover {
    border-color: ${({ theme }) => theme.dark};
    opacity: 100%;
  }
`

const CourseDivImgBtn = styled.div`
  // border: 1px solid;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-left: 10px;
`

const CourseImgBtnCou = styled.button`
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
  border-radius: 50%;
  border: 0px;
  z-index: 1;

  border: 1px solid ${({ theme }) => theme['black20']};
  margin: -1px 0px 0px -1px;
  //background-color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ courseNameColor }) => courseNameColor};
  cursor: pointer;
`
const CourseImgBtnIns = styled.button`
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
  border-radius: 50%;
  border: 0px;
  z-index: 1;

  border: 1px solid ${({ theme }) => theme['black20']};
  margin: -1px 0px 0px -1px;
  //background-color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ instructorNameColor }) => instructorNameColor};
  cursor: pointer;
`

// background-color: ${({ theme }) => theme.colors.secondary};
const CourseImgBtnPos = styled.button`
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
  border-radius: 50%;
  border: 0px;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme['black20']};

  margin: -1px 0px 0px -1px;
  // background-color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ positionColor }) => positionColor};
  cursor: pointer;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
  grid-gap: 17px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
export const Grid2 = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 15px;
`

const GridTree = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 17px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const SubHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  .wrapper {
    display: flex;
    position: relative;
    padding: 20px 45px;
    border-bottom: 1px solid #dedfe0;
    @media (max-width: 768px) {
      padding: 20px 15px;
    }
  }
  .main-header {
    flex: 1;
    min-width: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin: 0px;
      &h2 {
        /* margin-top: 10px; */
      }
    }

    // button {
    //   /* margin-top: 20px; */
    //   padding-top: 7px;
    //   padding-bottom: 7px;
    // }
  }
`

const Content = styled.div`
  padding: 40px 150px 80px 150px;
  @media (max-width: 1024px) {
    padding: 20px 45px;
  }
  @media (max-width: 768px) {
    padding: 20px 15px;
  }
`
// const styleImageBox = styled.div``
const DivAvatar = styled.div`
  position: absolute;
  top: 34.5vw;
  left: 40px;

  @media (max-width: 2560px) {
    top: 38.5vw;
    left: 10vw;
  }
  @media (max-width: 2460px) {
    top: 39.5vw;
    left: 7.5vw;
  }
  @media (max-width: 2300px) {
    top: 38.5vw;
    left: 7vw;
  }
  @media (max-width: 2130px) {
    top: 38vw;
    left: 6.5vw;
  }

  @media (max-width: 1970px) {
    top: 37.5vw;
    left: 6vw;
  }

  @media (max-width: 1920px) {
    top: 37.5vw;
    left: 95px;
  }

  @media (max-width: 1800px) {
    top: 35.5vw;
    left: 75px;
  }
  @media (max-width: 1680px) {
    top: 34.5vw;
    left: 55px;
  }

  @media (max-width: 1500px) {
    top: 33.5vw;
    left: 26px;
  }

  @media (max-width: 1500px) {
    top: 33.5vw;
    left: 26px;
  }
  @media (max-width: 1366px) {
    top: 32.5vw;
    left: 12px;
  }

  @media (max-width: 1280px) {
    top: 31.5vw;
    left: 8px;
  }
  @media (max-width: 1200px) {
    top: 30.5vw;
    left: 4px;
  }
  @media (max-width: 1152px) {
    top: 30vw;
    left: -5px;
  }
  @media (max-width: 1080px) {
    top: 28.5vw;
    left: -25px;
  }
`

const VideoDescription = styled(Typography)`
  font-size: ${({ theme }) => theme['text-xs']};
  color: ${(props) => props.theme.grey};
`

const Unit = styled.span`
  padding: 0px 12px;
  min-width: 53px;
  text-align: center;
`

const Description = styled(Typography)`
  font-size: ${({ theme }) => theme['text-xs']};
  color: #c2c2c2;
`

const Wrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const Form = styled.div`
  width: 100%;
`

const Badge = styled.div`
  display: flex;
  width: fit-content;
  padding: 4px 11px;
  border: none;
  margin-right: 8px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.whiteblue};
  font-weight: bold;
  height: 32px;
`
const Subtitle = styled(Typography)`
  font-size: ${({ theme }) => theme['text-xs']};
  /* line-height: 8px; */
  color: ${(props) => props.theme.gray2};
`

const UploadImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) {
    .image {
      flex: 7;
      width: 100%;
    }
    .image-ipad {
      flex: 5;
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  img {
    width: 100vw;
  }
  p {
    font-weight: 400;
  }
`

const RadioContainer = styled.div`
  display: inline-flex;
  padding-right: 30px;
  &:last-child {
    padding-right: 0;
  }
  .ant-checkbox .ant-checkbox-inner {
    border-color: #3d4453;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #253e87;
    border-color: #253e87;
  }
  .ant-radio-checked .ant-radio-inner {
    border: 1px solid #253e87;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #253e87;
    border: 1px solid #253e87;
  }
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const GridInstructor = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 15px;
`
export const Line = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.black38};
  margin-top: 50px;
  width: 100%;
`
export {
  EmptyContainer,
  Subtitle,
  Badge,
  Form,
  Wrapper,
  Container,
  Item,
  Itemradio,
  Content,
  SubHeader,
  Grid,
  VideoDescription,
  TimeInput,
  Description,
  Unit,
  UploadImageWrapper,
  RadioContainer,
  CourseImgInput,
  DivAvatar,
  CourseImgBtnCou,
  CourseImgBtnIns,
  CourseImgBtnPos,
  CourseDivImgBtn,
  GridTree,
  Header,
}
export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  background: white;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06);
  min-height: 56px;
  padding: 8px 50px;
  gap: 16px;
  align-items: center;
  z-index: 10;
  //position: sticky;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
`
