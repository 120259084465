import { Input } from 'antd'
import { Icon, Typography } from 'components'
import styled, { css } from 'styled-components'
import theme from 'theme'
const { TextArea } = Input

export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`
export const CommentButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`
export const Like = styled(Icon)`
  cursor: pointer;
  width: 22px;
  height: 22px;
`
export const CommentIcon = styled(Icon)`
  cursor: pointer;
  width: 22px;
  height: 22px;
`
export const ContainerComment = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  .comment-menu {
    opacity: 0;
    transition: 0.2s;
    :hover {
      opacity: 1;
    }
  }
`
export const ContainerCommentLevelOne = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0px;
  @media (max-width: 820px) {
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
  }
  @media (max-width: 425px) {
    margin-left: 0px;
  }
`

export const Footer = styled.div`
  width: 1200px;
  height: 10px;
  background-color: white;
  border-radius: 0px 0px 20px 20px;
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (max-width: 425px) {
    border-radius: 0px 0px 5px 5px;
  }
`

export const ReplyText = styled(Typography)`
  cursor: pointer;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`
export const CourseName = styled(Typography)`
  font-size: 16px;
  @media (max-width: 820px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 426px) {
    font-size: 16px;
  }
`
export const Desc = styled(Typography)`
  align-items: center;
  font-size: 12px;
  @media (max-width: 820px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 426px) {
    font-size: 12px;
  }
`
export const ReplyContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`
export const Container = styled.div`
  padding: 24px 0px 0px 0px;
`
export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
export const FeedCard = styled.div`
  /* border-radius: 8px; */
  padding: 5px 8px;
  background-color: ${theme.white};
  width: 1200px;

  @media (min-width: 1025px) {
    margin-right: ${({ courseId }) => (courseId ? '0px' : '0px')};
  }
  @media (max-width: 1024px) {
    margin-right: ${({ courseId }) => (courseId ? '0px' : '0px')};
    width: 100%;
    padding: 0px 10px;
  }

  @media (max-width: 920px) {
    margin-right: ${({ courseId }) => (courseId ? '0px' : '0px')};
    width: 100%;
    padding: 0px 10px;
  }

  @media (min-width: 768px) {
    margin-right: ${({ courseId }) => (courseId ? '0px' : '0px')};
    width: 100%;
    padding: 0px 10px;
  }

  @media (max-width: 426px) {
    margin-right: ${({ courseId }) => (courseId ? '0px' : '0px')};
    width: 100%;
    padding: 0px;
  }
  /* margin-top: 24px; */
  :first-child {
    margin-top: 0;
  }
`
export const FeedCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ courseId }) => (courseId ? '100%' : '90%')};
  @media (max-width: 426px) {
    width: ${({ courseId }) => (courseId ? '100%' : '100%')};
  }
  @media (min-width: 768px) {
    width: ${({ courseId }) => (courseId ? '100%' : '100%')};
  }
  @media (min-width: 1025px) {
    width: ${({ courseId }) => (courseId ? '100%' : '100%')};
  }
`
export const ImageSize = styled.img`
  max-height: 250px;
  @media (max-width: 426px) {
    max-width: 200px;
  }
`
export const PostImage = styled.img`
  width: 1200px;
  height: 960px;
  object-fit: cover;
  @media (max-width: 920px) {
    max-width: 100%;
    height: 600px;
  }
  @media (max-width: 820px) {
    max-width: 100%;
    height: 500px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    height: 500px;
  }
  @media (max-width: 540px) {
    max-width: 100%;
    height: 400px;
  }
  @media (max-width: 426px) {
    max-width: 100%;
    height: 300px;
  }
`

export const ImageFeedCourse = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;

  @media (max-width: 820px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 426px) {
    width: 40px;
    height: 40px;
  }
`
export const ImageContainer = styled.div`
  position: relative;
`
export const AvatarImg = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 0px;
  right: 2px;
  box-shadow: 0px 1px 4px 0px #00000026;
  border-radius: 50%;
  background-color: white;
  @media (max-width: 820px) {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 426px) {
    width: 18px;
    height: 18px;
  }
`
export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px 8px;
  width: 100%;
  justify-content: space-between;
`
export const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`
export const ContentFeed = styled.div`
  padding: 20px 0px;
`
export const CommentContainer = styled.div`
  padding: 0px 8px 0px;
  display: flex;
  flex-direction: column;
  @media (max-width: 426px) {
    padding: 10px 8px 0px;
  }
`
export const CommentInput = styled(TextArea)`
  border: none !important;

  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
  ${({ editMode }) => {
    if (editMode) {
      return css`
        margin-left: 0px !important;
      `
    }
    return css`
      margin-left: 8px !important;
    `
  }}
  padding: 6px 16px !important;
  font-size: 12px !important;
  min-height: 30px !important;
  background-color: ${theme.gray13} !important;
  width: auto;
  border-radius: 20px !important;
  ::placeholder {
    color: #c2c2c2;
  }
`
export const CommentReply = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0px;

  ${({ isCommentLevelOne }) => {
    if (isCommentLevelOne) {
      return css`
        width: 100%;
      `
    }
    return css`
      //width: 100vh;
    `
  }}
`
export const Reply = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
`
export const CommentReadOnly = styled.div`
  min-height: 30px;
  background-color: transparent;
  display: flex;
  padding: 0px;
  border-radius: 8px;

  align-items: center;
  /* ${({ txtlength }) => {
    if (txtlength) {
      return css`
        width: fit-content;
      `
    }
  }} */
`

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`
export const CommentList = styled.div`
  .ant-input:focus {
    /* border: none !important;
    border-color: none !important;
    box-shadow: none !important;
    outline: none !important; */
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }
`

export const Image = styled.div`
  /* width: 150px; */
  height: 150px;
  background-position: center;
  background-size: cover;
`

export const WrapImage = styled.div`
  margin-top: 10px;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  justify-content: center;
`

export const CommentFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin: 0px 0px 0px 40px;
`

export const UndoIcon = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
`

export const PenIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  top: -8px;
  right: 8px;
`
