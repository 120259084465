export const base64toFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.blob()
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType })
    })
}

export const loadCanvasImage = (imagePath) => {
  return new Promise((resolve, reject) => {
    let image = new window.Image()

    image.crossOrigin = 'anonymous'
    image.addEventListener('load', () => {
      //console.log(image)
      resolve(image)
    })

    image.addEventListener('error', (err) => {
      console.log(imagePath)
      reject(err)
    })
    image.src = imagePath
    // console.log(imagePath)
  })
}
