import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 20px;
  display: grid;
  height: calc(100vh - 50px);
  grid-template-columns: 1fr;
  gap: 15px;
  overflow: scroll;
  width: 100%;
  @media (max-width: 820px) {
    height: calc(100vh - 100px);
    padding: 25px;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 100px);
    padding: 25px;
  }
  @media (max-width: 412px) {
    height: calc(100vh - 75px);
  }
`

const Content = styled.div`
  ${({ bold }) => bold && `font-weight: bold`}
  ${({ size }) => {
    if (size === '1') {
      return `
        font-size: ${({ theme }) => theme['text-xl']};
      `
    }
    if (size === '2') {
      return `
        font-size: ${({ theme }) => theme['text-xs']};
      `
    }
    return ''
  }}
`

const Header = styled.h2`
  color: #253e87;
  font-weight: bold;
`

const Dialog = ({ type }) => {
  if (type === 'agreement') {
    return (
      <Container>
        <Header>เงื่อนไขการใช้งาน</Header>
        <Content>
          โปรดอ่านเงื่อนไขการใช้งานเหล่านี้โดยละเอียดก่อนจะเข้าถึงเว็บไซต์ หรือใช้บริการต่าง ๆ
          จากเราทั้งในส่วนของ เว็บไซต์ ซอฟต์แวร์ แอปพลิเคชัน และ/หรือ ปลั๊กอินต่าง ๆ ที่จัดไว้ให้โดย
          Classwin หรือบริษัทในเครือ (เรียกแยกกันและรวมกันว่า “บริการ”)
        </Content>
        <Content bold size="2">
          1. การยอมรับเงื่อนไข
        </Content>
        <Content>
          1.1 ท่านยอมรับและจะปฏิบัติตามข้อกำหนดและเงื่อนไขที่ระบุไว้ด้านล่างนี้
          รวมถึงเงื่อนไขที่มีการเปลี่ยนแปลงหรือเพิ่มเติมใด ๆ ซึ่ง Classwin Co., Ltd.
          (&quot;Classwin&quot;) ซึ่งอาจจะเผยแพร่เป็นครั้งคราว (เรียกรวมกันว่า
          &quoat;เงื่อนไขการใช้งาน&quoat;)
          หากท่านไม่ยอมรับเงื่อนไขและข้อกำหนดทั้งหมดที่มีอยู่ในเงื่อนไขการใช้งานนี้
          โปรดอย่าเข้าถึงหรือใช้บริการของเรา
        </Content>
        <Content>
          1.2 Classwin อาจเปลี่ยนแปลงเงื่อนไขการใช้งานเหล่านี้เป็นครั้งคราว
          การเข้าถึงหรือการใช้บริการไม่ว่าจะเป็นในส่วนของเว็บไซต์ หรือ
          แอปพลิเคชันของท่านอย่างต่อเนื่องจะถือว่าท่านยอมรับการเปลี่ยนแปลงดังกล่าว
          การเข้าถึงและการใช้บริการของท่านจะต้องเป็นไปตามเงื่อนไขการใช้งาน กฎ
          และแนวทางปฏิบัติที่บังคับใช้อยู่ในปัจจุบันซึ่งโพสต์บนเว็บไซต์ ณ เวลาที่ใช้งานนั้น
          โปรดตรวจสอบลิงก์ “เงื่อนไขการใช้งาน” เป็นประจำในหน้า แรกของ classwin.com และ
          app.classwin.com เพื่อดูเงื่อนไขในช่วงเวลาขณะนั้น หากท่านละเมิดเงื่อนไขการใช้งานใดก็ตาม
          สิทธิ์ใช้งานในการเข้าถึงเว็บไซต์หรือบริการนี้จะถูกยกเลิกโดยอัตโนมัติ
        </Content>
        <Content bold size="2">
          2.ทรัพย์สินทางปัญญา สิทธิ์ใช้งานแบบจำกัดแก่ผู้ใช้
        </Content>
        <Content>
          2.1 ท่านอาจเข้าถึงเว็บไซต์และบริการของเราได้
          โดยขึ้นอยู่กับการปฏิบัติตามเงื่อนไขการใช้งานเหล่านี้ของท่าน ข้อตกลงสิทธิ์ใช้งานที่ทำไว้กับ
          Classwin และกฎหมายที่บังคับใช้ Classwin ยังคงเป็นเจ้าของสิทธิ์ กรรมสิทธิ์
          และผลประโยชน์ทั้งหมดแต่เพียงผู้เดียว
          และขอสงวนสิทธิ์ทั้งหมดที่ไม่ได้มอบให้อย่างชัดแจ้งภายใต้เงื่อนไขการใช้งานเหล่านี้ Classwin
          อาจแก้ไข แทนที่ ยกเลิกเว็บไซต์ แอปพลิเคชัน หรือ บริการ หรือส่วนหนึ่งส่วนใดได้ตลอดเวลา
          ไม่ว่าด้วยเหตุผลใด โดยแจ้งหรือไม่แจ้งให้ทราบ ตามดุลยพินิจของ Classwin แต่เพียงผู้เดียว
          Classwin จัดหาบริการไว้ให้ “ตามสภาพที่เป็นอยู่” และ “ตามความพร้อมใช้งาน”
        </Content>
        <Content>
          2.2 เนื้อหาทั้งหมดในเว็บไซต์นี้ ซึ่งรวมถึงแต่ไม่จำกัดเพียง ข้อความ ภาพ วิดีโอ เพลง และ
          Meta Data ที่เกี่ยวข้อง (รวมเรียกว่า “ข้อมูล”) รวมทั้งการเลือกและการจัดการข้อมูลของ
          Classwin จะได้รับการคุ้มครองจากกฎหมายและสนธิสัญญาว่าด้วยลิขสิทธิ์ เครื่องหมายการค้า
          สิทธิบัตร ความลับทางการค้า และทรัพย์สินทางปัญญาอื่น ๆ การใช้งานเนื้อหาของ Classwin
          ใดก็ตามที่ไม่ได้รับอนุญาตจะเป็นการละเมิดกฎหมายดังกล่าวและเงื่อนไขการใช้บริการ Classwin
          ไม่อนุญาตทั้งโดยชัดแจ้งหรือโดยนัยต่อการใช้ข้อมูลของ Classwin ใด ๆ
          เว้นแต่จะระบุไว้โดยชัดแจ้งในที่นี้ หรือในข้อตกลงด้านสิทธิ์ใช้งานแยกต่างหากระหว่างท่านและ
          Classwin ท่านตกลงว่าจะไม่คัดลอก เผยแพร่ซ้ำ สร้างกรอบ ทำการเชื่อมโยง ดาวน์โหลด ส่ง ดัดแปลง
          ปรับเปลี่ยน สร้างงานต่อยอด ให้เช่า ให้เช่าซื้อ ให้ยืม ขาย โอนสิทธิ์ แจกจ่าย แสดงผล จัดทำ
          ให้สิทธิ์ใช้งาน ให้สิทธิ์ใช้งานต่อ กับเนื้อหาและบริการของ Classwin ส่วนใดก็ตาม นอกจากนี้
          ท่านยังตกลงว่าจะไม่ใช้งานการหาความสัมพันธ์ของข้อมูลปริมาณมหาศาล (Data Mining),
          โปรแกรมโรบอต หรือวิธีการรวบรวมและคัดแยกข้อมูลใด ๆ ของข้อมูลใน Classwin
        </Content>
        <Content bold size="2">
          3. เครื่องหมายการค้าของ Classwin
        </Content>
        <Content>
          3.1 ภายใต้วัตถุประสงค์ของเงื่อนไขการใช้บริการนี้ คำว่า &quoat;เครื่องหมายการค้า&quoat;
          จะหมายถึงกฎหมายจารีตประเพณีทั้งหมด หรือเครื่องหมายการค้าจดทะเบียน โลโก้ เครื่องหมายบริการ
          ชื่อทางการค้า ชื่ออินเทอร์เน็ตโดเมน
          หรือการบอกในลักษณะอื่นใดถึงแหล่งที่มาสินค้าในขณะนี้หรือในอนาคตที่ใช้โดย Classwin
        </Content>
        <Content>
          3.2
          ไม่มีส่วนใดที่ระบุไว้ในที่นี้จะมอบหรือจะถือว่ามอบสิทธิ์ให้ท่านในการใช้เครื่องหมายการค้าของ
          Classwin เว้นแต่จะกล่าวไว้โดยชัดแจ้งตามเงื่อนไขการใช้งานนี้
        </Content>
        <Content>
          3.3 ท่านตกลงว่าจะไม่ใช้เครื่องหมายการค้าของ Classwin ในลักษณะที่อาจสร้างความเสื่อมเสีย
          ดูหมิ่น หรือส่งผลในทางลบกับเครื่องหมายการค้าดังกล่าวหรือกับ Classwin
        </Content>
        <Content>
          3.4 ท่านตกลงว่าจะไม่โต้แย้งหรือคัดค้าน (เช่น ในการดำเนินการคดีทางกฎหมาย และอื่น ๆ)
          หรือให้ความช่วยเหลือ
          หรือสนับสนุนบุคคลหรือนิติบุคคลอื่นใดก็ตามในการโต้แย้งหรือคัดค้านความถูกต้องสมบูรณ์ของเครื่องหมายการค้าของ
          Classwin หรือสิทธิ์ในเครื่องหมายการค้าที่อ้างสิทธิ์โดย Classwin
        </Content>
        <Content>
          3.5 ท่านตกลงว่าจะไม่ใช้เครื่องหมายการค้าของ Classwin
          หรือเครื่องหมายนั้นในลักษณะอื่นใดก็ตาม รวมถึงคำสะกดไม่ถูกต้อง
          เป็นชื่อโดเมนหรือส่วนหนึ่งของชื่อโดเมน ไม่ว่าจะเป็นโดเมนระดับบนสุด หรือเป็น Meta Tag
          คำสำคัญ หรือรหัสหรือข้อมูลการเขียนโปรแกรมอื่น ๆ
        </Content>
        <Content>
          3.6 ไม่ว่าเมื่อใดก็ตาม
          ท่านต้องไม่เลือกใช้หรือใช้คำหรือเครื่องหมายที่คล้ายคลึงกับหรืออาจเกิดความสับสนกับเครื่องหมายการค้าของ
          Classwin โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจาก Classwin
        </Content>
        <Content>
          3.7 รูปลักษณ์และวิธีการใช้บริการของ Classwin รวมถึงส่วนหัวของหน้า ภาพกราฟิกแบบกำหนดเอง
          ไอคอนปุ่ม และสคริปต์ทั้งหมด
          คือรูปลักษณ์โดยรวมของผลิตภัณฑ์และ/หรือเครื่องหมายการค้าหรือเครื่องหมายบริการของ Classwin
          และห้ามคัดลอก ลอกเลียนแบบ หรือนำไปใช้ไม่ว่าจะทั้งหมดหรือบางส่วน
          โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจาก Classwin
        </Content>
        <Content>
          3.8 เครื่องหมายการค้า ชื่อผลิตภัณฑ์
          และชื่อบริษัทหรือโลโก้อื่นทั้งหมดที่ใช้หรือปรากฏบนเว็บไซต์ Classwin
          เป็นทรัพย์สินของเจ้าของนั้น ๆ การอ้างอิงถึงผลิตภัณฑ์ บริการ กระบวนการ
          หรือข้อมูลอื่นใดก็แล้วแต่ ตามชื่อทางการค้า เครื่องหมายการค้า ผู้ผลิต ซัพพลายเออร์ หรืออื่น
          ๆ ไม่ถือเป็นหรือสื่อถึงการรับรอง การให้การสนับสนุน หรือการแนะนำถึงสิ่งนั้น ๆ โดย Classwin
          เว้นแต่จะระบุไว้โดยชัดแจ้ง
        </Content>
        <Content>
          3.9 ท่านต้องไม่ใช้เครื่องหมายการค้า Classwin โลโก้ ภาพ หรือภาพกราฟิกที่มีกรรมสิทธิ์อื่น ๆ
          ของ Classwin เพื่อลิงค์เชื่อมโยงถึงเว็บไซต์ v โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจาก
          Classwin ก่อน
        </Content>
        <Content>
          3.10 ท่านต้องไม่สร้างกรอบสิ่งที่เหมือนกับเว็บไซต์หรือโยงฮ็อตลิงค์ถึงเว็บไซต์ Classwin
          หรือภาพใดก็ตามโดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจาก Classwin ก่อน
        </Content>
        <Content bold size="2">
          4. ข้อมูลที่ท่านให้มา
        </Content>
        <Content>
          4.1 Classwin (หรือบุคคลที่สามที่ดำเนินการในนามของเรา)
          อาจเก็บรวบรวมข้อมูลที่เกี่ยวข้องกับการใช้งานเว็บไซต์นี้ของท่าน
          แพลตฟอร์มของบุคคลที่สามซึ่งท่านใช้ในการเข้าถึงเว็บไซต์อาจเก็บรวบรวมข้อมูลเกี่ยวกับการใช้งานแพลตฟอร์มของบุคคลที่สามดังกล่าวของท่าน
          และทำให้ข้อมูลดังกล่าวพร้อมใช้งานแก่ Classwin
          ตามข้อตกลงระหว่างท่านกับแพลตฟอร์มของบุคคลที่สามที่ใช้บังคับ
          การเก็บรวบรวมและการใช้ข้อมูลดังกล่าวทั้งหมดของ Classwin
          จะเป็นไปตามเงื่อนไขการใช้งานเหล่านี้ นโยบายความเป็นส่วนตัว และกฎหมายที่บังคับใช้ตลอดเวลา
        </Content>
        <Content>
          4.2 Classwin จะปกป้องข้อมูลส่วนตัวของท่าน เช่น ชื่อและที่อยู
          ให้เป็นไปตามคำแถลงความเป็นส่วนตัวของ Classwin
          ซึ่งประกอบด้วยเนื้อหาอ้างอิงตามเงื่อนไขการใช้งานเหล่านี้
        </Content>
        <Content bold size="2">
          5. เนื้อหาของท่าน
        </Content>
        <Content>
          5.1 สำหรับ ข้อความ ภาพ วิดีโอ เสียง หรือเนื้อหาอื่นใดที่ท่านอัพโหลดหรือโพสต์ไปยังเว็บไซต์
          หรือ แอปพลิเคชัน (“เนื้อหาของท่าน”) ท่านให้การรับรองและรับประกันว่า:
          <br />
          (i) ท่านมีสิทธิ์ที่จำเป็นทั้งหมดในการส่งเนื้อหาของท่านไปยังเว็บไซต์
          หรือแอปพลิเคชันและให้สิทธิ์ใช้งานตามที่ระบุไว้ในที่นี้
          <br />
          (ii) Classwin
          ไม่จำเป็นต้องได้รับสิทธิ์ใช้งานจากบุคคลที่สามหรือจ่ายค่าลิขสิทธิ์ให้แก่บุคคลที่สามซึ่งเกี่ยวข้องกับเนื้อหาของท่าน
          <br />
          (iii) เนื้อหาของท่านไม่มีการละเมิดสิทธิ์ใด ๆ ของบุคคลที่สาม
          ซึ่งรวมถึงสิทธิ์ในทรัพย์สินทางปัญญาและสิทธิ์ความเป็นส่วนตัว และ
          <br />
          (iv) เนื้อหาของท่านสอดคล้องกับเงื่อนไขการใช้งานเหล่านี้และกฎหมายที่บังคับใช้ทุกประการ
        </Content>
        <Content>
          5.2 การอัพโหลดเนื้อหาของท่านเป็นการแสดงว่าท่านให้สิทธิ์ใช้งานแบบจำกัด ทั่วโลก
          ซึ่งไม่จำกัดเฉพาะแต่เพียงผู้เดียว และปลอดค่าลิขสิทธิ์แก่ Classwin รวมถึงสิทธิ์ในการคัดลอก
          ส่ง เผยแพร่ ใช้งานและแสดงแบบสาธารณะ
          (ผ่านสื่อทุกรูปแบบที่เป็นที่รู้จักในขณะนี้และที่จะสร้างขึ้นหลังจากนี้)
          และสร้างงานต่อยอดจากเนื้อหาของท่านเพื่อวัตถุประสงค์ให้ท่านสามารถแก้ไขและแสดงเนื้อหาของท่านโดยใช้เว็บไซต์
          ตลอดจนจัดเก็บข้อมูลหรือเก็บรักษาเนื้อหาของท่านเพื่อการพิพาท ดำเนินการตามกฎหมาย
          หรือเพื่อสืบสวนข้อเท็จจริง สิทธิ์ใช้งานข้างต้นจะยังคงอยู่
          เว้นแต่ว่าและจนกว่าท่านจะลบเนื้อหาของท่านออกจากเว็บไซต์
          ซึ่งในกรณีนี้สิทธิ์ในการใช้งานจะสิ้นสุดลงภายในระยะเวลาที่สมเหตุสมผลในเชิงพาณิชย์
          อย่างไรก็ตาม แม้จะมีข้อกำหนดดังที่กล่าวไปข้างต้น
          แต่สิทธิ์ใช้งานสำหรับวัตถุประสงค์ในการจัดเก็บ/เก็บรักษาข้อมูลตามกฎหมายจะยังคงมีผลต่อไปโดยไม่มีกำหนด
        </Content>
        <Content>
          5.3 ท่านไม่สามารถอัพโหลด โพสต์ หรือส่งวิดีโอ ภาพ ข้อความ เสียงบันทึก หรือเนื้อหาอื่นใดที่:
          <li>
            ละเมิดลิขสิทธิ์หรือสิทธิ์ในทรัพย์สินทางปัญญาอื่น ๆ ของบุคคลที่สาม
            หรือสิทธิ์ในความเป็นสาธารณะหรือความเป็นส่วนตัว
          </li>
          <li>
            มีเนื้อหาลามกอนาจาร เนื้อหาที่เป็นการหมิ่นประมาท
            หรือเนื้อหาที่ไม่ชอบด้วยกฎหมายหรือผิดศีลธรรม
          </li>
          <li>หาประโยชน์อันไม่สมควรจากผู้เยาว์</li>
          <li>แสดงถึงการกระทำที่ผิดกฎหมายหรือรุนแรง</li>
          <li>แสดงถึงการกระทำที่โหดร้ายต่อสัตว์หรือการใช้ความรุนแรงต่อสัตว์</li>
          <li>
            ส่งเสริมโครงการฉ้อฉลหรือก่อให้เกิดการกล่าวอ้างถึงโฆษณาที่หลอกลวง
            หรือการแข่งขันที่ไม่ยุติธรรม หรือ
          </li>
          <li>ละเมิดกฎหมาย ข้อบังคับ หรือระเบียบ</li>
        </Content>
        <Content>
          5.4 ท่านต้องไม่ใช้บริการของ Classwin เพื่อวัตถุประสงค์ใด ๆ โดยไม่ได้รับสิทธิ์ใช้บริการของ
          Classwin ดังกล่าวก่อน การใช้บริการของ Classwin โดยท่านจะอยู่ภายใต้การควบคุมของข้อตกลงด้าน
          สิทธิ์ใช้งานซึ่งกระทำต่างหากระหว่างท่านและ Classwin การแสดงและ/หรือแจกจ่ายข้อมูลของ
          Classwin ที่ไม่ได้รับสิทธิ์ใช้งานออกสู่สาธารณะ
          (ไม่ว่าจะโดยการนำไปประกอบร่วมกับงานต่อยอดหรือใช้โดยลำพัง) จะถือว่าเป็นการละเมิดลิขสิทธิ์
        </Content>
        <Content bold size="2">
          6. โรงเรียนออนไลน์ โรงเรียนออนไลน์ หมายถึง
          สมาชิกที่เป็นผู้ให้บริการคอร์สเรียนที่ผ่านการตรวจสอบข้อมูลเบื้องต้นโดย Classwin เท่านั้น
        </Content>
        <Content>
          โรงเรียนออนไลน์มีหน้าที่และความรับผิดชอบอย่างเต็มที่ต่อการดำเนินกิจกรรมใด ๆ
          ที่ไม่เป็นการละเมิดต่อนโยบายการใช้งานนี้
          ซึ่งหากละเมิดต่อนโนบายการใช้งานและเกิดความเสียหายขึ้นทางใดทางหนึ่ง
          <li>
            แก่ผู้ใดหรือกลุ่มใดกลุ่มหนึ่ง บริษัท คลาสวิน จำกัด
            มีหน้าที่ให้ความร่วมมือกับเจ้าหน้าที่รัฐอย่างเต็มที่
          </li>
          <li>
            ผู้ดูแลโรงเรียนออนไลน์จะต้องเป็นผู้รับผิดชอบและถูกดำเนินคดีทางกฏหมายแต่เพียงผู้เดียว
            มิสามารถฟ้องร้อง อ้างถึง บริษัท คลาสวิน จำกัด ได้ทุกกรณี
          </li>
        </Content>
        <Content bold size="2">
          7. ข้อจำกัด
        </Content>
        <Content>
          7.1 ท่านตกลงว่าจะไม่:
          <li>
            เข้าร่วมการกระทำใดก็ตามที่จะถือเป็นการละเมิดกฎหมายหรือละเมิดสิทธิ์ของ Classwin
            หรือบุคคลอื่น
          </li>
          <li>
            ละเมิดกฎหมายหรือระเบียบข้อบังคับที่บังคับใช้เกี่ยวกับการเข้าถึงหรือการใช้งานเว็บไซต์
            หรือเข้าร่วมกิจกรรมใดก็ตามที่ห้ามไว้โดยเงื่อนไขการใช้งานเหล่านี้
          </li>
          <li>
            ละเมิดสิทธิ์ของ Classwin ของบุคคลอื่น ๆ
            (รวมถึงสิทธิ์ของความเป็นส่วนตัวและความเป็นสาธารณะ) หรือใช้ในทางที่ผิด หมิ่นประมาท
            ล่วงละเมิด เฝ้าติดตาม หรือคุกคามผู้อื่น
          </li>
        </Content>
        <Content bold size="2">
          8. ข้อจำกัดและการบอกเลิกการใช้งาน
        </Content>
        <Content>
          8.1 Classwin อาจบล็อค จำกัด ปิดการใช้งาน ระงับ หรือยกเลิกการเข้าถึงเว็บไซต์ เนื้อหา
          และบริการทั้งหมดหรือบางส่วนของ Classwin ได้ตลอดเวลาตามดุลยพินิจของ Classwin
          โดยไม่ต้องแจ้งให้ทราบล่วงหน้าหรือมีความรับผิดต่อท่าน การกระทำใด ๆ
          ของท่านซึ่งอยู่ในดุลยพินิจฝ่ายเดียว ของ Classwin
          ที่ไปจำกัดหรือขัดขวางบุคคลอื่นใดไม่ให้เข้าใช้เว็บไซต์ถือเป็นข้อห้ามอย่างเด็ดขาด
          และอาจส่งผลให้มีการยกเลิกการเข้าถึงบริการของเราโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
        </Content>
        <Content bold size="2">
          9. ลิงค์ไปยังเว็บไซต์อื่น
        </Content>
        <Content>
          9.1 ในกรณีที่บริการนี้ใช้งานได้ผ่านทางแพลตฟอร์มของบุคคลที่สาม หรือถ้า Classwin
          เชื่อมโยงลิงก์จากบริการของตนไปยังแพลตฟอร์มของบุคคลที่สามใด ๆ
          หรืออนุญาตให้บุคคลที่สามเชื่อมโยงลิงก์จากแพลตฟอร์มของตนมายังบริการของ Classwin
          ท่านเข้าใจและตกลงว่า Classwin ไม่ได้ให้การรับประกันใดก็ตามทั้งโดยชัดแจ้งหรือโดยนัย
          และจะไม่รับผิดชอบต่อเนื้อหา บริการ
          หรือข้อปฏิบัติใดก็ตามของบุคคลอื่นหรือแพลตฟอร์มอื่นดังกล่าว
          แพลตฟอร์มดังกล่าวนั้นไม่ได้อยู่ภายใต้การควบคุมของ Classwin และ Classwin
          จัดหาลิงก์และ/หรืออนุญาตให้มีลิงก์เหล่านี้เพื่ออำนวยความสะดวกให้ท่านเท่านั้น
          การมีลิงก์ใดก็ตามไม่ได้แสดงเป็นนัยถึงการเป็นพันธมิตร การสนับสนุน
          หรือการยอมรับข้อบังคับและกฏระเบียบต่าง ๆ ของบุคคลหรือบริการอื่น
        </Content>
        <Content bold size="2">
          10. การรับประกันและข้อสงวนสิทธิ์
        </Content>
        <Content>
          10.1 การใช้บริการจะเกิดขึ้นภายใต้ความเสี่ยงของท่านเอง Classwin จัดหาบริการให้
          ภายใต้เงื่อนไขการใช้งานเหล่านี้ “ตามสภาพที่เป็นอยู่” โดยปราศจากการรับประกันใด ๆ
          ทั้งโดยชัดแจ้ง โดยนัย ตามกฎหมาย หรืออื่น ๆ Classwin ปฏิเสธโดยชัดแจ้งต่อการรับประกันใดๆ
          หรือทั้งหมดในทุกประเภท ไม่ว่าจะโดยชัดแจ้งหรือโดยนัยต่อบริการใด ๆ
          ที่ใช้งานได้จากเว็บไซต์แต่ละบริการ รวมถึงแต่ไม่จำกัดเพียงการรับประกันโดยนัยเกี่ยวกับ
          ความสามารถในการจำหน่าย ความเหมาะสมกับวัตถุประสงค์เฉพาะอย่าง การไม่ละเมิดสิทธิ์
          และการรับประกันอื่นใดที่อาจเกิดขึ้นภายใต้กฎหมาย ทั้งนี้
          โดยไม่จำกัดเพียงสิ่งที่กล่าวมาข้างต้น Classwin ไม่ให้การรับประกันว่า:
          <br />
          (i) บริการจะตรงตามความต้องการของท่าน
          <br />
          (ii) การเข้าถึงบริการจะไม่มีสิ่งใดติดขัด
          <br />
          (iii) คุณภาพของบริการจะตรงตามความคาดหวังของท่าน
          <br />
          (iv) ข้อผิดพลาดหรือความบกพร่องใด ๆ ก็ตามในเว็บไซต์ บริการ หรือวัสดุต่าง ๆ
          จะได้รับการแก้ไขให้ถูกต้อง Classwin ไม่ให้การรับรองหรือรับประกันว่า บริการ
          จะได้รับการอนุญาตภายใต้เขตอำนาจศาลของท่าน
          ตลอดจนไม่ให้การรับประกันว่าเนื้อหาของท่านที่ส่งโดยท่านจะมีให้ผ่านบริการ
          หรือจะถูกจัดเก็บโดยเว็บไซต์ ไม่ให้การรับประกันว่าบริการจะตรงตามความต้องการของท่าน หรือ
          Classwin จะยังคงให้การสนับสนุนแก่ท่านสมบัติอย่างใดอย่างหนึ่งของบริการ
          ในกรณีที่การปฏิเสธความรับผิดหรือการจำกัดความรับผิดใด ๆ ไม่สามารถบังคับใช้ได้
        </Content>
        <Content bold size="2">
          11. การชดใช้ค่าเสียหาย
        </Content>
        <Content>
          11.1 ท่านตกลงว่าจะป้องกัน ชดใช้ให้ และให้ค่าสินไหมทดแทนแก่ Classwin รวมถึงบริษัทสาขา
          บริษัทในเครือ ผู้อนุญาตให้ใช้สิทธิ์ ลูกจ้าง ตัวแทน ผู้ให้บริการข้อมูลภายนอก ผู้ส่งข้อมูล
          และผู้รับเหมาอิสระ สำหรับข้อเรียกร้อง ค่าเสียหาย ค่าใช้จ่าย ความรับผิด และรายจ่ายต่าง ๆ
          (รวมถึงแต่ไม่จำกัดเพียงค่าทนายความตามสมควร) ที่เกิดจากหรือเกี่ยวข้องกับการกระทำของท่าน
          การใช้งานของท่าน หรือความไม่สามารถใช้บริการ
          การละเมิดหรือการละเมิดที่กล่าวอ้างว่ามีต่อเงื่อนไขการใช้งานเว็บไซต์หรือการรับรองหรือการรับประกันใดก็ตามในที่นี้
          การที่ท่านใช้งานเนื้อหาของ Classwin โดยไม่ได้รับอนุญาต
          หรือการที่ท่านละเมิดสิทธิ์ใดก็ตามของผู้อื่น
        </Content>
        <Header>นโยบายการคืนเงิน</Header>
        <Content>
          บริษัท คลาสวิน จำกัด (&quoat;บริษัทฯ&quoat;)
          ขอแจ้งนโยบายการคืนเงินเพื่อเป็นข้อตกลงและสร้างความเข้าใจเกี่ยวกับการใช้บริการของเรา ดังนี้
          บริษัทฯ จะทำการคืนเงินค่าบริการให้กับท่าน ในกรณีที่บริษัทฯ
          ไม่สามารถจัดให้บริการตามที่ท่านต้องการได้ ดังนี้
          <li>
            กรณีที่เกิดขึ้นจากโรงเรียนออนไลน์ปฏิเสธการเข้าเรียน (เช่น ห้องเรียนเกินจำนวนที่กำหนด,
            ผู้เรียนมีคุณสมบัติไม่ตรงตามที่กำหนด, คอร์สเรียนถูกยกเลิกด้วยเหตุสุดวิสัยต่าง ๆ)
          </li>
          <li>
            กรณีที่เกิดขึ้นจากราคาสินค้าที่ระบุในรายละเอียดของคอร์สเรียนไม่ตรงกับราคาที่ชำระจริง
          </li>
          <li>
            กรณีที่เกิดขึ้นจากระบบขัดข้อง (System Error)
            อันอาจจะเกิดขึ้นและมีหลักฐานปรากฏเหตุของความผิดพลาดชัดเจนซึ่งอยู่ในดุลยพินิจของ บริษัท
            คลาสวิน จำกัด
          </li>
        </Content>
        <Content>
          <u>ระยะเวลาการคืนเงินค่าบริการ</u>
        </Content>
        <Content>
          กรณีที่ท่านชำระเงินเต็มจำนวนตัดผ่านบัตรเครดิตของธนาคารพาณิชย์ที่จดทะเบียนในประเทศไทย
          บริษัทจะส่งเรื่องขอคืนเงินไปยังผู้ให้บริการ Payment Gateway
          โดยการคืนเงินจะขึ้นอยู่กับนโยบายผู้ให้บริการ Payment gateway
          ซึ่งตามปกติแล้วผู้ถือบัตรจะได้รับเงินคืนในรอบบิลถัดไป
        </Content>
        <Content>
          สงวนสิทธิ์การไม่คืนค่าบริการหรือส่วนลดต่าง ๆ ในกรณีที่
          <li>กรณีที่เกิดขึ้นจากความผิดพลาดในลงทะเบียนเรียนผิด</li>
          <li>กรณีที่เกิดขึ้นจากการเปลี่ยนใจ (Change of mind) ของผู้เรียนทุกกรณี</li>
          <li>
            กรณีที่เกิดขึ้นจาก Gift Voucher ต่าง ๆ ทุกกรณี เช่น ลืมใส่ส่วนลด, หมดอายุ,
            เกินจำนวนที่กำหนด
          </li>
        </Content>
        <Header>นโยบายการจัดส่งสินค้าและบริการ</Header>
        <Content>
          <li>
            จัดส่งเมื่อทำการลงทะเบียน ชำระเงินเต็มจำนวนสำเร็จ
            และได้รับการยืนยันจากโรงเรียนออนไลน์แล้ว (ถ้ามี) แล้วเท่านั้น
          </li>
          <li>
            การรับบริการจะอยู่ในรูปแบบดิจิตอลซึ่งสามาถให้บริการได้บนเว็บไซต์ www.classwin.com,
            app.classwin.com, แอปพลิเคชัน Classwin, หรือที่อื่น ๆ
            ซึ่งอาจจะแจ้งเพิ่มเติมภายหลังเท่านั้น
          </li>
          <li>
            เนื่องจากการให้บริการคอร์สเรียนของแต่ละรายการอาจจะแตกต่างกัน
            ท่านสามารถตรวจสอบนโยบายการจัดส่งสินค้าก่อนทำการลงทะเบียนเรียนได้
            โดยอ่านรายละเอียดของการลงทะเบียนเรียนในหน้ารายละเอียดคอร์สเรียนแต่ละรายการ
          </li>
          <li>ท่านไม่สามารถเปลี่ยนแปลงรายชื่อผู้เรียนไม่ว่ากรณีใด ๆ ทั้งสิ้น</li>
          <li>บริษัทฯ ขอสงวนสิทธิในการเปลี่ยนแปลงเงื่อนไขใด ๆ โดยไม่จำต้องแจ้งให้ทราบล่วงหน้า</li>
        </Content>
        <br />
        <br />
      </Container>
    )
  }
  if (type === 'policy') {
    return (
      <Container>
        <Header>นโยบายคุ้มครองข้อมูลส่วนบุคคล</Header>
        <Content bold size="2">
          นโนบายด้านความเป็นส่วนตัว (Private Policy)
        </Content>
        <Content>
          Classwin ขอแนะนำให้ท่านทำความเข้าใจนโนบายเกี่ยวกับความเป็นส่วนตัวนี้
          เนื่องจากนโยบายนี้ได้อธิบายถึงวิธีการที่ Classwin ปฏิบัติต่อข้อมูลส่วนบุคคลของท่าน เช่น
          การเก็บรวบรวม การจัดเก็บรักษา การนำไปใช้ การเปิดเผย การวิเคราะห์ รวมถึงสิทธิต่าง ๆ ของท่าน
          เป็นต้น เพื่อให้ท่านได้รับทราบถึงนโยบายในการคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้ Classwin
          ทุกคน Classwin จึงประกาศนโนบายด้านความเป็นส่วนตัวดังต่อไปนี้ Classwin
          จะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เท่าที่จำเป็นสำหรับการดำเนินการต่าง ๆ
          เพี่อเป็นไปตามวัตถุประสงค์ที่ระบุไว้ในนโยบายด้านความเป็นส่วนตัวฉบับนี้
          โดยให้คำชี้แจงเกี่ยวกับการใช้ข้อมูลส่วนบุคคลของท่าน
          ซึ่งเราจะเก็บรวบรวมไว้ในช่วงเวลาที่ท่านเข้าใช้บริการของเราทั้งในรูปแบบของเว็บไซต์
          แอพพลิเคชั่น หรือบริการอื่น ๆ
          โดยท่านในฐานะเจ้าของข้อมูลจะเป็นเจ้าของข้อมูลส่วนตัวเพียงแต่ผู้เดียวเท่านั้น Classwin
          ไม่มีส่วนรับผิดชอบในข้อมูลส่วนบุคคลของท่านไม่ว่าในกรณีใด ๆ ทั้งสิ้น
        </Content>
        <Content bold size="2">
          คำนิยาม
        </Content>
        <Content>
          <div>“บริษัท” หมายถึง บริษัท คลาสวิน จำกัด รวมถึงบริษัทย่อยที่อยู่ในอํานาจควบคุม</div>
          <div>
            “บุคลากรของบริษัท” หมายถึง กรรมการ พนักงาน ลูกจ้าง ของบริษัท คลาสวิน จำกัด และบริษัทย่อย
            และ/หรือ บริษัทที่อยู่ในอํานาจควบคุม
          </div>
          <div>
            “ลูกค้า” หมายถึง บุคคลทั่วไป องค์กร บริษัท ห้างร้าน/นิติบุคคล รัฐวิสาหกิจ หน่วยงานภาครัฐ
            ที่ซื้อสินค้า และ/หรือใช้บริการของบริษัท
          </div>
          <div>
            “คู่ค้า” หมายถึง บริษัท ห้างร้าน/นิติบุคคล และบุคคล ที่ซื้อ-ขายสินค้า
            หรือบริการให้กับบริษัท
          </div>
          <div>
            “ผู้มาติดต่องาน หมายถึง บุคคลภายนอกที่เข้ามาติดต่องาน มาเยี่ยมชม หรือมาตรวจสอบใด ๆ
            กับบริษัทที่นอกเหนือจากการซื้อและขายสินค้าบริการ
          </div>
          <div>
            “ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
            ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
          </div>
          <div>
            “ข้อมูลส่วนบุคคลที่อ่อนไหว” หมายถึง
            ข้อมูลที่ต้องระมัดระวังเป็นพิเศษในการเก็บรวบรวมหรือประมวลผล เช่น ข้อมูลส่วนบุคคล
            เกี่ยวกับเชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา
            พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม
            และข้อมูลชีวภาพ เป็นต้น
          </div>
          <div>
            “ผู้ใช้บริการ” หมายถึง
            บุคคลธรรมดาหรือนิติบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลที่เข้ามาติดต่อกับบริษัท
            หรือที่เข้ามายังระบบสารสนเทศของบริษัท
          </div>
          <div>
            “ผู้ควบคุมข้อมูล” หมายถึง
            บริษัทและบริษัทในเครือของบริษัทในฐานะผู้มีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้
            หรือ
            เปิดเผยข้อมูลส่วนบุคคลรวมถึงบุคคลหรือนิติบุคคลอื่นใดที่บริษัทมอบหมายให้มีอำนาจหน้าที่นั้น
          </div>
          <div>
            “การประมวลผล” หมายถึง การดำเนินการใด ๆ เกี่ยวกับการใช้ การเปิดเผย
            หรือการแก้ไขซึ่งข้อมูลส่วนบุคคลของผู้ใช้บริการ ไม่ว่าจะใช้วิธีการใด ๆ
            และให้หมายความรวมถึงการส่งหรือโอนข้อมูล ส่วนบุคคลที่ใช้ในการประกอบกิจการ
          </div>
          <div>
            “ระบบข้อมูลสารสนเทศ” หมายถึง ระบบคอมพิวเตอร์ ระบบเครือข่ายติดต่อสื่อสาร
            ระบบเครือข่ายเชื่อมต่อเข้าระบบอินเตอร์เน็ต ระบบเก็บข้อมูล ระบบจดหมายอิเล็กทรอนิกส์
            (E-mail) ระบบสื่อสารข้อมูลทุกประเภท ข้อมูลอุปกรณ์สื่อสาร อุปกรณ์คอมพิวเตอร์
            และอุปกรณ์ต่อพ่วงหรืออุปกรณ์ใด ๆ
            ที่เกี่ยวข้องเป็นกรรมสิทธิ์ของบริษัทและ/หรือบริษัทในเครือของบริษัท
          </div>
        </Content>
        <Content bold size="2">
          ประเภทของข้อมูลที่จัดเก็บ
        </Content>
        <Content>
          บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านโดยจำแนกประเภทได้ดังต่อไปนี้ <br />
          1. ข้อมูลที่บ่งชี้ตัวตน ได้แก่ ชื่อ นามสกุล ที่อยู่ สถานที่ติดต่อ หมายเลขโทรศัพท์ อีเมล
          วันเดือนปีเกิด เพศ หมายเลขบัตรเครดิต เป็นต้น <br />
          2. ข้อมูลระบบได้แก่ ข้อมูล ที่บริษัทจัดเก็บโดยอัตโนมัติเมื่อท่านล๊อคอินเข้าสู่เว็บไซต์
          ของบริษัทไม่ว่าจะผ่านทางคุกกี้ เว็บบีคอน ไฟล์ล็อคอิน สคริปท์ รวมถึงข้อมูล ทางเทคนิค เช่น
          ที่อยู่ไอพี (IP Address) ประเภทของบราวเซอร์ การตั้งค่าเขตเวลา และที่ตั้งโดเมน
          ระบบปฏิบัติการ เป็นต้น <br />
          3. ข้อมูลการใช้งานได้แก่ หน้าเว็บไซต์ที่เยี่ยมชม เวลา และวันที่เข้าเว็บไซต์
          ที่อยู่เว็บไซต์ อ้างอิง สินค้า และบริการหรือข้อมูลที่คนหาในแพลตฟอร์ม
          ข้อมูลเกี่ยวกับสิ่งที่ท่านค้นหาหรือที่ท่านดูในขณะที่ใช้เว็บไซต์ เป็นต้น <br />
          4. ข้อมูลการติดต่อสื่อสาร ได้แก่ อีเมล บันทึกข้อมูล การสนทนา ข้อมูลต่าง ๆ
          ที่ท่านให้กับบริษัท
        </Content>
        <Content bold size="2">
          แหล่งที่มาของข้อมูลส่วนบุคคล
        </Content>
        <Content>
          1. บริษัทได้ข้อมูลส่วนบุคคลที่มาจากท่านโดยตรง ผ่านช่องทางดังต่อไปนี้
          <br />
          การลงทะเบียนผ่านระบบเว็บไซต์หรือแอปพลิเคชั่นของ CLASSWIN
          <br />
          การติดต่อกลับบริษัทเพื่อขอรับข้อมูลเกี่ยวกับคอร์สท่านสนใจ <br />
          การนัดหมายบริษัทหรือการให้ข้อมูลกับบริษัทเมื่อเข้ามาใช้คอร์ส <br />
          การทำแบบสอบถามหรือการโต้ตอบทางอีเมลระหว่างท่านกับบริษัทการเข้าร่วมกิจกรรมหรือการเคลื่อนไหวผ่านช่องทางอิเล็กทรอนิกส์
          หรือช่องทาง Social Network หรือช่องทาง ปกติ <br />
          2. ข้อมูลส่วนบุคคลของท่านที่บริษัทได้รับมาจากบุคคลภายนอก (Third Party)
          ที่ดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูล
          ส่วนบุคคลตามคำสั่งหรือในนามของบริษัท
          ซึ่งข้อมูลส่วนบุคคลของท่านจะไม่ถูกนำไปใช้นอกเหนือจากวัตถุประสงค์ที่บริษัทได้กำหนดหรือสั่งไว้
          และบริษัทได้กำหนดให้บุคคลภายนอกต้องรักษาความลับและคุ้มครองข้อมูล
          ส่วนบุคคลของท่านตามมาตรฐานของกฎหมายคุ้มครองข้อมูลส่วนบุคคล <br />
          3. ข้อมูลส่วนบุคคลที่ได้มาจากคุกกี้ (cookies) เมื่อท่านเข้าชมเว็บไซต์ของบริษัท
          ข้อมูลเหล่านี้ช่วยให้บริษัทสามารถจัดให้มีการบริการที่ดีขึ่น เร็วขึ้น ปลอดภัยขึ้น
          และเพื่อความเป็นส่วนตัวของท่าน เมื่อท่านใช้บริการ และ/หรือเข้าสู่แพลตฟอร์ม
        </Content>
        <Content bold size="2">
          วัตถุประสงค์ในการเก็บรวบรวม ใช้ข้อมูล
        </Content>
        <Content>
          ข้อมูลส่วนบุคคลที่บริษัทได้รวบรวมมาจะถูกใช้ภายในขอบเขตวัตถุประสงค์และเท่าที่จำเป็นแก่การดำเนินการอันเป็นประโยชน์แก่บริษัท
          เพื่อให้บริษัทสามารถจัดให้มีการบริการที่ดีขึ้น รวดเร็วขึ้น สะดวกขึ้น
          และมีประสิทธิภาพมากขึ้น ควบคู่ไปกับความปลอดภัย และความเป็นส่วนตัวของท่านเมื่อได้ใช้บริการ
          และ/หรือเข้าสู่แฟลตฟอร์ม ตามแต่ประเภทของข้อมูลซึ่งอยู่บนจุดประสงค์ 4 ข้อหลักนี้
          <br />
          1. เพื่อให้เราสามารถปรับปรุง หรือ พัฒนา
          บริการของเราให้มีประสิทธิภาพและเกิดประโยชน์ต่อสูงสุดต่อผู้ที่เกี่ยวข้องกับระบบทุกคน
          <br />
          2. เพื่อใช้เป็นส่วนหนึ่งในการระบุ ยืนยันตัวตนหรือเพิ่มความน่าเชื่อถือของผู้ใช้บริการ{' '}
          <br />
          3. เพื่อใช้ในการปกป้องรักษาข้อมูลและสิทธิประโยชน์ต่าง ๆ ของผู้ใช้บริการ <br />
          4. เพื่อใช้ในการวิเคราะห์และพัฒนาบริการซึ่งอาจจะเกี่ยวโยงถึง การตลาด
          การโฆษษณาประชาสัมพันธ์ โดยที่จะไม่ก่อให้เกิดผลเสียหายต่อชีวิตและทรัพย์สินของผู้ใช้บริการ
        </Content>
        <Content bold size="2">
          การประมวลผลข้อมูลส่วนบุคคล
        </Content>
        <Content>
          เมื่อได้รับข้อมูลส่วนบุคคลจากท่านแล้วบริษัทจะดำเนินการดังนี้
          <br />
          1. เก็บรวบรวมโดยมีการบันทึกในระบบคอมพิวเตอร์ที่ใช้บริการ ได้แก่ การตั้งเซิฟเวอร์ด้วยตนเอง
          <br />
          2. บริษัทจะใช้ข้อมูล
          ส่วนบุคคลของท่านที่ได้เก็บรวบรวมมาในการดำเนินการของบริษัทตามวัตถุประสงค์ <br />
          3. บริษัทจะเปิดเผยข้อมูลกับ
          พนักงานภายใต้สัญญาจ้างของบริษัทที่มีขอบเขตหน้าที่เกี่ยวกับข้อมูล เปิดเผยบนแพลตฟอร์ม
          ของบริษัทหรือช่องทางสังคมออนไลน์เพื่อการโฆษณา นอกจากนี้
          บริษัทอาจส่งข้อมูลส่วนบุคคลของท่านไปยังหน่วยงาน ข้อมูล
          เครดิตเพื่อตรวจสอบและอาจใช้ผลการตรวจสอบข้อมูลดังกล่าวเพื่อป้องกันการฉ้อโกง
        </Content>
        <Content bold size="2">
          การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
        </Content>
        <Content>
          บริษัทเก็บข้อมูลส่วนบุคคลของท่านในระบบงานสารสนเทศของบริษัท
          และจัดให้มีการเก็บรักษาความปลอดภัยที่มีมาตรฐานเพื่อให้
          ระบบงานสารสนเทศของบริษัทและการใช้ข้อมูล ส่วนบุคคลของท่านมีความปลอดภัยโดยมีมาตรการ ดังนี้{' '}
          <br />
          • จำกัดการเข้าถึงข้อมูลส่วนบุคคลที่อาจเข้าถึงได้โดย พนักงาน ตัวแทน คู่ค้า หรือบุคคลภายนอก
          การเข้าถึงข้อมูลส่วนบุคคลของบุคคลภายนอกจะสามารถทำได้ตามเท่าที่กำหนดไว้หรือตามคำสั่ง
          ซึ่งบุคคลภายนอกจะต้องมีหน้าที่ในการรักษาความลับ และคุ้มครองข้อมูล ส่วนบุคคล <br />•
          จัดให้มีวิธีการทางเทคโนโลยีเพื่อป้องกันไม่ให้มีการเข้าสู่ระบบคอมพิวเตอร์ที่ไม่ได้รับอนุญาต{' '}
          <br />
          •
          จัดการทำลายข้อมูลส่วนบุคคลของท่านเพื่อความปลอดภัยเมื่อข้อมูลดังกล่าวไม่จำเป็นสำหรับวัตถุประสงค์ทางกฎหมาย
          และธุรกิจอีกต่อไป <br />
          • มีกระบวนการจัดการกับกรณีละเมิดข้อมูลส่วนบุคคล หรือกรณีที่ต้องสงสัย
          และต้องแจงเหตุการละเมิดข้อมูลส่วนบุคคล <br />•
          แก่สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลตามเงื่อนไขที่กฎหมายกำหนดระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล{' '}
          <br />
          •
          บริษัทเก็บข้อมูลส่วนบุคคลของท่านไว้ในระยะเวลาที่จำเป็นเพื่อดำเนินการตามวัตถุประสงค์ในการให้บริการของบริษัท
          และเป็นไปตามระยะเวลาที่กำหนดโดยมาตรฐานทางบัญชี มาตรฐานทางกฎหมาย
          และกฎระเบียบอื่นที่เกี่ยวข้อง <br />
          • ในการกำหนดระยะเวลาในการเก็บรักษาข้อมูล บริษัทจะพิจารณาถึงจำนวน ลักษณะการใช้งาน
          วัตถุประสงค์ในการให้บริการ ความอ่อนไหวของข้อมูลส่วนบุคคล
          และความเสี่ยงที่อาจเกิดขึ้นจากการใช้ข้อมูลส่วนบุคคลโดยมิชอบ และ ระยะเวลาที่กำ
          หนดโดยกฎหมายที่เกี่ยวข้อง <br />• ข้อมูลส่วนบุคคลของท่านที่ได้มาจากคุกกี้ (Cookies)
          ในการเก็บข้อมูลเมื่อท่านเข้าใช้เว็บไซต์บริษัทจะเก็บไว้ไม่เกิน 13 เดือน
          หรือตามที่กฎหมายที่เกี่ยวข้องกำหนด
        </Content>
        <Content bold size="2">
          สิทธิของเจ้าของข้อมูล
        </Content>
        <Content>
          ท่านมีสิทธิในการดำเนินการ ดังต่อไปนี้ <br />
          1. สิทธิในการเพิกถอนความยินยอม (right to withdraw consent):
          ท่านมีสิทธิในการเพิกถอนความยินยอมในการ
          ประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทได้
          ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับ บริษัท <br />
          2. สิทธิในการเข้าถึงข้อมูลส่วนบุคคล (right of access):
          ท่านมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของท่านและขอให้บริษัท
          ทำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้แก่ท่าน
          รวมถึงขอให้บริษัทเปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ท่านไม่ได้ให้ความยินยอมต่อบริษัทได้{' '}
          <br />
          3. สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (right to rectification):
          ท่านมีสิทธิในการขอให้บริษัทแก้ไขข้อมูลที่ไม่ถูกต้อง หรือ เพิ่มเติมข้อมูลที่ไม่สมบูรณ์{' '}
          <br />
          4. สิทธิในการลบข้อมูลส่วนบุคคล (right to erasure):
          ท่านมีสิทธิในการขอให้บริษัททำการลบข้อมูลของท่านด้วยเหตุบาง ประการได้ <br />
          5. สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล (right to restriction of processing):
          ท่านมีสิทธิในการระงับการใช้ข้อมูลส่วน บุคคลของท่านด้วยเหตุบางประการได้ <br />
          6. สิทธิในการใหโอนย้ายข้อมูล ส่วนบุคคล (right to data portability):
          ท่านมีสิทธิในการโอนย้ายข้อมูลส่วนบุคคลของท่านที่ให้ไว้กับบริษัทไปยังผู้ควบคุมข้อมูลรายอื่น
          หรือ ตัวท่านเองด้วยเหตุบางประการได้ <br />
          7. สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (right to object):
          ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้
        </Content>
        <Content bold size="2">
          การใช้สิทธิเจ้าของข้อมูลและการลบข้อมูล
        </Content>
        <Content>
          ในกรณีที่ท่านต้องการให้เราจัดการข้อมูลส่วนตัวของท่านเพื่อการอย่างใดอย่างหนึ่ง
          ท่านสามารถติดต่อมายังเจ้าหน้าที่ของบริษัทได้
          เพื่อดำเนินการยื่นคำร้องขอดำเนินการตามสิทธิข้างต้นได้ตลอดเวลาผ่านช่องทางดังนี้ <br />
          บริษัท คลาสวิน จำกัด 418/2 ถนนประชาทร แขวงลาดกระบัง เขตลาดกระบัง กรุงเทพมหานคร <br />
          Line :{' '}
          <a href="https://lin.ee/kQCr7RN" target="_blank" rel="noopener noreferrer">
            https://lin.ee/kQCr7RN
          </a>{' '}
          <br />
          Facebook :{' '}
          <a href="https://www.facebook.com/classwin" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/classwin
          </a>{' '}
          <br />
          เบอร์โทรศัพท์ : 098-746-5919
        </Content>
        <br />
        <br />
      </Container>
    )
  }
  return null
}

export default Dialog
