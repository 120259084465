import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ErrorBoundary from '../../components/errorBoundary'

import { Heading, Container, Typography, Icon, Section, Button } from './style'
import ArrowIcon from '../../images/ic-arrow-black.svg'

class SchoolCourseLiveStudio extends React.Component {
  state = {
    live: false,
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    try {
      const {
        getBroadcastById,
        getCourseLivesById,
        getStreamById,
        match: {
          params: { courseId, liveId },
        },
      } = this.props
      const payload = {
        id: courseId,
        fk: liveId,
      }
      const {
        broadcastData: { id: broadcastId },
        streamData: { id: streamId },
      } = await getCourseLivesById(payload)
      const {
        status: { lifeCycleStatus },
      } = await getBroadcastById(broadcastId)
      await getStreamById(streamId)
      if (lifeCycleStatus === 'live') this.setState({ live: true })
    } catch (error) {
      throw new Error(error)
    }
  }

  handleStartLive = async () => {
    const {
      createCourseTransition,
      match: {
        params: { courseId, liveId },
      },
    } = this.props
    const payload = {
      id: courseId,
      fk: liveId,
      status: 'live',
    }
    try {
      await createCourseTransition(payload)
      this.setState({ live: true })
    } catch (error) {
      throw new Error(error)
    }
  }

  handleEndLive = async () => {
    const {
      createCourseTransition,
      history,
      match: {
        params: { schoolId, courseId, liveId },
      },
    } = this.props
    const payload = {
      id: courseId,
      fk: liveId,
      status: 'complete',
    }
    try {
      await createCourseTransition(payload)
      // Redirect to list live
      history.replace(`/schools/${schoolId}/curriculum/manage/${courseId}/live`)
    } catch (error) {
      throw new Error(error)
    }
  }

  handleBack = () => {
    const {
      history,
      match: {
        params: { schoolId, courseId },
      },
    } = this.props
    history.push(`/schools/${schoolId}/curriculum/manage/${courseId}/live`)
  }

  render() {
    const { children } = this.props
    const { live } = this.state
    return (
      <ErrorBoundary>
        <Heading>
          <Section onClick={this.handleBack} style={{ cursor: 'pointer' }}>
            <Icon src={ArrowIcon} alt="arrow" width="19px" style={{ marginRight: '13px' }} />
            <Typography h5 color="#585858">
              Back
            </Typography>
          </Section>
          <Section>
            {live ? (
              <Button rose onClick={this.handleEndLive}>
                End Live
              </Button>
            ) : (
              <Button ocean bold onClick={this.handleStartLive}>
                Start Live
              </Button>
            )}
          </Section>
        </Heading>
        <Container column="1" padding="21px 35px">
          {children}
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapState = ({ Course: { broadcast } }) => ({ broadcast })
const mapDispatch = ({
  Course: { getStreamById, createCourseTransition, getBroadcastById, getCourseLivesById },
}) => ({
  createCourseTransition,
  getBroadcastById,
  getCourseLivesById,
  getStreamById,
})

export default connect(mapState, mapDispatch)(withRouter(SchoolCourseLiveStudio))
