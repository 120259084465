import { UserAPI } from 'api'

export const Notification = {
  state: {
    notifications: [],
    notificationsForPopup: [],
    count: 0,
  },
  reducers: {
    setNotifications: (state, payload) => {
      return {
        ...state,
        notifications: payload,
      }
    },
    setNotificationsForPopup: (state, payload) => {
      return {
        ...state,
        notificationsForPopup: payload,
      }
    },
    setNotificationCount: (state, payload) => {
      return {
        ...state,
        count: payload.count,
      }
    },
  },
  effects: () => ({
    async getNotifications({ limit = 0, skip = 0 } = {}) {
      try {
        const filter = JSON.stringify({
          limit,
          skip,
          order: 'createdAt DESC',
        })
        const res = await UserAPI.getNotifications({ filter })
        const count = await UserAPI.getNotificationCount({
          where: JSON.stringify({ isRead: false }),
        })
        this.setNotifications(res)
        this.setNotificationCount(count)
        return res
      } catch (e) {
        throw e
      }
    },
    async getNotificationsForPopup() {
      try {
        const filter = JSON.stringify({
          limit: 10,
          skip: 0,
          order: 'createdAt DESC',
        })
        const res = await UserAPI.getNotifications({ filter })
        const count = await UserAPI.getNotificationCount({
          where: JSON.stringify({ isRead: false }),
        })
        this.setNotificationsForPopup(res)
        this.setNotificationCount(count)
        return res
      } catch (e) {
        throw e
      }
    },
    async readNotification({ id, isRead }) {
      try {
        const res = await UserAPI.updateNotification({ fk: id, isRead })
        const count = await UserAPI.getNotificationCount({
          where: JSON.stringify({ isRead: false }),
        })
        this.setNotificationCount(count)
        return res
      } catch (e) {
        throw e
      }
    },
    async markAllNotificationsRead() {
      try {
        const res = await UserAPI.markAllNotificationsRead()
        return res
      } catch (e) {
        throw e
      }
    },
  }),
}
