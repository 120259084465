import styled from 'styled-components'
import { Typography, Label } from 'components'
import theme from 'theme'

export const Container = styled.div`
  flex: 1;
  width: 100%;
  padding: 40px 150px 80px 150px;
  /* background: #fff;
  box-shadow: 0 2px 8px 2px rgba(20, 23, 28, 0.15);
  margin-bottom: 0px; */
  height: 100%;
  /* overflow: scroll; */
  @media (max-width: 991px) {
    border-radius: 0px;
    padding-bottom: 45px;
  }
  hr.promotion {
    margin: 20px 45px 0px 45px;
    /* margin: 20px 0; */

    height: 1px;
    background-color: #dedfe0;
    border: none;
    @media (max-width: 768px) {
      margin: 20px 15px 0px 15px;
    }
  }
`

export const SubHeader = styled.div`
  .wrapper {
    display: flex;
    position: relative;
    padding: 20px 45px;
    border-bottom: 1px solid #dedfe0;
    @media (max-width: 768px) {
      padding: 20px 15px;
    }
  }

  .main-header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin: 0px;
    }

    // button {
    //   padding-top: 7px;
    //   padding-bottom: 7px;
    //   /* max-height: 30px; */
    // }
  }
`

export const Content = styled.div`
  /* padding-top: 16px; */
  /* @media (max-width: 768px) {
    padding: 20px 15px 0px;
  } */
  .mock {
    font-size: ${({ theme }) => theme['text-xxs']};
    color: ${(props) => props.theme.grey};
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }
`

export const DeleteButton = styled.button`
  /* display: none; */
  /* padding: 5px; */
  padding-left: 14px;
  margin: 0;
  border: none;
  background-color: white;
  &:hover {
    cursor: pointer;
  }
`
export const DeleteImage = styled.img`
  width: 24px;
  height: 24px;
`

export const AddButton = styled.button`
  /* padding: 10px 20px; */
  display: flex;
  justify-content: flex-start;
  border: none;
  font-weight: 400;
  margin-top: 2px;
  color: ${(props) => props.theme.primary1};
  background-color: transparent;
  cursor: pointer;
`

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const TextLink = styled(Typography)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

export const TextLabel = styled(Label)`
  display: block;
  p {
    color: ${(props) => props.theme.primary1};
    font-size: ${({ theme }) => theme['text-xl']};
    font-weight: 700;
  }
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme['text-sm']};
  /* line-height: 10px; */
  margin-top: 12px;
  margin-bottom: 16px;
  grid-column: span 2;
  color: ${(props) => props.theme.primary1};
`

export const PriceMessage = styled.div`
  display: flex;
  flex-direction: row;
  /* padding-left: 45px; */
  padding-bottom: 10px;
  align-items: center;

  span {
    color: red;
  }
  @media (max-width: 768px) {
    padding-left: 15px;
  }
  @media (max-width: 350px) {
    flex-direction: column;
    align-items: start;
  }
`

export const CurrencyContainer = styled.span`
  /* grid-row: 1; */
  align-self: center;
  color: ${theme.black87};
  font-weight: 400;
  font-size: 14px;
  border: 1px solid ${theme.black20};
  border-radius: 6px 0 0 6px;
  border-right: 10px;
  /* background-color: hsl(0, 0%, 80%); */
  line-height: 34px;
  text-align: center;
  /* margin-right: 10px; */
  padding: 6px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid ${({ theme }) => theme.black20};
  /* border-top: 1px solid ${({ theme }) => theme.gray4}; */
  /* border-right: 1px solid ${({ theme }) => theme.gray4}; */
  border-radius: 6px;
  /* max-width: 600px; */
  /* min-width: 30%; */
  min-width: 250px;
  margin-bottom: 24px;

  > span {
    padding: 13px 4px;
    border-left: 1px solid ${({ theme }) => theme.black20};
    border-bottom: 1px solid ${({ theme }) => theme.black20};
    &:nth-of-type(2n + 1) {
      border-left: none;
    }
    &:nth-last-child(2),
    :nth-last-child(1) {
      border-bottom: none;
    }
    display: flex;
    justify-content: center;
  }
`

export const GridPackage = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid ${({ theme }) => theme.black20};
  border-radius: 6px;
  /* max-width: 600px; */
  min-width: 250px;
  margin-bottom: 24px;
  > span {
    padding: 13px 4px;
    border-left: 1px solid ${({ theme }) => theme.black20};
    border-bottom: 1px solid ${({ theme }) => theme.black20};
    &:nth-of-type(3n + 1) {
      border-left: none;
    }
    &:nth-last-child(1),
    :nth-last-child(2),
    :nth-last-child(3) {
      border-bottom: none;
    }
    display: flex;
    justify-content: center;
  }
`
export const Icon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 14px;
  align-self: center;
`

export const Itemradio = styled.div`
  margin: 18px 0px 18px 0px;
`
export const RadioContainer = styled.div`
  display: inline-flex;
  padding-right: 30px;
  &:last-child {
    padding-right: 0;
  }
  .ant-checkbox .ant-checkbox-inner {
    border-color: #3d4453;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #253e87;
    border-color: #253e87;
  }
  .ant-radio-checked .ant-radio-inner {
    border: 1px solid #253e87;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #253e87;
    border: 1px solid #253e87;
  }
`
export const PasswordInput = styled.div`
  position: relative;
  padding: 0px;
  width: calc(100% - 160px);
  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    padding-right: 40px;
  }
  @media (max-width: 426px) {
    padding: 10px 0 0px;
  }

  .icon {
    position: absolute;
    top: 18px;
    right: 10px;
    width: 18px;
    height: 18px;
    @media (max-width: 820px) {
      top: 58px;
    }
    @media (max-width: 768px) {
      top: 58px;
    }
    @media (max-width: 426px) {
      top: 47px;
    }
  }
`
export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  background: white;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06);
  min-height: 56px;
  padding: 8px 50px;
  gap: 16px;
  align-items: center;
  z-index: 10;
  //position: sticky;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
`
