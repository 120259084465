import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Typography from 'components/typography'
//import theme from '../../theme'
import CardCourse from './CardCourse'
import { Custom } from 'utils/displayResponsive'
import Empty from './empty'
import EmptyProfile from '../../images/icons/EmptyProfile.svg'
const Body = styled.div`
  margin: 0px;
  width: 100%;
`

const Card = styled.div`
  background-color: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 305px;
  //height: 100%;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  @media (max-width: 1439px) {
    max-width: 895px;
    width: 100%;
    min-width: 441px;
  }
  @media (max-width: 767px) {
    max-width: 740px;
    width: 100%;
    min-width: 288px;
  }
`
function RecommendedCourses(props) {
  const { school, getRecommendedCourses, RecommendedCourses } = props

  useEffect(() => {
    const payload = {
      id: school.id,
    }
    getRecommendedCourses(payload)
  }, [school.id, getRecommendedCourses])

  if (RecommendedCourses.length < 1) {
    return (
      <Body>
        <Card>
          <Typography h4 color="primary1" id="AcademyProfile.RecommendedCourses">
            Recommended Courses
          </Typography>

          <Empty image={EmptyProfile} />
        </Card>
      </Body>
    )
  }
  return (
    <Body>
      <Custom minWidth={1440}>
        <Card>
          <Typography h4 color="primary1" id="AcademyProfile.RecommendedCourses">
            Recommended Courses
          </Typography>
          {(RecommendedCourses.slice(0, 3) || []).map((item, key) => (
            <CardCourse {...item} key={key} link={`/courses/${item.slug}`} />
          ))}
        </Card>
      </Custom>
      <Custom maxWidth={1439}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            width: '100%',
            maxWidth: '895px',
          }}
        >
          <Typography h4 color="primary1" id="AcademyProfile.RecommendedCourses">
            Recommended Courses
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              overflow: 'scroll',
              width: '100%',
            }}
          >
            {(RecommendedCourses.slice(0, 3) || []).map((item, key) => (
              <CardCourse
                {...item}
                key={key}
                link={`/courses/${item.slug}`}
                style={{ maxWidth: '273px' }}
              />
            ))}
          </div>
        </div>
      </Custom>
    </Body>
  )
}

const mapState = (state) => ({
  school: state.School.school,
  RecommendedCourses: state.School.RecommendedCourses,
})
const mapDispatch = ({ School: { getRecommendedCourses } }) => ({
  getRecommendedCourses,
})
export default connect(mapState, mapDispatch)(RecommendedCourses)
