import moment from 'moment'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import appUserApi from '../../api/user'
import subjectApi from '../../api/subject'
import lectureAPI from '../../api/lecture'

export const Subject = {
  state: {
    subjects: [],
    subjectInfo: {},
    lectures: [],
    assessments: [],
    assessmentInfo: {},
    lectureInfo: {},
  },
  reducers: {
    setSubject(state, payload) {
      return {
        ...state,
        subjects: payload,
      }
    },
    setSubjectInfo(state, payload) {
      return {
        ...state,
        subjectInfo: payload,
      }
    },
    setLecture(state, payload) {
      return {
        ...state,
        lectures: payload,
      }
    },
    setLectureInfo(state, payload) {
      return {
        ...state,
        lectureInfo: payload,
      }
    },
    resetLecture(state) {
      return {
        ...state,
        lectures: [],
        lectureInfo: {},
      }
    },
    setAssessment(state, payload) {
      return {
        ...state,
        assessments: payload,
      }
    },
    setAssessmentInfo(state, payload) {
      return {
        ...state,
        assessmentInfo: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async getAssessment(payload) {
      try {
        const assessments = await subjectApi.getAssessment(payload)
        this.setAssessment(assessments)
      } catch (error) {
        throw error
      }
    },
    async createAssessment(payload) {
      try {
        const res = await subjectApi.createAssessment(payload)
        return res
      } catch (error) {
        throw error
      }
    },
    async deleteAssessment(payload) {
      try {
        await subjectApi.deleteAssessment(payload)
      } catch (error) {
        throw error
      }
    },
    async getAssessmentById(payload) {
      try {
        const assessmentInfo = await subjectApi.getAssessmentById(payload)
        this.setAssessmentInfo(assessmentInfo)
      } catch (error) {
        throw error
      }
    },
    async updateAssessmentById(payload) {
      try {
        await subjectApi.updateAssessmentById(payload)
      } catch (error) {
        throw error
      }
    },
    async deleteAssessmentById(payload) {
      try {
        await subjectApi.deleteAssessmentById(payload)
      } catch (error) {
        throw error
      }
    },
    async createAttachment(payload) {
      try {
        await lectureAPI.createAttachment(payload)
      } catch (error) {
        throw error
      }
    },
    async getAttachment(payload) {
      try {
        await lectureAPI.getAttachment(payload)
      } catch (error) {
        throw error
      }
    },
    async updateAttachment(payload) {
      try {
        await lectureAPI.updateAttachment(payload)
      } catch (error) {
        throw error
      }
    },
    async deleteAttachment(payload) {
      try {
        await lectureAPI.deleteAttachment(payload)
      } catch (error) {
        throw error
      }
    },
    async deleteAttachmentById(payload) {
      try {
        await lectureAPI.deleteAttachmentById(payload)
      } catch (error) {
        throw error
      }
    },
    async postSubjectLecture(payload) {
      try {
        const lecture = await subjectApi.postLecture(payload)
        return lecture
      } catch (error) {
        throw error
      }
    },
    async postSubjectLectureWithPriority(payload) {
      try {
        const lecture = await subjectApi.postLectureWithPriority(payload)
        return lecture
      } catch (error) {
        throw error
      }
    },
    async copyWorksheet(payload) {
      const payload1 = {
        type: payload?.type,
        worksheetId: payload?.worksheetId,
        subjectId: payload?.subjectId,
      }
      try {
        const workSheet = await subjectApi.copyWorksheet(payload1)
        return workSheet
      } catch (error) {
        throw error
      }
    },
    async fetchSubject() {
      const filter = JSON.stringify({
        order: 'createdAt DESC',
      })
      const payload = await appUserApi.getSubject({ filter })
      this.setSubject(payload)
    },
    async fetchSubjectInfo(subjectId) {
      const filter = JSON.stringify({
        // include: ['appUser', 'teachers', 'school', 'publishedSubject'],
        include: ['appUser', { lectures: 'attachments' }],
      })
      const info = await subjectApi.getSubjectInfo({ subjectId, filter })
      this.setSubjectInfo(info)
      return info
    },
    async updateSubjectInfo(payload) {
      const { subjectId } = payload
      try {
        const info = await subjectApi.updateSubjectInfo({ subjectId, ...payload })
        this.setSubjectInfo(info)
        dispatch.Alert.success({ title: 'Subject Updated Successfully' })
      } catch (e) {
        dispatch.Alert.error({ title: 'Subject Update Failed' })
        throw e
      }
    },
    async fetchSubjectLecture(subjectId) {
      const res = await subjectApi.getLecture({
        subjectId,
        filter: JSON.stringify({
          include: [{ tests: 'questions' }, 'attachments'],
          order: 'priority ASC',
        }),
      })

      this.setLecture(res)
      return res
    },
    async fetchLectureInfo(lectureId, state) {
      const subjectId = state.Subject.subjectInfo.id
      const res = await subjectApi.getLectureById({ subjectId, lectureId })
      this.setLectureInfo(res)
    },
    async getLectureInfo({ lectureId, subjectId }) {
      const res = await subjectApi.getLectureById({ subjectId, lectureId })
      this.setLectureInfo(res)
    },
    async createLecture(payload, state) {
      const result = await subjectApi.createLecture(payload)
      if (state.Assessment.lectureTest.pretest) {
        // const data = state.Assessment.lectureTest.pretest
        // await dispatch.Assessment.upsertLectureTest({
        //   lectureId: result.id,
        //   type: 'pretest',
        //   ...data,
        // })
        dispatch.Assessment.unsetLectureTest('pretest')
      }
      if (state.Assessment.lectureTest.posttest) {
        // const data = state.Assessment.lectureTest.posttest
        // await dispatch.Assessment.upsertLectureTest({
        //   lectureId: result.id,
        //   type: 'posttest',
        //   ...data,
        // })
        dispatch.Assessment.unsetLectureTest('posttest')
      }
      return result
    },
    async updateLecture(payload, state) {
      const subjectId = state.Subject.subjectInfo.id
      const lectureId = state.Subject.lectureInfo.id
      try {
        await subjectApi.updateLectureById({
          subjectId,
          lectureId,
          ...payload,
        })
        // dispatch.Alert.success({ title: 'Lecture Updated Successfully' })
      } catch (e) {
        dispatch.Alert.error({ title: 'Lecture Update Failed' })
        throw e
      }
    },

    async updateLectureContent(payload, state) {
      try {
        const subjectId = state.Subject.subjectInfo.id
        const lectureId = state.Subject.lectureInfo.id
        const result = await subjectApi.updateLectureContent({
          id: subjectId,
          lectureId,
          contents: payload.map((item) => {
            const data = { ...item }
            delete data.isActiveContent
            delete data.isActivePosttest
            delete data.isActivePretest
            return data
          }),
        })
        const removingTestIds = payload.reduce((list, item) => {
          if (item.showTest === 'pretest' && item.postTest) {
            return [...list, { id: item.postTest.id, lectureId: item.id }]
          }
          if (item.showTest === 'posttest' && item.preTest) {
            return [...list, { id: item.preTest.id, lectureId: item.id }]
          }
          if (!item.showTest || item.showTest === 'false') {
            if (item.postTest && item.preTest) {
              return [
                ...list,
                { id: item.preTest.id, lectureId: item.id },
                { id: item.postTest.id, lectureId: item.id },
              ]
            }
            if (item.postTest) {
              return [...list, { id: item.postTest.id, lectureId: item.id }]
            }
            if (item.preTest) {
              return [...list, { id: item.preTest.id, lectureId: item.id }]
            }
          }
          return list
        }, [])
        await Promise.all(
          removingTestIds.map(({ id: fk, lectureId: id }) => {
            return lectureAPI.deleteTest({
              id,
              fk,
            })
          })
        )
        dispatch.Alert.success({ title: 'Content Updated Successfully' })
        return result
      } catch (e) {
        dispatch.Alert.error({ title: 'Content Update Failed' })
        throw e
      }
    },
    async removeLectureById({ subjectId, lectureId }) {
      await subjectApi.removeLectureById({ subjectId, lectureId })
      this.fetchSubjectLecture(subjectId)
    },
    async updateContentPriority({ subjectId, contents }) {
      await subjectApi.updateContentPriority({ subjectId, contents })
      this.fetchSubjectLecture(subjectId)
      this.getAssessment({ subjectId })
    },
  }),
  selectors: () => ({
    lectureList() {
      return (rootState) => {
        const getShowTest = (hasPreTest, hasPostTest) => {
          if (hasPreTest && hasPostTest) {
            return 'both'
          }
          if (hasPreTest) {
            return 'pretest'
          }
          if (hasPostTest) {
            return 'posttest'
          }
          return ''
        }
        const getAddVideoLink = (videoLink) => {
          if (videoLink) {
            return 'Video Link'
          }

          return ''
        }

        const sortedList = sortBy(rootState.Subject.lectures, 'priority')
        const newList = sortedList.reduce((arr, item) => {
          if (item.type === 'paragraph') {
            const obj = {
              id: item.id,
              name: item.name,
              description: item.description,
              img: item.image,
              created: moment(item.createdAt).format('DD MMM YYYY'),
              update: moment(item.updateAt).format('DD MMM YYYY'),
              lectures: [],
            }
            return [...arr, obj]
          }

          const preTest = item.tests.find((test) => test.type === 'pretest')
          const postTest = item.tests.find((test) => test.type === 'posttest')

          const lecture = {
            id: item.id,
            name: item.name,
            description: item.description,
            content: item.content,
            hasPretest: !!preTest,
            hasPosttest: !!postTest,
            preTest,
            postTest,
            isActivePretest: true,
            isActivePosttest: true,
            isActiveContent: true,
            showTest: getShowTest(!!preTest, !!postTest),
            showAddVideo: getAddVideoLink(item.videoLink),
            videoLink: item.videoLink,
            attachments: item.attachments,
          }

          if (arr.length > 0) {
            const lastItem = arr.slice(-1)[0]
            const lecs = lastItem.lectures || []
            return [...arr.slice(0, -1), { ...lastItem, lectures: [...lecs, lecture] }]
          }
          return []
        }, [])

        return newList
      }
    },
    content() {
      return (rootState, props) => {
        const lectures = get(rootState.Subject.subjectInfo, 'lectures', [])
        const quiz = rootState.Subject.assessmentInfo
        const content = [...lectures].find((item) => item.id === props.lectureId)
        if (props.type === 'quiz') {
          return quiz
        }
        return content
      }
    },
    lectureSide() {
      return ({ Subject: { lectures, assessments } }) => {
        const quizzes = assessments.filter((item) => item.type === 'quiz')
        const pretest = assessments.filter((item) => item.type === 'pretest')
        const posttest = assessments.filter((item) => item.type === 'posttest')
        const sortedList = sortBy([...lectures, ...quizzes], 'priority')
        const newList = sortedList.reduce((arr, item) => {
          if (item.type === 'paragraph') {
            const obj = {
              id: item.id,
              title: item.name,
              description: item.description || '',
              img: item.image || '',
              created: moment(item.createdAt).format('DD MMM YYYY'),
              updated: moment(item.updatedAt).format('DD MMM YYYY'),
              lectures: [],
              priority: item.priority,
            }
            return [...arr, obj]
          }
          const hasPretest = !!item.preTest
          const hasPosttest = !!item.postTest
          const obj = {
            id: item.id,
            title: item.name,
            description: item.description || '',
            hasPretest,
            hasPosttest,
            // isActivePretest: hasPretest && !item.preTest.hasAnswer,
            isActivePretest: hasPretest,
            // isActivePosttest: hasPretest
            //   ? item.preTest.hasAnswer
            //   : hasPosttest && !item.postTest.hasAnswer,
            isActivePosttest: hasPosttest,
            // isActiveContent: hasPretest ? item.preTest.hasAnswer : true,
            isActiveContent: true,
            preTest: get(item, 'preTest', {}),
            postTest: get(item, 'postTest', {}),
            type: item.type || 'lecture',
            priority: item.priority,
          }
          if (arr.length > 0) {
            const lastItem = arr.slice(-1)[0]
            const lecs = lastItem.lectures || []
            return [...arr.slice(0, -1), { ...lastItem, lectures: [...lecs, obj] }]
          }
          return []
        }, [])

        return [...pretest, ...newList, ...posttest]
      }
    },
  }),
}
