import React from 'react'
import PropTypes from 'prop-types'
import Container from './style'

function PureContainer(props) {
  return <Container {...props} />
}

PureContainer.propTypes = {
  props: PropTypes.object,
}

PureContainer.defaultProps = {
  props: {},
}

export default PureContainer
