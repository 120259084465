import Resource from '../utils/resource'

export default new Resource('/StudentGroups', {
  upsert: {
    url: 'upsertData',
    method: 'post',
  },
  addStudents: {
    url: '{id}/addStudents',
    method: 'post',
  },
  delStudent: {
    url: '{id}/students/rel/{studentId}',
    method: 'delete',
  },
  getStudents: {
    url: '{id}',
    method: 'get',
  },
  getCourses: {
    url: '{id}/courses',
    method: 'get',
  },
  addCourses: {
    url: '{id}/addCourses',
    method: 'post',
  },
})
