import React from 'react'
import { Typography } from '../../components'
import styled from 'styled-components'

const FooterStyle = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fafafa; */

  width: 100%;
  /* margin-top: 20px; */
  padding: 0px 20px 40px;
  & p {
    font-weight: 500;
    /* margin: 0 auto; */
  }
  @media (max-height: 700px) {
    position: static;
  }
`
function Footer({ style = {} }) {
  return (
    <FooterStyle style={style}>
      <Typography color="primaryFont">Powered by Classwin Co.,Ltd.</Typography>
    </FooterStyle>
  )
}

export default Footer
