import React from 'react'
import { connect } from 'react-redux'
import CustomReactSelect from 'components/customReactSelect/loadable'
import { TextLabel, Description } from './styled'

const options = {
  PUBLIC: {
    th: [
      { label: 'ไม่มีค่าลงทะเบียน', value: 'FREE_COURSE' },
      { label: 'มีค่าลงทะเบียน ', value: 'PAID_COURSE' },
      { label: 'คิดค่าลงทะเบียนแบบเหมากลุ่ม', value: 'PACKAGE_COURSE' },
    ],
    en: [
      { label: 'No Registration Fee', value: 'FREE_COURSE' },
      { label: 'Require Registration Fee', value: 'PAID_COURSE' },
      { label: 'Group Registration Fee', value: 'PACKAGE_COURSE' },
    ],
  },
  CODE: {
    th: [
      { label: 'ไม่มีค่าลงทะเบียน', value: 'FREE_COURSE' },
      { label: 'มีค่าลงทะเบียน', value: 'PAID_COURSE' },
      { label: 'คิดค่าลงทะเบียนแบบเหมากลุ่ม', value: 'PACKAGE_COURSE' },
    ],
    en: [
      { label: 'No Registration Fee', value: 'FREE_COURSE' },
      { label: 'Require Registration Fee', value: 'PAID_COURSE' },
      { label: 'Group Registration Fee', value: 'PACKAGE_COURSE' },
    ],
  },
}

const PricingSelector = (props) => {
  const { locale, pricing, handleSelect, publicMode = 'CODE' } = props
  const selectValue = options[publicMode][locale].find(({ value }) => value === pricing)
  return (
    <div>
      <div>
        <TextLabel id="course.tuitionfee.fee">Tuition Fee</TextLabel>
        <Description id="course.tuitionfee.fee.desc">Set a tuition fee for this course</Description>
      </div>
      <CustomReactSelect
        id=""
        label=""
        name="pricing"
        value={selectValue}
        options={options[publicMode][locale]}
        onBlur={() => {}}
        onChange={(name, value) => {
          handleSelect(value)
        }}
      />
    </div>
  )
}

const mapState = ({ Language: { locale } }) => ({
  locale,
})
export default connect(mapState)(PricingSelector)
