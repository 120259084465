export const tabs = [
  {
    id: 'member.title',
    label: 'Member',
    key: '0',
    component: null,
    path: '/teacher',
  },
  {
    id: 'member.student',
    label: 'Student',
    key: '1',
    component: null,
    path: '/student',
  },
  {
    id: 'member.group',
    label: 'Group',
    key: '2',
    component: null,
    path: '/groups',
  },

  // {
  //   id: 'member.tags',
  //   label: 'Tags',
  //   key: '3',
  //   component: null,
  //   path: '/tags',
  // },
]
