/* eslint-disable no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { ClasswinEditor, Button, Typography } from '../../../components'
import PlusIcon from 'images/icons/primary-plus.svg'
import QuestionsItem from '../../questionsItem'

const SingleChoice = ({
  title,
  //inputChange,
  choices = [],
  assessmentId,
  answers,
  id: questionId,
  getQuestionData,
  handleDeleteChoice,
  handleAddChoice,
  handleAutoSave,
  locale,

  value,
  type,
  setTitle,
  setChoices,
  setAnswers,
}) => {
  return (
    <>
      <Formik initialValues={{ title }} enableReinitialize>
        {() => (
          <Form>
            <Typography id="label.question" color="primary1" margin="0 0 8px">
              Question
            </Typography>
            <Field name="title">
              {({ field, form }) => (
                <ClasswinEditor
                  style={{ marginBottom: '0px' }}
                  isShowAll={false}
                  id="title"
                  name="title"
                  value={field.value}
                  placeholder={locale === 'th' ? 'คำถาม' : 'Question'}
                  onChange={(value) => {
                    const e = {
                      target: {
                        name: 'title',
                        value,
                      },
                    }
                    form.handleChange(e)
                    setTitle(value)
                  }}
                />
              )}
            </Field>
          </Form>
        )}
      </Formik>
      {choices.length > 0 && (
        <Typography id="course.content.answer" color="primary1" margin="12px 0 8px">
          Answer(s)
        </Typography>
      )}
      {choices.map((c, i) => (
        <QuestionsItem
          key={i}
          index={i}
          title={c}
          uid={JSON.parse(c).uid}
          handleDeleteChoice={handleDeleteChoice}
          assessmentId={assessmentId}
          choices={choices}
          answers={answers}
          setChoices={setChoices}
          setAnswers={setAnswers}
          questionId={questionId}
          getQuestionData={getQuestionData}
          handleAutoSave={handleAutoSave}
        />
      ))}
      <Button
        id="button.add.choice"
        type="button"
        onClick={handleAddChoice}
        image={<img src={PlusIcon} alt="plus-icon" />}
        whiteBorder
        margin={choices.length > 0 ? '4px 0 0' : '16px 0 0'}
      >
        Add Choice
      </Button>
    </>
  )
}

const mapState = ({ Language: { locale } }) => ({ locale })

export default connect(mapState)(SingleChoice)
