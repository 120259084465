import Footer from 'components/Footer'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import ErrorBoundary from '../../components/errorBoundary'

import PageHeading from './pageHeading/loadable'
import { SmallMobile, DesktopAndTablet } from '../../utils/displayResponsive'
import { tabs } from './tabs'

const Container = styled.div`
  padding: 40px 50px 30px;
  height: calc(100vh - 128px);
  overflow: scroll;
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`

class SchoolPaymentLayout extends React.Component {
  onChangeTab = (path) => {
    const { match } = this.props
    this.props.history.push(match.url + path)
  }

  render() {
    const { children, location } = this.props
    const isKycPage = /^\/schools\/.+\/payment\/kyc$/.test(location.pathname)

    return (
      <ErrorBoundary>
        <Fragment>
          <DesktopAndTablet>
            <PageHeading
              // id="instructor.menu.payment"
              // text="Payment"
              tabData={!isKycPage ? tabs : null}
              onChangeTab={this.onChangeTab}
              learner
            />
          </DesktopAndTablet>
          <SmallMobile>
            <PageHeading
              // id="instructor.menu.payment"
              // text="Payment"
              tabData={!isKycPage ? tabs : null}
              onChangeTab={this.onChangeTab}
              learner
              headBorder
            />
          </SmallMobile>
          <Container>
            {children}
            <Footer style={{ padding: '40px 20px 0px' }} />
          </Container>
        </Fragment>
      </ErrorBoundary>
    )
  }
}

export default SchoolPaymentLayout
