export const menus = [
  {
    label: 'Information',
    key: '0',
    component: null,
    path: '/information',
  },
  {
    label: 'Content',
    key: '1',
    component: null,
    path: '/content',
  },
]
