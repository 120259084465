import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorBoundary from '../../components/errorBoundary'
import Breadcrumb from '../../components/breadcrumb/loadable'
import TabComponent from '../../components/tabComponent/loadable'
import { Button, Card } from '../../components/index'
import { Heading } from '../../components/styledComponents'
import { menus } from './menus'
import { ACCESS_TOKEN } from '../../constants'

const TabStyle = {
  backgroundColor: 'transparent',
  boxShadow: '0 0 0 0',
  padding: '0',
  borderBottom: '1px solid rgba(194, 194, 194, 0.5)',
}

const Container = styled.div`
  padding: 30px;

  min-height: calc(100vh - 114px);
  padding-bottom: 110px;

  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 80px;
    min-height: calc(100vh - 134px);
  }
`

const Top = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`

class SchoolCourseAssignmentLayout extends Component {
  async componentDidMount() {
    const {
      getAssignmentDetail,
      match: {
        params: { courseId, assignmentId },
      },
    } = this.props
    const testConsole = await getAssignmentDetail({ courseId, assignmentId })
    console.log('testConsole', testConsole)
  }

  onChangeTab = (path) => {
    const { match } = this.props
    this.props.history.push(match.url + path)
  }

  render() {
    const {
      match: {
        params: { assignmentId: id },
      },
    } = this.props
    const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN))
    const link = `${process.env.REACT_APP_API_URL}/Assignments/${id}/report?access_token=${token.id}`

    const {
      children,
      match: {
        params: { courseId, schoolId },
      },
      assignment: { course, title },
    } = this.props

    const courseName = course ? course.name : ''
    const breadcrumb = [
      {
        label: `${courseName}`,
        link: `/schools/${schoolId}/curriculum/manage/${courseId}`,
        isActive: false,
      },
      {
        id: 'tab.label.Assignment',
        label: 'Assignment',
        link: `/schools/${schoolId}/curriculum/manage/${courseId}/assignment`,
        isMobile: true,
        isActive: false,
      },
      {
        label: `${title}`,
        isActive: true,
      },
    ]

    return (
      <ErrorBoundary>
        <Breadcrumb
          link={`/schools/${schoolId}/curriculum`}
          firstLabel="My Course"
          firstLabelId="myCourse.title"
          data={breadcrumb}
        />
        <Container>
          <Card padding={30} boxShadow="none">
            <Top>
              <Heading h3>{title}</Heading>
              <a style={{ justifySelf: 'end' }} href={link}>
                <Button id="button.export" primary>
                  Export to CSV
                </Button>
              </a>
            </Top>
            <TabComponent
              hideShadow
              style={TabStyle}
              transparent
              tabData={menus}
              activeTab={0}
              onChangeTab={this.onChangeTab}
            />
            {children}
          </Card>
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapState = (state) => ({
  assignment: state.teachingCourse.assignmentDetail,
})

const mapDispatch = (dispatch) => {
  return {
    getAssignmentDetail: dispatch.teachingCourse.getAssignmentDetail,
  }
}

export default connect(mapState, mapDispatch)(SchoolCourseAssignmentLayout)
