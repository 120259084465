import React, { Fragment, useState } from 'react'
import {
  Button,
  Font,
  Label,
  Wrapper,
  Content,
  CustomCheckbox,
  Div,
  Img,
  NotificationContainer,
} from './style'
import FunnelSimple from '../FilterCourse/FunnelSimple.svg'
import FunnelSimple1 from '../FilterCourse/FunnelSimple1.svg'
import Tooltip from 'components/tooltip'
import ColorFilter from './ColorFilter'

const ContentCheckbox = ({ data, onChange, checked }) => {
  return (
    <>
      <Content>
        <CustomCheckbox
          backgroundColor="#253e87"
          id={data.value}
          onChange={onChange}
          checked={checked}
        />
        <Label color="#3d4453" for={data.value} check={checked}>
          {`${data.label} (${data.amountCourse})`}
        </Label>
      </Content>
    </>
  )
}

const FilterMyCourse = ({ value, handleChange, filter }) => {
  const [colorFilter, setColorFilter] = useState(false)
  const changeFilter = (event) => {
    setColorFilter(event)
  }
  return (
    <Tooltip
      placement="bottom"
      trigger="click"
      onVisibilityChange={(event) => changeFilter(event)}
      tooltip={
        <NotificationContainer>
          {value.map((course, i) => {
            return (
              <Fragment key={i.toString()}>
                <ColorFilter>
                  <Div>
                    <ContentCheckbox
                      data={course}
                      onChange={() => handleChange(i)}
                      checked={filter[i]}
                    />
                  </Div>
                </ColorFilter>
              </Fragment>
            )
          })}
        </NotificationContainer>
      }
    >
      <Button color={colorFilter ? '#162b67' : '#EBF4F9'}>
        <Wrapper>
          <Img src={colorFilter ? FunnelSimple : FunnelSimple1} alt="Funnel Simple" />
          <Font color={colorFilter ? '#ffffff' : '#253e87'}>Filter</Font>
        </Wrapper>
      </Button>
    </Tooltip>
  )
}

export default FilterMyCourse
