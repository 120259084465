/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import LibSidebar from 'react-sidebar'
import { connect } from 'react-redux'
import TeacherSchoolHeader from 'containers/teacherSchoolHeader/loadable'
import SchoolPackageModal from 'components/schoolPackageModal/loadable'
import SchoolTutorialModal from 'components/schoolTutorialModal'
// Classwin_Menu pop up_1-Dashboard.jpeg
import Welcome from '../../images/Classwin_Menu-pop-up_1-Dashboard.jpeg'
import Course from '../../images/Classwin_Menu-pop-up_2-Courses.jpeg'
import Member from '../../images/Classwin_Menu-pop-up_3-Member.jpeg'
import Payment from '../../images/Classwin_Menu-pop-up_4-Payment.jpeg'
import Certificate from '../../images/Classwin_Menu-pop-up_5-Certificate.jpeg'
import Report from '../../images/Classwin_Menu-pop-up_6-Report.jpeg'
import Information from '../../images/Classwin_Menu-pop-up_7-Information.jpeg'
import Packages from '../../images/Classwin_Menu_pop_up_8-Package.jpg'
import ErrorBoundary from '../../components/errorBoundary'
import Sidebar from '../../components/sideBar'
import { LayoutContainer, Content, Image } from './styles'
import { UnauthorizedLayout } from '../../components/layout/UnauthorizedLayout'
import { schoolMenus } from './menus'
import { ACCESS_TOKEN } from '../../constants'
// import SearchBar from '../../containers/searchBar/loadable'
import DrawerProfile from 'components/DrawerProfile'
import { popupMenus } from 'containers/searchBar/popupMenus'
import get from 'lodash/get'
import store from 'store'

const mql = window.matchMedia(`(max-width: 1280px)`)
import {
  DesktopAndTablet,
  SmallMobile,
  Large,
  Medium,
  Small,
  LargeDesktop,
  MobileAndTablet,
} from '../../utils/displayResponsive'
import styled from 'styled-components'
import { Dropdown, Menu } from 'antd'
import { Icon } from 'components'
import TutorialIcon from 'images/Tutorial.svg'
import { Typography } from '../../components/index'
import Button from '../../components/button'

const Bottom = styled.div`
  padding: 0px 30px;
  /* height: 68px; */
  width: 100%;
  display: flex;
  @media (max-width: 1024px) {
    padding: 0px 10px;
  }
  @media (max-width: 768px) {
    padding: 0px 0px;
  }
`

const Tutorial = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 70px;
  right: 0;
  z-index: 10;
  @media (max-width: 768px) {
    bottom: 120px;
  }
`

export class TeacherSchoolLayout extends Component {
  constructor(props) {
    super(props)
    mql.addListener(props.mediaQueryChanged)

    this.state = {
      isPackageOpen: false,
      cookiePolicy: true,
    }
  }

  menu = (
    <Menu
      items={[
        {
          key: '2',
          label: (
            <a target="_blank" rel="noopener noreferrer" href="/tutorialTeacher/teacherMode">
              คู่มือการใช้งานสำหรับผู้สอน
            </a>
          ),
        },
      ]}
    />
  )

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN))
    if (token) {
      const { fetchMe } = this.props
      await fetchMe()
    }

    const { match, getMySchoolById, getSchoolPackages } = this.props
    if (match.params.schoolId) {
      getMySchoolById(match.params.schoolId)
      getSchoolPackages()
    }
  }

  componentDidUpdate(prevProps) {
    const { getMySchoolById, match } = this.props
    if (match.params.schoolId !== prevProps.match.params.schoolId) {
      getMySchoolById(match.params.schoolId)
    }
  }

  componentWillUnmount() {
    mql.removeListener(this.props.mediaQueryChanged)
  }

  handleOpenPackageModal = () => {
    this.setState((prevState) => ({
      isPackageOpen: !prevState.isPackageOpen,
    }))
  }

  handleSkipTutorial = async (menu) => {
    const { updateProfile, schoolTutorial } = this.props
    await updateProfile({
      schoolTutorial: {
        ...schoolTutorial,
        [`skiped${menu[0].toUpperCase()}${menu.substring(1)}`]: true,
      },
    })
  }

  render() {
    const {
      authorized,
      sidebarOpen,
      sidebarDocked,
      toggle,
      teacherSidebar,
      match,
      location,
      schoolTutorial,
      isTeacher,
      appUser,
      sidebarTeacherProfile,
      mySchoolDetail: { name: schoolName },
      cookiePolicy,
      setCookiePolicy,
    } = this.props
    const subpath = location.pathname.split('/')[3]
    const isTeacherPath = match.url.startsWith('/teacher') || match.url.startsWith('/schools')
    const pathname = get(location, 'pathname', false)

    let currentMenu = subpath || 'welcome'
    if (subpath === 'dashboard') {
      currentMenu = 'welcome'
    }
    if (subpath === 'profile') {
      currentMenu = 'information'
    }
    if (subpath === 'curriculum') {
      currentMenu = 'course'
    }
    if (subpath === 'checkout') {
      currentMenu = 'packages'
    }
    const isOpen =
      !schoolTutorial[`skiped${currentMenu[0].toUpperCase()}${currentMenu.substring(1)}`]

    const images = { Welcome, Course, Member, Payment, Certificate, Report, Information, Packages }

    if (!authorized) {
      return <UnauthorizedLayout {...this.props} />
    }
    const widthContent = window.innerWidth - 200

    return (
      <ErrorBoundary>
        <LayoutContainer>
          <LargeDesktop>
            <Sidebar
              menus={schoolMenus(match.params.schoolId)}
              isSchool
              styleContainer={{ zIndex: 1 }}
              isPackageOpen={this.state.isPackageOpen}
              // handleOpenModal={this.handleOpenPackageModal}
              handleOpenModal={() => {
                toggle(true)
              }}
            />
            <div style={{ width: widthContent, marginLeft: 200, overflowY: 'auto' }}>
              <TeacherSchoolHeader />
              <div style={{ marginTop: 68 }}>{this.props.children}</div>
            </div>

            {sidebarOpen && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'fixed',
                  // backgroundColor: 'transparent',
                  zIndex: 100,
                  top: 0,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    backgroundColor: 'transparent',
                    zIndex: 100,
                    top: 0,
                  }}
                  onClick={() => {
                    toggle(false)
                  }}
                ></div>

                <div
                  style={{
                    height: 'auto',
                    width: 240,
                    zIndex: 1000,
                    position: 'fixed',
                    right: 50,
                    top: 56,
                  }}
                >
                  <DrawerProfile
                    onRequestClose={() => toggle(false)}
                    menu={popupMenus(isTeacherPath, appUser?.roles?.length)}
                    pathname={pathname}
                    isTeacher={isTeacher}
                  />
                </div>
              </div>
            )}
          </LargeDesktop>
          <MobileAndTablet>
            {/* <LibSidebar
              open={sidebarTeacherProfile}
              docked={sidebarDocked}
              onSetOpen={teacherSidebar}
              sidebar={
                <Sidebar
                  menus={schoolMenus(match.params.schoolId)}
                  isSchool
                  isPackageOpen={this.state.isPackageOpen}
                  // handleOpenModal={this.handleOpenPackageModal}
                  handleOpenModal={() => {
                    teacherSidebar(true)
                  }}
                />
              }
              transitions={mql.matches}
              styles={{
                root: {
                  overflow: 'unset',
                },
                sidebar: {
                  zIndex: 300,
                  position: 'fixed',
                  overflowY: 'hidden',
                  top: 0,
                  left: 0,
                },
                overlay: {
                  zIndex: 250,
                },
                content: {
                  position: 'absolute',
                  overflowY: 'unset',
                  // padding: '24px 24px 32px',
                },
              }}
            >
              <Content>
                <TeacherSchoolHeader />
                {this.props.children}
              </Content>
            </LibSidebar> */}
            <Content>
              <TeacherSchoolHeader />
              <div style={{ marginTop: 68 }}>{this.props.children}</div>
            </Content>
            {sidebarTeacherProfile && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  zIndex: 300,
                  position: 'fixed',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    backgroundColor: 'transparent',
                    zIndex: 100,
                    top: 0,
                  }}
                  onClick={() => {
                    teacherSidebar(false)
                  }}
                ></div>

                <Sidebar
                  menus={schoolMenus(match.params.schoolId)}
                  isSchool
                  isPackageOpen={this.state.isPackageOpen}
                  // handleOpenModal={this.handleOpenPackageModal}
                  handleOpenModal={() => {
                    teacherSidebar(true)
                  }}
                />
              </div>
            )}
            {sidebarOpen && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  zIndex: 300,
                  position: 'fixed',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    backgroundColor: 'transparent',
                    zIndex: 100,
                    top: 0,
                  }}
                  onClick={() => {
                    // teacherSidebar(false)
                    toggle(false)
                  }}
                ></div>

                <div
                  style={{
                    height: 'auto',
                    width: 240,
                    zIndex: 1000,
                    position: 'fixed',
                    right: 50,
                    top: 56,
                  }}
                >
                  <DrawerProfile
                    onRequestClose={() => toggle(false)}
                    menu={popupMenus(isTeacherPath, appUser?.roles?.length)}
                    pathname={pathname}
                    isTeacher={isTeacher}
                  />
                </div>
              </div>
            )}
          </MobileAndTablet>
          {/* <Content>
            <TeacherSchoolHeader />
            {this.props.children}
          </Content> */}
          {/* <LibSidebar
            open={sidebarTeacherProfile}
            onSetOpen={teacherSidebar}
            pullRight
            sidebar={
              <DrawerProfile
                onRequestClose={() => teacherSidebar(false)}
                menu={popupMenus(isTeacherPath, appUser?.roles?.length)}
                pathname={pathname}
                isTeacher={isTeacher}
              />
            }
            styles={{
              root: {
                overflow: 'unset',
              },
              sidebar: {
                zIndex: 300,
                position: 'fixed',
                overflowY: 'hidden',
              },
              overlay: {
                zIndex: 250,
              },
              content: {
                position: 'absolute',
                overflowY: 'unset',
              },
            }}
          >
            <LibSidebar
              open={sidebarOpen}
              docked={sidebarDocked}
              onSetOpen={toggle}
              sidebar={
                <Sidebar
                  menus={schoolMenus(match.params.schoolId)}
                  isSchool
                  isPackageOpen={this.state.isPackageOpen}
                  // handleOpenModal={this.handleOpenPackageModal}
                  handleOpenModal={() => {}}
                />
              }
              transitions={mql.matches}
              styles={{
                root: {
                  overflow: 'unset',
                },
                sidebar: {
                  zIndex: 300,
                  position: 'fixed',
                  overflowY: 'hidden',
                },
                overlay: {
                  zIndex: 250,
                },
                content: {
                  position: 'absolute',
                  overflowY: 'unset',
                  // padding: '24px 24px 32px',
                },
              }}
            >
              <Content>
                <TeacherSchoolHeader />
                {this.props.children}
              </Content>
            </LibSidebar>
          </LibSidebar> */}
        </LayoutContainer>
        <Tutorial>
          <Bottom>
            <div
              style={{
                padding: '12px',
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <DesktopAndTablet>
                <Dropdown overlay={this.menu} placement="topLeft">
                  <Icon style={{ width: '40px', height: '40px' }} src={TutorialIcon} />
                </Dropdown>
              </DesktopAndTablet>
              <SmallMobile>
                <Dropdown overlay={this.menu} placement="topLeft">
                  <Icon style={{ width: '40px', height: '40px' }} src={TutorialIcon} />
                </Dropdown>
              </SmallMobile>
            </div>
          </Bottom>
        </Tutorial>
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            bottom: '0px',
            zIndex: 1000,
            left: '0px',
            width: '100%',
            boxShadow: '1px 0px 2px 0px rgba(0, 0, 0, 0.06), 1px 0px 3px 0px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Bottom>
            {cookiePolicy ? (
              <div
                style={{
                  padding: '12px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Large>
                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    <Typography numberOfLines={1} bold style={{ fontSize: '12px' }}>
                      เว็บไซต์นี้มีการใช้คุกกี้เพื่อพัฒนาประสิทธิภาพและประสบการณ์การใช้งานเว็บไซต์ของท่านให้ดียิ่งขึ้น
                      คุณสามารถศึกษาข้อมูลเพิ่มเติมได้ที่
                    </Typography>
                    <a target="_blank" rel="noopener noreferrer" href="/cookie/cookiePolicy">
                      <Typography
                        numberOfLines={1}
                        bold
                        color="primary13"
                        style={{ fontSize: '12px', paddingLeft: 6, cursor: 'pointer' }}
                      >
                        รายละเอียดนโยบายคุกกี้และนโนบายข้อมูลส่วนตัว
                      </Typography>
                    </a>
                  </div>
                  <Button
                    style={{
                      borderRadius: '8px',
                      padding: '9px',
                      width: '150px',
                      height: '100%',
                      justifyContent: 'center',
                    }}
                    newPrimaryButton
                    onClick={() => setCookiePolicy(false)}
                  >
                    Accept all
                  </Button>
                </Large>
                <Medium>
                  <div
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        width: '85%',
                      }}
                    >
                      <Typography bold style={{ fontSize: '12px' }}>
                        เว็บไซต์นี้มีการใช้คุกกี้เพื่อพัฒนาประสิทธิภาพและประสบการณ์การใช้งานเว็บไซต์ของท่านให้ดียิ่งขึ้น
                        คุณสามารถศึกษาข้อมูลเพิ่มเติมได้ที่{' '}
                        <a target="_blank" rel="noopener noreferrer" href="/cookie/cookiePolicy">
                          รายละเอียดนโยบายคุกกี้และนโนบายข้อมูลส่วนตัว
                        </a>
                      </Typography>
                    </div>
                    <Button
                      style={{
                        borderRadius: '8px',
                        padding: '4px',
                        width: '100px',
                        height: '100%',
                        justifyContent: 'center',
                      }}
                      newPrimaryButton
                      onClick={() => setCookiePolicy(false)}
                    >
                      Accept all
                    </Button>
                  </div>
                </Medium>
                <Small>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    <div>
                      <Typography bold style={{ fontSize: '12px' }}>
                        เว็บไซต์นี้มีการใช้คุกกี้เพื่อพัฒนาประสิทธิภาพและประสบการณ์การใช้งานเว็บไซต์ของท่านให้ดียิ่งขึ้น
                        คุณสามารถศึกษาข้อมูลเพิ่มเติมได้ที่
                      </Typography>
                      <a target="_blank" rel="noopener noreferrer" href="/cookie/cookiePolicy">
                        <Typography
                          numberOfLines={1}
                          bold
                          color="primary13"
                          style={{ fontSize: '12px', cursor: 'pointer' }}
                        >
                          รายละเอียดนโยบายคุกกี้และนโนบายข้อมูลส่วนตัว
                        </Typography>
                      </a>
                    </div>
                    <Button
                      style={{
                        borderRadius: '8px',
                        padding: '4px',
                        // width: '100px',
                        height: '100%',
                        justifyContent: 'center',
                      }}
                      newPrimaryButton
                      onClick={() => setCookiePolicy(false)}
                    >
                      Accept all
                    </Button>
                  </div>
                </Small>
              </div>
            ) : (
              <></>
            )}
          </Bottom>
        </div>
        <SchoolPackageModal
          isOpen={this.state.isPackageOpen}
          match={match}
          handleModal={this.handleOpenPackageModal}
        />
        <SchoolTutorialModal
          menu={currentMenu}
          isOpen={isOpen}
          onSkip={this.handleSkipTutorial}
          schoolName={schoolName}
        >
          <Image src={images[currentMenu[0].toUpperCase() + currentMenu.substring(1)]} />
        </SchoolTutorialModal>
      </ErrorBoundary> //
    )
  }
}

TeacherSchoolLayout.defaultProps = {
  authorized: true,
}

TeacherSchoolLayout.propTypes = {
  children: PropTypes.element.isRequired,
  authorized: PropTypes.bool,
}
const selection = store.select((models) => ({
  isTeacher: models.AppUser.isTeacher,
}))
const mapState = (state) => {
  return {
    sidebarOpen: state.Sidebar.sidebarOpen,
    sidebarDocked: state.Sidebar.sidebarDocked,
    sidebarTeacherProfile: state.Sidebar.sidebarTeacherProfile,
    mySchoolDetail: state.MySchool.mySchoolDetail,
    schoolTutorial: state.AppUser.schoolTutorial,
    appUser: state.AppUser.appUser,
    cookiePolicy: state.AppUser.cookiePolicy,
    ...selection(state),
  }
}

const mapDispatch = (dispatch) => {
  return {
    toggle: dispatch.Sidebar.toggle,
    teacherSidebar: dispatch.Sidebar.teacherSidebar,
    mediaQueryChanged: dispatch.Sidebar.mediaQueryChanged,
    getMySchoolById: dispatch.MySchool.getMySchoolById,
    getSchoolPackages: dispatch.SchoolPackage.getSchoolPackages,
    setSkipSchoolTutorial: dispatch.AppUser.setSkipSchoolTutorial,
    fetchMe: dispatch.AppUser.fetchMe,
    updateProfile: dispatch.AppUser.updateProfile,
    setCookiePolicy: dispatch.AppUser.setCookiePolicy,
  }
}

export default connect(mapState, mapDispatch)(TeacherSchoolLayout)
