import React, { useState, useEffect } from 'react'
import { Table, ConfigProvider } from 'antd'
import { Container, EmptyWrapper } from './style'
import { Typography } from '../index'
import Sorter from './Sorter'
import EmptyImage from '../../images/EmptyFolder.svg'

const noData = () => {
  return (
    <EmptyWrapper>
      <div>
        <img src={EmptyImage} alt="empty" style={{ width: 150 }} />
        {/* <Typography id="label.nodata" h4 color="primary1">
          No data
        </Typography> */}
      </div>
    </EmptyWrapper>
  )
}

const AntTable = ({ columns, dataSource, pagination = false, bordered, ...props }) => {
  const [newColumns, setNewColumns] = useState([])
  const [newData, setNewData] = useState([])
  // console.log(newColumns)

  useEffect(() => {
    const n = columns.map(({ title, id, ...column }) => {
      const newTitle = column.customSorter ? (
        <Sorter
          title={title}
          sorter={column.customSorter}
          setNewData={setNewData}
          data={dataSource}
        />
      ) : (
        <Typography id={id || ''} color={props.headerColor ? props.headerColor : 'dark1'} bold>
          {title}
        </Typography>
      )
      return { title: newTitle, ...column }
    })
    // console.log('column', n)
    // console.log('dataSource', dataSource)
    setNewData(dataSource)
    setNewColumns(n)
  }, [dataSource, columns, props.headerColor])

  return (
    <Container bordered={bordered}>
      <ConfigProvider renderEmpty={noData}>
        <Table
          columns={newColumns}
          dataSource={newData}
          tableLayout="fixed"
          scroll={{ y: 1500, x: newColumns.length * 150 }}
          rowKey="id"
          pagination={pagination}
          {...props}
        />
      </ConfigProvider>
    </Container>
  )
}

export default AntTable
