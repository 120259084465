import React from 'react'
import { Typography } from '../../components'
import _ from 'lodash'
import LectureDone from '../../images/icons/CheckCircle.svg'
import VideoCamera from '../../images/icons/VideoCamera.svg'
//import FileTextIcon from '../../images/icons/FileText.svg'
import XCircle from '../../images/icons/XCircle.svg'
import PretestPostTest from '../../images/icons/PretestPostTest.svg'
import Icon from '../icon/loadable'
import styled from 'styled-components'
import ErrorBoundary from '../../components/errorBoundary'
import { connect } from 'react-redux'
import QuizIcon from '../../images/icons/PencilSimpleLine.svg'
import {
  //Desktop,
  DesktopAndTablet,
  // LargeDesktop,
  //Mobile,
  // MobileAndTablet,
  //SmallMobile,
} from '../../utils/displayResponsive'

const Logo = styled.div`
  background-color: #ebf4f9;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
`

const TeacherCourseEvaluationStudentPageLectureTab = ({ students: [item] }) => {
  if (!item) {
    return null
  }
  const {
    content: { lectures, preTest, quizzes, postTest },
  } = item
  const lecMap = lectures.map((lectures) => lectures.content)
  const LecQuiz = lecMap.concat(quizzes)
  const sortedLecQuiz = _.sortBy(LecQuiz, 'priority')
  // const sortedLectures = _.sortBy(lectures, 'content.priority')
  // const sortedQuiz = _.sortBy(quizzes, 'priority')
  console.log(sortedLecQuiz)
  return (
    <ErrorBoundary>
      <div>
        {preTest != undefined && (
          <Flex
            style={{
              justifyContent: 'space-between',
              padding: '16px 12px',
              borderBottom: '2px solid #F2F2F2',
            }}
          >
            <Flex style={{ width: '80%' }}>
              <Logo>
                <Icon src={PretestPostTest} />
              </Logo>
              <Typography numberOfLines={1} fontSize="text-sm" color="black100">
                {preTest.title}
              </Typography>
            </Flex>
            <div>
              {preTest.isPass === true ? (
                <Flex style={{ gap: '10px' }}>
                  <DesktopAndTablet>
                    <Typography fontSize="text-sm" color="primary1" bold>
                      Completed
                    </Typography>
                  </DesktopAndTablet>
                  <Icon style={{ width: '32px', height: '32px' }} src={LectureDone} />
                </Flex>
              ) : (
                <Flex style={{ gap: '10px' }}>
                  <DesktopAndTablet>
                    <Typography fontSize="text-sm" color="black60" bold>
                      Incompleted
                    </Typography>
                  </DesktopAndTablet>
                  <Icon src={XCircle} style={{ width: '32px', height: '32px' }} />
                </Flex>
              )}
            </div>
          </Flex>
        )}
        {/* {sortedLectures.map((lecture) =>
          lecture.content.type === 'paragraph' ? null : ( // </div> //   <Typography fontSize="text-base">{`${lecture.content.name}`}</Typography> // <div key={lecture.content.lectureId}>
            <div key={lecture.content.lectureId} style={{ borderBottom: '2px solid #F2F2F2' }}>
              <Flex style={{ justifyContent: 'space-between', padding: '16px 12px' }}>
                <Flex>
                  <Logo>
                    <Icon src={VideoCamera} />
                  </Logo>
                  <Typography fontSize="text-base">{lecture.content.name}</Typography>
                </Flex>
                <div>
                  {lecture.content.progress === 1 ? (
                    <Flex style={{ gap: '10px' }}>
                      <Typography fontSize="text-base" color="primary1" bold>
                        Completed
                      </Typography>
                      <Icon style={{ width: '32px', height: '32px' }} src={LectureDone} />
                    </Flex>
                  ) : (
                    <Flex style={{ gap: '10px' }}>
                      <Typography fontSize="text-base" color="black60" bold>
                        Incompleted
                      </Typography>
                      <Icon src={XCircle} style={{ width: '32px', height: '32px' }} />
                    </Flex>
                  )}
                </div>
              </Flex>
            </div>
          )
        )}
        {sortedQuiz.map((quizzes) => (
          <div key={quizzes.id} style={{ borderBottom: '2px solid #F2F2F2' }}>
            <Flex style={{ justifyContent: 'space-between', padding: '16px 12px' }}>
              <Flex>
                <Logo>
                  <Icon src={FileTextIcon} />
                </Logo>
                <Typography fontSize="text-base">{quizzes.title}</Typography>
              </Flex>
              <div>
                {quizzes.isPass === true ? (
                  <Flex style={{ gap: '10px' }}>
                    <Typography fontSize="text-base" color="primary1" bold>
                      Completed
                    </Typography>
                    <Icon style={{ width: '32px', height: '32px' }} src={LectureDone} />
                  </Flex>
                ) : (
                  <Flex style={{ gap: '10px' }}>
                    <Typography fontSize="text-base" color="black60" bold>
                      Incompleted
                    </Typography>
                    <Icon src={XCircle} style={{ width: '32px', height: '32px' }} />
                  </Flex>
                )}
              </div>
            </Flex>
          </div>
        ))} */}
        {sortedLecQuiz.map((LecQuiz) =>
          LecQuiz.type === 'paragraph' ? (
            <div key={LecQuiz.id} style={{ borderBottom: '2px solid #F2F2F2' }}>
              <Flex style={{ justifyContent: 'space-between', padding: '16px 12px' }}>
                <Flex style={{ width: '80%' }}>
                  {/* <Logo>
                    <Icon src={QuizIcon} />
                  </Logo> */}
                  <Typography numberOfLines={1} bold fontSize="text-base" color="primary1">
                    {LecQuiz.name}
                  </Typography>
                </Flex>
              </Flex>
            </div>
          ) : (
            <>
              {LecQuiz.type === 'quiz' ? (
                <div key={LecQuiz.id} style={{ borderBottom: '2px solid #F2F2F2' }}>
                  <Flex style={{ justifyContent: 'space-between', padding: '16px 12px' }}>
                    <Flex style={{ width: '80%' }}>
                      <Logo>
                        <Icon src={QuizIcon} />
                      </Logo>
                      <Typography numberOfLines={1} fontSize="text-sm" color="black100">
                        {LecQuiz.title}
                      </Typography>
                    </Flex>
                    <div>
                      {LecQuiz.isPass === true ? (
                        <Flex style={{ gap: '10px' }}>
                          <DesktopAndTablet>
                            <Typography fontSize="text-sm" color="primary1" bold>
                              Completed
                            </Typography>
                          </DesktopAndTablet>
                          <Icon style={{ width: '32px', height: '32px' }} src={LectureDone} />
                        </Flex>
                      ) : (
                        <Flex style={{ gap: '10px' }}>
                          <DesktopAndTablet>
                            <Typography fontSize="text-sm" color="black60" bold>
                              Incompleted
                            </Typography>
                          </DesktopAndTablet>
                          <Icon src={XCircle} style={{ width: '32px', height: '32px' }} />
                        </Flex>
                      )}
                    </div>
                  </Flex>
                </div>
              ) : (
                <div key={LecQuiz.lectureId} style={{ borderBottom: '2px solid #F2F2F2' }}>
                  <Flex style={{ justifyContent: 'space-between', padding: '16px 12px' }}>
                    <Flex style={{ width: '80%' }}>
                      <Logo>
                        <Icon src={VideoCamera} />
                      </Logo>
                      <Typography numberOfLines={1} fontSize="text-sm" color="black100">
                        {LecQuiz.name}
                      </Typography>
                    </Flex>
                    <div>
                      {LecQuiz.progress === 1 ? (
                        <Flex style={{ gap: '10px' }}>
                          <DesktopAndTablet>
                            <Typography fontSize="text-sm" color="primary1" bold>
                              Completed
                            </Typography>
                          </DesktopAndTablet>
                          <Icon style={{ width: '32px', height: '32px' }} src={LectureDone} />
                        </Flex>
                      ) : (
                        <Flex style={{ gap: '10px' }}>
                          <DesktopAndTablet>
                            <Typography fontSize="text-sm" color="black60" bold>
                              Incompleted
                            </Typography>
                          </DesktopAndTablet>
                          <Icon src={XCircle} style={{ width: '32px', height: '32px' }} />
                        </Flex>
                      )}
                    </div>
                  </Flex>
                </div>
              )}
            </>
          )
        )}
        {postTest != undefined && (
          <Flex style={{ justifyContent: 'space-between', padding: '16px 12px' }}>
            <Flex style={{ width: '80%' }}>
              <Logo>
                <Icon src={PretestPostTest} />
              </Logo>
              <Typography numberOfLines={1} fontSize="text-sm" color="black100">
                {postTest.title}
              </Typography>
            </Flex>
            <div>
              {postTest.isPass === true ? (
                <Flex style={{ gap: '10px' }}>
                  <DesktopAndTablet>
                    <Typography fontSize="text-sm" color="primary1" bold>
                      Completed
                    </Typography>
                  </DesktopAndTablet>
                  <Icon style={{ width: '32px', height: '32px' }} src={LectureDone} />
                </Flex>
              ) : (
                <Flex style={{ gap: '10px' }}>
                  <DesktopAndTablet>
                    <Typography fontSize="text-sm" color="black60" bold>
                      Incompleted
                    </Typography>
                  </DesktopAndTablet>
                  <Icon src={XCircle} style={{ width: '32px', height: '32px' }} />
                </Flex>
              )}
            </div>
          </Flex>
        )}
      </div>
    </ErrorBoundary>
  )
}

const mapState = (state) => ({
  students: state.teachingCourse.students,
})

export default connect(mapState, null)(TeacherCourseEvaluationStudentPageLectureTab)
