export const ACCESS_TOKEN = 'accessToken'

export const courseTypes = {
  online: 'Online',
  classroom: 'Classroom',
}

export const questionType = {
  'choice question': 'Single Choice',
  // 'True/False': 'True or false',
  'Fill a word/letter': 'Short answer',
  Essay: 'Paragraph',
  File: 'File',
  // 'content': 'Content',
}

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
