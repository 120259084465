import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import { UserAPI, AssignmentsAPI, StudentAssignmentAPI } from '../../api'

export const MyAssignment = {
  state: {
    myAssignmentList: [],
    myAssignment: {},
    myStudentAssignment: {},
    assignmentId: '',
    questions: [],
  },
  reducers: {
    setMyAssignmentList(state, payload) {
      return {
        ...state,
        myAssignmentList: payload,
      }
    },
    setMyAssignment(state, payload) {
      return {
        ...state,
        myAssignment: payload,
      }
    },
    setMyStudentAssignment(state, payload) {
      return {
        ...state,
        myStudentAssignment: payload,
      }
    },
    setAssignmentId(state, payload) {
      return {
        ...state,
        assignmentId: payload,
      }
    },
  },
  effects: () => ({
    async getMyAssignmentList() {
      const filter = JSON.stringify({
        include: 'assignmentWorksheets',
      })
      const list = await UserAPI.getStudentAssignments({ filter })
      this.setMyAssignmentList(list)
    },
    async getMyAssignmentListByCourse({ courseId }) {
      const filter = JSON.stringify({
        include: 'assignmentWorksheets',
      })
      const list = await UserAPI.getStudentAssignments({ courseId, filter })
      console.log('getMyAssignmentList❌', list)
      this.setMyAssignmentList(list)
    },
    async getMyAssignmentById(assignmentId) {
      const assignment = await UserAPI.getMyAssignmentById({ id: assignmentId })
      this.setMyAssignment(assignment)
      return assignment
    },
    async updateMyAssignmentById(payload) {
      await AssignmentsAPI.update(payload)
    },
    async getStudentAssignmentsById(assignmentId) {
      const studentAssignment = await StudentAssignmentAPI.find()
      const res = studentAssignment.filter((d) => d.assignmentId === assignmentId)
      this.setMyStudentAssignment(res)
    },
    async createMyAssignment(payload) {
      const { id } = await AssignmentsAPI.create(payload)
      this.setAssignmentId(id)
    },
  }),
  selectors: () => ({
    questions() {
      return (rootState) => {
        const questions = get(
          rootState.MyAssignment,
          'myAssignment.assignmentWorksheets[0].publishedWorksheet.data.questions',
          []
        )
        const result = sortBy(questions, ['priority']).map((item) => ({
          ...item,
          assignmentId: get(rootState.MyAssignment, 'myAssignment.id'),
        }))
        return result
      }
    },
    answers() {
      return (rootState, props) => {
        const answers = get(rootState.MyAnswer, `myAnswers.${props.assignmentId}`, {})
        const questions = this.questions(rootState)
        const result = questions
          .filter((item) => item.type !== 'content')
          .map((item) => ({
            ...item,
            answers: get(answers, `${item.id}.answers`, []),
            finish: get(answers, `${item.id}.answers`, []).length > 0,
          }))
        return result
      }
    },
  }),
}
