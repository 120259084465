/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Typography } from 'components'
import Icon from '../../../../components/icon/loadable'
import Avatar from '../../../../components/avatar/loadable'
import IconClock from '../../../../images/icons/black-clock.svg'
import IconLecture from '../../../../images/icons/DarkIcon/Black-lecture.svg'
import IconShare from '../../../../images/icons/DarkIcon/Dark-Share.svg'
import IconCloud from '../../../../images/icons/black-cloud.svg'
import IconClassroom from '../../../../images/icons/black-classroom.svg'
import LectureDetail from './LectureDetail'
import Discussion from './Discussion'
import DetailContentMobile from './DetailContentMobile'
import ShareModal from 'containers/courseDetail/ShareModal'
const Container = styled.div`
  //min-height: 100%;
  width: 100%;
  //padding: 16px;
  background-color: #222223;
  //padding: 16px 16px 16px 16px;
`
const IconWithText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  //width: 100px;
  color: ${(props) => (props.primary ? props.theme.primary2 : props.theme.grey)};
  font-size: ${({ theme }) => theme['text-xs']};
  img {
    margin-right: 0px;
  }
  ${({ start }) => {
    if (start) {
      return css`
        justify-content: flex-start;
      `
    }
  }}
  .after {
    // position: relative;
    // padding: 0 16px 0 0;
  }
  .after:after {
    content: '';
    position: absolute;
    height: 15px;
    background-color: ${(props) => props.theme.gray6};
    top: 3px;
    right: 8px;
  }

  &.mode img {
    filter: invert(40%);
  }
`
const Hilight = styled(Typography)`
  ${({ schoolLink }) => {
    if (schoolLink) {
      return css`
        cursor: pointer;
      `
    }
    return css``
  }}
  min-width: 120px;
  max-width: 120px;
  padding-left: 10px;

  &.school {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (max-width: 500px) {
    min-width: 100px;
  }

  @media (max-width: 400px) {
    min-width: 80px;
  }
`
function DetailMobile(props) {
  const {
    title,
    teacherName,
    shortDescription,
    estimateTimeUsageHr,
    estimateTimeUsageMin,
    lectureNum,
    type,
    courseId,
    data,
    preTest,
    postTest,
    courseSlug,
    locale,
    courseProject,
  } = props
  const courseTypes = {
    online: 'Online',
    classroom: 'Classroom',
  }

  const [openModal, setOpenModal] = useState(false)

  const url = `https://${window.location.host}/courses/${courseSlug}`
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div style={{ padding: '16px' }}>
          <Typography color="white" h3>
            {title}
          </Typography>
          <Typography color="white">โดย {teacherName}</Typography>
          <Typography color="white" style={{ paddingTop: '16px' }} numberOfLines={3}>
            {shortDescription}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '16px 0px 0px 0px',
              margin: '0px 0px 18px 0px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <IconWithText primary style={{ marginRight: '15px' }}>
                <Icon src={IconClock} />
                <Typography
                  id="card.course.total.hour"
                  values={{ hourCount: estimateTimeUsageHr }}
                  color="white"
                  fontSize="text-xs"
                >
                  {estimateTimeUsageHr} ชั่วโมง
                </Typography>
                <Typography
                  id="card.course.total.min"
                  values={{ minCount: estimateTimeUsageMin }}
                  color="white"
                  fontSize="text-xs"
                >
                  {estimateTimeUsageMin} นาที
                </Typography>
              </IconWithText>

              <IconWithText primary style={{ marginRight: '15px' }}>
                {type === 'online' ? (
                  <Icon src={IconCloud} alt="online-icon" />
                ) : (
                  <Icon src={IconClassroom} alt="online-icon" />
                )}
                <Typography id={`card.course.type.${type}`} color="white" fontSize="text-xs">
                  {courseTypes[type]}
                </Typography>
              </IconWithText>
              <IconWithText primary style={{ marginRight: '15px' }}>
                <Icon src={IconLecture} />
                <Typography
                  label2
                  className="after"
                  color="white"
                  id="card.course.total.lecture"
                  fontSize="text-xs"
                  values={{ lectureCount: courseProject?.full }}
                >
                  {courseProject?.full} Lectures
                </Typography>
              </IconWithText>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <IconWithText
                primary
                style={{ marginRight: '15px' }}
                onClick={() => setOpenModal(true)}
              >
                <Icon src={IconShare} />
                <Typography color="white" fontSize="text-xs">
                  {locale === 'en' ? 'share' : 'แชร์'}
                </Typography>
              </IconWithText>
              <ShareModal
                isOpen={openModal}
                url={url}
                setOpenModal={setOpenModal}
                locale={locale}
              />
            </div>
          </div>
        </div>
        <DetailContentMobile
          data={data}
          preTest={preTest}
          postTest={postTest}
          courseId={courseId}
        />
      </div>
    </Container>
  )
}

export default withRouter(DetailMobile)
