import Resource from '../utils/resource'

export default new Resource('/Enrollments', {
  setStatus: {
    url: '{id}',
    method: 'patch',
  },
  delete: {
    url: '{id}',
    method: 'delete',
  },
  approve: {
    url: '{id}/approve',
    method: 'post',
  },
})
