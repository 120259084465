import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { fabric } from 'fabric'
// import { useWindowSize } from 'react-use'
import getImageUrl from 'utils/getImageUrl'
import { connect } from 'react-redux'

import reactImageSize from 'react-image-size'
import theme from 'theme'

const PointOnImagePreview = ({
  image,
  id,
  answers,
  isResult,
  // isCorrect,
  checkedAns,
  quizAnswers = [],
  isAnswer = false,
  styleWidth,
}) => {
  const [canvas, setCanvas] = useState('')
  // const [imageSize, setImageSize] = useState({})
  // const { width } = useWindowSize()
  // const isMobile = width < 427
  // const multiplier = useCallback(() => {
  //   if (isMobile) {
  //     const currentImage = width - 62
  //     return currentImage / 533
  //   }
  //   return 1
  // }, [isMobile, width])
  // const radius = isMobile ? 30 * multiplier() : 30
  // const scale = isMobile ? multiplier() : 1
  const scale = useMemo(() => {
    if (styleWidth) {
      return styleWidth / 711
    }
    return 1
  }, [styleWidth])
  const radius = 30 * scale

  const dynamicCanvas = useCallback(
    async (image) => {
      if (image) {
        const { width, height } = await reactImageSize(getImageUrl(image))
        const w = width * scale
        const h = height * scale
        setCanvas(initCanvas(w, h))
      }
    },
    [initCanvas, scale]
  )

  const initCanvas = useCallback(
    (width, height) =>
      new fabric.Canvas(id, {
        height,
        width,
        selection: false,
        backgroundColor: 'transparent',
      }),
    [id]
  )
  useEffect(() => {
    dynamicCanvas(image)
  }, [dynamicCanvas, image])

  useEffect(() => {
    const initImage = () => {
      if (canvas) {
        canvas.remove(...canvas.getObjects())
        fabric.Image.fromURL(getImageUrl(image), function (img) {
          img.set({ left: 0, top: 0 }).scale(scale)

          canvas.setBackgroundImage(img)

          canvas?.renderAll()
        })
      }
      if (isAnswer && quizAnswers?.length > 0) {
        canvas?.remove?.(...canvas?.getObjects?.())

        let circle
        for (let i = 0; i <= quizAnswers?.length - 1; i++) {
          let pointer = quizAnswers[i]

          circle = new fabric.Circle({
            left: pointer.x * scale,
            top: pointer.y * scale,
            radius,
            strokeWidth: 5,

            fill: 'transparent',
            stroke: theme.success2,
            selectable: false,
            originX: 'center',
            originY: 'center',
          })
          canvas?.add?.(circle)
        }
      }
      if (isResult && checkedAns?.length > 0 && quizAnswers.length === 0) {
        canvas?.remove?.(...canvas?.getObjects?.())

        let circle
        for (let i = 0; i <= checkedAns?.length - 1; i++) {
          const { point, isCorrect } = checkedAns[i]

          circle = new fabric.Circle({
            left: point.x * scale,
            top: point.y * scale,
            radius,
            strokeWidth: 5,

            fill: 'transparent',
            stroke: isCorrect ? theme.primary : theme.danger,
            selectable: false,
            originX: 'center',
            originY: 'center',
          })
          canvas?.add?.(circle)
        }
      } else if (answers?.length > 0 && !isAnswer && quizAnswers?.length === 0) {
        canvas?.remove?.(...canvas?.getObjects?.())

        let circle
        for (let i = 0; i <= answers?.length - 1; i++) {
          let pointer = answers[i]

          circle = new fabric.Circle({
            left: pointer.x * scale,
            top: pointer.y * scale,
            radius,
            strokeWidth: 5,

            fill: 'transparent',
            stroke: theme.primary,
            selectable: false,
            originX: 'center',
            originY: 'center',
          })
          canvas?.add?.(circle)
        }
      }
    }
    initImage()
  }, [canvas, image, answers, checkedAns, isResult, scale, radius, isAnswer, quizAnswers])

  useEffect(() => {
    return () => {
      setCanvas('')
    }
  }, [])

  // useEffect(() => {
  //   reactImageSize(getImageUrl(image)).then(({ width, height }) => {
  //     setImageSize({ width, height })
  //   })
  // }, [image])

  return (
    <div
      style={{
        marginTop: 0,
        paddingLeft: 0,

        // marginTop: isCorrect ? 24 : 0,
        // paddingLeft: isCorrect ? 35 : 0,
        // background: isCorrect && `${theme.danger}0D`,
      }}
    >
      <canvas id={id} />
    </div>
  )
}
const mapState = null
export default connect(mapState)(PointOnImagePreview)
