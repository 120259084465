/* eslint-disable no-unused-vars */
import React from 'react'
import { Redirect } from 'react-router-dom'

import TeacherSchoolLayout from 'layouts/teacherSchoolLayout'
import SettingCourseLayout from 'layouts/settingCourseLayout'
// import SettingCourseSingleTestLayout from 'layouts/settingCourseSingleTestLayout/loadable'
import TeacherCourseLiveTab from 'containers/teacherCourseLiveTab/loadable'
import CourseCreateAssessment from 'containers/courseCreateAssessment/loadable'
import CourseAssessmentTab from 'containers/courseAssessmentTab/loadable'
import CourseAssessmentEditQuestionTab from 'containers/courseAssessmentEditQuestionTab/loadable'
import TeacherCourseLiveCreate from 'containers/teacherCourseLiveCreate/loadable'
import SchoolRewardsLayout from 'layouts/schoolRewardsLayout/loadable'
import SchoolAllRewardsPage from 'containers/schoolAllRewardsPage/loadable'
import SchoolRedeemPage from 'containers/schoolRedeemPage/loadable'
import SchoolCurriculumLayout from 'layouts/schoolCurriculumLayout/loadable'
import SchoolAllCoursePage from 'containers/schoolAllCoursePage/loadable'
import SchoolAllMarketplacePage from 'containers/schoolAllMarketplacePage/loadable'
import schoolAllMarketplacePage1 from 'containers/schoolAllMarketplacePage-1/loadable'
import schoolPaymentMarketplacePage from 'containers/schoolPaymentMarketplacePage/loadable'
// import SchoolYouMarketplacePage from 'containers/schoolYouMarketplacePage/loadable'
import SchoolAssignedCoursePage from 'containers/schoolAssignedCoursePage/loadable'
import SchoolCurriculumDetail from 'components/schoolCurriculumDetail/loadable'
import CurriculumDetailEditLayout from 'layouts/curriculumDetailEditLayout'
import InformationPage from 'containers/informationPage/loadable'
import SettingPage from 'containers/settingPage/loadable'
import SchoolInformationLayout from 'layouts/schoolInformationLayout/loadable'
import SchoolInformationAnnouncement from 'containers/schoolInformationAnnouncement/loadable'
import SchoolInformationActivities from 'containers/schoolInformationActivities/loadable'
import SchoolInformationCourses from 'containers/schoolInformationCourses/loadable'
import SchoolInformationAbout from 'containers/schoolInformationAbout/loadable'
import SchoolCertificateLayout from 'layouts/schoolCertificateLayout/loadable'
// import TemplatePage from 'containers/templatePage/loadable'
// import AllCertificatePage from 'containers/allCertificatePage/loadable'
// import BlockchainPage from 'containers/blockchainPage/loadable'
import SchoolSettingLayout from 'layouts/schoolSettingLayout'
import SchoolSettingInformation from 'containers/schoolSettingInformation/loadable'
import SchoolSettingSetting from 'containers/schoolSettingSetting/loadable'
import SchoolSettingAcademy from 'containers/schoolSettingAcademy/loadable'
import SchoolDashboardLayout from 'layouts/schoolDashboardLayout'
import SchoolDashboardOverview from 'containers/schoolDashboardOverview/loadable'
// import ReportCourseList from 'containers/reportCourseList/loadable'
import SchoolPaymentLayout from 'layouts/schoolPaymentLayout'
import SchoolKyc from 'containers/schoolKycPage/loadable'
import SchoolPaymentOverview from 'containers/schoolPaymentOverview/loadable'
import SchoolPaymentTransferHistory from 'containers/schoolPaymentTransferHistory/loadable'
import SchoolPaymentTransferDetail from 'containers/schoolPaymentTransferDetail/loadable'
import SchoolPaymentTransaction from 'containers/schoolPaymentTransaction/loadable'
import SchoolPaymentPackageCheckout from 'containers/schoolPackageCheckoutPage/loadable'
// import SchoolPaymentWithdraw from 'containers/schoolPaymentWithdraw/loadable'
import SchoolPaymentBankAccount from 'containers/schoolPaymentBankAccount/loadable'
import SchoolCourseAssignmentLayout from 'layouts/schoolCourseAssignmentLayout'
// import SchoolCourseLiveLayout from 'layouts/schoolCourseLiveLayout'
import SchoolMemberLayout from 'layouts/schoolMemberLayout'
import SchoolMarketplaceLayout from 'layouts/schoolMarketplaceLayout/loadable'
import SchoolMemberTeacherList from 'containers/schoolMemberTeacherList/loadable'
import SchoolMemberStudentList from 'containers/schoolMemberStudentList/loadable'
// import SchoolMemberGroup from 'containers/schoolMemberGroup/loadable'
import SchoolMemberGroups from 'containers/SchoolMemberGroups/loadable'
import SchoolMemberGroupsListInClass from 'containers/schoolMemberGroupsListInClass/loadable'
import SchoolMemberTags from 'containers/schoolMemberTags/loadable'
// import SchoolGroupPage from 'containers/schoolGroupPage/loadable'
import SchoolGroupEdit from 'containers/schoolGroupEdit/loadable'
import SchoolMemberProfileLayout from 'layouts/schoolMemberProfileLayout/loadable'
import MemberProfilePage from 'containers/memberProfilePage/loadable'
import MemberProgressPage from 'containers/memberProgressPage/loadable'
import SchoolGroupLayout from 'layouts/schoolGroupLayout/loadable'
import SchoolMemberGroupMember from 'containers/schoolMemberGroupMember/loadable'
import SchoolMemberGroupCourse from 'containers/schoolMemberGroupCourse/loadable'
import PublishCourseInformationPage from 'containers/courseInformationPage/publishedCourseInformationPage'
import PublishedCourseContentPage from 'containers/courseContentPage/publishedCourseContentPage'
import PublishedCourseInformationPage from 'containers/courseInstructorPage/publishedCourseInstructorPage'
import PublishedCoursePricePage from 'containers/coursePricePage/publishedCoursePricePage'
import PublishedCourseSettingPage from 'containers/courseSettingPage/publishedCourseSettingPage'
import CoursePricePage from 'containers/coursePricePage/loadable'
import CourseContentPage from 'containers/courseContentPage'
import CourseDescriptionTab from 'containers/courseDescriptionTab/loadable'
import MarketplaceDescriptionTab from 'containers/marketplaceDescriptionTab/loadable'
import TestOptionsModal from 'modals/testOptionsModal/loadable'
// import ContentPreviewModal from 'modals/contentPreviewModal/loadable'
import ContentPreviewModal from 'modals/contentPreviewModal/loadable'
import SchoolReportLayout from 'layouts/schoolReportLayout/loadable'
import SchoolWeeklyReportTab from 'containers/schoolWeeklyReportTab/loadable'
import TeacherCourseAssignmentOverviewTab from 'containers/teacherCourseAssignmentOverviewTab/loadable'
import TeacherCourseAssignmentSettingTab from 'containers/teacherCourseAssignmentSettingTab/loadable'
import TeacherCourseAssignmentReportTab from 'containers/teacherCourseAssignmentReportTab/loadable'
// import TeacherCourseAssignmentCreate from 'containers/teacherCourseAssignmentCreate/loadable'
import TeacherCourseDraftAssignmentLayout from 'containers/teacherCourseDraftAssignmentLayout/loadable'
import DraftAssignInfoPage from 'containers/draftAssignInfoPage/loadable'
import DraftAssignmentQuestionPage from 'containers/draftAssignmentQuestionPage/loadable'
import TeacherCourseAssignmentViewAnswer from 'containers/teacherCourseAssignmentViewAnswer/loadable'
import MyCourseCreateTeacherCourse from 'containers/myCourseCreateTeacherCourse/loadable'
import TeacherCourseLectureTestPreview from 'containers/teacherCourseLectureTestPreview/loadable'
import EvaluationSettingPage from 'containers/evaluationSettingPage/loadable'
import TeacherCourseEvaluationStudentPage from 'containers/teacherCourseEvaluationStudentPage/loadable'
import TeacherCreateCourseLecturePage from 'containers/teacherCreateCourseLecturePage/loadable'
import TeacherCourseLectureContent from 'components/teacherCourseLectureContent/loadable'
import TeacherSubjectAssessmentView from 'layouts/teacherSubjectAssessmentViewLayout/loadable'
import TeacherSubjectAssessmentViewInfo from 'containers/teacherSubjectAssessmentViewInfo/loadable'
import TeacherCourseDetailLayout from 'layouts/teacherCourseDetailLayout'
import TeacherMarketplaceDetailLayout from 'layouts/teacherMarketplaceDetailLayout'
import TeacherCourseAssignmentTab from 'containers/teacherCourseAssignmentTab/loadable'
import TeacherCourseDiscussionTab from 'containers/teacherCourseDiscussionTab/loadable'
import TeacherCourseGradesTab from 'containers/teacherCourseGradesTab/loadable'
import TeacherCourseReportTab from 'containers/teacherCourseReportTab/loadable'
import TeacherCourseMemberTab from 'containers/teacherCourseMemberTab/loadable'
import CreateCourseLayout from 'layouts/createCourseLayout/loadable'
import CourseInformationPage from 'containers/courseInformationPage/loadable'

import CoursePreviewLayout from 'layouts/coursePreviewLayout/loadable'
import CoursePreviewLectureLayout from 'components/coursePreviewLectureLayout/loadable'
import CoursePreviewLectureContent from 'components/coursePreviewLectureContent/loadable'

import CoursePreviewLectureTab from 'containers/CoursePreviewLectureTab/loadable'
import CoursePreviewDiscussionTab from 'containers/CoursePreviewDiscussionTab/loadable'

// Preview Test
import PreviewTestLayout from 'layouts/previewTestLayout'
import TeacherTestPreviewPage from 'containers/teacherTestPreviewPage/loadable'

import SchoolProgramCertificateLayout from 'layouts/schoolProgramCertificateLayout/loadable'
import SchoolProgramCertificateListPage from 'containers/schoolProgramCertificateListPage/loadable'
import SchoolProgramCertificateDetailPage from 'containers/schoolProgramCertificateDetailPage/loadable'
import SchoolProgramCertificateTemplatePage from 'containers/schoolProgramCertificateTemplatePage/loadable'
import PermissionSetting from 'components/permissionSetting'

import SchoolPackagesLayout from 'layouts/schoolPackagesLayout'
import SchoolPackagesOverview from 'containers/schoolPackagesOverview/loadable'
import SchoolPackagesHistory from 'containers/schoolPackagesHistory/loadable'

import TeacherCourseEvaluationStudentPageLectureTab from '../components/TeacherCourseEvaluationStudentPageLectureTab'
import TeacherCourseEvaluationStudentPageAssignTab from '../components/TeacherCourseEvaluationStudentPageAssignTab'

// import PublishCourseSettingSingleTestPage from 'containers/singleTestPage/courseSettingSingleTest/publishedCourseSettingSingleTest'

const schoolRoutes = {
  path: '/schools/:schoolId',
  layout: TeacherSchoolLayout,
  routes: [
    {
      exact: true,
      subpath: '/',
      render: ({ match }) => <Redirect to={`${match.url}/dashboard`} />,
    },

    // {
    //   path: '/schools/:schoolId/report/report/:reportId',
    //   isPrivate: true,
    //   exact: true,
    //   component: ReportCourseList,
    // },
    {
      path: '/schools/:schoolId/report',
      layout: SchoolReportLayout,
      routes: [
        {
          isPrivate: true,
          exact: true,
          subpath: '/',
          component: SchoolWeeklyReportTab,
        },
      ],
    },
    {
      path: '/schools/:schoolId/dashboard',
      layout: SchoolDashboardLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/overview`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/overview',
          component: SchoolDashboardOverview,
        },
      ],
    },
    {
      subpath: '/checkout/package/:packageId',
      component: SchoolPaymentPackageCheckout,
      exact: true,
      isPrivate: true,
    },
    {
      subpath: '/information/edit',
      layout: SchoolSettingLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/setting`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/setting',
          component: SchoolSettingSetting,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/academy',
          component: SchoolSettingAcademy,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/information',
          component: SchoolSettingInformation,
        },
      ],
    },
    {
      path: '/schools/:schoolId/information',
      layout: SchoolInformationLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/announcement`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/announcement',
          component: SchoolInformationAnnouncement,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/activities',
          component: SchoolInformationActivities,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/courses',
          component: SchoolInformationCourses,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/about',
          component: SchoolInformationAbout,
        },
      ],
    },
    {
      isPrivate: true,
      exact: true,
      subpath: '/curriculum/new',
      component: MyCourseCreateTeacherCourse,
    },

    // ! course management
    {
      isPrivate: true,
      exact: true,
      subpath: '/curriculum/manage/:courseId/lecture/:lectureId/content/:rank/test/:testId',
      component: TeacherCourseLectureTestPreview,
    },
    // TODO --> replace this with (Published)CourseContentPage
    {
      isPrivate: true,
      exact: true,
      subpath: '/curriculum/manage/:courseId/lecture/new',
      component: TeacherCreateCourseLecturePage,
    },
    // TODO --> replace this with (Published)CourseContentPage
    {
      isPrivate: true,
      exact: true,
      subpath: '/curriculum/manage/:courseId/lecture/:lectureId',

      component: TeacherCourseLectureContent,
    },
    // ! Evaluation
    {
      isPrivate: true,
      exact: true,
      subpath: '/curriculum/manage/:courseId/evaluation/setting',
      component: EvaluationSettingPage,
    },
    {
      subpath: '/curriculum/manage/:courseId/evaluation/:studentId',
      layout: TeacherCourseEvaluationStudentPage,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/lecture`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/lecture',
          component: TeacherCourseEvaluationStudentPageLectureTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/assignment',
          component: TeacherCourseEvaluationStudentPageAssignTab,
        },
      ],
    },
    // ! Assignment (create assignment)
    // {
    //   isPrivate: true,
    //   exact: true,
    //   subpath: '/curriculum/manage/:courseId/assignment/new',
    //   component: TeacherCourseAssignmentCreate,
    // },
    {
      // isPrivate: true,
      // exact: true,
      subpath: '/curriculum/manage/:courseId/assignment/draft/:draftAssignmentId',
      layout: TeacherCourseDraftAssignmentLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/information`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/information',
          component: DraftAssignInfoPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/questions',
          component: DraftAssignmentQuestionPage,
        },
      ],
    },
    {
      // isPrivate: true,
      exact: true,
      subpath: '/curriculum/manage/:courseId/live-new',
      component: TeacherCourseLiveCreate,
    },
    // ! Assignment (student assignment answer)
    {
      isPrivate: true,
      exact: true,
      subpath: '/curriculum/manage/:courseId/assignment/:assignmentId/student/:studentId',
      component: TeacherCourseAssignmentViewAnswer,
    },
    {
      isPrivate: true,
      exact: true,
      path: '/curriculum/manage/:courseId/evaluation/setting',
      component: EvaluationSettingPage,
    },
    // ! Course assignment
    {
      subpath: '/curriculum/manage/:courseId/assignment/:assignmentId',
      // layout: CurriculumDetailEditLayout,
      layout: SchoolCourseAssignmentLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/overview`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/overview',
          component: TeacherCourseAssignmentOverviewTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/setting',
          component: TeacherCourseAssignmentSettingTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/report',
          component: TeacherCourseAssignmentReportTab,
        },
      ],
    },
    {
      exact: true,
      isPrivate: true,
      subpath: '/curriculum/manage/:courseId/assessment/new',
      component: CourseCreateAssessment,
    },
    {
      subpath: '/curriculum/manage/:courseId/assessment/:assessmentId',
      layout: TeacherSubjectAssessmentView,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/information`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/information',
          component: TeacherSubjectAssessmentViewInfo,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/questions',
          // component: TeacherSubjectAssessmentViewQuestionsTab,
          component: CourseAssessmentEditQuestionTab,
        },
      ],
    },
    {
      subpath: '/curriculum/manage/:courseId',
      layout: TeacherCourseDetailLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/lecture`} />,
        },
        {
          subpath: '/lecture',
          layout: CourseDescriptionTab,
          routes: [
            {
              isPrivate: true,
              exact: true,
              subpath: '/',
              component: React.Fragment,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: '/modals/test-options/:testId',
              component: TestOptionsModal,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: '/modals/content-preview/:lectureId',
              component: ContentPreviewModal,
            },
          ],
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/assignment',
          component: TeacherCourseAssignmentTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/assessment',
          component: CourseAssessmentTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/discussion',
          component: TeacherCourseDiscussionTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/live',
          component: TeacherCourseLiveTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/members',
          component: TeacherCourseMemberTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/report',
          component: TeacherCourseReportTab,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/evaluation',
          component: TeacherCourseGradesTab,
        },
      ],
    },
    {
      subpath: '/curriculum',
      layout: SchoolCurriculumLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/school-courses`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/school-courses',
          component: SchoolAllCoursePage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/assigned',
          // component: TeacherCoursesPage,
          component: SchoolAssignedCoursePage,
        },
      ],
    },

    {
      isPrivate: true,
      exact: true,
      subpath: '/curriculum-detail/:courseId',
      component: SchoolCurriculumDetail, // <-- Course Preview
    },
    // ! Edit Publish Course
    {
      subpath: '/curriculum-detail/:courseId/edit',
      layout: CreateCourseLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/course-information`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/course-information',
          component: CourseInformationPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/setting',
          component: SettingPage,
        },
      ],
    },
    {
      subpath: '/curriculum-detail/:courseId',
      layout: CurriculumDetailEditLayout,
      routes: [
        {
          isPrivate: true,
          exact: true,
          subpath: '/information',
          component: InformationPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/setting',
          component: SettingPage,
        },
      ],
    },
    {
      subpath: '/rewards',
      layout: SchoolRewardsLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/all-rewards`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/all-rewards',
          component: SchoolAllRewardsPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/redeem',
          component: SchoolRedeemPage,
        },
      ],
    },
    {
      subpath: '/payment',
      layout: SchoolPaymentLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/overview`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/overview',
          component: SchoolPaymentOverview,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/kyc',
          component: SchoolKyc,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/transaction',
          component: SchoolPaymentTransaction,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/transfer-history',
          component: SchoolPaymentTransferHistory,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/transfer-history/:transferId',
          component: SchoolPaymentTransferDetail,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/bank-account',
          component: SchoolPaymentBankAccount,
        },
      ],
    },

    {
      subpath: '/certificate/program-certificate/:certificateId',
      layout: SchoolProgramCertificateLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/template`} />,
        },
        // {
        //   exact: true,
        //   subpath: '/recipient',
        //   component: SchoolProgramCertificateDetailPage,
        // },
        {
          isPrivate: true,
          exact: true,
          subpath: '/template',
          component: SchoolProgramCertificateTemplatePage,
        },
        {
          exact: true,
          subpath: '/courses',
          component: SchoolProgramCertificateDetailPage,
        },
      ],
    },
    {
      subpath: '/certificate',
      layout: SchoolCertificateLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/program-certificate`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/program-certificate',
          component: SchoolProgramCertificateListPage,
        },
      ],
    },
    {
      subpath: '/member/student/:memberId',
      layout: SchoolMemberProfileLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/profile`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/profile',
          component: MemberProfilePage,
        },

        {
          isPrivate: true,
          exact: true,
          subpath: '/progress',
          component: MemberProgressPage,
        },
      ],
    },
    {
      subpath: '/member/teacher/:memberId',
      layout: SchoolMemberProfileLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/profile`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/profile',
          component: MemberProfilePage,
        },
        // {
        //   isPrivate: true,
        //   exact: true,
        //   subpath: '/progress',
        //   component: MemberProgressPage,
        // },
      ],
    },
    {
      isPrivate: true,
      exact: true,
      subpath: '/member/group/:groupId/edit',
      component: SchoolGroupEdit,
    },
    {
      subpath: '/member/group/:groupId',
      layout: SchoolGroupLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/course`} />,
        },
        // {
        //   exact: true,
        //   subpath: '/',
        //   render: ({ match }) => <Redirect to={`${match.url}/discussion`} />,
        // },
        // {
        //   isPrivate: true,
        //   exact: true,
        //   subpath: '/discussion',
        //   component: () => <div>disscussion</div>,
        // },
        {
          isPrivate: true,
          exact: true,
          subpath: '/course',
          component: SchoolMemberGroupCourse,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/member',
          component: SchoolMemberGroupMember,
        },
      ],
    },
    {
      isPrivate: true,
      exact: true,
      subpath: '/member/:memberId/permission',
      component: PermissionSetting,
    },
    {
      subpath: '/member',
      layout: SchoolMemberLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/teacher`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/teacher',
          component: SchoolMemberTeacherList,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/student',
          component: SchoolMemberStudentList,
        },

        {
          isPrivate: true,
          exact: true,
          subpath: '/groups',
          component: SchoolMemberGroups,
        },

        {
          isPrivate: true,
          exact: true,
          // subpath: '/groups/listInclass',
          subpath: '/groups/listInclass/:groupId/:classId',
          component: SchoolMemberGroupsListInClass,
        },
        //mm
        // {
        //   isPrivate: true,
        //   exact: true,
        //   subpath: '/group',
        //   component: SchoolMemberGroup,
        // },
        {
          isPrivate: true,
          exact: true,
          subpath: '/tags',
          component: SchoolMemberTags,
        },
      ],
    },

    {
      subpath: '/packages',
      layout: SchoolPackagesLayout,
      routes: [
        {
          exact: true,
          subpath: '/',
          render: ({ match }) => <Redirect to={`${match.url}/overview`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/overview',
          component: SchoolPackagesOverview,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: '/history',
          component: SchoolPackagesHistory,
        },
      ],
    },

    // {
    //   subpath: '/marketplace',
    //   layout: SchoolMarketplaceLayout,
    //   routes: [
    //     {
    //       exact: true,
    //       subpath: '/',
    //       render: ({ match }) => <Redirect to={`${match.url}/all-course`} />,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/all-course',
    //       component: SchoolAllMarketplacePage,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/all-course-click/:courseId',
    //       component: schoolAllMarketplacePage1,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/payment-course/:courseId/:amount',
    //       component: schoolPaymentMarketplacePage,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/you-course',
    //       // component: TeacherCoursesPage,
    //       // component: SchoolYouMarketplacePage,
    //       component: SchoolAllMarketplacePage,
    //     },
    //   ],
    // },
    // {
    //   subpath: '/marketplace/manage/:courseId',
    //   layout: TeacherMarketplaceDetailLayout,
    //   routes: [
    //     {
    //       exact: true,
    //       subpath: '/',
    //       render: ({ match }) => <Redirect to={`${match.url}/lecture`} />,
    //     },
    //     {
    //       subpath: '/lecture',
    //       layout: MarketplaceDescriptionTab,
    //       routes: [
    //         {
    //           isPrivate: true,
    //           exact: true,
    //           subpath: '/',
    //           component: React.Fragment,
    //         },
    //         // {
    //         //   isPrivate: true,
    //         //   exact: true,
    //         //   subpath: '/modals/test-options/:testId',
    //         //   component: TestOptionsModal,
    //         // },
    //         // {
    //         //   isPrivate: true,
    //         //   exact: true,
    //         //   subpath: '/modals/content-preview/:lectureId',
    //         //   component: ContentPreviewModal,
    //         // },
    //       ],
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/assignment',
    //       component: TeacherCourseAssignmentTab,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/assessment',
    //       component: CourseAssessmentTab,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/discussion',
    //       component: TeacherCourseDiscussionTab,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/live',
    //       component: TeacherCourseLiveTab,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/members',
    //       component: TeacherCourseMemberTab,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/report',
    //       component: TeacherCourseReportTab,
    //     },
    //     {
    //       isPrivate: true,
    //       exact: true,
    //       subpath: '/evaluation',
    //       component: TeacherCourseGradesTab,
    //     },
    //   ],
    // },
  ],
}

export const coursePreviewRoutes = [
  // {
  //   path: '/schools/:schoolId/course-preview/:courseId/lecture/:lectureId',
  //   layout: CoursePreviewLayout,
  //   routes: [
  //     {
  //       isPrivate: true,
  //       exact: true,
  //       subpath: '/',
  //       component: CoursePreviewLectureContent,
  //     },
  //   ],
  // },
  {
    // path: '/schools/:schoolId/course-preview/:subjectId/course-draft/:courseId',
    path: '/schools/:schoolId/course-preview/:courseId',
    layout: CoursePreviewLayout,
    routes: [
      {
        exact: true,
        subpath: '/',
        render: ({ match }) => <Redirect to={`${match.url}/lecture`} />,
      },
      {
        isPrivate: true,
        exact: true,
        subpath: '/lecture',
        component: CoursePreviewLectureTab,
      },
      // {
      //   isPrivate: true,
      //   exact: true,
      //   subpath: '/discussion',
      //   component: CoursePreviewDiscussionTab,
      // },
      // {
      //   isPrivate: true,
      //   exact: true,
      //   subpath: '/discussion',
      //   component: CoursePreviewDiscussionTab,
      // },
      // {
      //   isPrivate: true,  path:
      //   exact: true,
      //   subpath: '/students',
      //   component: CoursePreviewClassmateTab,
      // },
    ],
  },
]

export const testPreviewRoutes = {
  path: '/schools/:schoolId/test-preview/:testId',
  layout: PreviewTestLayout,
  routes: [
    {
      exact: true,
      subpath: '/',
      component: TeacherTestPreviewPage,
    },
  ],
}

export const schoolCourseSettingRoutes = {
  path: '/schools/:schoolId/curriculum/manage/:courseId/setting',
  // layout: CurriculumDetailEditLayout,
  layout: SettingCourseLayout,
  //layout: CreateCourseLayout,
  isChildrenFunc: true,
  routes: [
    {
      exact: true,
      subpath: '/',
      render: ({ match }) => <Redirect to={`${match.url}/course-content`} />,
    },
    {
      subpath: '/course-content',
      isPrivate: true,
      exact: true,
      component: PublishedCourseContentPage,
    },
    {
      subpath: '/access',
      isPrivate: true,
      exact: true,
      component: PublishedCoursePricePage,
    },
    {
      subpath: '/setting',
      isPrivate: true,
      exact: true,
      component: PublishCourseInformationPage,
    },

    // {
    //   isPrivate: true,
    //   exact: true,
    //   subpath: '/course-information',
    //   component: PublishCourseInformationPage,
    // },
    // {
    //   isPrivate: true,
    //   exact: true,
    //   subpath: '/course-content',
    //   component: PublishedCourseContentPage,
    // },
    // {
    //   isPrivate: true,
    //   exact: true,
    //   subpath: '/course-instructor',
    //   component: PublishedCourseInformationPage,
    // },
    // {
    //   isPrivate: true,
    //   exact: true,
    //   subpath: '/course-price',
    //   component: PublishedCoursePricePage,
    // },
    // {
    //   isPrivate: true,
    //   exact: true,
    //   subpath: '/course-setting',
    //   component: PublishedCourseSettingPage,
    // },
    // {
    //   isPrivate: true,
    //   exact: true,
    //   subpath: '/information',
    //   component: InformationPage,
    // },
    // {
    //   isPrivate: true,
    //   exact: true,
    //   subpath: '/setting',
    //   component: SettingPage,
    // },
  ],
}
// export const schoolCourseSettingSingleTestRoutes = {
//   path: '/schools/:schoolId/curriculum/manage/:courseId/single-test',
//   // layout: CurriculumDetailEditLayout,
//   layout: SettingCourseSingleTestLayout,
//   //layout: CreateCourseLayout,
//   isChildrenFunc: true,
//   routes: [
//     {
//       exact: true,
//       subpath: '/',
//       render: ({ match }) => <Redirect to={`${match.url}/course-content`} />,
//     },
//     {
//       subpath: '/course-content',
//       isPrivate: true,
//       exact: true,
//       component: PublishedCourseContentPage,
//     },
//     {
//       subpath: '/access',
//       isPrivate: true,
//       exact: true,
//       component: PublishedCoursePricePage,
//     },
//     {
//       subpath: '/setting',
//       isPrivate: true,
//       exact: true,
//       component: PublishCourseSettingSingleTestPage,
//     },
//   ],
// }

export default schoolRoutes
