import Resource from '../utils/resource'

export default new Resource('/Answers', {
  updateAnswerScoreById: {
    url: '{id}/question/{fk}',
    method: 'put',
  },
  updateAnswerGradeById: {
    url: '{id}/grade',
    method: 'post',
  },
})
