import Typography from '../typography'

import styled from 'styled-components'
export const ContainerAll = styled.div`
  display: flex;
`
export const Container = styled.div`
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 500px;
  @media (max-width: 1024px) {
    height: 600px;
  }
  img {
    max-width: 260px;
    //height: auto;
    // padding-bottom: 24px;
  }

  b {
    color: ${(props) => props.theme.primary2};
  }
`
export const Email = styled(Typography)`
  padding: 20px 0 24px;
`
export const Highlight = styled.span`
  color: ${(props) => props.theme.grey};
`
export const ClasswinLogo = styled.img`
  height: 40px;
  @media (max-width: 426px) {
    height: 36px;
  }
`
export const ContainerLogo = styled.div`
  position: absolute;
  left: 50px;
  top: 24px;
  display: flex;
  justify-content: space-between;
  width: 45px;
  @media (max-width: 426px) {
    // top: -10px;
    left: 35px;
  }
`
export const ContainerLanguage = styled.div`
  position: absolute;
  right: 55px;
  top: 29px;
  display: flex;
  justify-content: space-between;
  width: 45px;
  @media (max-width: 426px) {
    // top: -5px;
    right: 30px;
  }
`
