import React from 'react'
import styled from 'styled-components'

const Div = styled.div`
  &:hover {
    background-color: ${(props) => props.theme.primary12};
  }
`

const ColorFilter = ({ children }) => {
  return <Div>{children}</Div>
}

export default ColorFilter
