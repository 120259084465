import styled from 'styled-components'
import Typography from '../../components/typography'

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme['text-xs']};
  /* line-height: 8px; */
  color: ${(props) => props.theme.gray2};
`
export const Itemradio = styled.div`
  margin: 18px 0px 18px 0px;
`
export const RadioContainer = styled.div`
  display: inline-flex;
  padding-right: 30px;
  &:last-child {
    padding-right: 0;
  }
  .ant-checkbox .ant-checkbox-inner {
    border-color: #3d4453;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #253e87;
    border-color: #253e87;
  }
  .ant-radio-checked .ant-radio-inner {
    border: 1px solid #253e87;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #253e87;
    border: 1px solid #253e87;
  }
`
export const Container = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  /* height: calc(100vh - 80px - 50px); */
  height: 100%;
  overflow-y: scroll;
  /* @media (max-width: 991px) {
    border-radius: 0px;
  } */
  @media (max-width: 991px) {
    border-radius: 0px;
    height: calc(100vh - 100px);
    padding-bottom: 45px;
  }
  hr.type {
    margin: 10px 45px 0px 45px;
    /* margin: 20px 0; */

    height: 1px;
    background-color: #dedfe0;
    border: none;
    @media (max-width: 768px) {
      margin: 20px 15px 0px 15px;
    }
  }
`

export const SubHeader = styled.div`
  .wrapper {
    display: flex;
    position: relative;
    padding: 20px 45px;
    border-bottom: 1px solid #dedfe0;
    @media (max-width: 768px) {
      padding: 20px 15px;
    }
  }

  .main-header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin: 0px;
    }

    // button {
    //   /* padding: 12px; */
    //   padding-top: 7px;
    //   padding-bottom: 7px;
    //   /* max-height: 30px; */
    // }
  }
`

export const AutoApprove = styled.div`
  display: flex;
  align-items: center;
`
export const PublishContent = styled.div`
  padding: 30px 45px 20px 45px;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`
export const Lable = styled.label`
  display: block;
  color: ${(props) => props.theme.primaryFont};
  margin-bottom: 10px;
`

export const GridDurationAndPricing = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* gap: 20px 15px; */
  /* padding: 20px; */
  margin-top: 24px;

  div > div > label.label {
    color: #707070;
    & + div.container__short-des {
      /* background-color: red; */
      /* min-height: 30px; */
      br {
        display: none;
      }
    }
  }
`
export const Content = styled.div`
  /* padding: 24px 32px 0 32px; */
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100% - 250px);
  // background-color: red;
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 426px) {
    height: auto;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const Line = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.black38};
  margin-top: 50px;
  width: 100%;
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 15px;
`
