import React from 'react'
import styled from 'styled-components'
import Modal from 'components/modal'
import Typography from 'components/typography'
import Button from 'components/button'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;

  & p.schoolName {
    margin: -5px 0 0 0;
  }
  & p.description {
    margin: 5px 0 20px 0;
  }

  width: 450px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const SchoolTutorialModal = ({ menu = 'dashboard', isOpen, onSkip, children, schoolName = '' }) => {
  return (
    <Modal isOpen={isOpen} contentStyle={{ padding: 0, border: 'none' }}>
      {children}
      <Content>
        <Typography className="menu" id={`instructor.menu.${menu}`} h3>
          {menu}
        </Typography>
        {menu === 'welcome' && schoolName && (
          <Typography className="schoolName" h3>
            {schoolName}
          </Typography>
        )}
        <Typography className="description" id={`school.tutorial.${menu}`}>
          {menu}
        </Typography>
        <Button id="ok" primary onClick={() => onSkip(menu)}>
          OK
        </Button>
      </Content>
    </Modal>
  )
}

export default SchoolTutorialModal
