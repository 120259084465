import ProfileBlack from '../../images/ProfileBlack.svg'
import ProfileActive from '../../images/UserCircleActive.svg'

import LockOpen from '../../images/LockOpen.svg'
import LockOpenActive from '../../images/LockOpenActive.svg'

export const accountsettingMenus = [
  {
    id: 'account.generalinfo',
    label: 'General Info',
    link: '/account-setting/general-information',
    icon: ProfileBlack,
    iconActive: ProfileActive,
    check: 'general-information',
  },
  {
    id: 'account.change.password',
    label: 'Change password',
    link: '/account-setting/change-password',
    icon: LockOpen,
    iconActive: LockOpenActive,
    check: 'change-password',
  },
  // {
  //   label: 'Notifications',
  //   link: '/account-setting/notification',
  // },
]
export const teacheraccountsettingMenus = [
  {
    id: 'account.generalinfo',
    label: 'General Info',
    link: '/teacher/account-setting/general-information',
    icon: ProfileBlack,
    iconActive: ProfileActive,
    check: 'general-information',
  },
  {
    id: 'account.change.password',
    label: 'Change password',
    link: '/teacher/account-setting/change-password',
    icon: LockOpen,
    iconActive: LockOpenActive,
    check: 'change-password',
  },
  // {
  //   label: 'Notifications',
  //   link: '/account-setting/notification',
  // },
]
