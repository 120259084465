import { SkillCategoryAPI } from '../../api'

export const SkillCategory = {
  state: {
    skillCategory: [],
  },
  reducers: {
    setSkillCategory(state, payload) {
      return {
        ...state,
        skillCategory: payload,
      }
    },
  },
  effects: () => ({
    async getSkillCategory() {
      try {
        const payload = {
          filter: JSON.stringify({ include: 'skills' }),
        }
        const result = await SkillCategoryAPI.find(payload)
        this.setSkillCategory(result)
        return result
      } catch (error) {
        throw error
      }
    },
  }),
}
