import styled, { css } from 'styled-components'
import theme from 'theme'

export const Container = styled.div`
  border: ${({ bordered }) => (bordered ? 1 : 0)}px solid #f0f0f0;
  border-right: 0;
  border-bottom: 0;

  ${(props) => {
    if (props.full)
      return css`
        width: 100%;
      `
  }}

  position: relative;

  thead > tr > .rc-table-cell-fix-right {
    right: 0 !important;
  }

  /* .rc-table-container {
     
 } */

  /* .rc-table-body {
     overflow-y: auto !important;
 }

 .rc-table-header {
     overflow: none !important;
 } */

  .rc-table-cell-scrollbar {
    display: none;
  }

  .rc-table-content {
    border-radius: 8px !important;
  }

  tbody > tr > .rc-table-cell-fix-right {
    background-color: #fff;
  }

  tbody > tr:hover > .rc-table-cell-fix-right {
    /* background-color: ${({ theme }) => theme.primary}; */
    background-color: ${({ theme }) => theme.gray4};
  }

  .rc-table-ping-right tbody > tr > .rc-table-cell-fix-right-first {
    -webkit-box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
  }

  .rc-table-ping-right thead > tr > .rc-table-cell-fix-right-first {
    -webkit-box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    box-shadow: -2px 0px 5px -5px rgba(0, 0, 0, 0.5);
  }

  thead > tr > .rc-table-cell-fix-left {
  }

  tbody > tr > .rc-table-cell-fix-left {
    background-color: #fff;
  }

  tbody > tr:hover > .rc-table-cell-fix-left {
    /* background-color: ${({ theme }) => theme.primary}; */
    background-color: ${({ theme }) => theme.gray4};
  }

  .rc-table-ping-left tbody > tr > .rc-table-cell-fix-left-last {
    -webkit-box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
  }

  .rc-table-ping-left thead > tr > .rc-table-cell-fix-left-last {
    -webkit-box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 0px 5px -5px rgba(0, 0, 0, 0.5);
  }

  th {
    font-weight: 600;
  }

  th,
  td {
    padding: 16px;
    &.rc-table-cell-fix-right-first {
      padding: 0;
      background-color: #fff;
    }
    &.rc-table-cell-fix-left-last {
      background-color: #fff;
    }
  }

  tbody > tr {
    background-color: #fff;
    margin: 10px 0px;
    &:hover {
      background-color: ${({ theme }) => theme.gray12};
    }
  }

  tbody > .rc-table-placeholder {
    &:hover {
      background-color: ${({ theme }) => theme.gray12};
      * {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  thead > tr > th {
    border-bottom: 1px solid ${theme.gray4};
    border-right: ${({ bordered }) => (bordered ? 1 : 0)}px solid ${theme.gray4};
    // border-top: ${({ bordered }) => (bordered ? 1 : 0)}px solid ${theme.gray4};
  }

  .rc-table-tbody > tr > td {
    height: 73px;
    border-bottom: 1px solid #f0f0f0;
    border-right: ${({ bordered }) => (bordered ? 1 : 0)}px solid #f0f0f0;
  }

  .rc-table-fixed-right {
    display: none;
  }
  .rc-table-fixed-left {
    display: none;
  }

  .rc-table-fixed {
    table-layout: fixed;
    width: 100%;
  }

  .rc-table-body {
    ::-webkit-scrollbar {
      width: 0px;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`

export const EmptyWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 20px;
  img {
    margin-bottom: 20px;
    height: 22vh;
    width: auto;
  }
`
