import { RewardAPI, UserAPI } from '../../api'

export const Reward = {
  state: {
    rewardList: [],
    rewardHistory: [],
  },
  reducers: {
    setRewardList(state, payload) {
      return {
        ...state,
        rewardList: payload,
      }
    },
    setRewardHistory(state, payload) {
      return {
        ...state,
        rewardHistory: payload.map(({ reward, school }) => ({
          title: reward.title,
          description: reward.description,
          point: reward.point,
          images: reward.images,
          school,
          disableBtn: true,
        })),
      }
    },
  },
  effects: () => ({
    async getRewardList() {
      try {
        const payload = {
          filter: JSON.stringify({ include: ['school'], order: 'createdAt DESC' }),
        }
        const result = await RewardAPI.find(payload)
        this.setRewardList(result)
      } catch (error) {
        throw error
      }
    },
    async getRewardHistory() {
      try {
        const payload = {
          filter: JSON.stringify({
            include: ['reward', 'school', 'appUser'],
            order: 'createdAt DESC',
          }),
        }
        const result = await UserAPI.getRewardTransaction(payload)
        this.setRewardHistory(result)
      } catch (error) {
        throw error
      }
    },
    async postReward(payload) {
      try {
        await UserAPI.sendRewardTransaction(payload)
      } catch (error) {
        throw error
      }
    },
  }),
}
