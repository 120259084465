import { Typography } from 'components'
import React, { Fragment, useState } from 'react'
import { CommentItem } from './CommentItem'

function Comment({ comments, userId, ...props }) {
  const [showAllComments, setShowAllComments] = useState()
  const prevComment = comments.length - 2
  // console.log(comments)
  return (
    <Fragment>
      {comments.length >= 3 && (
        <div
          style={{ cursor: 'pointer', paddingTop: 10 }}
          onClick={() => setShowAllComments(!showAllComments)}
        >
          {showAllComments ? (
            <Typography
              id="view.hide.comments"
              color="white"
              bold
              style={{ paddingBottom: 20, marginLeft: '0px' }}
            >
              view.hide.comments
            </Typography>
          ) : (
            <div
              style={{
                display: 'flex',
                paddingBottom: 10,
                marginLeft: '0px',
              }}
            >
              <>
                <Typography id="view.all.comments1" color="white" bold style={{ marginRight: 5 }}>
                  View
                </Typography>
                <Typography color="white" bold style={{ marginRight: 5 }}>
                  {prevComment}
                </Typography>

                {prevComment === 1 ? (
                  <Typography id="view.all.comments" color="white" bold>
                    previous comment
                  </Typography>
                ) : (
                  <Typography id="view.all.comments2" color="white" bold>
                    previous comments
                  </Typography>
                )}
              </>
            </div>
          )}
        </div>
      )}
      {showAllComments ? (
        <Fragment>
          {(comments || []).map((data, idx) => {
            const reply = data
            const isMe = reply.appUserId === userId
            const commentTime = data.createdAt
            return (
              <CommentItem
                key={idx}
                {...reply}
                media={data.media}
                {...props}
                commentTime={commentTime}
                isMe={isMe}
              />
            )
          })}
        </Fragment>
      ) : (
        <Fragment>
          {comments.length > 0 &&
            comments.slice(-2).map((comment, idx) => {
              const reply = comment
              const isMe = reply.appUserId === userId
              return (
                <CommentItem
                  key={idx}
                  {...comment}
                  {...props}
                  commentTime={comment.createdAt}
                  isMe={isMe}
                />
              )
            })}
        </Fragment>
      )}
    </Fragment>
  )
}

export default Comment
