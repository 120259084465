/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
// import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import windowSize from 'react-window-size'
import styled, { css } from 'styled-components'
import _get from 'lodash/get'

import ErrorBoundary from '../../components/errorBoundary'

import { SmallMobile, DesktopAndTablet } from 'utils/displayResponsive'
import {
  //Divider,
  notification,
} from 'antd'

import Sidebar from '../myCourseDetailLayoutNew/sidebar'

const MINIMUM_COMPLETE_VIDEO = 0.95

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  @media (max-width: 1024px) {
  }

  @media (max-width: 820px) {
    padding: 0px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }

  @media (max-width: 429px) {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
`
const Right = styled.div`
  //width: 70%;
  width: calc(100% - 420px);
  min-height: 100%;
`
const Left = styled.div`
  //width: 30%;
  width: 420px;
  min-height: 150%;
  background-color: #1c1c1e;
  //min-height: calc(100vh - 42px);
`

const initialPosition = { x: 0, y: 0 }

class MyCourseLectureContentBodyLayout extends Component {
  state = {
    width: '100%',
    height: 400,
    x: initialPosition.x,
    y: initialPosition.y,
    slider: 0,
    numPages: null,
    pageNumber: 1,
    showQuestionModal: false,
    timeSpent: 0,
    // isSpentEnoughTime: true,
    isDisabledButton: false,
    isLeastVideo: false,
    showArrow: false,
    isOpen: false,
    isOpenLecture: false,
    slideFullscreen: false,
  }

  async componentDidMount() {
    const { match, getLectureProgress } = this.props
    const { courseId, lectureId } = match.params
    const width = window.innerWidth
    await getLectureProgress({ courseId, lectureId })
    await this.init()
    if (width <= 1024) {
      this.setState({ isLeastVideo: true })
    } else {
      this.setState({ isLeastVideo: false })
    }
    this.presentRowRef = React.createRef()
    this.slideRef = React.createRef()
    this.videoBoxRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    const { match, isTimeRequired, id, progress } = this.props
    if (prevProps.match.params.lectureId !== match.params.lectureId) {
      this.handleTimerStop()
      this.setState({ timeSpent: null })
      if (prevProps.isTimeRequired) {
        this.handleUpdateTimeSpent(prevProps, prevState)
      }
    }

    if (!this.timer && _get(prevProps, 'id') !== id && isTimeRequired && !this.checkCompleted()) {
      this.init()
    }
    if (
      !this.timer &&
      _get(prevProps, 'progress.lectureId') !== _get(progress, 'lectureId') &&
      isTimeRequired &&
      !this.checkCompleted()
    ) {
      this.init()
    }
    // Stop timer when time spent is more than quired time
    if (this.checkEnoughTime()) {
      this.handleTimerStop()
    }
  }
  componentWillUnmount() {
    const { match } = this.props
    this.handleTimerStop()
    if (!/^\/schools/.test(match.url)) {
      this.handleUpdateTimeSpent()
    }
  }

  init() {
    const { isTimeRequired, progress, match } = this.props
    if (isTimeRequired && progress.lectureId === match.params.lectureId) {
      this.handleTimerStart()
      this.setState({ timeSpent: _get(progress, 'timeSpent', null) })
    }
  }

  getMinimumCompleteVideo = () => {
    return this.props.durationPercentage
      ? this.props.durationPercentage / 100
      : MINIMUM_COMPLETE_VIDEO
  }

  checkEnoughTime = () => {
    const { progress, playedPercent, videoLink } = this.props
    if (videoLink) {
      const minimumCompleteVideo = this.getMinimumCompleteVideo()
      const percent = progress?.percent || 0
      return percent > minimumCompleteVideo || playedPercent > minimumCompleteVideo
    }
    return true
  }

  checkCompleted = () => {
    const { progress, playedPercent, videoLink } = this.props
    const percent = progress?.percent || 0
    const minimumCompleteVideo = this.getMinimumCompleteVideo()
    if (videoLink) {
      return percent > minimumCompleteVideo || playedPercent > minimumCompleteVideo
    }
    return percent === 1
  }
  handleOpenLecture = () => {
    this.setState({ isOpenLecture: true })
  }
  handleCloseLecture = () => {
    this.setState({ isOpenLecture: false })
  }

  handleTimerStart = () => {
    this.timer = setInterval(
      () =>
        this.setState((prevState) => {
          if (prevState.timeSpent !== null) {
            return { timeSpent: prevState.timeSpent + 1 }
          }
          return {}
        }),
      1000
    )
  }

  handleTimerStop = () => {
    clearInterval(this.timer)
    this.timer = null
  }

  handleEnableButton = () => {
    const { isTimeRequired, videoLink } = this.props
    const isCompleted = this.checkCompleted()
    if (isCompleted) {
      return isCompleted
    }
    // handle wrong answer
    if (this.state.isDisabledButton) {
      return this.state.isDisabledButton
    }
    if (isTimeRequired) {
      if (videoLink) {
        return true
      }
      return !this.checkEnoughTime()
    }

    return false
  }

  handleWrongAnswer = () => {
    this.setState({ isDisabledButton: true })
    setTimeout(() => {
      this.setState({ isDisabledButton: false })
    }, 5000)
  }

  handleUpdateTimeSpent = (prevProps, prevState) => {
    const { sendTimeSpent, match } = prevProps || this.props
    const { timeSpent } = prevState || this.state
    sendTimeSpent({
      timeSpent: timeSpent,
      lectureId: match.params.lectureId,
      courseId: match.params.courseId,
    })
  }

  onStart = () => {
    const { setData, progress } = this.props
    setData({
      start: true,
      playedPercent: progress.percent,
    })
    if (this.player && progress) {
      const percent = progress.time / this.player.getDuration()
      this.player.seekTo(percent)
    }
  }

  onProgress = async (progress) => {
    const { setData, playedPercent, sendVideoProgress } = this.props
    await setData({
      playedSeconds: progress.playedSeconds,
      playedPercent: progress.played,
    })
    const canSend = Math.floor(progress.playedSeconds) % 10 === 0 || playedPercent >= 1
    if (canSend) {
      sendVideoProgress()
    }
  }

  playerRef = (player) => {
    this.player = player
  }

  handleEnableResize = (bool) => {
    return {
      bottom: bool,
      bottomLeft: bool,
      bottomRight: bool,
      left: bool,
      right: bool,
      top: bool,
      topLeft: bool,
      topRight: bool,
    }
  }

  handleNext = () => {
    this.setState((prevState) => ({
      pageNumber: prevState.pageNumber + 1,
    }))
  }

  handleNext = () => {
    this.setState((prev) => ({
      pageNumber: prev.pageNumber < prev.numPages ? prev.pageNumber + 1 : prev.numPages,
    }))
  }

  handlePrevious = () => {
    this.setState((prev) => ({
      pageNumber: prev.pageNumber !== 1 ? prev.pageNumber - 1 : 1,
    }))
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  handleLectureComplete = async () => {
    const {
      enableCompleteButton,
      isQuestionRequired,
      isTimeRequired,
      sendVideoProgress,
      locale,
      match,
      getLectureProgress,
      onNext,
      id,
      durationPercentage,
    } = this.props
    const { courseId, lectureId } = match.params
    // console.log('....func')
    if (isQuestionRequired) {
      this.handleOpenQuestionModal()
    }
    const isEnableCompleteOnly = enableCompleteButton && !isTimeRequired && !isQuestionRequired
    const isCheckTimeSpent =
      enableCompleteButton && isTimeRequired && this.checkEnoughTime() && !isQuestionRequired
    const isNotEnableComplete = !enableCompleteButton
    if (isEnableCompleteOnly || isCheckTimeSpent || isNotEnableComplete) {
      await sendVideoProgress(null, this.state.timeSpent)
      notification.success({ message: locale === 'th' ? 'สำเร็จ' : 'Lecture Complete' })
      await getLectureProgress({ courseId, lectureId })
      onNext(id)
    } else {
      notification.error({
        message:
          locale === 'th'
            ? `คุณต้องเรียนให้ถึง ${durationPercentage}% ของคลิปเพื่อผ่านบนเรียนนี้`
            : `You must study at least ${durationPercentage}% of this video to complete this lecture.`,
      })
    }
  }

  handleCompleteContentProgress = async () => {
    const { sendContentProgress, getLectureProgress, match } = this.props
    const { courseId, lectureId } = match.params
    await sendContentProgress()
    await getLectureProgress({ courseId, lectureId })
  }

  render() {
    const {
      content,
      attachments = [],
      onNext,
      onPrev,
      id,
      name,
      getLectures,
      question,
      videoLink,
      //postTest,
      match,
      //locale,
      //courseName,
      isNavigation,
      handleNavigation,
      dataLecture,
      preTest,
      postTest,
      courseId,
      history,
      modalLecture,
      setModalLecture,
      hasSideBar,
      enableCompleteButton,
      isTimeRequired,
      isQuestionRequired,
      children,
    } = this.props
    const { showQuestionModal } = this.state
    const isVideoContent = /^{"videoName":".+"}/.test(content)
    const lectureAll = getLectures()
    const index = lectureAll?.map(({ id }) => id).indexOf(id)
    const indexMax = lectureAll?.length - 1
    const isFirst = index === 0
    const isLast = index === indexMax
    const isEnableCompleteOnly = enableCompleteButton && !isTimeRequired && !isQuestionRequired
    const isCheckTimeSpent =
      enableCompleteButton && isTimeRequired && this.checkEnoughTime() && !isQuestionRequired
    const isNotEnableComplete = !enableCompleteButton
    const isTeacherPath =
      match.path === '/schools/:schoolId/curriculum/manage/:courseId/lecture/:lectureId'

    const lectureId = match?.params?.lectureId
    return (
      <ErrorBoundary>
        <Fragment>
          <Sidebar
            onNext={onNext}
            checkCompleted={this.checkCompleted}
            isTeacherPath={isTeacherPath}
            handleLectureComplete={this.handleLectureComplete}
            // onNext={
            //   this.checkCompleted() || isTeacherPath
            //     ? onNext(lectureId)
            //     : this.handleLectureComplete
            // }
          />
        </Fragment>
        {/* <Footer
          hasSideBar={hasSideBar}
          onNext={
            this.checkCompleted() || isTeacherPath ? () => onNext(id) : this.handleLectureComplete
          }
          onPrev={() => onPrev(id)}
          disabledNext={isLast}
          disabledPrev={isFirst}
          onOpenLecture={this.handleOpenLecture}
          courseId={courseId}
          history={history}
          isLast={isLast}
          url={`${match.url.split('/').slice(0, -1).join('/')}`}
          isEnableCompleteOnly={isEnableCompleteOnly}
          isCheckTimeSpent={isCheckTimeSpent}
          isNotEnableComplete={isNotEnableComplete}
        /> */}
      </ErrorBoundary>
    )
  }
}

MyCourseLectureContentBodyLayout.propTypes = {
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  getLectures: PropTypes.func,
  content: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  lectureWorksheets: PropTypes.array,
  name: PropTypes.string,
  playedPercent: PropTypes.number,
  priority: PropTypes.number,
  hasSideBar: PropTypes.bool,
  progress: PropTypes.shape({
    courseId: PropTypes.string,
    id: PropTypes.string,
    lectureId: PropTypes.string,
    percent: PropTypes.number,
    studentId: PropTypes.string,
    time: PropTypes.number,
    type: PropTypes.string,
    timeSpent: PropTypes.number,
  }),
  subjectId: PropTypes.string,
  type: PropTypes.string,
  videoLink: PropTypes.string,
  videoStarted: PropTypes.bool,
}

MyCourseLectureContentBodyLayout.defaultProps = {
  onPrev: () => {},
  onNext: () => {},
  getLectures: () => {},
  sendTimeSpent: () => {},
  progress: {},
  enableCompleteButton: false,
  isTimeRequired: false,
  timeRequired: null,
  isQuestionRequired: false,
  question: null,
}
const mapState = ({
  MyCourse: { lectureProgress, myCourse, modalLecture, lectures },
  Language: { locale },
}) => ({
  lectureProgress,
  courseName: myCourse.name,
  courseId: myCourse.id,
  locale,
  ImageCourse: myCourse.image,
  modalLecture,
  lectures,
})
const mapDispatch = ({ MyCourse: { getLectureProgress, setModalLecture } }) => ({
  getLectureProgress,
  setModalLecture,
})
export default connect(
  mapState,
  mapDispatch
)(withRouter(windowSize(MyCourseLectureContentBodyLayout)))
