/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { CSSTransition } from 'react-transition-group'
import debounce from 'lodash/debounce'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Collapse } from 'react-collapse'
import { notification } from 'antd'
import styled from 'styled-components'
// import { createCurriculumData } from '../../../../static'
import { Desktop, Mobile } from '../../../../utils/displayResponsive'
import {
  Container,
  Card,
  Button,
  InputWithLabel,
  // ButtonDialog,
  Icon as PureIcon,
} from '../../../../components'
import ConfirmModal from '../../../../components/confirmModal/loadable'
import Alert from '../../../../components/alert/loadable'
import ErrorBoundary from '../../../../components/errorBoundary'
import SideQuestion from '../../../sideQuestion'
import TestIcon from '../../../../images/icons/ClipboardText.svg'
import ArrowIcon from '../../../../images/icons/CaretDown.svg'
import DeleteIcon from 'images/icons/TrashColor.svg'
import PreviewIcon from '../../../../images/icons/Eye.svg'
import PlusIcon from '../../../../images/icons/primary-plus.svg'
import CopyIcon from '../../../../images/icons/Copy.svg'
// import SettingIcon from '../../../../images/ic-setting.png'
import closeIcon from 'images/icons/Gray-X.svg'
import { Icon, IconArrow } from '../lecture/style'
import { CardTitle, IconSection, SeperateLine, Title, Wrapper } from '../../style'
import TestOptionModal from '../modal/testOptionModal'
import TestOption from '../modal/testOption'
import '../css-transition.css'
import Typography from 'components/typography'
import theme from 'theme'
const StyledContainer = styled(Card)`
  display: flex;
  /* position: relative; */
  border-color: ${({ isSelected }) => (isSelected ? theme.colors.secondary : 'none')};
  box-sizing: border-box;
  height: 72px;
  transition: box-shadow, all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 0;
  border-left-width: 16px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;

  :hover {
    /* -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(100, 100, 100, 0); */
    /* border-bottom: none; */
    /* margin-top: -4px; */
    /* transform: scale(1.015); */
    z-index: 1;
    border-color: ${theme.primary1};
  }
`

const StyledCard = styled.div`
  display: flex;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0 24px 0 16px;
  justify-content: space-between;

  i.deleteIcon {
    display: inline;
    align-self: center;
    margin-right: 30px;
    /* position: absolute;
    top: -10px;
    left: -30px; */
  }

  :hover i.deleteIcon {
  }

  i.eva-hover {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
`

const Content = styled.section`
  i.eva-hover {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
`

const ContentBody = styled(Container)`
  position: fixed;
  top: ${({ isCourseSetting }) => (isCourseSetting ? '56px' : '0px')};
  height: ${({ isCourseSetting }) => (isCourseSetting ? 'calc(100vh - 0px)' : '100%')};
  //right: 0;
  z-index: 3;
  top: 54px;
  right: 140px;
  // box-shadow: -4px 6px 15px rgba(0, 0, 0, 0.1);
  transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  /* transform: translateX(0vw); */
  overflow-y: scroll;
  overflow-x: hidden;
  //margin: 0;
  width: 43%;
  min-width: 400px;
  background-color: transparent;
  grid-template-rows: repeat(5, min-content) 1fr;

  ::-webkit-scrollbar {
    width: 0px;
  }
`

const CollapseWrapper = styled.div`
  // padding: 0 5px 0 30px;
  .ReactCollapse--collapse {
    transition: height 300ms ease;
  }
`
const NumberOfQuestion = styled(Typography)`
  font-size: ${({ theme }) => theme['text-xs']};
  display: flex;
  align-items: center;
  white-space: nowrap;
`

class PreTest extends Component {
  state = {
    isExpand: false,
    showTestOptions: false,
    showButtonAdd: false,
    questions: [],
    showConfirm: false,
    isCollapse: false,
    questionId: '',

    isAlert: false,
    isError: false,
    titleAlert: '',
    payloadQuestion: {},
  }

  debounceInput = debounce(async ({ value, name }) => {
    const { subjectId, id: fk, updateAssessmentById, handleAutoSave } = this.props
    const payload = {
      subjectId,
      fk,
      [name]: value,
    }
    try {
      await updateAssessmentById(payload)
      handleAutoSave(true)
    } catch (error) {
      handleAutoSave(false)
      throw error
    }
  }, 1000)

  componentDidMount() {
    this.getQuestionData()
  }

  componentDidUpdate(prevProps) {
    const { selectedId, isSelected, id } = this.props
    if (prevProps.selectedId !== selectedId) {
      if (!isSelected(id)) this.handleSelect('')
    }
  }

  handleShowButtonAdd = (showButtonAdd) => () => {
    this.setState({ showButtonAdd })
  }

  getQuestionData = async () => {
    const { getQuestions, id } = this.props
    try {
      const questions = await getQuestions({ assessmentId: id })
      this.setState({ questions })
    } catch (error) {
      throw error
    }
  }

  inputChange = (e) => {
    // eslint-disable-next-line prefer-const
    let { value, name } = e.target
    if (name === 'name' && !value) value = 'Untitled'
    //this.props.handleAutoSave(false)
    //this.debounceInput({ value, name })
  }

  handleExpandCard = () => {
    this.setState(({ isExpand }) => ({ isExpand: !isExpand }))
  }

  handleRemove = async () => {
    const { deleteAssessmentById, subjectId, id: fk, onChange } = this.props
    const payload = {
      subjectId,
      fk,
    }
    try {
      await deleteAssessmentById(payload)
    } catch (error) {
      throw error
    } finally {
      onChange()
    }
  }

  handleTestOptions = () => {
    this.setState(({ showTestOptions }) => ({ showTestOptions: !showTestOptions }))
  }

  handleAddQuestion = async () => {
    const { addQuestion, id } = this.props
    const { questions } = this.state
    try {
      const payload = {
        assessmentId: id,
        title: JSON.stringify({
          content: '<p><br></p>',
          delta: { ops: [{ insert: '\n' }] },
        }),
        number: questions.length + 1,
        priority: questions.length + 1,
        type: 'choice question',
        choices: [],
        answers: [],
        correctScore: 1,
      }
      await addQuestion(payload)
    } catch (error) {
      throw error
    } finally {
      this.getQuestionData()
    }
  }

  getNewPriority = (list, srcIndex, dstIndex) => {
    if (srcIndex < dstIndex) {
      // 1 2* 3 4 --> 1 3 4 2*
      const head = list.slice(0, srcIndex)
      const tail = list.slice(dstIndex + 1)
      const newPriority = list
        .slice(srcIndex, dstIndex + 1)
        .map((item, index) => ({ ...item, priority: srcIndex + index + 1 }))
      return { head, newPriority, tail }
    }
    if (dstIndex < srcIndex) {
      // 1 2 3 4* --> 1 4* 2 3
      const head = list.slice(0, dstIndex)
      const tail = list.slice(srcIndex + 1)
      const newPriority = list
        .slice(dstIndex, srcIndex + 1)
        .map((item, index) => ({ ...item, priority: dstIndex + index + 1 }))
      return { head, newPriority, tail }
    }
    return { head: [], newPriority: [], tail: [] }
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result
    const { questions } = this.state
    const { updateQuestionPriority, id } = this.props
    if (!destination) {
      return
    }
    if (destination.droppabledId === source.droppabledId && destination.index === source.index)
      return

    const draggedQuestion = questions.find((item) => item.id === draggableId)
    const newQuestion = Array.from(questions)
    newQuestion.splice(source.index, 1)
    newQuestion.splice(destination.index, 0, draggedQuestion)
    const { head, newPriority, tail } = this.getNewPriority(
      newQuestion,
      source.index,
      destination.index
    )

    updateQuestionPriority({
      id,
      questions: newPriority.map((item) => ({ id: item.id, priority: item.priority })),
    })
    this.setState({ questions: [...head, ...newPriority, ...tail] })
  }

  handleCloseModal = () => {
    this.setState({ showConfirm: false })
  }

  handleCollapse = (e) => {
    const { isCollapse } = this.state
    e.stopPropagation()
    this.setState({ isCollapse: !isCollapse })
  }

  gotoPreview = (e) => {
    const {
      id,
      name,
      history,
      match: {
        params: { schoolId },
      },
    } = this.props
    e.stopPropagation()
    history.push(`/schools/${schoolId}/test-preview/${id}?title=${name}`)
  }

  handleExpand = (id) => {
    const { isSelected } = this.props
    return isSelected(id)
  }

  handleSelect = (id) => {
    const { questionId } = this.state
    if (questionId === id) this.setState({ questionId: '' })
    else {
      this.setState({ questionId: id })
    }
  }

  isSelected = (id) => {
    const { questionId } = this.state
    return questionId === id
  }

  onDragStart = () => {
    this.setState({ questionId: '' })
  }
  onCloseAlert = () => {
    this.setState({ isAlert: false, text: '' })
  }
  copyWorksheetPretest = async () => {
    const { copyWorksheet, subjectId, id, handleSelected } = this.props
    const payload = {
      type: 'posttest',
      worksheetId: id,
      subjectId: subjectId,
    }
    const posttest = await copyWorksheet(payload)
    await this?.props?.init()
    await handleSelected(`${posttest?.id}`)
    await window.scrollTo(0, document.body.scrollHeight)
  }
  handleChangePayload = (value) => {
    const { payloadQuestion } = this.state
    this.setState({ payloadQuestion: { ...payloadQuestion, ...value } })
  }
  render() {
    const {
      showTestOptions,
      questions,
      showButtonAdd,
      showConfirm,
      // selectedId,
      questionId,

      isAlert,
      isError,
      titleAlert,
      payloadQuestion,
    } = this.state

    const {
      name,
      description,
      id,
      subjectId,
      handleAutoSave,
      // addNewContent,
      // priority,
      isSelected,
      handleSelected,
      id: testId,
      match: {
        path,
        params: { schoolId },
      },
      locale,
      updateAssessmentById,
      id: fk,
      postTest,
      updateAssessment,
    } = this.props

    const isCourseSetting = /\/schools\/:schoolId\/curriculum\/manage.*/.test(path)
    const numberOfQuestions = questions.length
    const isTh = locale === 'th'

    return (
      <ErrorBoundary>
        <Desktop>
          <Formik
            initialValues={{
              name,
              description,
            }}
            enableReinitialize
            onSubmit={async (values) => {
              const payload = {
                subjectId,
                fk,
                ...values,
              }
              try {
                await updateAssessmentById(payload)
                await updateAssessment(payloadQuestion)

                // this.setState({
                //   isAlert: true,
                //   titleAlert: locale === 'en' ? 'Updated Successful' : 'บันทึกเรียบร้อย',
                // })
                notification.success({
                  message: locale === 'th' ? 'บันทึกเรียบร้อย' : 'Updated Successful',
                })
              } catch (error) {
                throw error
              }
            }}
          >
            {() => (
              <Form>
                <Typography id="label.pretest" h4 bold margin="0 0 12px">
                  Pre test
                </Typography>
                <StyledContainer
                  column="1"
                  bgColor="#fff"
                  padding="0px"
                  isSelected={isSelected(testId)}
                >
                  <StyledCard
                    // onClick={this.handleExpandCard}
                    onClick={() => {
                      handleSelected(testId)
                      this.handleSelect('')
                    }}
                    // onMouseLeave={this.handleShowButtonAdd(false)}
                    clickable
                  >
                    {/* <EvaIcon
                        fill="#483DF6"
                        name="edit-2-outline"
                        size="medium" // small, medium, large, xlarge
                        animation={{
                          // type: 'flip', // zoom, pulse, shake, flip
                          hover: true,
                          infinite: false,
                        }}
                      /> */}
                    <Title>
                      <PureIcon src={TestIcon} />
                      <SeperateLine />
                      <Field name="name">
                        {({ field }) => <CardTitle>{field.value}</CardTitle>}
                      </Field>
                    </Title>
                    <IconSection>
                      {numberOfQuestions >= 1 && (
                        <NumberOfQuestion
                          id="label.questions.count"
                          values={{ count: numberOfQuestions }}
                          color="primary1"
                          bold
                        >
                          {numberOfQuestions} Questions
                        </NumberOfQuestion>
                      )}
                      <a
                        href={`/schools/${schoolId}/test-preview/${id}?title=${name}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Icon
                        // onClick={this.gotoPreview}
                        >
                          <img width="23px" height="23px" src={PreviewIcon} alt="preview-icon" />
                        </Icon>
                      </a>
                      {postTest?.[0]?.id ? (
                        <></>
                      ) : (
                        <Icon
                          onClick={() => {
                            //e.stopPropagation()
                            //this.setState({ showConfirm: true })

                            this.copyWorksheetPretest()
                          }}
                          // styles={{ zIndex: '3' }}
                        >
                          <img src={CopyIcon} width="23px" height="23px" alt="remove-icon" />
                        </Icon>
                      )}

                      <Icon
                        onClick={(e) => {
                          e.stopPropagation()
                          this.setState({ showConfirm: true })
                        }}
                        // styles={{ zIndex: '3' }}
                      >
                        <img src={DeleteIcon} width="23px" height="23px" alt="remove-icon" />
                      </Icon>
                      <IconArrow
                        src={ArrowIcon}
                        rotate={isSelected(testId) ? '90deg' : '270deg'}
                        alt="arrow-icon"
                        // onClick={()=> handleSelected('')}
                        onClick={() => handleSelected(testId)}
                      />
                    </IconSection>
                  </StyledCard>
                  {/* <HoverCircleButton
                    type="button"
                    onClick={this.handleShowButtonAdd(!showButtonAdd)}
                    isActive={showButtonAdd}
                    isDragging>
                    <img src={AddIcon} alt="add-icon" />
                  </HoverCircleButton> */}
                </StyledContainer>
                <CollapseWrapper>
                  <Collapse isOpened={isSelected(testId)}>
                    <section>
                      <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                        <Droppable droppableId="quiz">
                          {(provided) => {
                            return (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                <Container column="1" gap="0px">
                                  {(questions || []).map((q, index) => (
                                    <Draggable key={q.id} draggableId={q.id} index={index}>
                                      {(dragProvided) => (
                                        <section
                                          key={q.id}
                                          {...dragProvided.draggableProps}
                                          // {...dragProvided.dragHandleProps}
                                          ref={dragProvided.innerRef}
                                        >
                                          <SideQuestion
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={q.id}
                                            index={index}
                                            assessmentId={id}
                                            getQuestionData={this.getQuestionData}
                                            handleAutoSave={handleAutoSave}
                                            handleSelect={this.handleSelect}
                                            isSelected={this.isSelected}
                                            seletedId={questionId}
                                            dragHandle={dragProvided.dragHandleProps}
                                            {...q}
                                          />
                                        </section>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                  <Button
                                    id="button.add.question"
                                    full
                                    onClick={this.handleAddQuestion}
                                    type="button"
                                    image={<img src={PlusIcon} alt="plus-blue-icon" />}
                                    whiteBorder
                                    margin="12px 0 0"
                                  >
                                    Add Questions
                                  </Button>
                                </Container>
                              </div>
                            )
                          }}
                        </Droppable>
                      </DragDropContext>
                    </section>
                  </Collapse>
                </CollapseWrapper>
                <CSSTransition
                  // in={isSelected(testId)}
                  in={isSelected(testId)}
                  // in={testId === this.state.selectedId}
                  appear={isSelected(id)}
                  timeout={1000}
                  classNames="lecture-body"
                  unmountOnExit
                  onEnter={() => console.log('onEnter 🌅')}
                  onExited={() => console.log('onExited 🌅')}
                >
                  <ContentBody
                    isCourseSetting={isCourseSetting}
                    onMouseLeave={this.handleShowButtonAdd(false)}
                    column="1"
                    gap="15px"
                    padding="40px 50px 20px 25px"
                  >
                    <Card
                      padding="40px 32px"
                      style={{
                        border: '1px solid #253e87',
                        borderRadius: '4px',
                        marginBottom: '40px',
                      }}
                    >
                      <PureIcon
                        onClick={() => handleSelected('')}
                        style={{ width: 25, height: 25, position: 'absolute', right: 65, top: 60 }}
                        src={closeIcon}
                        alt="arrow-icon"
                      />
                      <Field name="name">
                        {({ field, form }) => (
                          <>
                            <Container column="1">
                              <Content
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  gap: 8,
                                  marginBottom: 28,
                                }}
                              >
                                <IconArrow
                                  // onClick={this.handleExpandCard}
                                  onClick={() => handleSelected('')}
                                  src={ArrowIcon}
                                  rotate="180deg"
                                  alt="arrow-icon"
                                />
                                {/* <PureIcon
                                  src={TestIcon}
                                  style={{ position: 'relative', marginRight: 7 }}
                                /> */}
                                <Typography
                                  // style={{ cursor: 'pointer' }}
                                  // onClick={this.handleExpandCard}
                                  h4
                                  bold
                                  width="100%"
                                >
                                  {isTh ? 'แบบฝึกหัดก่อนเรียน' : 'Pre-Test'}
                                  {/* <CardTitle>{field.value}</CardTitle> */}
                                </Typography>
                                {/* <div
                                  style={{
                                    justifyContent: 'flex-end',
                                    display: 'flex',
                                  }}
                                >
                                  <IconArrow
                                    onClick={() => handleSelected('')}
                                    style={{ width: 25, height: 25, margin: 0 }}
                                    src={closeIcon}
                                    alt="arrow-icon"
                                  />
                                </div> */}
                                {/* <Icon onClick={() => this.setState({ showConfirm: true })}>
                                <img
                                  width="23px"
                                  height="23px"
                                  style={{ marginLeft: '5px' }}
                                  src={DeleteIcon}
                                  alt="remove-icon"
                                />
                              </Icon>
                              <Icon onClick={this.gotoPreview}>
                                <img
                                  width="23px"
                                  height="23px"
                                  src={PreviewIcon}
                                  alt="preview-icon"
                                  style={{ marginLeft: '5px' }}
                                />
                              </Icon> */}
                              </Content>
                            </Container>
                            <InputWithLabel
                              translateId="label.title"
                              id="title"
                              name="name"
                              label="Title"
                              type="text"
                              htmlFor="title"
                              value={field.value}
                              autoFocus
                              onChange={(e) => {
                                form.handleChange(e)
                                this.inputChange(e)
                              }}
                              color="primary1"
                              bold={false}
                            />
                            {form.errors && (
                              <Typography color="danger">{form.errors[field.name]}</Typography>
                            )}
                          </>
                        )}
                      </Field>
                      <Wrapper />
                      <Field name="description">
                        {({ field, form }) => (
                          <>
                            <InputWithLabel
                              translateId="label.desc"
                              id="description"
                              name="description"
                              label="Description"
                              type="text"
                              htmlFor="description"
                              value={field.value}
                              onChange={(e) => {
                                form.handleChange(e)
                                this.inputChange(e)
                              }}
                              color="primary1"
                              bold={false}
                            />
                            {form.errors && (
                              <Typography color="danger">{form.errors[field.name]}</Typography>
                            )}
                          </>
                        )}
                      </Field>
                      <TestOption
                        id={id}
                        subjectId={subjectId}
                        handleChangePayload={this.handleChangePayload}
                        // // showTestOptions={showTestOptions}
                        // handleTestOptions={this.handleTestOptions}
                      />
                      <hr
                        style={{ color: '#cbd5e1', backgroundColor: '#cbd5e1', marginTop: '24px' }}
                      />
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
                        <Button id="button.save" type="submit" primary width={171} center>
                          Save
                        </Button>
                      </div>
                      <Alert
                        isOpen={isAlert}
                        error={isError}
                        onRequestClose={this.onCloseAlert}
                        title={titleAlert}
                      />
                    </Card>
                  </ContentBody>
                </CSSTransition>
                <TestOptionModal
                  id={id}
                  subjectId={subjectId}
                  showTestOptions={showTestOptions}
                  handleTestOptions={this.handleTestOptions}
                />
              </Form>
            )}
          </Formik>
          {/* height 10 */}
          {showButtonAdd && (
            <Wrapper
              onMouseOver={this.handleShowButtonAdd(true)}
              onFocus={this.handleShowButtonAdd(true)}
              onBlur={this.handleShowButtonAdd(false)}
            />
          )}

          {/* {showButtonAdd && (
            <SectionWrapper onMouseLeave={this.handleShowButtonAdd(false)}>
              <ButtonDialogWrapper>
                <ButtonDialog
                  onClick={addNewContent}
                  data={createCurriculumData}
                  priority={priority}
                />
              </ButtonDialogWrapper>
            </SectionWrapper>
          )} */}
        </Desktop>
        <Mobile></Mobile>
        <ConfirmModal
          isOpen={showConfirm}
          onRequestClose={this.handleCloseModal}
          title={
            locale === 'en' ? 'Are you sure you want to delete?' : 'คุณแน่ใจหรือไม่ว่าต้องการลบ?'
          }
          // subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry…."
          btn1={
            <Button id="button.confirmModal" onClick={this.handleRemove} primary>
              Submit
            </Button>
          }
          btn2={
            <Button id="button.cancel" secondary onClick={this.handleCloseModal}>
              Cancel
            </Button>
          }
        />
      </ErrorBoundary>
    )
  }
}

PreTest.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
}

PreTest.defaultProps = {
  description: '',
}

const mapState = ({ Language: { locale } }) => ({ locale })
const mapDispatch = ({
  AssessmentQuestion: { getQuestions, addQuestion, updateQuestionPriority },
  Subject: { updateAssessmentById, deleteAssessmentById, copyWorksheet },
  Assessment: { updateAssessment, getAssessmentDetail },
}) => ({
  updateAssessmentById,
  deleteAssessmentById,
  getQuestions,
  addQuestion,
  updateQuestionPriority,
  copyWorksheet,
  updateAssessment,
})

export default connect(mapState, mapDispatch)(withRouter(PreTest))
