import SectionIcon from '../images/icons/Section.svg'
import LectureIcon from '../images/icons/VideoCamera.svg'
import QuizIcon from '../images/icons/PencilSimpleLine.svg'
import TestIcon from '../images/icons/ClipboardText.svg'
import PostTestIcon from '../images/icons/posttest-02.svg'

const createCurriculumAddTestData = [
  {
    id: 0,
    translate: 'course.content.section',
    title: 'Section',
    name: SectionIcon,
    fill: '#7AA0FD',
    // bgColor: 'linear-gradient(130deg, rgba(5,30,40,1) 0%, rgba(73,114,255,1) 100%)',
  },
  {
    id: 1,
    translate: 'course.content.lecture',
    title: 'Lecture',
    name: LectureIcon,
    fill: '#7AA0FD',
  },
  { id: 2, translate: 'course.content.quiz', title: 'Quiz', name: QuizIcon, fill: '#7AA0FD' },
  {
    id: 3,
    translate: 'course.content.pretest',
    title: 'Pre-Test',
    name: TestIcon,
    fill: '#7AA0FD',
  },
  {
    id: 4,
    translate: 'course.content.posttest',
    title: 'Post-Test',
    name: TestIcon,
    fill: '#7AA0FD',
  },
  // { id: 5, title: 'Clone from another course' },
]

const disabledAddContentData = [
  {
    id: 0,
    translate: 'course.content.section',
    title: 'Section',
    name: SectionIcon,
    fill: '#7AA0FD',
    // bgColor: 'linear-gradient(130deg, rgba(5,30,40,1) 0%, rgba(73,114,255,1) 100%)',
  },
  // {
  //   id: 1,
  //   translate: 'course.content.lecture',
  //   title: 'Lecture',
  //   name: LectureIcon,
  //   fill: '#7AA0FD',
  //   disabled: true,
  // },
  // {
  //   id: 2,
  //   translate: 'course.content.quiz',
  //   title: 'Quiz',
  //   name: QuizIcon,
  //   fill: '#7AA0FD',
  //   disabled: true,
  // },
  {
    id: 3,
    translate: 'course.content.pretest',
    title: 'Pre-Test',
    name: TestIcon,
    fill: '#7AA0FD',
    // disabled: true,
  },
  {
    id: 4,
    translate: 'course.content.posttest',
    title: 'Post-Test',
    name: PostTestIcon,
    fill: '#7AA0FD',
    // disabled: true,
  },
  // { id: 5, title: 'Clone from another course' },
]

const createCurriculumData = [
  {
    id: 0,
    translate: 'course.content.section',
    title: 'Section',
    name: SectionIcon,
    fill: '#7AA0FD',
  },
  {
    id: 1,
    translate: 'course.content.lecture',
    title: 'Lecture',
    name: LectureIcon,
    fill: '#7AA0FD',
  },
  { id: 2, translate: 'course.content.quiz', title: 'Quiz', name: QuizIcon, fill: '#7AA0FD' },
  // { id: 5, title: 'Clone from another course' },
]

const createCurriculumData2 = [
  // {
  //   id: 0,
  //   translate: 'course.content.section',
  //   title: 'Section',
  //   name: SectionIcon,
  //   fill: '#7AA0FD',
  // },
  {
    id: 1,
    translate: 'course.content.lecture',
    title: 'Lecture',
    name: LectureIcon,
    fill: '#7AA0FD',
  },
  { id: 2, translate: 'course.content.quiz', title: 'Quiz', name: QuizIcon, fill: '#7AA0FD' },
  // { id: 5, title: 'Clone from another course' },
]

export {
  createCurriculumData,
  createCurriculumAddTestData,
  disabledAddContentData,
  createCurriculumData2,
}
