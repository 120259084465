import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import Modal from '../modal/loadable'
import ErrorBoundary from '../errorBoundary'
import { Desktop, Mobile } from '../../utils/displayResponsive'
import { customAddCourseModal, mobileModalStyle } from './customAddCourseModal'
import SchoolCreateCourseLayout from '../../layouts/schoolCreateCourseLayout'

class AddCourseModal extends Component {
  render() {
    const { isOpen, handleModal } = this.props

    return (
      <ErrorBoundary>
        <Desktop>
          <Modal
            isOpen={isOpen}
            overlayStyle={customAddCourseModal.overlay}
            contentStyle={customAddCourseModal.content}
          >
            <SchoolCreateCourseLayout handleModal={handleModal} />
          </Modal>
        </Desktop>
        <Mobile>
          <Modal
            isOpen={isOpen}
            overlayStyle={mobileModalStyle.overlay}
            contentStyle={mobileModalStyle.content}
          >
            <SchoolCreateCourseLayout handleModal={handleModal} />
          </Modal>
        </Mobile>
      </ErrorBoundary>
    )
  }
}

// AddCourseModal.defaultProps = {
//   isOpen: false,
//   onRequestClose: () => {},
// }

// AddCourseModal.propTypes = {
//   isOpen: PropTypes.bool,
//   onRequestClose: PropTypes.func,
// }

export default AddCourseModal
