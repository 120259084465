import { SchoolAPI } from '../../api'

export const SchoolPayments = {
  state: {
    schoolPayments: [],
  },
  reducers: {
    setSchoolPayments(state, payload) {
      return {
        ...state,
        schoolPayments: payload,
      }
    },
  },
  effects: () => ({
    async getSchoolPayments({ schoolId }) {
      const result = await SchoolAPI.getSchoolPayments({ id: schoolId })
      this.setSchoolPayments(result)
      return result
    },
  }),
}
