import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import { Typography } from '../index'

const ScheduleDateTime = styled.div`
  display: grid;
  gap: 10px;
  position: relative;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  width: auto;
  padding: 6px 11px;
  border: 1px solid #d5d5d5;
  transition: all 0.2s;
  border-radius: 4px;
  outline: none;
  margin: 0;
  color: #333;
  font-size: ${({ theme }) => theme['text-sm']};
  line-height: 1.5;
  list-style: none;

  :hover {
    border-color: gray;
  }

  :hover div {
    border-color: gray;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 79px;
  }
  
  .date-time {
    margin: 3px 3px 0px 10px
    width: 100%;
    color: #333;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;

    ::placeholder {
      color: ${(props) => props.theme.black38};
    }
  }

`
const Item = styled.div`
  display: inline-flex;
  align-items: center;
  transition: all 0.2s
  cursor: pointer;
`

const _Typography = styled(Typography)`
  margin: 3px 3px 0px 10px;
  transition: all 0.2s
  :hover {
    color: gray;
  }
`

const Line = styled.div`
  transition: all 0.2s
  position: absolute;
  height: 27px;
  border-right: 1px solid #d8d8d8;
`

const Icon = styled.img`
  width: 18px;
`

export { ScheduleDateTime, Item, _Typography as Typography, Line, Icon }
