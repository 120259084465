import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import ErrorBoundary from '../errorBoundary'
import { Footer, Container, Body, CloseIcon, AspectRatio } from './style'
import { Button, Typography } from '../'
import getCroppedImg from './cropImage'
import Loader from '../loader'
import CancelIcon from 'images/icons/Cancel.svg'
import FourToThreeIcon from 'images/icons/Classwin-aspect-ratio-four-to-three.png'
import SixteenToNineIcon from 'images/icons/Classwin-aspect-ratio-sixteen-to-nine.png'
import FocusFourToThreeIcon from 'images/icons/Classwin-aspect-ratio-four-to-three-focus.png'
import FocusSixteenToNineIcon from 'images/icons/Classwin-aspect-ratio-sixteen-to-nine-focus.png'

const Cropable = ({
  children,
  fileType,
  image,
  afterCrop,
  cropShape,
  // aspect,
  aspect: aspectProp,
  hasCustomAspect = false,
  handleClearFile,
  cropLabel,
  cropLabelId,
  isModal,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  // eslint-disable-next-line no-unused-vars
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [croppedImage, setCroppedImage] = useState(null)
  const [zoom, setZoom] = useState(1)
  const [aspect, setAspect] = useState(hasCustomAspect ? 4 / 3 : 16 / 9)
  const [loading, setLoading] = useState(false)
  // const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (aspectProp) {
      setAspect(aspectProp)
    }
  }, [aspectProp])

  const style = {
    containerStyle: {
      zIndex: 7,
      position: 'fixed',
    },
  }
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const keepInLocalStore = async (blob) => {
    afterCrop && afterCrop(blob)
  }

  const showCroppedImage = useCallback(async () => {
    try {
      setLoading(true)
      let cropped = ''
      Promise.all([
        (cropped = await getCroppedImg(image, croppedAreaPixels, rotation, fileType)),
        await keepInLocalStore(cropped),
        setCroppedImage(cropped),
        // setIsOpen(false),
      ]).finally(() => {
        setLoading(false)
      })
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels, rotation])
  // console.log(hasCustomAspect)
  if (!image) return children || null
  if (loading) return <Loader />

  return isModal ? (
    <ErrorBoundary>
      <Container className="cropable-container">
        <Body>
          <div className="header">
            <Typography h3 color="primary1" id={cropLabelId}>
              {cropLabel}
            </Typography>
            <CloseIcon onClick={handleClearFile}>
              <img src={CancelIcon} alt="cancel" />
            </CloseIcon>
          </div>
          <div className="cropper">
            <Cropper
              image={image}
              aspect={aspect}
              crop={crop}
              zoom={zoom}
              cropShape={cropShape}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="footer">
            {hasCustomAspect && (
              <div className="aspect-ratio">
                <Typography title2 padding={'0 40px 0 0'}>
                  Aspect Ratio:
                </Typography>
                <AspectRatio onClick={() => setAspect(4 / 3)}>
                  <div className={aspect === 4 / 3 ? 'active' : ''}>
                    <img
                      src={aspect === 4 / 3 ? FocusFourToThreeIcon : FourToThreeIcon}
                      className="four-to-three"
                      alt="FourToThreeIcon"
                    />
                    <Typography color={aspect === 4 / 3 ? 'primary2' : ''} bold>
                      4:3
                    </Typography>
                  </div>
                </AspectRatio>
                <AspectRatio onClick={() => setAspect(16 / 9)}>
                  <div className={aspect === 16 / 9 ? 'active' : ''}>
                    <img
                      src={aspect === 16 / 9 ? FocusSixteenToNineIcon : SixteenToNineIcon}
                      className="sixteen-to-nine"
                      alt="SixteenToNineIcon"
                    />
                    <Typography color={aspect === 16 / 9 ? 'primary2' : ''} bold>
                      16:9
                    </Typography>
                  </div>
                </AspectRatio>
              </div>
            )}
            <Button
              id="button.Crop"
              className="button"
              primary
              type="button"
              width="30%"
              onClick={showCroppedImage}
            >
              Crop
            </Button>
          </div>
        </Body>
      </Container>
    </ErrorBoundary>
  ) : (
    <ErrorBoundary>
      <Container>
        <Cropper
          image={image}
          style={style}
          aspect={aspect ? aspect : 16 / 9}
          crop={crop}
          zoom={zoom}
          cropShape={cropShape}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
        <Footer>
          <Button
            id="button.Crop"
            primary
            type="button"
            onClick={showCroppedImage}
            width="30%"
            style={{ marginTop: '20px' }}
          >
            Crop
          </Button>
        </Footer>
      </Container>
    </ErrorBoundary>
  )
}

export default Cropable
