import styled, { css } from 'styled-components'
import Typography from '../typography'

export const Label = styled.label`
  display: block;
  margin-bottom: ${(props) => (props.noMargin ? '0px' : '8px')};
  color: ${(props) => props.theme.gray6};
  font-size: ${({ theme }) => theme['text-sm']};
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme['text-xs']};
  /* line-height: 12px; */
  color: ${(props) => props.theme.black38};

  ${({ fontSize, theme }) => {
    if (fontSize) {
      return css`
        font-size: ${theme[fontSize] || theme['text-xs']};
      `
    }
  }}
`
