import styled, { css } from 'styled-components'
import Typography from '../typography'
import theme from '../../theme'

const Avatar = styled.div`
  border: ${(props) => (props.noneBorder ? 'none' : `1px solid ${theme.gray4}`)};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-image: url('https://via.placeholder.com/100');
  background-size: cover;
  background-position: center;
  flex-shrink: 0;

  ${({ borderSchool }) => css`
    border: ${borderSchool}px solid ${theme.primary1};
  `}
  ${({ marginLeft }) => css`
    margin-left: ${marginLeft}px;
  `}

  ${({ marginRight }) => css`
    margin-right: ${marginRight}px;
  `}

  ${({ width }) => {
    if (width) {
      return css`
        width: ${width}px;
        height: ${width}px;
      `
    }
  }}


  ${({ size }) => {
    if (size === 'large') {
      return css`
        width: 130px;
        height: 130px;
      `
    }
    if (size === 'medium') {
      return css`
        width: 80px;
        height: 80px;
      `
    }
    if (size === 'small') {
      return css`
        width: 40px;
        height: 40px;

        @media (max-width: 425px) {
          width: 30px;
          height: 30px;
        }
      `
    }

    return css`
      width: ${size}px;
      height: ${size}px;
    `
  }}

  ${({ src }) => {
    if (src) {
      return css`
        background-image: url(${src});
      `
    }
    return css``
  }}

  ${({ margin }) => {
    if (margin) {
      return css`
        margin: ${margin};
      `
    }
    return css``
  }}
`

const Text = styled(Typography)`
  ${({ marginText }) => {
    if (marginText) {
      return css`
        margin: ${marginText};
      `
    }
    return css`
      margin-left: ${(props) => (props.nonMarginLabel ? '0px' : '12px')};
    `
  }}
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
`

export { Avatar, Text, Container }
