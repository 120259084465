/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import classNames from 'classnames'

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import hljs from 'highlight.js'
import ReactQuill, { Quill } from 'react-quill'
import 'highlight.js/styles/monokai-sublime.css'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import 'react-quill/dist/quill.snow.css'
import AutoLinks from 'quill-auto-links'

import { FileAPI } from '../../../../../api'
import EquationEditor from './equationEditor'
import Graph from './graph'
import ImagePicker from './imagePicker'
// import ImageResize from 'quill-image-resize-module-react'
import Label from '../../../../../components/label'
import Button from '../../../../../components/button'
import ErrorBoundary from '../../../../../components/errorBoundary'
import { Mobile, AllTablet, LargeDesktop } from 'utils/displayResponsive'
window.katex = katex

Quill.register('modules/EquationEditor', EquationEditor)
Quill.register('modules/Graph', Graph)
Quill.register('modules/ImagePicker', ImagePicker)
Quill.register('modules/autoLinks', AutoLinks)
// Quill.register('modules/imageResize', ImageResize)

hljs.configure({
  languages: ['javascript', 'ruby', 'python', 'rust', 'css', 'html', 'c#'],
})

const Container = styled.div`
  .editing-area {
    .ql-container {
      /* height: calc(100% - 100px); */
      /* height: 100%; */
      max-height: ${({ quillStyle: { maxHeight = '' } }) => maxHeight};
      overflow: ${({ quillStyle: { overflow = '' } }) => overflow};
      .ql-editor {
        min-height: ${(props) => (props.autoHeight ? 'auto' : '150px')};
        max-width: ${(props) => (props.maxWidth ? props.maxWidth : '950px')};
        min-width: 50px;
        border-radius: ${(props) => props.backgroundFeed && '8px'};
        border: ${(props) => props.backgroundFeed && '1.5px solid #6F6F6F50'};
        padding: ${({ padding }) => padding};
        cursor: text;
        strong {
          font-size: ${({ fontSize, theme }) => theme[fontSize] || theme['text-sm']};
          color: ${(props) => (props.color ? props.color : props.theme.black87)};
        }

        p {
          font-size: ${({ fontSize, theme }) => theme[fontSize] || theme['text-sm']};
          line-height: 1.5;
          word-break: break-word;
          font-weight: ${({ fontweight }) => fontweight};
          color: ${(props) => (props.color ? props.color : props.theme.white)};

          background: ${(props) => props.backgroundFeed && 'transparent'};
          padding: ${(props) => props.backgroundFeed && '6px 16px'};
          margin-left: ${(props) => props.backgroundFeed && '10px'};
          // border-radius: ${(props) => props.backgroundFeed && '8px'};
          // border: ${(props) => props.backgroundFeed && '1.5px solid #6F6F6F50'};

          span {
            /* all: unset; */
            font-size: ${({ fontSize, theme }) => theme[fontSize] || theme['text-sm']};
            color: inherit !important;
          }
        }

        h3 {
          span {
            font-size: ${({ theme }) => theme['text-base']};
          }
        }

        a {
          font-size: ${({ fontSize, theme }) => theme[fontSize] || theme['text-sm']};
        }
      }
    }

    .ql-clipboard {
      position: fixed;
    }
    .ql-toolbar {
      border-radius: 8px 8px 0px 0px;
      ${(props) =>
        props.noBorderRadius &&
        `
        border-radius 0px;
        border: none;
        border-bottom: 1px solid #a6a6a6;
      `}
      display: ${(props) => (props.showToolbar ? 'block' : 'none')};
    }
    .ql-toolbar.ql-snow {
      border-color: ${(props) => props.theme.black20};
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-color: ${(props) => props.theme.black20};
      border-top: ${(props) => (!props.showToolbar ? '1px solid #ccc' : '0px')};
      border-radius: 0px 0px 8px 8px;
      ${(props) =>
        props.noBorderRadius &&
        `
        border-radius 0px;
        border: none;
      `}
    }
  }
  .read-only {
    .ql-toolbar {
      display: none;
    }
    .ql-container {
      border: none;
      .ql-editor {
        min-height: 0;
        overflow: hidden;
      }
    }
    .ql-snow .ql-editor img {
      max-width: 15%;
    }
  }

  .read-only-with-toolbar {
    .ql-container {
      .ql-editor {
        overflow: hidden;
      }
    }
  }
  .show-all {
    /* height: 100%; */
  }
  .show-less {
    .ql-container {
      .ql-editor {
        max-height: ${({ editorMaxHeight }) => (editorMaxHeight ? `${editorMaxHeight}px` : '36em')};
        text-overflow: -o-ellipsis-lastline;
      }
    }
  }

  .quill > .ql-container > .ql-editor.ql-blank::before {
    color: rgba(0, 0, 0, 0.2);
    font-style: normal;
    font-size: ${({ fontSize, theme }) => theme[fontSize] || theme['text-sm']};
    left: ${({ noPadding, backgroundFeed }) =>
      noPadding ? '0px' : backgroundFeed ? '25px' : '15px'};
    width: ${({ backgroundFeed }) => backgroundFeed && '100%'};
    height: ${({ backgroundFeed }) => backgroundFeed && '100%'};
    display: ${({ backgroundFeed }) => backgroundFeed && 'flex'};
    align-items: ${({ backgroundFeed }) => backgroundFeed && 'center'};
  }
  .ql-editor .ql-video {
    width: 100%;
    height: 550px;
  }
  @media (max-width: 1024px) {
    .ql-editor .ql-video {
      display: block;
      max-width: 100%;
      width: 100%;
      height: 450px;
    }
  }
  @media (max-width: 820px) {
    .editing-area .ql-container .ql-editor {
      //max-width: 500px;
    }
  }
  @media (max-width: 425px) {
    .editing-area .ql-container .ql-editor {
      //max-width: 200px;
    }
    .ql-video {
      height: 250px;
    }
  }

  .ql-snow .ql-editor pre.ql-syntax {
    font-family: monospace;
    font-size: ${({ theme }) => theme['text-xxs']};
    & * {
      font-family: inherit;
      font-size: inherit;
    }
  }

  span[class^='hljs-function'] {
    color: inherit;
  }

  .ql-snow .ql-editor pre.ql-syntax {
    background-color: #272822;
    /* overflow: visible; */
  }
  .ql-editor ol > li,
  .ql-editor ul {
    padding-left: ${({ bulletPadding }) => `${bulletPadding}px`};
  }
  .ql-editor ul > li {
    font-size: ${({ fontSize, theme }) => theme[fontSize] || theme['text-sm']};
    color: ${(props) => (props.color ? props.color : props.theme.black87)};
    * {
      font-size: ${({ fontSize, theme }) => theme[fontSize] || theme['text-sm']};
      color: ${(props) => (props.color ? props.color : props.theme.black87)};
    }
  }
  .ql-editor ul > li::before {
    font-size: ${({ fontSize, theme }) => theme[fontSize] || theme['text-sm']};
    color: ${(props) => (props.color ? props.color : props.theme.black87)};
  }
`
// const Label = styled.label`
//   display: inline-block;
//   color: ${(props) => props.theme.primaryFont};
//   margin-bottom: 15px;
//   font-size: ${({ theme }) => theme['text-sm']};
//   /* margin-top: 10px; */
// `
const MyButton = styled((props) => <Button {...props} />)`
  box-shadow: none;
  // justify-content:start;
  // padding: 0px
  color: ${({ color, theme }) => color || theme.black20};
  //border: ${({ border, theme }) => border || `1px solid ${theme.black20}`};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize}px;
  svg {
    fill: ${({ color, theme }) => color || theme.black20};
    width: ${({ iconSize }) => `${iconSize}px`};
    height: ${({ iconSize }) => `${iconSize}px`};
  }
`

const LinearGradient = styled.div`
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #fff 100%); */
  height: ${({ editorMaxHeight }) => (editorMaxHeight ? `${editorMaxHeight}px` : '36em')};
  margin-top: -${({ editorMaxHeight }) => (editorMaxHeight ? `${editorMaxHeight}px` : '36em')};
`

class ClasswinEditor extends Component {
  state = {
    showAll: false,
    numLine: 2,
  }

  componentDidMount() {
    if (this.props.readOnly) {
      this.setState({
        numLine: this.getNumLine(),
        showAll: false,
      })
    }
  }
  componentDidUpdate(props) {
    if (this.props.readOnly && this.props.value !== props.value) {
      this.setState({
        numLine: this.getNumLine(),
        showAll: false,
      })
    }
  }

  getValue = () => {
    let value
    if (!this.props.value || _.isEmpty(this.props.value.trim())) {
      value = { content: '', delta: { ops: [] } }
    } else {
      value = JSON.parse(this.props.value)
    }
    if (this.props.readOnly) {
      return value.delta
    }
    return value.content
  }

  getNumLine = () => {
    const { calLineParams } = this.props
    if (this.divRef) {
      if (calLineParams) {
        return (this.divRef.clientHeight - calLineParams.subtract) / calLineParams.lineHeight
      }
      return this.divRef.clientHeight / 20
    }
    return 0
  }

  handleChange = (content, delta, source, editor) => {
    if (source === 'user') {
      let value = this.getValue()
      if (!this.props.value || _.isEmpty(this.props.value.trim())) {
        value = { content: '', delta: { ops: [] } }
      } else {
        value = JSON.parse(this.props.value)
      }
      const data = {
        ...value,
        content,
        delta: editor.getContents(),
      }
      if (this.props.onChange) {
        this.props.onChange(JSON.stringify(data))
      }
    }
  }

  viewAllContent = () => {
    this.setState({
      showAll: true,
    })
  }

  viewContent = () => {
    this.setState({
      showAll: false,
    })
  }

  render() {
    const { numLine, showAll } = this.state
    const {
      translateId,
      readOnlyWithToolBar,
      readOnly,
      isShowAll,
      disabledShowAll,
      style,
      autoHeight,
      isMainFeed,
      showToolbar,
      quillStyle,
      min,
      padding,
      modules,
      noBorderRadius = false,
      fontSize,
      label,
      labelFontSize,
      bulletPadding,
      editorMaxHeight,
      editorMaxWidth,
      buttonStyles,
      noPadding,
      backgroundFeed,
      paddingComment,
      maxWidth,
      fontweight,
      isComment,
    } = this.props

    const minLine = min ? min : 3
    const editorClass = classNames({
      'editing-area': true,
      'read-only-with-toolbar': readOnlyWithToolBar,
      'read-only': readOnly,
      'show-all': isShowAll,
      'show-less': !isShowAll && numLine > minLine && !showAll,
    })

    return (
      <ErrorBoundary>
        <Container
          className="classwin-editor"
          ref={(element) => (this.divRef = element)} // eslint-disable-line
          style={style}
          noBorderRadius={noBorderRadius}
          color={this.props.color}
          autoHeight={autoHeight}
          padding={padding}
          showToolbar={showToolbar}
          quillStyle={quillStyle}
          fontSize={fontSize}
          bulletPadding={bulletPadding}
          editorMaxHeight={editorMaxHeight}
          noPadding={noPadding}
          backgroundFeed={backgroundFeed}
          paddingComment={paddingComment}
        >
          {label && (
            <Label id={translateId} className="label" fontSize={labelFontSize}>
              {label}
            </Label>
          )}
          <ReactQuill
            className={editorClass}
            theme="snow"
            onChange={this.handleChange}
            value={this.getValue()}
            modules={modules ? modules : ClasswinEditor.modules}
            formats={ClasswinEditor.formats}
            placeholder={this.props.placeholder}
            readOnly={this.props.readOnly || this.props.readOnlyWithToolBar}
            bounds=".classwin-editor"
            onKeyDown={this.props.onKeyDown}
          />
          {this.props.readOnly && !this.props.isShowAll && numLine > minLine && (
            <Fragment>
              {!showAll && <LinearGradient editorMaxHeight={editorMaxHeight} />}
              <div style={{ display: 'flex', justifyContent: 'start' }}>
                <LargeDesktop>
                  <MyButton
                    id={showAll ? 'button.editor.showLess' : 'button.editor.showMore'}
                    //icon={showAll ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    reverse
                    bgColor="transparent"
                    color={buttonStyles?.color}
                    border={buttonStyles?.border}
                    fontSize={12}
                    fontWeight={buttonStyles?.fontWeight}
                    //iconSize={buttonStyles?.iconSize}
                    onClick={showAll ? this.viewContent : this.viewAllContent}
                    style={{
                      padding: '0px 0px 0px 12px',
                      display: 'flex',
                      justifyContent: 'start',
                      //backgroundColor: 'red',
                      color: isComment ? '#fff' : '#AAADB9',
                    }}
                  >
                    {showAll ? 'Show less' : 'Show more'}
                  </MyButton>
                </LargeDesktop>
                {/* <AllTablet>
                  <MyButton
                    id={showAll ? 'button.editor.showLess' : 'button.editor.showMore'}
                    icon={showAll ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    reverse
                    bgColor="transparent"
                    color={buttonStyles?.color}
                    border={buttonStyles?.border}
                    fontSize={buttonStyles?.fontSize}
                    fontWeight={buttonStyles?.fontWeight}
                    iconSize={buttonStyles?.iconSize}
                    onClick={showAll ? this.viewContent : this.viewAllContent}
                    style={{
                      padding: '32px 50px 0px 50px',
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                  >
                    {showAll ? 'Show less' : 'Show more'}
                  </MyButton>
                </AllTablet>
                <Mobile>
                  <MyButton
                    id={showAll ? 'button.editor.showLess' : 'button.editor.showMore'}
                    icon={showAll ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    reverse
                    bgColor="transparent"
                    color={buttonStyles?.color}
                    border={buttonStyles?.border}
                    fontSize={buttonStyles?.fontSize}
                    fontWeight={buttonStyles?.fontWeight}
                    iconSize={buttonStyles?.iconSize}
                    onClick={showAll ? this.viewContent : this.viewAllContent}
                    style={{
                      padding: '32px 20px 0px 20px',
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                  >
                    {showAll ? 'Show less' : 'Show more'}
                  </MyButton>
                </Mobile> */}
              </div>
            </Fragment>
          )}
        </Container>
      </ErrorBoundary>
    )
  }
}

ClasswinEditor.modules = {
  EquationEditor: {
    handler(value = '') {
      // eslint-disable-next-line
      const formula = prompt('Enter latex formula', value)
      if (formula) {
        return Promise.resolve(formula)
      }
      return Promise.reject(new Error('Cancel'))
    },
  },
  Graph: {
    handler(value = '') {
      // eslint-disable-next-line
      const formula = prompt('Enter function', value)
      if (formula) {
        return Promise.resolve(formula)
      }
      return Promise.reject(new Error('Cancel'))
    },
  },
  ImagePicker: {
    handler() {
      const fileInput = document.createElement('input')
      fileInput.setAttribute('type', 'file')
      fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon')
      fileInput.click()
      return new Promise((resolve, reject) => {
        fileInput.addEventListener('change', async () => {
          if (fileInput.files != null && fileInput.files[0] != null) {
            const file = fileInput.files[0]
            const formData = new FormData()
            formData.append('file', file)
            const res = await FileAPI.upload(formData)
            const imageName = res.result.files.file[0].name
            const imageUrl = FileAPI.getUrl(imageName)
            return resolve(imageUrl)
          }
          return reject(new Error('Cancel'))
        })
      })
    },
  },
  // imageResize: {
  //   modules: ['Resize', 'DisplaySize', 'Toolbar'],
  // },
  autoLinks: true,
  formula: true,
  syntax: {
    highlight: (text) => hljs.highlightAuto(text).value,
  },
  toolbar: [
    [{ header: 1 }, { header: 2 }],

    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ color: [] }, { background: [] }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ indent: '-1' }, { indent: '+1' }],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],

    ['link', 'image', 'video'],

    ['formula', 'graph'],

    ['clean'],
  ],
}

ClasswinEditor.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'color',
  'background',
  'align',
  'indent',
  'list',
  'bullet',
  'script',
  'link',
  'image',
  'video',
  'formula',
  'graph',
]

ClasswinEditor.defaultProps = {
  readOnly: false,
  isShowAll: true,
  style: {},
  showToolbar: true,
  quillStyle: {},
  noPadding: false,
  backgroundFeed: false,
}
ClasswinEditor.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  isShowAll: PropTypes.bool,
  style: PropTypes.object,
  autoHeight: PropTypes.bool,
  isMainFeed: PropTypes.bool,
  showToolbar: PropTypes.bool,
  noPadding: PropTypes.bool,
  backgroundFeed: PropTypes.bool,
  // editorStyle: PropTypes.object,
}

export default ClasswinEditor
