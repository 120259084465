import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import Typography from '../typography'
import Eye from '../../images/icons/Eye.svg'
import EyeSlash from '../../images/icons/EyeSlash.svg'
// import { Mobile } from '../../utils/displayResponsive'

// import Typography from '../typography'
import Button from '../button'
import TextInput from '../textInput'
import { PasswordInput, Header, ContainerSignup, HeaderTitle, Text } from './styles'
// import theme from 'theme'

export class LoginForm extends Component {
  state = {
    isShowPassword: true,
  }
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  }

  handleShowPassword = () => {
    const { isShowPassword } = this.state
    this.setState({ isShowPassword: !isShowPassword })
  }

  render() {
    const { isShowPassword } = this.state
    const { onSubmit, setTypeModal, from, hideResetPassword } = this.props
    return (
      <>
        <Header>
          <HeaderTitle bold color="primary1" id="welcome.toclasswin">
            Welcome to Classwin
          </HeaderTitle>
        </Header>

        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(
                <Typography
                  style={{ textTransform: 'none' }}
                  id="login.alert.email"
                  fontSize="text-xs"
                  color="danger"
                >
                  Email is incorrect. Please try again.
                </Typography>
              )
              .required(
                <Typography
                  style={{ textTransform: 'none' }}
                  id="login.alert.required"
                  fontSize="text-xs"
                  color="danger"
                >
                  Required
                </Typography>
              ),
            password: Yup.string().required(
              <Typography
                style={{ textTransform: 'none' }}
                id="login.alert.required"
                fontSize="text-xs"
                color="danger"
              >
                Required
              </Typography>
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values)
            setSubmitting(false)
          }}
        >
          {(props) => {
            const { values, errors, handleChange, handleSubmit } = props
            const enabled = values.email && values.password
            return (
              <>
                <form onSubmit={handleSubmit}>
                  {/* <Typography id="login.title" h2 padding="10px 0px 20px">
                  Login
                </Typography> */}
                  <TextInput
                    id="login.label.email"
                    value={values.email}
                    error={errors.email}
                    name="email"
                    placeholder="example@email.com"
                    data-testid="login-form-email-input"
                    onChange={handleChange}
                    style={{ marginBottom: 0 }}
                    inputStyle={{ lineHeight: 8 }}
                    type="email"
                    isLogin
                    full
                  />
                  <PasswordInput>
                    <TextInput
                      id="login.label.password"
                      value={values.password}
                      error={errors.password}
                      data-testid="login-form-password-input"
                      style={{ marginBottom: 0 }}
                      type={isShowPassword ? 'password' : 'text'}
                      name="password"
                      placeholder="*********"
                      onChange={handleChange}
                      isLogin
                      full
                    />
                    {isShowPassword && (
                      // <IoIosEye className="icon" onClick={this.handleShowPassword} />
                      <img
                        src={Eye}
                        className="icon"
                        alt="showPassword"
                        onClick={this.handleShowPassword}
                      />
                    )}
                    {!isShowPassword && (
                      // <IoIosEyeOff className="icon" onClick={this.handleShowPassword} />
                      <img
                        src={EyeSlash}
                        className="icon"
                        alt="hidePassword"
                        onClick={this.handleShowPassword}
                      />
                    )}
                    <ContainerSignup>
                      {setTypeModal ? (
                        <div style={{ display: 'flex' }}>
                          {/* <DesktopAndTablet>
                            <Text id="not.register.yet" margin="0 2px 0 0">
                              do not an account
                            </Text>
                          </DesktopAndTablet> */}
                          <div style={{ cursor: 'pointer' }} onClick={() => setTypeModal('signup')}>
                            <Text id="login.label.create.account" color="primary1">
                              Create a new account
                            </Text>
                          </div>
                        </div>
                      ) : (
                        <div style={{ display: 'flex' }}>
                          {/* <DesktopAndTablet>
                            <Text id="not.register.yet" color="gray2" margin="0 4px 0 0">
                              do not an account
                            </Text>
                          </DesktopAndTablet> */}
                          <Link
                            to={{
                              pathname: '/user/register',
                              state: { from },
                            }}
                          >
                            <Text bold id="login.label.create.account" color="primary1">
                              Create a new account
                            </Text>
                          </Link>
                        </div>
                      )}

                      {!hideResetPassword && (
                        <Link to="/user/forgotPassword">
                          <Text
                            bold
                            color="primary1"
                            textAlign="right"
                            id="login.label.forget.password"
                          >
                            Forgot password?
                          </Text>
                        </Link>
                      )}
                    </ContainerSignup>
                  </PasswordInput>

                  <Button
                    id="login.title"
                    data-testid="login-submit-button"
                    primaryDisabled={!enabled}
                    primary={enabled}
                    fontStyle={{ fontSize: '16px' }}
                    style={{ height: 45, marginTop: 10 }}
                    full
                  >
                    Login
                  </Button>
                </form>
              </>
            )
          }}
        </Formik>
      </>
    )
  }
}

export default LoginForm
