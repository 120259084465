import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import ErrorBoundary from '../../components/errorBoundary'
import { Box } from '../../components/styledComponents'
import Button from '../../components/button'
import TextInputBox from '../../components/textInputBox/loadable'
import UploadComponent from '../../components/uploadComponent'
import ImageIcon from '../../images/ic-addphoto-gray-24-px.svg'
import getImageUrl from '../../utils/getImageUrl'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('Required'),
})

const Container = styled(Box)`
  padding: 30px;
  margin-top: 30px;

  input[type='text'] {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 15px;
    margin-top: 15px;
  }
`

const ButtonGroup = styled.div`
  button {
    width: 100px;

    & + button {
      margin-left: 10px;
    }
  }
`
const FileInput = styled.div`
  border: 1px dashed rgba(194, 194, 194, 0.5);
  padding: 10px;
  padding-right: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  button {
    margin-left: auto;
  }

  .text {
    color: ${(props) => props.theme.grey};
    margin-left: 50px;
  }

  .background--color {
    padding: 30px 55px;
    background: #f3f3f3;
    border-radius: 4px;
    width: 150px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;

    button,
    .text {
      margin-left: 0;
    }

    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100vw - 200px);
  /* width: 100vw; */
  /* flex: 1; */
  background-color: #fff;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100vw;
    padding: 10px 15px;
  }
`

const Image = styled.div`
  width: 150px;
  height: 100px;
  /* background-image: ${(props) =>
    `linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(${props.src})`}; */
  background-image: ${(props) => `url(${props.src})`};
  background-position: center;
  background-size: cover;
`

class TeacherCourseLectureEditInformationTab extends Component {
  componentDidMount = () => {
    const {
      getCourse,
      getCourseLectureByLectureId,
      match: {
        params: { courseId, lectureId },
      },
    } = this.props

    getCourse(courseId)
    getCourseLectureByLectureId({ courseId, lectureId })
  }

  handleSubmit = (values) => {
    const { updateCourseLecture } = this.props
    updateCourseLecture(values)
  }

  render() {
    const {
      lectureInfo,
      lectureInfo: { image = '', name = '', description = '' },
    } = this.props
    console.log('lectureInfo', lectureInfo)
    return (
      <ErrorBoundary>
        <Formik
          enableReinitialize
          initialValues={{ image, name, description }}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          render={({ values, errors, handleChange, setFieldValue }) => (
            <Form>
              <Container>
                <UploadComponent
                  afterUpload={(value) => {
                    console.log('afterUpload -- value', value)
                    setFieldValue('image', value.name)
                  }}
                >
                  <FileInput>
                    {values.image ? (
                      <Image src={getImageUrl(values.image)} />
                    ) : (
                      <div className="background--color">
                        <img src={ImageIcon} alt="img-icon" />
                      </div>
                    )}

                    <div className="text">Click add photo to lecture</div>
                    <Button width="140" primary>
                      Add Photo
                    </Button>
                  </FileInput>
                </UploadComponent>
                <TextInputBox
                  name="name"
                  label="Lecture :"
                  value={values.name}
                  error={errors.name}
                  onChange={handleChange}
                />
                <TextInputBox
                  name="description"
                  label="Description :"
                  type="textarea"
                  value={values.description}
                  error={errors.description}
                  onChange={handleChange}
                />
              </Container>
              <Footer>
                <ButtonGroup>
                  <Button type="button" secondary>
                    Cancel
                  </Button>
                  <Button type="submit" primary>
                    Save
                  </Button>
                </ButtonGroup>
              </Footer>
            </Form>
          )}
        />
      </ErrorBoundary>
    )
  }
}

const mapState = (state) => ({
  appUser: state.AppUser,
  course: state.teachingCourse.course,
  lectureInfo: state.teachingCourse.lectureInfo,
})

const mapDispatch = (dispatch) => {
  const {
    AppUser: { login },
    teachingCourse: { getCourse, getCourseLectureByLectureId, updateCourseLecture },
  } = dispatch
  return {
    login,
    getCourse,
    getCourseLectureByLectureId,
    updateCourseLecture,
  }
}

export default connect(mapState, mapDispatch)(TeacherCourseLectureEditInformationTab)
