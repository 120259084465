/* eslint-disable no-unused-vars */
import React, { useState, Suspense } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { Formik, Form, FieldArray, Field } from 'formik'
//import { Form, Field } from 'formik'
import { SchoolAPI, CourseAPI } from 'api'
import {
  AutoApprove,
  GridDurationAndPricing,
  Content,
  Header,
  Line,
  Grid,
  Itemradio,
  RadioContainer,
} from './styled'
import { Button, Typography, Switch, Card } from '../../components'
import CustomReactSelect from '../../components/customReactSelect'
import CustomFieldDuration from '../../components/customFieldDuration/loadable'
import CustomFieldPrice from '../../components/customFieldPrice/loadable'
import ConfirmModal from '../../components/confirmModal/loadable'
import BadgeAndSkill from '../courseInformationPage/BadgeAndSkills'
import { MobileAndTablet } from 'utils/displayResponsive'
import * as yup from 'yup'
import MyLoader from '../courseInstructorPage/myLoader'
import InstructorFooter from '../courseInstructorPage/instructorFooter'
import RadioButton from 'components/customRadioButton'

import { withRouter } from 'react-router-dom'
const StyledButton = styled(Button)`
  /* color: ${(props) => props.theme.danger}; */
  color: #fff;
  background-color: ${(props) => props.theme.danger};
  border: 3px solid ${(props) => props.theme.danger};
  padding: 12px;
  width: 155px;
  height: 40px;
  line-height: 2px;
  font-weight: 600;
  box-sizing: border-box;
  border-radius: 6px;

  &.publish {
    background-color: ${(props) => props.theme.success} !important;
    border: none;
  }
  .unpublish {
    /* border: 3px solid ${(props) => (props.disabled ? '#8f8f8f' : props.theme.danger)}; */
  }

  .delete {
  }
  &.clone {
    color: ${(props) => props.theme.primary1} !important;
    background-color: ${(props) => props.theme.white} !important;
    border: 1px solid ${(props) => props.theme.primary1};
  }

  &:disabled {
    border: 1px solid ${(props) => props.theme.grey};
    color: ${(props) => props.theme.grey};
    font-weight: 600;
    background-color: transparent;
  }
`
const StyledParagraph = styled(Typography)`
  color: ${({ theme }) => theme.primary1};
  font-size: ${({ theme }) => theme['text-sm']};
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 100%;
`
const ButtonWrapper = styled.div`
  display: flex;
  :not(:first-of-type) {
    margin-top: 25px;
  }
`
export const validationSchema = yup.object().shape({
  teachers: yup.array().min(0),
})
const InstructorCard = React.lazy(() => import('../courseInstructorPage/instructorCard'))
export const CourseSettingPage = (props) => {
  const [isOpenModal, setOpenModal] = useState(false)
  const [limitation, setLimitation] = useState('online')
  useEffectOnce(() => {
    const {
      getTeacherList,
      match: {
        params: { schoolId },
      },
    } = props

    getTeacherList(schoolId)
  })
  const getSchoolCourses = async (value) => {
    const { match } = props
    const courses = await SchoolAPI.getSchoolCourse({
      id: match.params.schoolId,
      filter: JSON.stringify({
        where: {
          name: {
            like: value,
            options: 'i',
          },
        },
        fields: {
          id: 1,
          name: 1,
        },
      }),
    })
    return courses
  }
  const handleSubmit = (values) => {
    const {
      match: {
        params: { courseId, schoolId },
      },
      // draftInfo
      publicMode,
      name,
      updateCourse,
    } = props

    updateCourse({
      name,
      id: courseId,
      schoolId,
      publicMode,
      _teachers: values.teachers,
      customTeacherList: values.customTeacherList,
    })
  }
  const handleDelete = async () => {
    const {
      deleteDraft,
      match: {
        params: { courseId, schoolId },
      },
      history,
    } = props
    await deleteDraft({ id: courseId })
    setOpenModal(false)
    history.replace(`/schools/${schoolId}/curriculum/school-courses`)
  }

  const texts = [
    { prefix: 'From', name: 'StartDate' },
    { prefix: 'To', name: 'EndDate' },
  ]

  const handleUnpublishCourse = () => {
    const {
      match: {
        params: { courseId },
      },
      unpublishCourse,
    } = props
    unpublishCourse({ id: courseId, isHidden: true })
  }

  const handlePublishCourse = () => {
    const {
      match: {
        params: { courseId },
      },
      unpublishCourse,
    } = props
    unpublishCourse({ id: courseId, isHidden: false })
  }

  const handleSetApprove = async () => {
    const {
      editCourse,
      getCourse,
      autoApprove,
      match: {
        params: { courseId },
      },
      isPublished,
    } = props

    if (isPublished) {
      await editCourse({ id: courseId, autoApprove: !autoApprove })
      await getCourse(courseId)
    } else {
      await editCourse({ id: courseId, autoApprove: !autoApprove, notAlert: true })
      await getCourse({ id: courseId })
    }
  }

  const promiseOptions = async (inputValue) => {
    const courses = await getSchoolCourses(inputValue)
    return courses.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  }

  const options = [
    { label: 'No Limit', value: 'online' },
    { label: 'Duration and Student Limiting', value: 'classroom' },
  ]
  const optionsTH = [
    { label: 'ไม่มีกำหนด', value: 'online' },
    { label: 'กำหนดระยะเวลาและจำนวนคน', value: 'classroom' },
  ]
  // const tutorialLink = 'http://classwin.co/course-delete/'
  const {
    type,
    // publishStartDate,
    // publishEndDate,
    // registerStartDate,
    // registerEndDate,
    // maxStudent,
    isPublished,
    isHidden,
    autoApprove: isApprove,
    publicMode,
    studentCount,
    teacherList,

    _teachers,
    customTeacherList,
    formRef,
    locale,
  } = props
  const { values, setFieldValue, setFieldTouched, errors, touched, handleChange } =
    props.formikProps

  return (
    <>
      <ConfirmModal
        isOpen={isOpenModal}
        onRequestClose={() => setOpenModal(false)}
        title={
          locale === 'en' ? 'Are you sure you want to delete?' : 'คุณแน่ใจหรือไม่ว่าต้องการลบ?'
        }
        titleId="modal.sure.delete"
        // subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry…."
        subtitleId="modal.sure.delete.subtitle"
        btn1={
          <Button id="button.confirmModal" onClick={handleDelete} danger>
            Submit
          </Button>
        }
        btn2={
          <Button id="button.cancel" secondary onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
        }
      />
      {/* <SubHeader>
                <div className="wrapper">
                  <div className="main-header">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography h3 className="title" id="course.setting.title">
                          Setting
                        </Typography>

                        <a href={tutorialLink} target="_blank" rel="noreferrer">
                          <Icon
                            style={{ width: '20px', height: '20px', margin: '10px' }}
                            src={TutorialIcon}
                          />
                        </a>
                      </div>
                      <Description id="course.setting.subtitle">Edit your course</Description>
                    </div>
                    <Button
                      type="submit"
                      id="button.save"
                      primary
                      bold
                      onClick={formikHandleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </SubHeader> */}

      <Card padding="24px 32px" margin="16px 0 0">
        <Typography id="course.setting.prerequisite" h4 bold margin="0 0 24px">
          Prerequisite
        </Typography>

        <CustomReactSelect
          id="course.setting.prerequisite.desc"
          label="The set of courses that students must be completed before enrolling these courses"
          placeholder="Search..."
          name="baseCourses"
          isMulti
          isAsync
          value={values.baseCourses}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.baseCourses}
          touched={touched.baseCourses}
          loadOptions={promiseOptions}
          color="primary1"
          bold={false}
        />
      </Card>
      {publicMode === 'CODE' && (
        <Card padding="24px 32px" margin="16px 0 0">
          <Typography id="course.setting.approval" h4 bold margin="0 0 24px">
            Approval Setting
          </Typography>
          <AutoApprove>
            <Switch
              type="checkbox"
              name="autoApprove"
              // checked={values.isGraduated}
              // onChange={(value) => {
              //   const e = {
              //     target: {
              //       name: 'isGraduated',
              //       value,
              //     },
              //   }
              //   handleChange(e)
              // }}
              checked={isApprove}
              onChange={handleSetApprove}
            />
            <Typography id="setting.newCourse" margin="0 15px" color="primary1">
              Automatically Approve Members
            </Typography>
          </AutoApprove>
        </Card>
      )}
      <Card padding="24px 32px" margin="16px 0 0">
        <Typography id="course.setting.title" h4 bold margin="0 0 24px">
          Limitation
        </Typography>
        <Itemradio>
          {/* Course Image */}
          <RadioContainer>
            <RadioButton
              field={{
                name: 'type',
                value: limitation,
                onChange: () => {
                  setLimitation('online')
                  //  setCourseImageTypeState('chooseFile')
                  setFieldValue('type', 'online')
                },
              }}
              id="online"
              label={props.locale === 'en' ? 'No Limit' : 'ไม่มีกำหนด'}
              labelId="course.setting.online"
              color="primary1"
            />
          </RadioContainer>

          <RadioContainer>
            <RadioButton
              field={{
                name: 'type',
                value: limitation,
                onChange: () => {
                  setLimitation('classroom')
                  //  setCourseImageTypeState('useTemplate')
                  setFieldValue('type', 'classroom')
                },
              }}
              id="classroom"
              label={
                props.locale === 'en' ? 'Duration and Student Limiting' : 'กำหนดระยะเวลาและจำนวนคน'
              }
              labelId="course.setting.classroom"
              color="primary1"
            />
          </RadioContainer>
        </Itemradio>
        {/* <CustomReactSelect
          // id="course.setting.coursetype"
          // label="Limiting"
          name="type"
          options={props.locale === 'en' ? options : optionsTH}
          value={values.type}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.type}
          touched={touched.type}
          type={type}
          color="primary1"
          bold={false}
        /> */}
        {limitation === 'classroom' && (
          <Form>
            <GridDurationAndPricing>
              {/* <div>
                        <Field
                          name="publish"
                          title="publish"
                          component={CustomFieldDuration}
                          label="Publish period :"
                          shortDes="(Set a period of time to display this course on the system)"
                          texts={texts}
                          {...{ handleChange, values, errors, touched }}
                        />
                      </div> */}
              <div>
                <Field
                  id="course.setting.coursetype.classroom.register"
                  descId="course.setting.coursetype.classroom.register.des"
                  name="register"
                  title="register"
                  component={CustomFieldDuration}
                  label="Registration Period"
                  shortDes="Set a period of time for enrollment"
                  texts={texts}
                  color="primary1"
                  fontSize="text-xl"
                  descfontSize="text-base"
                  descPadding="12px 0 0"
                  descMargin="0 0 24px"
                  {...{ handleChange, values, errors, touched }}
                />
              </div>
              <div style={{ marginTop: 9 }}>
                <Field
                  id="course.setting.coursetype.classroom.maxcapacity"
                  descId="course.setting.coursetype.classroom.maxcapacity.desc"
                  name="maxStudent"
                  component={CustomFieldPrice}
                  label="Max capacity"
                  shortDes="Maximum number of students for this course"
                  color="primary1"
                  fontSize="text-xl"
                  descfontSize="text-base"
                  descPadding="12px 0 0"
                  descMargin="0 0 24px"
                  {...{ handleChange, values, errors, touched }}
                />
              </div>
            </GridDurationAndPricing>
          </Form>
        )}
      </Card>

      <Card padding="24px 32px" margin="16px 0 0">
        <BadgeAndSkill isManagePage={props.isManagePage}></BadgeAndSkill>
      </Card>

      <Card padding="24px 32px" margin="16px 0 0">
        {/* <Formik
          ref={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            teachers: _teachers || [],
            customTeacherList: customTeacherList || [],
          }}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {(formikProps) => ( */}
        <>
          {/* <SubHeader>
              <div className="wrapper">
                <div className="main-header">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography id="course.instructor.title" h3 className="title">
                        Instructor
                      </Typography>
                      <a href={tutorialLink} target="_blank" rel="noreferrer">
                        <Icon
                          style={{ width: '20px', height: '20px', margin: '10px' }}
                          src={TutorialIcon}
                        />
                      </a>
                    </div>
                    <Description id="course.instructor.desc">
                      Assign instructor to this course
                    </Description>
                  </div>
                  <Button
                    id="button.save"
                    type="submit"
                    primary
                    // primary={!formikProps.isSubmitting}
                    bold
                    onClick={formikProps.handleSubmit}
                    // disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </SubHeader> */}
          <Content>
            <Form>
              <FieldArray name="teachers">
                {({ name, ...arrayHelpers }) => {
                  return (
                    <>
                      <Header>
                        <Typography
                          id="course.instructor.instructor-list"
                          fontSize="text-xl"
                          bold
                          color="primary1"
                          margin="0 0 16px 0"
                        >
                          Instructor list
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography
                            id="course.instructor.inHouse-instructor"
                            color="primary1"
                            margin="0 0 12px 0"
                          >
                            In-House Instructor
                          </Typography>
                          <Typography color="danger" margin="0 0 12px 0">
                            *
                          </Typography>
                        </div>
                      </Header>
                      <Grid>
                        {/* {getIn(values, name).map((item, index) => ( */}
                        {teacherList &&
                          teacherList.length > 0 &&
                          teacherList.map((item, index) => {
                            const nameTeacher = `${item?.appUser?.firstname} ${item?.appUser?.lastname}`
                            const id = isPublished ? item?.appUserId : item?.id
                            const teacherImage = isPublished
                              ? item?.appUser?.profileImage
                              : item.profileImage
                            return (
                              <Suspense fallback={MyLoader} key={id}>
                                <Field
                                  key={id}
                                  name={name}
                                  id={id}
                                  teacherName={item.name || nameTeacher}
                                  teacherImage={teacherImage}
                                  skill={item.skill}
                                  component={InstructorCard}
                                  arrayHelpers={arrayHelpers}
                                  index={index}
                                  errors={errors.teachers}
                                />
                              </Suspense>
                            )
                          })}
                      </Grid>
                    </>
                  )
                }}
              </FieldArray>
              {/* <hr style={{ color: '#cbd5e1', backgroundColor: '#cbd5e1', marginTop: '24px' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: '12px',
                }}
              >
                <Button
                  id="button.save"
                  type="submit"
                  primary
                  width={171}
                  center
                  //onClick={handleSubmit}
                >
                  Save
                </Button>
              </div> */}
            </Form>
            <Line></Line>
          </Content>

          <InstructorFooter
            customList={values.customTeacherList}
            //formikProps={formikProps}
            setFieldValue={setFieldValue}
          />
        </>
        {/* )}
         </Formik> */}
      </Card>

      <Card padding="24px 32px" margin="16px 0 0">
        <MobileAndTablet>
          <ButtonWrapper>
            {isHidden ? (
              <StyledButton
                id="button.publish"
                className="publish"
                disabled={!isPublished}
                onClick={handlePublishCourse}
              >
                Publish
              </StyledButton>
            ) : (
              <StyledButton
                id="button.unpublish"
                className="unpublish"
                disabled={!isPublished}
                onClick={handleUnpublishCourse}
              >
                Unpublish
              </StyledButton>
            )}
            {isHidden ? (
              <StyledParagraph id="course.setting.publish.desc">
                Allows student to find your course via search
              </StyledParagraph>
            ) : (
              <StyledParagraph id="course.setting.unpublish.desc">
                New students cannot find your course via search, but existing students can still
                access content.
              </StyledParagraph>
            )}
          </ButtonWrapper>
        </MobileAndTablet>
        <ButtonWrapper>
          <StyledButton
            id="button.delete"
            className="delete"
            type="button"
            onClick={() => {
              setOpenModal(true)
            }}
            disabled={isPublished ? (studentCount > 0 ? true : false) : false}
          >
            Delete
          </StyledButton>
          <StyledParagraph id="course.setting.delete.desc">
            Courses cannot be deleted after students have enrolled.
          </StyledParagraph>
        </ButtonWrapper>
        {props.Confirm && (
          <ButtonWrapper>
            <StyledButton
              id="button.clone"
              type="button"
              className="clone"
              onClick={() => {
                props.Confirm.open({
                  title: (
                    <Typography id="course.setting.clone.confirmMessage">
                      Are you sure you want to clone this course?
                    </Typography>
                  ),
                  onCancel: () => props.Confirm.close(),
                  onConfirm: async () => {
                    const { schoolId, courseId } = props.match.params
                    const draftCourse = await CourseAPI.cloneCourse({ id: courseId })
                    props.history.push(`/schools/${schoolId}/course-draft/${draftCourse.id}`)
                    props.Confirm.close()
                  },
                  confirmText: 'Confirm',
                })
              }}
            >
              Clone
            </StyledButton>
            <StyledParagraph id="course.setting.clone.desc" color="primary1">
              Copy course
            </StyledParagraph>
          </ButtonWrapper>
        )}
      </Card>
    </>
  )
}

CourseSettingPage.defaultProps = {}

const mapState = ({
  TeacherSchool: { teacherList },
  DraftCourse: {
    draftInfo: { _teachers, publicMode, name, customTeacherList },
  },
  Language: { locale },
}) => ({
  teacherList: teacherList.map((item) => ({
    id: item.appUser.id,
    name: `${item.appUser.firstname} ${item.appUser.lastname}`,
    profileImage: item.appUser.profileImage,
    skill: '',
  })),
  _teachers,
  publicMode,
  name,
  customTeacherList,
  locale,
  isPublished: false,
})

const mapDispatch = ({ TeacherSchool: { getTeacherList }, DraftCourse: { updateDraft } }) => ({
  getTeacherList,
  updateCourse: updateDraft,
})
export default connect(mapState, mapDispatch)(CourseSettingPage)
