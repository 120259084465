import styled, { css } from 'styled-components'
import { IoMdMenu } from 'react-icons/io'
import { Container } from '../../components'

const ContainerQuestions = styled(Container)`
  position: fixed;
  top: ${({ isCourseSetting }) => (isCourseSetting ? '56px' : '48px')};
  right: 0;
  height: ${({ isCourseSetting }) => (isCourseSetting ? 'calc(100vh - 0px)' : '100%')};
  z-index: 4;
  top: 54px;
  right: 140px;
  // grid-template-rows: repeat(20, min-content);
  grid-template-rows: repeat(5, min-content) 1fr;
  margin: 0;
  width: 43%;
  min-width: 400px;
  overflow-y: scroll;
  transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  ::-webkit-scrollbar {
    width: 0px;
  }
  // padding: 10px 27px;
  /* border-radius: 11px; */
  background-color: transparent;
  // box-shadow: 0px 5px 20px #00000029;

  display: ${({ selected }) => (selected ? 'grid' : 'none')};
  ${({ customStyle }) => {
    if (customStyle) {
      const { top, height } = customStyle
      return css`
        top: ${top};
        height: ${height};
      `
    }
    return css``
  }}
`

const ContainerHeader = styled.div`
  // display: inline-flex;
  // align-items: center;
  margin-bottom: 24px;
`

const DragHandle = styled.div`
  margin-right: 10px;
  align-self: center;
  justify-self: end;
`

const StyledIoMdMenu = styled(IoMdMenu)`
  font-size: ${({ theme }) => theme['text-sm']};
  fill: #cdcdcd;
  display: flex;
  /* margin-right: 20px; */
`

const Wrapper = styled.div`
  position: relative;
`
const Content = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 28px;
`
export { ContainerQuestions, ContainerHeader, DragHandle, StyledIoMdMenu, Wrapper, Content }
