import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorBoundary from '../../components/errorBoundary'
import Breadcrumb from '../../components/breadcrumb/loadable'
import TabComponent from '../../components/tabComponent/loadable'
import { Heading } from '../../components/styledComponents'
import { menus } from './menus'

const breadcrumb = [
  {
    label: 'Courses',
    link: '',
    isActive: false,
  },
  {
    label: 'Course Title',
    link: '',
    isMobile: true,
    isActive: false,
  },
  {
    label: 'Course Setting',
    link: '',
    isActive: true,
  },
]

const TabStyle = {
  backgroundColor: 'transparent',
  boxShadow: '0 0 0 0',
  padding: '0',
  borderBottom: '1px solid rgba(194, 194, 194, 0.5)',
}

const Container = styled.div`
  padding: 30px;

  min-height: calc(100vh - 114px);
  padding-bottom: 110px;

  @media (max-width: 768px) {
    padding: 20px;
    padding-bottom: 80px;
    min-height: calc(100vh - 110px);
  }
`

class TeacherCourseSettingLayout extends Component {
  static propTypes = {}

  onChangeTab = (path) => {
    const { match } = this.props
    this.props.history.push(match.url + path)
  }

  render() {
    const { children } = this.props
    return (
      <ErrorBoundary>
        <Breadcrumb firstLabel="My Course" data={breadcrumb} />
        <Container>
          <Heading>Course Title</Heading>
          <TabComponent
            style={TabStyle}
            transparent
            tabData={menus}
            activeTab={0}
            onChangeTab={this.onChangeTab}
          />
          {children}
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapState = (state) => ({
  appUser: state.AppUser.appUser,
})

const mapDispatch = (dispatch) => {
  const {
    AppUser: { login },
  } = dispatch
  return {
    login,
  }
}

export default connect(mapState, mapDispatch)(TeacherCourseSettingLayout)
