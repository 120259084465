import React from 'react'
import ErrorBoundary from '../errorBoundary'
// import getImageUrl from '../../utils/getImageUrl'

import { Container } from './style'

const HoverVideoPlayer = ({ children }) => {
  // console.log('hoverVideo')
  return (
    <ErrorBoundary>
      <Container>{children}</Container>
    </ErrorBoundary>
  )
}

export default HoverVideoPlayer
