import { AssignmentsAPI } from '../../api'

export const Assignment = {
  state: {},
  reducers: {
    setAssignmentReport(state, payload) {
      return {
        ...state,
        assignmentReport: payload,
      }
    },
  },
  effects: () => ({
    async getAssignmentReportByAssignmentId(assignmentId) {
      const assignmentReport = await AssignmentsAPI.getAssignmentReportByAssignmentId({
        id: assignmentId,
      })
      this.setAssignmentReport(assignmentReport)
    },
    async cloneAssignment(assignmentId) {
      try {
        const result = await AssignmentsAPI.cloneAssignment({ id: assignmentId })
        return result
      } catch (error) {
        throw error
      }
    },
  }),
}
