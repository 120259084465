import React from 'react'
import PropTypes from 'prop-types'
import { EmptyStyle, Desc, EmptyCard, Image } from './style'
import Typography from '../typography'
import EmptyImg from '../../images/empty.png'

function EmptyNoBr({
  id,
  values = {},
  image,
  title,
  desc,
  titleIcon,
  hideImage,
  btn,
  widthTitle,
  noMargin,
  onImageLoaded,
  reversepicture = false,
  ...props
}) {
  if (reversepicture) {
    return (
      <EmptyCard noMargin={noMargin}>
        <EmptyStyle {...props}>
          {title && (
            <Typography
              values={values}
              id={id?.title}
              // h2
              // color="gray2"
              center
              h4
              strong
              color="#828282"
              className="title"
              icon={titleIcon}
              flex={titleIcon}
              reverse={titleIcon}
              width={widthTitle}
            >
              {title}
            </Typography>
          )}
          {desc && (
            <Desc id={id?.desc} center h5 thin color="#ADADAD" className="desc">
              {desc}
            </Desc>
          )}
          {!hideImage && (
            <Image
              src={image || EmptyImg}
              alt="response-img"
              width="295px"
              className="full-image"
              onLoad={onImageLoaded}
            />
          )}
          {btn && btn}
        </EmptyStyle>
      </EmptyCard>
    )
  } else {
    return (
      <EmptyCard noMargin={noMargin}>
        <EmptyStyle {...props}>
          {!hideImage && (
            <Image
              src={image || EmptyImg}
              alt="response-img"
              width="295px"
              className="full-image"
              onLoad={onImageLoaded}
            />
          )}
          {title && (
            <Typography
              values={values}
              id={id?.title}
              // h2
              // color="gray2"
              center
              h4
              strong
              color="#828282"
              className="title"
              icon={titleIcon}
              flex={titleIcon}
              reverse={titleIcon}
              width={widthTitle}
            >
              {title}
            </Typography>
          )}
          {desc && (
            <Desc id={id?.desc} center h5 thin color="#ADADAD" className="desc">
              {desc}
            </Desc>
          )}
          {btn && btn}
        </EmptyStyle>
      </EmptyCard>
    )
  }
}
EmptyNoBr.propTypes = {
  id: PropTypes.object,
  values: PropTypes.object,
  children: PropTypes.node,
  props: PropTypes.object,
}

EmptyNoBr.defaultProps = {
  props: {},
  children: null,
}

export default EmptyNoBr
