import ProfileBlack from '../../images/ProfileBlack.svg'
import InfoBlackIcon from '../../images/InfoBlack.svg'
import SuitcaseSimple from '../../images/SuitcaseSimple.svg'
import GraduationCap from '../../images/GraduationCap.svg'
import Heart from '../../images/Heart.svg'
import Envelope from '../../images/Envelope.svg'

import ProfileActive from '../../images/UserCircleActive.svg'
import InfoActive from '../../images/InfoActive.svg'
import SuitcaseSimpleActive from '../../images/SuitcaseSimpleActive.svg'
import GraduationCapActive from '../../images/GraduationCapActive.svg'
import HeartActive from '../../images/HeartActive.svg'
import EnvelopeActive from '../../images/EnvelopeActive.svg'
export const menus = [
  {
    id: 'profile.profile.title',
    label: 'Profile',
    check: 'profile',
    link: '/profile/edit/profile',
    icon: ProfileBlack,
    iconActive: ProfileActive,
  },
  {
    id: 'profile.intro.title',
    label: 'Intro',
    check: 'intro',
    link: '/profile/edit/intro',
    icon: InfoBlackIcon,
    iconActive: InfoActive,
  },
  {
    id: 'profile.work experience.title',
    label: 'Work Experience',
    check: 'work-experience',
    link: '/profile/edit/work-experience',
    icon: SuitcaseSimple,
    iconActive: SuitcaseSimpleActive,
  },
  {
    id: 'profile.education.title',
    label: 'Education',
    check: 'education',
    link: '/profile/edit/education',
    icon: GraduationCap,
    iconActive: GraduationCapActive,
  },
  {
    id: 'profile.interest.title',
    label: 'Interest',
    check: 'interests',
    link: '/profile/edit/interests',
    icon: Heart,
    iconActive: HeartActive,
  },
  {
    id: 'profile.contact.title',
    label: 'Contact',
    check: 'contact',
    link: '/profile/edit/contact',
    icon: Envelope,
    iconActive: EnvelopeActive,
  },
]
export const menusTeacher = [
  {
    id: 'profile.profile.title',
    label: 'Profile',
    check: 'profile',
    link: '/teacher/profile/edit/profile',
    icon: ProfileBlack,
    iconActive: ProfileActive,
  },
  {
    id: 'profile.intro.title',
    label: 'Intro',
    check: 'intro',
    link: '/teacher/profile/edit/intro',
    icon: InfoBlackIcon,
    iconActive: InfoActive,
  },
  {
    id: 'profile.work experience.title',
    label: 'Work Experience',
    check: 'work-experience',
    link: '/teacher/profile/edit/work-experience',
    icon: SuitcaseSimple,
    iconActive: SuitcaseSimpleActive,
  },
  {
    id: 'profile.education.title',
    label: 'Education',
    check: 'education',
    link: '/teacher/profile/edit/education',
    icon: GraduationCap,
    iconActive: GraduationCapActive,
  },
  {
    id: 'profile.interest.title',
    label: 'Interest',
    check: 'interests',
    link: '/teacher/profile/edit/interests',
    icon: Heart,
    iconActive: HeartActive,
  },
  {
    id: 'profile.contact.title',
    label: 'Contact',
    check: 'contact',
    link: '/teacher/profile/edit/contact',
    icon: Envelope,
    iconActive: EnvelopeActive,
  },
]
