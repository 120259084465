import React, { useRef, useEffect, useState } from 'react'
import { Form, FieldArray, Field, getIn } from 'formik'
import styled from 'styled-components'
import PlusIcon from '../../images/icons/primary-plus.svg'
import DeleteIcon from '../../images/icons/Trash.svg'
import { Input, Label, Typography } from '../../components'

const Container = styled.div`
  :not(:first-of-type) {
    margin-top: 20px;
  }
`

const InputItem = styled.div`
  width: 100%;
`
// const Description = styled.div`
//   font-size: ${({ theme }) => theme['text-xs']};
//   line-height: 10px;
//   padding-bottom: 5px;
//   color: ${(props) => props.theme.grey};
// `

const DeleteButton = styled.button`
  /* display: none; */
  padding: 5px;
  margin: 0;
  border: none;
  background-color: white;
`
const DeleteImage = styled.img`
  width: 20px;
`
const InputText = ({
  field,
  form: { errors },
  form,
  placeholder,
  push,
  remove,
  parentName,
  index,
  state,
  setState,
}) => {
  const inputText = useRef(null)

  useEffect(() => {
    if (index === state.index + 1) {
      inputText.current.focus()
    } else {
      // inputText.current.blur()
    }
  }, [state, index])
  const isLastItem = index === getIn(form.values, parentName).length - 1
  const handleKeydown = (e) => {
    if (
      e.key === 'Enter' &&
      !getIn(errors, field.name) &&
      getIn(form.values, field.name) !== '' &&
      isLastItem
    ) {
      push('')
      setState({
        index,
      })
      // inputText.current.focus()
    } else if (e.key === 'Enter') {
      setState({
        index,
      })
    }
  }

  return (
    <InputItem>
      <Input
        type="text"
        inputRef={inputText}
        {...field}
        placeholder={placeholder}
        onKeyDown={handleKeydown}
        bgSuffix="inherit"
        suffix={
          <DeleteButton type="button" className="deleteButton" onClick={() => remove(index)}>
            <DeleteImage src={DeleteIcon}></DeleteImage>
          </DeleteButton>
        }
      />
    </InputItem>
  )
}

const AddItemList = ({ id, descId, label, description, name, value, placeholder, ...props }) => {
  const [state, setState] = useState({ index: 0 })

  return (
    <Container>
      <Form>
        <FieldArray name={name}>
          {({ push, remove }) => {
            const handleClick = () => {
              push('')
            }
            return (
              <>
                <Label
                  id={id}
                  descId={descId}
                  noMargin
                  description={description}
                  isRequired={props.isRequired}
                  color={props.color}
                  bold={props.bold}
                >
                  {label}
                </Label>
                <div style={{ display: 'flex', gap: 14, paddingTop: description ? 0 : 8 }}>
                  {value.map((item, index) => (
                    <Field
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index}`}
                      name={`${name}[${index}]`}
                      component={InputText}
                      label="Course Title"
                      placeholder={placeholder}
                      push={push}
                      remove={remove}
                      index={index}
                      parentName={name}
                      state={state}
                      setState={setState}
                    />
                  ))}
                  {/* <input type="text" onChange={(e) => setInput(e.target.value)} value={newInput} /> */}
                  {!props.hideAdd && (
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                        alignItems: 'center',
                        cursor: 'pointer',
                        minWidth: '80px',
                        padding: '8px 0',
                      }}
                      onClick={handleClick}
                    >
                      <img alt="add-icon" src={PlusIcon} style={{ width: 24, height: 24 }} />
                      <Typography id="profile.intro.add" color="primary1">
                        + Add
                      </Typography>
                    </div>
                  )}
                </div>
              </>
            )
          }}
        </FieldArray>
      </Form>
    </Container>
  )
}

export default AddItemList
