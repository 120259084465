import React, { Fragment } from 'react'
// import Icon from 'react-eva-icons'
import 'react-datepicker/dist/react-datepicker.css'
import PropTypes from 'prop-types'
import CalendarIcon from '../../images/icons/date-picker-icon.svg'
import DarkgrayCalendarIcon from '../../images/icons/darkgray-date-picker-icon.svg'

import { DateTime, Wrapper, Span } from './style'
import Typography from '../typography'

function PureDateTime({ id, name, hasIcon, value, onChange, ...props }) {
  return (
    <Fragment>
      <Wrapper {...props}>
        {hasIcon && (
          <Span {...props} style={{ position: 'relative', bottom: 8 }}>
            <img src={props.darkgrayIcon ? DarkgrayCalendarIcon : CalendarIcon} alt="calendar" />
          </Span>
        )}
        <DateTime
          id={id}
          name={name}
          selected={value}
          onChange={onChange}
          dateFormat="dd MMM yyyy"
          hasIcon={hasIcon}
          placeholderText={props.placeholder}
          autocomplete="off"
          {...props}
        />
      </Wrapper>
      {props.status === 'error' && (
        <Typography margin="5px 0px" strong error>
          {props.messageStatus}
        </Typography>
      )}
    </Fragment>
  )
}

PureDateTime.propTypes = {
  props: PropTypes.object,
  hasIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

PureDateTime.defaultProps = {
  props: {},
  hasIcon: false,
}

export default PureDateTime
