import React from 'react'
import styled from 'styled-components'
// import _ from 'lodash'

// import PageHeading from '../../components/pageHeading/loadable'
// import { tabs } from './tabs'

const Container = styled.div``

class SchoolDashboardLayout extends React.Component {
  // constructor(props) {
  // super(props)
  // this.state = {
  //   activePath: `/${props.location.pathname.split('/').slice(-1)[0]}`,
  // }
  // }

  onChangeTab = (path) => {
    const { match } = this.props
    // this.setState({ activePath: path })
    this.props.history.push(match.url + path)
  }

  render() {
    const { children } = this.props
    // const { activePath } = this.state
    return (
      <React.Fragment>
        {/* <PageHeading
          id="dashboard.title"
          text="Dashboard"
          tabData={tabs}
          onChangeTab={this.onChangeTab}
          hideShadow={_.includes(['/report'], activePath)}
        /> */}
        <Container>{children}</Container>
      </React.Fragment>
    )
  }
}

export default SchoolDashboardLayout
