import React from 'react'
import { connect } from 'react-redux'
import CurrencyReactSelect from 'components/currencyReactSelect'
import { Typography } from 'components'
import { Content, Row, TextLink } from './styled'

// import SummaryCard from './summaryCard'

const PaidCourse = (props) => {
  const { formikProps, isVerified, Currency, handleVerifyButton } = props
  // const { name, logo } = mySchoolDetail
  return (
    <Content style={{ paddingTop: 16 }}>
      <Row>
        {/* {publicMode === 'PUBLIC' && (
                    <div style={{ marginRight: 10 }}>
                      <Switch checked={shownPrice} onChange={() => setShownPrice((v) => !v)} />
                    </div>
                  )} */}
      </Row>
      <CurrencyReactSelect
        style={{ display: 'grid', gridTemplateColumns: '45px 1fr' }}
        name="price"
        disable={!isVerified}
        value={formikProps.values.price}
        options={formikProps.values.priceOptions}
        onChange={formikProps.setFieldValue}
        onBlur={formikProps.setFieldTouched}
        error={formikProps.errors.price}
        touched={formikProps.touched.price}
        SelectHeader={Currency}
        placeholder="THB"
      />

      {!isVerified && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            id="course.tuitionfee.verify"
            fontSize="text-sm"
            color="primary1"
            margin="16px 5px 0px 0px"
          >
            Your academy hasn&apos;t verify by Classwin,
          </Typography>
          <TextLink
            id="course.tuitionfee.verify.click"
            fontSize="text-sm"
            color="primary13"
            margin="16px 0px 0px 0px"
            onClick={handleVerifyButton}
          >
            click here
          </TextLink>
          <Typography
            id="course.tuitionfee.verify.submit"
            fontSize="text-sm"
            color="primary1"
            margin="16px 0px 0px 5px"
          >
            to submit document for verification
          </Typography>
        </div>
      )}
    </Content>
  )
}

const mapState = ({ MySchool: { mySchoolDetail } }) => ({ mySchoolDetail })

export default connect(mapState)(PaidCourse)
