/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { ClasswinEditor, Button, Typography, Upload } from '../../../components'
import Cropable from '../../../components/cropableCourseContent'
import PlusIcon from 'images/ic-plus-white-24-px.svg'
import Modal from '../../../components/modal'
// import QuestionsItem from '../../questionsItem'
import { FileAPI } from 'api'
import { fabric } from 'fabric'
import getImageUrl from 'utils/getImageUrl'
import theme from 'theme'
import debounce from 'lodash/debounce'
import { useEffectOnce } from 'react-use'
import reactImageSize from 'react-image-size'
import styled from 'styled-components'
import ConfirmModal from 'components/confirmModal/loadable'
// import Lightbox from 'react-image-lightbox'
// import 'react-image-lightbox/style.css'
import CancelIcon from 'images/icons/primary-small-x.svg'
import CancelIconModal from 'images/icons/closeIcon.svg'
import PencilSimple from 'images/icons/PencilSimple.svg'
import PointOnImagePreview from 'components/pointOnImagePreview'
import { Space } from 'antd'

const FilenameBorder = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  border: 1px solid ${({ theme }) => theme.black38};
  border-radius: 8px;
  width: 100%;
  height: 40px;
`
const Content = styled.div`
  background-color: ${(props) => props.theme.whiteblue};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 3px 12px;
  min-width: 120px;
  gap: 8px;
`
const CloseIcon = styled.div`
  display: flex;
  margin-left: auto;
  height: max-content;
  cursor: pointer;

  img {
    width: 10px;
    height: 10px;
  }
`
const Container = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #000000ad;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const Body = styled.div`
  background-color: #fff;
  position: relative;
  max-width: 775px;
  width: 100%;
  //height: 400px;
  z-index: 100;
  margin: auto;
  //padding: 20px 32px 32px 32px;
  border-radius: 8px;
  .header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: 24px 0 16px 0;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const CloseIconModal = styled.div`
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 20px;
  img {
    height: 24px;
    width: 24px;
  }
`
const Item = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`
const PointImage = ({
  locale,
  title,
  inputChange,
  updateQuestion,
  id,
  assessmentId,
  handleAutoSave,
  getQuestionData,
  points,
  image,
  ImageApi,
  debounceInput,
  value,
  type,
  setTitle,
  setPoints,
  setImage,
}) => {
  //  const [image, setImage] = useState(null)
  const [isShowSaveModal, setIsShowSaveModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [openPointOnImage, setOpenPointOnImage] = useState(false)
  const [canvas, setCanvas] = useState('')
  const [file, setFile] = useState(null)
  const [fileType, setFileType] = useState(null)
  const [circlePoints, setCirclePoints] = useState([])

  const onChange = (value) => {
    setImage(value.name)
  }
  const afterUpload = async (value) => {
    // conssole.log(value)
    await clearAll(circlePoints)
    dynamicCanvas(image || ImageApi)
    setOpenPointOnImage(true)
  }
  const beforeCrop = (formData) => {
    const file = formData.get('file')
    const objectURL = URL.createObjectURL(file)
    setFile(objectURL)
    setFileType(file.type)
  }

  const afterCrop = async (blob) => {
    try {
      const file = await fetch(blob)
        .then((res) => res.blob())
        .then((blob) => {
          let filename = 'filename.jpeg'
          if (fileType === 'image/png') {
            filename = 'filename.png'
          }
          const newFile = new File([blob], filename, { type: fileType })
          return newFile
        })
      const formData = new FormData()
      formData.append('file', file)

      const res = await FileAPI.upload(formData)

      afterUpload({ name: res.result.files.file[0].name })
      setImage(res.result.files.file[0].name)
      // getQuestionData()

      dynamicCanvas(res.result.files.file[0].name || ImageApi)
    } catch (error) {
      throw error
    } finally {
      setFile(null)
    }
  }
  // console.log('image', image)
  const undo = async (values) => {
    if (values?.length > 0) {
      canvas._objects.pop()
      setCirclePoints((prev) => prev.slice(0, -1))
      // try {
      //   await updateQuestion({
      //     assessmentId,
      //     questionId: id,
      //     image: image || ImageApi,
      //     points: [],
      //   })
      //   await getQuestionData()
      //   // handleAutoSave(true)
      // } catch (e) {
      //   // handleAutoSave(false)
      //   console.log(JSON.stringify(e.response))
      // }

      canvas.renderAll()
    }
  }
  const clearAll = async (values) => {
    if (values?.length > 0) {
      setCirclePoints([])
      canvas?.remove?.(...canvas?.getObjects?.())

      // try {
      //   await updateQuestion({
      //     assessmentId,
      //     questionId: id,
      //     image: image || ImageApi,
      //     points: [],
      //   })
      //   await getQuestionData()
      //   // handleAutoSave(true)
      // } catch (e) {
      //   // handleAutoSave(false)
      //   console.log(JSON.stringify(e.response))
      // }
    }
    canvas.renderAll()
  }
  const dynamicCanvas = useCallback(
    async (image) => {
      if (image) {
        const { width, height } = await reactImageSize(getImageUrl(image, null, 300, 400))
        // console.log('width', width, 'height', height)
        setCanvas(initCanvas(width, height))
      } else {
        setCanvas(initCanvas(600, 550))
      }
    },
    [initCanvas]
  )
  useEffectOnce(() => {
    getQuestionData()

    dynamicCanvas(image || ImageApi)
  })

  // useEffect(() => {
  //   if (ImageApi) {
  //     setImage(ImageApi)
  //   }
  // }, [ImageApi])

  const initCanvas = useCallback(
    (width, height) =>
      new fabric.Canvas(id, {
        height,
        width,
        selection: false,
        backgroundColor: 'transparent',
      }),
    [id]
  )

  useEffect(() => {
    if (points?.length > 0) {
      setCirclePoints(points || [])
    }
  }, [points])

  // useEffect(() => {
  //   const debouncePointImg = debounce(async (points) => {
  //     try {
  //       await updateQuestion({
  //         assessmentId,
  //         questionId: id,
  //         image: image || ImageApi,
  //         points,
  //       })
  //       // await getQuestionData()
  //       //  handleAutoSave(true)
  //     } catch (e) {
  //       // handleAutoSave(false)
  //       console.log(JSON.stringify(e.response))
  //     }
  //   }, 300)

  //   debouncePointImg(circlePoints || [])
  // }, [circlePoints, assessmentId, id, updateQuestion, ImageApi, image])

  const initImage = useCallback(
    (canvas) => {
      if (canvas) {
        fabric.Image.fromURL(getImageUrl(image || ImageApi, null, 300, 400), function (img) {
          img.set({ left: 0, top: 0 }).scale(1)

          canvas.setBackgroundImage(img)

          canvas?.renderAll()
        })
        if (points?.length > 0) {
          canvas?.remove?.(...canvas?.getObjects?.())

          let circle
          for (let i = 0; i <= points?.length - 1; i++) {
            let pointer = points[i]

            circle = new fabric.Circle({
              left: pointer.x,
              top: pointer.y,
              radius: 30,
              strokeWidth: 5,

              fill: 'transparent',
              stroke: theme.success2,
              selectable: false,
              originX: 'center',
              originY: 'center',
            })
            canvas.add(circle)
          }
        }
      }
    },

    [image, ImageApi, points]
  )
  useEffect(() => {
    initImage(canvas)
  }, [canvas, initImage])
  fabric.Object.prototype.lockRotation = true
  fabric.Object.prototype.lockMovementX = true
  fabric.Object.prototype.lockMovementY = true
  fabric.Object.prototype.selectable = false
  useEffect(() => {
    let circle

    if (canvas) {
      canvas.on('mouse:down', async function (value) {
        const pointer = canvas.getPointer(value.e)
        const isDiff = canvas._objects?.every((item) => {
          const diff = Math.sqrt((item.left - pointer.x) ** 2 + (item.top - pointer.y) ** 2)
          const radius2 = 2 * 30

          return diff > radius2
        })

        if (isDiff) {
          circle = new fabric.Circle({
            left: pointer.x,
            top: pointer.y,
            radius: 30,
            strokeWidth: 5,
            fill: 'transparent',
            stroke: theme.success2,
            selectable: false,
            originX: 'center',
            originY: 'center',
          })
          setCirclePoints((prev) => [...prev, { x: pointer.x, y: pointer.y }])
          canvas.add(circle)
          // debouncePointImg({ x: pointer.x, y: pointer.y })
          // try {
          //   await updateQuestion({
          //     assessmentId,
          //     questionId: id,
          //     image: image || ImageApi,
          //     points,
          //   })

          //   handleAutoSave(true)
          // } catch (e) {
          //   handleAutoSave(false)
          //   console.log(JSON.stringify(e.response))
          // }
        } else {
          return null
        }
      })
    }
  }, [
    canvas,
    circlePoints,
    // updateQuestion,
    // handleAutoSave,
    // assessmentId,
    // id,
    // image,
    // ImageApi,
    // points,
  ])

  useEffect(() => {
    return () => {
      setCanvas('')
    }
  }, [])
  // console.log('ImageApi', ImageApi)
  return (
    <>
      <Formik initialValues={{ title }} enableReinitialize>
        {() => (
          <Form>
            <Typography id="label.question" color="primary1" margin="0 0 8px">
              Question
            </Typography>
            <Field name="title">
              {({ field, form }) => (
                <ClasswinEditor
                  style={{ marginBottom: '0px' }}
                  isShowAll={false}
                  id="title"
                  name="title"
                  value={field.value}
                  placeholder={locale === 'th' ? 'คำถาม' : 'Question'}
                  onChange={(value) => {
                    const e = {
                      target: {
                        name: 'title',
                        value,
                      },
                    }
                    form.handleChange(e)
                    //inputChange(value)
                    setTitle(value)
                  }}
                />
              )}
            </Field>
          </Form>
        )}
      </Formik>
      <Typography id="course.content.answer" color="primary1" margin="12px 0 0">
        Answer(s)
      </Typography>
      <Typography
        id="course.content.answer.pointOnImage.desc"
        color="black38"
        margin="0 0 8px"
        fontSize="text-xs"
      >
        (Select image from your device to upload (limit 300 MB))
      </Typography>

      <Cropable
        isModal
        cropLabel="Answers"
        cropLabelId="answers.pointOnImage"
        fileType={fileType}
        image={file}
        afterCrop={afterCrop}
        cropShape="rect"
        // aspect={640 / 480}
        hasCustomAspect={true}
        handleClearFile={() => setFile(null)}
        setOpenPointOnImage={setOpenPointOnImage}
      >
        <Item>
          <FilenameBorder>
            {image && (
              <Content>
                <Typography color="primary1" fontSize="text-xs" width="100%" numberOfLines={1}>
                  {image || 'image.jpeg'}
                </Typography>
                <CloseIcon
                  onClick={() => {
                    setImage(null)
                    setFile(null)
                    setPoints([])
                  }}
                >
                  <img src={CancelIcon} alt="cancel" />
                </CloseIcon>
              </Content>
            )}
          </FilenameBorder>
          {image ? (
            <Button
              id="button.edit.answer"
              type="button"
              cancelbtn
              //width="100%"
              style={{ minWidth: '140px', maxWidth: '140px' }}
              image={<img src={PencilSimple} alt="plus-icon" />}
              onClick={() => {
                //  handleAutoSave(true)
                dynamicCanvas(image || ImageApi)
                setOpenPointOnImage(true)
              }}
            >
              Edit Answer ภาษาไทยเป็น แก้ไข
            </Button>
          ) : (
            <Upload
              beforeCrop={beforeCrop}
              afterUpload={(value) => onChange(value)}
              limitSize={5242880}
              acceptFileTypes={['image/jpeg', 'image/png']}
            >
              <Button
                id="button.upload.img2"
                type="button"
                primary
                image={<img src={PlusIcon} alt="plus-icon" />}
                //width="100%"
                style={{ minWidth: '140px', maxWidth: '140px' }}
              >
                Upload Image
              </Button>
            </Upload>
          )}
        </Item>
      </Cropable>
      {image && (
        <Space
          style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <PointOnImagePreview
            styleWidth={400}
            id={`${id}-preview`}
            image={image}
            answers={points}
          />
        </Space>
      )}
      {/* {image && ()} */}
      <Modal isOpen={openPointOnImage} shownXclose onClickClose={() => setOpenPointOnImage(false)}>
        <Body>
          {/* <CloseIconModal onClick={() => setOpenPointOnImage(false)}>
            <img src={CancelIconModal} alt="cancel" />
          </CloseIconModal> */}
          <div className="header">
            <Typography h3 color="primary1" id="answers.pointOnImage">
              Answer
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
            <div>
              <Typography
                id="title.pointOnImage"
                fontSize="text-xs"
                color="primary1"
                margin="0 0 2px 0"
              >
                Please circle the answer in the image. The circles cannot overlap each other.
              </Typography>
              <Typography
                fontSize="text-xxs"
                id="subTitle.pointOnImage"
                color="black38"
                margin="0 0 22px 0"
              >
                Click on the image to display a green circle. To delete, please press the undo
                button.
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                // width: '100%',
                gap: 8,
              }}
            >
              <Button onClick={() => undo(circlePoints)} type="button" whiteBorder width="120px">
                {locale === 'en' ? 'Undo' : 'เลิกทำ'}
              </Button>
              <Button onClick={() => clearAll(circlePoints)} type="button" primary width="120px">
                {locale === 'en' ? 'Clear All' : 'ลบทั้งหมด'}
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <canvas id={id} />
          </div>
          <div className="footer">
            <Button
              //id="button.save"
              className="button"
              primary
              type="button"
              width="252px"
              margin="12px 0 0 0"
              onClick={async () => {
                //await getQuestionData()
                //  handleAutoSave(true)
                setPoints(circlePoints)
                setOpenPointOnImage(false)
                // dynamicCanvas(image || ImageApi)
              }}
            >
              {locale === 'en' ? 'Confirm' : 'ยืนยัน'}
            </Button>
          </div>
        </Body>
      </Modal>
    </>
  )
}

const mapState = ({ Language: { locale } }) => ({ locale })

export default connect(mapState)(PointImage)
