import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ErrorBoundary from '../errorBoundary'
import Typography from '../typography'

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const NodeGroup = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    width: 100%;
  }

  > :first-child::after {
    content: none;
  }
`

const Node = styled(Typography)`
  position: relative;
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme['text-lg']};
  font-weight: 500;
  color: ${(props) => props.theme.gray2};
  transition: all 0.2s;
  ${(props) => {
    if (props.active) {
      return css`
        color: ${props.theme.primary2};
      `
    }
    return ''
  }}

  &::before {
    content: '${(props) => props.order}';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: ${({ theme }) => theme['text-xl']};
    font-weight: 500;
    margin: 0 auto 15px auto;
    background-color: ${(props) => props.theme.gray4};
    transition: all 0.2s;
    color: #fff;

    ${(props) => {
      if (props.active) {
        return css`
          background-color: ${props.theme.primary2};
          box-shadow: -1px 3px 4px 0 rgba(178, 174, 174, 0.5);
          color: #fff;
        `
      }
      return ''
    }}
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    top: 18px;
    left: -50%;
    z-index: -1;
    background-color: ${(props) => props.theme.gray4};

    ${(props) => {
      if (props.active) {
        return css`
          background-color: ${props.theme.primary2};
        `
      }
      return ''
    }}
  }

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme['text-xs']};
  }
`

class FormNavigator extends Component {
  static propTypes = {}

  render() {
    const { steps, currentStep } = this.props
    return (
      <ErrorBoundary>
        <Container>
          <NodeGroup>
            {steps.map((step) => (
              <Node
                id={step.id}
                key={step.order}
                order={step.order + 1}
                active={step.order <= currentStep}
              >
                {step.label}
              </Node>
            ))}
          </NodeGroup>
        </Container>
      </ErrorBoundary>
    )
  }
}

FormNavigator.propTypes = {
  steps: PropTypes.array.isRequired,
}

export default FormNavigator
